import * as React from 'react'
import { createStyles, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import Text from '_atoms/text/Text'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.space(4),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.space(2),
            },
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        title: {
            color: theme.palette.neutral[800],
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(21),
        },
        tagline: {
            color: theme.palette.neutral[800],
            whiteSpace: 'pre-wrap',
            marginTop: theme.space(1),
        },
    }),
)

type BlueBackgroundHeaderProps = {
    title: string
    tagline?: string
    rightAddon?: any
    className?: string
}

const BlueBackgroundHeader: React.FC<BlueBackgroundHeaderProps> = ({ title, tagline, rightAddon, className = '' }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={`${classes.root} ${className}`}>
            <div className={classes.container}>
                <Text variant="S2" className={classes.title}>
                    {title}
                </Text>
                {rightAddon}
            </div>
            {tagline && (
                <Text variant={isMobile ? 'P4' : 'P5'} className={classes.tagline}>
                    {tagline}
                </Text>
            )}
        </div>
    )
}

export default BlueBackgroundHeader
