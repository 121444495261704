import { Button, Typography, makeStyles, createStyles, Theme } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: 180,
            maxWidth: 400,
            margin: 'auto',
            borderRadius: 16,
            padding: theme.spacing(3),
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.gradient,
            transition: `${theme.transitions.create(['transform'], { duration: theme.transitions.duration.standard })}`,
            transform: 'scale(1)',
            '&:hover': {
                transform: 'scale(0.99)',
            },
            '& a ': {
                textDecoration: 'none',
            },
            '& .MuiButtonBase-root': {
                lineHeight: 1,
                color: theme.palette.grey[800],
                backgroundColor: theme.palette.primary.contrastText,
            },
        },
        link: {
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
        },
    }),
)

type TProps = {
    title: string
    location: string | undefined
    testId: string
    ctaText?: string
}

const QuickAction: React.FC<TProps> = ({ title, location, testId, ctaText }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <article className={classes.card}>
            <Typography variant="h3">{t(title)}</Typography>
            {location && (
                <Link to={location} data-testid={testId} className={classes.link}>
                    <Button variant="contained" size="small" component="span" endIcon={<KeyboardArrowRightIcon />}>
                        {ctaText || t('quickAction.cta')}
                    </Button>
                </Link>
            )}
        </article>
    )
}

export default QuickAction
