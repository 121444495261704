import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import Button from '_atoms/buttons/Button'
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons'
import Dash from '_atoms/Dash'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
    createStyles({
        filterStatusOption: {
            display: 'flex',
            flexDirection: 'column',
            width: theme.space(65),
        },
        footer: {
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'end',
        },
        hr: {
            width: '100%',
            margin: theme.space(2, 0),
        },
    }),
)

type CheckboxListFilterProps = {
    options: string[]
    filteredOptions?: string[]
    applyFilter?: (customers: string[]) => void // Injected by TableFilter component
}

const CheckboxListFilter: React.FC<CheckboxListFilterProps> = ({ options, filteredOptions = [], applyFilter }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [selectedOptions, setSelectedOptions] = useState<string[]>(filteredOptions)

    function toggleCheckboxClick(value: string) {
        if (selectedOptions.includes(value)) {
            setSelectedOptions(selectedOptions.filter((option) => option != value))
        } else {
            setSelectedOptions([...selectedOptions, value])
        }
    }

    function toggleApply() {
        applyFilter!(selectedOptions)
    }

    function resetAndApply() {
        if (filteredOptions.length) applyFilter!([])
        else setSelectedOptions([])
    }

    return (
        <div className={classes.filterStatusOption}>
            {options.map((option) => (
                <BasicCheckbox
                    key={option}
                    label={option}
                    setValue={() => toggleCheckboxClick(option)}
                    checked={selectedOptions.includes(option)}
                    dataTestId={`${option}Filter`}
                />
            ))}
            <>
                <Dash className={classes.hr} />
                <div className={classes.footer}>
                    {selectedOptions.length > 0 && (
                        <Button variant="ghost" size="small" onClick={resetAndApply} endIcon={faCircleXmark}>
                            {t('global.erase')}
                        </Button>
                    )}
                    <Button size="small" onClick={toggleApply} data-testid="applyFilterButton">
                        {t('global.apply')}
                    </Button>
                </div>
            </>
        </div>
    )
}

export default CheckboxListFilter
