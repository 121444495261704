import * as React from 'react'
import { createStyles, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BatchStatus, IBatch, batchPickingStatuses, batchPackingStatuses } from 'interfaces/Batch.interface'
import WhiteAndBluePartsCard from '_molecules/WhiteAndBluePartsCard'
import UrgentChip from './UrgentChip'
import Text from '_atoms/text/Text'
import BatchCardStatus from './BatchCardStatus'
import { returnPluralPath } from 'utils/stringConvertion'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import { PACKING_ROUTE, PICKING_ROUTE } from 'utils/routes/frontend'
import PickPackProgress from './PickPackProgress'
import { PickPackIssueStatus } from 'interfaces/PickPackIssue.interface'

dayjs.extend(minMax)

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        headerLine: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        content: {
            color: theme.palette.neutral[800],
            display: 'flex',
            alignItems: 'center',
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    }),
)

const BatchCard: React.FC<IBatch> = ({
    idPerWarehouse,
    status,
    isUrgent,
    batchType,
    orderType,
    orders,
    pickedUniqueProducts,
}) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const withContent = !(isMobile && status === BatchStatus.FINISHED)
    const withUrgentChip = isUrgent && status !== BatchStatus.FINISHED

    const ordersCount = orders.length
    const productsCount = orders.reduce(
        (productReferenceCount, order) =>
            productReferenceCount +
            order.productReferences.reduce(
                (quantityCount, productReference) => quantityCount + productReference.quantity,
                0,
            ),
        0,
    )

    const hasPickPackIssue =
        orders
            .flatMap((order) => order.pickPackIssues)
            .filter((pickPackIssue) => pickPackIssue.status !== PickPackIssueStatus.TERMINATED).length > 0
    const shippingDates = orders.map((order) => order.shipments!.map((shipment) => dayjs(shipment.shippingDate))).flat()
    const shippingDate =
        shippingDates.length > 1 ? dayjs.min(shippingDates).format('DD/MM') : dayjs(shippingDates[0]).format('DD/MM')

    const orderTypeT = t(`new_orders.pickPack.orderTypeOnBatchCard.${orderType}`)
    const batchTypeT = t(`new_orders.pickPack.batchCard.batchType.${batchType}`)
    const productsCountT = returnPluralPath(t, 'new_orders.pickPack.batchCard.product', productsCount)
    const productsOrderT = returnPluralPath(t, 'new_orders.pickPack.batchCard.order', ordersCount)

    function onBatchCardClick() {
        if (batchPickingStatuses.includes(status)) {
            navigate(PICKING_ROUTE(idPerWarehouse))
        } else if (batchPackingStatuses.includes(status)) {
            navigate(PACKING_ROUTE(idPerWarehouse))
        }
    }

    return (
        <WhiteAndBluePartsCard
            onClick={status === BatchStatus.FINISHED ? undefined : onBatchCardClick}
            dataTestId="batchCard"
            blueClass={classes.content}
            whiteClass={classes.header}
        >
            <>
                <div className={classes.headerLine}>
                    <div className={classes.title}>
                        {withUrgentChip && <UrgentChip />}
                        <Text variant="S2">{`${t('new_orders.pickPack.batch')} ${idPerWarehouse}`}</Text>
                    </div>
                    <BatchCardStatus status={status} hasIssue={hasPickPackIssue} />
                </div>
                <PickPackProgress
                    productsCount={productsCount}
                    status={status}
                    pickedUniqueProducts={pickedUniqueProducts}
                    orders={orders}
                />
            </>
            {withContent && (
                <Text variant="P5">{`${batchTypeT} • ${orderTypeT} • ${shippingDate} • ${productsCountT} • ${productsOrderT}`}</Text>
            )}
        </WhiteAndBluePartsCard>
    )
}

export default BatchCard
