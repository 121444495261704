import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { IN_STORE_RECEPTIONIST } from 'utils/roles'
import useKeycloakStore from 'utils/store/useKeycloak.store'
import { KeycloakUserState } from 'utils/store/keycloak.store'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import IconsBackground from '_molecules/backgrounds/IconsBackground'
import { faGear, faGrid } from '@fortawesome/pro-regular-svg-icons'
import FloatingTabSystem from '_organisms/FloatingTabSystem'
import SellerHP from './roles/SellerHP'
import OperatorHP from './roles/OperatorHP'
import CatalogConfiguration from './tabs/CatalogConfiguration'
import SecondHandHP from './roles/SecondHandHP'
import useSelectedCustomersStore from '../../utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from '../../utils/store/selectedCustomers.store'

const useStyles = makeStyles(() =>
    createStyles({
        iconsBackground: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
        },
        tabContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    }),
)

const Home: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [keycloakUser, hasOneOfRoles] = useKeycloakStore((state: KeycloakUserState) => [
        state.keycloakUser,
        state.hasOneOfRoles,
    ])

    const [shouldDisplaySecondHandElements] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.shouldDisplaySecondHandElements,
    ])

    return (
        <Page
            title={`${t('homepage.title')} ${keycloakUser.fullName}`}
            tagline={t('homepage.tagline')}
            headerStyle={HeaderStyleEnum.HOMEPAGE}
            withDivider={false}
        >
            <IconsBackground className={classes.iconsBackground}>
                <FloatingTabSystem
                    tabAttributes={[
                        { label: t('homepage.tabs.preview'), startIcon: faGrid },
                        { label: t('homepage.tabs.configuration'), startIcon: faGear },
                    ]}
                >
                    <div className={classes.tabContent}>
                        {/*{user.customers[0]?.secondHand ? (*/}
                        {/*    <>{hasOneOfRoles([IN_STORE_RECEPTIONIST, ...OPERATOR_ROLES]) && <SecondHandHP />}</>*/}
                        {/*) : (*/}
                        {/*    <>*/}
                        {/*        {hasOneOfRoles([IN_STORE_RECEPTIONIST]) && <SellerHP />}*/}
                        {/*        {hasOneOfRoles(OPERATOR_ROLES) && <OperatorHP />}*/}
                        {/*    </>*/}
                        {/*)}*/}
                        {shouldDisplaySecondHandElements() ? (
                            <SecondHandHP />
                        ) : (
                            <>{hasOneOfRoles([IN_STORE_RECEPTIONIST]) ? <SellerHP /> : <OperatorHP />}</>
                        )}
                    </div>
                    <div className={classes.tabContent}>
                        <CatalogConfiguration />
                    </div>
                </FloatingTabSystem>
            </IconsBackground>
        </Page>
    )
}

export default Home
