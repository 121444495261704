import React, { useEffect, useState } from 'react'
import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { ReturnItem } from 'interfaces/ReturnItem.interface'
import { useTranslation } from 'react-i18next'
import { PickerLabel, SimpleLabel } from '_atoms/text/Labels'
import { downloadFiles } from 'utils/files'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        productDescriptionContainer: {
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
        },
        roundedBox: {
            borderRadius: '4px',
            padding: theme.spacing(1),
            margin: '0 auto',
            border: '1px solid black',
            marginBottom: theme.spacing(1),
        },
        productReferenceImage: {
            paddingRight: '3px',
            '& img': {
                display: 'block',
                height: '200px',
                width: '200px',
                objectFit: 'contain',
            },
        },
        dataTitle: {
            padding: 0,
            fontSize: '15px',
            color: '#6A7590',
            fontWeight: 500,
        },
        dataContent: {
            padding: 0,
            fontSize: '12px',
            color: '#2A2F3A',
            fontWeight: 500,
        },
        picturesContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(12),
        },
        picture: {
            padding: '0 5px',
            '& img': {
                display: 'block',
                height: '200px',
                width: '200px',
                objectFit: 'contain',
            },
        },
    }),
)

type PrintableUniqueProductOverviewProps = {
    returnItem: ReturnItem
}

const PrintableUniqueProductOverview: React.FC<PrintableUniqueProductOverviewProps> = ({ returnItem }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [uniqueProductImages, setUniqueProductImages] = useState<string[]>([])

    const { productIssues, comment, files, uniqueProduct } = returnItem
    const { barcodeUid, productReference, status } = uniqueProduct
    const productName = productReference?.name

    const fetchImages = async () => {
        const imagesData = await downloadFiles(files)
        setUniqueProductImages(imagesData || [])
    }

    useEffect(() => {
        fetchImages()
        // eslint-disable-next-line
    }, [])

    return (
        <div style={{ marginBottom: '30px' }}>
            <Box className={classes.roundedBox}>
                <Grid className={classes.productDescriptionContainer} container>
                    <Grid item className={classes.productReferenceImage}>
                        {productReference?.image && <img src={productReference?.image} alt="product" />}
                    </Grid>
                    <Grid item>
                        <PickerLabel className={classes.dataTitle}>
                            {t('returns.uniqueProductHeaders.name')}
                        </PickerLabel>
                        <SimpleLabel className={classes.dataContent}>{productName}</SimpleLabel>
                        <PickerLabel className={classes.dataTitle}>
                            {t('returns.uniqueProductHeaders.PUID')}
                        </PickerLabel>
                        <SimpleLabel className={classes.dataContent}>{barcodeUid}</SimpleLabel>
                        <PickerLabel className={classes.dataTitle}>{t('returns.issues')}</PickerLabel>
                        <SimpleLabel className={classes.dataContent}>
                            {productIssues?.map((issue) => issue.name).join(', ') || 'N / A'}
                        </SimpleLabel>
                        <PickerLabel className={classes.dataTitle}>{t('returns.printable.status')}</PickerLabel>
                        <SimpleLabel className={classes.dataContent}>{t(`uniqueProductStatus.${status}`)}</SimpleLabel>
                        {comment && (
                            <>
                                <PickerLabel className={classes.dataTitle}>
                                    {t('returns.printable.description')}
                                </PickerLabel>
                                <SimpleLabel className={classes.dataContent}>{comment || ''}</SimpleLabel>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <div className={classes.picturesContainer}>
                {uniqueProductImages?.map((url, index) => (
                    <Grid className={classes.picture} key={index} item>
                        <img src={url} alt="product" />
                    </Grid>
                ))}
            </div>
            <div className="page-break" />
        </div>
    )
}

export default PrintableUniqueProductOverview
