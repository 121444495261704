export const RMS_BIRTH = 2019
export const imgExtensions = [
    '.jpg',
    '.jpeg',
    '.jpe',
    '.jif',
    '.jfif',
    '.jfi',
    '.png',
    '.webp',
    '.tiff',
    '.tif',
    '.gif',
    '.bmp',
    '.dib',
    '.heif',
    '.heic',
]
