import { useState, useEffect } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, IconButton, makeStyles, Theme, useTheme } from '@material-ui/core'
import { IParcel, ParcelStatus, UniqueProductStatusDisplay } from 'interfaces'
import { TailSpin } from 'react-loader-spinner'
import { SectionTitle } from '_atoms/text/SectionTitle'
import ParcelSummarySection from './ParcelSummarySection'
import ParcelCommentsSection from './ParcelCommentsSection'
import PackagingSection from './PackagingSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/pro-light-svg-icons'
import S3SwipeableCarousel from '_organisms/images/carousel/S3SwipeableCarousel'
import Table from '_organisms/tables/Table'
import DialogCarousel from '_organisms/images/carousel/DialogCarousel'
import Status from '_atoms/badges/Status'
import PrintableReturnedParcelPage from './printable/PrintableReturnedParcelPage'
import Page from '_organisms/Page'
import Button from '_atoms/buttons/Button'
import { QueryState, useApi } from 'utils/hooks/useApi.hook'
import { toast } from 'utils/toast'
import { RETURNED_PARCEL_URL } from 'utils/routes/backend'
import { useParams } from 'react-router-dom'
import { useExportPdf } from 'utils/hooks/useExportPdf'
import { faPrint } from '@fortawesome/pro-regular-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionContainer: {
            justifyContent: 'center',
        },
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        pictureIcon: {
            fontSize: theme.typography.pxToRem(30),
        },
        uniqueProductName: {
            color: '#14223D',
            opacity: '70%',
            margin: '0',
        },
        status: {
            maxWidth: theme.space(36),
        },
    }),
)

const ReturnedParcel: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const { parcelId } = useParams()

    const { result: parcel, error: userInfoError, state: shipmentQueryState } = useApi(RETURNED_PARCEL_URL(parcelId))
    const { printableContent } = useExportPdf(() => setIsPriting(false))

    const [uniqueProductPicturesToDisplay, setUniqueProductPicturesToDisplay] = useState<any[] | null>(null)
    const [isPrinting, setIsPriting] = useState<boolean>(false)

    useEffect(() => {
        if (userInfoError) toast.error(t('takebacks.loadParcelError', { message: userInfoError.message }))
        // eslint-disable-next-line
    }, [userInfoError])

    const isLoading = shipmentQueryState === QueryState.LOADING
    const customerName = parcel?.shipment?.renter?.customer?.name || ''
    const relatedOrder = parcel?.shipment?.order?.omsOrderId || 'N/A'
    const arrivedAt = parcel?.statusHistory?.find((history: any) => history.status === ParcelStatus.ARRIVED)?.createdAt
    const arriveProcessedStatusHistory = parcel?.statusHistory?.find(
        (satusHistory: any) => satusHistory.status === ParcelStatus.PROCESSED,
    )
    const processingDate = arriveProcessedStatusHistory?.createdAt

    const uniqueProductHeaders = [
        {
            label: (
                <span key={1} style={{ padding: '0' }}>{`${t('returns.uniqueProductHeaders.PUID')} / ${t(
                    'returns.uniqueProductHeaders.name',
                )}`}</span>
            ),
        },
        { label: t('returns.uniqueProductHeaders.status') },
        { label: t('returns.issues') },
        { label: t('returns.uniqueProductHeaders.comment') },
        { label: t('returns.uniqueProductHeaders.pictures') },
    ]

    function uniqueProductsToRows(parcel: IParcel): any {
        return parcel?.returnItems?.map((returnItem, index) => {
            const { status, productIssues, comment, files, uniqueProduct } = returnItem
            const { barcodeUid, productReference } = uniqueProduct

            const barcodeUidWithName = (
                <>
                    {barcodeUid}
                    <p className={classes.uniqueProductName}>{productReference?.name}</p>
                </>
            )

            return {
                cells: [
                    { value: barcodeUidWithName },
                    {
                        value: (
                            <Status
                                key={index}
                                color={UniqueProductStatusDisplay[status]}
                                label={t(`uniqueProductStatus.${status}`)}
                                className={classes.status}
                            />
                        ),
                    },
                    { value: productIssues.map((issue) => issue.name).join(',') },
                    { value: comment },
                    {
                        value:
                            files?.length > 0 ? (
                                <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                                    <IconButton key={index} onClick={() => setUniqueProductPicturesToDisplay(files)}>
                                        <FontAwesomeIcon icon={faImage} className={classes.pictureIcon} />
                                    </IconButton>
                                    {files?.length > 1 ? `+${files.length - 1}` : ''}
                                </div>
                            ) : (
                                ''
                            ),
                    },
                ],
            }
        })
    }

    function relatedParcels(parcel: IParcel) {
        return parcel?.shipment?.parcels?.filter((relatedParcel) => relatedParcel.barcode !== parcel?.barcode) || []
    }

    const renderExportButton = () => (
        <Button label={t('returns.printable.exportPdf')} startIcon={faPrint} onClick={() => setIsPriting(true)}>
            {isPrinting && <TailSpin wrapperStyle={{ marginLeft: '10px' }} color="#FFF" width="20" height="20" />}
        </Button>
    )

    if (isLoading)
        return (
            <div style={{ position: 'absolute', top: '35%', left: '58%' }}>
                <TailSpin visible color={theme.palette.primary.main} height={90} width={90} />
            </div>
        )

    return (
        <Page
            section={t('menu.returns.returnedParcelSection')}
            title={`${t('menu.returns.parcel')} ${parcel?.barcode}`}
            rightAddon={{ addon: renderExportButton(), displayOnMobile: false }}
        >
            <ParcelSummarySection
                renterEmail={parcel?.shipment?.renter?.email}
                parcelInfos={{
                    customerName,
                    relatedOrder,
                    carrier: parcel?.shipment?.carrier?.name,
                    barcode: parcel?.barcode,
                    trackingCode: parcel?.trackingCode,
                    trackingLink: parcel?.trackingLink,
                    pdfUrl: parcel?.pdfUrl,
                    relatedParcels: relatedParcels(parcel),
                }}
                shipmentDates={{
                    expectedShippingDate: parcel?.shipment?.shippingDate,
                    shippedAt: parcel?.shippedAt,
                    arrivedAt: arrivedAt,
                    processedAt: processingDate,
                }}
                rightOfWithdrawal={parcel?.shipment?.rightOfWithdrawal}
            />
            <div className={classes.sectionContainer}>
                <SectionTitle className={classes.sectionTitle} size="large">
                    {t('returns.products')}
                </SectionTitle>
                <Table header={uniqueProductHeaders} rows={uniqueProductsToRows(parcel)} />
            </div>
            <Grid container className={classes.sectionContainer}>
                <ParcelCommentsSection parcel={parcel} order={parcel?.shipment?.order} />
                <PackagingSection packaging={parcel?.returnPackaging} />
            </Grid>
            <DialogCarousel
                title={'uniqueProductIssues'}
                isOpen={!!uniqueProductPicturesToDisplay?.length}
                handleClose={() => setUniqueProductPicturesToDisplay(null)}
            >
                <S3SwipeableCarousel
                    files={uniqueProductPicturesToDisplay}
                    handleFetchFailure={() => setUniqueProductPicturesToDisplay(null)}
                />
            </DialogCarousel>
            {isPrinting && printableContent(<PrintableReturnedParcelPage parcel={parcel} />)}
        </Page>
    )
}

export default ReturnedParcel
