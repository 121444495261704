import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { StepHandlerType } from '_organisms/Stepper'
import { IProductReferenceWithLabelReference, IUniqueProduct } from 'interfaces'
import { faArrowRight, faTrash, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import { RejectSecondHandReason } from 'interfaces/SecondHand.interface'
import Page from '_organisms/Page'
import Divider from '_atoms/Divider'
import Text from '_atoms/text/Text'
import SearchReference from './SearchReference'
import SecondHandProductCard from './SecondHandProductCard'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { UNIQUE_PRODUCT_UPDATE_URL } from 'utils/routes/backend'
import Button from '_atoms/buttons/Button'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            [theme.breakpoints.up('sm')]: {
                padding: theme.space(0, 12),
            },
        },
        header: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.space(0, 3),
            },
        },
        title: {
            color: theme.palette.neutral[700],
        },
        tagline: {
            color: theme.palette.neutral[600],
            margin: theme.space(1, 0, 4),
        },
        uniqueProductCard: {
            padding: theme.space(0, 13),
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: `${theme.space(2)}px`,
            '& > *': {
                flexGrow: 1,
            },
        },
        submitButton: {
            [theme.breakpoints.up('md')]: {
                marginLeft: 'auto',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            marginTop: theme.space(3.2),
        },
        divider: {
            margin: `${theme.space(4)}px 0px`,
        },
        productCard: {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                boxShadow: 'none',
            },
        },
        refuse: {
            backgroundColor: theme.palette.common.white,
            [theme.breakpoints.up('md')]: {
                border: `1px solid ${theme.palette.neutral[300]}`,
            },
            '&:hover:enabled': {
                backgroundColor: theme.palette.common.white,
            },
        },
        wrongReference: {
            background: theme.palette.common.white,
            '&:hover:enabled': {
                backgroundColor: theme.palette.common.white,
            },
        },
    }),
)

type CheckReferenceStepProps = {
    selectedUniqueProduct: IUniqueProduct
    setSelectedUniqueProduct: (uniqueProduct: IUniqueProduct) => void
    handleRefuse: (reason: RejectSecondHandReason) => void
    handleSteps?: StepHandlerType
}

const CheckReferenceStep: React.FC<CheckReferenceStepProps> = ({
    selectedUniqueProduct,
    setSelectedUniqueProduct,
    handleSteps,
    handleRefuse,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const continueButtonRef = useRef<HTMLButtonElement>(null)
    const prismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.qualities)

    const [isWrongReference, setIsWrongReference] = useState<boolean>(false)
    const [newProductReference, setNewProductReference] = useState<IProductReferenceWithLabelReference>()

    useEffect(() => {
        continueButtonRef.current!.focus()
    }, [])

    async function validateProductReference() {
        try {
            const response = await rmsApi.patch(UNIQUE_PRODUCT_UPDATE_URL(selectedUniqueProduct.id), {
                productReferenceId: newProductReference!.id,
            })
            setSelectedUniqueProduct(response.data)
            handleSteps?.nextStep()
        } catch (e: any) {
            toast.error(t('secondHand.checkReference.updateReferenceError'))
        }
    }

    const renderButtons = () => (
        <div className={classes.buttonContainer}>
            <Button
                variant="stroke"
                color="neutral"
                size={isMobile ? 'medium' : 'large'}
                onClick={() => setIsWrongReference(true)}
                startIcon={faTriangleExclamation}
                className={classes.wrongReference}
                data-testid="wrong-reference-button"
                label={t('secondHand.checkReference.wrongReference')}
            />
            <Button
                size={isMobile ? 'medium' : 'large'}
                onClick={() => handleSteps?.nextStep()}
                endIcon={faArrowRight}
                ref={continueButtonRef}
                data-testid="checkRef-next-button"
                label={t('secondHand.checkReference.next')}
            />
        </div>
    )

    const renderWrongReferenceButtons = () => (
        <div className={classes.buttonContainer}>
            <Button
                variant="ghost"
                color="error"
                size={isMobile ? 'medium' : 'large'}
                startIcon={faTrash}
                className={classes.refuse}
                onClick={() => handleRefuse(RejectSecondHandReason.WRONG_REFERENCE)}
                data-testid="refuse-button"
                label={t('secondHand.checkReference.refuseProduct')}
            />
            <Button
                size={isMobile ? 'medium' : 'large'}
                onClick={validateProductReference}
                endIcon={faArrowRight}
                disabled={isWrongReference && !newProductReference}
                ref={continueButtonRef}
                data-testid="checkRef-next-button"
                label={t('secondHand.checkReference.validateReference')}
            />
        </div>
    )

    const { quality, productReference } = selectedUniqueProduct
    const { name, product, image, size } = newProductReference || productReference
    const reference = product!.labelReference

    return (
        <Page
            title={t('secondHand.checkReference.title')}
            section={t('secondHand.secondHandSection')}
            rightAddon={{ addon: handleSteps?.getCounter(), displayOnMobile: true }}
            handlePrevious={() => handleSteps?.previousStep()}
        >
            <BackgroundWithScanAndFooter
                footer={isWrongReference ? renderWrongReferenceButtons() : renderButtons()}
                footerInBoxMobile
            >
                <div className={classes.header}>
                    <Text variant="S1" className={classes.title}>
                        {t('secondHand.checkReference.question')}
                    </Text>
                    <Text variant="P5" className={classes.tagline}>
                        {t('secondHand.checkReference.content')}
                    </Text>
                </div>
                {isMobile && <Divider spaceSize={'medium'} className={classes.divider} dashed />}
                <div className={classes.content}>
                    {isWrongReference && <SearchReference updateProductReference={setNewProductReference} />}
                    <SecondHandProductCard
                        className={classes.productCard}
                        name={name}
                        reference={reference}
                        quality={quality}
                        prismicQualities={prismicQualities}
                        size={size}
                        image={image!}
                    />
                </div>
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default CheckReferenceStep
