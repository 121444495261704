import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Text from '_atoms/text/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faWarning } from '@fortawesome/pro-solid-svg-icons'
import { BatchStatus } from 'interfaces/Batch.interface'

type StyleProps = {
    isBatchFinished: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        root: {
            color: ({ isBatchFinished }) => (isBatchFinished ? theme.palette.success[700] : theme.palette.primary[600]),
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.space(4)}px`,
            svg: {
                fontSize: theme.typography.pxToRem(13),
            },
        },
        warning: {
            color: theme.palette.error[600],
        },
    }),
)

type BatchCardStatusProps = {
    status: BatchStatus
    hasIssue?: boolean
}

const BatchCardStatus: React.FC<BatchCardStatusProps> = ({ status, hasIssue }) => {
    const { t } = useTranslation()
    const isBatchFinished = status === BatchStatus.FINISHED
    const classes = useStyles({ isBatchFinished })

    return (
        <div className={classes.root}>
            <Text variant="S2">{t(`new_orders.pickPack.batchStatus.${status}`)}</Text>
            {isBatchFinished && <FontAwesomeIcon icon={faCheck} />}
            {hasIssue && (
                <FontAwesomeIcon className={classes.warning} icon={faWarning} data-testid="anomaly-warning-icon" />
            )}
        </div>
    )
}

export default BatchCardStatus
