import { SyntheticEvent, useEffect, useState } from 'react'
import * as React from 'react'
import { Calendar, dateFnsLocalizer, View } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import frFR from 'date-fns/locale/fr'
import { CalendarDetails, CalendarEvent } from 'interfaces'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import EventModal from './reusable/EventModal'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { CALENDAR_EVENTS_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import i18n from 'i18next'
import rmsApi from 'utils/api'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        calendar: {
            '& .rbc-agenda-event-cell': {
                cursor: 'pointer',
                color: theme.palette.common.white,
                transition: theme.transitions.create('box-shadow'),
                boxShadow: '0px 6px 15px 0px rgba(0,0,0, 0.001)',
                '&:hover, &:focus': {
                    boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)',
                },
            },
            '& .rbc-agenda-date-cell, & .rbc-agenda-time-cell': {
                color: theme.palette.common.white,
            },
            '& .rbc-event': {
                border: 0,
                transition: theme.transitions.create('box-shadow'),
                boxShadow: '0px 6px 15px 0px rgba(0,0,0, 0.001)',
                '&:hover, &:focus': {
                    boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)',
                },
            },
        },
    }),
)

const locales = {
    'fr-FR': frFR,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const frenchMessages = {
    allDay: 'Journée',
    previous: 'Précédent',
    next: 'Suivant',
    today: "Aujourd'hui",
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Événement',
    showMore: (total: number) => `+ ${total} événement(s) supplémentaire(s)`,
}

const today = new Date()
const min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 7)
const max = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 21)

const DESKTOP_VIEWS: View[] = ['month', 'week', 'day', 'agenda']
const MOBILE_VIEWS: View[] = ['day', 'agenda']

type AppCalendarProps = {
    events: CalendarEvent[]
}

const AppCalendar: React.FC<AppCalendarProps> = ({ events }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null)
    const [eventDetailsArray, setEventDetailsArray] = useState<CalendarDetails[] | null>(null)
    const [view, setView] = useState<View>()
    const [views, setViews] = useState<View[]>()

    const culture = i18n.language === 'fr' ? 'fr-FR' : 'en-US'
    const messages = i18n.language === 'fr' ? frenchMessages : {} // if the object is empty, it uses english messages by default

    useEffect(() => {
        if (isMobile) {
            setView('agenda')
            setViews(MOBILE_VIEWS)
        } else {
            setView('week')
            setViews(DESKTOP_VIEWS)
        }
    }, [isMobile])

    const handleViewChange = (view: View) => {
        setView(view)
    }

    const onSelectEvent = async (event: CalendarEvent, e: SyntheticEvent) => {
        setSelectedEvent(event)
        const isFetched = await fetchEventDetails(event)
        if (isFetched) {
            setIsModalOpen(true)
        }
    }

    const onModalClose = () => {
        setIsModalOpen(false)
        setSelectedEvent(null)
        setEventDetailsArray(null)
    }

    async function fetchEventDetails(event: CalendarEvent) {
        try {
            const fetchEventDetailsRequest = await rmsApi.get(`${CALENDAR_EVENTS_URL}/${event.uuid}`)
            const eventDetailsArray = fetchEventDetailsRequest.data

            setEventDetailsArray(eventDetailsArray)
            return true
        } catch (e: any) {
            if (e.response?.data) {
                toast.error(e.response.data.message)
            } else {
                toast.error(e.message)
            }
        }
    }

    return (
        <>
            <Calendar
                localizer={localizer}
                className={classes.calendar}
                culture={culture}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '80vh' }}
                view={view}
                onView={handleViewChange}
                views={views}
                messages={messages}
                min={min}
                max={max}
                onSelectEvent={onSelectEvent}
                eventPropGetter={(event, start, end, isSelected) => ({
                    event,
                    start,
                    end,
                    isSelected,
                    style: {
                        backgroundColor:
                            event.type === 'withdrawal' ? theme.palette.primary.main : theme.palette.secondary.main,
                    },
                })}
            />
            {selectedEvent && (
                <EventModal
                    title={selectedEvent.title}
                    description={
                        selectedEvent.type === 'withdrawal'
                            ? t('orders.appointments.withdrawal')
                            : t('orders.appointments.return')
                    }
                    isOpen={isModalOpen}
                    onClose={onModalClose}
                    event={selectedEvent}
                    eventDetailsArray={eventDetailsArray}
                />
            )}
        </>
    )
}

export default AppCalendar
