import * as React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        H1: {
            fontWeight: 600,
            fontSize: '36px',
            lineHeight: '54px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '22px',
                lineHeight: '33px',
            },
        },
        H2: {
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: '48px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                lineHeight: '30px',
            },
        },
        H3: {
            fontWeight: 600,
            fontSize: '30px',
            lineHeight: '45px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '24px',
            },
        },
        H4: {
            fontWeight: 600,
            fontSize: '26px',
            lineHeight: '39px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '21px',
            },
        },
        H5: {
            fontWeight: 500,
            fontSize: '22px',
            lineHeight: '33px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '18px',
            },
        },
        H6: {
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '27px',
            [theme.breakpoints.down('sm')]: {},
        },
        S1: { fontWeight: 500, fontSize: '15px', lineHeight: '22px', [theme.breakpoints.down('sm')]: {} },
        S1U: {
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '22px',
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {},
        },
        S2: {
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '16px',
            },
        },
        S2U: {
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '20px',
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {},
        },
        S3: { fontWeight: 400, fontSize: '11px', lineHeight: '16px', [theme.breakpoints.down('sm')]: {} },
        S3U: {
            fontWeight: 400,
            fontSize: '11px',
            lineHeight: '16px',
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {},
        },
        P1: {
            fontWeight: 300,
            fontSize: '22px',
            lineHeight: '30px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                lineHeight: '26px',
            },
        },
        P2: {
            fontWeight: 300,
            fontSize: '18px',
            lineHeight: '26px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '24px',
            },
        },
        P3: {
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '24px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '22px',
            },
        },
        P4: {
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '22px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '20px',
            },
        },
        P5: {
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '19px',
            },
        },
        P6: {
            fontWeight: 300,
            fontSize: '10px',
            lineHeight: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '19px',
            },
        },
        N1: {
            fontFamily: 'Oswald',
            fontWeight: 500,
            fontSize: '36px',
            lineHeight: '53px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                lineHeight: '30px',
            },
        },
        N2: {
            fontFamily: 'Oswald',
            fontWeight: 500,
            fontSize: '30px',
            lineHeight: '44px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: { fontSize: '18px', lineHeight: '27px' },
        },
        N3: {
            fontFamily: 'Oswald',
            fontWeight: 500,
            fontSize: '26px',
            lineHeight: '39px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '24px',
            },
        },
        N4: {
            fontFamily: 'Oswald',
            fontWeight: 300,
            fontSize: '22px',
            lineHeight: '33px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '21px',
            },
        },
        N5: {
            fontFamily: 'Oswald',
            fontWeight: 300,
            fontSize: '18px',
            lineHeight: '27px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '18px',
            },
        },
        N6: {
            fontFamily: 'Oswald',
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '16px',
            },
        },
        N7: {
            fontFamily: 'Oswald',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '16px',
            },
        },
        N8: {
            fontFamily: 'Oswald',
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '16px',
            },
        },
        N9: {
            fontFamily: 'Oswald',
            fontWeight: 300,
            fontSize: '11px',
            lineHeight: '16px',
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '16px',
            },
        },
    }),
)

export type VariantType =
    | 'H1'
    | 'H2'
    | 'H3'
    | 'H4'
    | 'H5'
    | 'H6'
    | 'S1'
    | 'S1U'
    | 'S2'
    | 'S2U'
    | 'S3'
    | 'S3U'
    | 'P1'
    | 'P2'
    | 'P3'
    | 'P4'
    | 'P5'
    | 'P6'
    | 'N1'
    | 'N3'
    | 'N4'
    | 'N5'
    | 'N6'
    | 'N7'
    | 'N8'
    | 'N9'

export enum VariantEnum {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
    H6 = 'H6',
    S1 = 'S1',
    S1U = 'S1U',
    S2 = 'S2',
    S2U = 'S2U',
    S3 = 'S3',
    S3U = 'S3U',
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    P4 = 'P4',
    P5 = 'P5',
    P6 = 'P6',
    N1 = 'N1',
    N3 = 'N3',
    N4 = 'N4',
    N5 = 'N5',
    N6 = 'N6',
    N7 = 'N7',
    N8 = 'N8',
    N9 = 'N9',
}

type TextProps = {
    variant: VariantType
    color?: string
    children?: React.ReactNode
    className?: string
    [props: string]: any
}

const Text: React.FC<TextProps> = ({ variant, color, className = '', children, ...props }) => {
    const classes = useStyles()
    return (
        <Typography className={`${classes[variant]} ${className}`} style={{ color }} {...props}>
            {children}
        </Typography>
    )
}

export default Text
