import * as React from 'react'
import { useCallback } from 'react'
import { Box, createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SectionTitle } from '_atoms/text/SectionTitle'
import Tagline from '_atoms/text/Tagline'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { PickerLabel, SimpleLabel } from '_atoms/text/Labels'
import { RMSFile } from 'utils/files'
import { IUniqueProduct } from 'interfaces'
import ThumbnailGrid from '_organisms/images/shooting/ThumbnailGrid'
import { debounce } from 'lodash'
import { StepHandlerType } from '_organisms/Stepper'
import UniqueProductSecondHandQualityDetails from '_organisms/uniqueProduct/UniqueProductSecondHandQualityDetails'
import { HeaderProps } from '_organisms/header/Header'
import Page from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        intro: {
            marginLeft: theme.spacing(1),
        },
        sectionTitle: {
            fontSize: '15px',
        },
        subSectionTitle: {
            textAlign: 'left',
            margin: `16px 0 8px 0`,
            fontSize: '13px',
        },
        tagline: {
            paddingBottom: theme.spacing(3),
        },
        roundedBox: {
            borderRadius: '10px',
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.neutral[100],
            },
            [theme.breakpoints.up('sm')]: {
                border: `solid 1px ${theme.palette.neutral[200]}`,
            },
        },
        boxTitle: {
            padding: 0,
            fontSize: '12px',
            color: '#6A7590',
            fontWeight: 500,
        },
        boxData: {
            padding: 0,
            fontSize: '15px',
            color: '#2A2F3A',
            fontWeight: 500,
        },
        boxDataSmall: {
            padding: 0,
            fontSize: '13px',
            color: '#2A2F3A',
            fontWeight: 500,
        },
        filesContainer: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                overflow: 'auto',
            },
        },
        input: {
            '& textarea': {
                background: 'rgba(235, 239, 251, 0.16)',
                border: '1px solid #F7F7F9',
                borderRadius: '2px',
                padding: '10px 15px',
                marginBottom: theme.spacing(12),
                fontSize: theme.typography.pxToRem(12),
                '&:hover': {
                    border: '1px solid #D6E0F6',
                },
                '&:focus': {
                    border: '1px solid #ADC1ED',
                },
            },
        },
        marginBottom: {
            marginBottom: theme.spacing(2.5),
        },
    }),
)

type ReviewProps = {
    uniqueProduct: IUniqueProduct | undefined
    pictures: RMSFile[]
    updateComment: (comment: string) => void
    setUniqueProduct: (uniqueProduct: IUniqueProduct) => void

    // Injected Stepper props
    handleSteps?: StepHandlerType
    hidden?: boolean
    updateHeader?: (props: HeaderProps) => void
}

const Review: React.FC<ReviewProps> = ({ uniqueProduct, pictures, updateComment, handleSteps, setUniqueProduct }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const returnItems = uniqueProduct?.returnItems
    let existingComment = ''
    if (returnItems) existingComment = returnItems[0]?.comment || ''

    const debouncedCommentUpdate = useCallback(
        debounce((value) => updateComment(value), 600),
        [],
    )

    const renderButton = () => (
        <Button
            label={t('publish.publish')}
            color="success"
            size="large"
            onClick={() => handleSteps?.onSubmit()}
            endIcon={faCheck}
            data-testid="nextStep-button"
        />
    )

    return (
        <Page
            title={t('publish.review.header')}
            handlePrevious={() => handleSteps?.previousStep()}
            rightAddon={{ addon: handleSteps?.getCounter(), displayOnMobile: true }}
        >
            <BackgroundWithScanAndFooter footer={renderButton()}>
                <div className={classes.intro}>
                    <SectionTitle className={classes.sectionTitle}>{t('publish.review.title')}</SectionTitle>
                    <Tagline className={classes.tagline}>{t('publish.review.tagline')}</Tagline>
                </div>
                <UniqueProductSecondHandQualityDetails
                    uniqueProduct={uniqueProduct!}
                    className={classes.marginBottom}
                    setUniqueProduct={setUniqueProduct}
                />
                <Box className={classes.roundedBox}>
                    <PickerLabel className={classes.boxTitle}>{t('publish.review.labelRef')}</PickerLabel>
                    <SimpleLabel className={classes.boxData}>
                        {uniqueProduct?.productReference?.product?.labelReference || ''}
                    </SimpleLabel>
                </Box>
                <Box className={classes.roundedBox}>
                    <PickerLabel className={classes.boxTitle}>{t('publish.review.PUID')}</PickerLabel>
                    <SimpleLabel className={classes.boxData}>{uniqueProduct?.barcodeUid || ''}</SimpleLabel>
                </Box>
                <Box className={classes.roundedBox}>
                    <PickerLabel className={classes.boxTitle}>{t('publish.review.productName')}</PickerLabel>
                    <SimpleLabel className={`${classes.boxData} ${classes.boxDataSmall}`}>
                        {uniqueProduct?.productReference?.name || ''}
                    </SimpleLabel>
                </Box>
                <SectionTitle className={classes.subSectionTitle}>{t('publish.review.photos')}</SectionTitle>
                <ThumbnailGrid pictures={pictures} />
                <SectionTitle className={classes.subSectionTitle}>{t('publish.review.note')}</SectionTitle>
                <TextField
                    defaultValue={existingComment}
                    fullWidth={true}
                    multiline={true}
                    rows={3}
                    onChange={(e: any) => debouncedCommentUpdate(e.target.value)}
                    inputProps={{ maxLength: 500 }}
                    data-testid="commentTextarea-comment"
                    placeholder={t('publish.review.notePlaceHolder')}
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                    }}
                    className={classes.input}
                />
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default Review
