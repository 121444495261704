import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Button,
    ClickAwayListener,
    createStyles,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TableCell,
    TableRow,
    Theme,
    Typography,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ArrowDownBoldCircleIcon from 'mdi-react/ArrowDownBoldCircleIcon'
import { JuneEvent, trackJuneEvent } from '../../../utils/june'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableTitle: {
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.grey[700],
        },
        select: {
            borderRadius: 10,
        },
        btn: {
            display: 'block',
            width: '100%',
            position: 'relative',
            textDecoration: 'none',
            lineHeight: 1.4,
            paddingRight: theme.spacing(4),
            '&:active': {
                color: 'currentColor',
            },
        },
        btnDisabled: {
            pointerEvents: 'none',
            opacity: 0.5,
        },
        btnDate: {
            display: 'block',
            fontSize: theme.typography.pxToRem(12),
            textTransform: 'uppercase',
            color: theme.palette.grey[700],
        },
        btnIcon: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        disabled: {
            opacity: 0.5,
        },
        active: {
            opacity: 1,
            transition: theme.transitions.create('opacity'),
        },
        menu: {
            zIndex: theme.zIndex.drawer,
            marginRight: theme.spacing(1),
        },
    }),
)

type InvoiceRowProps = {
    category: string
    invoices: { url: string; paymentDate: string; reason: string }[] | undefined
}

const InvoiceRow: React.FC<InvoiceRowProps> = ({ category, invoices }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false)
    const [disabled, setDisabled] = React.useState(true)
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
        trackJuneEvent(JuneEvent.INVOICES_DOWNLOADED)
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false)
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus()
        }

        prevOpen.current = open
    }, [open])

    useEffect(() => {
        if (!(invoices && invoices.length > 0)) setDisabled(true)
        else setDisabled(false)
    }, [invoices])

    return (
        <TableRow className={disabled ? classes.disabled : classes.active}>
            <TableCell>
                <Typography component="div" className={classes.tableTitle}>
                    {category}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Button
                    variant="outlined"
                    className={classes.select}
                    disabled={disabled}
                    endIcon={<KeyboardArrowDownIcon />}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    {t('finances.invoices.download.one')}
                </Button>
                <Popper
                    className={classes.menu}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {invoices?.map((invoice, index) => (
                                            <MenuItem key={index}>
                                                <a
                                                    href={invoice.url}
                                                    className={
                                                        invoice.url
                                                            ? classes.btn
                                                            : `${classes.btn} ${classes.btnDisabled}`
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download
                                                >
                                                    <span>
                                                        {index + 1} - {invoice.reason}
                                                    </span>
                                                    <span className={classes.btnDate}>{invoice.paymentDate}</span>
                                                    <ArrowDownBoldCircleIcon className={classes.btnIcon} />
                                                </a>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </TableCell>
        </TableRow>
    )
}

export default InvoiceRow
