import create from 'zustand'
import { Socket, io } from 'socket.io-client'
import accessTokenStore from './accessToken.store'

export interface SocketState {
    socket: Socket | null
    socketConnected: boolean
    connect: () => void
    disconnect: () => void
}

const useSocketStore = create<SocketState>((set, get) => ({
    socket: null,
    socketConnected: false,
    connect: () => {
        const { socketConnected } = get()

        if (!socketConnected) {
            const accessToken = accessTokenStore.getState().accessToken
            const socket = io(import.meta.env.REACT_APP_API_URL, {
                transports: ['websocket'],
                auth: {
                    token: accessToken ? `Bearer ${accessToken}` : '',
                },
            })
            set({ socket, socketConnected: true })
        }
    },
    disconnect: () => {
        const { socket } = get()
        if (socket) {
            socket.disconnect()
            set({ socket: null, socketConnected: false })
        }
    },
}))

export default useSocketStore
