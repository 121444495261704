import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import StepBullet from '_atoms/badges/StepBullet'
import Text from '_atoms/text/Text'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.space(0, 4),
            [theme.breakpoints.down('sm')]: {
                padding: theme.space(3, 5),
            },
        },
        header: {
            marginBottom: theme.space(4),
            '& p:first-child': {
                fontSize: theme.typography.pxToRem(14),
            },
        },
        instructions: {
            paddingTop: theme.space(5),
        },
        text: {
            color: theme.palette.neutral[600],
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
            flex: 1,
        },
        instruction: {
            display: 'flex',
            gap: `${theme.space(3)}px`,
            marginBottom: theme.space(4),
            alignItems: 'center',
        },
    }),
)

type HowItWorksProps = {
    title: string
    description?: string
    instructions: string[]
}

const HowItWorks: React.FC<HowItWorksProps> = ({ title, description, instructions }) => {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <BlueBackgroundHeader title={title} tagline={description} className={classes.header} />
            {instructions?.map((instruction, index) => (
                <div key={index} className={classes.instruction}>
                    <StepBullet step={index + 1} />
                    <Text variant="P4" className={classes.text}>
                        {instruction}
                    </Text>
                </div>
            ))}
        </div>
    )
}

export default HowItWorks
