import * as React from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import ModalWithHeader, { HeaderType } from './ModalWithHeader/ModalWithHeader'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons'
import Text from '_atoms/text/Text'
import ScanInput from './ScanInput'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tagline: {
            color: theme.palette.neutral[600],
            whiteSpace: 'pre-wrap',
            [theme.breakpoints.down('md')]: {
                color: theme.palette.common.white,
                textAlign: 'center',
            },
        },
        scanInput: {
            width: '100%',
            marginTop: theme.space(4),
        },
    }),
)

type AwaitingScanModalProps = {
    title: string
    tagline?: string
    placeholder?: string
    onSubmit: (barcode: string) => void
    onClose: () => void
    className?: string
}

const AwaitingScanModal: React.FC<AwaitingScanModalProps> = ({ title, tagline, placeholder, onSubmit, onClose }) => {
    const theme = useTheme()
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <ModalWithHeader
            title={title}
            width={520}
            handleClose={onClose}
            disableRestoreFocus
            open={true}
            headerIcon={faScannerGun}
            headerIconColor={theme.palette.warning[600]}
            mobileDarkTheme
            headerType={HeaderType.MOBILE}
        >
            <>
                <Text variant="P5" className={classes.tagline}>
                    {tagline}
                </Text>
                <ScanInput
                    invisible={isMobile}
                    placeholder={placeholder}
                    iconStart={faScannerGun}
                    onSubmit={onSubmit}
                    className={classes.scanInput}
                    dataTestId="modal-scan-input"
                />
            </>
        </ModalWithHeader>
    )
}

export default AwaitingScanModal
