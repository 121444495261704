import * as React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react'

import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import './index.css'
import keycloak from './keycloak'
import frTranslation from './translations/fr.json'
import enTranslation from './translations/en.json'
import { datadogRum } from '@datadog/browser-rum'

import { registerLocale } from 'react-datepicker'
//Add locale translations of date picker
import fr from 'date-fns/locale/fr'

declare global {
    interface Window {
        FreshworksWidget?: any
    }
}

registerLocale('fr', fr)

if (window.FreshworksWidget) {
    window.FreshworksWidget('setLabels', {
        en: {
            banner: 'Welcome to the help center',
            launcher: 'Help',
            contact_form: { title: 'Contact us', submit: 'Send', confirmation: 'We’ll get back to you shortly' },
            frustration_tracking: {
                banner: 'How can we help?',
                description:
                    'We noticed you seem to be stuck on this page. Tell us what you were trying to do and our support team will contact you shortly.',
                confirmation: 'Thank you. We keep in touch!',
            },
        },
    })
}

datadogRum.init({
    applicationId: 'aa7e2283-bf05-4bab-bdda-a9b51ab66e46',
    clientToken: 'pubd03541888bafeb2752ee2620f17dc577',
    site: 'datadoghq.eu',
    proxy: 'https://log-collector.lizee.io',
    service: 'rms',
    env: import.meta.env.REACT_APP_BUGSNAG_STAGE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,

    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
})

let ErrorBoundary: any
if (import.meta.env.REACT_APP_BUGSNAG_STAGE !== 'local') {
    Bugsnag.start({
        apiKey: 'b076e8cc5cf292d9a05856f0bb58142f',
        plugins: [new BugsnagPluginReact()],
        releaseStage: import.meta.env.REACT_APP_BUGSNAG_STAGE,
    })
    ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React)
}
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            fr: frTranslation,
            en: enTranslation,
        },
        lng: 'fr',
        fallbackLng: 'fr',

        interpolation: {
            escapeValue: false,
        },
    })

const matomoInstance = createInstance({
    urlBase: 'https://analytics.lizee.io/',
    siteId: 2,
    //disabled: process.env.REACT_APP_BUGSNAG_STAGE !== 'production', // optional, false by default. Makes all tracking calls no-ops if set to true.
})

ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloak}>
        <MatomoProvider value={matomoInstance}>
            {ErrorBoundary ? (
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            ) : (
                <App />
            )}
        </MatomoProvider>
    </ReactKeycloakProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
