import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { EntityType, RMSFile, getSignedUrl, uploadFiles, deleteS3File } from 'utils/files'
import ModalWithHeader, { HeaderType } from '../../_molecules/ModalWithHeader/ModalWithHeader'
import InputField from '_atoms/inputs/InputField'
import { faCamera } from '@fortawesome/pro-regular-svg-icons'
import FileInput from '_atoms/inputs/FileInput'
import { faComment } from '@fortawesome/pro-light-svg-icons'
import { useDisplayFiles } from 'utils/hooks/useDisplayFiles'
import { toast } from 'utils/toast'
import Button from '_atoms/buttons/Button'
import { ReturnItem } from 'interfaces/ReturnItem.interface'
import PictureGrid from '_molecules/pictures/PictureGrid'
import ModalFooter from '_molecules/ModalFooter'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filesContainer: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                overflow: 'auto',
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(3)}px`,
            [theme.breakpoints.down('md')]: {
                padding: theme.space(0, 4),
            },
        },
        fileButton: {
            border: `1px solid ${theme.palette.neutral[300]}`,
        },
        footer: {
            [theme.breakpoints.up('md')]: {
                padding: theme.space(10, 0, 0, 0),
            },
        },
    }),
)

type AddCommentModalProps = {
    isOpen: boolean
    handleClose: () => void
    handleSubmit: (editReturnItem: ReturnItem) => void
    returnItem: ReturnItem
}

const AddCommentModal: React.FC<AddCommentModalProps> = ({ isOpen, handleClose, handleSubmit, returnItem }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    const { files, setFiles } = useDisplayFiles(returnItem?.files as RMSFile[])
    const [comment, setComment] = useState<string>(returnItem?.comment || '')
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true)

    useEffect(() => {
        if (files.length || comment.length) {
            setIsSubmitButtonDisabled(false)
        } else {
            setIsSubmitButtonDisabled(true)
        }
    }, [files, comment])

    const handleFileDelete = async (fileToDelete: RMSFile) => {
        const fileId = fileToDelete.id
        if (fileId) {
            const error = await deleteS3File(fileId)
            if (error) {
                toast.error(t('files.deleteError') + error.message)
                return
            }
        }
        setFiles(files.filter((file) => file.name !== fileToDelete.name))
    }

    const handleFileInput = async (inputFiles: RMSFile[]) => {
        const newFiles = []
        for (const file of inputFiles) {
            const newFile = await getSignedUrl(file, EntityType.RETURN_ITEM_OR_RETURN_PACKAGING)
            newFiles.push(newFile)
        }
        setFiles([...files, ...newFiles])
    }

    const submit = useCallback(async () => {
        window.scrollTo(0, 0)
        try {
            const newFiles = files.filter((file) => !file.id)
            await uploadFiles(newFiles)

            handleSubmit({ ...returnItem, comment, files })
        } catch (e) {
            toast.error(t('reportIssue.uploadError'))
        }
    }, [comment, files, handleSubmit])

    return (
        <ModalWithHeader
            title={t('addComment.title')}
            width={520}
            handleClose={handleClose}
            disableRestoreFocus
            open={isOpen}
            headerIcon={faComment}
            headerIconColor={theme.palette.primary[600]}
            headerType={HeaderType.MOBILE}
        >
            <Box className={classes.content}>
                <InputField
                    fullWidth
                    multiline
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    inputProps={{ maxLength: 500 }}
                    data-testid="commentTextarea-comment"
                    placeholder={t('addComment.comment.placeholder')}
                    className="noKeyboardListener"
                />
                <Button
                    component="label"
                    variant="fill"
                    color="neutral"
                    size="small"
                    startIcon={faCamera}
                    className={classes.fileButton}
                >
                    <FileInput multiple handleFile={handleFileInput} />
                    {t('addComment.takePicture.addPicture')}
                </Button>
                {files?.length > 0 && <PictureGrid pictures={files} handleDelete={handleFileDelete} />}
                <ModalFooter
                    label={t('addComment.submitComment')}
                    endIcon={faArrowRight}
                    onSubmit={submit}
                    onCancel={handleClose}
                    submitDisabled={isSubmitButtonDisabled}
                    withDivider={false}
                />
            </Box>
        </ModalWithHeader>
    )
}

export default AddCommentModal
