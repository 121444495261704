import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons'
import Button from '_atoms/buttons/Button'

type ExportButtonProps = {
    text?: string
    onClick: () => void
}

const ExportButton: React.FC<ExportButtonProps> = ({ text, onClick }) => {
    const { t } = useTranslation()

    return (
        <Button
            color="secondary"
            onClick={onClick}
            data-testid="export_button"
            label={text || t('buttons.exportData')}
            startIcon={faFileArrowDown}
            size="small"
        />
    )
}

export default ExportButton
