import { makeStyles, Popover, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import Button from '_atoms/buttons/Button'
import CustomRadioGroup from '_atoms/inputs/RadioGroup'
import Text from '_atoms/text/Text'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'
import { IWarehouse } from 'interfaces/Warehouse'
import Account from '_organisms/drawerMenu/Account'
import Tips from '_atoms/containers/Tips'

const useStyles = makeStyles<
    Theme,
    {
        heightPopoverContent: number | string
        heightPopover: number | string
        step: ActionType | undefined
        disabledSelectedWarehouse: boolean
    }
>((theme) => ({
    popover: {
        height: ({ heightPopover, disabledSelectedWarehouse }) => (disabledSelectedWarehouse ? 210 : heightPopover),
        paddingTop: ({ disabledSelectedWarehouse, step }) =>
            disabledSelectedWarehouse && step === undefined ? '35px' : 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        background: 'none',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    popoverContent: {
        height: ({ heightPopoverContent, disabledSelectedWarehouse }) =>
            disabledSelectedWarehouse ? 210 : heightPopoverContent,
        width: theme.space(58),
        padding: theme.space(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: `height ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
        background: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
    },
    backButtonBackground: {
        position: 'fixed',
        backgroundColor: theme.palette.common.white,
        width: theme.space(58),
        height: theme.space(9),
        margin: theme.space(-3),
        display: 'flex',
        zIndex: 2,
        borderRadius: 4,
    },
    backButton: {
        paddingTop: theme.space(3),
        '&:focus': {
            outline: 'none!important',
        },
    },
    accountStep: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    radioGroup: {
        marginTop: ({ disabledSelectedWarehouse }) => (disabledSelectedWarehouse ? theme.space(2) : theme.space(6)),
        height: theme.space(18),
        overflow: 'scroll',
        flexWrap: 'nowrap',
    },
    parcelShopInfo: {
        height: theme.space(20),
        display: 'flex',
        flexDirection: 'column',
    },
    warehouseInfo: {
        marginTop: theme.space(6),
        padding: theme.space(2, 0),
        height: theme.space(52 / 5),
        paddingLeft: `${theme.space(3)}px!important`,
    },
}))

export enum ActionType {
    WAREHOUSE_PICKER = 'WAREHOUSE_PICKER',
    PARCEL_SHOP_INFO = 'PARCEL_SHOP_INFO',
}

type AccountPophoverProps = {
    anchorEl: HTMLButtonElement | null
    setAnchorEl: (anchorEl: HTMLButtonElement | null) => void
    logout: () => void
}

const AccountPopover: React.FC<AccountPophoverProps> = ({ anchorEl, setAnchorEl, logout }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [step, setStep] = useState<ActionType | undefined>()
    const [user] = useConnectedUserStore((state: ConnectedUserState) => [state.user])
    const [
        selectedWarehouse,
        disabledSelectedWarehouse,
        setSelectedWarehouse,
    ] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.selectedWarehouse,
        state.disabledSelectedWarehouse,
        state.setSelectedWarehouse,
    ])
    function computeHeightPopover(consider = true) {
        if (step !== undefined && consider) return theme.space(28)
        if (user.customers.length === 1 && user.parcelShop) {
            return theme.space(238 / 5)
        } else if (user.customers.length === 1 || user.parcelShop) {
            return theme.space(207 / 5)
        } else {
            return theme.space(35)
        }
    }
    const classes = useStyles({
        heightPopoverContent: computeHeightPopover(),
        heightPopover: computeHeightPopover(false),
        step,
        disabledSelectedWarehouse,
    })
    const open = Boolean(anchorEl)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const handleClose = () => {
        setAnchorEl(null)
        setStep(undefined)
    }

    function handleChangeRadio(value: string) {
        const warehouseId = Number(value)
        setSelectedWarehouse(user.warehouses.find((warehouse) => warehouse.id === warehouseId) as IWarehouse)
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
                className: classes.popover,
            }}
            anchorOrigin={{
                vertical: isMobile ? 'bottom' : 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'left',
            }}
            data-testid="account-popover"
        >
            <div className={classes.popoverContent}>
                {!step ? (
                    <Account setStep={setStep} logout={logout} />
                ) : (
                    <div className={classes.accountStep}>
                        <div className={classes.backButtonBackground}>
                            <Button
                                label="Retour"
                                color="neutral"
                                variant={'ghost'}
                                size={'small'}
                                startIcon={faChevronLeft}
                                onClick={() => setStep(undefined)}
                                className={classes.backButton}
                            />
                        </div>
                        {disabledSelectedWarehouse && (
                            <Tips variant="info" className={classes.warehouseInfo}>
                                {t('menu.account.info')}
                            </Tips>
                        )}
                        {step === ActionType.WAREHOUSE_PICKER && (
                            <CustomRadioGroup
                                options={user.warehouses.map((warehouse) => {
                                    return {
                                        label: warehouse.name,
                                        value: warehouse.id,
                                        disabled: disabledSelectedWarehouse,
                                    }
                                })}
                                value={selectedWarehouse?.id}
                                onChange={handleChangeRadio}
                                className={classes.radioGroup}
                                data-testid="warehouses-radio-group"
                            />
                        )}
                        {step === ActionType.PARCEL_SHOP_INFO && (
                            <div className={classes.parcelShopInfo}>
                                <Text variant={'S2'}>{user.parcelShop?.name}</Text>
                                <Text variant={'P5'}>{user.parcelShop?.address}</Text>
                                <Text variant={'P5'}>{user.parcelShop?.addressDetails}</Text>
                                <Text variant={'P5'}>
                                    {user.parcelShop?.zipcode} - {user.parcelShop?.country}
                                </Text>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Popover>
    )
}

export default AccountPopover
