import { useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, IconButton, makeStyles, Theme, useTheme } from '@material-ui/core'
import { ParcelStatus, UniqueProductStatus, UniqueProductStatusDisplay } from 'interfaces'
import { TailSpin } from 'react-loader-spinner'
import { SectionTitle } from '_atoms/text/SectionTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/pro-light-svg-icons'
import Table from '_organisms/tables/Table'
import { readablePrice } from 'utils/stringConvertion'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'
import SquareProductImage from '_atoms/images/SquareProductImage'
import { SecondHandRequest } from 'interfaces/SecondHand.interface'
import TakebackParcelSummarySection from './TakebackParcelSummarySection'
import dayjs from 'dayjs'
import DialogCarousel from '_organisms/images/carousel/DialogCarousel'
import S3SwipeableCarousel from '_organisms/images/carousel/S3SwipeableCarousel'
import Status from '_atoms/badges/Status'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionContainer: {
            paddingBottom: theme.spacing(2),
            justifyContent: 'center',
        },
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        pictureIcon: {
            fontSize: theme.typography.pxToRem(30),
        },
        uniqueProductName: {
            color: theme.palette.primary[900],
            opacity: '70%',
            margin: '0',
        },
        status: {
            maxWidth: theme.space(36),
        },
    }),
)

type TakebackProps = {
    secondHandRequest: SecondHandRequest
    isLoading: boolean
}

const TakebackPage: React.FC<TakebackProps> = ({ secondHandRequest, isLoading }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const prismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.qualities)
    const [uniqueProductPicturesToDisplay, setUniqueProductPicturesToDisplay] = useState<any[] | null>(null)

    const customerName = secondHandRequest?.shipment?.renter?.customer?.name || ''
    const parcelsDates = []
    const parcelsInfos = []

    const uniqueProductHeaders = [
        { label: t('takebacks.uniqueProductHeaders.picture') },
        {
            label: (
                <span key={1} style={{ padding: '0' }}>{`${t('returns.uniqueProductHeaders.PUID')} / ${t(
                    'takebacks.uniqueProductHeaders.labelRefAndSize',
                )}`}</span>
            ),
        },
        { label: t('returns.uniqueProductHeaders.status') },
        { label: t('takebacks.uniqueProductHeaders.initialStatus') },
        { label: t('takebacks.uniqueProductHeaders.initialVoucher') },
        { label: t('takebacks.uniqueProductHeaders.effectiveStatus') },
        { label: t('takebacks.uniqueProductHeaders.effectiveVoucher') },
        { label: t('takebacks.uniqueProductHeaders.refusedReason') },
        { label: t('takebacks.uniqueProductHeaders.shootingPictures') },
    ]

    const voucherHeaders = [
        { label: t('takebacks.voucherHeaders.createdAt') },
        { label: t('takebacks.voucherHeaders.amount') },
        { label: t('takebacks.voucherHeaders.type') },
    ]

    if (secondHandRequest?.shipment?.parcels) {
        for (const parcel of secondHandRequest?.shipment?.parcels || []) {
            const dates = {
                arrivedAt: undefined,
                shippedAt: parcel.shippedAt,
                processedAt: undefined,
            }

            const arrivedStatusHistory = secondHandRequest?.shipment?.parcels?.[0].statusHistory?.find(
                (satusHistory: any) => satusHistory.status === ParcelStatus.ARRIVED,
            )
            const processedStatusHistory = secondHandRequest?.shipment?.parcels?.[0].statusHistory?.find(
                (satusHistory: any) => satusHistory.status === ParcelStatus.PROCESSED,
            )
            dates.arrivedAt = arrivedStatusHistory?.createdAt
            dates.processedAt = processedStatusHistory?.createdAt

            parcelsDates.push(dates)
            parcelsInfos.push({
                barcode: parcel.barcode,
                trackingCode: parcel.trackingCode,
                trackingLink: parcel.trackingLink,
                pdfUrl: parcel.pdfUrl,
            })
        }
    }

    function getTakebackProductStatus(status: UniqueProductStatus): string {
        if (status === UniqueProductStatus.REFUSED) return t('takebacks.statuses.refused')
        else if (status === UniqueProductStatus.NOT_SET) return t('takebacks.statuses.waiting')
        else return t('takebacks.statuses.accepted')
    }

    function getTakebackProductStatusType(status: UniqueProductStatus): UniqueProductStatusDisplay {
        if (status === UniqueProductStatus.REFUSED) return UniqueProductStatusDisplay.REFUSED
        else if (status === UniqueProductStatus.NOT_SET) return UniqueProductStatusDisplay.NOT_SET
        else return UniqueProductStatusDisplay.TO_PUBLISH
    }

    function uniqueProductsToRows(): any {
        return secondHandRequest?.productRequests?.map((productRequest, index) => {
            const { uniqueProduct } = productRequest

            if (!uniqueProduct) return

            const { barcodeUid, productReference, photos, status, returnItems } = uniqueProduct

            const barcodeUidWithLabelRefAndSize = (
                <>
                    {barcodeUid}
                    <p
                        className={classes.uniqueProductName}
                    >{`${productRequest.labelReference} - ${productReference?.size}`}</p>
                </>
            )

            let refusedReason = 'n/a'

            if (returnItems && returnItems.length > 0 && returnItems[0].comment)
                refusedReason = t(`takebacks.refusedReasons.${returnItems[0].comment}`)

            return {
                cells: [
                    {
                        value: (
                            <SquareProductImage
                                image={
                                    uniqueProduct.productReference?.image ||
                                    uniqueProduct.productReference?.product?.image
                                }
                            />
                        ),
                    },
                    { value: barcodeUidWithLabelRefAndSize },
                    {
                        value: (
                            <Status
                                key={index}
                                color={getTakebackProductStatusType(status)}
                                label={getTakebackProductStatus(status)}
                                className={classes.status}
                            />
                        ),
                    },
                    {
                        value:
                            prismicQualities?.find((prismicQuality) => prismicQuality.value === productRequest.quality)
                                ?.label || productRequest.quality,
                    },
                    {
                        value: readablePrice(productRequest.suggestedPrice),
                    },
                    {
                        value:
                            prismicQualities?.find((prismicQuality) => prismicQuality.value === uniqueProduct.quality)
                                ?.label || uniqueProduct.quality,
                    },
                    {
                        value:
                            status === UniqueProductStatus.NOT_SET ? 'n/a' : readablePrice(productRequest.finalPrice),
                    },
                    {
                        value: refusedReason,
                    },
                    {
                        value:
                            photos && photos?.length > 0 ? (
                                <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                                    <IconButton key={index} onClick={() => setUniqueProductPicturesToDisplay(photos)}>
                                        <FontAwesomeIcon icon={faImage} className={classes.pictureIcon} />
                                    </IconButton>
                                    {photos?.length > 1 ? `+${photos.length - 1}` : ''}
                                </div>
                            ) : (
                                ''
                            ),
                    },
                ],
            }
        })
    }

    function vouchersToRows(): any {
        return secondHandRequest?.vouchers?.map((voucher) => {
            const { createdAt, amount, type } = voucher

            return {
                cells: [
                    { value: dayjs(createdAt).format('DD/MM/YYYY') },
                    {
                        value: readablePrice(amount),
                    },
                    { value: type },
                ],
            }
        })
    }

    if (isLoading)
        return (
            <div style={{ position: 'absolute', top: '35%', left: '58%' }}>
                <TailSpin visible color={theme.palette.primary.main} height={90} width={90} />
            </div>
        )

    return (
        <>
            <TakebackParcelSummarySection
                renterInfos={{
                    email: secondHandRequest?.shipment?.renter?.email,
                    fullName: `${secondHandRequest?.shipment?.renter?.firstName} ${secondHandRequest?.shipment?.renter?.lastName}`,
                }}
                customerName={customerName}
                carrier={secondHandRequest?.shipment?.carrier?.name}
                parcelsInfos={parcelsInfos}
                takebackDate={secondHandRequest?.createdAt}
                shipmentDates={parcelsDates}
            />
            <div className={classes.sectionContainer}>
                <SectionTitle className={classes.sectionTitle} size="large">
                    {t('returns.products')}
                </SectionTitle>
                <Table header={uniqueProductHeaders} rows={uniqueProductsToRows()} />
            </div>
            <div className={classes.sectionContainer}>
                <SectionTitle className={classes.sectionTitle} size="large">
                    {t('takebacks.vouchers')}
                </SectionTitle>
                <Table header={voucherHeaders} rows={vouchersToRows()} />
            </div>
            <DialogCarousel
                title={t('takebacks.uniqueProductHeaders.shootingPictures')}
                isOpen={!!uniqueProductPicturesToDisplay?.length}
                handleClose={() => setUniqueProductPicturesToDisplay(null)}
            >
                <S3SwipeableCarousel
                    files={uniqueProductPicturesToDisplay}
                    handleFetchFailure={() => setUniqueProductPicturesToDisplay(null)}
                />
            </DialogCarousel>
        </>
    )
}

export default TakebackPage
