import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ButtonGroup from '_molecules/ButtonGroup'
import Button from '_atoms/buttons/Button'
import { useTranslation } from 'react-i18next'
import { BatchFilter } from 'interfaces/Batch.interface'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center',
                padding: theme.space(0),
                margin: theme.space(6, 0),
            },
            [theme.breakpoints.up('md')]: {
                background: theme.palette.common.white,
                position: 'sticky',
                top: 0,
                borderBottom: `1px solid ${theme.palette.neutral[200]}`,
                margin: theme.space(0, -12),
                padding: theme.space(4, 12),
                zIndex: 2,
            },
        },
        createBatchButton: {
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        filterButton: {
            width: theme.space(23),
            [theme.breakpoints.down('md')]: {
                width: theme.space(21),
            },
        },
    }),
)

type BatchListActionsProps = {
    filter: string
    setFilter: (value: string) => void
    openCreateBatchModal: () => void
}

const BatchListActions: React.FC<BatchListActionsProps> = ({ filter, setFilter, openCreateBatchModal }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const buttonGroupOptions = [
        { value: BatchFilter.ALL, label: t('new_orders.pickPack.filterLabels.all') },
        { value: BatchFilter.PICKING, label: t('new_orders.pickPack.filterLabels.picking') },
        { value: BatchFilter.PACKING, label: t('new_orders.pickPack.filterLabels.packing') },
    ]

    return (
        <div className={classes.root}>
            <ButtonGroup
                options={buttonGroupOptions}
                value={filter}
                setValue={setFilter}
                buttonClass={classes.filterButton}
            />
            <Button
                data-testid="createBatch_button"
                label={t('new_orders.pickPack.generateBatch')}
                className={classes.createBatchButton}
                size="small"
                onClick={openCreateBatchModal}
            />
        </div>
    )
}

export default BatchListActions
