import * as React from 'react'
import { useCallback } from 'react'
import { createStyles, makeStyles, Slider } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { faGripLinesVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles<Theme, { value: number | null; maxValue: number; placeholder: string }>((theme) =>
    createStyles({
        slider: {
            marginTop: theme.space(8),
            width: '80%',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                transform: `translateX(calc(10% + ${theme.space(1)}px))`,
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                marginLeft: theme.space(2),
            },
        },
        rail: ({ value, maxValue }) => ({
            height: theme.space(1),
            marginRight: theme.space(2),
            opacity: 1,
            backgroundColor: theme.palette.primary[100],
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                height: '100%',
                width: '10%',
                left: '-10%',
                backgroundColor: theme.palette.primary[600],
                borderRadius: theme.space(1, 0, 0, 1),
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                height: '100%',
                width: '10%',
                right: '-10%',
                borderRadius: theme.space(0, 1, 1, 0),
                backgroundColor: theme.palette.primary[value == maxValue ? 600 : 100],
            },
        }),
        track: {
            height: '5px',
            borderRadius: 0,
        },
        markLabel: {
            display: ({ value }) => (value !== null ? 'block' : 'none'),
            top: `-${theme.space(5)}px`,
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(11),
            lineHeight: theme.typography.pxToRem(16.5),
        },
        thumb: ({ placeholder, value }) => ({
            height: theme.space(6),
            width: theme.space(6),
            marginTop: `-${theme.space(2.6)}px`,
            marginLeft: `-${theme.space(2.6)}px`,
            '&:before': {
                content: `"${placeholder}"`,
                color: theme.palette.neutral[900],
                fontWeight: 400,
                fontSize: theme.typography.pxToRem(11),
                lineHeight: theme.typography.pxToRem(16.5),
                marginBottom: '-30px',
                minWidth: theme.space(26),
                bottom: theme.space(14),
                right: `-${theme.space(10)}px`,
                position: 'absolute',
                display: value === null ? 'flex' : 'none',
                justifyContent: 'center',
            },
            '& svg': {
                color: theme.palette.common.white,
            },
        }),
    }),
)

export type MarkType = { value: number; name: string; label: string; emoji: string }

type SliderEmojiProps = {
    value: number | null
    setValue: (value: number) => void
    marks: MarkType[]
    dataTestId?: string
    maxValue?: number
}

const SliderEmoji: React.FC<SliderEmojiProps> = ({
    value,
    setValue,
    marks,
    dataTestId,
    maxValue = marks.length - 1,
}) => {
    const { t } = useTranslation()
    const classes = useStyles({ value, maxValue, placeholder: t('slider.placeholder') })

    const currentEmoji = marks.find((mark) => mark.value === value)?.emoji

    const EmojiThumb = useCallback(
        (props: any) => {
            return currentEmoji ? (
                <span role="img" {...props}>
                    {currentEmoji}
                </span>
            ) : (
                <div {...props}>
                    <FontAwesomeIcon icon={faGripLinesVertical} />
                </div>
            )
        },
        [value],
    )

    const handleChange = (e: any, value: any) => {
        setValue(value)
    }

    return (
        <Slider
            defaultValue={maxValue / 2}
            value={value === null ? maxValue / 2 : value}
            min={0}
            max={maxValue}
            onChange={handleChange}
            marks={marks}
            valueLabelDisplay="off"
            step={1}
            ThumbComponent={EmojiThumb}
            className={classes.slider}
            classes={{
                rail: classes.rail,
                track: classes.track,
                markLabel: classes.markLabel,
                thumb: classes.thumb,
            }}
            data-testid={dataTestId}
        />
    )
}

export default SliderEmoji
