import * as React from 'react'

type WhalyIframeProps = {
    dashboardUrl?: string
}

const WhalyIframe: React.FC<WhalyIframeProps> = ({ dashboardUrl }) => {
    if (dashboardUrl)
        return (
            <iframe
                src={dashboardUrl}
                frameBorder="0"
                style={{ width: '100%', height: '2000px' }}
                scrolling="no"
                allowFullScreen
            ></iframe>
        )

    return null
}

export default WhalyIframe
