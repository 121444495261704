import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IUniqueProduct } from 'interfaces'
import { UNIQUE_PRODUCTS_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { ProductType, newProductsType } from './InsertProductHome'
import EnrichedIcon from '../../_molecules/EnrichedIcon'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons'
import UniqueProductCardList from './UniqueProductCardList'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import Button from '_atoms/buttons/Button'
import { StepHandlerType } from '_organisms/Stepper'

type InsertProductProps = {
    productType: ProductType
    ean: string
    goToHome: () => void
    handleSteps?: StepHandlerType
    hidden?: boolean
}

const InsertProduct: React.FC<InsertProductProps> = ({ productType, ean, goToHome, handleSteps, hidden }) => {
    const { t } = useTranslation()
    const [puidList, setPuidList] = useState<newProductsType>([])
    const areAllQualitiesSet = puidList.filter((e) => e.quality === undefined).length === 0
    const isProcessable =
        puidList.length > 0 &&
        (productType === ProductType.RENTAL || (productType === ProductType.SECOND_HAND && areAllQualitiesSet))

    async function fetchPuid(puid: string): Promise<IUniqueProduct | undefined> {
        try {
            const uniqueProductRequest = await rmsApi.get(`${UNIQUE_PRODUCTS_URL}/check-if-exists/${encodeURI(puid)}`)
            return uniqueProductRequest?.data
        } catch (e: any) {
            toast.error(t('wms.scanUniqueProduct.unknownError'))
        }
    }

    async function addPuidToList(puid: string) {
        if (puid && !puidList.map((uniqueProduct) => uniqueProduct.barcode).includes(puid)) {
            const product = await fetchPuid(puid)

            if (!product) {
                setPuidList([...puidList, { barcode: puid }])
            } else {
                toast.error(
                    t('wms.scanUniqueProduct.uniqueProductExists', {
                        PUID: product.barcodeUid,
                        storageLocation: product.storageLocation,
                    }),
                )
            }
        } else {
            toast.error(t('wms.scanUniqueProduct.emptyOrAlreadyInList'))
        }
    }

    function cancel() {
        setPuidList([])
        goToHome()
    }

    function deletePuidFromList(id: number): void {
        const tempPuidList = [...puidList]
        tempPuidList.splice(id, 1)
        setPuidList(tempPuidList)
    }

    function setPuidState(id: number, quality: string): void {
        const tempPuidList = [...puidList]
        tempPuidList[id].quality = quality
        setPuidList(tempPuidList)
    }

    const renderCreateButton = () => {
        if (isProcessable)
            return (
                <Button
                    size="large"
                    onClick={() => {
                        handleSteps?.onSubmit(puidList)
                        setPuidList([])
                    }}
                    disabled={puidList?.length === 0}
                    data-testid="insertProducts"
                >
                    {t('wms.scanUniqueProduct.processBulkAdd')}
                </Button>
            )
    }

    if (hidden) return <></>

    return (
        <Page
            title={t('wms.scanUniqueProduct.eanHeaderTitle', { EAN: ean })}
            section={t('wms.scanUniqueProduct.title')}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            handlePrevious={cancel}
        >
            <BackgroundWithScanAndFooter
                footer={renderCreateButton()}
                footerInBoxMobile={true}
                scanProps={{
                    placeholder: t('wms.scanUniqueProduct.puidPlaceholder'),
                    onSubmit: addPuidToList,
                }}
            >
                {puidList.length > 0 ? (
                    <UniqueProductCardList
                        puidList={puidList}
                        handleSetQuality={productType === ProductType.SECOND_HAND && setPuidState}
                        handleDelete={deletePuidFromList}
                    />
                ) : (
                    <EnrichedIcon
                        icon={faScannerGun}
                        title={t('wms.scanUniqueProduct.waitingScan.title')}
                        tagline={t('wms.scanUniqueProduct.waitingScan.tagline')}
                    />
                )}
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default InsertProduct
