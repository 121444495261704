import * as React from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import Text from '_atoms/text/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles<Theme, { mobileDarkTheme: boolean }>((theme: Theme) =>
    createStyles({
        closeButton: {
            backgroundColor: ({ mobileDarkTheme }) =>
                mobileDarkTheme ? theme.palette.neutral[800] : theme.palette.neutral[50],
            minWidth: theme.space(8),
            margin: theme.space(2, -1, 0, 0),
            maxWidth: theme.space(8),
            height: theme.space(8),
            fontWeight: 400,
            [theme.breakpoints.down('sm')]: {
                fontWeight: 400,
            },
            '& svg': {
                color: ({ mobileDarkTheme }) =>
                    mobileDarkTheme ? theme.palette.common.white : theme.palette.neutral[700],
            },
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: `${theme.space(1)}px`,
            position: 'fixed',
            right: 0,
            left: 0,
            borderRadius: theme.space(3, 3, 0, 0),
            padding: theme.space(0, 3),
            background: ({ mobileDarkTheme }) =>
                mobileDarkTheme ? theme.palette.neutral[900] : theme.palette.common.white,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: `${theme.space(2)}px`,
            textAlign: 'center',
            width: '100%',
        },
        icon: {
            marginBottom: theme.space(2),
            height: theme.space(6),
            width: theme.space(6),
        },
        title: {
            fontSize: theme.typography.pxToRem(14),
            color: ({ mobileDarkTheme }) => (mobileDarkTheme ? theme.palette.common.white : theme.palette.neutral[700]),
        },
        tagline: {
            whiteSpace: 'pre-wrap',
            color: ({ mobileDarkTheme }) => (mobileDarkTheme ? theme.palette.common.white : theme.palette.neutral[600]),
        },
    }),
)

type MobileHeaderProps = {
    title: string
    mobileDarkTheme: boolean
    headerIcon?: IconProp
    headerIconColor?: string
    tagline?: string
    handleClose?: () => void
}

const MobileHeader = React.forwardRef<HTMLDivElement, MobileHeaderProps>(
    ({ title, handleClose, tagline, headerIcon, headerIconColor, mobileDarkTheme }, ref) => {
        const classes = useStyles({ mobileDarkTheme })
        const theme = useTheme()

        return (
            <div className={classes.header} ref={ref}>
                {handleClose && (
                    <Button
                        onClick={handleClose}
                        startIcon={faXmark}
                        shape="squared"
                        color="neutral"
                        variant="fill"
                        className={classes.closeButton}
                        data-testid="close-modal"
                    />
                )}
                <div className={classes.container}>
                    {headerIcon && (
                        <FontAwesomeIcon
                            icon={headerIcon}
                            color={headerIconColor ?? theme.palette.primary.main}
                            className={classes.icon}
                        />
                    )}
                    <Text variant="S1" className={classes.title}>
                        {title}
                    </Text>
                    {tagline && (
                        <Text variant="P5" className={classes.tagline}>
                            {tagline}
                        </Text>
                    )}
                </div>
            </div>
        )
    },
)

export default MobileHeader
