import * as React from 'react'
import { useEffect, useState } from 'react'

import { B2C_INVOICES } from 'utils/routes/backend'
import InvoicesPage from 'components/financeApp/invoicesApp/InvoicePage'

import dayjs from 'dayjs'
import Page from '_organisms/Page'
import { useTranslation } from 'react-i18next'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'

type IInvoice = {
    url: string
    paymentDate: string
    reason: string
}

const InvoicesApp: React.FC = () => {
    const { t } = useTranslation()

    const [month, setMonth] = useState<number>(dayjs().month())
    const [year, setYear] = useState<number>(dayjs().year())
    const [invoicesB2C, setinvoicesB2C] = useState<IInvoice[] | null>(null)

    useEffect(() => {
        const fetchInvoices = async () => {
            setinvoicesB2C(null)

            try {
                const b2cResponse = await rmsApi.get(`${B2C_INVOICES}?fromYear=${year}&fromMonth=${month}`)
                setinvoicesB2C(b2cResponse?.data)
            } catch (error: any) {
                toast.error(t('menu.invoices.b2cError', { error }))
            }
        }
        fetchInvoices()
    }, [month, year])

    function handleMonthChange(selectedMonth: number) {
        setMonth(selectedMonth)
    }

    function handleYearChange(selectedYear: number) {
        setYear(selectedYear)
    }

    return (
        <Page title={t('menu.financesInvoices')} tagline={t('finances.invoices.tagline')}>
            <InvoicesPage setMonth={handleMonthChange} setYear={handleYearChange} invoicesB2C={invoicesB2C} />
        </Page>
    )
}

export default InvoicesApp
