import * as React from 'react'
import { useState } from 'react'
import Page from '_organisms/Page'
import { useTranslation } from 'react-i18next'
import { PaginationInfos, SearchFieldDefaultFilterKey } from 'interfaces/Table.interfaces'
import rmsApi from 'utils/api'
import { PARCELS_BY_FILTERS_URL } from 'utils/routes/backend'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import dayjs from 'dayjs'
import { ParcelStatus, ParcelStatusDisplay } from 'interfaces'
import MobileSearchFilter from '_organisms/tableV2/filters/MobileSearchFilter'
import CheckboxListFilter from '_organisms/tableV2/filters/CheckboxListFilter'
import TableV2 from '_organisms/tableV2/TableV2'
import { toast } from 'utils/toast'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import Status from '../../_atoms/badges/Status'
import DatePickerFilter from '../../_organisms/tableV2/filters/DatePickerFilter'
import { BasicText } from '../../_atoms/text/BasicText'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overdue: {
            color: theme.palette.common.black,
        },
        overdueWarning: {
            color: theme.palette.warning.main,
        },
        overdueError: {
            color: theme.palette.error.main,
        },
    }),
)

interface TableRowForthParcel {
    trackingCode: JSX.Element
    omsOrderId: string
    renterEmail: string
    shippingDate: string
    shippedAt: string
    status: JSX.Element
    customerName: string
    barcode: string
    shipmentId: string
    lateDays?: JSX.Element
    carrier: string
    label: JSX.Element
    deliveryNoteUrl: JSX.Element
}

const ForthParcelTable: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()

    const selectedCustomers = useSelectedCustomersStore((state: SelectedCustomersState) => state.selectedCustomers)
    const [forthParcels, setForthParcels] = useState<TableRowForthParcel[]>([])

    const parcelStatusOptions = Object.keys(ParcelStatus).map((status) => ({
        value: status,
        label: t(`parcelStatus.${status}`),
    }))

    const defaultStartDate = dayjs().startOf('year')
    const defaultEndDate = dayjs().add(15, 'day')

    async function buildRows(rawParcels: any) {
        const rows: TableRowForthParcel[] = []

        for (const rawParcel of rawParcels) {
            const renterEmail = rawParcel.renter_email
            const customerName = rawParcel.customer_name
            const omsOrderId = rawParcel.order_oms_order_id

            const trackingCode = (
                <a target="_blank" rel="noopener noreferrer" href={rawParcel.parcel_tracking_link}>
                    {rawParcel.parcel_tracking_code}
                </a>
            )

            const shippingDate = dayjs(rawParcel.shipment_shipping_date.toLocaleString()).format('DD/MM/YY')
            const shippedAt =
                rawParcel.parcel_shipped_at && dayjs(rawParcel.parcel_shipped_at.toLocaleString()).format('DD/MM/YY')
            const status = (
                <Status
                    label={t(`parcelStatus.${rawParcel.parcel_status}`)}
                    color={ParcelStatusDisplay[rawParcel.parcel_status as ParcelStatus]}
                />
            )

            const barcode = rawParcel.parcel_barcode
            const shipmentId = rawParcel.shipment_id
            const lateDays =
                rawParcel.days_late > 0 ? (
                    <BasicText
                        className={`${classes.overdue} ${rawParcel.days_late > 0 ? classes.overdueWarning : ''} ${
                            rawParcel.days_late > 3 ? classes.overdueError : ''
                        }`}
                    >
                        {`${rawParcel.days_late} ${t('returns.rentalDates.days')}`}
                    </BasicText>
                ) : undefined
            const carrier = rawParcel.carrier_name
            const label = (
                <a target="_blank" rel="noopener noreferrer" href={rawParcel.parcel_pdf_url}>
                    Lien
                </a>
            )

            const deliveryNoteUrl = rawParcel.shipment_delivery_note_url && (
                <a target="_blank" rel="noopener noreferrer" href={rawParcel.shipment_delivery_note_url}>
                    Lien
                </a>
            )

            rows.push({
                trackingCode,
                omsOrderId,
                renterEmail,
                shippingDate,
                shippedAt,
                status,
                customerName,
                barcode,
                shipmentId,
                lateDays,
                carrier,
                label,
                deliveryNoteUrl,
            })
        }

        setForthParcels(rows)
    }

    function buildSearchParams(filters: any) {
        const search = filters.searchField ? `&search=${filters.searchField}` : ''

        const buildCustomersParams = (name: string) =>
            `&customerIds[]=${selectedCustomers.find((customer) => customer.name === name)?.id}`
        const customers = filters.customers?.map(buildCustomersParams).join('') || ''

        const buildStatusesParams = (label: string) =>
            `&statuses[]=${parcelStatusOptions.find((status) => status.label === label)?.value}`
        const statuses = filters.statuses?.map(buildStatusesParams).join('') || ''

        const from = `&from=${(filters.dateRange?.startDate || defaultStartDate).format('YYYY-MM-DD')}`
        const to = `&to=${(filters.dateRange?.endDate || defaultEndDate).format('YYYY-MM-DD')}`

        return `${search}${customers}${statuses}${from}${to}`
    }

    async function fetchForthParcels(
        filters: any,
        currentPage: number,
        itemsPerPage: number,
        setPagination: (p: PaginationInfos) => void,
    ): Promise<void> {
        const params = buildSearchParams(filters)

        try {
            const request = await rmsApi.get(
                `${PARCELS_BY_FILTERS_URL}?isReturn=0&page=${currentPage}&limit=${itemsPerPage}${params}`,
            )
            const { items, meta } = request.data

            if (meta) setPagination(meta)
            await buildRows(items)
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    const header = [
        { value: 'trackingCode', label: 'forthParcels.columnTracking' },
        { value: 'omsOrderId', label: 'orders.columnOmsOrderId' },
        { value: 'renterEmail', label: 'shipments.columnRenterEmail' },
        { value: 'shippingDate', label: 'forthParcels.columnShippingDate' },
        { value: 'shippedAt', label: 'forthParcels.columnShippedAt' },
        { value: 'status', label: 'shipments.columnStatus' },
        { value: 'customerName', label: 'forthParcels.columnCustomer' },
        { value: 'barcode', label: 'forthParcels.collapsed.barcode' },
        { value: 'shipmentId', label: 'forthParcels.collapsed.shipmentId' },
        { value: 'lateDays', label: 'forthParcels.collapsed.lateDays' },
        { value: 'carrier', label: 'forthParcels.collapsed.carrier' },
        { value: 'label', label: 'forthParcels.collapsed.label' },
        { value: 'deliveryNoteUrl', label: 'forthParcels.collapsed.deliveryNote' },
    ]

    const filterOptions = (filters: any) => [
        {
            filterKey: SearchFieldDefaultFilterKey,
            label: t('table.searchField'),
            component: <MobileSearchFilter placeholder={t('forthParcels.searchInput.placeholder')} />,
        },
        {
            filterKey: 'customers',
            label: t('takebacks.filters.customers'),
            component: (
                <CheckboxListFilter
                    options={selectedCustomers.map((customer) => customer.name!) || []}
                    filteredOptions={filters?.customers || []}
                />
            ),
        },
        {
            filterKey: 'statuses',
            label: t('shipments.columnStatus'),
            component: (
                <CheckboxListFilter
                    options={parcelStatusOptions.map((status) => status.label) || []}
                    filteredOptions={filters?.statuses || []}
                />
            ),
        },
        {
            filterKey: 'dateRange',
            label: t('returns.filters.dateRange'),
            component: (
                <DatePickerFilter
                    startDate={filters?.dateRange?.startDate || defaultStartDate}
                    endDate={filters?.dateRange?.endDate || defaultEndDate}
                    range
                />
            ),
        },
    ]

    const defaultFilters = { dateRange: { startDate: defaultStartDate, endDate: defaultEndDate } }

    return (
        <Page
            title={t('menu.shipmentsAndWithdrawals.forthParcelList')}
            section={t('menu.shipmentsAndWithdrawals.title')}
            contentPaddingTopDesktop={2}
        >
            <TableV2
                fetchRows={fetchForthParcels}
                initialHeader={header}
                rows={forthParcels}
                filterOptions={filterOptions}
                searchFieldPlaceholder={t('forthParcels.searchInput.placeholder')}
                defaultFilters={defaultFilters}
                localStorageName="forthParcelTable"
            />
        </Page>
    )
}

export default ForthParcelTable
