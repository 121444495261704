import { PreparationStatus } from './Preparation.interface'
import { ISavAction } from './SavAction.interface'

export enum PickPackIssueType {
    PICKING_MISSING_PRODUCT = 'PICKING_MISSING_PRODUCT',
    LABEL_ERROR = 'LABEL_ERROR',
    OTHER = 'OTHER',
}

export enum PickPackIssueStatus {
    WAITING_FOR_OPERATOR_ACTION = 'WAITING_FOR_OPERATOR_ACTION',
    WAITING_FOR_SAV_ACTION = 'WAITING_FOR_SAV_ACTION',
    PROCESSED_BY_SAV = 'PROCESSED_BY_SAV',
    PROCESSED_BY_OPERATOR = 'PROCESSED_BY_OPERATOR',
    TERMINATED = 'TERMINATED',
}
export interface PickPackIssueBatch {
    id: number
    idPerWarehouse: number
}

export interface PickPackIssueCustomer {
    id: number
    name: string
}

export interface IPickPackIssueUniqueProduct {
    id: number
}

export interface IPickPackIssueShipment {
    id: number
    uniqueProducts: IPickPackIssueUniqueProduct[]
}

export interface PickPackIssuePreparation {
    id: number
    status: PreparationStatus
}

export interface PickPackIssueOrder {
    id: number
    omsOrderId: string
    shipments: IPickPackIssueShipment[]
    customer: PickPackIssueCustomer
    batch: PickPackIssueBatch
    savActions: ISavAction[]
    preparation: PickPackIssuePreparation
}

export interface IPickPackIssue {
    id: number
    order: PickPackIssueOrder
    issueType: PickPackIssueType
    status: PickPackIssueStatus
}

export enum PickPackIssueListFilter {
    NEED_OPERATOR_ACTION = 'withSavActions',
    NEED_SAV_ACTION = 'withoutSavActions',
}

export interface IPickPackIssueDisplay {
    orderId: number
    omsOrderId: string
    customerName: string
    batchIdPerWarehouse: number
    nbPackedProducts: number
    nbDeletedProducts: number
    nbAddedProducts: number
    orderIsCanceled: boolean
    nbUnknownIssues: number
    nbPdfIssues: number
    nbMissingProducts: number
    needSavAction: boolean
}
