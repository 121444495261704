import * as React from 'react'
import { ReactNode } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import theme from 'theme'

const useStyles = makeStyles(() =>
    createStyles({
        background: {
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.space(10),
                marginTop: theme.space(6),
                backgroundColor: theme.palette.primary[50],
                padding: theme.space(12),
                borderRadius: '10px',
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.space(20),
            },
        },
    }),
)

type BlueBackgroundProps = {
    children: ReactNode
    className?: string
}

const BlueBackground: React.FC<BlueBackgroundProps> = ({ children, className }) => {
    const classes = useStyles()
    return <div className={`${classes.background} ${className}`}>{children}</div>
}

export default BlueBackground
