import * as React from 'react'
import { ReactNode } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import theme from 'theme'
import Background from 'assets/icons_background.svg'

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        background: {
            display: 'flex',
            padding: theme.space(5),
            borderRadius: theme.space(7),
            minHeight: '500px',
            backgroundColor: theme.palette.secondary[100],
            background: `url('${Background}')`,
            [theme.breakpoints.down('md')]: {
                margin: theme.space(4, -14 / 5, -18 / 5, -14 / 5),
                borderRadius: 0,
            },
        },
    }),
)

type IconsBackgroundProps = {
    children: ReactNode
    className?: string
}

const IconsBackground: React.FC<IconsBackgroundProps> = ({ children, className }) => {
    const classes = useStyles()
    return <div className={`${classes.background} ${className}`}>{children}</div>
}

export default IconsBackground
