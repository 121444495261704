import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DoughnutAndCards from '../tabs/DoughnutAndCards'
import Button from '_atoms/buttons/Button'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { SECOND_HAND_HP_STATS_ROUTE } from 'utils/routes/backend'
import { ORDERS_ROUTE, REFIT_ROUTE, RETURN_PARCEL_ROUTE, WMS_PUBLISH } from 'utils/routes/frontend'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

type SecondHandHpStats = {
    incomingTakebacks: number
    productsToRefit: number
    productsToPublish: number
    ordersToPrepare: number
}

const SecondHandHP: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [figures, setFigures] = useState<SecondHandHpStats>({
        incomingTakebacks: 0,
        productsToRefit: 0,
        productsToPublish: 0,
        ordersToPrepare: 0,
    })
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    async function fetchHpData() {
        try {
            const customerIds = selectedCustomers.map((customer) => customer.id).join(',')
            const dataRequest = await rmsApi.get(
                `${SECOND_HAND_HP_STATS_ROUTE}?customerIds[]=${customerIds}&warehouseId=${selectedWarehouse?.id}`,
            )
            setFigures({
                incomingTakebacks: dataRequest.data.incomingTakebacks.nb_incoming_takebacks,
                productsToRefit: dataRequest.data.productsToRefit.nb_products_to_refit,
                productsToPublish: dataRequest.data.productsToPublish.nb_products_to_publish,
                ordersToPrepare: dataRequest.data.ordersToPrepare.orders_to_prepare,
            })
        } catch (e: any) {
            toast.error(t('homepage.errors.statsFetchError', { message: e.message }))
        }
    }

    useEffect(() => {
        fetchHpData()
    }, [selectedCustomers, selectedWarehouse])

    const steps = {
        first: {
            title: t('homepage.roles.secondHand.firstStep.title'),
            tagline: t('homepage.roles.secondHand.firstStep.tagline'),
            figures: [
                {
                    figure: figures.incomingTakebacks,
                    figureTitle: t('homepage.roles.secondHand.firstStep.figureTitles.incomingTakebacks'),
                },
            ],
            actions: (
                <Button
                    color="secondary"
                    size="small"
                    onClick={() => navigate(RETURN_PARCEL_ROUTE)}
                    data-testid="hpProcessTakeback"
                >
                    {t('homepage.roles.secondHand.firstStep.actions.processTakeback')}
                </Button>
            ),
        },
        second: {
            title: t('homepage.roles.secondHand.secondStep.title'),
            tagline: t('homepage.roles.secondHand.secondStep.tagline'),
            figures: [
                {
                    figure: figures.productsToRefit,
                    figureTitle: t('homepage.roles.secondHand.secondStep.figureTitles.productsToRefit'),
                },
            ],
            actions: (
                <Button
                    color="secondary"
                    size="small"
                    onClick={() => navigate(REFIT_ROUTE)}
                    data-testid="hpRefitProduct"
                >
                    {t('homepage.roles.secondHand.secondStep.actions.refitProduct')}
                </Button>
            ),
        },
        third: {
            title: t('homepage.roles.secondHand.thirdStep.title'),
            tagline: t('homepage.roles.secondHand.thirdStep.tagline'),
            figures: [
                {
                    figure: figures.productsToPublish,
                    figureTitle: t('homepage.roles.secondHand.thirdStep.figureTitles.productsToPublish'),
                },
            ],
            actions: (
                <Button color="secondary" size="small" onClick={() => navigate(WMS_PUBLISH)} data-testid="hpPublish">
                    {t('homepage.roles.secondHand.thirdStep.actions.publish')}
                </Button>
            ),
        },
        fourth: {
            title: t('homepage.roles.secondHand.fourthStep.title'),
            tagline: t('homepage.roles.secondHand.fourthStep.tagline'),
            figures: [
                {
                    figure: figures.ordersToPrepare,
                    figureTitle: t('homepage.roles.secondHand.fourthStep.figureTitles.ordersToPrepare'),
                },
            ],
            actions: (
                <Button
                    color="secondary"
                    size="small"
                    onClick={() => navigate(ORDERS_ROUTE)}
                    data-testid="hpPrepareOrders"
                >
                    {t('homepage.roles.secondHand.fourthStep.actions.prepareOrders')}
                </Button>
            ),
        },
    }

    return <DoughnutAndCards steps={steps} />
}

export default SecondHandHP
