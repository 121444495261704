import { useEffect } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, createStyles, Grid, makeStyles } from '@material-ui/core'
import { CalendarEvent } from 'interfaces'
import AppCalendar from 'components/AppCalendar'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { CALENDAR_EVENTS_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page from '_organisms/Page'

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            marginBottom: '20px',
        },
        button: {},
        buttonGroup: {
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
        },
    }),
)

const WITHDRAWAL_EVENTS = 'withdrawal'
const RETURN_EVENTS = 'return'
const ALL_EVENTS = 'all'

const Appointments: React.FC = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [eventType, setEventType] = React.useState(ALL_EVENTS)
    const [allEvents, setAllEvents] = React.useState<CalendarEvent[]>([])
    const [currentEvents, setCurrentEvents] = React.useState<CalendarEvent[]>([])

    async function fetchEvents() {
        try {
            const fetchEventsRequest = await rmsApi.get(CALENDAR_EVENTS_URL)
            let allEvents = fetchEventsRequest.data

            allEvents = allEvents.map((event: CalendarEvent) => {
                event.start = new Date(event.start)
                event.end = new Date(event.end)
                return event
            })

            setAllEvents(allEvents)
        } catch (e: any) {
            if (e.response?.data) {
                toast.error(e.response.data.message)
            } else {
                toast.error(e.message)
            }
        }
    }

    useEffect(() => {
        fetchEvents()
        // eslint-disable-next-line
    }, [])

    const handleChange = (mouseEvent: React.MouseEvent<HTMLElement>, newEventType: string) => {
        if (newEventType) {
            setEventType(newEventType)
        }
    }

    useEffect(() => {
        if (eventType === ALL_EVENTS) {
            setCurrentEvents(allEvents)
        } else {
            const filteredEvents = allEvents.filter((event) => event.type === eventType)
            setCurrentEvents(filteredEvents)
        }
    }, [eventType, allEvents])

    return (
        <Page title={t('orders.appointments.title')}>
            <Container disableGutters>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.title}
                >
                    <Grid item md={4} xs={12}>
                        <ToggleButtonGroup
                            value={eventType}
                            exclusive
                            onChange={handleChange}
                            className={classes.buttonGroup}
                        >
                            <ToggleButton value={ALL_EVENTS} className={classes.button}>
                                {t('orders.appointments.buttonAll')}
                            </ToggleButton>
                            <ToggleButton value={WITHDRAWAL_EVENTS} className={classes.button}>
                                {t('orders.appointments.buttonWithdrawal')}
                            </ToggleButton>
                            <ToggleButton value={RETURN_EVENTS} className={classes.button}>
                                {t('orders.appointments.buttonReturn')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Container>

            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <Container disableGutters>
                    <AppCalendar events={currentEvents} />
                </Container>
            </Grid>
        </Page>
    )
}

export default Appointments
