import * as React from 'react'
import { createStyles, DialogContent, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import TitledHr from '../reusable/TitledHr'
import { IProductReferenceEan } from 'interfaces'
import MagnifyIcon from 'mdi-react/MagnifyIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            background: theme.palette.common.white,
            padding: theme.spacing(0.5, 2, 1),
            maxHeight: '100%',
            overflow: 'unset',
        },
        productInfo: {
            alignItems: 'center',
            textAlign: 'center',
            padding: theme.spacing(1, 2),
            justifyContent: 'center',
        },
        divider: {
            width: '90%',
            margin: 'auto',
            border: `0.1px dashed `,
            borderColor: theme.palette.grey[300],
            backgroundColor: '#ffffff',
        },
        hidden: {
            visibility: 'hidden',
        },
        icon: {
            height: '2em',
            width: '2em',
            marginRight: theme.spacing(0.5),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(0.25),
                height: '1.5em',
                width: '1.5em',
            },
        },
        label: {
            textTransform: 'uppercase',
            fontWeight: 400,
            color: theme.palette.grey[700],
        },
        value: {
            textTransform: 'uppercase',
            fontWeight: 800,
        },
    }),
)

type ProductDetailsProps = {
    eans: IProductReferenceEan[]
    sku: string
    createdAt?: Date
    updatedAt?: Date
}

const ProductReferenceDetails: React.FC<ProductDetailsProps> = ({ eans, sku, createdAt, updatedAt }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <DialogContent className={classes.dialogContent}>
            <TitledHr title={t('wms.productReferences.details')} mdiIcon={<MagnifyIcon />} />
            <Grid className={classes.productInfo} container>
                <Grid item xs={5}>
                    <Typography className={classes.label} variant="h6" component="p">{`${t(
                        'wms.uniqueProducts.columnSku',
                    )}`}</Typography>
                    <Typography className={classes.value} data-testid={`skuOverview`} variant="h6" component="p">
                        {sku}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem variant="middle" />
                <Grid item xs={5} className={eans.length === 0 ? classes.hidden : ''}>
                    <Typography className={classes.label} variant="h6" component="p">{`${t(
                        'wms.uniqueProducts.columnEans',
                    )}`}</Typography>
                    <>
                        {eans?.map((ean: any, index: number) => (
                            <div key={index}>
                                <Typography
                                    className={classes.value}
                                    data-testid={`eanOverview`}
                                    variant="h6"
                                    component="p"
                                >
                                    {ean.ean}
                                </Typography>
                            </div>
                        ))}
                    </>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={classes.productInfo} container>
                <Grid item xs={5}>
                    <Typography className={classes.label} variant="h6" component="p">{`${t(
                        'wms.uniqueProducts.columnCreatedAt',
                    )}`}</Typography>
                    <Typography className={classes.value} variant="h6" component="p" data-testid={`createdAtOverview`}>
                        {dayjs(createdAt).format('DD/MM/YYYY')}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem variant="middle" />
                <Grid item xs={5}>
                    <Typography className={classes.label} variant="h6" component="p">{`${t(
                        'wms.uniqueProducts.columnUpdatedAt',
                    )}`}</Typography>
                    <Typography
                        className={classes.value}
                        variant="h6"
                        component="p"
                        data-testid={`updatedAtOverview`}
                        style={{ margin: 0 }}
                    >
                        {dayjs(updatedAt).format('DD/MM/YYYY')}
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>
    )
}

export default ProductReferenceDetails
