import { useEffect } from 'react'
import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'

import { AppBar, Box, Button, Grid, Slide, Toolbar, Typography } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
        },
        toolBar: {
            justifyContent: 'space-between',
            padding: theme.spacing(0, 3),
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 0.5),
            },
        },
        actionButtons: {
            padding: theme.spacing(0.5, 1),
            color: theme.palette.primary.contrastText,
            transition: theme.transitions.create('opacity'),
            '&:hover, &:focus': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.contrastText,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(12),
                whiteSpace: 'nowrap',
            },
        },
        disabledActionButtons: {
            opacity: 0.3,
            color: theme.palette.primary.contrastText,
        },
        grid: {
            textAlign: 'center',
        },
        title: {
            margin: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2, 1),
                fontSize: theme.typography.pxToRem(14),
            },
        },
        sticky: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: theme.zIndex.modal,
                background: theme.palette.success.main,
                boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)',
            },
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(6),
            },
        },
        disabledSticky: {
            [theme.breakpoints.down('sm')]: {
                background: theme.palette.grey[300],
            },
        },
        finishButton: {
            [theme.breakpoints.down('sm')]: {
                borderRadius: 0,
                width: '100%',
                padding: theme.spacing(2),
            },
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.success.dark,
            },
            '&:disabled': {
                backgroundColor: theme.palette.success.light,
                opacity: 0.5,
                color: '#FFF',
            },
        },
        hidden: {
            display: 'none',
        },
    }),
)

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />
})

type DialogStepper = {
    isOpen: boolean
    step?: number
    handleClose: () => void
    appBarAction: { label: string; action: () => void; disabled: boolean; startIcon?: any; endIcon?: any }[]
    children: React.ReactElement<any>[]
    title?: string
    finishAction?: {
        label: string
        action: () => void
        disabled: boolean
        hidden: boolean
        startIcon?: any
        endIcon?: any
    }
}

const DialogStepper: React.FC<DialogStepper> = ({
    isOpen,
    appBarAction,
    handleClose,
    children,
    step = 0,
    title,
    finishAction,
}) => {
    const classes = useStyles()
    const [status, setStatus] = React.useState<boolean>(isOpen)

    useEffect(() => {
        setStatus(isOpen)
    }, [isOpen])

    return (
        <Dialog
            fullScreen
            open={status}
            onClose={() => handleClose()}
            TransitionComponent={Transition}
            data-testid={`preparationModaleOpened`}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolBar} disableGutters>
                    {appBarAction.map((action, index) => (
                        <Button
                            key={`btn-${index}`}
                            onClick={() => {
                                action.action()
                                handleClose()
                            }}
                            className={
                                action.disabled
                                    ? `${classes.actionButtons} ${classes.disabledActionButtons}`
                                    : classes.actionButtons
                            }
                            startIcon={action?.startIcon}
                            endIcon={action?.endIcon}
                            disabled={action.disabled}
                            data-testid={`DialogHeaderButton_${index}`}
                        >
                            {action.label}
                        </Button>
                    ))}
                </Toolbar>
            </AppBar>
            {title && (
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} className={classes.grid}>
                        <Typography variant="h2" className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {children[step]}
            {finishAction && (
                <Box
                    className={finishAction.disabled ? `${classes.sticky} ${classes.disabledSticky}` : classes.sticky}
                    textAlign="center"
                >
                    <Button
                        key={`btn-finish`}
                        onClick={() => {
                            finishAction.action()
                            handleClose()
                        }}
                        variant="contained"
                        disabled={finishAction.disabled}
                        hidden={finishAction.hidden}
                        className={finishAction.hidden ? classes.hidden : classes.finishButton}
                        startIcon={finishAction.startIcon}
                        endIcon={finishAction.endIcon}
                        data-testid={`buttonFinish`}
                    >
                        {finishAction.label}
                    </Button>
                </Box>
            )}
        </Dialog>
    )
}

export default DialogStepper
