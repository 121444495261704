import * as React from 'react'
import { useState, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import NoBatchPanel from './NoBatchPanel'
import { BatchStatus, IBatch } from 'interfaces/Batch.interface'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'
import { BATCHES_URL } from 'utils/routes/backend'
import CreateBatchModal from './CreateBatchModal'
import BatchList from './BatchList'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('md')]: {
                marginTop: theme.space(6),
            },
        },
    }),
)

const BatchesListTab: React.FC = () => {
    const classes = useStyles()
    const buildErrorMessage = useErrorMessage()

    const [batches, setBatches] = useState<IBatch[]>([])
    const [isCreateBatchModalOpened, setIsCreateBatchModalOpened] = useState<boolean>(false)
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    function sortBatches(batches: IBatch[]) {
        const finishedBatches = batches.filter((batch) => batch.status === BatchStatus.FINISHED)
        const unfinishedBatches = batches.filter((batch) => batch.status !== BatchStatus.FINISHED)
        return [...unfinishedBatches, ...finishedBatches]
    }

    async function fetchBatches() {
        try {
            const batchesRequest = await rmsApi.get(BATCHES_URL)
            const batches = sortBatches(batchesRequest.data)
            setBatches(batches)
        } catch (e) {
            toast.error(buildErrorMessage(e, undefined, 'new_orders.errors.fetchBatchesError'))
        }
    }

    useEffect(() => {
        fetchBatches()
    }, [selectedWarehouse])

    const openCreateBatchModal = () => setIsCreateBatchModalOpened(true)
    const closeCreateBatchModal = () => setIsCreateBatchModalOpened(false)

    return (
        <div className={classes.root}>
            {batches.length === 0 ? (
                <NoBatchPanel openCreateBatchModal={openCreateBatchModal} />
            ) : (
                <BatchList batches={batches} openCreateBatchModal={openCreateBatchModal} />
            )}
            {isCreateBatchModalOpened && (
                <CreateBatchModal
                    open={isCreateBatchModalOpened}
                    handleClose={closeCreateBatchModal}
                    fetchBatches={fetchBatches}
                />
            )}
        </div>
    )
}

export default BatchesListTab
