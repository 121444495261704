import * as React from 'react'
import { useEffect } from 'react'
import { QueryState, useApi } from 'utils/hooks/useApi.hook'
import { toast } from 'utils/toast'
import { SECOND_HAND_REQUEST_URL } from 'utils/routes/backend'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TakebackPage from '_pages/takebackDetail/Takeback'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'
import Page from '_organisms/Page'

const Takeback: React.FC = () => {
    const { t } = useTranslation()
    const { takebackId } = useParams()

    const { result: secondHandRequest, error: userInfoError, state: shipmentQueryState } = useApi(
        SECOND_HAND_REQUEST_URL.replace(':secondHandRequestId', takebackId || ''),
    )
    const loadPrismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.loadPrismicQualities)

    React.useEffect(() => {
        if (secondHandRequest) loadPrismicQualities(secondHandRequest.shipment.renter.customer.prismicTemplate)
        // eslint-disable-next-line
    }, [secondHandRequest])

    useEffect(() => {
        if (userInfoError) toast.error(t('takebacks.loadParcelError', { message: userInfoError.message }))
        // eslint-disable-next-line
    }, [userInfoError])

    return (
        <Page
            section={t('menu.takebacks.takebackList')}
            title={`${t('takebacks.takebackTitle')}${secondHandRequest?.id}`}
        >
            <TakebackPage secondHandRequest={secondHandRequest} isLoading={shipmentQueryState === QueryState.LOADING} />
        </Page>
    )
}

export default Takeback
