import { IUniqueProduct } from './UniqueProduct.interface'
import { ICarrier } from './Carrier.interface'
import { Renter } from './Renter.interface'
import { Order } from './Order.interface'
import { IParcel } from './Parcel.interface'
import { SecondHandRequest } from './SecondHand.interface'

export interface Shipment {
    id: number
    carrier: ICarrier | null
    order: Order | null
    uniqueProducts: IUniqueProduct[]
    isReturn: boolean
    shippingDate?: string
    status: ShipmentStatus
    renter?: Renter
    parcels?: IParcel[]
    secondHandRequest?: SecondHandRequest
    deliveryNoteUrl: string
    secondHandRequestId?: number
    rightOfWithdrawal?: boolean
}

export enum ShipmentStatus {
    CREATED = 'CREATED',
    TO_PREPARE = 'TO_PREPARE',
    PREPARATION_IN_PROGRESS = 'PREPARATION_IN_PROGRESS',
    PREPARATION_DELAYED = 'PREPARATION_DELAYED',
    PREPARATION_ERROR = 'PREPARATION_ERROR',
    PREPARATION_CANCELED = 'PREPARATION_CANCELED',
    TO_BE_DELIVERED = 'TO_BE_DELIVERED',
    TO_WITHDRAW = 'TO_WITHDRAW',
    SHIPPED = 'SHIPPED',
    ARRIVED = 'ARRIVED',
    AWAITING_RETURN = 'AWAITING_RETURN',
    IN_TRANSIT = 'IN_TRANSIT',
    RETURNED = 'RETURNED',
    PROCESSED = 'PROCESSED',
    CARRIER_LATE = 'CARRIER_LATE',
    RENTER_LATE = 'RENTER_LATE',
    ERROR = 'ERROR',
}

export enum ShipmentStatusDisplay {
    CREATED = 'neutral',
    TO_PREPARE = 'primary',
    PREPARATION_IN_PROGRESS = 'info',
    PREPARATION_DELAYED = 'error',
    PREPARATION_ERROR = 'warning',
    PREPARATION_CANCELED = 'warning',
    TO_BE_DELIVERED = 'primary',
    TO_WITHDRAW = 'primary',
    SHIPPED = 'info',
    ARRIVED = 'success',
    AWAITING_RETURN = 'warning',
    IN_TRANSIT = 'info',
    RETURNED = 'secondary',
    PROCESSED = 'secondary',
    CARRIER_LATE = 'error',
    RENTER_LATE = 'error',
    ERROR = 'error',
}

export const FORTH_SHIPMENT_STATUS = [
    ShipmentStatus.CREATED,
    ShipmentStatus.TO_PREPARE,
    ShipmentStatus.PREPARATION_IN_PROGRESS,
    ShipmentStatus.PREPARATION_DELAYED,
    ShipmentStatus.PREPARATION_CANCELED,
    ShipmentStatus.TO_BE_DELIVERED,
    ShipmentStatus.TO_WITHDRAW,
    ShipmentStatus.SHIPPED,
    ShipmentStatus.IN_TRANSIT,
    ShipmentStatus.ARRIVED,
]
