import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core'

import { IUniqueProduct } from 'interfaces'
import { UNIQUE_PRODUCT_UPDATE_LOCATION_URL, UNIQUE_PRODUCTS_BY_BARCODEUID_URL } from 'utils/routes/backend'
import AccordionStepper from 'components/wmsStepper/AccordionStepper'
import Step from 'components/wmsStepper/Step'
import ProductWithoutDisabledStepInput from 'components/WMS/ProductWithoutDisabledStepInput'
import { CollapsibleTable } from 'components/reusable/Table'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import CheckIcon from '@material-ui/icons/Check'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page from '_organisms/Page'
import { ErrorCode } from '../utils/errors'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        removeButton: {
            color: theme.palette.error.main,
            padding: 0,
            minWidth: 0,
        },
        puidListContainer: {
            marginTop: theme.spacing(3),
        },
        sticky: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: theme.zIndex.modal,
                background: theme.palette.success.main,
                boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)',
            },
        },
        finishButton: {
            [theme.breakpoints.down('sm')]: {
                borderRadius: 0,
                width: '100%',
                padding: theme.spacing(2),
            },
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.success.dark,
            },
        },
    }),
)

enum stepLevel {
    LOCATION = 0,
    PUID = 1,
    FINISHED = 2,
}

const LinkLocationBulk: React.FC = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const buildErrorMessage = useErrorMessage()
    const [expanded, setExpanded] = React.useState<number | null>(null)
    const [currentStep, setCurrentStep] = React.useState<number>(0)
    const [stepperValues, setStepperValues] = React.useState<any>([])
    const [puidList, setPuidList] = React.useState<IUniqueProduct[]>([])
    const [setDisabledSelectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.setDisabledSelectedCustomers,
    ])
    const [setDisabledSelectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.setDisabledSelectedWarehouse,
    ])

    const headers = [
        { id: 'deleteRow', label: '' },
        { id: 'currentLocation', label: t('wms.linkLocationBulk.table.currentLocation') },
        { id: 'puid', label: t('wms.linkLocationBulk.table.puid') },
    ]

    function puidToRow(product: IUniqueProduct, index: number): any {
        const action = (
            <Button
                className={classes.removeButton}
                onClick={() => {
                    deletePuidFromList(index)
                }}
                size="small"
                data-testid={`deleteRow_${product.barcodeUid}`}
            >
                <RemoveCircleOutlineIcon />
            </Button>
        )

        return {
            displayed: [action, product.storageLocation, product.barcodeUid],
        }
    }

    const rows = puidList.map((puid, index) => puidToRow(puid, index))

    const addPuidToList = async (stepUpdated: number, submittedStep: any) => {
        if (
            submittedStep &&
            (!stepperValues[stepLevel.PUID] || stepperValues[stepLevel.PUID]?.indexOf(submittedStep) < 0)
        ) {
            const product = await fetchPuidLocation(submittedStep)

            if (product) {
                puidList.push(product)
                setPuidList(puidList)
                handleStepperUpdated(
                    stepLevel.PUID,
                    puidList.map((uniqueProduct) => {
                        return uniqueProduct.barcodeUid
                    }),
                )
            }
        } else {
            toast.error(t('wms.linkLocationBulk.emptyOrAlreadyInList'))
        }
    }

    function deletePuidFromList(id: number): void {
        puidList.splice(id, 1)
        setPuidList(puidList)
        handleStepperUpdated(
            stepLevel.PUID,
            puidList.map((uniqueProduct) => {
                return uniqueProduct.barcodeUid
            }),
        )
    }

    const updateStorageLocation = async function (): Promise<{
        uniqueProducts: IUniqueProduct[] | null
        error: string
    }> {
        const response: { uniqueProducts: IUniqueProduct[] | null; error: string } = {
            uniqueProducts: null,
            error: '',
        }

        const payload = {
            barcodeUid: stepperValues[stepLevel.PUID],
            storageLocation: stepperValues[stepLevel.LOCATION],
        }

        try {
            const updateLocationRequest = await rmsApi.patch(UNIQUE_PRODUCT_UPDATE_LOCATION_URL, payload)
            response.uniqueProducts = updateLocationRequest.data
        } catch (e: any) {
            const errorData = e.response?.data
            const { status, message } = errorData

            if (status === 403 && message === ErrorCode.SECOND_HAND_WITHOUT_PHOTOS) {
                response.error = t('wms.linkLocationBulk.errorSecondHandWithoutPhotos')
            } else if (message === ErrorCode.NOT_FOUND) {
                response.error = t('wms.linkLocationBulk.error', {
                    PUID: payload.barcodeUid,
                    storageLocation: payload.storageLocation,
                })
            } else {
                response.error =
                    t('wms.linkLocationBulk.unknownError', {
                        PUID: payload.barcodeUid,
                        storageLocation: payload.storageLocation,
                    }) + `: ${e.message}`
            }
        }
        return response
    }

    async function fetchPuidLocation(puid: string): Promise<IUniqueProduct | undefined> {
        try {
            const uniqueProductRequest = await rmsApi.get(UNIQUE_PRODUCTS_BY_BARCODEUID_URL(puid))
            const uniqueProduct = uniqueProductRequest?.data
            if (uniqueProduct.isSecondHand && !uniqueProduct.photos.length) {
                toast.error(t('wms.linkLocationBulk.errorSecondHandWithoutPhotos', { PUID: puid }))
                return undefined
            }
            return uniqueProduct
        } catch (e) {
            toast.error(buildErrorMessage(e, { barcodeUid: puid }, 'wms.linkLocationBulk.unknownError'))
        }
    }

    useEffect(() => {
        setExpanded(currentStep)
    }, [currentStep])

    useEffect(() => {
        if (stepperValues.length >= 1) updateProduct()
        // eslint-disable-next-line
    }, [stepperValues])

    useEffect(() => {
        if (currentStep !== 0 || puidList.length > 0) {
            setDisabledSelectedCustomers(true)
            setDisabledSelectedWarehouse(true)
        } else {
            setDisabledSelectedCustomers(false)
            setDisabledSelectedWarehouse(false)
        }
    }, [currentStep, puidList])

    const handleChange = (panel: number) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
        if (
            (panel === 1 && !stepperValues[stepLevel.PUID] && stepperValues[stepLevel.PUID] === '') ||
            (panel === 0 && !stepperValues[stepLevel.LOCATION] && stepperValues[stepLevel.LOCATION] === '')
        )
            return
        else if (isExpanded) setCurrentStep(panel)
    }

    const handleStepperUpdated = async (stepUpdated: number, submittedStep: any) => {
        if (!submittedStep && submittedStep !== false) return
        const newStepperValues = [...stepperValues]
        newStepperValues[stepUpdated] = submittedStep
        setStepperValues(newStepperValues)
    }

    const handleNextStep = (overrideStep?: number) => {
        if (overrideStep != null && overrideStep >= stepLevel.LOCATION) {
            setCurrentStep(overrideStep)
        } else {
            setCurrentStep(currentStep + 1)
        }
    }

    async function updateProduct() {
        if (!stepperValues[stepLevel.LOCATION]) {
            handleNextStep(stepLevel.LOCATION)
        } else if (!stepperValues[stepLevel.FINISHED]) {
            handleNextStep(stepLevel.PUID)
        }
        if (stepperValues[stepLevel.PUID] && stepperValues[stepLevel.LOCATION] && stepperValues[stepLevel.FINISHED]) {
            toast.info(t('wms.linkLocationBulk.loading'))
            const response = await updateStorageLocation()
            toast.dismiss()
            if (!response.uniqueProducts) {
                toast.error(response.error)
                handleStepperUpdated(stepLevel.FINISHED, false)
            } else {
                toast.success(
                    t('wms.linkLocationBulk.success', {
                        puidCount: stepperValues[stepLevel.PUID].length,
                        storageLocation: stepperValues[stepLevel.LOCATION],
                    }),
                )
                setStepperValues([])
                setPuidList([])
                handleNextStep(stepLevel.LOCATION)
            }
        }
    }

    return (
        <Page title={t('wms.linkLocationBulk.title')}>
            <AccordionStepper>
                <Step
                    expanded={expanded}
                    handleChange={handleChange}
                    stepTitle={t('wms.linkLocationBulk.storageLocation.title')}
                    stepSubTitle={t('wms.linkLocationBulk.storageLocation.subTitle')}
                >
                    <ProductWithoutDisabledStepInput
                        label={t('wms.linkLocationBulk.storageLocation.inputLabel')}
                        handleStepperUpdated={handleStepperUpdated}
                        defaultValue={stepperValues[stepLevel.LOCATION]}
                    />
                </Step>
                <Step
                    expanded={expanded}
                    handleChange={handleChange}
                    stepTitle={t('wms.linkLocationBulk.puid.title')}
                    stepSubTitle={t('wms.linkLocationBulk.puid.subTitle')}
                >
                    <>
                        <ProductWithoutDisabledStepInput
                            label={t('wms.linkLocationBulk.puid.inputLabel')}
                            handleStepperUpdated={addPuidToList}
                        />
                        {puidList.length >= 1 && (
                            <div className={classes.puidListContainer}>
                                <CollapsibleTable header={headers} rows={rows} />
                                <Box className={classes.sticky} textAlign="center">
                                    <Button
                                        variant="contained"
                                        onClick={() => handleStepperUpdated(stepLevel.FINISHED, true)}
                                        data-testid={`processBulkLink`}
                                        startIcon={<CheckIcon />}
                                        className={classes.finishButton}
                                    >
                                        {t('wms.linkLocationBulk.processBulkLink')}
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </>
                </Step>
            </AccordionStepper>
        </Page>
    )
}

export default LinkLocationBulk
