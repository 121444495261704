import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import Status from '_atoms/badges/Status'
import Button from '_atoms/buttons/Button'
import { ROLES_BY_ACCESS_LEVEL } from 'utils/roles'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import { useAuth } from 'utils/hooks/useAuth'
import Text from '_atoms/text/Text'
import { ActionType } from '_organisms/drawerMenu/AccountPopover'

const useStyles = makeStyles((theme) => ({
    accountRole: {
        textTransform: 'none',
    },
    accountButton: {
        paddingLeft: 0,
        height: theme.space(17 / 5),
        justifyContent: 'left',
        '&:focus': {
            outline: 'none!important',
        },
    },
    account: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    accountNameAndRole: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.space(3),
    },
    accountSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    accountVersionAndLogout: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dashedDivider: {
        margin: theme.space(3, 0),
        borderBottom: `1px dashed ${theme.palette.neutral[200]}`,
    },
    version: {
        color: theme.palette.grey[400],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

type AccountProps = {
    setStep: (actionType: ActionType | undefined) => void
    logout: () => void
}

const Account: React.FC<AccountProps> = ({ setStep, logout }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const [user] = useConnectedUserStore((state: ConnectedUserState) => [state.user])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const { roles } = useAuth()

    function getHighestAccessRole() {
        const rmsRoles = roles?.roles.filter((role: string) => /^\p{Lu}/u.test(role)) // check if role starts with uppercase, if so, it's a rms role
        const highestRole = ROLES_BY_ACCESS_LEVEL.find((rolesByAccessLevel) => {
            if (rmsRoles.includes(rolesByAccessLevel)) {
                return rolesByAccessLevel
            }
        })
        return highestRole || ''
    }

    return (
        <div className={classes.account}>
            <div className={classes.accountNameAndRole}>
                <Text variant={'S1'}>{user.name}</Text>
                <Status label={getHighestAccessRole()} className={classes.accountRole} color="neutral" />
            </div>
            {user.customers.length === 1 && (
                <div className={classes.accountSection}>
                    <Text variant={'S3'} color={theme.palette.neutral[500]}>
                        {t('menu.account.customer')}
                    </Text>
                    <Text variant={'S2'}>{selectedCustomers[0].name}</Text>
                </div>
            )}
            <div className={classes.accountSection}>
                <Text variant={'S3'} color={theme.palette.neutral[500]}>
                    {t('menu.account.warehouse')}
                </Text>
                {user.warehouses.length === 1 || user.warehouses.length === 0 ? (
                    <Text variant={'S2'}>{selectedWarehouse?.name}</Text>
                ) : (
                    <Button
                        label={selectedWarehouse?.name}
                        color="neutral"
                        variant={'ghost'}
                        size={'small'}
                        endIcon={faChevronRight}
                        onClick={() => setStep(ActionType.WAREHOUSE_PICKER)}
                        className={classes.accountButton}
                        data-testid="select-warehouse"
                    />
                )}
            </div>
            {user.parcelShop && (
                <div className={classes.accountSection}>
                    <Text variant={'S3'} color={theme.palette.neutral[500]}>
                        {t('menu.account.shop')}
                    </Text>
                    <Button
                        label={user.parcelShop.name}
                        color="neutral"
                        variant={'ghost'}
                        size={'small'}
                        endIcon={faChevronRight}
                        onClick={() => setStep(ActionType.PARCEL_SHOP_INFO)}
                        className={classes.accountButton}
                    />
                </div>
            )}
            <span className={classes.dashedDivider} />
            <div className={classes.accountVersionAndLogout}>
                <div className={classes.version}>
                    {t('global.version')}
                    {import.meta.env.PACKAGE_VERSION}
                </div>
                <Button
                    label={t('global.signOut')}
                    color="neutral"
                    variant={'ghost'}
                    size={'small'}
                    endIcon={faArrowRightFromBracket}
                    onClick={logout}
                />
            </div>
        </div>
    )
}

export default Account
