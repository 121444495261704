import * as React from 'react'
import { ReactElement, useState } from 'react'
import Confettis from '_atoms/Confettis'

export const useConfetti = (): { confetti: () => ReactElement; trigConfetti: () => void } => {
    const [isActive, setIsActive] = useState<boolean>(false)

    function trigConfetti() {
        setIsActive(true)
        setTimeout(() => setIsActive(false), 3000)
    }

    const confetti = () => <Confettis active={isActive} />
    return { confetti, trigConfetti }
}
