import { RMSFile } from 'utils/files'
import { IProductReference } from './ProductReference.interface'
import { ReturnItem } from './ReturnItem.interface'
import { ProductRequest } from './SecondHand.interface'

export interface IUniqueProductRefit {
    id: number
    barcodeUid: string
    lastInstructionOrder: number | null
    cycles?: number
    productReference: IProductReference
}

export enum UniqueProductStatus {
    TO_RESTOCK = 'TO_RESTOCK',
    TO_REFIT = 'TO_REFIT',
    OUT_OF_ORDER = 'OUT_OF_ORDER',
    REFIT_IN_PROGRESS = 'REFIT_IN_PROGRESS',
    IN_STOCK = 'IN_STOCK',
    AT_RENTER_HOME = 'AT_RENTER_HOME',
    LOST = 'LOST',
    RETURNED_TO_CUSTOMER = 'RETURNED_TO_CUSTOMER',
    SOLD = 'SOLD',
    TO_REPAIR = 'TO_REPAIR',
    REFUSED = 'REFUSED',
    TO_PUBLISH = 'TO_PUBLISH',
    NOT_SET = 'NOT_SET',
    RESERVED = 'RESERVED',
    PICKED = 'PICKED',
    PACKED = 'PACKED',
}

export enum UniqueProductStatusFront {
    TO_PROCESS = 'TO_PROCESS',
    UNTRACEABLE = 'UNTRACEABLE',
}

export enum UniqueProductStatusDisplay {
    TO_RESTOCK = 'primary',
    TO_REFIT = 'primary',
    OUT_OF_ORDER = 'error',
    REFIT_IN_PROGRESS = 'primary',
    IN_STOCK = 'success',
    AT_RENTER_HOME = 'neutral',
    LOST = 'error',
    RETURNED_TO_CUSTOMER = 'success',
    SOLD = 'success',
    TO_REPAIR = 'warning',
    REFUSED = 'error',
    TO_PUBLISH = 'success',
    NOT_SET = 'primary',
    TO_PROCESS = 'neutral',
    RESERVED = 'primary',
    UNTRACEABLE = 'neutral',
    PICKED = 'neutral',
    PACKED = 'neutral',
}

export const uniqueProductStatusResponsabilityLevel = {
    low: [
        UniqueProductStatus.TO_RESTOCK,
        UniqueProductStatus.TO_REFIT,
        UniqueProductStatus.OUT_OF_ORDER,
        UniqueProductStatus.TO_REPAIR,
    ],
    get medium(): UniqueProductStatus[] {
        return [
            ...this.low,
            UniqueProductStatus.IN_STOCK,
            UniqueProductStatus.LOST,
            UniqueProductStatus.RETURNED_TO_CUSTOMER,
        ]
    },
    get high(): UniqueProductStatus[] {
        return [
            ...this.medium,
            UniqueProductStatus.AT_RENTER_HOME,
            UniqueProductStatus.REFIT_IN_PROGRESS,
            UniqueProductStatus.SOLD,
            UniqueProductStatus.TO_PUBLISH,
        ]
    },
}

export const forbiddenStatusByCurrentUniqueProductStatus: Record<UniqueProductStatus, UniqueProductStatus[]> = {
    [UniqueProductStatus.REFIT_IN_PROGRESS]: [
        UniqueProductStatus.TO_RESTOCK,
        UniqueProductStatus.IN_STOCK,
        UniqueProductStatus.AT_RENTER_HOME,
        UniqueProductStatus.SOLD,
        UniqueProductStatus.TO_PUBLISH,
    ],
    [UniqueProductStatus.TO_RESTOCK]: [],
    [UniqueProductStatus.TO_REFIT]: [],
    [UniqueProductStatus.OUT_OF_ORDER]: [],
    [UniqueProductStatus.TO_REPAIR]: [],
    [UniqueProductStatus.IN_STOCK]: [],
    [UniqueProductStatus.LOST]: [],
    [UniqueProductStatus.RETURNED_TO_CUSTOMER]: [],
    [UniqueProductStatus.AT_RENTER_HOME]: [],
    [UniqueProductStatus.SOLD]: [],
    [UniqueProductStatus.TO_PUBLISH]: [],
    [UniqueProductStatus.REFUSED]: [],
    [UniqueProductStatus.NOT_SET]: [],
    [UniqueProductStatus.RESERVED]: [],
    [UniqueProductStatus.PICKED]: [],
    [UniqueProductStatus.PACKED]: [],
}

export interface IUniqueProduct {
    id: number
    barcodeUid: string
    lastInstructionOrder: null | number
    cycles: 0
    status: UniqueProductStatus
    createdAt: Date
    updatedAt: Date
    productReference: IProductReference
    storageLocation: string
    secondHandProductRequest: ProductRequest
    quality: string
    returnItems?: ReturnItem[]
    photos?: RMSFile[]
    isSecondHand?: boolean
}
