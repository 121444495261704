import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IUniqueProduct } from 'interfaces'
import GridLabeledValue from '_molecules/GridLabeledValue'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import InfoQualityModal from './InfoQualityModal'
import usePrismicQualitiesStore, { PrismicQualitiesState } from '../../utils/store/usePrismicQualities.store'
import Button from '_atoms/buttons/Button'
import SetQualityModal from './SetQualityModal'
import { toast } from 'utils/toast'
import { UNIQUE_PRODUCT_UPDATE_URL } from 'utils/routes/backend'
import rmsApi from 'utils/api'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardDetailContainer: {
            flexDirection: 'row-reverse',
            borderRadius: '10px',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.neutral[100],
            },
            [theme.breakpoints.up('sm')]: {
                border: `solid 1px ${theme.palette.neutral[200]}`,
            },
        },
        cardDetailItem: {
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
            },
        },
        label: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 500,
            lineHeight: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(13),
            },
        },
        value: {
            fontWeight: 500,
            lineHeight: '22px',
            fontSize: theme.typography.pxToRem(14),
        },
        iconInfo: {
            height: '13px',
            color: 'rgba(20, 34, 61, 0.5)',
            cursor: 'pointer',
        },
        ellipsisButton: {
            minWidth: '30px',
            height: '16px',
            fontSize: theme.typography.pxToRem(16),
            margin: theme.spacing(0, 1.25),
        },
    }),
)
type UniqueProductSecondHandQualityDetailsType = {
    uniqueProduct: IUniqueProduct
    className?: string
    setUniqueProduct: (uniqueProduct: IUniqueProduct) => void
}

const UniqueProductSecondHandQualityDetails: React.FC<UniqueProductSecondHandQualityDetailsType> = ({
    uniqueProduct,
    className,
    setUniqueProduct,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [isInfoQualityModalOpen, setInfoQualityModalOpen] = React.useState(false)
    const [isUpdateQualityModalOpen, setUpdateQualityModalOpen] = React.useState(false)
    const prismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.qualities)
    const prismicQuality = prismicQualities?.find((prismicQuality) => prismicQuality.value === uniqueProduct.quality)
    const uniqueProductQuality = prismicQuality?.label || uniqueProduct.quality

    async function handleSetQuality(quality: string) {
        try {
            await rmsApi.patch(UNIQUE_PRODUCT_UPDATE_URL(uniqueProduct.id), { quality: quality })
            setUniqueProduct({ ...uniqueProduct, quality })
        } catch (e) {
            toast.error(t('secondHand.updateQuality.error'))
        }
    }

    return (
        <>
            <Box
                alignItems="center"
                className={`${classes.cardDetailContainer} ${className || ''}`}
                data-testid="cardDetailContainer"
            >
                <Box className={classes.cardDetailItem}>
                    <Grid container alignItems="center">
                        <GridLabeledValue
                            label={t('wms.uniqueProducts.quality')}
                            labelClassName={classes.label}
                            valueClassName={classes.value}
                            labelAddon={() => {
                                return (
                                    <FontAwesomeIcon
                                        className={classes.iconInfo}
                                        onClick={() => {
                                            setInfoQualityModalOpen(true)
                                        }}
                                        icon={faCircleInfo}
                                    />
                                )
                            }}
                            valueAddon={() => {
                                return (
                                    <Button
                                        color="neutral"
                                        variant="stroke"
                                        startIcon={faEllipsis}
                                        className={classes.ellipsisButton}
                                        onClick={() => {
                                            setUpdateQualityModalOpen(true)
                                        }}
                                        data-testid="updateQualityButton"
                                    />
                                )
                            }}
                        >
                            {uniqueProductQuality}
                        </GridLabeledValue>
                        <GridLabeledValue
                            label={t('wms.uniqueProducts.color')}
                            labelClassName={classes.label}
                            valueClassName={classes.value}
                        >
                            {uniqueProduct.productReference.color}
                        </GridLabeledValue>
                        <GridLabeledValue
                            label={t('wms.uniqueProducts.size')}
                            labelClassName={classes.label}
                            valueClassName={classes.value}
                        >
                            {uniqueProduct.productReference.size}
                        </GridLabeledValue>
                    </Grid>
                </Box>
            </Box>
            <SetQualityModal
                isOpen={isUpdateQualityModalOpen}
                handleClose={() => {
                    setUpdateQualityModalOpen(false)
                }}
                handleSetQuality={handleSetQuality}
                puidQuality={uniqueProduct.quality}
                prismicQualities={prismicQualities}
            />
            <InfoQualityModal
                isOpen={isInfoQualityModalOpen}
                handleClose={() => {
                    setInfoQualityModalOpen(false)
                }}
            />
        </>
    )
}

export default UniqueProductSecondHandQualityDetails
