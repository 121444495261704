import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, Grid, makeStyles, Tab, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { AxiosResponse } from 'axios'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { OMS_STATS_URL } from 'utils/routes/backend'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import Figure from 'components/statistics/Figure'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { ICustomer } from 'interfaces/Customer'
import {
    getChartsColors,
    getDoughnutOptions,
    getStatsStyles,
    getVerticalBarChartOptions,
} from 'components/reusable/utils/StatsUtils'
import { toast } from 'utils/toast'
import LoadingIndicator from 'components/reusable/LoadingIndicator'
import Page from '_organisms/Page'
import DateRangePicker from '_atoms/inputs/DateRangePicker'
import { BasicText } from '_atoms/text/BasicText'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { CsvType, downloadOrdersOrProductsCsv } from 'components/reusable/utils/OrdersManagement'
import { ORDERS_ROUTE } from 'utils/routes/frontend'
import rmsApi from 'utils/api'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import ExportButton from '../../_atoms/inputs/ExportButton'
import CustomerPicker from '_organisms/header/addons/CustomerPicker'

ChartJS.register(...registerables)

const statsStyles = getStatsStyles()
const useStyles = makeStyles(() => createStyles(statsStyles))

const OrderManagementStatsApp: React.FC = () => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [selectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])
    const [activeTab, setActiveTab] = useState<string>('summary')
    const [stats, setStats] = useState<any>(null)
    const [fullChartOptions, setFullChartOptions] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean | string>(true)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())

    dayjs.locale(i18n.language)

    useEffect(() => {
        if (selectedCustomer) fetchStats()
        // eslint-disable-next-line
    }, [selectedCustomer, startDate, endDate])

    async function fetchStats() {
        try {
            setIsLoading(true)
            const statsUrl = OMS_STATS_URL
            let params = `?customerId=${selectedCustomer?.id}&date=${dayjs().format('YYYY-MM-DD')}`
            if (startDate && endDate) {
                const from = dayjs(startDate).format('YYYY-MM-DD')
                const to = dayjs(endDate).format('YYYY-MM-DD')
                params += `&from=${from}&to=${to}`
            }
            const statsRequest: AxiosResponse = await rmsApi.get(statsUrl + params)
            formatStatsData(statsRequest.data)
        } catch (e: any) {
            setIsLoading('error')
            toast.error(e)
        }
    }

    const doughnutOptions: any = getDoughnutOptions()
    const barChartOptions: any = getVerticalBarChartOptions()
    const chartColors: string[] = getChartsColors()
    ChartJS.overrides.doughnut.plugins.tooltip = doughnutOptions.plugins.tooltip

    const formatStatsData = (statsToFormat: any) => {
        const formattedData: any = {}
        const buildingChartOptions: any = {}
        setFullChartOptions({})
        formattedData.totalOrders = {
            figureDescription: 'omsStats.figures.totalOrders.description',
            value: Number(statsToFormat.totalOrders).toLocaleString(),
            hint: 'omsStats.figures.totalOrders.hint',
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { total, ...totalOrdersByStatus } = statsToFormat.totalOrdersByStatus
        formattedData.totalOrdersByStatus = {
            // eslint-disable-next-line
            labels: Object.entries(totalOrdersByStatus).map(([key, value]) => {
                return t(`omsStats.figures.totalOrdersByStatus.${key}`)
            }),
            datasets: [
                {
                    label: 'label',
                    backgroundColor: chartColors,
                    // eslint-disable-next-line
                    data: Object.entries(totalOrdersByStatus).map(([key, value]) => {
                        return value
                    }),
                },
            ],
            hint: 'omsStats.figures.totalOrdersByStatus.hint',
        }
        buildingChartOptions.totalOrdersByStatus = doughnutOptions
        buildingChartOptions.totalOrdersByStatus = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.totalOrdersByStatus.plugins.title.text = t(
            'omsStats.figures.totalOrdersByStatus.description',
        )
        formattedData.turnover = {
            figureDescription: 'omsStats.figures.turnover.description',
            value: Number(statsToFormat.turnover).toLocaleString() + ' €',
            hint: 'omsStats.figures.turnover.hint',
        }
        formattedData.basketAvg = {
            figureDescription: 'omsStats.figures.basketAvg.description',
            value: Number(statsToFormat.basketAvg).toLocaleString() + ' €',
            hint: 'omsStats.figures.basketAvg.hint',
        }
        formattedData.totalProductsOrdered = {
            figureDescription: 'omsStats.figures.totalProductsOrdered.description',
            value: Number(statsToFormat.totalProductsOrdered).toLocaleString(),
            hint: 'omsStats.figures.totalProductsOrdered.hint',
        }
        formattedData.totalOrdersByRange = {
            figureDescription: 'omsStats.figures.totalOrdersByRange.description',
            value: Number(statsToFormat.totalOrdersByRange).toLocaleString(),
            hint: 'omsStats.figures.totalOrdersByRange.hint',
        }
        formattedData.ordersAndTurnoverEvolution = {
            labels: statsToFormat.ordersEvolutions.map((item: any) => {
                return item.mois
            }),
            datasets: [
                {
                    label: t('omsStats.figures.ordersEvolution.description'),
                    backgroundColor: chartColors[0],
                    data: statsToFormat.ordersEvolutions.map((item: any) => {
                        return item.order
                    }),
                },
                {
                    label: t('omsStats.figures.turnoverEvolution.description'),
                    backgroundColor: chartColors[4],
                    data: statsToFormat.ordersEvolutions.map((item: any) => {
                        return item.turnover
                    }),
                },
            ],
        }
        buildingChartOptions.ordersAndTurnoverEvolution = barChartOptions
        buildingChartOptions.ordersAndTurnoverEvolution = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.ordersAndTurnoverEvolution.plugins.title.text = t(
            'omsStats.figures.ordersAndTurnoverEvolution.description',
        )
        formattedData.turnoverByRange = {
            figureDescription: 'omsStats.figures.turnoverByRange.description',
            value: Number(statsToFormat.turnoverByRange).toLocaleString() + ' €',
            hint: 'omsStats.figures.turnoverByRange.hint',
        }
        formattedData.basketAvgByRange = {
            figureDescription: 'omsStats.figures.basketAvgByRange.description',
            value: Number(statsToFormat.basketAvgByRange).toLocaleString() + ' €',
            hint: 'omsStats.figures.basketAvgByRange.hint',
        }
        formattedData.canceledOrdersByRange = {
            figureDescription: 'omsStats.figures.canceledOrdersByRange.description',
            value: Number(statsToFormat.canceledOrdersByRange).toLocaleString(),
            hint: 'omsStats.figures.canceledOrdersByRange.hint',
        }
        formattedData.basketAvgEvolution = {
            labels: statsToFormat.basketAvgEvolutionByRange.map((item: any) => {
                return item.mois
            }),
            datasets: [
                {
                    label: t('omsStats.figures.basketEvolution.description'),
                    backgroundColor: chartColors[0],
                    data: statsToFormat.basketAvgEvolutionByRange.map((item: any) => {
                        return item.total
                    }),
                },
            ],
        }
        buildingChartOptions.basketAvgEvolution = barChartOptions
        buildingChartOptions.basketAvgEvolution = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.basketAvgEvolution.plugins.title.text = t(
            'omsStats.figures.basketAvgEvolution.description',
        )
        formattedData.totalProductsOrderedByRange = {
            figureDescription: 'omsStats.figures.totalProductsOrderedByRange.description',
            value: Number(statsToFormat.totalProductsOrderedByRange).toLocaleString(),
            hint: 'omsStats.figures.totalProductsOrderedByRange.hint',
        }
        formattedData.popularProductsByRange = {
            // eslint-disable-next-line
            labels: statsToFormat.popularProductsByRange.map((item: { total: number; product: string }) => {
                return item.product
            }),
            datasets: [
                {
                    label: 'label',
                    backgroundColor: chartColors,
                    // eslint-disable-next-line
                    data: statsToFormat.popularProductsByRange.map((item: { total: number; product: string }) => {
                        return item.total
                    }),
                },
            ],
            hint: 'omsStats.figures.totalOrdersByStatus.hint',
        }
        buildingChartOptions.popularProductsByRange = doughnutOptions
        buildingChartOptions.popularProductsByRange = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.popularProductsByRange.plugins.title.text = t(
            'omsStats.figures.popularProductsByRange.description',
        )
        formattedData.productsAvgByRange = {
            figureDescription: 'omsStats.figures.productsAvgByRange.description',
            value: Number(statsToFormat.productsAvgByRange).toLocaleString(),
            hint: 'omsStats.figures.productsAvgByRange.hint',
        }
        formattedData.uniqueCustomerseByRange = {
            figureDescription: 'omsStats.figures.uniqueCustomerseByRange.description',
            value: Number(statsToFormat.uniqueCustomerseByRange).toLocaleString(),
            hint: 'omsStats.figures.uniqueCustomerseByRange.hint',
        }
        formattedData.subscribersByRange = {
            figureDescription: 'omsStats.figures.subscribersByRange.description',
            value: Number(statsToFormat.subscribersByRange).toLocaleString(),
            hint: 'omsStats.figures.subscribersByRange.hint',
        }
        setFullChartOptions(buildingChartOptions)
        setStats(formattedData)
        setIsLoading(false)
    }

    const handleTabChange = (event: any, newValue: any) => {
        setActiveTab(newValue)
    }

    const handleEndDateChange = (endDateToSet: Dayjs) => {
        if (endDateToSet.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDateToSet).subtract(1, 'day'))
            setEndDate(endDateToSet)
        } else {
            setEndDate(endDateToSet)
        }
    }

    const handleExportCsv = async (startDate: Dayjs, endDate: Dayjs, customer: ICustomer, csvType: CsvType) => {
        try {
            await downloadOrdersOrProductsCsv({ startDate, endDate, customer }, csvType)
        } catch (e) {
            toast.error(t('statistics.downloadCsvError', { error: e }))
        }
    }

    const exportAllButton = () => {
        return (
            <div className={classes.mainExportButtonContainer}>
                <div className={classes.customerSelectMargins}>
                    <CustomerPicker initWithRandomCustomer truncateLabelAbove={140} />
                </div>
                {!isMobile && activeTab === 'orders' && (
                    <>
                        <ExportButton
                            onClick={() => handleExportCsv(startDate, endDate, selectedCustomer!, CsvType.ORDERS)}
                        />
                        <Link className={classes.accessUniqueProductsListLink} to={ORDERS_ROUTE}>
                            {t('buttons.accessOrders')}
                        </Link>
                    </>
                )}

                {!isMobile && activeTab === 'products' && (
                    <ExportButton
                        onClick={() => handleExportCsv(startDate, endDate, selectedCustomer!, CsvType.PRODUCTS)}
                    />
                )}
            </div>
        )
    }

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.oms')}
            tagline={t('omsStats.tagline')}
            rightAddon={{ addon: exportAllButton(), displayOnMobile: true }}
            withDivider={false}
        >
            <TabContext value={activeTab}>
                <Box className={classes.tabsBox}>
                    <TabList
                        className={classes.tabs}
                        onChange={handleTabChange}
                        aria-label="stat-tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label={t('omsStats.tabs.summary.menuTitle')} value="summary" data-testid="summary_tab" />
                        <Tab label={t('omsStats.tabs.orders.menuTitle')} value="orders" data-testid="orders_tab" />
                        <Tab
                            label={t('omsStats.tabs.products.menuTitle')}
                            value="products"
                            data-testid="products_tab"
                        />
                        <Tab
                            label={t('omsStats.tabs.customers.menuTitle')}
                            value="customers"
                            data-testid="customers_tab"
                        />
                    </TabList>
                </Box>
                <TabPanel className={classes.tabContent} value="summary">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('omsStats.tabs.summary.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('omsStats.tabs.summary.tagline')}</Typography>

                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.totalOrders && (
                                <Figure testId="summary-main-figure" figure={stats.totalOrders} main />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.chartContainer}>
                                {stats?.totalOrdersByStatus && (
                                    <Chart
                                        type="doughnut"
                                        data={stats?.totalOrdersByStatus}
                                        options={fullChartOptions?.totalOrdersByStatus}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.spacer} />
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.turnover && <Figure testId="turnover" figure={stats.turnover} withColor />}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.basketAvg && <Figure testId="basketAvg" figure={stats.basketAvg} withColor />}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.totalProductsOrdered && (
                                <Figure testId="totalProductsOrdered" figure={stats.totalProductsOrdered} withColor />
                            )}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="orders">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('omsStats.tabs.orders.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('omsStats.tabs.orders.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                    />
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.barChartContainer}>
                                {stats?.ordersAndTurnoverEvolution && (
                                    <Chart
                                        type="bar"
                                        data={stats?.ordersAndTurnoverEvolution}
                                        options={fullChartOptions?.ordersAndTurnoverEvolution}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.totalOrdersByRange && (
                                <Figure
                                    testId="total-orders-on-daterange"
                                    figure={stats.totalOrdersByRange}
                                    withColor
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.turnoverByRange && (
                                <Figure testId="total-orders-on-daterange" figure={stats.turnoverByRange} withColor />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.basketAvgByRange && (
                                <Figure testId="basket-avg-on-daterange" figure={stats.basketAvgByRange} withColor />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.canceledOrdersByRange && (
                                <Figure testId="canceled-orders-on-daterange" figure={stats.canceledOrdersByRange} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.barChartContainer}>
                                {stats?.basketAvgEvolution && (
                                    <Chart
                                        type="bar"
                                        data={stats?.basketAvgEvolution}
                                        options={fullChartOptions?.basketAvgEvolution}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="products">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('omsStats.tabs.products.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('omsStats.tabs.products.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                    />
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.totalProductsOrderedByRange && (
                                <Figure
                                    testId="total-products-on-daterange"
                                    figure={stats.totalProductsOrderedByRange}
                                    main
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.chartContainer}>
                                {stats?.popularProductsByRange && (
                                    <Chart
                                        type="doughnut"
                                        data={stats?.popularProductsByRange}
                                        options={fullChartOptions?.popularProductsByRange}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {stats?.productsAvgByRange && (
                            <Figure
                                testId="products-average-on-daterange"
                                figure={stats.productsAvgByRange}
                                withColor
                            />
                        )}
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="customers">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('omsStats.tabs.customers.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('omsStats.tabs.customers.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                    />
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.uniqueCustomerseByRange && (
                                <Figure
                                    testId="total-customer-on-daterange"
                                    figure={stats.uniqueCustomerseByRange}
                                    main
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.subscribersByRange && (
                                <Figure
                                    testId="total-subscribers-on-daterange"
                                    figure={stats.subscribersByRange}
                                    withColor
                                />
                            )}
                        </Grid>
                    </Grid>
                    <div className={classes.matomoLinkContainer}>
                        <BasicText>{t('omsStats.link')}</BasicText>
                        <a
                            className={classes.matomoLink}
                            href="https://analytics.lizee.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Web analytics{' '}
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={classes.matomoLinkIcon} />
                        </a>
                    </div>
                </TabPanel>
            </TabContext>
        </Page>
    )
}

export default OrderManagementStatsApp
