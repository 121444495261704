import * as React from 'react'
import { useState } from 'react'

import rmsApi from 'utils/api'
import { REFIT_INSTRUCTION_ROUTE } from 'utils/routes/backend'
import InstructionModal from '_organisms/instructionModal/InstructionModal'
import { InstructionModalType } from '_organisms/instructionModal/InstructionModal'
import { IInstructionCategorizing, IRefitInstruction } from 'interfaces/RefitInstruction.interface'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import SearchInstructionModal from './SearchInstructionModal'

type AddInstructionToGroupModalModalProps = {
    customerRefitInstructions: IInstructionCategorizing[]
    handleSubmit: (instruction: IRefitInstruction) => void
    handleClose: () => void
    handleRefresh: () => void
}

const AddInstructionToGroupModal: React.FC<AddInstructionToGroupModalModalProps> = ({
    customerRefitInstructions,
    handleSubmit,
    handleClose,
    handleRefresh,
}) => {
    const { t } = useTranslation()
    const [instructionToAdd, setInstructionToAdd] = useState<IRefitInstruction>()

    async function fetchSelectedInstruction(instruction: IRefitInstruction) {
        try {
            const instructionRequest = await rmsApi.get(REFIT_INSTRUCTION_ROUTE(instruction.id!))
            setInstructionToAdd(instructionRequest.data)
        } catch (e: any) {
            toast.error(t('refit.instructionGroups.errors.fetchInstruction'))
        }
    }

    const instructionsCategorizing = customerRefitInstructions.map(
        ({ id, instructionTitle, categoryTitle, subCategoryTitle }) => ({
            id,
            categorization: `${instructionTitle} / ${categoryTitle} / ${subCategoryTitle} `,
        }),
    )

    if (instructionToAdd) {
        return (
            <InstructionModal
                type={InstructionModalType.ADD_TO_GROUP}
                instruction={instructionToAdd}
                handleRefresh={handleRefresh}
                handleClose={handleClose}
                handleSubmit={() => handleSubmit(instructionToAdd)}
            />
        )
    }

    return (
        <SearchInstructionModal
            handleClose={handleClose}
            handleChange={fetchSelectedInstruction}
            options={instructionsCategorizing}
        />
    )
}

export default AddInstructionToGroupModal
