import * as React from 'react'
import { IRefitInstruction } from 'interfaces'
import InstructionModal, { InstructionModalType } from '_organisms/instructionModal/InstructionModal'
import rmsApi from 'utils/api'
import { REFIT_CATEGORIES_ROUTE, REFIT_INSTRUCTIONS_ROUTE } from 'utils/routes/backend'

type InstructionModalProps = {
    type: InstructionModalType
    instruction?: IRefitInstruction
    parentId?: number
    isOpen?: boolean
    handleClose: () => void
    handleRefresh: () => void
    category?: string
    subCategory?: string
}

const ManageInstructionModal: React.FC<InstructionModalProps> = ({ type, parentId, ...others }) => {
    async function handleSubmit(newInstruction: IRefitInstruction) {
        if (type === InstructionModalType.EDIT) {
            await rmsApi.patch(`${REFIT_INSTRUCTIONS_ROUTE}/${newInstruction.id}`, newInstruction)
        } else {
            if (newInstruction.id) {
                delete newInstruction.id
            }
            await rmsApi.post(
                `${REFIT_CATEGORIES_ROUTE}/${parentId ?? newInstruction?.refitCategory.id}/instructions`,
                newInstruction,
            )
        }
    }

    return <InstructionModal type={type} handleSubmit={handleSubmit} {...others} />
}

export default ManageInstructionModal
