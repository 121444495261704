import * as React from 'react'
import { ReactNode } from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { SimpleLabel } from '_atoms/text/Labels'
import { BasicText, OswaldText } from '_atoms/text/BasicText'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: 'flex',
        },
        row: {
            alignItems: 'center',
        },
        column: {
            flexDirection: 'column',
        },
        labeledValueAndAddonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        label: {
            fontSize: theme.typography.pxToRem(10),
            paddingTop: '1px',
        },
        poppinText: {
            fontSize: theme.typography.pxToRem(12),
            textTransform: 'uppercase',
            color: '#283044',
            padding: '0 2px',
        },
        oswaldText: {
            fontWeight: 300,
        },
    }),
)

type LabeledValueProps = {
    type?: 'row' | 'column'
    text?: 'oswald' | 'poppin'
    className?: string
    labelClassName?: string
    valueClassName?: string
    label: string & ReactNode
    children: any
    addon?: () => JSX.Element
    testId?: string
}

export const LabeledValue: React.FC<LabeledValueProps> = ({
    type = 'row',
    text = 'oswald',
    label,
    children,
    className,
    labelClassName,
    valueClassName,
    addon,
    testId,
}) => {
    const classes = useStyles()

    return (
        <Box className={classes.labeledValueAndAddonContainer} data-testid={testId}>
            <Box className={`${classes.box} ${classes[type]} ${className || ''}`}>
                <SimpleLabel className={`${classes.label} ${labelClassName || ''}`}>{label}</SimpleLabel>
                {text === 'poppin' && (
                    <BasicText className={`${classes.poppinText} ${valueClassName || ''}`}>{children}</BasicText>
                )}
                {text === 'oswald' && (
                    <OswaldText className={`${classes.oswaldText} ${valueClassName || ''}`}>{children}</OswaldText>
                )}
            </Box>
            {addon && addon()}
        </Box>
    )
}

export default LabeledValue
