import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, createStyles, Grid, makeStyles, Theme, Divider } from '@material-ui/core'
import Text from '_atoms/text/Text'
import { IProductWithEans } from 'interfaces'
import { useTranslation } from 'react-i18next'
import Checkbox from '_atoms/inputs/Checkbox'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerRow: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            backgroundColor: theme.palette.primary[50],
            padding: theme.space(2, 4),
            borderRadius: '6px 6px 0px 0px',
            marginBottom: theme.space(1),
        },
        checkboxContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        gridContent: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: theme.space(2, 4),
            cursor: 'pointer',
            '& .MuiGrid-item:last-child': {
                height: 'fit-content',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary[50],
            },
        },
        checked: {
            backgroundColor: theme.palette.primary[100],
            '&:hover': {
                backgroundColor: theme.palette.primary[100],
            },
        },
        checkbox: {
            marginRight: theme.space(1),
            '&:hover': {
                backgroundColor: 'transparent !important',
            },
        },
        eans: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: theme.space(1, 1),
        },
        eanBox: {
            borderRadius: '4px',
            height: theme.space(4),
            backgroundColor: theme.palette.neutral[100],
            padding: theme.space(0.4, 1.9),
        },
        divider: {
            backgroundColor: theme.palette.neutral[100],
        },
    }),
)

type ProductsTableProps = {
    products: IProductWithEans[]
    selectedIds: number[]
    setSelectedProducts: (ids: number[]) => void
}

const ProductsTable: React.FC<ProductsTableProps> = ({ products, selectedIds, setSelectedProducts }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [hovered, setHovered] = useState<number | null>(null)

    function editProductsSelection(ids: number[]) {
        let newSelectedProducts: number[]
        // Select all
        if (ids.length === products.length && selectedIds.length !== ids.length) newSelectedProducts = ids
        // Unselect all
        else if (ids.length === products.length && selectedIds.length === ids.length) newSelectedProducts = []
        // Select / unSelect one product
        else {
            if (selectedIds.includes(ids[0]))
                newSelectedProducts = selectedIds.filter(
                    (selectedProductReference) => selectedProductReference !== ids[0],
                )
            else newSelectedProducts = [...selectedIds, ...ids]
        }
        setSelectedProducts(newSelectedProducts)
    }

    function isChecked(product: IProductWithEans) {
        return !!selectedIds.find((selectedId) => selectedId === product.id)
    }

    function isHovered(product: IProductWithEans) {
        return !isChecked(product) && hovered === product.id
    }

    return (
        <div>
            <Grid
                container
                className={classes.headerRow}
                onClick={() => editProductsSelection(products.map((productRef) => productRef.id))}
            >
                <Grid item xs={6}>
                    <div className={classes.checkboxContainer}>
                        <Checkbox
                            checked={products.length === selectedIds.length}
                            color="primary"
                            size="small"
                            disableRipple
                            className={classes.checkbox}
                        />
                        <Text variant="P5">{t('refit.instructionGroups.productTable.name')}</Text>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Text variant="P5">{t('refit.instructionGroups.productTable.eans')}</Text>
                </Grid>
            </Grid>
            {products.map((product, index) => (
                <div key={index}>
                    <Grid
                        container
                        className={`${classes.gridContent} ${isChecked(product) ? classes.checked : ''}`}
                        onMouseEnter={() => setHovered(product.id)}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => editProductsSelection([product.id])}
                    >
                        <Grid item xs={6}>
                            <div className={classes.checkboxContainer}>
                                <Checkbox
                                    disableRipple
                                    checked={isChecked(product)}
                                    className={classes.checkbox}
                                    indeterminate={isHovered(product)}
                                />
                                <Text variant="P5" data-testid={'product_' + index}>
                                    {product.name}
                                </Text>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.eans}>
                                {product?.eans?.map((ean, index) => (
                                    <Box key={index} className={classes.eanBox}>
                                        <Text variant="N9">{ean}</Text>
                                    </Box>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                </div>
            ))}
        </div>
    )
}

export default ProductsTable
