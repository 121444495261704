import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const primary = '#00233A'

// This implements a base 5 spacing for theme

function factor(values) {
    const css = values.map((value) => `${5 * value}px`)
    return css.join(' ')
}

function space(...args) {
    if (!args) return 5
    else if (args.length === 1) return 5 * args[0]
    else return factor(args)
}

//const theme = createMuiTheme();
export default responsiveFontSizes(
    createMuiTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1120, //changed 960 to 1120
                lg: 1280,
                xl: 1536,
            },
        },
        mixins: {
            toolbar: {
                minHeight: 60,
            },
        },
        zIndex: {
            appBar: 1250,
        },
        typography: {
            useNextVariants: true,
            fontFamily: 'Hanken Grotesk',
            h1: {
                fontWeight: 600,
                fontSize: 36,
                lineHeight: 1.5,
                '@media (max-width:600px)': {
                    fontSize: 22,
                    lineHeight: 1.5,
                },
            },
            h2: {
                fontWeight: 600,
                fontSize: 32,
                lineHeight: 1.5,
                '@media (max-width:600px)': {
                    fontSize: 20,
                    lineHeight: 1.5,
                },
            },
            h3: {
                fontSize: 18,
                lineHeight: 1.4,
                fontWeight: 700,
            },
            h4: {
                fontSize: 16,
                lineHeight: 1.55,
                fontWeight: 700,
            },
            h5: {
                fontSize: 14,
                lineHeight: 1.25,
                fontWeight: 700,
                '@media (max-width: 767px)': {
                    fontSize: 12,
                },
            },
            h6: {
                fontSize: 12,
                lineHeight: 1.25,
                fontWeight: 700,
            },
            body1: {
                fontSize: 15,
                lineHeight: 1.67,
            },
            body2: {
                fontSize: 13,
                lineHeight: 1.67,
            },
            subtitle1: {
                fontSize: '15px',
                fontWeight: 500,
            },
            caption: {
                fontSize: 12,
                lineHeight: 1.17,
                fontWeight: 400,
                color: '#59575c',
            },
            button: {
                fontSize: 15,
                lineHeight: 1.67,
            },
        },
        palette: {
            primary: {
                main: primary,
                contrastText: '#FFFFFF',
                gradient: 'linear-gradient(47deg, #00233A 0%, #566D7C 100%)',
                50: '#F9FAFA',
                100: '#E5E9EB',
                200: '#81929E',
                300: '#566D7C',
                400: '#2B485B',
                500: '#00233A',
                600: primary,
                700: '#00233A',
                800: '#001727',
                900: '#000C13',
            },
            secondary: {
                main: '#B4D2C1',
                contrastText: '#1B2C23',
                gradient: 'linear-gradient(316deg, #B4D2C1 0%, #5A7A67 100%)',
                50: '#F7FAF9',
                100: '#F0F6F3',
                200: '#E8F1EC',
                300: '#E1EDE6',
                400: '#D2E4DA',
                500: '#C3DBCD',
                600: '#B4D2C1',
                700: '#80A08E',
                800: '#5A7A67',
                900: '#1B2C23',
            },
            success: {
                main: '#48C78E',
                contrastText: '#0E281C',
                50: '#EDF9F4',
                100: '#DAF4E8',
                200: '#C8EEDD',
                300: '#B6E9D2',
                400: '#91DDBB',
                500: '#6DD2A5',
                600: '#48C78E',
                700: '#3A9F72',
                800: '#2B7755',
                900: '#0E281C',
            },
            error: {
                main: '#D23333',
                contrastText: '#FFFFFF',
                50: '#FCF1F1',
                100: '#F8DDDD',
                200: '#F0BBBB',
                300: '#E99999',
                400: '#E17777',
                500: '#DA5555',
                600: '#D23333',
                700: '#B22727',
                800: '#8F1F1F',
                900: '#6B1818',
            },
            warning: {
                main: '#F0A029',
                contrastText: '#563709',
                50: '#FDF5EA',
                100: '#FCECD4',
                200: '#FAE2BF',
                300: '#F9D9A9',
                400: '#F6C67F',
                500: '#F3B354',
                600: '#F0A029',
                700: '#CA8621',
                800: '#A36C1A',
                900: '#563709',
            },
            info: {
                main: '#3498DB',
                contrastText: '#FFFFFF',
                50: '#EEF6FC',
                100: '#B5D9F2',
                200: '#9BCCEE',
                300: '#81BFE9',
                400: '#67B2E4',
                500: '#52A7E0',
                600: '#3498DB',
                700: '#2691D9',
                800: '#2385C7',
                900: '#1D6EA5',
            },
            neutral: {
                main: '#6A7590',
                contrastText: '#FFFFFF',
                0: '#ffffff',
                50: '#FCFCFD',
                100: '#F7F7F9',
                200: '#DEE0E7',
                300: '#CDD1DA',
                400: '#B4BAC8',
                500: '#9BA3B6',
                600: '#6A7590',
                700: '#555E73',
                800: '#404656',
                900: '#2A2F3A',
            },
        },
        spacing: 8,
        space,
        props: {
            MuiTextField: {
                variant: 'outlined',
            },
            MuiCheckbox: {
                color: primary,
            },
            MuiRadio: {
                color: primary,
            },
            MuiSwitch: {
                color: primary,
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    'html, body, #root, .App': {
                        height: '100%!important',
                    },
                    body: {
                        backgroundColor: 'rgba(0, 0, 0, 0.87)',
                        height: '100%',
                    },
                    '@keyframes scale': {
                        '0%': {
                            transform: 'scale(1)',
                        },
                        '100%': {
                            transform: 'scale(.99)',
                        },
                    },
                    '#root': {
                        '&.js-modal-openned': {
                            backgroundColor: '#fff',
                            transformOrigin: 'center bottom',
                            transition: 'transform ease-in-out .25s',
                            transform: 'scale(.99)',
                            overflow: 'hidden',
                            willChange: 'transform',
                        },
                        '&.js-modal-closed': {
                            backgroundColor: '#fff',
                            transformOrigin: 'center bottom',
                            transition: 'transform ease-in-out .25s',
                            transform: 'scale(1)',
                            overflow: 'hidden',
                            willChange: 'transform',
                        },
                    },
                },
            },
            MuiCircularProgress: {
                colorPrimary: {
                    color: primary,
                },
            },
            MuiButton: {
                root: {
                    fontWeight: 400,
                    borderRadius: 20,
                    padding: '8px 24px',
                    textTransform: 'none',
                },
                sizeSmall: {
                    padding: '4px 16px',
                    fontSize: 12,
                    borderRadius: 16,
                },
                sizeLarge: {
                    padding: '12px 45px',
                },
                contained: {
                    boxShadow: 'none',
                    '&:hover, &:focus': {
                        boxShadow: 'none',
                    },
                },
                outlinedPrimary: {
                    borderColor: primary,
                },
                outlinedSizeSmall: {},
                endIcon: {
                    '&.MuiButton-iconSizeSmall': {
                        marginLeft: 0,
                    },
                },
                startIcon: {
                    '&.MuiButton-iconSizeSmall': {
                        marginRight: 0,
                    },
                },
            },
            MuiPaper: {
                elevation0: {
                    boxShadow: 'none',
                },
                elevation1: {
                    borderRadius: 10,
                    boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)',
                },
            },
            MuiOutlinedInput: {
                root: {
                    borderRadius: 10,
                },
                adornedEnd: {
                    paddingRight: 0,
                },
            },
            MuiInputLabel: {
                formControl: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: 'calc(100% - 48px)',
                },
            },
            MuiChip: {
                sizeSmall: {
                    borderRadius: 4,
                    fontWeight: 400,
                    fontSize: 12,
                },
                outlined: {
                    borderColor: '#000',
                },
            },
            MuiTimelineDot: {
                root: {
                    boxShadow: 'none',
                },
            },
            MuiTimelineConnector: {
                root: {
                    width: 1,
                    minHeight: 24,
                },
            },
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#132756',
                    padding: '5px 10px',
                },
            },
            MuiAutocomplete: {
                option: {
                    '&[data-focus="true"]': {
                        backgroundColor: '#FCFCFD',
                    },
                },
                paper: {
                    borderRadius: '2px',
                    padding: '15px 15px',
                    border: '1px solid #DEE0E7',
                    background: '#fff',
                    boxShadow: 'none',
                    '& ul': {
                        padding: 0,
                        margin: 0,
                    },
                    '& li': {
                        padding: '0px 10px',
                        margin: 0,
                        fontWeight: 300,
                        fontSize: '12px',
                        minHeight: '20px!important',
                        color: '#404656',
                    },
                    '& li:not(:last-child)': {
                        marginBottom: '10px',
                    },
                },
                noOptions: {
                    padding: '0px 10px',
                    margin: 0,
                    fontWeight: 300,
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: '#404656',
                },
            },
            MuiPickersBasePicker: {
                pickerView: {
                    maxWidth: '295px',
                    minWidth: '295px',
                    maxHeight: '305px',
                    minHeight: '305px',
                    justifyContent: 'flex-start',
                    overflow: 'hidden',
                },
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    '& p': {
                        fontWeight: 500,
                        color: '#2A2F3A',
                        fontSize: '15px',
                        lineHeight: '22px',
                        textTransform: 'capitalize',
                    },
                    marginTop: '15px',
                    marginBottom: '5px',
                    paddingLeft: '25px',
                    paddingRight: '25px',
                },
                daysHeader: {
                    gap: '10px',
                    maxHeight: '25px',
                },
                dayLabel: {
                    fontFamily: 'Oswald',
                    color: '#555E73',
                    fontWeight: 500,
                    fontSize: 0,
                    lineHeight: '25px',
                    margin: 0,
                    width: '25px',
                    height: '25px',
                    verticalAlign: 'center',
                    textTransform: 'uppercase',
                    '&:first-letter': {
                        fontSize: '12px',
                    },
                },
                iconButton: {
                    width: '30px',
                    height: '30px',
                    padding: 0,
                    '& svg': {
                        width: '12px',
                        height: '12px',
                        color: '#6A7590',
                    },
                },
            },
            MuiPickersCalendar: {
                transitionContainer: {
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingBottom: '15px',
                    marginTop: '15px',
                    '& > *': {
                        position: 'relative',
                        '& > *:not(:last-child)': {
                            marginBottom: '10px',
                        },
                    },
                },
                week: {
                    gap: '0px',
                    justifyContent: 'space-between',
                },
            },
            MuiPickersDay: {
                day: {
                    margin: 0,
                    width: '25px',
                    height: '25px',
                    color: '#555E73',
                    '& p': {
                        fontFamily: 'Oswald',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                    },
                },
                daySelected: {
                    color: '#1B2C23',
                    backgroundColor: '#B4D2C1',
                },
                hidden: {
                    color: '#B4BAC8',
                    opacity: 1,
                },
                current: {
                    color: '#555E73',
                },
            },
        },
    }),
)
