import * as React from 'react'
import { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Button from '_atoms/buttons/Button'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import Text from '_atoms/text/Text'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            position: 'relative',
            textAlign: 'center',
            marginBottom: theme.space(1),
        },
        previousButton: {
            padding: theme.space(2 / 5, 1),
            position: 'absolute',
            top: '-3px',
            left: 0,
        },
        content: { margin: '0 auto' },
    }),
)

export type TableFilterProps = {
    filterTitle: string
    applyFilter: (filter: any) => void // Injected in children component
    closeFilterPopover?: () => void // Injected By TableFilterSystem
    previousStep?: () => void // Injected By TableFilterSystem
    children: JSX.Element
}

const TableFilter: FC<TableFilterProps> = ({
    applyFilter,
    closeFilterPopover,
    previousStep,
    filterTitle,
    children,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    function applyAndReset(filter: any) {
        applyFilter(filter)
        closeFilterPopover!()
    }

    return (
        <>
            <div className={classes.header}>
                <Button
                    variant="ghost"
                    size="small"
                    color="neutral"
                    startIcon={faChevronLeft}
                    onClick={previousStep!}
                    className={classes.previousButton}
                >
                    <Text variant="P6" color="neutral">
                        {t('table.previous')}
                    </Text>
                </Button>
                <Text variant="P2" color="neutral">
                    {filterTitle}
                </Text>
            </div>
            <div className={classes.content}>{React.cloneElement(children, { applyFilter: applyAndReset })}</div>
        </>
    )
}

export default TableFilter as React.FC<TableFilterProps>
