import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, Grid, makeStyles, Tab, Typography } from '@material-ui/core'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { TAKEBACKS_STATS_URL, VOUCHERS_STATS_URL } from 'utils/routes/backend'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import Figure from 'components/statistics/Figure'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import {
    chartColorBlue,
    chartColorGrey,
    chartColorMiddleBlue,
    chartColorMiddleRed,
    chartColorRed,
    getChartsColorsSecondHand,
    getDoughnutOptions,
    getLineChartOptions,
    getStatsStyles,
    getVerticalBarChartOptions,
    sortByNumberDescending,
} from 'components/reusable/utils/StatsUtils'
import LoadingIndicator from 'components/reusable/LoadingIndicator'
import Page from '_organisms/Page'
import DateRangePicker from '_atoms/inputs/DateRangePicker'
import { AxiosResponse } from 'axios'
import { toast } from 'utils/toast'
import Table from '_organisms/tables/Table'
import rmsApi from 'utils/api'
import usePrismicQualitiesStore, { PrismicQualitiesState } from '../../utils/store/usePrismicQualities.store'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import CustomerPicker from '_organisms/header/addons/CustomerPicker'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

ChartJS.register(...registerables)

const statsStyles = getStatsStyles()
const useStyles = makeStyles(() => createStyles(statsStyles))

const SecondHandStatsApp: React.FC = () => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const [selectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])
    const [activeTab, setActiveTab] = useState<string>('takebacks')
    const [stats, setStats] = useState<any>(null)
    const [fullChartOptions, setFullChartOptions] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean | string>(true)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    const [loadPrismicQualities, prismicQualities] = usePrismicQualitiesStore((state: PrismicQualitiesState) => [
        state.loadPrismicQualities,
        state.qualities,
    ])
    const [prismicQualitiesWritten, setPrismicQualitiesWritten] = useState<boolean>(false)

    dayjs.locale(i18n.language)

    useEffect(() => {
        if (selectedCustomer && selectedCustomer?.prismicTemplate)
            loadPrismicQualities(selectedCustomer.prismicTemplate)
        // eslint-disable-next-line
    }, [selectedCustomer])

    useEffect(() => {
        if (selectedCustomer) fetchStats()
        // eslint-disable-next-line
    }, [selectedCustomers, selectedCustomer, selectedWarehouse, startDate, endDate, activeTab])

    // allows to set the prismic qualities once we got the real values
    useEffect(() => {
        if (stats && prismicQualities.length && !prismicQualitiesWritten) {
            setStats((prevState: any) => {
                const newState = { ...prevState }
                newState.productQualityDistribution.labels = newState.productQualityDistribution.labels.map(
                    (quality: string) => {
                        const prismicQuality = prismicQualities?.find(
                            (prismicQuality) => prismicQuality.value === quality,
                        )
                        return prismicQuality?.label || quality
                    },
                )
                return newState
            })
            setPrismicQualitiesWritten(true)
        }
    }, [stats, prismicQualities, prismicQualitiesWritten])

    async function fetchStats() {
        if (!selectedCustomer) return
        try {
            setIsLoading(true)
            let params = `?customerId=${selectedCustomer?.id}`
            if (startDate && endDate) {
                const from = dayjs(startDate).format('YYYY-MM-DD')
                const to = dayjs(endDate).format('YYYY-MM-DD')
                params += `&from=${from}&to=${to}`
            }

            let statsUrl = TAKEBACKS_STATS_URL + params

            switch (activeTab) {
                case 'vouchers':
                    statsUrl = VOUCHERS_STATS_URL + params
                    break
            }
            const statsRequest: AxiosResponse = await rmsApi.get(statsUrl)
            formatStatsData(statsRequest.data)
        } catch (e: any) {
            setIsLoading('error')
            toast.error(e)
        }
    }

    const doughnutOptions: any = getDoughnutOptions()
    const lineOptions: any = getLineChartOptions()
    const barOptions: any = getVerticalBarChartOptions()
    const chartColors: string[] = getChartsColorsSecondHand()
    ChartJS.overrides.doughnut.plugins.tooltip = doughnutOptions.plugins.tooltip

    const formatStatsData = (statsToFormat: any) => {
        const formattedData: any = {}
        const buildingChartOptions: any = {}
        setFullChartOptions({})
        switch (activeTab) {
            case 'takebacks':
                formattedData.totalProductsTakebacks = {
                    figureDescription: 'secondHandStats.figures.totalProductsTakebacks.description',
                    value: Number(statsToFormat.totalProductsTakebacks[0].total_nb_products).toLocaleString(),
                    hint: 'secondHandStats.figures.totalProductsTakebacks.hint',
                }
                formattedData.productStatusDistribution = {
                    labels: statsToFormat.productStatusDistribution.map((item: any) => {
                        return t(`uniqueProductStatus.${item.status}`)
                    }),
                    datasets: [
                        {
                            backgroundColor: [chartColorBlue, chartColorRed],
                            data: statsToFormat.productStatusDistribution.map((item: any) => {
                                return item.nb_products
                            }),
                        },
                    ],
                }
                buildingChartOptions.productStatusDistribution = JSON.parse(JSON.stringify(doughnutOptions))
                buildingChartOptions.productStatusDistribution.plugins.title.text = t(
                    'secondHandStats.figures.productStatusDistribution.title',
                )

                formattedData.productQualityDistribution = {
                    labels: statsToFormat.productQualityDistribution.map((item: any) => {
                        const prismicQuality = prismicQualities?.find(
                            (prismicQuality) => prismicQuality.value === item.quality,
                        )
                        return prismicQuality?.label || item.quality
                    }),
                    datasets: [
                        {
                            backgroundColor: [chartColorBlue, chartColorMiddleBlue, chartColorMiddleRed, chartColorRed],
                            data: statsToFormat.productQualityDistribution.map((item: any) => {
                                return item.nb_products
                            }),
                        },
                    ],
                }
                buildingChartOptions.productQualityDistribution = JSON.parse(JSON.stringify(doughnutOptions))
                buildingChartOptions.productQualityDistribution.plugins.title.text = t(
                    'secondHandStats.figures.productQualityDistribution.title',
                )

                formattedData.totalProductsToShoot = {
                    figureDescription: 'secondHandStats.figures.totalProductsToShoot.description',
                    value: Number(statsToFormat.totalProductsToShoot[0].total_products_to_shoot).toLocaleString(),
                    hint: 'secondHandStats.figures.totalProductsToShoot.hint',
                }

                formattedData.totalNumberOfTakebacksSent = {
                    figureDescription: 'secondHandStats.figures.totalNumberOfTakebacksSent.description',
                    value: Number(statsToFormat.totalNumberOfTakebacksSent[0].nb_takebacks_sent).toLocaleString(),
                    hint: 'secondHandStats.figures.totalNumberOfTakebacksSent.hint',
                }

                formattedData.productPublishedDistribution = {
                    labels: [
                        t('secondHandStats.figures.productPublishedDistribution.labels.notPublished'),
                        t('secondHandStats.figures.productPublishedDistribution.labels.published'),
                    ],
                    datasets: [
                        {
                            backgroundColor: [chartColorRed, chartColorBlue],
                            data: [
                                statsToFormat.takebacksVolumeAcceptedToPublishEvolution.reduce(
                                    (a: any, b: any) =>
                                        Number.parseInt(a) + Number.parseInt(b.total_nb_accepted_to_publish_takebacks),
                                    0,
                                ),
                                statsToFormat.takebacksVolumeEvolution.accepted.reduce(
                                    (a: any, b: any) =>
                                        Number.parseInt(a) + Number.parseInt(b.total_nb_accepted_takebacks),
                                    0,
                                ),
                            ],
                        },
                    ],
                }
                buildingChartOptions.productPublishedDistribution = JSON.parse(JSON.stringify(doughnutOptions))
                buildingChartOptions.productPublishedDistribution.plugins.title.text = t(
                    'secondHandStats.figures.productPublishedDistribution.title',
                )

                formattedData.averageProductsPerTakeback = {
                    figureDescription: 'secondHandStats.figures.averageProductsPerTakeback.description',
                    value: Number(statsToFormat.averageProductsPerTakeback[0].nb_products_per_request).toLocaleString(),
                    hint: 'secondHandStats.figures.averageProductsPerTakeback.hint',
                }
                formattedData.averagePriceOfAcceptedProducts = {
                    figureDescription: 'secondHandStats.figures.averagePriceOfAcceptedProducts.description',
                    value:
                        Number(
                            statsToFormat.averagePriceOfAcceptedProducts[0].avg_price_accepted_products,
                        ).toLocaleString() + ' €',
                    hint: 'secondHandStats.figures.averagePriceOfAcceptedProducts.hint',
                }
                formattedData.totalPriceOfAcceptedProducts = {
                    figureDescription: 'secondHandStats.figures.totalPriceOfAcceptedProducts.description',
                    value:
                        Number(
                            statsToFormat.totalPriceOfAcceptedProducts[0].total_price_accepted_products,
                        ).toLocaleString() + ' €',
                    hint: 'secondHandStats.figures.totalPriceOfAcceptedProducts.hint',
                }
                formattedData.totalUniqueCustomers = {
                    figureDescription: 'secondHandStats.figures.totalUniqueCustomers.description',
                    value: Number(statsToFormat.totalUniqueCustomers[0].nb_unique_customers).toLocaleString(),
                    hint: 'secondHandStats.figures.totalUniqueCustomers.hint',
                }
                formattedData.averageTakebacksPerCustomer = {
                    figureDescription: 'secondHandStats.figures.averageTakebacksPerCustomer.description',
                    value: Number(
                        statsToFormat.averageTakebacksPerCustomer[0].avg_nb_requests_per_customer,
                    ).toLocaleString(),
                    hint: 'secondHandStats.figures.averageTakebacksPerCustomer.hint',
                }

                formattedData.takebacksValueEvolution = {
                    labels: statsToFormat.takebacksValueEvolution.map((item: any) => {
                        return dayjs(item.month).format('MMM YYYY')
                    }),
                    datasets: [
                        {
                            label: t('secondHandStats.figures.takebacksValueEvolution.valueLabel'),
                            borderColor: chartColorBlue,
                            backgroundColor: chartColorBlue,
                            data: statsToFormat.takebacksValueEvolution.map((item: any) => {
                                return item.total_price_accepted_products
                            }),
                        },
                    ],
                }
                buildingChartOptions.takebacksValueEvolution = JSON.parse(JSON.stringify(lineOptions))
                buildingChartOptions.takebacksValueEvolution.plugins.title.text = t(
                    'secondHandStats.figures.takebacksValueEvolution.title',
                )

                formattedData.takebacksVolumeEvolution = {
                    labels: statsToFormat.takebacksVolumeEvolution.accepted.map((item: any) => {
                        return dayjs(item.month).format('MMM YYYY')
                    }),
                    datasets: [
                        {
                            type: 'bar' as const,
                            label: t('secondHandStats.figures.takebacksVolumeEvolution.acceptedLabel'),
                            borderColor: chartColorBlue,
                            backgroundColor: chartColorBlue,
                            data: statsToFormat.takebacksVolumeEvolution.accepted.map((item: any) => {
                                return item.total_nb_accepted_takebacks
                            }),
                        },
                        {
                            type: 'bar' as const,
                            label: t('secondHandStats.figures.takebacksVolumeEvolution.refusedLabel'),
                            borderColor: chartColorRed,
                            backgroundColor: chartColorRed,
                            data: statsToFormat.takebacksVolumeEvolution.refused.map((item: any) => {
                                return item.total_nb_refused_takebacks
                            }),
                        },
                        {
                            type: 'line' as const,
                            label: t('secondHandStats.figures.takebacksVolumeEvolution.totalLabel'),
                            borderColor: chartColorGrey,
                            backgroundColor: chartColorGrey,
                            data: statsToFormat.takebacksVolumeEvolution.accepted.map((item: any, idx: number) => {
                                return (
                                    Number.parseInt(item.total_nb_accepted_takebacks) +
                                    Number.parseInt(
                                        statsToFormat.takebacksVolumeEvolution.refused[idx].total_nb_refused_takebacks,
                                    )
                                )
                            }),
                        },
                    ],
                }
                buildingChartOptions.takebacksVolumeEvolution = JSON.parse(JSON.stringify(barOptions))
                buildingChartOptions.takebacksVolumeEvolution.plugins.title.text = t(
                    'secondHandStats.figures.takebacksVolumeEvolution.title',
                )

                formattedData.takebacksVolumeAcceptedToPublishEvolution = {
                    labels: statsToFormat.takebacksVolumeAcceptedToPublishEvolution.map((item: any) => {
                        return dayjs(item.month).format('MMM YYYY')
                    }),
                    datasets: [
                        {
                            label: t('secondHandStats.figures.takebacksVolumeAcceptedToPublishEvolution.volumeLabel'),
                            borderColor: chartColorBlue,
                            backgroundColor: chartColorBlue,
                            data: statsToFormat.takebacksVolumeAcceptedToPublishEvolution.map((item: any) => {
                                return item.total_nb_accepted_to_publish_takebacks
                            }),
                        },
                    ],
                }
                buildingChartOptions.takebacksVolumeAcceptedToPublishEvolution = JSON.parse(JSON.stringify(barOptions))
                buildingChartOptions.takebacksVolumeAcceptedToPublishEvolution.plugins.title.text = t(
                    'secondHandStats.figures.takebacksVolumeAcceptedToPublishEvolution.title',
                )

                formattedData.productsTopTen = {
                    header: [
                        { label: '#' },
                        { label: t('secondHandStats.figures.productsTopTen.header.name') },
                        { label: t('secondHandStats.figures.productsTopTen.header.code') },
                        { label: t('secondHandStats.figures.productsTopTen.header.nbTakebacks') },
                    ],
                    rows: statsToFormat.productsTopTen.map((item: any) => {
                        return {
                            cells: [
                                { value: item.order_number },
                                { value: item.name },
                                { value: item.code },
                                { value: item.nb_takebacks_per_product },
                            ],
                        }
                    }),
                }
                break

            case 'vouchers':
                formattedData.averagePriceVouchers = {
                    figureDescription: 'secondHandStats.figures.averagePriceVouchers.description',
                    value:
                        Number(statsToFormat.averagePriceVouchers[0].avg_amount_vouchers_accepted).toLocaleString() +
                        ' €',
                    hint: 'secondHandStats.figures.averagePriceVouchers.hint',
                }
                formattedData.vouchersTypeDistribution = {
                    labels: sortByNumberDescending(statsToFormat.vouchersTypeDistribution, 'nb_vouchers').map(
                        (item: any) => {
                            return t(`voucherType.${item.type}`)
                        },
                    ),
                    datasets: [
                        {
                            backgroundColor: chartColors,
                            data: sortByNumberDescending(statsToFormat.vouchersTypeDistribution, 'nb_vouchers').map(
                                (item: any) => {
                                    return item.nb_vouchers
                                },
                            ),
                        },
                    ],
                }
                buildingChartOptions.vouchersTypeDistribution = JSON.parse(JSON.stringify(doughnutOptions))
                buildingChartOptions.vouchersTypeDistribution.plugins.title.text = t(
                    'secondHandStats.figures.vouchersTypeDistribution.title',
                )

                formattedData.totalPriceVouchers = {
                    figureDescription: 'secondHandStats.figures.totalPriceVouchers.description',
                    value:
                        Number(statsToFormat.totalPriceVouchers[0].total_amount_vouchers_accepted).toLocaleString() +
                        ' €',
                    hint: 'secondHandStats.figures.totalPriceVouchers.hint',
                }
                formattedData.totalNbVouchers = {
                    figureDescription: 'secondHandStats.figures.totalNbVouchers.description',
                    value: Number(statsToFormat.totalNbVouchers[0].nb_vouchers_accepted).toLocaleString(),
                    hint: 'secondHandStats.figures.totalNbVouchers.hint',
                }

                formattedData.vouchersEvolution = {
                    labels: statsToFormat.vouchersEvolution.map((item: any) => {
                        return dayjs(item.month).format('MMM YYYY')
                    }),
                    datasets: [
                        {
                            label: t('secondHandStats.figures.vouchersEvolution.valueLabel'),
                            borderColor: chartColorBlue,
                            backgroundColor: chartColorBlue,
                            data: statsToFormat.vouchersEvolution.map((item: any) => {
                                return item.total_amount_vouchers_accepted
                            }),
                        },
                        {
                            label: t('secondHandStats.figures.vouchersEvolution.volumeLabel'),
                            borderColor: chartColorRed,
                            backgroundColor: chartColorRed,
                            data: statsToFormat.vouchersEvolution.map((item: any) => {
                                return item.total_nb_vouchers_accepted
                            }),
                        },
                    ],
                }
                buildingChartOptions.vouchersEvolution = JSON.parse(JSON.stringify(lineOptions))
                buildingChartOptions.vouchersEvolution.plugins.title.text = t(
                    'secondHandStats.figures.vouchersEvolution.title',
                )
                break
        }

        setFullChartOptions(buildingChartOptions)
        setStats(formattedData)
        setIsLoading(false)
    }

    const handleTabChange = (event: any, newValue: any) => {
        setActiveTab(newValue)
    }

    const handleEndDateChange = (endDateToSet: Dayjs) => {
        if (endDateToSet.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDateToSet).subtract(1, 'day'))
            setEndDate(endDateToSet)
        } else {
            setEndDate(endDateToSet)
        }
    }

    const customerPicker = () => <CustomerPicker initWithRandomCustomer truncateLabelAbove={140} />

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.secondHand')}
            tagline={t('secondHandStats.tagline')}
            rightAddon={{ addon: customerPicker(), displayOnMobile: false }}
            withDivider={false}
        >
            <TabContext value={activeTab}>
                <Box className={classes.tabsBox}>
                    <TabList
                        className={classes.tabs}
                        onChange={handleTabChange}
                        aria-label="stat-tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label={t('secondHandStats.tabs.takebacks.menuTitle')} value="takebacks" />
                        <Tab label={t('secondHandStats.tabs.vouchers.menuTitle')} value="vouchers" />
                    </TabList>
                </Box>
                <TabPanel className={classes.tabContent} value="takebacks">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('secondHandStats.tabs.takebacks.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('secondHandStats.tabs.takebacks.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                    />
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.totalProductsTakebacks && (
                                <Figure
                                    testId="total-products-takebacks"
                                    figure={stats.totalProductsTakebacks}
                                    main={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.chartContainer}>
                                {stats?.productStatusDistribution && (
                                    <Chart
                                        type="doughnut"
                                        data={stats?.productStatusDistribution}
                                        options={fullChartOptions?.productStatusDistribution}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.spacer} />
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.chartContainer}>
                                {stats?.productQualityDistribution && (
                                    <Chart
                                        type="doughnut"
                                        data={stats?.productQualityDistribution}
                                        options={fullChartOptions?.productQualityDistribution}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.totalProductsToShoot && (
                                <Figure
                                    testId="total-products-to-shoot"
                                    figure={stats.totalProductsToShoot}
                                    main={true}
                                />
                            )}
                        </Grid>

                        <Grid item md={12} className={classes.spacer} />
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.totalNumberOfTakebacksSent && (
                                <Figure
                                    testId="total-number-of-takebacks-sent"
                                    figure={stats.totalNumberOfTakebacksSent}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.averageProductsPerTakeback && (
                                <Figure
                                    testId="average-products-per-takeback"
                                    figure={stats.averageProductsPerTakeback}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.averagePriceOfAcceptedProducts && (
                                <Figure
                                    testId="average-price-of-accepted-products"
                                    figure={stats.averagePriceOfAcceptedProducts}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.totalPriceOfAcceptedProducts && (
                                <Figure
                                    testId="total-price-of-accepted-products"
                                    figure={stats.totalPriceOfAcceptedProducts}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.totalUniqueCustomers && (
                                <Figure testId="total-unique-customers" figure={stats.totalUniqueCustomers} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.averageTakebacksPerCustomer && (
                                <Figure
                                    testId="average-takebacks-per-customer"
                                    figure={stats.averageTakebacksPerCustomer}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item md={12} className={classes.biggerSpacer} />
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.lineChartContainer}>
                                {stats?.takebacksValueEvolution && (
                                    <Chart
                                        type="line"
                                        options={fullChartOptions?.takebacksValueEvolution}
                                        data={stats?.takebacksValueEvolution}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.biggerSpacer} />
                    </Grid>

                    <Grid container>
                        <Grid item md={12} className={classes.biggerSpacer} />
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.lineChartContainer}>
                                {stats?.takebacksVolumeEvolution && (
                                    <Chart
                                        type="bar"
                                        options={fullChartOptions?.takebacksVolumeEvolution}
                                        data={stats?.takebacksVolumeEvolution}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.biggerSpacer} />
                    </Grid>

                    <Grid container>
                        <Grid item md={12} className={classes.biggerSpacer} />
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.lineChartContainer}>
                                {stats?.takebacksVolumeAcceptedToPublishEvolution && (
                                    <Chart
                                        type="bar"
                                        options={fullChartOptions?.takebacksVolumeAcceptedToPublishEvolution}
                                        data={stats?.takebacksVolumeAcceptedToPublishEvolution}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.biggerSpacer} />
                    </Grid>

                    <Grid container>
                        <Grid item md={12}>
                            <Typography className={classes.tabContentTitle} variant="h3">
                                {t('secondHandStats.figures.productsTopTen.title')}
                            </Typography>
                            {stats?.productsTopTen && (
                                <Table header={stats?.productsTopTen.header} rows={stats?.productsTopTen.rows} />
                            )}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="vouchers">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('secondHandStats.tabs.vouchers.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('secondHandStats.tabs.vouchers.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                    />
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.averagePriceVouchers && (
                                <Figure
                                    testId="average-price-vouchers"
                                    figure={stats.averagePriceVouchers}
                                    main={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.chartContainer}>
                                {stats?.vouchersTypeDistribution && (
                                    <Chart
                                        type="doughnut"
                                        data={stats?.vouchersTypeDistribution}
                                        options={fullChartOptions?.vouchersTypeDistribution}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.spacer} />
                        <Grid item xs={12} sm={6} md={6}>
                            {stats?.totalPriceVouchers && (
                                <Figure
                                    testId="total-price-vouchers"
                                    figure={stats.totalPriceVouchers}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            {stats?.totalNbVouchers && (
                                <Figure testId="total-nb-vouchers" figure={stats.totalNbVouchers} withColor={true} />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.lineChartContainer}>
                                {stats?.vouchersEvolution && (
                                    <Chart
                                        type="line"
                                        options={fullChartOptions?.vouchersEvolution}
                                        data={stats?.vouchersEvolution}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </Page>
    )
}

export default SecondHandStatsApp
