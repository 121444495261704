import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, ListItem, ListItemText, Popover, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { User } from 'utils/store/useConnectedUser.store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildings } from '@fortawesome/pro-regular-svg-icons'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import Text from '_atoms/text/Text'
import { faAnglesDown, faAnglesUp, faMagnifyingGlass, faWarning } from '@fortawesome/pro-light-svg-icons'
import SearchInput from '_molecules/SearchInput'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import Pill from '_atoms/badges/Pill'
import { ICustomer } from 'interfaces/Customer'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import Tips from '_atoms/containers/Tips'

const useStyles = makeStyles<Theme, { open: boolean; isDrawerOpen: boolean }>((theme) => ({
    popover: {
        marginTop: theme.space(1),
        width: theme.space(62),
        padding: theme.space(3),
        maxHeight: theme.space(60),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            border: `1px solid ${theme.palette.neutral[200]}`,
            marginTop: `-${theme.space(2)}px`,
            boxShadow: 'none',
        },
    },
    divider: {
        margin: theme.space(2, 0),
    },
    customerChoices: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.space(1),
    },
    clients: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    customerInfo: {
        height: theme.space(52 / 5),
        paddingLeft: `${theme.space(3)}px!important`,
    },
    customers: {
        margin: 0,
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            color: `${theme.palette.primary[300]}!important`,
        },
        '& span': {
            fontSize: 11,
        },
    },
    customersTitle: {
        display: 'flex',
        color: (props) => (props.open ? theme.palette.neutral[500] : 'rgba(255, 255, 255, 0.64)'),
    },
    warningIcon: {
        marginRight: theme.space(1),
    },
    customersList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: theme.space(3),
        '& .MuiTypography-root': {
            color: (props) => (props.open ? theme.palette.primary[500] : theme.palette.primary.contrastText),
            [theme.breakpoints.down('sm')]: {
                color: `${theme.palette.primary[600]}!important`,
            },
        },
    },
    countCustomers: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        gap: 2,
        padding: theme.space(0, 1),
        fontSize: 9,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '13.34px',
        fontFamily: 'Oswald',
        height: theme.space(18 / 5),
        backgroundColor: (props) => (props.open ? theme.palette.primary[500] : 'rgba(255, 255, 255, 0.16)'),
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.common.white,
            backgroundColor: `${theme.palette.primary[500]}!important`,
        },
        '& svg': {
            color: theme.palette.common.white,
            width: `${theme.space(1.6)}px!important`,
            padding: `${theme.space(0.4)}px!important`,
            backgroundColor: 'unset!important',
            marginRight: `${theme.space(0.2)}px!important`,
        },
    },
    customerLink: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            padding: theme.space(3),
        },
        '& .MuiListItem-root': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: (props) => (props.isDrawerOpen ? 'space-between' : 'center'),
            height: (props) => (props.isDrawerOpen ? theme.space(14) : theme.space(11)),
            margin: (props) => (props.isDrawerOpen ? theme.space(3) : theme.space(3, 0)),
            padding: (props) => (props.isDrawerOpen ? theme.space(2, 3, 2, 2) : 0),
            backgroundColor: (props) => (props.open ? theme.palette.neutral[0] : 'rgba(255, 255, 255, 0.04)'),
            borderRadius: '6px',
            [theme.breakpoints.down('sm')]: {
                margin: '0!important',
                backgroundColor: `${theme.palette.common.white}!important`,
                border: `1px solid ${theme.palette.neutral[200]}`,
            },
        },
        '& .MuiListItem-root:hover': {
            background: 'rgba(255, 255, 255, 0.04)',
        },
    },
    iconCustomerSwitcher: {
        width: theme.space(16 / 5),
        height: 'auto',
        marginRight: theme.space(3),
        backgroundColor: (props) => (props.open ? theme.palette.primary[600] : 'rgba(255, 255, 255, 0.16)'),
        borderRadius: '6px',
        color: (props) => (props.open ? theme.palette.neutral[0] : 'rgba(255, 255, 255, 0.64)'),
        padding: theme.space(13 / 5),
        [theme.breakpoints.down('sm')]: {
            color: `${theme.palette.common.white}!important`,
            backgroundColor: `${theme.palette.primary[600]}!important`,
        },
    },
    anglesIcon: {
        width: theme.space(16 / 5),
        height: 'auto',
        padding: theme.space(4 / 5),
        color: (props) => (props.open ? theme.palette.primary[600] : theme.palette.neutral[0]),
        [theme.breakpoints.down('sm')]: {
            color: `${theme.palette.primary[600]}!important`,
        },
    },
}))

type CustomerSwitcherProps = {
    user: User
    customers: ICustomer[]
    setCustomers: (b: any) => void
    isDrawerOpen: boolean
}

const CustomerSwitcher: React.FC<CustomerSwitcherProps> = ({ user, isDrawerOpen, customers, setCustomers }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const [
        selectedCustomers,
        disabledSelectedCustomers,
        setSelectedCustomers,
    ] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.selectedCustomers,
        state.disabledSelectedCustomers,
        state.setSelectedCustomers,
    ])
    const options = user.customers.map((customer: ICustomer) => customer!.name!)
    const [filter, setFilter] = useState<string>('')

    const classes = useStyles({ open, isDrawerOpen })
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))
    const { t } = useTranslation()
    let unshownCustomerCount = 0

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    function formatCustomersName(): string {
        let totalLength = 0
        let customersName = ''
        let firstTimeExceeded = true

        for (const customer of selectedCustomers) {
            const nameToAdd = customer.name

            if (totalLength + nameToAdd!.length <= 20) {
                if (customersName.length > 0) {
                    customersName += ', '
                    totalLength += 2 // Pour la virgule et l'espace ajoutés
                }
                customersName += nameToAdd
                totalLength += nameToAdd!.length
            } else {
                if (firstTimeExceeded) {
                    customersName += '...'
                    firstTimeExceeded = false
                }
                unshownCustomerCount++
            }
        }

        return customersName
    }

    function handleCheck(value: boolean, customerName: string) {
        if (value) {
            const customerToAdd = user.customers.find((customer: ICustomer) =>
                customer.name === customerName ? customer : null,
            )
            if (customerToAdd) {
                setCustomers([...customers, customerToAdd])
                setSelectedCustomers([...selectedCustomers, customerToAdd])
            }
        } else {
            if (selectedCustomers.length !== 1) {
                setCustomers(customers.filter((customer) => customer.name !== customerName))
                const customerToRemove = selectedCustomers.find((customer: ICustomer) =>
                    customer.name === customerName ? customer : null,
                )
                if (customerToRemove) {
                    const newSelected = selectedCustomers.filter((customer) => customer !== customerToRemove)
                    setSelectedCustomers(newSelected)
                }
            }
        }
    }

    function normalizeString(str: string) {
        return (str || '').replace(/ /g, '\u00A0').toLowerCase()
    }

    const filteredOptions = options?.reduce(function (filtered: any[], option: string) {
        if (!filter) {
            filtered.push(
                <BasicCheckbox
                    className={classes.option}
                    checked={selectedCustomers.some((customer) => customer.name === option)}
                    setValue={(value) => handleCheck(value, option)}
                    label={option}
                    data-testid={option}
                    key={option}
                    disabled={disabledSelectedCustomers}
                />,
            )
        } else {
            const normalizedOption = normalizeString(option)
            const normalizedFilter = normalizeString(filter)
            const matchIndex = normalizedOption.indexOf(normalizedFilter)

            if (matchIndex !== -1) {
                filtered.push(
                    <div key={option}>
                        <BasicCheckbox
                            className={classes.option}
                            checked={selectedCustomers.some((customer) => customer.name === option)}
                            setValue={(value) => handleCheck(value, option)}
                            label={option}
                            key={option}
                            disabled={disabledSelectedCustomers}
                        />
                    </div>,
                )
            }
        }
        return filtered
    }, [])

    return (
        <>
            <Box className={`${classes.customerLink}`} data-testid="customer-switcher">
                <Divider orientation="vertical" />
                {!isDrawerOpen ? (
                    <ListItem button onClick={handleClick} disableGutters>
                        <FontAwesomeIcon icon={faBuildings} />
                    </ListItem>
                ) : (
                    <ListItem button onClick={handleClick} disableGutters>
                        <FontAwesomeIcon icon={faBuildings} className={classes.iconCustomerSwitcher} />
                        <div className={classes.clients}>
                            <div className={classes.customersTitle}>
                                {disabledSelectedCustomers && (
                                    <FontAwesomeIcon icon={faWarning} className={classes.warningIcon} />
                                )}
                                <ListItemText
                                    primary={t('menu.customerSwitcher.customers')}
                                    className={classes.customers}
                                />
                            </div>
                            <div className={classes.customersList}>
                                <Text variant={'S2'}>{formatCustomersName()}</Text>
                                {unshownCustomerCount !== 0 && (
                                    <Pill
                                        startIcon={faPlus}
                                        label={unshownCustomerCount}
                                        className={classes.countCustomers}
                                        isSmall
                                    />
                                )}
                            </div>
                        </div>
                        <FontAwesomeIcon
                            icon={isMobile ? (open ? faAnglesDown : faAnglesUp) : open ? faAnglesUp : faAnglesDown}
                            className={classes.anglesIcon}
                        />
                    </ListItem>
                )}
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{ paper: classes.popover }}
                anchorOrigin={{
                    vertical: isMobile ? 'top' : 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: isMobile ? 'bottom' : 'top',
                    horizontal: 'left',
                }}
                data-testid="customer-switcher-popover"
            >
                {!disabledSelectedCustomers ? (
                    <div>
                        <SearchInput
                            value={filter}
                            autoFocus={true}
                            placeholder={t('menu.customerSwitcher.searchPlaceholder')}
                            iconStart={faMagnifyingGlass}
                            onChange={(e: any) => setFilter(e.target.value)}
                            fullWidth
                            data-testid="search-customer-switcher"
                        />
                        <Divider className={classes.divider} />
                    </div>
                ) : (
                    <Tips variant="info" className={classes.customerInfo}>
                        {t('menu.customerSwitcher.info')}
                    </Tips>
                )}
                <div className={classes.customerChoices} data-testid="customer-choices">
                    {filteredOptions && filteredOptions?.length > 0 ? filteredOptions : t('global.noMatch')}
                </div>
            </Popover>
        </>
    )
}

export default CustomerSwitcher
