import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BlueBackground from '_molecules/backgrounds/BlueBackground'
import EnrichedIcon from '_molecules/EnrichedIcon'
import Button from '_atoms/buttons/Button'
import { faPersonDollyEmpty } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        background: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: `${theme.space(5)}px`,
            marginTop: theme.space(6),
        },
    }),
)

type NoBatchPanelProps = {
    openCreateBatchModal: () => void
}

const NoBatchPanel: React.FC<NoBatchPanelProps> = ({ openCreateBatchModal }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <BlueBackground className={classes.background}>
            <EnrichedIcon
                icon={faPersonDollyEmpty}
                title={t('new_orders.pickPack.noBatchPanelTitle')}
                tagline={t('new_orders.pickPack.noBatchPanelTagline')}
            />
            <Button label={t('new_orders.pickPack.generateBatch')} onClick={openCreateBatchModal} />
        </BlueBackground>
    )
}

export default NoBatchPanel
