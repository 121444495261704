import * as React from 'react'
import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'

import Text, { VariantType } from '_atoms/text/Text'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelContainer: {
            marginBottom: theme.space(1),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        tooltip: {
            padding: theme.space(3, 4),
            fontWeight: 300,
            lineHeight: theme.typography.pxToRem(19),
            fontSize: theme.typography.pxToRem(11),
            whiteSpace: 'pre-wrap',
        },
        tooltipIcon: {
            color: theme.palette.neutral[400],
            fontSize: theme.typography.pxToRem(12),
        },
        label: {
            fontWeight: 400,
            width: 'fit-content',
            marginRight: theme.space(4),
        },
    }),
)

interface Props {
    label: string
    children: React.ReactNode
    tooltip?: string
    variant?: VariantType
    className?: string
}

const InputWithLabel: React.FC<Props> = ({ label, children, tooltip, variant = 'S2', className = '' }) => {
    const classes = useStyles()

    return (
        <div className={`${className}`}>
            <div className={classes.labelContainer}>
                <Text className={classes.label} variant={variant}>
                    {label}
                </Text>
                {tooltip && (
                    <Tooltip
                        placement="top-end"
                        title={<div className={classes.tooltip}>{tooltip}</div>}
                        enterTouchDelay={100}
                    >
                        <div>
                            <FontAwesomeIcon icon={faCircleInfo} className={classes.tooltipIcon} />
                        </div>
                    </Tooltip>
                )}
            </div>
            {children}
        </div>
    )
}

export default InputWithLabel
