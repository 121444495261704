import * as React from 'react'

import { MenuItem, makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        menuItem: {
            padding: 0,
        },
        selected: {
            backgroundColor: theme.palette.grey[300],
        },
        button: {
            padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
            width: '100%',
        },
    }),
)

type PropsType = {
    name: string | null
    label: string
    handleUpdate: (name: any) => void
    handleMenuClose?: () => void
    selected?: boolean
}

const TableHeaderMenuItem: React.FC<PropsType> = ({ selected = false, label, name, handleUpdate, handleMenuClose }) => {
    const classes = useStyles()

    const handleMenuClick = () => {
        handleUpdate(name)
        if (handleMenuClose) handleMenuClose()
    }

    return (
        <MenuItem className={`${selected ? classes.selected : ''} ${classes.menuItem}`}>
            <div className={classes.button} onClick={handleMenuClick}>
                {label}
            </div>
        </MenuItem>
    )
}

export default TableHeaderMenuItem
