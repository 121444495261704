import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { IParcel, ParcelStatus } from 'interfaces'
import { SectionTitle } from '_atoms/text/SectionTitle'

import { H1Title } from '_atoms/text/Titles'
import RentalDateOverview from '_pages/returnedParcels/rentalDateOverview'
import ParcelInfosOverview from '_pages/returnedParcels/ParcelInfosOverview'
import PrintableUniqueProductOverview from '_pages/returnedParcels/printable/PrintableUniqueProductOverview'
import { toast } from 'utils/toast'
import { downloadFiles } from 'utils/files'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hidden: {
            position: 'absolute',
            zIndex: -1,
        },
        sectionContainer: {
            width: '90%',
            margin: `${theme.spacing(4)}px auto 0px`,
            justifyContent: 'center',
        },
        packagingSectionContainer: {
            width: '90%',
            margin: `${theme.spacing(4)}px auto 0px`,
            justifyContent: 'left',
        },
        title: {
            marginTop: '50px',
            textAlign: 'center',
        },
        sectionTitle: {
            marginBottom: theme.spacing(1),
        },
        summarySection: {
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '80%',
            margin: '0 auto',
        },
        square: {
            border: '1px solid black',
            padding: '10px',
        },
        uniqueProductName: {
            color: '#14223D',
            opacity: '70%',
            margin: '0',
        },
        uniqueProductsOverview: {
            display: 'flex',
        },
        picture: {
            '& img': {
                display: 'block',
                height: '200px',
                width: '200px',
                objectFit: 'contain',
            },
        },
        packagingDescriptionContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        roundedBox: {
            borderRadius: '4px',
            marginLeft: '3px',
            marginBottom: '5px',
            padding: theme.spacing(1),
            margin: '0 auto',
            border: '1px solid black',
        },
    }),
)

type ReturnedParcelProps = {
    parcel: IParcel
    togglePrintWindow?: () => void
}

const PrintableReturnedParcelPage: React.FC<ReturnedParcelProps> = ({ parcel, togglePrintWindow }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [packagingImages, setPackagingImages] = useState<string[]>([])

    const fetchImages = async () => {
        try {
            const imagesData = await downloadFiles(parcel?.returnPackaging?.files)
            if (imagesData?.length) setPackagingImages(imagesData || [])
            // Trig print action with small wait to avoid undisplayed images
            setTimeout(() => {
                if (togglePrintWindow) togglePrintWindow()
            }, 1500)
        } catch (e: any) {
            toast.error(t('files.fetchError', { message: e.message }))
        }
    }

    useEffect(() => {
        fetchImages()
        // eslint-disable-next-line
    }, [])

    const customerName = parcel?.shipment?.renter?.customer?.name || ''
    const { renter } = parcel?.shipment || {}
    const relatedOrder = parcel?.shipment?.order?.omsOrderId || 'N/A'
    const arrivedAt = parcel?.statusHistory?.find((history: any) => history.status === ParcelStatus.ARRIVED)?.createdAt
    const arriveProcessedStatusHistory = parcel?.statusHistory?.find(
        (satusHistory: any) => satusHistory.status === ParcelStatus.PROCESSED,
    )
    const processingDate = arriveProcessedStatusHistory?.createdAt
    const rightOfWithdrawal = parcel?.shipment?.rightOfWithdrawal

    const parcelInfos = {
        customerName,
        relatedOrder,
        carrier: parcel?.shipment?.carrier?.name,
        barcode: parcel?.barcode,
        trackingCode: parcel?.trackingCode,
        trackingLink: parcel?.trackingLink,
        pdfUrl: parcel?.pdfUrl,
        relatedParcels:
            parcel?.shipment?.parcels?.filter((relatedParcel) => relatedParcel.barcode !== parcel?.barcode) || [],
    }

    const setPageMargins = () => {
        return `@media print {
                    @page { margin: 0.4cm}
                }`
    }

    return (
        <div className={classes.hidden}>
            <style>{setPageMargins()} </style>
            <H1Title className={classes.title}>
                {t('returns.printable.report', { trackingCode: parcel.trackingCode })}
            </H1Title>
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: '20px' }}>
                <div>
                    <SectionTitle className={classes.sectionTitle} size="large">
                        {t('returns.parcelInfos.customer')}
                    </SectionTitle>
                </div>
                <div>
                    <span>
                        <strong>{t('global.lastname')} : </strong>
                    </span>
                    <span>{renter?.lastName}</span>
                </div>
                <div>
                    <span>
                        <strong>{t('global.firstname')} : </strong>
                    </span>
                    <span>{renter?.firstName}</span>
                </div>
                <div>
                    <span>
                        <strong>{t('global.email')} : </strong>
                    </span>
                    <span>{renter?.email}</span>
                </div>
            </div>
            <Grid container className={classes.summarySection}>
                <Grid className={classes.square} item sm={6}>
                    <ParcelInfosOverview parcelInfos={parcelInfos} hideBarcode={true} />
                </Grid>
                <Grid className={classes.square} item sm={6}>
                    <RentalDateOverview
                        expectedShippingDate={parcel?.shipment?.shippingDate}
                        shippedAt={parcel?.shippedAt}
                        arrivedAt={arrivedAt}
                        processedAt={processingDate}
                        rightOfWithdrawal={rightOfWithdrawal}
                    />
                </Grid>
                <Grid className={classes.square} item sm={12}>
                    <div>
                        {t('returns.printable.brokenItems')} :{' '}
                        <span>{parcel?.returnItems?.length ? t('global.yes') : t('global.no')}</span>
                    </div>
                    <div>
                        {t('returns.printable.brokenPackaging')} :{' '}
                        <span>{parcel?.returnPackaging ? t('global.yes') : t('global.no')}</span>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.sectionContainer}>
                <SectionTitle className={classes.sectionTitle} size="large">
                    {t('returns.products')}
                </SectionTitle>
                {parcel?.returnItems?.map((returnItem, index) => (
                    <PrintableUniqueProductOverview key={index} returnItem={returnItem} />
                ))}
            </div>
            {parcel?.returnPackaging && (
                <div className={classes.packagingSectionContainer}>
                    <SectionTitle className={classes.sectionTitle} size="large">
                        {t('returns.packaging.packaging')}
                    </SectionTitle>

                    <Box className={classes.roundedBox}>
                        <Grid container>
                            <Grid sm={6} item>
                                <strong>{t('returns.packaging.state')} : </strong>
                            </Grid>
                            <Grid sm={6} item>
                                {t('returns.packaging.issueReported')}
                            </Grid>
                            <Grid sm={6} item>
                                <strong>{t('returns.packaging.comment')}</strong>
                            </Grid>
                            <Grid sm={6} item>
                                {parcel?.returnPackaging?.comment || 'N/A'}
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid className={classes.packagingDescriptionContainer}>
                        {packagingImages?.map((imageUrl, index) => (
                            <Grid className={classes.picture} key={index} item>
                                <img key={index} src={imageUrl} alt="packaging" />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )}
        </div>
    )
}

export default PrintableReturnedParcelPage
