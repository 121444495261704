import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { UniqueProductStatus, UniqueProductStatusFront } from 'interfaces'
import { useTranslation } from 'react-i18next'
import ReturnAddon from './ReturnAddon'
import ReturnProductCard from '../ReturnProductCard'
import Text from '_atoms/text/Text'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelValue: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            color: theme.palette.neutral[600],
            textTransform: 'uppercase',
            fontWeight: 300,
            width: theme.space(7),
            marginRight: theme.space(1),
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                fontWeight: 400,
            },
        },
        puidLabel: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(24),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(10),
                lineHeight: theme.typography.pxToRem(18),
            },
        },
        eanLabel: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(21),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(10),
                lineHeight: theme.typography.pxToRem(15),
            },
        },
        value: {
            color: theme.palette.neutral[900],
            textTransform: 'uppercase',
            fontFamily: 'Oswald',
            fontWeight: 500,
            letterSpacing: '0.05em',
            [theme.breakpoints.down('sm')]: {
                fontWeight: 300,
            },
        },
        puidValue: {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(12),
                lineHeight: theme.typography.pxToRem(18),
            },
        },
        eanValue: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(21),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(10),
                lineHeight: theme.typography.pxToRem(15),
            },
        },
    }),
)

type RentalProductCardProps = {
    name: string
    image: string | null
    barcodeUid: string
    eans?: { ean: string }[]
    status?: UniqueProductStatus | UniqueProductStatusFront
    onClick?: () => void
    handleCommentClick?: () => void
    handleEditClick?: () => void
}

const RentalProductCard: React.FC<RentalProductCardProps> = ({
    name,
    barcodeUid,
    eans = [],
    status,
    image,
    onClick,
    handleCommentClick,
    handleEditClick,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const returnAddon = Boolean(handleEditClick && handleCommentClick)

    return (
        <ReturnProductCard name={name} image={image} onClick={onClick} status={status}>
            <>
                <div className={classes.labelValue}>
                    <Text variant="S2" className={`${classes.label} ${classes.puidLabel}`}>
                        {t('wms.uniqueProducts.columnID')}
                    </Text>
                    <Text variant="S2" className={`${classes.value} ${classes.puidValue}`}>
                        {barcodeUid}
                    </Text>
                </div>
                {returnAddon ? (
                    <ReturnAddon
                        handleEdit={handleEditClick!}
                        handleComment={handleCommentClick!}
                        status={status as UniqueProductStatus}
                        barcodeUid={barcodeUid}
                    />
                ) : (
                    eans.length > 0 && (
                        <div className={classes.labelValue}>
                            <Text variant="S2" className={`${classes.label} ${classes.eanLabel}`}>
                                {t('wms.uniqueProducts.columnEans')}
                            </Text>
                            <Text variant="S2" className={`${classes.value} ${classes.eanValue}`}>
                                {eans.map((ean, i) => (
                                    <span key={i}>{ean.ean}</span>
                                ))}
                            </Text>
                        </div>
                    )
                )}
            </>
        </ReturnProductCard>
    )
}

export default RentalProductCard
