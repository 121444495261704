import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { Chip } from '@material-ui/core'

// Style of paper and list is in theme.js

type MultiselectStyleProps = {
    empty: boolean
}

const useStyles = makeStyles<Theme, MultiselectStyleProps>((theme) => ({
    input: ({ empty }) => ({
        backgroundColor: theme.palette.neutral[50],
        border: `1px solid ${theme.palette.neutral[200]}`,
        borderRadius: 2,
        padding: theme.space(9 / 5, 3),
        transition: 'all 0.2s ease-out',
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${theme.space(1)}px`,
        '&:hover': {
            borderColor: theme.palette.neutral[400],
            borderWidth: '1px',
        },
        '&:focus-within': {
            borderColor: theme.palette.primary[500],
        },
        '& .MuiAutocomplete-input': {
            padding: '0!important',
            maxWidth: theme.space(empty ? 64 : 32),
            height: theme.space(4),
            fontSize: theme.typography.pxToRem(12),
        },
    }),
    multiselect: {
        '& .MuiAutocomplete-endAdornment': {
            right: theme.space(3),
            top: theme.space(2),
            '& svg': {
                color: theme.palette.neutral[600],
                fontSize: theme.typography.pxToRem(12),
            },
        },
        '& .MuiAutocomplete-tag': {
            borderRadius: '2px',
            margin: 0,
            background: theme.palette.neutral[600],
            height: theme.space(4),
            padding: theme.space(0, 2),
            fontWeight: '300',
            color: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
        },
        '& .MuiAutocomplete-tag:not(.MuiChip-deletable)': {
            background: theme.palette.primary[400],
            padding: theme.space(0, 2),
        },
        '& .MuiChip-label': {
            padding: 0,
            fontWeight: '500',
            color: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(12),
        },
        '& .MuiChip-deleteIcon': {
            margin: theme.space(0, 0, 0, 1),
            height: theme.space(12 / 5),
            width: theme.space(12 / 5),
            color: theme.palette.neutral[200],
        },
    },
}))

// AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>
interface MultiselectProps
    extends Pick<
        AutocompleteProps<any, undefined, undefined, undefined>,
        'options' | 'getOptionLabel' | 'limitTags' | 'noOptionsText' | 'value' | 'onChange'
    > {
    placeholder: string
    dataTestId?: string
    className?: string
}

const Multiselect: React.FC<MultiselectProps> = ({
    placeholder,
    dataTestId,
    getOptionLabel,
    className,
    ...multiselectProps
}) => {
    const empty = multiselectProps?.value?.length === 0
    const classes = useStyles({ empty })

    return (
        <Autocomplete
            multiple
            disableClearable
            filterSelectedOptions
            freeSolo={false}
            className={`${classes.multiselect} ${className}`}
            getOptionLabel={getOptionLabel}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <Chip key={index} {...getTagProps({ index })} label={getOptionLabel!(option)} />
                ))
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    InputProps={{
                        ...params.InputProps,
                        className: `${classes.input} noKeyboardListener`,
                        disableUnderline: true,
                        fullWidth: false,
                        placeholder: empty ? placeholder : '',
                    }}
                />
            )}
            popupIcon={<FontAwesomeIcon icon={faAngleDown} />}
            data-testid={dataTestId}
            {...multiselectProps}
        />
    )
}

export default Multiselect
