import * as React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import ScanInput, { ScanInputProps } from './ScanInput'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons'
import BlueBackground from './backgrounds/BlueBackground'

type StyleProps = {
    footerInBoxMobile: boolean
    footerInBoxDesktop: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        scan: {
            zIndex: 1001,
            transform: `translate(0, ${theme.space(-18)}px)`,
            [theme.breakpoints.up('sm')]: {
                width: theme.space(100),
                position: 'absolute',
            },
            [theme.breakpoints.down('sm')]: {
                width: `calc(100vw - ${theme.space(6)}px)`,
                position: 'fixed',
            },
        },
        footerBox: {
            zIndex: 1,
            [theme.breakpoints.up('sm')]: {
                position: 'absolute',
                left: '50%',
                bottom: ({ footerInBoxDesktop }) => theme.space(footerInBoxDesktop ? -8 : -6),
                transform: 'translate(-50%)',
                padding: ({ footerInBoxDesktop }) => (footerInBoxDesktop ? theme.space(2, 6) : 0),
                background: ({ footerInBoxDesktop }) => (footerInBoxDesktop ? theme.palette.common.white : 'none'),
                borderRadius: theme.space(4 / 5),
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                position: 'fixed',
                bottom: 0,
                left: 0,
                padding: theme.space(3, 4),
                backgroundColor: ({ footerInBoxMobile }) =>
                    footerInBoxMobile ? theme.palette.common.white : 'transparent',
                boxShadow: ({ footerInBoxMobile }) =>
                    footerInBoxMobile ? '0px 0px 15px rgba(46, 46, 46, 0.1)' : 'none',
            },
        },
        footer: {
            [theme.breakpoints.up('sm')]: {
                minWidth: ({ footerInBoxDesktop }) => (footerInBoxDesktop ? 'fit-content' : theme.space(66)),
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    }),
)

type BackgroundWithScanAndFooterProps = {
    children: React.ReactNode
    footer?: React.ReactElement | null
    scanProps?: ScanInputProps
    className?: string
    footerInBoxMobile?: boolean
    footerInBoxDesktop?: boolean
}

const BackgroundWithScanAndFooter: React.FC<BackgroundWithScanAndFooterProps> = ({
    children,
    footer,
    scanProps,
    className,
    footerInBoxMobile = true,
    footerInBoxDesktop = false,
}) => {
    const classes = useStyles({ footerInBoxMobile, footerInBoxDesktop })

    return (
        <BlueBackground className={`${className} ${classes.root}`}>
            {scanProps && (
                <ScanInput
                    {...scanProps}
                    iconStart={faScannerGun}
                    className={`${scanProps.className} ${classes.scan}`}
                />
            )}
            {footer && (
                <Box textAlign="center" className={classes.footerBox}>
                    {React.cloneElement(footer!, { className: `${classes.footer} ${footer.props.className}` })}
                </Box>
            )}
            {children}
        </BlueBackground>
    )
}

export default BackgroundWithScanAndFooter
