import * as React from 'react'
import { ReactNode } from 'react'
import { Checkbox, createStyles, FormControlLabel, makeStyles, Theme, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/pro-light-svg-icons'
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons'
import { faSquareCheck as faSquareCheckLight } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        checkboxLabel: {
            '&.Mui-disabled': {
                opacity: 0.32,
            },
            '& > .MuiFormControlLabel-label': {
                color: theme.palette.neutral[800],
                fontSize: theme.typography.pxToRem(12),
            },
        },
        checkbox: {
            '& svg': {
                width: '16px',
                height: '16px',
                display: 'inline-block',
            },
            '&.MuiCheckbox-indeterminate': {
                color: 'red!important',
            },
        },
    }),
)

export type BasicCheckboxProps = {
    className?: string
    label?: string | ReactNode
    checked?: boolean
    setValue?: (value: boolean) => void
    preventFormEvent?: boolean
    indeterminate?: boolean
    disabled?: boolean
    [checkboxProps: string]: any
    dataTestId?: string
}

const BasicCheckbox: React.FC<BasicCheckboxProps> = ({
    label,
    checked = false,
    setValue,
    className = '',
    preventFormEvent = false,
    stopPropagation = false,
    indeterminate = false,
    dataTestId,
    checkboxProps,
    disabled,
}) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <FormControlLabel
            onClick={(e: any) => {
                // can prevent some bugs relative to events.
                if (preventFormEvent) e.preventDefault()
                if (stopPropagation) e.stopPropagation()
            }}
            className={`${classes.checkboxLabel} ${className}`}
            control={
                <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    onChange={setValue ? () => setValue(!checked) : undefined}
                    checked={checked}
                    icon={<FontAwesomeIcon icon={faSquare} color={theme.palette.neutral[400]} />}
                    checkedIcon={<FontAwesomeIcon icon={faSquareCheck} />}
                    indeterminateIcon={<FontAwesomeIcon icon={faSquareCheckLight} color={theme.palette.neutral[400]} />}
                    indeterminate={indeterminate}
                />
            }
            label={label ?? ''}
            data-testid={dataTestId}
            disabled={disabled}
            {...checkboxProps}
        />
    )
}

export default BasicCheckbox
