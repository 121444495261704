import * as React from 'react'
import CommentBubble from '_atoms/text/CommentBubble'
import { Grid } from '@material-ui/core'

type GroupedCommentsProps = { comments: { comment: string; detail?: string }[] }

const GroupedComments: React.FC<GroupedCommentsProps> = ({ comments }) => {
    return (
        <Grid>
            {comments.map((commentInfo, index) => (
                <CommentBubble key={index} comment={commentInfo.comment} detail={commentInfo.detail} />
            ))}
        </Grid>
    )
}

export default GroupedComments
