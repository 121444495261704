import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { PaletteType } from '_atoms/buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type StyleProps = {
    color: PaletteType
    isNumber: boolean
    isSmall: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        pill: ({ isNumber, isSmall }) => ({
            borderRadius: isSmall ? 4 : 6,
            fontWeight: 500,
            width: 'fit-content',
            fontFamily: isNumber ? 'Oswald' : 'Hanken Grotesk',
            textAlign: 'center',
            fontSize: theme.typography.pxToRem(isSmall ? 9 : 14),
            lineHeight: theme.typography.pxToRem(isSmall ? 14 : 18),
            padding: isSmall ? theme.space(2 / 5, 1) : theme.space(1, 2),
        }),
        fill: ({ color }) => ({
            color: color === 'secondary' ? theme.palette.secondary.contrastText : theme.palette.common.white,
            backgroundColor: `${theme.palette[color][color === 'success' ? 700 : 600]} !important`,
        }),
        stroke: ({ color }) => ({
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette[color][['secondary', 'success'].includes(color) ? 800 : 600],
            color: theme.palette[color][['secondary', 'success'].includes(color) ? 800 : 600],
        }),
        basic: ({ color }) => ({
            backgroundColor: theme.palette[color][['secondary', 'success'].includes(color) ? 50 : 100],
            color: theme.palette[color][['secondary', 'success'].includes(color) ? 800 : 700],
        }),
        ghost: ({ color }) => ({
            color: theme.palette[color][['secondary', 'success'].includes(color) ? 800 : 600],
        }),
        content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        startIcon: {
            marginRight: ({ isSmall }) => (isSmall ? 'none' : theme.space(1)),
        },
        endIcon: {
            marginLeft: ({ isSmall }) => (isSmall ? 'none' : theme.space(1)),
        },
    }),
)

export type PillVariant = 'fill' | 'stroke' | 'basic' | 'ghost'

export type PillProps = {
    label: string | number
    variant?: PillVariant
    color?: PaletteType
    isNumber?: boolean
    isSmall?: boolean
    className?: string
    id?: string
    startIcon?: IconProp
    endIcon?: IconProp
    dataTestId?: string
}

const Pill: React.FC<PillProps> = ({
    label,
    variant = 'fill',
    color = 'primary',
    className = '',
    isSmall = false,
    isNumber = false,
    id = '',
    startIcon,
    endIcon,
    dataTestId = '',
}) => {
    const classes = useStyles({ color, isNumber, isSmall })
    return (
        <div
            id={id}
            className={`${classes.pill} ${classes[variant]} ${className}`}
            data-testid={dataTestId || `pill_${label}`}
        >
            <div className={classes.content}>
                {startIcon && <FontAwesomeIcon icon={startIcon} className={classes.startIcon} />}
                {label}
                {endIcon && <FontAwesomeIcon icon={endIcon} className={classes.endIcon} />}
            </div>
        </div>
    )
}

export default Pill
