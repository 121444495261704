import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import InputField from '_atoms/inputs/InputField'
import { faClose, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import Dash from '_atoms/Dash'
import Button from '_atoms/buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'end',
        },
        hr: {
            width: '100%',
            margin: theme.space(2, 0),
        },
        clear: {
            cursor: 'pointer',
        },
    }),
)

type MobileSearchFilterProps = {
    placeholder?: string
    applyFilter?: (search: string) => void // Injected by TableFilter component
}

const MobileSearchFilter: React.FC<MobileSearchFilterProps> = ({ placeholder, applyFilter }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [displayedValue, setDisplayedValue] = useState<string>('')

    function handleUpdateSearch(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null) {
        const newValue = event?.target?.value || ''
        setDisplayedValue(newValue)
    }

    return (
        <div>
            <InputField
                data-testid="searchField"
                value={displayedValue}
                placeholder={placeholder}
                fullWidth
                onChange={handleUpdateSearch}
                iconStart={faMagnifyingGlass}
                endAdornment={
                    displayedValue && (
                        <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => handleUpdateSearch(null)}
                            className={classes.clear}
                        />
                    )
                }
            />

            <Dash className={classes.hr} />
            <div className={classes.footer}>
                <Button size="small" onClick={() => applyFilter!(displayedValue)}>
                    {t('global.apply')}
                </Button>
            </div>
        </div>
    )
}

export default MobileSearchFilter
