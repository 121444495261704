import * as React from 'react'
import { useEffect, useState } from 'react'
import { faHourglassClock, faUserClock } from '@fortawesome/pro-light-svg-icons'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import { EntityType, getSignedUrl, RMSFile, uploadFiles } from 'utils/files'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import { IRefitInstruction } from 'interfaces'
import ModalFooter from '_molecules/ModalFooter'
import InputWithLabel from '_molecules/InputWithLabel'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import InputField from '_atoms/inputs/InputField'
import RadioButtons from '_atoms/inputs/RadioButtons'
import Tips from '_atoms/containers/Tips'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import ManageFiles from './ManageFiles'
import { JuneEvent, trackJuneEvent } from 'utils/june'

dayjs.extend(duration)

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalBody: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.space(6),
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                flexWrap: 'nowrap',
            },
        },
        columnBox: {
            [theme.breakpoints.up('md')]: {
                width: '50%',
            },
            '& > *:not(:last-child)': {
                marginBottom: theme.space(4),
            },
        },
        durationField: {
            '& > .MuiInputBase-root': {
                '& > input': {
                    fontFamily: "'Oswald'",
                },
            },
        },
        filesContainer: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                overflow: 'auto',
            },
        },
        fileButton: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(2),
            },
        },
    }),
)

export enum InstructionModalType {
    VIEW = 'VIEW',
    CLONE = 'CLONE',
    EDIT = 'EDIT',
    CREATE = 'CREATE',
    ADD_TO_GROUP = 'ADD_TO_GROUP',
}

function getParametersByInstructionType(t: any) {
    return {
        [InstructionModalType.CREATE]: {
            inputDisabled: false,
            modalTitle: t(`refit.instructionCategories.modal.INSTRUCTIONS.addTitle`),
            hasFooter: true,
            submitLabel: t('refit.instructionCategories.modal.createButton'),
            hasCheckbox: true,
            successMessage: t('refit.instructionCategories.callbackMessages.instructionSuccessfullyCreated'),
        },
        [InstructionModalType.EDIT]: {
            inputDisabled: false,
            modalTitle: t(`refit.instructionCategories.modal.INSTRUCTIONS.editTitle`),
            hasFooter: true,
            submitLabel: t('refit.instructionCategories.modal.editButton'),
            hasCheckbox: false,
            successMessage: t('refit.instructionCategories.callbackMessages.instructionSuccessfullyModified'),
        },
        [InstructionModalType.CLONE]: {
            inputDisabled: false,
            modalTitle: t(`refit.instructionCategories.modal.INSTRUCTIONS.cloneTitle`),
            hasFooter: true,
            submitLabel: t('refit.instructionCategories.modal.createButton'),
            hasCheckbox: true,
            successMessage: t('refit.instructionCategories.callbackMessages.instructionSuccessfullyCreated'),
        },
        [InstructionModalType.VIEW]: {
            inputDisabled: true,
            modalTitle: t('refit.instructionGroups.manageGroup.instructionsTab.previewTitle'),
            hasFooter: false,
            submitLabel: '',
            hasCheckbox: false,
            successMessage: '',
        },
        [InstructionModalType.ADD_TO_GROUP]: {
            inputDisabled: true,
            modalTitle: t('refit.instructionGroups.addInstructionToGroup.add'),
            hasFooter: true,
            submitLabel: t('refit.instructionGroups.actionButtons.add'),
            hasCheckbox: false,
            successMessage: t('refit.instructionGroups.manageGroup.addInstruction.successMessage'),
        },
    }
}

type InstructionModalProps = {
    type: InstructionModalType
    instruction?: IRefitInstruction
    handleClose: () => void
    handleRefresh?: () => void
    handleSubmit?: (instruction: IRefitInstruction) => void
    isOpen?: boolean
    category?: string
    subCategory?: string
}

const InstructionModal: React.FC<InstructionModalProps> = ({
    type,
    isOpen = true,
    instruction,
    handleClose,
    handleSubmit,
    handleRefresh,
    category,
    subCategory,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [selectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])

    const settings = getParametersByInstructionType(t)[type]
    const { inputDisabled, modalTitle, hasFooter, submitLabel, hasCheckbox, successMessage } = settings

    const formatEstimatedDuration = (estimatedDuration?: number) => {
        if (estimatedDuration && estimatedDuration > 0) {
            return dayjs.duration(estimatedDuration, 'seconds').format('HH:mm:ss')
        } else if (estimatedDuration === 0) {
            return '00:00:00'
        } else {
            return ''
        }
    }

    const [title, setTitle] = useState<string>(
        type === InstructionModalType.CLONE
            ? `${instruction?.title} (${t('global.copied')})`
            : instruction?.title || '',
    )
    const [description, setDescription] = useState<string>(instruction?.description || '')
    const [files, setFiles] = useState<RMSFile[]>(instruction?.files?.length ? instruction?.files : [])
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true)
    const [addMore, setAddMore] = useState<boolean>(false)
    const [isAutonomous, setIsAutonomous] = useState<boolean>(!!instruction?.isAutonomous)
    const [isMultiple, setIsMultiple] = useState<boolean>(!!instruction?.isMultiple)
    const [isMandatory, setIsMandatory] = useState<boolean>(!!instruction?.isMandatory)
    const [isScanOnly, setIsScanOnly] = useState<boolean>(!!instruction?.scanOnly)
    const [estimatedDuration, setEstimatedDuration] = useState<string>(
        formatEstimatedDuration(instruction?.estimatedDuration),
    )

    useEffect(() => {
        if (
            title.length === 0 ||
            description.length === 0 ||
            estimatedDuration.length === 0 ||
            (type === InstructionModalType.EDIT &&
                title === instruction!.title &&
                description === instruction!.description &&
                instruction!.files?.length === files.length &&
                instruction!.files?.every((file, index) => file.position === files[index].position) &&
                instruction!.files?.every((file, index) => file.name === files[index].name) &&
                isAutonomous === instruction!.isAutonomous &&
                isMultiple === instruction!.isMultiple &&
                isMandatory === instruction!.isMandatory &&
                isScanOnly === instruction?.scanOnly &&
                estimatedDuration === formatEstimatedDuration(instruction!.estimatedDuration))
        ) {
            setIsSubmitButtonDisabled(true)
        } else {
            setIsSubmitButtonDisabled(false)
        }
    }, [title, description, estimatedDuration, files, isAutonomous, isMultiple, isMandatory, isScanOnly])

    async function submit() {
        try {
            // upload new files to S3
            const newFiles = []
            for (const file of files) {
                if (!file.id) {
                    const data = await getSignedUrl(file, EntityType.INSTRUCTION)
                    newFiles.push(data)
                }
            }

            if (newFiles.length > 0) await uploadFiles(newFiles)

            const [hours, minutes, seconds] = estimatedDuration.split(':')
            const durationObject = { hours, minutes, seconds }

            const newInstruction = instruction || ({} as IRefitInstruction)
            newInstruction.title = title
            newInstruction.description = description
            newInstruction.isAutonomous = isAutonomous
            newInstruction.isMultiple = isAutonomous && isMultiple
            newInstruction.isMandatory = isMandatory
            newInstruction.scanOnly = isScanOnly
            newInstruction.estimatedDuration = dayjs.duration(durationObject).as('seconds') as any
            newInstruction.files = files.map((file) => {
                return {
                    name: file.remoteName || file.name,
                    signedUrl: file.signedUrl,
                    type: file.type,
                    position: file.position,
                }
            }) as any

            await handleSubmit!(newInstruction)

            await handleRefresh!()

            setTitle('')
            setDescription('')
            setFiles([] as RMSFile[])
            setIsAutonomous(false)
            setIsMultiple(false)
            setIsMandatory(true)
            setIsScanOnly(false)
            setEstimatedDuration('')

            if (addMore) {
                setAddMore(false)
            } else {
                handleClose()
            }

            toast.success(successMessage)
            trackJuneEvent(JuneEvent.NEW_REFIT_INSTRUCTION)
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    function buildLabel(label: string) {
        return inputDisabled ? t(label) : `${t(label)} *`
    }

    return (
        <ModalWithHeader
            title={modalTitle}
            headerType={HeaderType.DIVIDER}
            handleClose={handleClose}
            width={800}
            open={isOpen}
            data-testid="instructionModal"
        >
            <Grid container spacing={5} className={classes.modalBody}>
                <Grid item className={classes.columnBox}>
                    <InputWithLabel label={buildLabel('refit.instructionCategories.modal.INSTRUCTIONS.titleLabel')}>
                        <InputField
                            fullWidth={true}
                            value={title || ''}
                            onChange={(e) => setTitle(e.target.value)}
                            data-testid="instructionModal-title"
                            placeholder={t(`refit.instructionCategories.modal.INSTRUCTIONS.titlePlaceholder`)}
                            disabled={inputDisabled}
                        />
                    </InputWithLabel>
                    <InputWithLabel
                        label={buildLabel('refit.instructionCategories.modal.INSTRUCTIONS.descriptionLabel')}
                    >
                        <InputField
                            multiline={true}
                            rows={3}
                            fullWidth={true}
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                            data-testid="instructionModal-description"
                            placeholder={t(`refit.instructionCategories.modal.INSTRUCTIONS.descriptionPlaceholder`)}
                            disabled={inputDisabled}
                        />
                    </InputWithLabel>
                    <InputWithLabel label={t('reportIssue.takePicture.label')}>
                        <ManageFiles
                            files={files}
                            setFiles={setFiles}
                            inputDisabled={inputDisabled}
                            instructionId={instruction?.id}
                        />
                    </InputWithLabel>
                </Grid>
                <Grid item className={classes.columnBox}>
                    <InputWithLabel
                        label={buildLabel('refit.instructionCategories.modal.INSTRUCTIONS.typeLabel')}
                        tooltip={t('refit.instructionCategories.modal.INSTRUCTIONS.typeTooltip')}
                    >
                        <RadioButtons
                            options={[
                                {
                                    value: false,
                                    label: t(`refit.instructionCategories.modal.INSTRUCTIONS.typeRadio.OPERATOR`),
                                    icon: faUserClock,
                                },
                                {
                                    value: true,
                                    label: t(`refit.instructionCategories.modal.INSTRUCTIONS.typeRadio.AUTONOMOUS`),
                                    icon: faHourglassClock,
                                },
                            ]}
                            value={isAutonomous}
                            onChange={(selectedOption: any) => setIsAutonomous(selectedOption.value)}
                            disabled={inputDisabled}
                            dataTestId="instructionModal-autonomous"
                        />
                    </InputWithLabel>

                    {isAutonomous && (
                        <InputWithLabel
                            label={buildLabel('refit.instructionCategories.modal.INSTRUCTIONS.multipleLabel')}
                            tooltip={t('refit.instructionCategories.modal.INSTRUCTIONS.multipleTooltip')}
                        >
                            <RadioButtons
                                options={[
                                    {
                                        value: true,
                                        label: t(`refit.instructionCategories.modal.INSTRUCTIONS.multipleRadio.YES`),
                                    },
                                    {
                                        value: false,
                                        label: t(`refit.instructionCategories.modal.INSTRUCTIONS.multipleRadio.NO`),
                                    },
                                ]}
                                value={isMultiple}
                                onChange={(selectedOption: any) => setIsMultiple(selectedOption.value)}
                                disabled={inputDisabled}
                                dataTestId="instructionModal-multiple"
                            />
                        </InputWithLabel>
                    )}
                    <InputWithLabel
                        label={buildLabel('refit.instructionCategories.modal.INSTRUCTIONS.mandatoryLabel')}
                        tooltip={t('refit.instructionCategories.modal.INSTRUCTIONS.mandatoryTooltip')}
                    >
                        <RadioButtons
                            options={[
                                {
                                    value: true,
                                    label: t(`refit.instructionCategories.modal.INSTRUCTIONS.mandatoryRadio.YES`),
                                },
                                {
                                    value: false,
                                    label: t(`refit.instructionCategories.modal.INSTRUCTIONS.mandatoryRadio.NO`),
                                },
                            ]}
                            value={isMandatory}
                            onChange={(selectedOption: any) => setIsMandatory(selectedOption.value)}
                            disabled={inputDisabled}
                            dataTestId="instructionModal-mandatory"
                        />
                    </InputWithLabel>
                    <InputWithLabel
                        label={buildLabel('refit.instructionCategories.modal.INSTRUCTIONS.availableActionsLabel')}
                        tooltip={t('refit.instructionCategories.modal.INSTRUCTIONS.availableActionsTooltip')}
                    >
                        <RadioButtons
                            options={[
                                {
                                    value: false,
                                    label: t(
                                        `refit.instructionCategories.modal.INSTRUCTIONS.availableActionsRadio.SCAN_AND_BUTTON`,
                                    ),
                                },
                                {
                                    value: true,
                                    label: t(
                                        `refit.instructionCategories.modal.INSTRUCTIONS.availableActionsRadio.SCAN_ONLY`,
                                    ),
                                },
                            ]}
                            value={isScanOnly}
                            onChange={(selectedOption: any) => setIsScanOnly(selectedOption.value)}
                            disabled={inputDisabled}
                            dataTestId="instructionModal-actions"
                        />
                    </InputWithLabel>
                    <InputWithLabel
                        label={buildLabel('refit.instructionCategories.modal.INSTRUCTIONS.estimatedDurationLabel')}
                    >
                        <InputField
                            fullWidth={true}
                            type="time"
                            value={estimatedDuration || ''}
                            onChange={(e) => setEstimatedDuration(e.target.value)}
                            data-testid="instructionModal-duration"
                            placeholder={t(
                                `refit.instructionCategories.modal.INSTRUCTIONS.estimatedDurationPlaceholder`,
                            )}
                            inputProps={{
                                step: 1,
                            }}
                            disabled={inputDisabled}
                        />
                    </InputWithLabel>
                </Grid>
            </Grid>
            <Tips>
                {t('refit.instructionGroups.createOrEditGroup.forCustomer')} <strong>{selectedCustomer?.name}</strong>{' '}
                {t('refit.instructionGroups.createOrEditGroup.inCategory')}{' '}
                <strong>{category || instruction?.refitCategory?.parentCategory?.title}</strong>{' '}
                {t('refit.instructionGroups.createOrEditGroup.inSubCategory')}{' '}
                <strong>{subCategory || instruction?.refitCategory?.title}</strong>
            </Tips>
            {hasFooter ? (
                <ModalFooter
                    label={submitLabel}
                    submitDisabled={isSubmitButtonDisabled}
                    onSubmit={submit}
                    onCancel={handleClose}
                    leftWidget={
                        hasCheckbox ? (
                            <BasicCheckbox
                                label={t(`refit.instructionCategories.modal.INSTRUCTIONS.addMoreCheckboxLabel`)}
                                setValue={setAddMore}
                                checked={addMore}
                                dataTestId="createAnotherInstruction"
                            />
                        ) : (
                            <></>
                        )
                    }
                />
            ) : (
                <></>
            )}
        </ModalWithHeader>
    )
}

export default InstructionModal
