import * as React from 'react'
import HowItWorks from '_organisms/HowItWorks'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'

type PublishHowItWorksProps = {
    scanInput: (input: string) => void
}

const PublishHowItWorks: React.FC<PublishHowItWorksProps> = ({ scanInput }) => {
    const { t } = useTranslation()

    return (
        <Page title={t('publish.title')} headerStyle={HeaderStyleEnum.WITH_TOOLBAR}>
            <BackgroundWithScanAndFooter scanProps={{ onSubmit: scanInput, placeholder: t('global.scan.puid') }}>
                <HowItWorks
                    title={t('global.howItWorks.title')}
                    description={t('publish.howItWorks.description')}
                    instructions={[
                        t('publish.howItWorks.step1'),
                        t('publish.howItWorks.step2'),
                        t('publish.howItWorks.step3'),
                        t('publish.howItWorks.step4'),
                    ]}
                />
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default PublishHowItWorks
