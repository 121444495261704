import * as React from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import ModalFooter from '_molecules/ModalFooter'
import Multiselect from '_atoms/inputs/Multiselect'
import InputWithLabel from '_molecules/InputWithLabel'
import DatePicker from '_atoms/inputs/DatePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import InputField from '_atoms/inputs/InputField'
import { BatchType, IBatchOrder } from 'interfaces/Batch.interface'
import { OrderType } from 'interfaces'
import RadioButtons, { RadioButtonOption } from '_atoms/inputs/RadioButtons'
import dayjs from 'dayjs'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'
import { BATCHES_URL, ORDERS_NOT_IN_BATCH_URL } from 'utils/routes/backend'
import Tips from '_atoms/containers/Tips'
import { returnPluralPath } from 'utils/stringConvertion'
import { ICustomer } from 'interfaces/Customer'
import { JuneEvent, trackJuneEvent } from 'utils/june'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const useStyles = makeStyles<Theme, { hasOnlyOneCustomer: boolean }>((theme) =>
    createStyles({
        content: {
            display: 'flex',
            gap: `${theme.space(8)}px`,
            marginBottom: theme.space(4),
            [theme.breakpoints.up('sm')]: {
                '& > *': {
                    flex: 1,
                },
            },
            [theme.breakpoints.down('sm')]: {
                gap: `${theme.space(4)}px`,
                flexDirection: 'column',
                paddingTop: theme.space(4),
            },
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(4)}px`,
            '& > *': {
                width: '100%',
            },
        },
        row: {
            display: 'flex',
            gap: `${theme.space(4)}px`,
            flexDirection: (props) => (props.hasOnlyOneCustomer ? 'column' : 'row'),
            [theme.breakpoints.up('sm')]: {
                '& > *': {
                    flex: 1,
                },
            },
            [theme.breakpoints.down('sm')]: {
                gap: `${theme.space(4)}px`,
                flexDirection: 'column',
            },
        },
        datePicker: {
            height: `${theme.space(42 / 5)}px`,
            width: '100%!important',
        },
        multiSelect: {
            height: `${theme.space(42 / 5)}px`,
        },
        bold: {
            fontWeight: 500,
        },
        inputWithLabel: {
            width: '100%',
        },
        inputRadioButton: {
            width: 'fit-content',
        },
        desktopTip: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        mobileTip: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    }),
)

type CreateBatchModalProps = {
    open: boolean
    handleClose: () => void
    fetchBatches: () => void
}

const CreateBatchModal: React.FC<CreateBatchModalProps> = ({ open, handleClose, fetchBatches }) => {
    const [
        selectedCustomers,
        shouldDisplaySecondHandElements,
    ] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.selectedCustomers,
        state.shouldDisplaySecondHandElements,
    ])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])
    const hasOnlyOneCustomer = selectedCustomers.length === 1
    const classes = useStyles({ hasOnlyOneCustomer })
    const { t } = useTranslation()

    const customersWithRentalOnly = !shouldDisplaySecondHandElements()

    const [customers, setCustomers] = useState<ICustomer[]>(hasOnlyOneCustomer ? selectedCustomers : [])
    const [maxShippingDate, setMaxShippingDate] = useState<MaterialUiPickersDate>(dayjs())
    const [maxProducts, setMaxProducts] = useState<number>(selectedWarehouse!.defaultProductNumberByBatch)
    const [batchType, setBatchType] = useState<BatchType>(BatchType.MIXED)
    const [orderType, setOrderType] = useState<OrderType>(customersWithRentalOnly ? OrderType.RENTAL : OrderType.MIXED)
    const [orders, setOrders] = useState<IBatchOrder[]>([])

    function addParamsTofetchOrdersURL() {
        const batchTypeParam = `batchType=${batchType}`
        const orderTypeParam = `orderType=${orderType}`
        const dateParam = `maxShippingDate=${maxShippingDate!.format('YYYY-MM-DD')}`
        const customerFilter = customers.length > 0 ? customers : selectedCustomers
        const customersParam = customerFilter.reduce(
            (customerParam: string, customer: ICustomer) => `${customerParam}&customerIds[]=${customer.id}`,
            '',
        )
        return `${ORDERS_NOT_IN_BATCH_URL}?${batchTypeParam}&${orderTypeParam}&${dateParam}${customersParam}`
    }

    async function fetchOrdersNotInBatch() {
        try {
            const ordersRequest = await rmsApi.get(addParamsTofetchOrdersURL())
            setOrders(ordersRequest.data)
        } catch (e) {
            toast.error(t('new_orders.errors.fetchProductNumberError'))
        }
    }

    useEffect(() => {
        fetchOrdersNotInBatch()
    }, [customers, maxShippingDate, batchType, orderType])

    async function createBatch() {
        try {
            await rmsApi.post(BATCHES_URL, {
                customerIds: customers.map((customer) => customer.id),
                maxShippingDate,
                maxProducts,
                batchType,
                orderType,
            })
            handleClose()
            fetchBatches()
            trackJuneEvent(JuneEvent.NEW_BATCH)
        } catch (e) {
            toast.error(t('new_orders.errors.createBatchError'))
        }
    }

    function incrementMaxProductNumber() {
        setMaxProducts((maxProducts || 0) + 1)
    }

    function decrementMaxProductNumber() {
        setMaxProducts(maxProducts - 1)
    }

    useEffect(() => {
        if (maxProducts < 1) {
            setMaxProducts(1)
        }
    }, [maxProducts])

    const batchTypeOptions = Object.keys(BatchType).map((batchType) => ({
        value: batchType,
        label: t(`new_orders.pickPack.batchCard.batchType.${batchType}`),
    }))

    const orderTypeOptions = Object.keys(OrderType)
        .filter((orderType) => orderType !== OrderType.SALE)
        .map((orderType) => ({
            value: orderType,
            label: t(`new_orders.pickPack.orderType.${orderType}`),
        }))

    const ordersCount = orders.length
    const productsCount = orders.reduce(
        (productReferenceCount, order) =>
            productReferenceCount +
            order.productReferences.reduce(
                (quantityCount, productReference) => quantityCount + productReference.quantity,
                0,
            ),
        0,
    )

    const renderTip = () => (
        <>
            {t('new_orders.pickPack.createBatchModal.atDate')}{' '}
            <span className={classes.bold}>{maxShippingDate?.format('DD/MM/YYYY')}</span>
            {t('new_orders.pickPack.createBatchModal.thereIs')}{' '}
            <span className={classes.bold}>
                {returnPluralPath(t, 'new_orders.pickPack.batchCard.order', ordersCount)}
            </span>
            {t('new_orders.pickPack.createBatchModal.correspondingTo')}{' '}
            <span className={classes.bold}>
                {returnPluralPath(t, 'new_orders.pickPack.batchCard.product', productsCount)}
            </span>
            {t('new_orders.pickPack.createBatchModal.unlinkedBatch')}{' '}
        </>
    )

    return (
        <ModalWithHeader
            title={t('new_orders.pickPack.createBatchModal.title')}
            width={800}
            handleClose={handleClose}
            open={open}
            headerType={HeaderType.MOBILE}
        >
            <div className={classes.content}>
                <div className={classes.column}>
                    {!hasOnlyOneCustomer && (
                        <InputWithLabel label={t('new_orders.pickPack.createBatchModal.customersLabel')}>
                            <Multiselect
                                dataTestId="selectCustomer"
                                options={selectedCustomers}
                                getOptionLabel={(option) => option.name}
                                noOptionsText={t('new_orders.pickPack.createBatchModal.customersEmptyMessage')}
                                placeholder={t('new_orders.pickPack.createBatchModal.customersPlaceholder')}
                                value={customers}
                                onChange={(event, values) => setCustomers(values)}
                                limitTags={2}
                                className={classes.multiSelect}
                            />
                        </InputWithLabel>
                    )}
                    <InputWithLabel
                        label={t('new_orders.pickPack.createBatchModal.limitShippingDateLabel')}
                        className={classes.inputWithLabel}
                    >
                        <DatePicker
                            value={maxShippingDate}
                            onChange={setMaxShippingDate}
                            className={classes.datePicker}
                        />
                    </InputWithLabel>
                    <Tips className={classes.desktopTip}>{renderTip()}</Tips>
                </div>
                <div className={classes.column}>
                    <InputWithLabel
                        label={t('new_orders.pickPack.createBatchModal.batchType')}
                        tooltip={t('new_orders.pickPack.createBatchModal.batchTypeTooltip')}
                        className={classes.inputRadioButton}
                    >
                        <RadioButtons
                            options={batchTypeOptions}
                            value={batchType}
                            onChange={(option: RadioButtonOption) => setBatchType(option.value)}
                            dataTestId="instructionModal-actions"
                        />
                    </InputWithLabel>
                    {!customersWithRentalOnly && (
                        <InputWithLabel
                            label={t('new_orders.pickPack.createBatchModal.orderType')}
                            tooltip={t('new_orders.pickPack.createBatchModal.orderTypeTooltip')}
                            className={classes.inputRadioButton}
                        >
                            <RadioButtons
                                options={orderTypeOptions}
                                value={orderType}
                                onChange={(option: RadioButtonOption) => setOrderType(option.value)}
                                dataTestId="instructionModal-actions"
                            />
                        </InputWithLabel>
                    )}
                    <InputWithLabel
                        label={t('new_orders.pickPack.createBatchModal.maxProductNumber')}
                        className={classes.productNumber}
                    >
                        <InputField
                            type="number"
                            value={String(maxProducts)}
                            onChange={(e) => setMaxProducts(parseInt(e.target.value))}
                            increment={incrementMaxProductNumber}
                            decrement={decrementMaxProductNumber}
                            fullWidth
                        />
                    </InputWithLabel>
                    <Tips className={classes.mobileTip}>{renderTip()}</Tips>
                </div>
            </div>
            <ModalFooter
                label={t('global.validate')}
                onSubmit={createBatch}
                onCancel={handleClose}
                submitDisabled={customers.length === 0 || isNaN(maxProducts) || orders.length === 0}
            />
        </ModalWithHeader>
    )
}

export default CreateBatchModal
