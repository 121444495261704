import * as React from 'react'
import {
    createStyles,
    makeStyles,
    Paper,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core'
import { BasicText } from '_atoms/text/BasicText'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            maxHeight: '60vh',
        },
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
        headerSty: {},
        headerCells: {
            padding: `${theme.spacing(1.5)}px`,
            border: 'none',
            borderBottom: `2px solid ${theme.palette.grey[300]}`,
        },
        row: {
            '&:nth-of-type(even)': {
                backgroundColor: 'rgba(247, 247, 249, 0.4)',
            },
        },
        emptyRowCell: {
            color: theme.palette.grey[500],
            textAlign: 'center',
        },
        cell: {
            padding: `${theme.spacing(1.5)}px`,
            color: '#14223D',
            border: 'none',
        },
        headerLabelContainer: {
            alignItems: 'center',
        },
        pointerCursor: {
            cursor: 'pointer',
        },
        headerText: {
            color: '#555E73',
        },
    }),
)

type TableProps = {
    header: { label: any }[]
    rows: { cells: { value: any }[]; onRowClick?: () => any }[]
    children?: any
}

const Table: React.FC<TableProps> = ({ header, rows, children }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [filters, pagination] = children || []

    return (
        <>
            {filters}
            <TableContainer component={Paper} style={{ margin: '10px 0', maxHeight: '60vh', boxShadow: 'none' }}>
                <MuiTable id="tableBody" className={classes.table} aria-label="customized table" stickyHeader>
                    <TableHead className={classes.headerSty}>
                        <TableRow>
                            {header?.map((headerElement: any, index: number) => (
                                <TableCell className={classes.headerCells} key={index}>
                                    <BasicText className={classes.headerText}>{headerElement.label}</BasicText>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.length > 0 ? (
                            <>
                                {rows.map(({ cells, onRowClick }, index: number) => (
                                    <TableRow
                                        key={index}
                                        onClick={onRowClick}
                                        className={`${classes.row} ${onRowClick ? classes.pointerCursor : ''}`}
                                    >
                                        {cells?.map((cell, index) => (
                                            <TableCell className={classes.cell} key={index}>
                                                {cell?.value}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </>
                        ) : (
                            <TableRow key={1} className={classes.row}>
                                <TableCell colSpan={header?.length} className={classes.emptyRowCell}>
                                    {t('table.emptyTable')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MuiTable>
            </TableContainer>
            {pagination}
        </>
    )
}

export default Table
