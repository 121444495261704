import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { WMS_PRODUCT_REFERENCE_STOCK_TO_CSV_URL, WMS_PRODUCT_REFERENCE_STOCK_URL } from 'utils/routes/backend'
import rmsApi from 'utils/api'
import AlertCircleIcon from 'mdi-react/AlertCircleIcon'
import Page from '_organisms/Page'
import TableV2 from '_organisms/tableV2/TableV2'
import Dash from '_atoms/Dash'
import Status from '_atoms/badges/Status'
import { PaginationInfos, SearchFieldDefaultFilterKey } from 'interfaces/Table.interfaces'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import DatePickerFilter from '_organisms/tableV2/filters/DatePickerFilter'
import { toast } from 'react-toastify'
import { UniqueProductStatus } from 'interfaces'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import MobileSearchFilter from '_organisms/tableV2/filters/MobileSearchFilter'
import CheckboxListFilter from '_organisms/tableV2/filters/CheckboxListFilter'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        storageLocationContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(1)}px`,
        },
        locationContainer: {
            display: 'flex',
            gap: `${theme.space(2 / 5)}px`,
            alignItems: 'center',
            justifyContent: 'start',
        },
        storageLocation: {
            color: theme.palette.common.white,
        },
        stockProblem: {
            display: 'inline-block',
            color: theme.palette.error.main,
            '&>.icon': {
                fontSize: theme.typography.pxToRem(14),
                verticalAlign: 'middle',
            },
        },
    }),
)

interface TableRowStock {
    locationsWithQuantity: string | JSX.Element
    sku: string
    productReferenceName: string
    quantityInStock: number
    quantityInRefit: number
    quantityInRepair: number
    expectedQuantity: number | JSX.Element
    orders: string
    customerName: string
}

const StockForecastTable: React.FC = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const buildErrorMessage = useErrorMessage()
    const [productList, setProductList] = useState<TableRowStock[]>([])
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])

    function buildQuantityFromStatuses(
        stock: Record<UniqueProductStatus, Record<string, number>>,
        statuses: UniqueProductStatus[],
    ) {
        let quantity = 0
        for (const status of statuses) {
            if (stock[status]) {
                quantity += Object.values(stock[status]).reduce((total, value) => {
                    return total + value
                }, 0)
            }
        }
        return quantity
    }

    function buildLocationsWithQuantity(
        stock: Record<UniqueProductStatus, Record<string, number>>,
        statuses: UniqueProductStatus[],
    ) {
        const locationsWithQuantity: { location: string; quantity: number }[] = []

        for (const status of statuses) {
            if (stock[status]) {
                for (const [location, quantity] of Object.entries(stock[status])) {
                    const locationExists = locationsWithQuantity.find(
                        (locationWithQuantity) => locationWithQuantity.location === location,
                    )
                    if (locationExists) {
                        locationExists.quantity += quantity
                    } else {
                        locationsWithQuantity.push({ location, quantity })
                    }
                }
            }
        }

        return (
            <div key="unique" className={classes.storageLocationContainer}>
                {locationsWithQuantity.map(({ location, quantity }) => (
                    <div key={location} className={classes.locationContainer}>
                        <Status color="primary" label={location} />
                        <Dash size="20px" orientation="vertical" />
                        {quantity}
                    </div>
                ))}
            </div>
        )
    }

    function buildExpectedQuantity(inStock: number, expectedQuantity: number) {
        if (inStock >= expectedQuantity) {
            return expectedQuantity
        }

        return (
            <div className={classes.stockProblem}>
                <AlertCircleIcon className="MuiSvgIcon-root icon" />
                &nbsp;{expectedQuantity}
            </div>
        )
    }

    function buildRows(products: any) {
        const rows: TableRowStock[] = []

        for (const product of products) {
            const {
                productReference_name: name,
                productReference_size: size,
                productReference_sku: sku,
                customer_name: customerName,
                expected_qty: expected,
                stockDetail_stock: stock,
                orders,
            } = product

            const inStockStatuses = [UniqueProductStatus.IN_STOCK, UniqueProductStatus.RESERVED]
            const inRefitStatuses = [UniqueProductStatus.TO_REFIT, UniqueProductStatus.REFIT_IN_PROGRESS]

            const productReferenceName = size ? `${name} - ${size}` : name
            const quantityInStock = buildQuantityFromStatuses(stock, inStockStatuses)
            const quantityInRefit = buildQuantityFromStatuses(stock, inRefitStatuses)
            const quantityInRepair = buildQuantityFromStatuses(stock, [UniqueProductStatus.TO_REPAIR])
            const locationsWithQuantity = buildLocationsWithQuantity(stock, inStockStatuses)
            const expectedQuantity = buildExpectedQuantity(quantityInStock, expected)

            rows.push({
                locationsWithQuantity,
                sku,
                productReferenceName,
                quantityInStock,
                quantityInRefit,
                quantityInRepair,
                expectedQuantity,
                orders,
                customerName,
            })
        }

        setProductList(rows)
    }

    async function fetchProductList(
        filters: any,
        currentPage: number,
        itemsPerPage: number,
        setPagination: (p: PaginationInfos) => void,
    ): Promise<void> {
        const limitDate = filters.limitDate?.toISOString() || new Date().toISOString()

        const search = filters.searchField ? `&search=${filters.searchField}` : ''
        const buildCustomersParams = (name: string) =>
            `&customerIds[]=${selectedCustomers.find((customer) => customer.name === name)?.id}`
        const customers = filters.customers?.map(buildCustomersParams).join('') || ''

        try {
            const request = await rmsApi.get(
                `${WMS_PRODUCT_REFERENCE_STOCK_URL}?date=${limitDate}&page=${currentPage}&limit=${itemsPerPage}${search}${customers}`,
            )
            const { items, meta } = request.data
            const products = items.filter((product: any) => product.productReference_name)

            if (meta) setPagination(meta)
            buildRows(products)
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function downloadForecastNeedListToCsv(filters: any, skus?: string[]): Promise<void> {
        try {
            const limitDate = filters.limitDate?.toISOString() || new Date().toISOString()
            const downloadDate = dayjs().format('YYYY-MM-DD-HH-mm-ss')
            let forecastNeedToCsvQuery = `${WMS_PRODUCT_REFERENCE_STOCK_TO_CSV_URL}?date=${limitDate}&search=${
                filters.searchField || ''
            }`
            if (skus) forecastNeedToCsvQuery += skus.map((sku) => `&skus[]=${sku}`).join('')
            const forecastNeedCsv = await rmsApi.get(forecastNeedToCsvQuery, { responseType: 'blob' })

            fileDownload(forecastNeedCsv.data, `forecast-need-${downloadDate}.csv`)
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    const header = [
        { value: 'locationsWithQuantity', label: 'wms.productReferences.columnStorageQty' },
        { value: 'sku', label: 'wms.productReferences.columnProductReference' },
        { value: 'productReferenceName', label: 'wms.productReferences.columnName' },
        { value: 'quantityInStock', label: 'wms.productReferences.columnInStock' },
        { value: 'quantityInRefit', label: 'wms.productReferences.columnInRefit' },
        { value: 'quantityInRepair', label: 'wms.productReferences.columnInRepair' },
        { value: 'expectedQuantity', label: 'wms.productReferences.columnExpectedQty' },
        { value: 'orderIds', label: 'wms.productReferences.columnOrders' },
        { value: 'customerName', label: 'wms.productReferences.columnCustomer' },
    ]

    const filterOptions = (filters: any) => [
        {
            filterKey: SearchFieldDefaultFilterKey,
            label: t('table.searchField'),
            component: <MobileSearchFilter placeholder={t('wms.productReferences.searchFieldPlaceholder')} />,
        },
        {
            filterKey: 'customers',
            label: t('wms.productReferences.filters.customers'),
            component: (
                <CheckboxListFilter
                    options={selectedCustomers.map((customer) => customer.name!) || []}
                    filteredOptions={filters?.customers || []}
                />
            ),
        },
        {
            filterKey: 'limitDate',
            label: t('wms.productReferences.filters.limitDate'),
            component: <DatePickerFilter />,
        },
    ]

    const defaultAction = (filters: any) => {
        return {
            label: t('wms.productReferences.availableActions.allToCSV'),
            action: () => downloadForecastNeedListToCsv(filters),
        }
    }

    const batchActions = (filters: any, selectedRows: any[]) => [
        {
            label: t('wms.productReferences.availableActions.selectedToCSV'),
            action: () =>
                downloadForecastNeedListToCsv(
                    filters,
                    selectedRows.map((s) => s.row.sku),
                ),
        },
    ]

    return (
        <Page
            title={t('wms.productReferences.title')}
            section={t('menu.shipmentsAndWithdrawals.title')}
            contentPaddingTopDesktop={2}
            className={classes.page}
        >
            <TableV2
                fetchRows={fetchProductList}
                initialHeader={header}
                rows={productList}
                filterOptions={filterOptions}
                defaultAction={defaultAction}
                batchActions={batchActions}
                searchFieldPlaceholder={t('wms.productReferences.searchFieldPlaceholder')}
                localStorageName="stockForecastTable"
            />
        </Page>
    )
}

export default StockForecastTable
