import { useEffect, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Skeleton } from '@material-ui/lab'
import { UNIQUE_PRODUCTS_URL, UNIQUEPRODUCTS_HISTORY_URL } from 'utils/routes/backend'
import RMSTable from 'components/reusable/RMSTable'
import HistoryDetailApp from './HistoryDetailApp'
import { HistoryList, HistorySingle } from 'interfaces/History.interface'
import AppTitle from 'components/reusable/AppTitle'
import rmsApi from 'utils/api'
import Page from '_organisms/Page'

const HistoryApp: React.FC = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)
    const [historyList, setHistoryList] = useState<HistoryList[]>([])
    const [historySingle, setHistorySingle] = useState<HistorySingle[]>([])
    const [historyDatas, setHistoryDatas] = useState<string[]>([])

    const loadingDatasRow = { col1: null, col2: null, col3: null, col4: null }
    const loadingDatas = Array(12).fill(loadingDatasRow)

    async function fetchHistory(): Promise<void> {
        try {
            const historyRequest = await rmsApi.get(UNIQUEPRODUCTS_HISTORY_URL)
            const history = historyRequest.data
            history.forEach((el: HistoryList) => {
                el.detail = (
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        endIcon={<ChevronRightIcon />}
                        key={el.uniqueProduct_id}
                        onClick={() => handleHistorySingle(el.uniqueProduct_id, el.product_name)}
                        data-testid="cta"
                    >
                        {t('history.list.more')}
                    </Button>
                )
            })
            setHistoryList(history)
            setLoading(false)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    async function fetchHistorySingle(id: string): Promise<void> {
        try {
            const historySingleRequest = await rmsApi.get(`${UNIQUE_PRODUCTS_URL}/${id}/history`)
            const historySingle = historySingleRequest.data
            setHistorySingle(historySingle)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    const handleHistorySingle = (id: string, productName: string) => {
        fetchHistorySingle(id)
        setHistoryDatas([productName, id])
    }

    const handleHistoryBack = () => {
        setHistorySingle([])
    }

    useEffect(() => {
        fetchHistory()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {loading ? (
                <>
                    <Grid item>
                        <Typography variant="h1" component="h2" align="center" gutterBottom data-testid="title">
                            <Skeleton variant="text" style={{ maxWidth: '50%', margin: 'auto' }} />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <RMSTable isLoading columns={new Array(4)} rows={loadingDatas} />
                    </Grid>
                </>
            ) : historySingle.length === 0 ? (
                <Page title={t('history.title')}>
                    <RMSTable
                        columns={[
                            t('history.list.uid'),
                            t('history.list.productName'),
                            t('history.list.activities'),
                            t('history.list.operations'),
                        ]}
                        rows={historyList}
                    />
                </Page>
            ) : (
                <HistoryDetailApp data={historyDatas} operations={historySingle} handleBack={handleHistoryBack} />
            )}
        </>
    )
}

export default HistoryApp
