import * as React from 'react'
import { createStyles, makeStyles, Theme, Tooltip, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IRefitInstruction } from 'interfaces'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import Text from '_atoms/text/Text'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            padding: theme.space(3, 4),
            fontWeight: 300,
            lineHeight: theme.typography.pxToRem(19),
            fontSize: theme.typography.pxToRem(11),
        },
    }),
)

type InstructionInfoProps = {
    instruction: IRefitInstruction
}

export const InstructionInfo: React.FC<InstructionInfoProps> = ({ instruction }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    const { isAutonomous, isMultiple, isMandatory, scanOnly } = instruction

    return (
        <Tooltip
            placement="top"
            title={
                <div className={classes.tooltip}>
                    <Text variant="P5">
                        {t('refit.instructionCategories.modal.INSTRUCTIONS.typeLabel')} :{' '}
                        {isAutonomous
                            ? t('refit.instructionCategories.modal.INSTRUCTIONS.typeRadio.AUTONOMOUS')
                            : t('refit.instructionCategories.modal.INSTRUCTIONS.typeRadio.OPERATOR')}
                    </Text>
                    {isAutonomous && (
                        <Text variant="P5">
                            {t('refit.instructionCategories.modal.INSTRUCTIONS.multipleLabel')} :{' '}
                            {isMultiple
                                ? t('refit.instructionCategories.modal.INSTRUCTIONS.multipleRadio.YES')
                                : t('refit.instructionCategories.modal.INSTRUCTIONS.multipleRadio.NO')}
                        </Text>
                    )}
                    <Text variant="P5">
                        {t('refit.instructionCategories.modal.INSTRUCTIONS.mandatoryLabel')} :{' '}
                        {isMandatory
                            ? t('refit.instructionCategories.modal.INSTRUCTIONS.mandatoryRadio.YES')
                            : t('refit.instructionCategories.modal.INSTRUCTIONS.mandatoryRadio.NO')}
                    </Text>
                    <Text variant="P5">
                        {t('refit.instructionCategories.modal.INSTRUCTIONS.availableActionsLabel')} :{' '}
                        {scanOnly
                            ? t('refit.instructionCategories.modal.INSTRUCTIONS.availableActionsRadio.SCAN_ONLY')
                            : t('refit.instructionCategories.modal.INSTRUCTIONS.availableActionsRadio.SCAN_AND_BUTTON')}
                    </Text>
                </div>
            }
        >
            <div>
                <FontAwesomeIcon icon={faCircleInfo} color={theme.palette.neutral[600]} />
            </div>
        </Tooltip>
    )
}
