import * as React from 'react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Container,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { TailSpin } from 'react-loader-spinner'
import { Label } from 'interfaces'
import axios from 'axios'
import PDFMerger from 'pdf-merger-js/browser'
import Print from '@material-ui/icons/Print'
import CloudDownload from '@material-ui/icons/CloudDownload'
import LabelRow from './labelRow'
import dayjs from 'dayjs'
import { toast } from 'utils/toast'
import DropdownValuePicker from 'components/reusable/DropDownValuePicker'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subtitle: {
            color: theme.palette.grey[600],
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(16),
            [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
        helperText: {
            marginTop: theme.spacing(1),
            color: theme.palette.grey[600],
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.1,
            [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
        nbParcelsPicker: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        listTitle: {
            [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(14),
            },
        },
        listOfLabels: {
            marginTop: theme.spacing(2),
        },
        downloadLabelContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: ' center',
        },
        downloadLabelLine: {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 600,
        },
        downloadLabelsCta: {
            border: `1px solid ${theme.palette.primary[600]}`,
            color: theme.palette.primary[600],
        },
        downloadDeliveryNoteCta: {
            border: `1px solid ${theme.palette.primary[600]}`,
            color: theme.palette.primary[600],
            marginRight: theme.spacing(-1),
        },
        printAndDownloadContainer: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
    }),
)

const NB_MAX_PARCELS = 20

type LabelPrinterProps = {
    forthLabels: Label[]
    returnLabels: Label[]
    deliveryNoteEnabled: boolean | undefined
    fetchLabels: (nbParcels: number) => any
    deliveryNoteUrl: string | undefined
    fetchDeliveryNote: () => any
    setValidatePreparation: (b: boolean) => void
}

const LabelPrinter: React.FC<LabelPrinterProps> = ({
    forthLabels,
    returnLabels,
    deliveryNoteEnabled,
    fetchLabels,
    deliveryNoteUrl,
    fetchDeliveryNote,
    setValidatePreparation,
}) => {
    const { t } = useTranslation()
    const [nbParcels, setNbParcels] = useState(1)
    const [isGenerating, setIsGenerating] = useState(false)
    const [shouldCreatePdf, setShouldCreatePdf] = useState(false)
    const classes = useStyles()
    const theme = useTheme()
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))

    const nbLabels = forthLabels.length
    const possibleNbParcels = []
    for (let i = 1; i <= NB_MAX_PARCELS; ++i) {
        possibleNbParcels.push({ label: `${i}`, value: i })
    }

    const [mergedPdfUrl, setMergedPdfUrl] = useState<string>()
    const [mergedPdfLabelUrl, setMergedPdfLabelUrl] = useState<string>()
    const [deliveryNotePdfUrl, setDeliveryNotePdfUrl] = useState<string>()
    const [deliveryNoteFile, setDeliveryNoteFile] = useState<any>(null)
    const [labelFiles, setLabelFiles] = useState<any[]>([])

    async function generatePdf(filesToMerge: File[]) {
        const merger = new PDFMerger()
        await Promise.all(filesToMerge.map(async (file) => await merger.add(file)))
        const mergedPdf = await merger.saveAsBlob()
        return URL.createObjectURL(mergedPdf)
    }

    async function createAllPdfs() {
        const deliveryNoteFiles = Array(forthLabels.length).fill(deliveryNoteFile)

        const promises = []
        try {
            promises.push(generatePdf(labelFiles))
            // generate deliveryNote url
            if (deliveryNoteEnabled && deliveryNoteFile !== null) {
                promises.push(generatePdf(deliveryNoteFiles))
                // generate both label and deliveryNote url
                promises.push(generatePdf([...labelFiles, ...deliveryNoteFiles]))
            }

            const [pdfLabelUrl, deliveryNotePdfUrl, mergedPdfUrl] = await Promise.all(promises)

            setMergedPdfLabelUrl(pdfLabelUrl)
            if (deliveryNoteEnabled) {
                setDeliveryNotePdfUrl(deliveryNotePdfUrl)
                setMergedPdfUrl(mergedPdfUrl)
            } else {
                setMergedPdfUrl(pdfLabelUrl)
            }

            setIsGenerating(false)
            setValidatePreparation(true)
        } catch (e) {
            toast.error(t('wms.preparation.labels.generationError'))
        }
    }

    useEffect(() => {
        // Generate labels url
        if (labelFiles.length > 0) {
            if (!deliveryNoteEnabled || (deliveryNoteEnabled && deliveryNoteFile)) {
                setShouldCreatePdf(true)
            }
        }
    }, [deliveryNoteFile, labelFiles])

    useEffect(() => {
        if (shouldCreatePdf) {
            createAllPdfs()
        }
    }, [shouldCreatePdf])

    useEffect(() => {
        const downloadBlob = async (url: string) => {
            const res = await axios.get(url, { responseType: 'blob' })
            return res.data
        }

        const getPdfs = async () => {
            const newLabelFiles = []
            try {
                if (forthLabels?.length) {
                    for (const forthLabel of forthLabels) {
                        const file = await downloadBlob(forthLabel.label)
                        newLabelFiles.push(file)
                    }
                }
                if (returnLabels?.length) {
                    for (const returnLabel of returnLabels) {
                        const file = await downloadBlob(returnLabel.label)
                        newLabelFiles.push(file)
                    }
                }
                if (newLabelFiles.length > 0) setLabelFiles(newLabelFiles)
                if (deliveryNoteUrl && deliveryNoteEnabled) {
                    const file = await downloadBlob(deliveryNoteUrl)
                    setDeliveryNoteFile(file)
                }
            } catch (e) {
                console.error(e)
                toast.error(t('wms.preparation.labels.downloadError'))
            }
        }

        getPdfs()
    }, [forthLabels, returnLabels, deliveryNoteUrl, t])

    useLayoutEffect(() => {
        return () => {
            if (mergedPdfUrl) {
                window.URL.revokeObjectURL(mergedPdfUrl)
            }
            if (mergedPdfLabelUrl) {
                window.URL.revokeObjectURL(mergedPdfLabelUrl)
            }
            if (deliveryNotePdfUrl) {
                window.URL.revokeObjectURL(deliveryNotePdfUrl)
            }
        }
    }, [])

    useEffect(() => {
        if (mergedPdfUrl) {
            setTimeout(
                () => togglePrintDialog(mergedPdfUrl, t('wms.preparation.labels.filename'), 'iframe-all-pdf'),
                500,
            )
        }
    }, [mergedPdfUrl])

    const printIframe = (fileType: string) => {
        const iframe = (document as any).frames ? (document as any).frames[fileType] : document.getElementById(fileType)
        const iframeWindow = iframe?.contentWindow || iframe

        if (iframe) iframe.focus()
        if (iframeWindow) iframeWindow.print()
    }

    async function togglePrintDialog(filesUrl: string | undefined, name: string, fileType: string) {
        if (!filesUrl) return
        const fileName = `${name}-${dayjs(new Date()).format('HHmmssDDMMYY')}.pdf`

        if (isMobileDevice && navigator['share']) {
            const downloadedFileQuery = await axios.get(filesUrl, {
                responseType: 'blob',
            })
            await shareMobile(downloadedFileQuery?.data, fileName)
        } else {
            printIframe(fileType)
        }
    }

    async function shareMobile(blobFile: Blob, fileName: string) {
        try {
            const pdf = new File([blobFile], fileName, { type: 'application/pdf' })
            const files = { files: [pdf] } as ShareData
            await navigator.share(files)
        } catch (e: any) {
            toast.error(t('label.printError'))
        }
    }

    function openPdfInNewTab() {
        if (!mergedPdfUrl) return

        try {
            const a = document.createElement('a')
            a.href = mergedPdfUrl
            a.target = '_blank'
            a.rel = 'noopener noreferrer'
            a.click()
            a.remove()
        } catch (e: any) {
            toast.error(t('label.printError'))
        }
    }

    const generateAndMergeLabels = async () => {
        setIsGenerating(true)
        if (deliveryNoteEnabled) {
            await fetchDeliveryNote()
        }
        await fetchLabels(nbParcels)
    }

    const handleSelectChange = (event: any) => {
        setNbParcels(event.target.value)
    }

    return (
        <>
            <Container maxWidth="sm">
                {nbLabels ? (
                    <Box pb={2}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            {t('wms.preparation.labels.subtitle2')}
                        </Typography>
                        <Typography className={classes.helperText}>{t('wms.preparation.labels.helper3')}</Typography>
                        <Typography className={classes.helperText}>{t('wms.preparation.labels.helper4')}</Typography>
                    </Box>
                ) : (
                    <>
                        <Box pb={2}>
                            <Typography variant="subtitle2" className={classes.subtitle}>
                                {t('wms.preparation.labels.subtitle1')}
                            </Typography>
                            <Typography className={classes.helperText}>
                                {t('wms.preparation.labels.helper1')}
                            </Typography>
                            <Typography className={classes.helperText}>
                                {t('wms.preparation.labels.helper2')}
                            </Typography>
                        </Box>

                        <Grid container justify="space-between" alignItems="center" className={classes.nbParcelsPicker}>
                            <Grid item xs={8} sm="auto">
                                <Typography variant="h4">{t('wms.preparation.labels.nbParcelsTitle')}</Typography>
                            </Grid>
                            <Grid item xs={4} sm="auto">
                                <DropdownValuePicker
                                    value={nbParcels}
                                    choices={possibleNbParcels}
                                    onChange={handleSelectChange}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Container>
            <Container maxWidth="sm">
                {mergedPdfLabelUrl && (
                    <>
                        <Box pt={2} pb={2}>
                            <Typography variant="h2" className={classes.listTitle}>
                                {t('wms.preparation.labels.generatedDocuments')}
                            </Typography>
                            <Container disableGutters className={classes.listOfLabels}>
                                <Grid className={classes.downloadLabelContainer} container>
                                    <Grid className={classes.downloadLabelLine} item>
                                        {t('wms.preparation.labels.labels')}
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="small"
                                            data-testid="togglePrintLabels"
                                            className={classes.downloadLabelsCta}
                                            onClick={() =>
                                                togglePrintDialog(
                                                    mergedPdfLabelUrl,
                                                    t('wms.preparation.labels.labelsFilename'),
                                                    'iframe-labels-pdf',
                                                )
                                            }
                                        >
                                            {t('wms.preparation.labels.printLabels')}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <LabelRow
                                    text={t('wms.preparation.labels.forthLabel')}
                                    tips={t('wms.preparation.labels.onParcelTips')}
                                >
                                    x{forthLabels.length}
                                </LabelRow>
                                {returnLabels?.length > 0 && (
                                    <>
                                        <LabelRow
                                            text={t('wms.preparation.labels.returnLabel')}
                                            tips={t('wms.preparation.labels.insideParcelTips')}
                                        >
                                            x{returnLabels.length}
                                        </LabelRow>
                                        <Divider style={{ margin: '16px 0px' }} />
                                    </>
                                )}

                                {deliveryNoteEnabled && (
                                    <LabelRow
                                        text={t('wms.preparation.labels.deliveryNote')}
                                        tips={t('wms.preparation.labels.insideParcelTips')}
                                    >
                                        <Button
                                            size="small"
                                            disabled={!deliveryNotePdfUrl}
                                            className={classes.downloadDeliveryNoteCta}
                                            onClick={() =>
                                                togglePrintDialog(
                                                    deliveryNotePdfUrl,
                                                    t('wms.preparation.labels.deliveryNoteFilename'),
                                                    'iframe-deliveryNote-pdf',
                                                )
                                            }
                                        >
                                            {t('wms.preparation.labels.printDeliveryNote')}
                                        </Button>
                                    </LabelRow>
                                )}
                            </Container>
                            {deliveryNoteEnabled && <Divider style={{ margin: '16px 0px' }} />}
                        </Box>
                    </>
                )}
                <Grid container justify="center" alignItems="center">
                    {mergedPdfUrl ? (
                        <>
                            <Grid container className={classes.printAndDownloadContainer} spacing={1}>
                                <Grid item>
                                    <Button
                                        data-testid={'printButton'}
                                        size={isMobileDevice ? 'small' : 'medium'}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Print />}
                                        onClick={() =>
                                            togglePrintDialog(
                                                mergedPdfUrl,
                                                t('wms.preparation.labels.filename'),
                                                'iframe-all-pdf',
                                            )
                                        }
                                    >
                                        {t('wms.preparation.labels.print')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        data-testid={'downloadButton'}
                                        size={isMobileDevice ? 'small' : 'medium'}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CloudDownload />}
                                        onClick={() => openPdfInNewTab()}
                                    >
                                        {t('wms.preparation.labels.download')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Button
                            data-testid={'generateButton'}
                            size={isMobileDevice ? 'small' : 'medium'}
                            variant="contained"
                            color="primary"
                            onClick={() => generateAndMergeLabels()}
                            startIcon={
                                isGenerating && (
                                    <Box mr={1}>
                                        <TailSpin visible color="#FFF" height={16} width={16} />
                                    </Box>
                                )
                            }
                            disabled={isGenerating}
                        >
                            {deliveryNoteEnabled
                                ? t('wms.preparation.labels.generateWithDeliveryNote')
                                : t('wms.preparation.labels.generate')}
                        </Button>
                    )}
                </Grid>
            </Container>
            {mergedPdfUrl && (
                <iframe
                    data-testid="allFilesIframe"
                    id="iframe-all-pdf"
                    title="iframe-all-pdf"
                    src={mergedPdfUrl}
                    style={{ display: 'none' }}
                ></iframe>
            )}
            {mergedPdfLabelUrl && (
                <iframe
                    data-testid="labelsIframe"
                    id="iframe-labels-pdf"
                    title="iframe-labels-pdf"
                    src={mergedPdfLabelUrl}
                    style={{ display: 'none' }}
                ></iframe>
            )}
            {deliveryNotePdfUrl && (
                <iframe
                    data-testid="deliveryNoteIframe"
                    id="iframe-deliveryNote-pdf"
                    title="iframe-deliveryNote-pdf"
                    src={deliveryNotePdfUrl}
                    style={{ display: 'none' }}
                ></iframe>
            )}
        </>
    )
}

export default LabelPrinter
