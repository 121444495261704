import create from 'zustand/vanilla'
import { persist } from 'zustand/middleware'
import { IWarehouse } from 'interfaces/Warehouse'

export interface SelectedWarehouseState {
    selectedWarehouse: IWarehouse | undefined
    disabledSelectedWarehouse: boolean
    setSelectedWarehouse: (warehouse: IWarehouse) => void
    setDisabledSelectedWarehouse: (disabledSelectedWarehouse: boolean) => void
}

const selectedWarehouseStore = create<SelectedWarehouseState>()(
    persist(
        (set, get) => ({
            selectedWarehouse: undefined,
            disabledSelectedWarehouse: false,
            setSelectedWarehouse: (selectedWarehouse: IWarehouse) => {
                set({ selectedWarehouse })
            },
            setDisabledSelectedWarehouse: (disabledSelectedWarehouse: boolean) => set({ disabledSelectedWarehouse }),
        }),
        {
            name: 'selectedWarehouse',
        },
    ),
)

export default selectedWarehouseStore
