import * as React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.common.white,
    },
    img: {
        maxWidth: '100%',
        height: 'calc(100vh - 85px)',
        objectFit: 'contain',
    },
    swipeButton: {
        position: 'absolute',
        top: '50%',
        backgroundColor: theme.palette.grey[900],
        opacity: '40%',
        borderRadius: '3px',
        width: '30px',
        minWidth: '30px',
        height: '50px',
    },
    disabledSwipeButton: {
        disabled: true,
        visibility: 'hidden',
    },
    icon: {
        color: '#ffffff',
    },
    hideCounter: {
        '.MuiMobileStepper-positionStatic .MuiMobileStepper-dot': {
            visibility: 'hidden',
        },
        backgroundColor: '#000000',
        color: '#ffffff',
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}))

type SwipeableCarouselProps = {
    images: string[]
}

const SwipeableCarousel: React.FC<SwipeableCarouselProps> = ({ images }) => {
    const classes = useStyles()
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = images?.length || 0

    if (!images || !images.length) return null
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStepChange = (step: number) => {
        setActiveStep(step)
    }

    return (
        <div>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((image, index) => (
                    <div key={index} className={classes.imgContainer}>
                        <img key={index} className={classes.img} src={image} alt="Product" />
                    </div>
                ))}
            </SwipeableViews>
            <MobileStepper
                className={classes.hideCounter}
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                backButton={
                    <>
                        <div></div>
                        <Button
                            className={`${classes.swipeButton} ${activeStep === 0 ? classes.disabledSwipeButton : ''}`}
                            style={{ left: '10%' }}
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight className={classes.icon} />
                            ) : (
                                <KeyboardArrowLeft className={classes.icon} />
                            )}
                        </Button>
                    </>
                }
                nextButton={
                    <>
                        <div></div>
                        <Button
                            className={`${classes.swipeButton} ${
                                activeStep === maxSteps - 1 ? classes.disabledSwipeButton : ''
                            }`}
                            style={{ right: '10%' }}
                            size="small"
                            onClick={handleNext}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft className={classes.icon} />
                            ) : (
                                <KeyboardArrowRight className={classes.icon} />
                            )}
                        </Button>
                    </>
                }
            />
        </div>
    )
}

export default SwipeableCarousel
