import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import LocationCard from './LocationCard'
import { useNavigate, useParams } from 'react-router-dom'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { PICKING_PACKING_ROUTE } from 'utils/routes/frontend'
import Button from '_atoms/buttons/Button'
import { faPause } from '@fortawesome/pro-regular-svg-icons'
import Text from '_atoms/text/Text'
import { toast } from 'utils/toast'
import { IPickingLocation } from 'interfaces/Picking.interface'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import { useKeycloak } from '@react-keycloak/web'
import Counter from '_atoms/badges/Counter'
import { IUniqueProductOrProductReference } from 'interfaces/Batch.interface'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
            marginTop: theme.space(2),
        },
        pickedProductsHeader: {
            padding: theme.space(0, 2),
        },
        locationListTagline: {
            color: theme.palette.neutral[600],
            padding: theme.space(1, 5),
            [theme.breakpoints.up('md')]: {
                padding: theme.space(5, 0),
            },
        },
    }),
)

type LocationListProps = {
    locations?: IPickingLocation[]
    alreadyPickedProducts?: IUniqueProductOrProductReference[]
    setCurrentLocation: (location: IPickingLocation | null) => void
}

const LocationList: React.FC<LocationListProps> = ({ locations, alreadyPickedProducts, setCurrentLocation }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { batchId } = useParams()
    const { keycloak } = useKeycloak<any>()

    const nbProductsToPick = locations?.reduce((acc, location) => acc + location.products.length, 0) || 0
    const totalProducts = nbProductsToPick + (alreadyPickedProducts?.length || 0)

    const renderPauseButton = (
        <Button
            endIcon={faPause}
            size="large"
            onClick={() => navigate(PICKING_PACKING_ROUTE)}
            data-testid="pausePicking"
        >
            {t('new_orders.picking.pauseButton')}
        </Button>
    )

    function selectLocationByScan(location: string) {
        const locationInfos = locations?.find((l) => l.location === location)

        if (!locationInfos) {
            toast.error(t('new_orders.errors.locationDoesNotExist'))
        } else {
            setCurrentLocation(locationInfos)
        }
    }

    let sortedLocations: IPickingLocation[] = []
    if (locations) {
        const emptyLocations = locations.filter((location) => !location.location)
        const notEmptyLocations = locations
            .filter((location) => location.location)
            .sort((a, b) => a.location.localeCompare(b.location, keycloak?.tokenParsed?.locale, { numeric: true }))
        sortedLocations = emptyLocations.length > 0 ? [...notEmptyLocations, ...emptyLocations] : notEmptyLocations
    }

    return (
        <Page
            title={`${t('new_orders.picking.headerTitle')} ${batchId}`}
            section={t('new_orders.picking.headerSection')}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            handlePrevious={() => navigate(PICKING_PACKING_ROUTE)}
        >
            <BackgroundWithScanAndFooter
                footer={renderPauseButton}
                scanProps={{
                    placeholder: t('new_orders.picking.locationPlaceholder'),
                    onSubmit: selectLocationByScan,
                }}
            >
                <Text variant="P5" className={classes.locationListTagline}>
                    {t('new_orders.picking.locationListTagline')}
                </Text>
                <BlueBackgroundHeader
                    title={t('new_orders.picking.productToPick')}
                    className={classes.pickedProductsHeader}
                    rightAddon={
                        <Counter
                            current={alreadyPickedProducts?.length || 0}
                            total={totalProducts}
                            variant="stroke"
                            color="success"
                        />
                    }
                />
                <div className={classes.list}>
                    {sortedLocations.map((storageLocation: IPickingLocation) => (
                        <LocationCard
                            key={storageLocation.location}
                            location={storageLocation}
                            onClick={() => setCurrentLocation(storageLocation)}
                        />
                    ))}
                </div>
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default LocationList
