import * as React from 'react'
import { useTranslation } from 'react-i18next'
import FitText from '@kennethormandy/react-fittext'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IStatisticsFigure } from 'interfaces/Statistics.interface'
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
            },
            maxHeight: 150,
        },
        cardMain: {
            maxWidth: 400,
            minHeight: 180,
            boxShadow: 'none',
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.gradient,
            '& .MuiCardContent-root': {
                textAlign: 'center',
                '& .figure': {
                    fontSize: theme.typography.pxToRem(42),
                },
            },
        },
        cardSecondary: {
            maxWidth: 350,
            minHeight: 120,
            margin: theme.spacing(3),
            boxShadow: 'none',
            border: `1px solid ${theme.palette.grey[200]}`,
            color: theme.palette.grey[700],
            '& .MuiCardContent-root': {
                textAlign: 'left',
                '& .figure': {
                    fontSize: theme.typography.pxToRem(40),
                },
                '& .figureDescription': {
                    fontWeight: 100,
                    fontSize: theme.typography.pxToRem(14),
                    textTransform: 'uppercase',
                },
            },
        },
        cardContent: {
            padding: theme.spacing(4),
            textAlign: 'center',
        },
        cardTitle: {
            fontWeight: 200,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.1,
            margin: theme.spacing(2),
        },
        cardFigure: {
            transform: 'scale(.9, 1)',
        },
        withColor: {
            color: theme.palette.secondary[700],
        },
        tooltipIconContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
        },
        tooltipIcon: {
            color: theme.palette.grey[500],
            fontSize: theme.typography.pxToRem(18),
            marginTop: theme.spacing(-2),
            marginRight: theme.spacing(-2),
        },
        mainTooltipIcon: {
            color: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(18),
            marginTop: theme.spacing(-2),
            marginRight: theme.spacing(-2),
        },
    }),
)

const Figure: React.FC<IStatisticsFigure> = ({ testId, figure, main, withColor }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const hint = figure?.hint ? t(figure.hint) : null

    return (
        <>
            {figure?.value && figure?.figureDescription && (
                <Card
                    className={`${classes.card} ${main ? classes.cardMain : classes.cardSecondary}`}
                    data-testid={`figures_${testId}`}
                >
                    <CardContent className={classes.cardContent}>
                        {hint && (
                            <div className={classes.tooltipIconContainer}>
                                <Tooltip title={hint} placement="top-end">
                                    <InfoOutlined className={main ? classes.mainTooltipIcon : classes.tooltipIcon} />
                                </Tooltip>
                            </div>
                        )}
                        {main ? (
                            <>
                                <Typography variant="h5" component="h5" className={classes.cardTitle}>
                                    {t(figure.figureDescription)}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    component="h5"
                                    noWrap
                                    className={`figure ${classes.cardFigure}`}
                                >
                                    <FitText compressor={0.5} minFontSize={24} maxFontSize={42}>
                                        <span>{figure.value}</span>
                                    </FitText>
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography
                                    variant="h2"
                                    component="h5"
                                    noWrap
                                    className={`figure ${classes.cardFigure} ${withColor ? classes.withColor : ''}`}
                                >
                                    <FitText compressor={0.7} minFontSize={24} maxFontSize={42}>
                                        <span>{figure.value}</span>
                                    </FitText>
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    className={`figureDescription ${classes.cardTitle}`}
                                >
                                    {t(figure.figureDescription)}
                                    {figure.note && ` (${figure.note})`}
                                </Typography>
                            </>
                        )}
                    </CardContent>
                </Card>
            )}
        </>
    )
}

export default Figure
