import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Counter, { CounterProps } from '_atoms/badges/Counter'
import BlueBackgroundHeader from './backgrounds/BlueBackgroundHeader'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.space(4),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.space(2),
            },
        },
        header: {
            padding: theme.space(0, 2),
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
        },
    }),
)

type ListWithCounterProps = {
    title: string
    children: JSX.Element[]
    className?: string
    counterProps?: CounterProps
}

export const ListWithCounter: React.FC<ListWithCounterProps> = ({ title, children, className, counterProps }) => {
    const classes = useStyles()

    if (counterProps?.current === 0 || children.length === 0) return <></>

    return (
        <div className={`${classes.root} ${className}`}>
            <BlueBackgroundHeader
                title={title}
                className={classes.header}
                rightAddon={counterProps && <Counter {...counterProps} />}
            />
            <div className={classes.list}>{children}</div>
        </div>
    )
}

export default ListWithCounter
