import * as React from 'react'
import { HeaderProps } from '_organisms/header/Header'
import Counter from '_atoms/badges/Counter'

export type StepHandlerType = {
    getCounter: () => any
    nextStep: () => void
    previousStep: () => void
    goToStep: (step: number) => void
    onSubmit: (data?: any) => void
    onCancel: () => void
}

type StepperProps = {
    currentStep: number
    setCurrentStep: (step: number) => void
    updateHeader?: (props: HeaderProps) => void
    onSubmit: (data?: any) => void
    onCancel: () => void
    children: any[]
}

const Stepper: React.FC<StepperProps> = ({
    currentStep,
    setCurrentStep,
    updateHeader,
    onSubmit,
    onCancel,
    children,
}) => {
    const handleSteps = {
        getCounter: () => <Counter current={currentStep + 1} total={children.length} inHeader />,
        nextStep: () => setCurrentStep(currentStep + 1),
        previousStep: () => (currentStep > 0 ? setCurrentStep(currentStep - 1) : onCancel()),
        goToStep: (step: number) => setCurrentStep(step),
        onSubmit,
        onCancel,
    }

    if (children.length <= currentStep || currentStep < 0) return <></>

    return (
        <>
            {children.map((child, index) => {
                const hidden = index !== currentStep
                return (
                    <div key={index} style={{ display: hidden ? 'none' : 'flex', width: '100%' }}>
                        {React.cloneElement(child, {
                            handleSteps,
                            updateHeader,
                            hidden: currentStep !== index,
                        })}
                    </div>
                )
            })}
        </>
    )
}

export default Stepper
