import * as React from 'react'
import { useEffect, useState } from 'react'
import rmsApi from 'utils/api'
import { PARCELS_URL } from 'utils/routes/backend'
import { IParcel, IUniqueProduct, ParcelStatus } from 'interfaces'
import RentalReturnParcelPage from '_pages/returnParcel/rentalReturn/RentalReturnParcelPage'
import { useTranslation } from 'react-i18next'
import ReturnParcelHome from '_pages/returnParcel/ReturnParcelHome'
import { toast } from 'utils/toast'
import SecondHandParcelPage from '_pages/returnParcel/SecondHand/SecondHandParcelPage'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'
import { useConfetti } from '_organisms/UseConfetti'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import { ICustomer } from 'interfaces/Customer'
import { Addons } from 'interfaces/AddonInterface'
import { useLocation } from 'react-router-dom'
import { Packaging } from 'interfaces/Packaging.interface'
import SearchParcelByPuid from './parcelSearch/SearchParcelByPuid'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const ReturnParcel: React.FC = () => {
    const { t } = useTranslation()
    const user = useConnectedUserStore((state: ConnectedUserState) => state.user)

    const [parcel, setParcel] = useState<IParcel>()
    const [pickableUniqueProducts, setPickableUniqueProducts] = useState<IUniqueProduct[]>([])
    const [scannedPuidForParcelSearch, setScannedPuidForParcelSearch] = useState<string>()
    const { search } = useLocation()
    const { confetti, trigConfetti } = useConfetti()
    const [setDisabledSelectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.setDisabledSelectedCustomers,
    ])
    const [setDisabledSelectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.setDisabledSelectedWarehouse,
    ])

    const fetchParcel = async (scannedParcelBarcode: string) => {
        if (!scannedParcelBarcode) return

        try {
            const parcelRequest = await rmsApi.get(`${PARCELS_URL}/content`, {
                params: { parcelBarcode: scannedParcelBarcode },
            })
            if (parcelRequest.data?.parcel?.status === 'PROCESSED') {
                toast.success(t('parcel.scan.alreadyScanned'))
                return
            }

            setParcel(parcelRequest.data.parcel)
            setPickableUniqueProducts(parcelRequest.data.possibleUniqueProducts)
        } catch (e: any) {
            if (e.response?.status === 403) toast.error(t('parcel.scan.error403'))
            toast.error(t('parcel.scan.noParcelError', { tracking: scannedParcelBarcode }))
        }
    }

    const isSecondHand = parcel?.shipment?.secondHandRequest
    const loadPrismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.loadPrismicQualities)

    // attach corresponding returnItems to Products
    const processedUniqueProducts = parcel?.shipment.uniqueProducts.map((uniqueProduct) => {
        const returnItem = parcel?.returnItems?.find((returnItem) => returnItem.uniqueProductId === uniqueProduct.id)
        return returnItem ? { ...uniqueProduct, returnItems: [returnItem] } : uniqueProduct
    })

    let hasReturnAddon = false
    if (parcel?.shipment?.renter?.customer) {
        const currentCustomer = user.customers.find(
            (customer: ICustomer) => customer.id === parcel?.shipment?.renter?.customer?.id,
        )
        if (currentCustomer?.addons.find((addon: any) => addon.name === Addons.RETURN_2_0)) hasReturnAddon = true
    }

    useEffect(() => {
        if (parcel?.shipment?.secondHandRequest) {
            const prismicTemplate = parcel?.shipment.renter!.customer?.prismicTemplate
            if (prismicTemplate) loadPrismicQualities(prismicTemplate)
        }
        if (parcel) {
            setDisabledSelectedCustomers(true)
            setDisabledSelectedWarehouse(true)
        } else {
            setDisabledSelectedCustomers(false)
            setDisabledSelectedWarehouse(false)
        }
    }, [parcel])

    useEffect(() => {
        const parcelBarcode = new URLSearchParams(search).get('barcode')
        if (parcelBarcode) fetchParcel(parcelBarcode)
        // eslint-disable-next-line
    }, [search])

    function resetState() {
        setScannedPuidForParcelSearch(undefined)
        setParcel(undefined)
        setPickableUniqueProducts([])
    }

    async function parcelProcessed(returnPackaging?: Packaging) {
        if (parcel?.status !== ParcelStatus.PROCESSED) {
            const parcelCopy = Object.assign({}, parcel)
            parcelCopy.status = ParcelStatus.PROCESSED

            if (returnPackaging) {
                parcelCopy.returnPackaging = returnPackaging
            }

            setParcel(parcelCopy)
            await rmsApi.put(`${PARCELS_URL}/returned`, parcelCopy)
            trigConfetti()
            resetState()
            toast.success(t('returnParcel.congrats'))
        }
    }

    const addPickableUniqueProduct = (uniqueProduct: IUniqueProduct) => {
        setPickableUniqueProducts((prevState) => [...prevState, uniqueProduct])
    }

    return (
        <>
            {confetti()}
            {!parcel ? (
                <>
                    {scannedPuidForParcelSearch ? (
                        <SearchParcelByPuid
                            puid={scannedPuidForParcelSearch}
                            goToHomePage={resetState}
                            onPickParcel={fetchParcel}
                        />
                    ) : (
                        <ReturnParcelHome handleScan={fetchParcel} onParcelSearch={setScannedPuidForParcelSearch} />
                    )}
                </>
            ) : (
                <>
                    {isSecondHand ? (
                        <SecondHandParcelPage
                            refreshProductList={() => fetchParcel(parcel.barcode)}
                            parcel={parcel}
                            possibleUniqueProducts={pickableUniqueProducts}
                            processedUniqueProducts={processedUniqueProducts}
                            onSubmit={parcelProcessed}
                            onCancel={resetState}
                        />
                    ) : (
                        <RentalReturnParcelPage
                            parcel={parcel}
                            pickableUniqueProducts={pickableUniqueProducts}
                            processedUniqueProducts={processedUniqueProducts}
                            refreshProductList={() => fetchParcel(parcel.barcode)}
                            addPickableUniqueProduct={addPickableUniqueProduct}
                            hasReturnAddon={hasReturnAddon}
                            onSubmitParcelProcessed={parcelProcessed}
                            onCancel={resetState}
                            setParcel={setParcel}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default ReturnParcel
