import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import WhiteAndBluePartsCard from '_molecules/WhiteAndBluePartsCard'
import Text from '_atoms/text/Text'
import { IPickingLocation } from 'interfaces/Picking.interface'
import { useTranslation } from 'react-i18next'
import Pill from '_atoms/badges/Pill'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        content: {
            color: theme.palette.neutral[800],
            display: 'flex',
            alignItems: 'center',
        },
        eans: {
            display: 'inline-block',
            marginLeft: theme.space(1),
        },
    }),
)

type LocationCardProps = {
    location: IPickingLocation
    onClick: () => void
}

const LocationCard: React.FC<LocationCardProps> = ({ location, onClick }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    function getEans() {
        const eans = location.products
            .flatMap((product) => (product.eans ? product : product.productReference!).eans?.map((ean) => ean.ean))
            .filter((n) => n)
        return [...new Set(eans)].join(', ')
    }

    const productsCount = location?.products?.length?.toString()

    return (
        <WhiteAndBluePartsCard
            onClick={onClick}
            dataTestId="locationCard"
            whiteClass={classes.header}
            blueClass={classes.content}
        >
            <>
                <Text variant="S2">{`${t('new_orders.picking.location')} ${
                    location.location || t('new_orders.picking.unknownLocation')
                }`}</Text>
                <Pill label={productsCount} />
            </>
            <>
                <Text variant="P5" className={classes.content}>
                    {t('new_orders.picking.eans')}
                </Text>
                <Text variant="P4" className={classes.eans}>
                    {getEans()}
                </Text>
            </>
        </WhiteAndBluePartsCard>
    )
}

export default LocationCard
