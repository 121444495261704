import * as React from 'react'
import {
    createStyles,
    Input,
    InputAdornment,
    makeStyles,
    Theme,
    InputProps,
    FormControl,
    FormHelperText,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles<Theme, { iconColor?: string }>((theme: Theme) =>
    createStyles({
        input: {
            backgroundColor: theme.palette.neutral[50],
            border: `1px solid ${theme.palette.neutral[200]}`,
            borderRadius: 2,
            padding: theme.space(2, 3),
            transition: 'all 0.2s ease-out',
            minHeight: theme.space(8),
            '&.MuiInputBase-multiline': {
                padding: theme.space(2, 3),
            },
            '&:hover': {
                borderColor: theme.palette.neutral[400],
            },
            '&:focus-within': {
                borderColor: theme.palette.primary[500],
            },
            '& input, & > textarea': {
                padding: 0,
                lineHeight: theme.typography.pxToRem(20),
                fontSize: theme.typography.pxToRem(12),
                color: theme.palette.neutral[700],
                '&[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&[type="number"]': {
                    appearance: 'textfield',
                },
            },
            '&.Mui-disabled': {
                borderRadius: '6px',
                backgroundColor: theme.palette.primary[50],
                opacity: 0.64,
                color: theme.palette.primary[700],
                fontStyle: 'italic',
                fontWeight: 500,
                border: 'none',
            },
            '& svg': {
                padding: 0,
            },
        },
        icon: {
            color: ({ iconColor }) => iconColor ?? '#484E5A',
            padding: theme.spacing(1),
            fontSize: theme.typography.pxToRem(16),
        },
        cursor: {
            cursor: 'pointer',
        },
        textFieldDisabled: {
            '& > .MuiInputBase-root': {
                backgroundColor: theme.palette.neutral[50],
                borderRadius: '2px',
                padding: theme.spacing(1.25),
                border: `1px solid ${theme.palette.neutral[200]}`,
                '& > textarea, & > input': {
                    fontSize: theme.typography.pxToRem(13),
                    padding: theme.spacing(0.25),
                },
            },
        },
        helperText: {
            fontSize: theme.typography.pxToRem(11),
            color: theme.palette.neutral[600],
            fontWeight: 300,
            marginLeft: theme.space(2),
        },
        numberArrowContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
        },
        numberArrow: {
            fontSize: theme.typography.pxToRem(10),
            cursor: 'pointer',
            color: theme.palette.neutral[500],
        },
        numberInput: {
            '& .MuiInputBase-root': {
                height: theme.space(8),
                fontFamily: 'Oswald',
                letterSpacing: '0.6px',
            },
        },
    }),
)

/**
 * Following component is called 'pure' since it's only composed of jsx and there is no js code for functional purpose
 *
 */

export interface InputFieldProps extends InputProps {
    iconStart?: IconDefinition
    iconEnd?: IconDefinition
    onClickEndIcon?: (a?: any) => void
    onClearInput?: () => void
    iconColor?: string
    withErase?: boolean
    onKeyPress?: (e: React.KeyboardEvent) => void
    helperText?: string
    increment?: () => void
    decrement?: () => void
}

const InputField: React.FC<InputFieldProps> = React.forwardRef(
    (
        {
            value = '',
            className,
            iconStart,
            iconEnd,
            onClickEndIcon,
            onClearInput,
            iconColor,
            helperText,
            fullWidth,
            increment,
            decrement,
            type,
            ...props
        },
        ref,
    ) => {
        const classes = useStyles({ iconColor })

        let startAdornment = undefined
        let endAdornment = undefined
        if (iconStart)
            startAdornment = (
                <InputAdornment position={'start'}>
                    <FontAwesomeIcon
                        icon={iconStart}
                        className={`MuiSvgIcon-root ${classes.icon}`}
                        onMouseDown={(e) => e.preventDefault()}
                    />
                </InputAdornment>
            )
        if (iconEnd || onClearInput) {
            let iconToDisplay = iconEnd
            if (!iconEnd && onClearInput && value) iconToDisplay = faXmark
            if (iconToDisplay)
                endAdornment = (
                    <InputAdornment position={'end'}>
                        <FontAwesomeIcon
                            icon={iconToDisplay}
                            onClick={() => {
                                if (onClickEndIcon) onClickEndIcon()
                                else if (onClearInput) onClearInput()
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            className={`MuiSvgIcon-root ${classes.icon} ${
                                onClickEndIcon || onClearInput ? classes.cursor : ''
                            }`}
                        />
                    </InputAdornment>
                )
        }

        let numberClass = ''
        if (type === 'number' && increment && decrement) {
            endAdornment = (
                <div className={classes.numberArrowContainer}>
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        onClick={increment}
                        onMouseDown={(e) => e.preventDefault()}
                        className={classes.numberArrow}
                    />
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        onClick={decrement}
                        onMouseDown={(e) => e.preventDefault()}
                        className={classes.numberArrow}
                    />
                </div>
            )
            numberClass = classes.numberInput
        }

        return (
            <FormControl className={`${className} ${numberClass}`} fullWidth={fullWidth}>
                <Input
                    ref={ref}
                    type={type}
                    value={value}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    className={classes.input}
                    disableUnderline
                    {...props}
                />
                <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
            </FormControl>
        )
    },
)

export default InputField
