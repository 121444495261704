import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import RentalDateOverview from './rentalDateOverview'
import { IParcel } from 'interfaces'
import ParcelInfosOverview from '_pages/returnedParcels/ParcelInfosOverview'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionContainer: {
            justifyContent: 'center',
        },
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        soonAvailableLink: {
            color: 'grey',
            pointerEvents: 'none',
            cursor: 'default',
        },
        labelText: {
            color: theme.palette.grey[600],
        },
    }),
)

type ParcelSummarySectionProps = {
    renterEmail?: string
    shipmentDates: {
        expectedShippingDate: string | undefined
        shippedAt: string | undefined
        arrivedAt: string | undefined
        processedAt: string | undefined
    }
    parcelInfos?: {
        customerName: string
        carrier?: string
        relatedOrder: string
        barcode?: string
        trackingLink?: string
        trackingCode?: string
        pdfUrl?: string
        relatedParcels: IParcel[]
    }
    rightOfWithdrawal: boolean
}

const ParcelSummarySection: React.FC<ParcelSummarySectionProps> = ({
    renterEmail,
    shipmentDates,
    parcelInfos,
    rightOfWithdrawal,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const { expectedShippingDate, shippedAt, arrivedAt, processedAt } = shipmentDates

    return (
        <Grid container className={classes.sectionContainer}>
            <Grid item xs={12} sm={4}>
                <SectionTitle className={classes.sectionTitle} size="large">
                    {t('returns.renter')}
                </SectionTitle>
                <strong>{renterEmail}</strong>
            </Grid>

            <Grid item xs={12} sm={4}>
                <RentalDateOverview
                    expectedShippingDate={expectedShippingDate}
                    shippedAt={shippedAt}
                    arrivedAt={arrivedAt}
                    processedAt={processedAt}
                    rightOfWithdrawal={rightOfWithdrawal}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <ParcelInfosOverview parcelInfos={parcelInfos} />
            </Grid>
        </Grid>
    )
}

export default ParcelSummarySection
