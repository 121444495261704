import * as React from 'react'
import { useState } from 'react'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { faPen, faTrash } from '@fortawesome/pro-light-svg-icons'
import DotsActions, { TooltipAction } from '_molecules/DotsActions'
import Dash from '_atoms/Dash'
import Card from '_atoms/containers/Card'
import { CategoryActionType, IRefitCategory } from 'interfaces'
import Text from '_atoms/text/Text'
import { toast } from 'utils/toast'

type CategoryCardProps = {
    updateSelection: () => void
    category: IRefitCategory
    setSelectedCategory: (category: any) => void
    setOpenCategoryActionModal: (action: CategoryActionType) => void
}

const CategoryCard: React.FC<CategoryCardProps> = ({
    updateSelection,
    category,
    setSelectedCategory,
    setOpenCategoryActionModal,
}) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const { t } = useTranslation()

    const [actionsVisible, setActionsVisible] = useState<boolean>(false)

    function clickTooltipAction(event: Event, action: CategoryActionType) {
        event.stopPropagation() // Prevent triggering parent element click
        setSelectedCategory(category)
        setOpenCategoryActionModal(action)
    }

    const isEmpty =
        (category.refitInstructions && category.refitInstructions.length <= 0) ||
        (category.subCategories && category.subCategories.length <= 0)

    const renderDotAction = () => (
        <DotsActions visible={actionsVisible}>
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionCategories.actionButton.edit')}
                icon={faPen}
                onClick={(e) => clickTooltipAction(e, CategoryActionType.EDIT)}
            />
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionCategories.actionButton.delete')}
                color={theme.palette.error[600]}
                icon={faTrash}
                onClick={(e) => {
                    if (isEmpty) {
                        clickTooltipAction(e, CategoryActionType.DELETE)
                    } else {
                        toast.error(t('refit.instructionCategories.modal.notEmptyError'))
                    }
                }}
            />
        </DotsActions>
    )

    return (
        <Card
            rightAddon={renderDotAction()}
            onMouseEnter={() => setActionsVisible(true)}
            onMouseLeave={() => setActionsVisible(false)}
            onClick={updateSelection}
        >
            <>
                <Text variant="S1">{category.title}</Text>
                {isDesktop && <Dash color={theme.palette.secondary[600]} />}
                <Text variant="P4">
                    {category.subCategories &&
                        t('refit.instructionCategories.subCategories', {
                            count: category.subCategories.length,
                        })}
                    {category.refitInstructions &&
                        t('refit.instructionCategories.instructions', {
                            count: category.refitInstructions.length,
                        })}
                </Text>
            </>
        </Card>
    )
}

export default CategoryCard
