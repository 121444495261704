import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ORDER_PACKING_ROUTE, PICKING_PACKING_ROUTE } from 'utils/routes/frontend'
import { BATCH_PACKING_INFOS_URL, PACK_UNIQUE_PRODUCT_URL, UPDATE_BATCH_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import Text from '_atoms/text/Text'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import Button from '_atoms/buttons/Button'
import { BatchStatus } from 'interfaces/Batch.interface'
import { PreparationStatus } from 'interfaces'
import EnrichedIcon from '_molecules/EnrichedIcon'
import { faLoader, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import {
    DataPackUniqueProduct,
    IBatchPackingList,
    IPackingLocationSearchInfos,
    PackingType,
} from 'interfaces/Packing.interface'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import LocationSearch from './LocationSearch'
import ListWithCounter from '_molecules/ListWithCounter'
import PickPackProductCard from '_organisms/PickPackProductCard'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        issueList: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
        },
        text: {
            color: theme.palette.neutral[600],
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
            marginBottom: theme.space(4),
            padding: theme.space(0, 5),
        },
    }),
)

const BatchPacking: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { batchId } = useParams()
    const buildErrorMessage = useErrorMessage()

    const [batchPackingData, setBatchPackingData] = useState<IBatchPackingList>()
    const [locationSearchInfos, setLocationSearchInfos] = useState<IPackingLocationSearchInfos>()

    async function fetchBatchPackingData() {
        try {
            const packingRequest = await rmsApi.get(BATCH_PACKING_INFOS_URL(batchId!))
            setBatchPackingData(packingRequest.data)
        } catch (e) {
            toast.error(buildErrorMessage(e, { batchId }, 'new_orders.errors.fetchPackingInfosError'))
        }
    }

    useEffect(() => {
        fetchBatchPackingData()
    }, [])

    if (!batchPackingData) {
        return (
            <Page title={t('new_orders.packing.loadingPage.title')} key="batchPackingLoader">
                <EnrichedIcon
                    icon={faLoader}
                    title={t('new_orders.packing.loadingPage.title')}
                    tagline={t('new_orders.packing.loadingPage.orderTagline')}
                    iconProps={{ spin: true }}
                />
            </Page>
        )
    }

    if (locationSearchInfos) {
        const { product, isAddedProduct } = locationSearchInfos

        const order = product.savActions![0].order
        const { id, omsOrderId, customer, shipments } = order
        const shipmentId = shipments.find((shipment) => !shipment.isReturn)!.id

        return (
            <LocationSearch
                product={product}
                isAddedProduct={isAddedProduct}
                omsOrderId={omsOrderId}
                customerName={customer.name}
                shipmentId={shipmentId}
                orderId={id}
                resetPage={() => navigate(ORDER_PACKING_ROUTE(id))}
            />
        )
    }

    const { orders, ...packingData } = batchPackingData!
    const finishedPreparationStatuses = [PreparationStatus.FINISHED, PreparationStatus.CANCELED]
    const isBatchTerminated = orders.every((order) => finishedPreparationStatuses.includes(order.preparation.status))

    async function handleScan(barcodeUid: string) {
        const scannedUniqueProduct = batchPackingData!.productsToPack.find(
            (uniqueProduct) => uniqueProduct.barcodeUid === barcodeUid,
        )

        if (!scannedUniqueProduct) {
            const order = orders.find((order) =>
                order.shipments[0].uniqueProducts.some((uniqueProduct) => uniqueProduct.barcodeUid === barcodeUid),
            )

            if (order && order.preparation.status === PreparationStatus.IN_PROGRESS) {
                navigate(ORDER_PACKING_ROUTE(order.id))
                return
            }

            toast.error(t('errors.UNIQUE_PRODUCT_NOT_NEEDED_IN_BATCH', { barcodeUid }))
            return
        }

        if (scannedUniqueProduct.productReference!.orders.length === 0) {
            toast.error(t('errors.UNIQUE_PRODUCT_NOT_NEEDED_IN_BATCH', { barcodeUid }))
            return
        }

        const { id, shipments } = scannedUniqueProduct.productReference!.orders[0].order
        const shipmentId = shipments[0].id

        const payload: DataPackUniqueProduct = {
            barcodeUid,
            shipmentId,
            packingType: PackingType.PICK_PACK,
        }

        try {
            await rmsApi.post(PACK_UNIQUE_PRODUCT_URL(id), payload)
            navigate(ORDER_PACKING_ROUTE(id))
        } catch (e) {
            toast.error(buildErrorMessage(e, { barcodeUid }, 'new_orders.errors.packUniqueProductError'))
        }
    }

    async function terminateBatch() {
        try {
            await rmsApi.patch(UPDATE_BATCH_URL(batchId!), {
                status: BatchStatus.FINISHED,
                puidsToRestock: batchPackingData?.productsToRestock.map((product) => product.barcodeUid),
            })
            navigate(PICKING_PACKING_ROUTE)
        } catch (e) {
            toast.error(buildErrorMessage(e, { batchId }, 'new_orders.errors.terminateBatchError'))
        }
    }

    const footer = () => (
        <Button label={t('new_orders.packing.finishBatchLabel')} size="large" onClick={terminateBatch} />
    )

    return (
        <Page
            title={`${t('new_orders.packing.packing')} ${t('new_orders.pickPack.batch')} ${batchId}`}
            handlePrevious={() => navigate(PICKING_PACKING_ROUTE)}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            key="batchPacking"
        >
            <BackgroundWithScanAndFooter
                scanProps={{ onSubmit: handleScan, placeholder: t('new_orders.packing.scanPlaceholder') }}
                footer={isBatchTerminated ? footer() : undefined}
            >
                {isMobile && (
                    <Text variant="P5" className={classes.text}>
                        {t('new_orders.packing.packingListTagline')}
                    </Text>
                )}
                {Object.entries(packingData).map(([key, products], index) => (
                    <ListWithCounter key={index} title={t(`new_orders.packing.${key}`)}>
                        {products.map((product, index) => (
                            <PickPackProductCard
                                key={index}
                                product={product}
                                dataTestId={`${key}Card`}
                                buttonProps={
                                    key === 'productsAddedBySav'
                                        ? {
                                              label: t('new_orders.packing.locationSearchButton'),
                                              startIcon: faMagnifyingGlass,
                                              color: 'info',
                                              onClick: () => setLocationSearchInfos({ product, isAddedProduct: true }),
                                          }
                                        : undefined
                                }
                            />
                        ))}
                    </ListWithCounter>
                ))}
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default BatchPacking
