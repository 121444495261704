import * as React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        basicText: {
            color: '#36415B',
            fontSize: theme.typography.pxToRem(12),
        },
        oswaldText: {
            fontFamily: "'Oswald', sans-serif",
            fontWeight: 100,
            color: '#283044',
            padding: theme.spacing(0, 0.5),
            [theme.breakpoints.down('sm')]: {
                fontWeight: 300,
                fontSize: theme.typography.pxToRem(12),
            },
        },
    }),
)

type BasicTextProps = {
    label?: string
    className?: string
    children?: React.ReactNode
    [typographyProps: string]: any
}

export const BasicText: React.FC<BasicTextProps> = ({ label, className, children, ...typographyProps }) => {
    const classes = useStyles()
    return (
        <Typography className={`${classes.basicText} ${className || ''}`} {...typographyProps}>
            {label}
            {children}
        </Typography>
    )
}

type OswaldTextProps = {
    label?: string
    className?: string
    children?: React.ReactNode
    [typographyProps: string]: any
}

export const OswaldText: React.FC<OswaldTextProps> = ({ label, className, children, ...typographyProps }) => {
    const classes = useStyles()
    return (
        <Typography
            variant="body1"
            component="span"
            className={`${classes.oswaldText} ${className || ''}`}
            {...typographyProps}
        >
            {label}
            {children}
        </Typography>
    )
}
