import * as React from 'react'
import {
    createStyles,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            marginBottom: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                maxHeight: '60vh',
            },
            '& .MuiTableHead-root .MuiTableCell-head': {
                padding: theme.spacing(1.5),
                fontWeight: 700,
                lineHeight: 1,
                [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(1),
                    fontSize: theme.typography.pxToRem(12),
                },
            },
            '& .MuiTableBody-root > tr': {
                '&>.MuiTableCell-root': {
                    padding: theme.spacing(1.5),
                    color: theme.palette.grey[700],
                    borderColor: theme.palette.grey[300],
                    [theme.breakpoints.down('md')]: {
                        padding: theme.spacing(0.5, 1),
                        fontSize: theme.typography.pxToRem(12),
                        '& .MuiButtonBase-root': {
                            whiteSpace: 'nowrap',
                            fontSize: theme.typography.pxToRem(10),
                            paddingRight: theme.spacing(1),
                        },
                    },
                },
                '&:last-child .MuiTableCell-root': {
                    borderBottom: 0,
                },
                '&:hover, &:focus': {
                    '&>.MuiTableCell-root': {
                        color: theme.palette.common.black,
                        backgroundColor: theme.palette.grey[200],
                    },
                },
            },
        },
    }),
)

export interface IRMSTable {
    title?: string
    columns: string[]
    rows: any[]
    isLoading?: boolean
}

const RMSTable: React.FC<IRMSTable> = ({ title, columns, rows, isLoading }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            {title && (
                <Typography variant="h5" component="h2" gutterBottom>
                    {title}
                </Typography>
            )}
            <TableContainer className={classes.tableContainer} component={Paper} data-testid="table">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        {isLoading ? <Skeleton variant="text"></Skeleton> : column}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index_row) => (
                            <TableRow key={index_row}>
                                {Object.keys(row).map((key, index_key) => (
                                    <TableCell
                                        key={index_key}
                                        component="th"
                                        scope="row"
                                        data-testid={`RMSTABLE_${title}_${index_row}_${index_key}`}
                                    >
                                        {isLoading ? (
                                            <Skeleton variant="text"></Skeleton>
                                        ) : row[key] != null ? (
                                            row[key]
                                        ) : (
                                            t('RMSTable.noData')
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default RMSTable
