import * as React from 'react'
import { useState, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarRange, faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        paper: {
            border: `1px solid ${theme.palette.neutral[100]}`,
            boxShadow: '2px 2px 25px rgba(0, 0, 0, 0.03)',
            boderRadius: `${theme.space(2)}px`,
            transform: 'translate(-39px, 16px)!important',
        },
        icon: {
            padding: 0,
            '&:hover': {
                background: 'none',
            },
            '&:focus:enabled': {
                backgroundColor: 'transparent',
            },
            '& svg': {
                padding: 0,
                width: theme.space(3),
                height: theme.space(3),
                color: theme.palette.primary[600],
                fontSize: theme.typography.pxToRem(16),
            },
        },
        input: {
            margin: 0,
            '& .MuiInputBase-root': {
                backgroundColor: theme.palette.neutral[50],
                border: `1px solid ${theme.palette.neutral[200]}`,
                borderRadius: 2,
                padding: theme.space(2, 3),
                transition: 'all 0.2s ease-out',
                fontSize: theme.typography.pxToRem(12),
                height: theme.space(8),
                fontWeight: 300,
                color: theme.palette.neutral[700],
                '&:hover': {
                    borderColor: theme.palette.neutral[400],
                },
                '&:focus-within': {
                    borderColor: theme.palette.primary[500],
                },
                '& > input': {
                    padding: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    display: 'none',
                },
                '&.Mui-error': {
                    borderColor: theme.palette.error[600],
                },
            },
            '& .MuiFormHelperText-contained': {
                marginLeft: 0,
                marginRight: 0,
                fontSize: theme.typography.pxToRem(10),
            },
            '& .MuiFormHelperText-root.Mui-error': {
                color: theme.palette.error[600],
            },
        },
    }),
)

const DatePicker: React.FC<KeyboardDatePickerProps> = ({
    className,
    placeholder,
    InputAdornmentProps,
    InputProps,
    KeyboardButtonProps,
    PopoverProps,
    ...props
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [pickerIsOpen, setPickerIsOpen] = useState<boolean>(false)

    const { value, inputValue } = props

    useEffect(() => {
        if (value || inputValue) {
            setPickerIsOpen(false)
        }
    }, [value, inputValue])

    return (
        <KeyboardDatePicker
            {...props}
            placeholder={placeholder || t('global.datePicker.datePlaceholder')}
            variant="inline"
            margin="normal"
            format={t('global.datePicker.dateFormat')}
            inputVariant="outlined"
            disableToolbar
            className={`${className} ${classes.input}`}
            rightArrowIcon={<FontAwesomeIcon icon={faChevronRight} />}
            leftArrowIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            keyboardIcon={<FontAwesomeIcon icon={faCalendarRange} />}
            onClose={() => setPickerIsOpen(false)}
            open={pickerIsOpen}
            invalidDateMessage={t('global.datePicker.invalidDate')}
            InputAdornmentProps={{
                ...InputAdornmentProps,
                position: 'start',
            }}
            InputProps={{
                ...InputProps,
                onClick: () => setPickerIsOpen(true),
            }}
            KeyboardButtonProps={{
                ...KeyboardButtonProps,
                className: classes.icon,
                onClick: () => setPickerIsOpen(true),
            }}
            PopoverProps={{
                ...PopoverProps,
                classes: { paper: classes.paper },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
            }}
        />
    )
}

export default DatePicker
