import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { faCircleMinus } from '@fortawesome/pro-light-svg-icons'
import { TooltipAction } from '_molecules/DotsActions'
import Card from '_atoms/containers/Card'
import Text from '_atoms/text/Text'
import { newProductsType } from './InsertProductHome'
import SetQualityModal from '_organisms/uniqueProduct/SetQualityModal'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '740px',
            height: '49px',
            margin: `${theme.space(2)}px auto`,
            padding: theme.space(0, 3),
            cursor: 'default',
            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.palette.common.white,
            },
            '& > div': {
                display: 'block',
            },
        },
        title: {
            color: theme.palette.neutral[600],
        },
        setStateButton: {
            marginLeft: 'auto',
            marginRight: theme.space(2),
            borderRadius: '4px',
            padding: theme.space(1, 2),
        },
    }),
)

type UniqueProductCardListProps = {
    puidList: newProductsType
    handleSetQuality: ((id: number, state: string) => void) | false
    handleDelete: (id: number) => void
}

const UniqueProductCardList: React.FC<UniqueProductCardListProps> = ({ puidList, handleSetQuality, handleDelete }) => {
    const theme = useTheme()
    const classes = useStyles()
    const { t } = useTranslation()
    const prismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.qualities)
    const [puidToQualify, setPuidToQualify] = useState<{ barcode: string; quality?: string } | undefined>(undefined)

    function renderAddon(id: number, puid: { barcode: string; quality?: string }) {
        return (
            <>
                {handleSetQuality && (
                    <Button
                        color="neutral"
                        size="small"
                        className={classes.setStateButton}
                        onClick={() => setPuidToQualify(puid)}
                        data-testid="quality_button"
                        variant="stroke"
                    >
                        {puid.quality
                            ? prismicQualities.find((e) => e.value === puid.quality)?.label
                            : t('wms.scanUniqueProduct.list.setStateButtonTitle')}
                    </Button>
                )}
                <TooltipAction
                    tooltipPlacement="top"
                    tooltipText="Supprimer de la liste"
                    color={theme.palette.error[600]}
                    icon={faCircleMinus}
                    onClick={() => handleDelete(id)}
                />
            </>
        )
    }

    return (
        <>
            {puidList.map((puid, index) => (
                <Card
                    key={index}
                    rightAddon={renderAddon(index, puid)}
                    className={classes.card}
                    data-testid={`insert_puid_${puid.barcode}`}
                >
                    <Text variant="S3" className={classes.title}>
                        {t('wms.scanUniqueProduct.list.puidTitle')}
                    </Text>
                    <Text variant="P3">{puid.barcode}</Text>
                </Card>
            ))}
            {handleSetQuality && (
                <SetQualityModal
                    isOpen={!!puidToQualify}
                    handleClose={() => setPuidToQualify(undefined)}
                    handleSetQuality={(quality) => handleSetQuality(puidList.indexOf(puidToQualify!), quality)}
                    puidQuality={puidToQualify?.quality}
                    prismicQualities={prismicQualities}
                />
            )}
        </>
    )
}

export default UniqueProductCardList
