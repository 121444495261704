import * as React from 'react'

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelRow: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1),
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0.5),
            },
        },
        text: {
            display: 'block',
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.1,
            [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
        numberOfLabels: {
            fontWeight: 700,
            textAlign: 'right',
        },
        tips: {
            color: theme.palette.grey[600],
            fontSize: theme.typography.pxToRem(14),
            display: 'block',
            [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(10),
            },
        },
    }),
)

type LabelRowProps = {
    text: string
    tips: string
    children: any
}

const LabelRow: React.FC<LabelRowProps> = ({ text, tips, children }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.labelRow}>
            <Grid item>
                <span className={classes.text}>
                    {text}
                    <span className={classes.tips}>{tips}</span>
                </span>
            </Grid>
            <Grid item className={classes.numberOfLabels}>
                {children}
            </Grid>
        </Grid>
    )
}

export default LabelRow
