import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import DropDownPicker from '_molecules/DropDownPicker'
import TableFilterSystem from './TableFilterSystem'
import Button from '_atoms/buttons/Button'
import Pill from '_atoms/badges/Pill'
import { faTable, faXmarkCircle } from '@fortawesome/pro-light-svg-icons'
import { ButtonAction, filterOption, SearchFieldDefaultFilterKey } from '../../interfaces/Table.interfaces'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'
import Text from '_atoms/text/Text'
import ColumnsSettingsModal from './ColumnsSettingsModal'
import { HeaderType } from './TableV2'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: `${theme.space(3)}px`,
        },
        filterPillsContainer: {
            display: 'flex',
            flex: 1,
            marginTop: theme.space(2),
            gap: `${theme.space(2)}px`,
        },
        flexCtaContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.space(4)}px`,
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse',
                justifyContent: 'right',
                alignItems: 'end',
                gap: 0,
            },
        },
        eraseFilters: {
            display: 'flex',
            justifyContent: 'end',
        },
        cursor: {
            cursor: 'pointer',
        },
        verticalLine: {
            width: 0,
            height: theme.space(6),
            borderLeft: `1px solid ${theme.palette.neutral[200]}`,
        },
        settingsButton: {
            [theme.breakpoints.down('md')]: {
                '& svg': {
                    paddingRight: 0,
                },
            },
        },
        dropdownIcon: {
            fontSize: `${theme.typography.pxToRem(12)}!important`,
        },
    }),
)

type SettingsHeaderProps = {
    className?: string
    nbSelectedItems?: number
    defaultAction?: ButtonAction
    availableActions?: ButtonAction[]
    filterOptions?: filterOption[]
    activeFilters: any
    updateFilters?: (filters: any) => void
    resetFilters: () => void
    setSearch?: (search: string) => void
    applyFilter: (obj: any) => void
    header: HeaderType[]
    setHeader: (header: HeaderType[]) => void
    defaultFilters: Record<string, any>
}

const SettingsHeader: React.FC<SettingsHeaderProps> = ({
    nbSelectedItems = 0,
    defaultAction,
    availableActions,
    filterOptions = [],
    activeFilters,
    updateFilters,
    resetFilters,
    setSearch,
    applyFilter,
    header,
    setHeader,
    defaultFilters,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false)

    function executeAction(actionLabel: string) {
        availableActions?.find((action) => action.label === actionLabel)?.action()
    }

    const renderDefaultAction = () => {
        if (!defaultAction) return null
        return (
            <Button data-testid="defaultActionButton" size="small" onClick={defaultAction.action}>
                {defaultAction.label}
            </Button>
        )
    }

    function eraseFilter(key: string) {
        const newFilters = { ...activeFilters }
        delete newFilters[key]
        updateFilters!(newFilters)
        if (key === SearchFieldDefaultFilterKey && setSearch) {
            setSearch('')
        }
    }

    let filteredActiveFields = { ...activeFilters }
    delete filteredActiveFields.pagination
    if (filteredActiveFields.searchField === '') delete filteredActiveFields.searchField
    if (!Object.keys(filteredActiveFields).length) filteredActiveFields = null

    const displayEraseFilters =
        filteredActiveFields && JSON.stringify(filteredActiveFields) !== JSON.stringify(defaultFilters)

    const renderActiveFilters = () => {
        const pills = []
        for (const [key, value] of Object.entries(filteredActiveFields)) {
            if (key === 'dateRange') {
                const rangeDate = value as { startDate: Dayjs; endDate: Dayjs }
                pills.push(
                    <Pill
                        key={key}
                        color="primary"
                        variant="basic"
                        label={`${rangeDate.startDate.format('DD/MM/YYYY')} - ${rangeDate.endDate.format(
                            'DD/MM/YYYY',
                        )}`}
                        dataTestId={`${key}RemoveFilterButton`}
                    />,
                )
            } else {
                if (!value || (value instanceof Array && value.length === 0)) continue
                let displayValue = value
                if (dayjs.isDayjs(value)) displayValue = value.format('DD/MM/YYYY')

                pills.push(
                    <div key={key} className={classes.cursor} onClick={() => eraseFilter(key)}>
                        <Pill
                            startIcon={faXmarkCircle}
                            color="primary"
                            variant="basic"
                            label={`${
                                filterOptions.find((option) => option.filterKey === key)?.label
                            }: ${displayValue}`}
                            dataTestId={`${key}RemoveFilterButton`}
                        />
                    </div>,
                )
            }
        }
        return pills
    }

    const renderActionButton = () => {
        if (defaultAction && nbSelectedItems === 0) {
            return renderDefaultAction()
        } else if (nbSelectedItems > 0) {
            if (availableActions?.length === 1) {
                return (
                    <Button data-testid="actionButton" size="small" onClick={() => availableActions[0].action()}>
                        {availableActions[0].label}
                    </Button>
                )
            } else {
                return (
                    <div className={classes.flexCtaContainer}>
                        <Text variant="S3">
                            {nbSelectedItems} {t('table.selected')}
                        </Text>
                        <DropDownPicker
                            value={t('table.availableActions')}
                            withFilter={false}
                            submitSelection={executeAction}
                            options={availableActions?.map((action) => action.label) || []}
                            data-testid="availableActionsDropDown"
                        />
                    </div>
                )
            }
        }
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.flexContainer}>
                    <Button
                        label={isMobile ? '' : t('table.tableSettings.title')}
                        variant="stroke"
                        color="neutral"
                        size="small"
                        startIcon={faTable}
                        onClick={() => setIsSettingsModalOpen(true)}
                        data-testid="tableSettings"
                        className={classes.settingsButton}
                    />
                    {filterOptions && (
                        <>
                            <div className={classes.verticalLine} />
                            <TableFilterSystem filters={filterOptions} applyFilter={applyFilter} />
                        </>
                    )}
                </div>
                {renderActionButton()}
            </div>
            {filteredActiveFields && <div className={classes.filterPillsContainer}>{renderActiveFilters()}</div>}
            {displayEraseFilters && (
                <div className={classes.eraseFilters}>
                    <Button
                        size="small"
                        variant="ghost"
                        color="error"
                        startIcon={faXmarkCircle}
                        onClick={resetFilters}
                        data-testid="eraseFilters"
                    >
                        {t('table.reset')}
                    </Button>
                </div>
            )}
            <ColumnsSettingsModal
                open={isSettingsModalOpen}
                header={header}
                setHeader={setHeader}
                handleClose={() => setIsSettingsModalOpen(false)}
            />
        </>
    )
}

export default SettingsHeader
