import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { faPause } from '@fortawesome/pro-light-svg-icons'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            gap: `${theme.space(2)}px`,
            '& > *': {
                height: theme.space(6),
                width: theme.space(6),
                minWidth: 0,
                '& svg': {
                    width: theme.space(3),
                    height: theme.space(3),
                },
                [theme.breakpoints.down('sm')]: {
                    height: theme.space(8),
                    width: theme.space(12),
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                },
            },
        },
        pause: {
            border: `1px solid ${theme.palette.neutral[200]}`,
            [theme.breakpoints.down('sm')]: {
                border: 'none',
            },
        },
    }),
)

type PauseAndReportButtonsProps = {
    handlePause: () => void
    openReportIssueModal: () => void
}

const PauseAndReportButtons: React.FC<PauseAndReportButtonsProps> = ({ handlePause, openReportIssueModal }) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Button
                size="small"
                onClick={handlePause}
                color="neutral"
                startIcon={faPause}
                className={classes.pause}
                data-testid="pauseButton"
            ></Button>
            <Button
                size="small"
                onClick={openReportIssueModal}
                color="warning"
                data-testid="reportIssueButton"
                startIcon={faTriangleExclamation}
            />
        </div>
    )
}

export default PauseAndReportButtons
