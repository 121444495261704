import * as React from 'react'
import { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { ReactNode } from 'react'
import Text from '_atoms/text/Text'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Theme } from '@material-ui/core/styles'
import { HandleSteps } from './VerticalStepper'
import StepBullet, { StepBulletStatus } from '_atoms/badges/StepBullet'

const useStyles = makeStyles<Theme, { disabled: boolean }>((theme: Theme) =>
    createStyles({
        body: {
            opacity: ({ disabled }) => (disabled ? '0.5' : '1'),
            display: 'flex',
            marginBottom: theme.space(5),
            gap: `${theme.space(2)}px`,
        },
        content: {
            flex: 1,
        },
        titleBlock: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: theme.space(1),
        },
        title: {
            color: theme.palette.neutral[700],
        },
        info: {
            cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
            marginLeft: theme.space(1),
        },
        tagline: {
            color: theme.palette.neutral[700],
            marginBottom: theme.space(2),
        },
    }),
)

type StepProps = {
    step?: number
    title: string
    tagline: string
    status: StepBulletStatus
    disabled?: boolean
    children: ReactNode
    openInfoModal?: () => void
    handleSteps?: HandleSteps
    dataTestId?: string
}

const VerticalStep: React.FC<StepProps> = ({
    title,
    tagline,
    status,
    children,
    handleSteps,
    openInfoModal,
    dataTestId,
}) => {
    const { step, disabled, updateStatuses } = handleSteps!
    const classes = useStyles({ disabled })

    useEffect(() => {
        updateStatuses && updateStatuses(status)
    }, [status])

    return (
        <div className={classes.body} data-testid={dataTestId}>
            <StepBullet step={step! + 1} status={status} />
            <div className={classes.content}>
                <div className={classes.titleBlock}>
                    <Text variant={'S2U'} className={classes.title}>
                        {title}
                    </Text>
                    {openInfoModal && (
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            color={'#9BA3B6'}
                            className={classes.info}
                            onClick={openInfoModal}
                        />
                    )}
                </div>
                <Text variant={'P4'} className={classes.tagline}>
                    {tagline}
                </Text>
                {!disabled && children}
            </div>
        </div>
    )
}

export default VerticalStep
