import * as React from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopwatch } from '@fortawesome/pro-light-svg-icons'
import Text from '_atoms/text/Text'
import dayjs from 'dayjs'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timer: {
            height: theme.space(12),
            minWidth: theme.space(29),
            paddingLeft: theme.space(6),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            background: theme.palette.common.white,
            borderRadius: theme.space(2 / 5),
            [theme.breakpoints.down('sm')]: {
                background: theme.palette.neutral[50],
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: theme.space(4 / 5),
                minWidth: theme.space(22),
                paddingLeft: theme.space(3),
            },
        },
        time: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: theme.space(3),
            height: theme.space(3),
            width: theme.space(3),
        },
        text: {
            fontWeight: 500,
        },
    }),
)

type TimeProps = {
    time: number
    withIcon?: boolean
    withPlus?: boolean
    iconColor: string
    textColor: string
}

const Time: React.FC<TimeProps> = ({ time, withIcon = true, withPlus = false, iconColor, textColor }) => {
    const classes = useStyles()

    return (
        <div className={classes.time}>
            {withIcon && <FontAwesomeIcon icon={faStopwatch} color={iconColor} className={classes.icon} />}
            <Text variant={withIcon ? 'N8' : 'N9'} className={classes.text} color={textColor}>
                {`${withPlus ? '+' : ''}${dayjs.duration(time, 'seconds').format('HH:mm:ss')}`}
            </Text>
        </div>
    )
}

type TimerProps = {
    duration: number
    extraDuration: number
}

const Timer: React.FC<TimerProps> = ({ duration, extraDuration }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isExtraDuration = extraDuration > 0

    return (
        <div className={classes.timer}>
            <Time
                time={duration}
                iconColor={theme.palette.primary[600]}
                textColor={theme.palette.neutral[isExtraDuration ? 600 : 700]}
                withIcon={!isExtraDuration}
            />
            {isExtraDuration && (
                <Time
                    time={extraDuration}
                    iconColor={theme.palette.warning[500]}
                    textColor={theme.palette.warning[900]}
                    withPlus
                />
            )}
        </div>
    )
}

export default Timer
