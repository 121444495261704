import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import TopSearchSection from '_molecules/TopSearchSection'
import ProductsTable from '_pages/instructionGroupsManagement/ProductsTable'
import { IInstructionGroup, IProductWithEans } from 'interfaces'
import LinkProductsToGroupModal from '_pages/instructionGroupsManagement/modals/LinkProductsToGroupModal'
import rmsApi from 'utils/api'
import { REFIT_GROUP_LINK_PRODUCTS_ROUTE } from 'utils/routes/backend'
import { faLink } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import NoResultTip from '_molecules/NoResultTip'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.common.white,
        },
        container: {
            [theme.breakpoints.up('md')]: {
                padding: theme.space(6, 0),
            },
        },
    }),
)

type UnlinkedProductsTabProps = {
    groups: IInstructionGroup[]
    unlinkedProducts: IProductWithEans[]
    refreshData: () => void
}

const UnlinkedProductsTab: React.FC<UnlinkedProductsTabProps> = ({ groups, unlinkedProducts, refreshData }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [selectedProducts, setSelectedProducts] = useState<number[]>([])
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
    const [unlinkedProductFilter, setUnlinkedProductFilter] = useState<string>('')

    async function submitChanges(groupId: number) {
        setIsModalOpened(false)
        try {
            await rmsApi.patch(REFIT_GROUP_LINK_PRODUCTS_ROUTE(groupId, selectedProducts))
            refreshData()
            setSelectedProducts([])
            toast.success(t('refit.instructionGroups.successMessage.linkProduct'))
        } catch (e: any) {
            toast.error(t('refit.instructionGroups.errors.productLink'))
        }
    }

    const filteredUnlinkedProducts = unlinkedProducts.filter((unlinkedProduct) =>
        unlinkedProduct.name.toLowerCase().includes(unlinkedProductFilter.toLowerCase()),
    )

    return (
        <div className={classes.container}>
            <TopSearchSection
                placeholder={t('refit.instructionGroups.searchInputPlaceholder.product')}
                filter={unlinkedProductFilter}
                setFilter={setUnlinkedProductFilter}
                clearFilter={() => setUnlinkedProductFilter('')}
                input={unlinkedProducts.length > 0}
            >
                {selectedProducts.length > 0 && (
                    <Button
                        onClick={() => setIsModalOpened(true)}
                        startIcon={faLink}
                        shape={isMobile ? 'rounded' : undefined}
                        disabled={!groups.length}
                        data-testid={'linkToGroupButton'}
                    >
                        {!isMobile && t('refit.instructionGroups.manageGroups.productsTab.link')}
                    </Button>
                )}
            </TopSearchSection>
            {filteredUnlinkedProducts?.length ? (
                <ProductsTable
                    products={filteredUnlinkedProducts}
                    selectedIds={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            ) : (
                <NoResultTip
                    text={
                        unlinkedProductFilter
                            ? t('refit.instructionGroups.manageGroups.productsTab.noProductWithFilter')
                            : t('refit.instructionGroups.manageGroups.productsTab.noProduct')
                    }
                />
            )}
            <LinkProductsToGroupModal
                isOpen={isModalOpened}
                groups={groups}
                onSubmit={submitChanges}
                onClose={() => setIsModalOpened(false)}
            />
        </div>
    )
}

export default UnlinkedProductsTab
