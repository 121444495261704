import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Divider from '_atoms/Divider'
import SearchInput from './SearchInput'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topSection: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
                margin: theme.space(0, 0, 5),
            },
        },
        searchField: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                zIndex: 1001,
                width: `calc(100vw - ${theme.space(6)}px)`,
                transform: `translate(0, ${theme.space(-18)}px)`,
            },
        },
        divider: {
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.space(5),
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        addon: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: theme.space(18),
                right: theme.space(4),
            },
        },
    }),
)

type TopSearchSectionProps = {
    input?: boolean
    placeholder?: string
    filter?: string
    setFilter?: (filter: string) => void
    clearFilter?: () => void
    children?: React.ReactNode
    className?: string
    [props: string]: any
}

const TopSearchSection: React.FC<TopSearchSectionProps> = ({
    input = true,
    placeholder,
    filter,
    setFilter,
    clearFilter,
    className = '',
    children,
    props,
}) => {
    const classes = useStyles()

    if (!input && !children) return <></>

    return (
        <>
            <div className={classes.topSection}>
                {input && (
                    <SearchInput
                        placeholder={placeholder}
                        className={`${classes.searchField} ${className}`}
                        value={filter}
                        onChange={(e: any) => setFilter!(e.target.value)}
                        onClickEndIcon={clearFilter}
                        onClearInput={clearFilter}
                        data-testid="topSearchInput"
                        {...props}
                    />
                )}
                <div className={classes.addon}>{children}</div>
            </div>
            <Divider dashed={true} spaceSize={'none'} className={classes.divider} />
        </>
    )
}

export default TopSearchSection
