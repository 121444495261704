import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { BatchStatus, IBatchOrder, IBatchUniqueProduct } from 'interfaces/Batch.interface'
import LinearProgress from '_atoms/LinearProgress'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(minMax)

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        progress: {
            marginTop: theme.space(1),
            '& .MuiLinearProgress-bar': {
                background: theme.palette.secondary.gradient,
            },
        },
    }),
)

type PickPackProgressProps = {
    productsCount: number
    status: BatchStatus
    pickedUniqueProducts: IBatchUniqueProduct[]
    orders: IBatchOrder[]
}

const PickPackProgress: React.FC<PickPackProgressProps> = ({ productsCount, status, pickedUniqueProducts, orders }) => {
    const classes = useStyles()

    function percentagePickedProducts() {
        return Math.round((pickedUniqueProducts.length / productsCount) * 100)
    }

    function percentagePackedProducts() {
        const packedProducts = orders.reduce(
            (packedProductsCount, order) => packedProductsCount + order.shipments[0].uniqueProducts.length,
            0,
        )
        const result = Math.round((packedProducts / productsCount) * 100)

        return result > 100 ? 100 : result
    }

    let withLinearProgress = false
    let progressValue = 0

    if (status === BatchStatus.PICKING_IN_PROGRESS) {
        withLinearProgress = true
        progressValue = percentagePickedProducts()
    } else if (status === BatchStatus.PACKING_IN_PROGRESS) {
        withLinearProgress = true
        progressValue = percentagePackedProducts()
    }

    if (!withLinearProgress) {
        return <></>
    }

    return <LinearProgress className={classes.progress} variant="determinate" value={progressValue} />
}

export default PickPackProgress
