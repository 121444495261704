import * as React from 'react'
import { createStyles, makeStyles, Theme, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button, { PaletteType } from '_atoms/buttons/Button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: `${theme.space(2)}px`,
            flexGrow: 1,
            [theme.breakpoints.down('md')]: {
                justifyContent: 'space-between',
            },
        },
        divider: {
            marginTop: theme.space(7),
            marginBottom: theme.space(6),
            backgroundColor: theme.palette.neutral[200],
        },
        leftWidget: {
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.space(2),
            },
        },
    }),
)

type ModalFooterProps = {
    label: string
    color?: PaletteType
    submitDisabled?: boolean
    onSubmit: () => void
    onCancel: () => void
    className?: string
    leftWidget?: React.ReactElement | null
    withDivider?: boolean
    cancelLabel?: string
    endIcon?: IconProp
}

const ModalFooter: React.FC<ModalFooterProps> = ({
    label,
    color,
    onSubmit,
    onCancel,
    submitDisabled = false,
    leftWidget,
    withDivider = true,
    className,
    cancelLabel,
    endIcon,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={className}>
            {withDivider && <Divider className={classes.divider} />}
            <div className={classes.actionContainer}>
                {leftWidget &&
                    React.cloneElement(leftWidget, {
                        className: `${classes.leftWidget} ${leftWidget.props.className}`,
                    })}
                <div className={classes.buttonContainer}>
                    <Button
                        label={cancelLabel || t('global.cancel')}
                        color="neutral"
                        variant="stroke"
                        onClick={onCancel}
                        data-testid="modalCancelButton"
                    />
                    <Button
                        variant="fill"
                        disabled={submitDisabled}
                        onClick={onSubmit}
                        label={label}
                        color={color}
                        endIcon={endIcon}
                        data-testid="modalValidateButton"
                    />
                </div>
            </div>
        </div>
    )
}

export default ModalFooter
