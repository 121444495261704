import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ModalFooter from '_molecules/ModalFooter'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import { useTranslation } from 'react-i18next'
import Tips from '_atoms/containers/Tips'
import InputWithLabel from '_molecules/InputWithLabel'
import InputField from '_atoms/inputs/InputField'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tips: {
            marginTop: theme.space(4),
        },
    }),
)

type DeleteModalProps = {
    title: string
    label: string
    inputValue: string
    tipText?: string
    buttonLabel: string
    isOpen?: boolean
    onSubmit: () => void
    onClose: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    title,
    label,
    inputValue,
    tipText,
    buttonLabel,
    isOpen = true,
    onSubmit,
    onClose,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <ModalWithHeader title={title} headerType={HeaderType.DIVIDER} open={isOpen} handleClose={onClose} width={600}>
            <InputWithLabel label={label}>
                <InputField
                    fullWidth
                    placeholder={t('refit.instructionGroups.createOrEditGroup.namePlaceholder')}
                    value={inputValue}
                    disabled={true}
                    data-testid="delete-modal-input"
                />
            </InputWithLabel>
            {tipText && <Tips className={classes.tips}>{tipText}</Tips>}
            <ModalFooter label={buttonLabel} color="error" onSubmit={onSubmit} onCancel={onClose} />
        </ModalWithHeader>
    )
}

export default DeleteModal
