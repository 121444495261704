import * as React from 'react'
import { useState, useEffect } from 'react'
import rmsApi from 'utils/api'
import { BATCH_PICKING_INFOS_URL } from 'utils/routes/backend'
import { useParams } from 'react-router-dom'
import { toast } from 'utils/toast'
import LocationList from './LocationList'
import LocationPicking from './LocationPicking'
import { IPickingInfos, IPickingLocation } from 'interfaces/Picking.interface'
import FinishedPicking from './FinishedPicking'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'

const Picking: React.FC = () => {
    const { batchId } = useParams()
    const buildErrorMessage = useErrorMessage()

    const [pickingInfos, setPickingInfos] = useState<IPickingInfos>()
    const [selectedLocation, setSelectedLocation] = useState<IPickingLocation | null>(null)

    async function fetchPickingInfos() {
        try {
            const batchesRequest = await rmsApi.get(BATCH_PICKING_INFOS_URL(batchId!))
            setPickingInfos(batchesRequest.data)
        } catch (e) {
            toast.error(buildErrorMessage(e, { batchId }, 'new_orders.errors.fetchBatchPickingError'))
        }
    }

    useEffect(() => {
        if (!pickingInfos || !selectedLocation) fetchPickingInfos()
    }, [selectedLocation])

    const pickingIsFinished = pickingInfos?.storageLocations?.length === 0
    const nbIssues = pickingInfos?.pickingIssues?.length || 0

    if (selectedLocation) {
        return (
            <LocationPicking
                location={selectedLocation}
                setCurrentLocation={setSelectedLocation}
                batchId={pickingInfos!.batchId}
            />
        )
    }

    if (pickingIsFinished) {
        return <FinishedPicking nbIssues={nbIssues} />
    }

    return (
        <LocationList
            locations={pickingInfos?.storageLocations}
            alreadyPickedProducts={pickingInfos?.alreadyPickedProducts}
            setCurrentLocation={setSelectedLocation}
        />
    )
}

export default Picking
