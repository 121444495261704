import * as React from 'react'
import { useState, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { IBatch, BatchFilter, batchPickingStatuses, batchPackingStatuses } from 'interfaces/Batch.interface'
import { useTranslation } from 'react-i18next'
import BatchCard from './BatchCard'
import Button from '_atoms/buttons/Button'
import BatchListActions from './BatchListActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import WarningIssueCard from './WarningIssueCard'
import Tips from '_atoms/containers/Tips'

const CARD_NUMBER_BY_PAGE = 10

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
            marginTop: theme.space(2),
            paddingBottom: theme.space(6),
        },
        loadMore: {
            width: 'fit-content',
            alignSelf: 'center',
        },
        mobileFloatingButton: {
            position: 'fixed',
            right: theme.space(4),
            bottom: theme.space(4),
            height: theme.space(10),
            width: theme.space(10),
            borderRadius: '50%',
            '& svg': {
                fontSize: theme.typography.pxToRem(16),
                color: theme.palette.common.white,
            },
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    }),
)

type BatchListProps = {
    openCreateBatchModal: () => void
    batches: IBatch[]
}

const BatchList: React.FC<BatchListProps> = ({ openCreateBatchModal, batches }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [page, setPage] = useState<number>(1)
    const [filter, setFilter] = useState<string>(BatchFilter.ALL)

    useEffect(() => {
        setFilter(BatchFilter.ALL)
    }, [batches])

    function filterBatches() {
        if (filter === BatchFilter.PICKING) {
            return batches.filter((batch) => batchPickingStatuses.includes(batch.status))
        } else if (filter === BatchFilter.PACKING) {
            return batches.filter((batch) => batchPackingStatuses.includes(batch.status))
        } else {
            return batches
        }
    }

    const filteredBatches = filterBatches()
    const lastIndexToDisplay = page * CARD_NUMBER_BY_PAGE
    const displayLoadMoreButton = lastIndexToDisplay < filteredBatches.length
    const displayedBatches = filteredBatches.slice(0, lastIndexToDisplay)

    const noPickingBatch = filter === BatchFilter.PICKING && filteredBatches.length === 0
    const noPackingBatch = filter === BatchFilter.PACKING && filteredBatches.length === 0

    return (
        <>
            <WarningIssueCard />
            <BatchListActions filter={filter} setFilter={setFilter} openCreateBatchModal={openCreateBatchModal} />
            <div className={classes.list}>
                {noPickingBatch && <Tips text={t('new_orders.pickPack.noPickingBatch')} />}
                {noPackingBatch && <Tips text={t('new_orders.pickPack.noPackingBatch')} />}
                {displayedBatches.map((batch) => (
                    <BatchCard key={batch.id} {...batch} />
                ))}
                {displayLoadMoreButton && (
                    <Button
                        variant="stroke"
                        color="neutral"
                        label={t('new_orders.pickPack.loadMoreBatch')}
                        className={classes.loadMore}
                        onClick={() => setPage(page + 1)}
                        data-testid="loadMoreButton"
                    />
                )}
            </div>
            <Button className={classes.mobileFloatingButton} onClick={openCreateBatchModal}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </>
    )
}

export default BatchList
