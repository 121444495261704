import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import theme from '../../theme'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        locationContainer: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
        },
        locationNamebox: {
            padding: '0 3px',
            backgroundColor: '#283044',
            borderRadius: '2px',
            fontVariantNumeric: 'tabular-nums',
        },
        storageLocation: {
            padding: `${theme.spacing(0.4)}px ${theme.spacing(1.5)}px`,
            color: theme.palette.common.white,
            fontWeight: 500,
        },
        quantityBox: {
            backgroundColor: theme.palette.grey[500],
            borderRadius: '2px',
            color: theme.palette.common.white,
            padding: `${theme.spacing(0.5)}px ${theme.spacing(0.8)}px`,
            fontVariantNumeric: 'tabular-nums',
        },
        hidden: {
            visibility: 'hidden',
        },
        highlight: {
            backgroundColor: '#283044',
            border: `1px solid #283044`,
        },
    }),
)

type StorageLocationProps = {
    location: string
    quantity?: number | undefined
    highlight?: boolean
    highestLocationNameLength?: number // used for styling grouped StorageLocation
    highestLocationQuantityLength?: number // used for styling grouped StorageLocation
}
const StorageLocation: React.FC<StorageLocationProps> = ({
    location,
    quantity,
    highlight,
    highestLocationNameLength,
    highestLocationQuantityLength,
}) => {
    const classes = useStyles()

    let margin = 0

    if (!highestLocationNameLength) margin = 1
    else if (highestLocationNameLength === location.length) margin = 1
    else margin = highestLocationNameLength - location.length + 1

    const currentQuantityLength = quantity?.toString().length || 0
    let padding = 1
    if (quantity && highestLocationQuantityLength && highestLocationQuantityLength !== currentQuantityLength)
        padding = (highestLocationQuantityLength - currentQuantityLength!) / 2 + 1

    return (
        <div className={`${classes.locationContainer} ${location ? '' : classes.hidden}`}>
            <Box
                className={`${classes.locationNamebox} ${location ? '' : classes.hidden} ${
                    highlight ? classes.highlight : ''
                }`}
            >
                <Typography className={classes.storageLocation} variant="h5">
                    {location || 'N/A'}
                </Typography>
            </Box>
            <Box
                className={`${classes.quantityBox} ${location ? '' : classes.hidden} ${
                    highlight ? classes.highlight : ''
                }`}
                style={{ marginLeft: `${margin}ch`, padding: `${theme.spacing(0.4)}px ${padding}ch` }}
            >
                <Typography variant="h5">{quantity || 0}</Typography>
            </Box>
        </div>
    )
}

export default StorageLocation
