import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Option, VerticalRadioButtons } from '_atoms/inputs/VerticalRadioButtons'
import ModalFooter from '_molecules/ModalFooter'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxHeight: theme.space(82),
            overflow: 'scroll',
        },
    }),
)

type UpdateStatusModalProps = {
    isOpen: boolean
    initialStatuses: string[]
    handleClose: () => void
    updateStatus: (status: string) => void
    statusOptions: Option[]
}

const UpdateStatusModal: React.FC<UpdateStatusModalProps> = ({
    isOpen,
    initialStatuses,
    handleClose,
    updateStatus,
    statusOptions,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const initialStatus = [...new Set(initialStatuses)].length === 1 ? initialStatuses[0] : null
    const [status, setStatus] = useState<string | null>(initialStatus)

    return (
        <ModalWithHeader
            headerType={HeaderType.MOBILE}
            title={t('wms.scanUniqueProduct.setStatusModalTitle')}
            open={isOpen}
            handleClose={handleClose}
            width={600}
        >
            <div className={classes.container}>
                <VerticalRadioButtons
                    options={statusOptions}
                    value={status}
                    onChange={(e) => setStatus(e.value)}
                    disabled={false}
                />
            </div>
            <ModalFooter
                label={t('global.validate')}
                onSubmit={() => {
                    updateStatus(status!)
                    handleClose()
                }}
                onCancel={handleClose}
                submitDisabled={!status || status === initialStatus}
            />
        </ModalWithHeader>
    )
}

export default UpdateStatusModal
