import * as React from 'react'
import { createStyles, makeStyles, Theme, Tooltip, useTheme, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, IconDefinition } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles<Theme, { actionNumber: number }>((theme: Theme) =>
    createStyles({
        dotsContainer: {
            margin: '0 8px',
            display: 'flex',
            boxSizing: 'border-box',
            alignSelf: 'center',
            alignItems: 'center',
            width: '16px',
            height: '16px',
            fontSize: theme.typography.pxToRem(16),
            '&:hover $dots, &:focus $dots': {
                visiblity: 'hidden',
                opacity: 0,
                left: theme.spacing(-2),
                transition: 'all 0.15s ease-in-out',
                padding: '0',
            },
            '&:hover $actionIconsContainer, &:focus $actionIconsContainer': {
                visibility: 'visible',
                opacity: 1,
                transition: 'all 0.3s ease-in-out, opacity 0.15s ease-in-out 0.15s',
            },
        },
        dotsContainerVisible: {
            '& $dots, & $dots': {
                visiblity: 'hidden',
                opacity: 0,
                left: theme.spacing(-2),
                transition: 'all 0.15s ease-in-out',
                padding: '0',
            },
            '& $actionIconsContainer, & $actionIconsContainer': {
                visibility: 'visible',
                opacity: 1,
                transition: 'all 0.3s ease-in-out, opacity 0.05s ease-in-out 0.15s',
            },
        },
        actionIconsContainer: {
            display: 'flex',
            marginLeft: (props) =>
                props.actionNumber > 1 ? theme.spacing(-4 * props.actionNumber + 1) : theme.spacing(-3),
            padding: '0 10px',
            width: 'auto',
            visibility: 'hidden',
            opacity: 0,
            alignItems: 'center',
            background:
                'linear-gradient(90deg, rgba(247,247,249,0) 0%, rgba(247,247,249,1) 8%, rgba(247,247,249,1) 92%, rgba(247,247,249,0) 92%)',
            transition: 'all 0.2s ease-in-out 0.05s, opacity 0.15s ease-in-out',
        },
        actionIcon: {
            padding: theme.spacing(1, 1),
        },
        dots: {
            padding: theme.spacing(1.5),
            opacity: 1,
            position: 'relative',
            left: 0,
            transition: 'all 0.2s ease-in-out 0.05s, opacity 0.15s ease-in-out',
        },
    }),
)

type DotsActionsProps = {
    children?: any | any[]
    className?: string
    visible?: boolean
    [props: string]: any
}

const DotsActions: React.FC<DotsActionsProps> = ({ className, children, visible, props }) => {
    const classes = useStyles({ actionNumber: children?.length || 1 })

    return (
        <div className={`${classes.dotsContainer} ${visible && classes.dotsContainerVisible}`}>
            <FontAwesomeIcon className={classes.dots} icon={faEllipsis} fontSize={'16px'} color={'#6A7590'} />
            <div className={classes.actionIconsContainer}>
                {children?.length > 1
                    ? children.map((child: any, index: number) =>
                          React.cloneElement(child, {
                              key: `dotsActions_${index}`,
                              className: classes.actionIcon,
                          }),
                      )
                    : React.cloneElement(children, { className: classes.actionIcon })}
            </div>
        </div>
    )
}

export default DotsActions

const useTooltipActionStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButton: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            minWidth: 'unset',
        },
    }),
)

type TooltipActionProps = {
    icon: IconDefinition
    color?: string
    onClick: (event?: any) => void
    tooltipPlacement?: 'top' | 'right' | 'left' | 'bottom'
    tooltipText?: string
    className?: string
    [props: string]: any
}

export const TooltipAction: React.FC<TooltipActionProps> = ({
    icon,
    onClick,
    color,
    tooltipPlacement = 'top',
    tooltipText = '',
    className,
    ...props
}) => {
    const classes = useTooltipActionStyles()
    const theme = useTheme()

    function action(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        onClick(event)
    }

    return (
        <Tooltip placement={tooltipPlacement} title={tooltipText}>
            <Button
                className={`${classes.actionButton} ${className || ''}`}
                onClick={action}
                data-testid={props ? props['data-testid'] : ''}
                {...props}
            >
                <FontAwesomeIcon icon={icon} color={color || theme.palette.neutral[600]} />
            </Button>
        </Tooltip>
    )
}
