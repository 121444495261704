export const LIZEE_SUPER_ADMIN = 'LizeeSuperAdmin'
export const BRAND_ADMIN = 'BrandAdmin'

export const LIZEE_PROJECT_MANAGER = 'CustomerSuccessManagerLizee'
export const BRAND_PROJECT_MANAGER = 'BrandProjectManager'
export const BRAND_PROJECT_MANAGER_BRAND = 'BrandProjectManagerBrand'

export const AFTER_SALES_MANAGER = 'AfterSalesManager'

export const WAREHOUSE_MANAGER = 'WarehouseManager'
export const WAREHOUSE_OPERATOR = 'WarehouseOperator'

export const IN_STORE_MANAGER = 'InStoreManager'
export const IN_STORE_OPERATOR = 'InStoreOperator'
export const IN_STORE_RECEPTIONIST = 'InStoreReceptionist'

export const ACCOUNTANT = 'Accountant'

export const ALL_ROLES = [
    LIZEE_SUPER_ADMIN,
    BRAND_ADMIN,
    LIZEE_PROJECT_MANAGER,
    BRAND_PROJECT_MANAGER,
    AFTER_SALES_MANAGER,
    WAREHOUSE_MANAGER,
    WAREHOUSE_OPERATOR,
    IN_STORE_MANAGER,
    IN_STORE_OPERATOR,
    ACCOUNTANT,
]

export const ROLES_BY_ACCESS_LEVEL = [
    LIZEE_SUPER_ADMIN,
    BRAND_ADMIN,
    LIZEE_PROJECT_MANAGER,
    BRAND_PROJECT_MANAGER,
    AFTER_SALES_MANAGER,
    BRAND_PROJECT_MANAGER_BRAND,
    WAREHOUSE_MANAGER,
    WAREHOUSE_OPERATOR,
    IN_STORE_MANAGER,
    IN_STORE_OPERATOR,
    ACCOUNTANT,
    IN_STORE_RECEPTIONIST,
]

export const ALL_ROLES_BUT_ACCOUNTANT = [
    LIZEE_SUPER_ADMIN,
    BRAND_ADMIN,
    LIZEE_PROJECT_MANAGER,
    BRAND_PROJECT_MANAGER,
    AFTER_SALES_MANAGER,
    WAREHOUSE_MANAGER,
    WAREHOUSE_OPERATOR,
    IN_STORE_MANAGER,
    IN_STORE_OPERATOR,
]

export const WAREHOUSE_ROLES = [
    LIZEE_SUPER_ADMIN,
    BRAND_ADMIN,
    WAREHOUSE_MANAGER,
    WAREHOUSE_OPERATOR,
    IN_STORE_MANAGER,
    IN_STORE_OPERATOR,
]

export const IN_STORE_ROLES = [
    LIZEE_SUPER_ADMIN,
    BRAND_ADMIN,
    LIZEE_PROJECT_MANAGER,
    BRAND_PROJECT_MANAGER,
    IN_STORE_MANAGER,
    IN_STORE_OPERATOR,
]

export const MANAGER_ROLES = [
    LIZEE_SUPER_ADMIN,
    BRAND_ADMIN,
    LIZEE_PROJECT_MANAGER,
    BRAND_PROJECT_MANAGER,
    WAREHOUSE_MANAGER,
    IN_STORE_MANAGER,
]

export const OPERATOR_ROLES = [WAREHOUSE_OPERATOR, IN_STORE_OPERATOR]

export const ONLY_ADMIN_ROLES = [LIZEE_SUPER_ADMIN, BRAND_ADMIN]
export const ONLY_MANAGER_ROLES = [LIZEE_PROJECT_MANAGER, BRAND_PROJECT_MANAGER, WAREHOUSE_MANAGER, IN_STORE_MANAGER]
export const ONLY_WORKER_ROLES = [IN_STORE_OPERATOR, WAREHOUSE_OPERATOR]
