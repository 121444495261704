import { useTranslation } from 'react-i18next'

export const useErrorMessage = () => {
    const { t, i18n } = useTranslation()

    function buildErrorMessage(error: any, params?: any, unknown?: string) {
        if (error.response?.data?.message) {
            if (i18n.exists(`errors.${error.response.data.message}`)) {
                return t(`errors.${error.response.data.message}`, params)
            } else {
                return error.response.data.message
            }
        } else if (unknown) {
            if (i18n.exists(unknown)) {
                return t(unknown, { ...params, error: error.message })
            } else {
                return unknown
            }
        } else {
            return t('errors.UNKNOWN', { error: error.message })
        }
    }

    return buildErrorMessage
}
