import * as React from 'react'
import { useState, useEffect } from 'react'
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import isBetween from 'dayjs/plugin/isBetween'
import Button from '_atoms/buttons/Button'

dayjs.extend(isBetween)

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        container: {
            width: theme.space(57),
            padding: theme.space(3, 5),
        },
        selectContainer: {
            height: theme.space(43),
        },
        headerContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.space(1),
            '& p': {
                fontFamily: 'Hanken Grotesk',
                fontSize: theme.typography.pxToRem(15),
                color: theme.palette.primary[900],
                fontWeight: 500,
                '&::first-letter': {
                    textTransform: 'uppercase',
                },
            },
        },
        switchMonthButton: {
            width: theme.space(6),
            height: theme.space(6),
            borderRadius: '4px',
            '& svg': {
                fontSize: theme.typography.pxToRem(14),
                color: theme.palette.neutral[600],
            },
        },
        weekDays: {
            width: '100%',
            display: 'flex',
            gap: `${theme.space(2)}px`,
            marginBottom: theme.space(3),
        },
        weekDay: {
            width: theme.space(5),
            height: theme.space(5),
            fontFamily: 'Oswald',
            fontWeight: 500,
            fontSize: theme.typography.pxToRem(12),
            letterSpacing: '0.6px',
            color: theme.palette.neutral[700],
            textAlign: 'center',
        },
        day: {
            margin: 0,
            width: theme.space(5),
            height: theme.space(5),
            fontFamily: 'Oswald',
            color: theme.palette.neutral[700],
            fontWeight: 500,
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(18),
        },
        days: {
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: `${theme.space(1)}px`,
        },
        customDayHighlight: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: `${theme.space(2 / 5)}px`,
            right: `${theme.space(2 / 5)}px`,
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '50%',
        },
        nonCurrentMonthDay: {
            color: theme.palette.neutral[400],
        },
        highlight: {
            '& .MuiButtonBase-root': {
                background: theme.palette.secondary[600],
                borderRadius: 0,
            },
        },
        firstHighlight: {
            extend: 'highlight',
            '& .MuiButtonBase-root': {
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
            },
        },
        endHighlight: {
            extend: 'highlight',
            '& .MuiButtonBase-root': {
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
            },
        },
        dayWrapper: {
            display: 'flex',
            position: 'relative',
        },
        betweenDays: {
            width: theme.space(2),
            height: theme.space(5),
        },
        highlightBetween: {
            background: theme.palette.secondary[600],
        },
        endBeetween: {
            background: theme.palette.common.white,
        },
        months: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: theme.space(1, 2),
            height: theme.space(43),
        },
        monthRow: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        month: {
            padding: theme.space(2, 1),
        },
        headerTextContainer: {
            display: 'flex',
            gap: `${theme.space(1.5)}px`,
            flex: 1,
            justifyContent: 'center',
            height: theme.space(6),
            '& svg': {
                fontSize: theme.typography.pxToRem(10),
                marginLeft: theme.space(1),
                marginBottom: theme.space(0.1),
            },
        },
        headerText: {
            display: 'inline-block',
            alignItems: 'center',
            width: 'fit-content',
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: '15px',
            height: '22px',
            background: 'none',
            border: 'none',
            padding: 0,
            '&::first-letter': {
                textTransform: 'uppercase',
            },
        },
    }),
)

export type DateRangePickerProps = {
    startDate?: Dayjs
    endDate?: Dayjs
    onDateChange: (startDate: Dayjs, endDate?: Dayjs) => void
    range?: boolean
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onDateChange, range = false }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [date, setDate] = useState<Dayjs>(startDate ? startDate.set('date', 1) : dayjs().set('date', 1))
    const [start, setStart] = useState<Dayjs | undefined>(startDate)
    const [end, setEnd] = useState<Dayjs | undefined>(endDate)
    const [panel, setPanel] = useState<'date' | 'month' | 'year'>('date')

    useEffect(() => {
        if (range && start && end && (startDate !== start || endDate !== end)) {
            onDateChange(start, end)
        }
    }, [start, end])

    function decreaseMonth() {
        setDate(date.subtract(1, 'month'))
    }

    function increaseMonth() {
        setDate(date.add(1, 'month'))
    }

    const handleDateChange = (date: Dayjs) => {
        if (range) {
            if (date && start && !end && start < date) {
                setEnd(date)
            } else {
                setEnd(undefined)
                setStart(date)
            }
        } else {
            onDateChange(date)
        }
    }

    const handleMonthChange = (month: number) => {
        setDate(date.month(month))
        setPanel('date')
    }

    const handleYearChange = (year: number) => {
        setDate(date.year(year))
        setPanel('date')
    }

    const daysInMonth = date.daysInMonth()
    const firstWeekDayInMonth = date.day() || 7

    let numberOfWeeksToDisplay = Math.trunc((daysInMonth + firstWeekDayInMonth - 1) / 7)
    if ((daysInMonth + firstWeekDayInMonth - 1) % 7 > 0) {
        numberOfWeeksToDisplay += 1
    }

    const numberOfDaysToDisplay = numberOfWeeksToDisplay * 7
    const firstMonday = date.subtract(firstWeekDayInMonth - 1, 'day')

    const month: Dayjs[] = []
    for (let index = 0; index < numberOfDaysToDisplay; index++) {
        month.push(firstMonday.add(index, 'day'))
    }

    function renderWrappedWeekDay(date: Dayjs, index: number) {
        let dayIsBetween = false
        let isFirstDay = false
        let isLastDay = false

        if (start) {
            const dayIsStartDate = date.isSame(start, 'day')
            if (end) {
                dayIsBetween = date.isBetween(start, end, 'day', '[]')
                isFirstDay = dayIsStartDate
                isLastDay = date.isSame(end, 'day')
            } else {
                dayIsBetween = dayIsStartDate
                isFirstDay = dayIsStartDate
                isLastDay = dayIsStartDate
            }
        }

        const dayInCurrentMonth = index >= firstWeekDayInMonth - 1 && index < daysInMonth + firstWeekDayInMonth - 1

        const isHighlightClass = dayIsBetween ? classes.highlight : ''
        const isFirstClass = isFirstDay ? classes.firstHighlight : ''
        const isLastClass = isLastDay ? classes.endHighlight : ''

        const isInCurrentMonthClass = dayInCurrentMonth ? '' : classes.nonCurrentMonthDay

        const isHighlightBetweenClass = dayIsBetween ? classes.highlightBetween : ''
        const isFirstBetweenClass = isFirstDay ? classes.firstBetween : ''
        const isLastBetweenClass = isLastDay ? classes.endBeetween : ''

        return (
            <div
                key={index}
                className={`${classes.dayWrapper} ${isHighlightClass} ${isFirstClass} ${isLastClass}`}
                data-testid={date.format('DD/MM/YYYY')}
            >
                <IconButton
                    className={`${classes.day} ${isInCurrentMonthClass}`}
                    onClick={() => handleDateChange(date)}
                    disabled={!dayInCurrentMonth}
                >
                    <span> {date.format('D')} </span>
                </IconButton>
                {date.format('d') !== '0' && (
                    <div
                        className={`${classes.betweenDays} ${isHighlightBetweenClass} ${isFirstBetweenClass} ${isLastBetweenClass}`}
                    />
                )}
            </div>
        )
    }

    const renderDatePicker = () => (
        <div className={classes.selectContainer}>
            <div className={classes.weekDays}>
                {new Array(7).fill(0).map((day, index) => (
                    <div key={index} className={classes.weekDay}>
                        {t(`global.days.${index}`)}
                    </div>
                ))}
            </div>
            <div className={classes.days}>
                {month.map((day, index) => {
                    return renderWrappedWeekDay(day, index)
                })}
            </div>
        </div>
    )

    function sliceMonth(month: string) {
        const slicedMonth = month.length > 4 ? `${month.slice(0, 3)}.` : month
        return slicedMonth.charAt(0).toLocaleUpperCase() + slicedMonth.slice(1)
    }

    const renderMonthPicker = () => (
        <div className={classes.months}>
            {new Array(3).fill(0).map((day, rowIndex) => (
                <div className={classes.monthRow} key={rowIndex}>
                    {new Array(4).fill(0).map((day, colIndex) => {
                        const index = rowIndex * 4 + colIndex
                        return (
                            <Button
                                key={colIndex}
                                className={classes.month}
                                label={sliceMonth(t(`global.months.${index}`))}
                                size="small"
                                variant={date.month() === index ? 'fill' : 'ghost'}
                                color={date.month() === index ? 'secondary' : 'neutral'}
                                onClick={() => handleMonthChange(index)}
                                data-testid={`${t(`global.months.${index}`)}-month`}
                            />
                        )
                    })}
                </div>
            ))}
        </div>
    )

    const renderYearPicker = () => (
        <div className={classes.months}>
            {new Array(3).fill(0).map((day, rowIndex) => (
                <div className={classes.monthRow} key={rowIndex}>
                    {new Array(4).fill(0).map((day, colIndex) => {
                        const year = dayjs().year() - (3 - rowIndex) * 4 - (2 - colIndex) + 4
                        return (
                            <Button
                                key={colIndex}
                                className={classes.month}
                                label={year}
                                size="small"
                                variant={date.year() === year ? 'fill' : 'ghost'}
                                color={date.year() === year ? 'secondary' : 'neutral'}
                                onClick={() => handleYearChange(year)}
                                data-testid={`${year}-year`}
                            />
                        )
                    })}
                </div>
            ))}
        </div>
    )

    const isMonthPanel = panel === 'month'
    const isYearPanel = panel === 'year'
    const isDatePanel = panel === 'date'

    return (
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                {isDatePanel && (
                    <IconButton
                        className={classes.switchMonthButton}
                        onClick={decreaseMonth}
                        data-testid="decreaseMonthButton"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </IconButton>
                )}
                <div className={classes.headerTextContainer}>
                    <button
                        className={classes.headerText}
                        onClick={() => setPanel(isMonthPanel ? 'date' : 'month')}
                        data-testid="monthPanelButton"
                    >
                        {t(`global.months.${date.month()}`)}
                        <FontAwesomeIcon icon={isMonthPanel ? faChevronUp : faChevronDown} />
                    </button>

                    <button
                        className={classes.headerText}
                        onClick={() => setPanel(isYearPanel ? 'date' : 'year')}
                        data-testid="yearPanelButton"
                    >
                        {date.year()}
                        <FontAwesomeIcon icon={isYearPanel ? faChevronUp : faChevronDown} />
                    </button>
                </div>
                {isDatePanel && (
                    <IconButton
                        className={classes.switchMonthButton}
                        onClick={increaseMonth}
                        data-testid="increaseMonthButton"
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </IconButton>
                )}
            </div>
            {panel === 'date' && renderDatePicker()}
            {panel === 'month' && renderMonthPicker()}
            {panel === 'year' && renderYearPicker()}
        </div>
    )
}

export default DateRangePicker
