import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import HowItWorks from '_organisms/HowItWorks'
import BlueBackground from '_molecules/backgrounds/BlueBackground'
import ScanInput from '_molecules/ScanInput'
import { faScannerGun, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Button from '_atoms/buttons/Button'
import AwaitingScanModal from '_molecules/AwaitingScanModal'
import useSelectedCustomersStore from '../../utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from '../../utils/store/selectedCustomers.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            position: 'relative',
        },
        scanWrapper: {
            zIndex: 1001,
            display: 'flex',
            gap: `${theme.space(2)}px`,
            [theme.breakpoints.up('sm')]: {
                width: theme.space(100),
                position: 'absolute',
                top: theme.space(-6),
                left: `calc(50% - ${theme.space(50)}px)`,
            },
            [theme.breakpoints.down('sm')]: {
                transform: `translate(0, ${theme.space(-18)}px)`,
                width: `calc(100vw - ${theme.space(6)}px)`,
                position: 'fixed',
            },
        },
    }),
)

type ReturnParcelHowItWorksProps = {
    handleScan: (scannedParcelBarcode: string) => void
    onParcelSearch: (parcelBarcode: string) => void
}

const ReturnParcelHome: React.FC<ReturnParcelHowItWorksProps> = ({ handleScan, onParcelSearch }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [shouldDisplaySecondHandElements] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.shouldDisplaySecondHandElements,
    ])
    const [isScanPuidModalOpened, setIsScanPuidModalOpened] = useState<boolean>(false)

    function handleSubmitScanParcel(scannedParcelBarcode: string) {
        if (!isScanPuidModalOpened) handleScan(scannedParcelBarcode)
    }

    function onClickUnreadableBarcode() {
        setIsScanPuidModalOpened(true)
    }

    return (
        <Page
            title={
                shouldDisplaySecondHandElements() ? t('menu.returns.registerSecondHand') : t('menu.returns.register')
            }
            section={t('menu.returns.title')}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
        >
            <BlueBackground className={classes.background}>
                <div className={classes.scanWrapper}>
                    <ScanInput
                        iconStart={faScannerGun}
                        onSubmit={handleSubmitScanParcel}
                        placeholder={t('global.scan.parcel')}
                        disabled={isScanPuidModalOpened}
                        fullWidth
                    />
                    <Button
                        size="large"
                        startIcon={faTriangleExclamation}
                        onClick={onClickUnreadableBarcode}
                        data-testid="retrieveParcelByPuid"
                        color="warning"
                    />
                </div>
                <HowItWorks
                    title={t('returnParcel.howItWorks.title')}
                    description={t('returnParcel.howItWorks.description')}
                    instructions={[
                        t('returnParcel.howItWorks.step1'),
                        t('returnParcel.howItWorks.step2'),
                        t('returnParcel.howItWorks.step3'),
                        t('returnParcel.howItWorks.step4'),
                        t('returnParcel.howItWorks.step5'),
                        t('returnParcel.howItWorks.step6'),
                    ]}
                />
            </BlueBackground>
            {isScanPuidModalOpened && (
                <AwaitingScanModal
                    title={t('returnParcel.searchByProduct.modalHeader')}
                    tagline={t('returnParcel.searchByProduct.tagline')}
                    onClose={() => setIsScanPuidModalOpened(false)}
                    onSubmit={onParcelSearch}
                    placeholder={t('returnParcel.searchByProduct.scanInputPlaceholder')}
                />
            )}
        </Page>
    )
}

export default ReturnParcelHome
