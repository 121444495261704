import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import ModalFooter from '_molecules/ModalFooter'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

type MissingProductModalProps = {
    onClose: () => void
    onSubmit: () => void
    onCancel: () => void
    cancelLabel: string
}

const MissingProductModal: React.FC<MissingProductModalProps> = ({ onClose, onSubmit, onCancel, cancelLabel }) => {
    const { t } = useTranslation()

    return (
        <ModalWithHeader
            headerType={HeaderType.MOBILE}
            title={t('new_orders.packing.missingProductModal.title')}
            tagline={t('new_orders.packing.missingProductModal.tagline')}
            open={true}
            handleClose={onClose}
            width={600}
            withDesktopTagline
        >
            <ModalFooter
                label={cancelLabel}
                cancelLabel={t('new_orders.packing.missingProductModal.cancel')}
                onCancel={onCancel}
                onSubmit={onSubmit}
                endIcon={faArrowRight}
                withDivider={false}
            />
        </ModalWithHeader>
    )
}

export default MissingProductModal
