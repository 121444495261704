import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Divider, Link, makeStyles, TextField, Theme, Drawer } from '@material-ui/core'
import { IUniqueProduct, Order, UniqueProductStatus } from 'interfaces'
import UniqueProductCard from '_organisms/uniqueProduct/UniqueProductCard'
import { ORDER_LIST_URL, UNIQUE_PRODUCTS_LAST_COMMENT_URL } from 'utils/routes/backend'
import debounce from 'lodash/debounce'
import { faBoxesStacked, faDryer } from '@fortawesome/pro-light-svg-icons'
import { faScrewdriverWrench, faTrash } from '@fortawesome/pro-regular-svg-icons'
import rmsApi from 'utils/api'
import { RMSFile } from 'utils/files'
import { toast } from 'utils/toast'
import Header from '_organisms/header/Header'
import InputWithLabel from '_molecules/InputWithLabel'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('md')]: {
                '& > *:first-child > *:first-child': {
                    padding: theme.space(7, 6, 6, 6),
                },
                '& > *:first-child .MuiTypography-root': {
                    fontWeight: 500,
                    fontSize: theme.typography.pxToRem(15),
                },
            },
        },
        drawerContainer: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: '100vw',
            minHeight: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            overflow: 'auto',
            backgroundColor: theme.palette.common.white,
            [theme.breakpoints.up('md')]: {
                width: 450,
                minWidth: 'unset',
                minHeight: 'unset',
                borderRadius: '10px',
                padding: theme.space(2, 6, 0),
            },
        },
        drawerBody: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
                padding: theme.space(55, 3, 0),
            },
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.space(0, 3, 0, 6),
            height: theme.space(22),
            alignItems: 'center',
            borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
            background: theme.palette.common.white,
        },
        title: {
            fontWeight: 500,
            fontSize: `${theme.space(3)}px`,
            lineHeight: `${theme.space(4.5)}px`,
        },
        commentContainer: {
            margin: theme.space(8, 0),
            [theme.breakpoints.down('sm')]: {
                margin: theme.space(4, 0),
            },
        },
        dashedDivider: {
            border: 'none',
            borderTop: '1px dashed rgba(0, 0, 0, 0.12)',
            background: 'none',
            '&:last-of-type': {},
        },
        dashedDividerWithMargin: {
            margin: theme.space(4, 0),
        },
        buttonLine: {
            display: 'flex',
            gap: `${theme.space(2)}px`,
            '& .MuiButtonBase-root': {
                height: theme.space(8),
            },
            '&:last-child': {
                padding: theme.space(0, 6),
            },
            '&:last-child .MuiButtonBase-root': {
                height: theme.space(6),
            },
        },
        buttonContainer: {
            padding: theme.space(0, 6),
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                marginTop: theme.space(6),
            },
        },
        statusButton: {
            flex: 1,
            borderRadius: '4px',
        },
        hideDesktop: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        drawerTextfield: {
            backgroundColor: 'rgba(235, 239, 251, 0.16)',
            border: '1px solid #F1F3F7',
            borderRadius: '2px',
            '& *': {
                border: 'none',
            },
            '& textarea': {
                fontSize: theme.typography.pxToRem(12),
            },
        },
        addCommentLink: {
            color: '#3363D2',
            fontSize: theme.typography.pxToRem(12),
            paddingTop: theme.spacing(1),
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        disabledButtonBox: {
            opacity: 0.5,
        },
        productCard: {
            padding: 0,
            margin: 0,
            width: '100%',
        },
        closeButton: {
            minWidth: theme.spacing(5.5),
            minHeight: theme.spacing(5),
            height: 'fit-content',
            borderRadius: '6px',
            fontSize: theme.typography.pxToRem(20),
            [theme.breakpoints.down('sm')]: {
                '& svg': {
                    color: theme.palette.common.white,
                },
                paddingLeft: theme.space(6),
                borderLeft: 'solid 1px rgba(255, 255, 255, 0.25)',
                width: theme.space(8),
                height: theme.space(8),
                borderRadius: 0,
                marginLeft: theme.space(3),
            },
        },
    }),
)

const processedStatus = [
    UniqueProductStatus.TO_REFIT,
    UniqueProductStatus.TO_RESTOCK,
    UniqueProductStatus.TO_REPAIR,
    UniqueProductStatus.OUT_OF_ORDER,
]

type UniqueProductProcessingDrawerProps = {
    isOpen: boolean
    uniqueProduct: IUniqueProduct
    onSubmit: (status: UniqueProductStatus, comment?: string, files?: RMSFile[]) => void
    onCancel: () => void
    addComment: () => void
}

const UniqueProductProcessingDrawer: React.FC<UniqueProductProcessingDrawerProps> = ({
    isOpen,
    uniqueProduct,
    onSubmit,
    onCancel,
    addComment,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [order, setOrder] = useState<Order | undefined | null>()
    const omsOrderId = order?.omsOrderId

    const updateOrderComment = async (comment: string): Promise<void> => {
        if (!order || order.comment === comment) return
        try {
            const updatedOrder = await rmsApi.patch(`${ORDER_LIST_URL}/${order?.id}/comment`, {
                comment: comment,
            })
            setOrder(updatedOrder.data)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    const debouncedCommentUpdate = debounce((comment) => updateOrderComment(comment), 1000)

    async function fetchLastOrderComment(uniqueProductId?: number): Promise<void> {
        if (uniqueProductId) {
            try {
                const uniqueProduct = await rmsApi.get(UNIQUE_PRODUCTS_LAST_COMMENT_URL([uniqueProductId]))
                setOrder(uniqueProduct?.data[0].shipments[0]?.order)
            } catch (e: any) {
                toast.warning(t('returns.parcelInfos.parcelCommentsError'))
            }
        }
    }

    useEffect(() => {
        if (isOpen) {
            fetchLastOrderComment(uniqueProduct?.id)
        }
    }, [isOpen])

    const processedUniqueProductStatus = processedStatus.includes(uniqueProduct.status)

    const renderCloseButton = () => (
        <Button
            onClick={onCancel}
            variant="ghost"
            color="neutral"
            data-testid="close-modal"
            startIcon={faXmark}
            className={classes.closeButton}
        />
    )

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={isOpen}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{
                className: classes.drawer,
            }}
        >
            <Header
                title={uniqueProduct.productReference.name}
                mobileStyle={{ paddingBottom: 12, blueHeight: 12 }}
                rightAddon={{ addon: renderCloseButton(), displayOnMobile: true }}
                withMenuButton={false}
                withDivider
                withToggleNav={false}
            />
            <div className={classes.drawerContainer}>
                <UniqueProductCard uniqueProduct={uniqueProduct} />
                <div className={classes.drawerBody}>
                    <Divider className={`${classes.dashedDivider} ${classes.hideDesktop}`} />
                    {order?.id && (
                        <InputWithLabel
                            label={`${t('parcel.comment')} ${omsOrderId}`}
                            className={classes.commentContainer}
                        >
                            <TextField
                                defaultValue={order.comment}
                                onChange={(e) => debouncedCommentUpdate(e.target.value)}
                                fullWidth={true}
                                multiline={true}
                                rows={2}
                                inputProps={{ maxLength: 500 }}
                                disabled={!order}
                                data-testid={'parcelReturnTextArea-comment'}
                                className={`${classes.drawerTextfield} noKeyboardListener`}
                            />
                        </InputWithLabel>
                    )}
                    <Divider className={`${classes.dashedDivider} ${classes.hideDesktop}`} />
                    <div className={classes.buttonContainer}>
                        <div className={classes.buttonLine}>
                            <Button
                                label={t('parcel.modal.refit')}
                                onClick={() => onSubmit(UniqueProductStatus.TO_REFIT)}
                                startIcon={faDryer}
                                data-testid="statusButton_TO_REFIT"
                                size="medium"
                                className={`${
                                    processedUniqueProductStatus &&
                                    uniqueProduct.status !== UniqueProductStatus.TO_REFIT
                                        ? classes.disabledButtonBox
                                        : ''
                                } ${classes.statusButton}`}
                            />
                            <Button
                                label={t('parcel.modal.restock')}
                                onClick={() => onSubmit(UniqueProductStatus.TO_RESTOCK)}
                                startIcon={faBoxesStacked}
                                data-testid="statusButton_TO_RESTOCK"
                                size="medium"
                                className={`${
                                    processedUniqueProductStatus &&
                                    uniqueProduct.status !== UniqueProductStatus.TO_RESTOCK
                                        ? classes.disabledButtonBox
                                        : ''
                                } ${classes.statusButton}`}
                            />
                        </div>
                        <Divider className={`${classes.dashedDivider} ${classes.dashedDividerWithMargin}`} />
                        <div className={classes.buttonLine}>
                            <Button
                                label={t('parcel.modal.repair')}
                                color="warning"
                                size="small"
                                onClick={() => onSubmit(UniqueProductStatus.TO_REPAIR)}
                                startIcon={faScrewdriverWrench}
                                data-testid={'statusButton_TO_REPAIR'}
                                className={`${
                                    processedUniqueProductStatus &&
                                    uniqueProduct.status !== UniqueProductStatus.TO_REPAIR
                                        ? classes.disabledButtonBox
                                        : ''
                                } ${classes.statusButton}`}
                            />
                            <Button
                                label={t('parcel.modal.trash')}
                                color="error"
                                size="small"
                                onClick={() => onSubmit(UniqueProductStatus.OUT_OF_ORDER)}
                                startIcon={faTrash}
                                data-testid={'statusButton_OUT_OF_ORDER'}
                                className={`${
                                    processedUniqueProductStatus &&
                                    uniqueProduct.status !== UniqueProductStatus.OUT_OF_ORDER
                                        ? classes.disabledButtonBox
                                        : ''
                                } ${classes.statusButton}`}
                            />
                        </div>
                    </div>
                    {processedStatus.includes(uniqueProduct?.status) && (
                        <Link className={classes.addCommentLink} onClick={addComment}>
                            {t('addComment.title')}
                        </Link>
                    )}
                </div>
            </div>
        </Drawer>
    )
}

export default UniqueProductProcessingDrawer
