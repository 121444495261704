import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import Tagline from '_atoms/text/Tagline'
import { SimpleLabel } from '_atoms/text/Labels'
import usePrismicQualitiesStore, { PrismicQualitiesState } from '../../utils/store/usePrismicQualities.store'
import ModalWithHeader, { HeaderType } from '../../_molecules/ModalWithHeader/ModalWithHeader'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentBox: {
            textAlign: 'center',
        },
        prismicQualityLabel: {
            color: '#2A2F3A',
            fontWeight: 500,
        },
        prismicQualityDescription: {
            color: '#2A2F3A',
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(1.5),
        },
    }),
)

type InfoQualityModalProps = {
    isOpen: boolean
    handleClose: () => void
}

const InfoQualityModal: React.FC<InfoQualityModalProps> = ({ isOpen, handleClose }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const prismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.qualities)

    return (
        <ModalWithHeader
            title={t('secondHand.infoQuality.title')}
            open={isOpen}
            handleClose={handleClose}
            width={640}
            headerType={HeaderType.DIVIDER}
        >
            <Box className={classes.contentBox}>
                {prismicQualities?.map((prismicQuality, index) => (
                    <Box key={index}>
                        <SimpleLabel className={classes.prismicQualityLabel}>{prismicQuality.label}</SimpleLabel>
                        <Tagline className={classes.prismicQualityDescription}>{prismicQuality.description}</Tagline>
                    </Box>
                ))}
            </Box>
        </ModalWithHeader>
    )
}

export default InfoQualityModal
