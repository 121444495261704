import * as React from 'react'
import HowItWorks from '_organisms/HowItWorks'
import { useTranslation } from 'react-i18next'
import { InsertType } from './InsertProductHome'

type InsertProductHowItWorksProps = {
    type: InsertType
}

const InsertProductHowItWorks: React.FC<InsertProductHowItWorksProps> = ({ type }) => {
    const { t } = useTranslation()
    const instructions = {
        RENTAL: [
            t('wms.scanUniqueProduct.rentalTab.howItWorks.step1'),
            t('wms.scanUniqueProduct.rentalTab.howItWorks.step2'),
        ],
        SECOND_HAND: [
            t('wms.scanUniqueProduct.secondHandTab.howItWorks.step1'),
            t('wms.scanUniqueProduct.secondHandTab.howItWorks.step2'),
            t('wms.scanUniqueProduct.secondHandTab.howItWorks.step3'),
        ],
    }

    return <HowItWorks title={t('global.howItWorks.title')} instructions={instructions[type]} />
}

export default InsertProductHowItWorks
