import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles<Theme, { disabled: boolean; enabledBorder: boolean; size: string }>((theme: Theme) =>
    createStyles({
        root: {
            display: 'inline-flex',
            padding: theme.space(1),
            borderRadius: '2px',
            border: ({ enabledBorder, disabled }) =>
                enabledBorder && !disabled ? `1px solid ${theme.palette.neutral[200]}` : 'none',
        },
        rootDisabled: {
            border: 'none',
        },
        button: {
            '&:focus:enabled': {
                [theme.breakpoints.up('sm')]: {
                    border: `1px solid ${theme.palette.primary[800]}`,
                },
            },
        },
        buttonMargin: {
            marginLeft: theme.space(1),
        },
        unselected: ({ enabledBorder }) => ({
            [theme.breakpoints.up('sm')]: {
                border: !enabledBorder ? `1px solid ${theme.palette.neutral[300]}` : 'none',
            },
        }),
        buttonPadding: ({ size }) => ({
            padding: size === 'medium' ? theme.space(2, 9) : theme.space(1, 3),
        }),
        icon: {
            marginLeft: theme.space(2),
        },
        hidden: {
            display: 'none',
        },
    }),
)

export interface RadioButtonOption {
    value: any
    label: string
    description?: string
    icon?: IconDefinition
    disabledOption?: boolean
}

type SizeType = 'small' | 'medium' | 'large'

interface RadioButtonsProps {
    options: RadioButtonOption[]
    value: any
    onChange: (a: any) => void
    disabled?: boolean
    dataTestId?: string
    enabledBorder?: boolean
    size?: SizeType
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
    options,
    value,
    onChange,
    disabled = false,
    dataTestId,
    enabledBorder = true,
    size = 'small',
}) => {
    const classes = useStyles({ disabled, enabledBorder, size })

    function handleChange(event: React.MouseEvent<HTMLButtonElement>) {
        onChange(options.find((option) => option.label === event.currentTarget.value))
    }

    return (
        <div className={`${classes.root} ${disabled && classes.rootDisabled}`} data-testid={dataTestId}>
            {options.map((option, index) => {
                const marginClass = index > 0 ? classes.buttonMargin : ''
                const hiddenClass = option.value !== value && (disabled || option.disabledOption) ? classes.hidden : ''
                const unselectedClass = option.value !== value ? classes.unselected : ''
                return (
                    <Button
                        key={index}
                        color={option.value === value ? 'primary' : 'neutral'}
                        onClick={handleChange}
                        value={option.label}
                        label={option.label}
                        className={`${classes.button} ${classes.buttonPadding} ${marginClass} ${hiddenClass} ${unselectedClass}`}
                        disabled={disabled || option.disabledOption}
                        size={size}
                    >
                        {option.icon && <FontAwesomeIcon icon={option.icon} className={classes.icon} />}
                    </Button>
                )
            })}
        </div>
    )
}

export default RadioButtons
