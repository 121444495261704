import { useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, IconButton, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import { Packaging } from 'interfaces/Packaging.interface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import S3SwipeableCarousel from '_organisms/images/carousel/S3SwipeableCarousel'
import DialogCarousel from '_organisms/images/carousel/DialogCarousel'
import { BasicText } from '_atoms/text/BasicText'
import { faImageLandscape } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        summarySection: {
            padding: '0 20px',
        },
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        soonAvailableLink: {
            color: 'grey',
            pointerEvents: 'none',
            cursor: 'default',
        },
        gridContainer: {
            rowGap: `${theme.spacing(0.5)}px`,
            alignItems: 'center',
        },
        pictureIcon: {
            fontSize: '24px',
        },
        modaleDesktop: {
            maxWidth: '1000px',
            width: '1000px',
            '& .MuiDialog-container': {
                width: '80vw',
                maxWidth: '1500px',

                '& .MuiPaper-root': {
                    width: '80vw',
                    maxWidth: '1500px',
                },
            },
        },
        paper: { maxWidth: '100%', width: '100vw' },
        imgDesktop: {
            width: '100%',
            margin: '0 auto',
            display: 'block',
            objectFit: 'contain',
            overflow: 'hidden',
        },
        imgMobile: {
            width: '100%',
        },
        boxLabel: {
            fontFamily: 'Oswald',
        },
        labelText: {
            color: theme.palette.grey[600],
        },
        warning: {
            color: theme.palette.warning.main,
        },
    }),
)

type PackagingSectionProps = {
    packaging?: Packaging
}

const PackagingSection: React.FC<PackagingSectionProps> = ({ packaging }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [isCarouselOpened, setIsCarouselOpened] = useState<boolean>(false)

    if (!packaging) return <Grid item className={classes.summarySection} xs={12} sm={6} style={{ padding: '0 20px' }} />
    const pictures = packaging?.files?.sort((file: any, fileNext: any) => file.id - fileNext.id)
    return (
        <Grid item className={classes.summarySection} xs={12} sm={6} style={{ padding: '0 20px' }}>
            <SectionTitle className={classes.sectionTitle} size="large">
                {t('returns.packaging.packaging')}
            </SectionTitle>
            <Grid className={classes.gridContainer} container>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.packaging.state')}</BasicText>
                </Grid>
                <Grid xs={6} item className={`${packaging ? classes.warning : ''}`}>
                    {packaging ? t('returns.packaging.issueReported') : t('returns.packaging.ok')}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.packaging.comment')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {packaging?.comment || 'N/A'}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.pictures')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {packaging?.files?.length > 0 ? (
                        <div>
                            <IconButton
                                onClick={() => {
                                    setIsCarouselOpened(true)
                                }}
                            >
                                <FontAwesomeIcon icon={faImageLandscape} className={classes.pictureIcon} />
                            </IconButton>
                            {packaging?.files?.length > 1 ? `+${packaging.files.length - 1}` : ''}
                        </div>
                    ) : (
                        ''
                    )}
                </Grid>
                <DialogCarousel
                    title={'packagingIssues'}
                    isOpen={isCarouselOpened}
                    handleClose={() => setIsCarouselOpened(false)}
                >
                    <S3SwipeableCarousel files={pictures} />
                </DialogCarousel>
            </Grid>
        </Grid>
    )
}

export default PackagingSection
