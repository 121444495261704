import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Text from '_atoms/text/Text'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        enrichedIconContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.space(3),
            alignItems: 'center',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                marginTop: '33%',
            },
        },
        icon: {
            display: 'flex',
            width: theme.space(12),
            height: theme.space(12),
            fontSize: theme.typography.pxToRem(30),
            color: theme.palette.primary[600],
            backgroundColor: theme.palette.primary[100],
            padding: theme.space(3),
            borderRadius: '50%',
        },
        title: {
            paddingTop: theme.space(6),
        },
        tagline: {
            paddingTop: theme.space(1),
            textAlign: 'center',
        },
    }),
)

type EnrichedIconProps = {
    icon: IconProp
    title: string
    tagline?: string
    iconProps?: Omit<FontAwesomeIconProps, 'icon'>
}

const EnrichedIcon: React.FC<EnrichedIconProps> = ({ icon, title, tagline, iconProps }) => {
    const classes = useStyles()

    const { className: iconClassName, ...otherIconProps } = iconProps || {}

    return (
        <div className={classes.enrichedIconContainer}>
            <div className={`${classes.icon} ${iconClassName}`}>
                <FontAwesomeIcon icon={icon} {...otherIconProps} />
            </div>
            <Text variant="S2" className={classes.title}>
                {title}
            </Text>
            {tagline && (
                <Text variant="P5" className={classes.tagline}>
                    {tagline}
                </Text>
            )}
        </div>
    )
}

export default EnrichedIcon
