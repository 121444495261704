import * as React from 'react'
import { useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import Button from '_atoms/buttons/Button'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { LOCATIONS_FROM_SKU, PACK_UNIQUE_PRODUCT_URL, PICK_PACK_ISSUE_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import EnrichedIcon from '_molecules/EnrichedIcon'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import { IUniqueProduct } from 'interfaces'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import MissingProductModal from './MissingProductModal'
import { DataPackUniqueProduct, PackingType } from 'interfaces/Packing.interface'
import { PickPackIssueStatus, PickPackIssueType } from 'interfaces/PickPackIssue.interface'
import Tips from '_atoms/containers/Tips'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import Pill from '_atoms/badges/Pill'
import PickPackProductCard from '_organisms/PickPackProductCard'
import { IUniqueProductOrProductReference } from 'interfaces/Batch.interface'

const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            padding: theme.space(2, 5, 0),
        },
        locations: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.space(1)}px`,
            padding: theme.space(3),
            borderRadius: '4px',
            border: `1px solid ${theme.palette.primary[100]}`,
            margin: theme.space(4, 0),
        },
        noLocation: {
            margin: theme.space(4, 0),
        },
    }),
)

type LocationSearchProps = {
    product: IUniqueProductOrProductReference
    omsOrderId: string
    customerName: string
    shipmentId: number
    orderId: number
    isAddedProduct: boolean
    resetPage: () => void
}

const LocationSearch: React.FC<LocationSearchProps> = ({
    omsOrderId,
    customerName,
    shipmentId,
    orderId,
    product,
    isAddedProduct,
    resetPage,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()

    const [locations, setLocations] = useState<string[]>()
    const [isMissingProduct, setIsMissingProduct] = useState<boolean>(false)

    async function fetchPossibleLocationsForSku() {
        try {
            const { data } = await rmsApi.get(LOCATIONS_FROM_SKU(product.sku!))
            const locations: string[] = data.uniqueProducts.map(
                (uniqueProduct: IUniqueProduct) => uniqueProduct.storageLocation,
            )
            setLocations([...new Set(locations.filter((location) => location))])
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { sku: product.sku }, 'new_orders.errors.fetchLocationsError'))
        }
    }

    useEffect(() => {
        if (product.barcodeUid) {
            if (product.storageLocation) {
                setLocations([product.storageLocation])
            } else {
                setLocations([])
            }
        } else {
            fetchPossibleLocationsForSku()
        }
    }, [])

    async function handleScan(barcodeUid: string) {
        if (product.barcodeUid && product.barcodeUid !== barcodeUid) {
            toast.error(t('errors.WRONG_PRODUCT', { barcodeUid }))
        } else {
            const url = PACK_UNIQUE_PRODUCT_URL(orderId)
            const packingType = isAddedProduct ? PackingType.ADDED_BY_SAV : PackingType.SEARCHED_BY_OPERATOR
            const neededProductReferenceId = product.barcodeUid ? product.productReference!.id : product.id
            const payload: DataPackUniqueProduct = { barcodeUid, shipmentId, packingType, neededProductReferenceId }
            try {
                await rmsApi.post(url, payload)
                resetPage()
            } catch (e) {
                toast.error(buildErrorMessage(e, { barcodeUid }, 'new_orders.errors.packUniqueProductError'))
            }
        }
    }

    async function createPickPackIssue(uniqueProductId?: number, productReferenceId?: number) {
        try {
            await rmsApi.post(PICK_PACK_ISSUE_URL, {
                orderId,
                uniqueProductId,
                productReferenceId,
                issueType: PickPackIssueType.PICKING_MISSING_PRODUCT,
                status: PickPackIssueStatus.WAITING_FOR_SAV_ACTION,
            })
            resetPage()
            toast.success(t('new_orders.picking.pickingIssueSubmitted'))
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { orderId }, 'new_orders.errors.createIssueError'))
        }
    }

    async function updatePickPackIssue(uniqueProductId?: number, productReferenceId?: number) {
        try {
            await rmsApi.patch(PICK_PACK_ISSUE_URL, {
                orderId,
                uniqueProductId,
                productReferenceId,
                status: PickPackIssueStatus.WAITING_FOR_SAV_ACTION,
            })
            resetPage()
            toast.success(t('new_orders.packing.missingProductModal.updatePickingMessage'))
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { orderId }, 'new_orders.errors.updateIssueError'))
        }
    }

    async function handleMissingProduct() {
        const uniqueProductId = product.barcodeUid ? product.id : undefined
        const productReferenceId = uniqueProductId ? undefined : product.id

        if (isAddedProduct) {
            await createPickPackIssue(uniqueProductId, productReferenceId)
        } else {
            await updatePickPackIssue(uniqueProductId, productReferenceId)
        }
    }

    const footer = (
        <Button
            size="large"
            data-testid="packing-missing-product"
            label={t('new_orders.packing.missingProduct')}
            onClick={() => setIsMissingProduct(true)}
        />
    )

    if (!locations) {
        return (
            <Page
                title={t('new_orders.packing.locationSearch')}
                handlePrevious={resetPage}
                key="avoidAppBarGlitchLoading"
            >
                <EnrichedIcon
                    icon={faLoader}
                    title={t('new_orders.packing.loadingPage.title')}
                    tagline={t('new_orders.packing.loadingPage.locationSearchTagline')}
                    iconProps={{ spin: true }}
                />
            </Page>
        )
    }

    return (
        <Page
            title={`${customerName} #${omsOrderId}`}
            handlePrevious={resetPage}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            key="avoidAppBarGlitchContent"
        >
            <BackgroundWithScanAndFooter
                footer={footer}
                footerInBoxDesktop={true}
                scanProps={{ onSubmit: handleScan, placeholder: t('new_orders.packing.scanPlaceholder') }}
            >
                <BlueBackgroundHeader
                    title={t('new_orders.packing.locationSearchTitle')}
                    tagline={t('new_orders.packing.locationSearchTagline')}
                    className={classes.header}
                />
                {locations.length > 0 ? (
                    <div className={classes.locations}>
                        {locations.map((location) => (
                            <Pill key={location} label={location} variant="basic" isNumber />
                        ))}
                    </div>
                ) : (
                    <Tips
                        text={t('new_orders.packing.noLocationFound')}
                        className={classes.noLocation}
                        variant="warning"
                    />
                )}
                <PickPackProductCard product={product} />
            </BackgroundWithScanAndFooter>
            {isMissingProduct && (
                <MissingProductModal
                    onClose={() => setIsMissingProduct(false)}
                    onSubmit={handleMissingProduct}
                    onCancel={() => setIsMissingProduct(false)}
                    cancelLabel={
                        isAddedProduct
                            ? t('new_orders.packing.missingProductModal.createIssue')
                            : t('new_orders.packing.missingProductModal.updateIssue')
                    }
                />
            )}
        </Page>
    )
}

export default LocationSearch
