import * as React from 'react'
import { Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import BasicModal from '../reusable/BasicModal'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            borderRadius: 25,
            display: 'flex',
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0.5, 2),
            textTransform: 'uppercase',
            minHeight: 30,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    }),
)

type ConfirmModalProps = {
    title: string
    description: string
    buttonSuccessText: string
    buttonDangerText: string
    isOpen: boolean
    onClose: () => void
    handleConfirm: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
    title,
    description,
    buttonSuccessText,
    buttonDangerText,
    isOpen,
    onClose,
    handleConfirm,
}) => {
    const classes = useStyles()

    return (
        <BasicModal title={title} description={description} isOpen={isOpen} onClose={onClose}>
            <Grid container spacing={2} alignItems={'center'} justify={'center'} alignContent={'center'}>
                <Button
                    data-testid="confirmModalSuccessButton"
                    className={classes.button}
                    onClick={() => handleConfirm()}
                    variant="contained"
                    color="primary"
                    size="small"
                    component="span"
                >
                    {buttonSuccessText}
                </Button>
                <Button
                    data-testid="confirmModalDangerButton"
                    className={classes.button}
                    onClick={() => onClose()}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    component="span"
                >
                    {buttonDangerText}
                </Button>
            </Grid>
        </BasicModal>
    )
}

export default ConfirmModal
