import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import InputField, { InputFieldProps } from '_atoms/inputs/InputField'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scan: {
            '& .MuiInput-root': {
                '& svg': {
                    marginRight: theme.space(3),
                    color: theme.palette.neutral[800],
                },
                height: theme.space(12),
                borderRadius: 4,
                border: 'none',
                backgroundColor: theme.palette.common.white,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
            },
        },
    }),
)

export interface ScanInputProps extends Omit<InputFieldProps, 'onChange' | 'onSubmit'> {
    defaultValue?: string
    onSubmit: (submittedText: string) => void
    onChange?: (submittedText: string) => void
    lockFocus?: boolean
    invisible?: boolean
    disabled?: boolean
    dataTestId?: string
}

const ScanInput: React.FC<ScanInputProps> = ({
    className,
    defaultValue = '',
    onChange = () => {
        return null
    },
    onSubmit,
    lockFocus = false,
    invisible = false,
    disabled = false,
    dataTestId = 'scanInput',
    ...inputProps
}) => {
    const classes = useStyles()
    const [value, setValue] = useState(defaultValue)
    const [isListenerActive, setListenerActive] = useState<boolean>(invisible)

    const handleChange = (currentValue: string) => {
        if (disabled) return
        setValue(currentValue)
        onChange(currentValue)
    }

    const handleSubmit = () => {
        if (disabled) return
        setValue('')
        onSubmit(value)
    }

    const isNoKeyboardListenerActive = useCallback((maxRec: number, element?: any): boolean | null => {
        const currentElement = element ?? document.activeElement
        if (currentElement && maxRec > 0) {
            if (currentElement.classList.contains('noKeyboardListener')) {
                return true
            }
            return currentElement.parentElement && isNoKeyboardListenerActive(maxRec - 1, currentElement.parentElement)
        }
        return false
    }, [])

    const handleEventListnerInput = ({ key }: any) => {
        if (disabled) return
        if (!isNoKeyboardListenerActive(3)) {
            if (key === 'Enter') {
                if (value) handleSubmit()
            } else {
                const valueCopy = `${value || ''}`
                onChange(`${valueCopy}${key}`)
                setValue(`${valueCopy}${key}`)
            }
        }
    }

    useEffect(() => {
        if (isListenerActive) document.addEventListener('keypress', handleEventListnerInput)
        else document.removeEventListener('keypress', handleEventListnerInput)

        return () => {
            document.removeEventListener('keypress', handleEventListnerInput)
        }
    }, [isListenerActive, onChange, onSubmit, handleEventListnerInput])

    if (invisible) return <></>

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                const input = e.currentTarget.firstChild! as HTMLElement
                input.blur()
                handleSubmit()
            }}
            style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center' }}
            action=""
        >
            <InputField
                data-testid={dataTestId}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                autoFocus
                onBlur={() => {
                    if (lockFocus) document.getElementById('codeScanner')?.focus()
                    setListenerActive(true)
                }}
                onFocus={() => {
                    setListenerActive(false)
                }}
                className={`${className} ${classes.scan}`}
                type="search"
                {...inputProps}
            />
        </form>
    )
}

export default ScanInput
