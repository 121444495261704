import * as React from 'react'
import { useState } from 'react'
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { List, arrayMove } from 'react-movable'
import rmsApi from 'utils/api'
import {
    ADD_INSTRUCTION_TO_GROUP,
    REMOVE_INSTRUCTION_FROM_GROUP,
    UPDATE_INSTRUCTION_POSITION_ROUTE,
} from 'utils/routes/backend'
import { toast } from 'utils/toast'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import AddInstructionToGroupModal from '_pages/instructionGroupsManagement/modals/AddInstructionToGroupModal'
import { InstructionActionType } from 'interfaces'
import { IRefitInstruction, IRefitInstructionLink } from 'interfaces'
import InstructionCard from './InstructionCard'
import InstructionModal from '_organisms/instructionModal/InstructionModal'
import { InstructionModalType } from '_organisms/instructionModal/InstructionModal'
import TopSearchSection from '_molecules/TopSearchSection'
import NoResultTip from '_molecules/NoResultTip'
import DeleteModal from '_organisms/DeleteModal'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up('md')]: {
                padding: theme.space(6, 0),
            },
        },
    }),
)

type InstructionGroupTabProps = {
    instructions: any[]
    customerRefitInstructions: any[]
    groupId: number
    setInstructions: (instructions: any) => void
    refreshGroup: () => void
}

const InstructionGroupTab: React.FC<InstructionGroupTabProps> = ({
    instructions,
    customerRefitInstructions,
    groupId,
    setInstructions,
    refreshGroup,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [refitInstructionLink, setRefitInstructionLink] = useState<IRefitInstructionLink | undefined>()
    const [openInstructionActionModal, setOpenInstructionActionModal] = useState<InstructionActionType | undefined>()

    async function patchPosition({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
        setInstructions(arrayMove(instructions, oldIndex, newIndex))
        const instruction = instructions[oldIndex]
        try {
            const newPositions = await rmsApi.patch(
                UPDATE_INSTRUCTION_POSITION_ROUTE(instruction.refitGroup.id, instruction.id),
                {
                    position: newIndex + 1,
                },
            )
            setInstructions(newPositions.data)
        } catch (e) {
            toast.error('Failed to reorder instructions')
            refreshGroup()
        }
    }

    async function addInstructionToGroup(instruction: IRefitInstruction) {
        try {
            await rmsApi.post(ADD_INSTRUCTION_TO_GROUP(groupId, instruction.id!))
            refreshGroup()
            setOpenInstructionActionModal(undefined)
        } catch (e: any) {
            toast.error(t('refit.instructionGroups.errors.addInstruction'))
        }
    }

    async function unlinkInstructionFromGroup(instructionLinkId: number) {
        try {
            await rmsApi.delete(REMOVE_INSTRUCTION_FROM_GROUP(groupId, instructionLinkId))
            refreshGroup()
            setOpenInstructionActionModal(undefined)
            toast.success(t('refit.instructionGroups.successMessage.unlinkInstruction'))
        } catch (e: any) {
            toast.error(t('refit.instructionGroups.errors.unlinkInstruction'))
        }
    }

    // Filter pickable instructions to add
    const filteredCustomerRefitInstructions = customerRefitInstructions.filter(
        (customerInstruction) =>
            !instructions.some((groupInstruction) => groupInstruction.refitInstruction.id === customerInstruction.id),
    )

    return (
        <div className={classes.container}>
            <TopSearchSection input={false}>
                <Button
                    onClick={() => setOpenInstructionActionModal(InstructionActionType.ADD)}
                    startIcon={faPlus}
                    shape={isMobile ? 'rounded' : undefined}
                    data-testid={'addInstructionButton'}
                >
                    {!isMobile ? t('refit.instructionGroups.manageGroup.instructionsTab.newInstruction') : undefined}
                </Button>
            </TopSearchSection>
            {instructions.length ? (
                <List
                    values={instructions}
                    lockVertically={true}
                    onChange={patchPosition}
                    renderList={({ children, props }) => (
                        <div {...props} data-testid="instructionList">
                            {children}
                        </div>
                    )}
                    renderItem={({ value: refitInstructionLink, props: listProps }) => (
                        <div {...listProps}>
                            <InstructionCard
                                refitInstructionLink={refitInstructionLink}
                                setRefitInstruction={setRefitInstructionLink}
                                setOpenInstructionActionModal={setOpenInstructionActionModal}
                                dataTestId={`instructionCard_${listProps.key}`}
                            />
                        </div>
                    )}
                />
            ) : (
                <NoResultTip text={t('refit.instructionGroups.manageGroup.instructionsTab.noInstruction')}>
                    <Button
                        label={t('refit.instructionGroups.manageGroup.instructionsTab.newInstruction')}
                        onClick={() => setOpenInstructionActionModal(InstructionActionType.ADD)}
                        startIcon={faPlus}
                        data-testid={'addInstructionButton'}
                    />
                </NoResultTip>
            )}
            {openInstructionActionModal === InstructionActionType.ADD && (
                <AddInstructionToGroupModal
                    customerRefitInstructions={filteredCustomerRefitInstructions}
                    handleSubmit={addInstructionToGroup}
                    handleClose={() => setOpenInstructionActionModal(undefined)}
                    handleRefresh={refreshGroup}
                />
            )}
            {openInstructionActionModal === InstructionActionType.VIEW && (
                <InstructionModal
                    type={InstructionModalType.VIEW}
                    instruction={refitInstructionLink!.refitInstruction}
                    handleClose={() => setOpenInstructionActionModal(undefined)}
                />
            )}
            {openInstructionActionModal === InstructionActionType.DELETE && (
                <DeleteModal
                    onSubmit={() => unlinkInstructionFromGroup(refitInstructionLink!.id!)}
                    onClose={() => setOpenInstructionActionModal(undefined)}
                    title={t('refit.instructionGroups.manageGroup.unlinkInstruction.unlink')}
                    label={t('refit.instructionGroups.manageGroup.unlinkInstruction.unlinkLabel')}
                    inputValue={refitInstructionLink!.refitInstruction.title}
                    tipText={t('refit.instructionGroups.manageGroup.unlinkInstruction.unlinkTip')}
                    buttonLabel={t('refit.instructionGroups.actionButtons.unlink')}
                />
            )}
        </div>
    )
}

export default InstructionGroupTab
