import { ReactNode } from 'react'
import * as React from 'react'
import DateRangePicker from '_atoms/inputs/DateRangePicker'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { PickerLabel } from '_atoms/text/Labels'
import { Dayjs } from 'dayjs'

type LabeledDateRangePickerProps = {
    className?: string
    label: string & ReactNode
    startDate: Dayjs
    endDate: Dayjs
    setStartDate: any
    setEndDate: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        dateRangePicker: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(0),
            },
        },
    }),
)

const LabeledDateRangePicker: React.FC<LabeledDateRangePickerProps> = ({
    label,
    className,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) => {
    const classes = useStyles()

    return (
        <Box className={`${classes.box} ${className}`}>
            <PickerLabel>{label}</PickerLabel>
            <DateRangePicker
                className={classes.dateRangePicker}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
        </Box>
    )
}

export default LabeledDateRangePicker
