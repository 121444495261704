import * as React from 'react'
import { useState } from 'react'
import { createStyles, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import Text from '_atoms/text/Text'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import Logo from 'components/Logo'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },
        doughnut: {
            background: 'radial-gradient(circle, transparent 23%, rgba(255, 255, 255, 0.64) 23%)',
            width: '100%',
            borderRadius: '100%',
            aspectRatio: '1/1',
            maxHeight: `calc(100vh - ${theme.space(40)}px)`,
            maxWidth: `calc(100vh - ${theme.space(40)}px)`,
            margin: 'auto 0',
        },
        doughnutHover: {
            background: 'radial-gradient(circle, transparent 23%, rgba(255, 255, 255, 0.56) 23%)',
        },
        doughnutLine: {
            width: '100%',
            height: '33.3%',
        },
        logo: {
            width: '50%',
            height: '50%',
        },
        doughnutElement: {
            cursor: 'default',
            transition: 'all linear 0.15s',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '2.5%',
            borderRadius: '100%',
        },
        doughnutElementHover: {
            background: 'radial-gradient(circle, transparent 23%, rgba(255, 255, 255, 0.64) 23%)',
        },
        doughnutElementNotHover: {
            opacity: 0.6,
        },
        arrow: {
            color: theme.palette.neutral[600],
            fontSize: theme.typography.pxToRem(16),
        },
        number: {
            width: theme.space(4),
            height: theme.space(4),
            padding: theme.space(2),
            fontSize: theme.typography.pxToRem(11),
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary[600],
            backgroundColor: theme.palette.primary[100],
            margin: theme.space(3 / 5),
        },
        oneToTwo: {
            transform: 'rotate(45deg)',
            position: 'relative',
            top: '30%',
            right: '30%',
        },
        twoToThree: {
            transform: 'rotate(135deg)',
            position: 'relative',
            bottom: '30%',
            right: '30%',
        },
        threeToFour: {
            transform: 'rotate(225deg)',
            position: 'relative',
            bottom: '30%',
            left: '30%',
        },
        fourToOne: {
            transform: 'rotate(315deg)',
            position: 'relative',
            top: '30%',
            left: '30%',
        },
        boxContainer: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: '100%',
            [theme.breakpoints.up('md')]: {
                minWidth: theme.space(60),
                marginLeft: theme.space(4),
            },
        },
        box: {
            transition: 'all linear 0.15s',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: theme.space(60),
            },
            backgroundColor: 'rgba(255, 255, 255, 0.64)',
            borderRadius: theme.space(2),
            padding: theme.space(3),
            marginBottom: theme.space(1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            '& > button': {
                marginTop: theme.space(1),
            },
            border: '1px solid transparent',
            flexBasis: 'auto',
        },
        mobileChevron: {
            right: theme.space(9),
            position: 'absolute',
            color: theme.palette.neutral[600],
            fontSize: theme.typography.pxToRem(16),
            padding: theme.space(1),
        },
        mobileBoxDescription: {
            [theme.breakpoints.down('md')]: {
                transition: 'all linear 0.15s',
                opacity: 0,
                height: 0,
                overflow: 'hidden',
            },
        },
        boxHover: {
            [theme.breakpoints.up('md')]: {
                border: `1px solid ${theme.palette.secondary[500]}`,
            },
            [theme.breakpoints.down('md')]: {
                opacity: 1,
                height: 'auto',
                '& > p': {
                    padding: theme.space(1, 0),
                },
            },
        },
        boxNotHover: {
            [theme.breakpoints.up('md')]: {
                opacity: 0.6,
            },
        },
        boxTitle: {
            display: 'flex',
            cursor: 'pointer',
        },
        boxTitleText: {
            marginLeft: theme.space(1),
        },
        figure: {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(32),
            lineHeight: theme.typography.pxToRem(30),
            marginBottom: -2,
        },
        figureTitle: {
            fontSize: theme.typography.pxToRem(15),
            lineHeight: theme.typography.pxToRem(15),
            paddingLeft: theme.space(1),
            display: 'inline-block',
            alignItems: 'baseline',
        },
        cardFigures: {
            display: 'flex',
            alignItems: 'flex-end',
            background: '-webkit-linear-gradient(0deg, rgba(0,35,58,1) 0%, rgba(86,109,124,1) 100%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
    }),
)

type Figures = {
    figure: number
    figureTitle: string
}[]

type DoughnutAndCardsStep = {
    title: string
    tagline: string
    figures: Figures
    actions: JSX.Element
}

export type DoughnutAndCardsProps = {
    steps?: {
        first: DoughnutAndCardsStep
        second: DoughnutAndCardsStep
        third: DoughnutAndCardsStep
        fourth: DoughnutAndCardsStep
    }
}

const DoughnutAndCards: React.FC<DoughnutAndCardsProps> = ({ steps }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

    const defaultBoxAndDoughnutStyle = ['', '', '', '']

    const [doughnutStyle, setDoughnutStyle] = useState<string>('')
    const [doughnutStepStyle, setDoughnutStepStyle] = useState<string[]>(defaultBoxAndDoughnutStyle)
    const [boxStepStyle, setBoxStepStyle] = useState<string[]>(defaultBoxAndDoughnutStyle)

    if (!steps) return <></>

    function setStepStyle(stepNumber: number, add: boolean): void {
        if (add) {
            setDoughnutStyle(classes.doughnutHover)
            setDoughnutStepStyle(
                doughnutStepStyle.map((step, idx) => {
                    if (idx === stepNumber) return classes.doughnutElementHover
                    else return classes.doughnutElementNotHover
                }),
            )
            setBoxStepStyle(
                doughnutStepStyle.map((step, idx) => {
                    if (idx === stepNumber) return classes.boxHover
                    else return classes.boxNotHover
                }),
            )
        } else {
            setDoughnutStyle('')
            setDoughnutStepStyle(defaultBoxAndDoughnutStyle)
            setBoxStepStyle(defaultBoxAndDoughnutStyle)
        }
    }

    function setStepStyleOnKeyPress(keyPressed: string): void {
        switch (keyPressed) {
            case '1':
                setStepStyle(0, boxStepStyle[0] != classes.boxHover)
                break
            case '2':
                setStepStyle(1, boxStepStyle[1] != classes.boxHover)
                break
            case '3':
                setStepStyle(2, boxStepStyle[2] != classes.boxHover)
                break
            case '4':
                setStepStyle(3, boxStepStyle[3] != classes.boxHover)
                break
        }
    }

    const renderCard = (figures: Figures) => (
        <div className={classes.cardFigures}>
            {figures.map(({ figureTitle, figure }, index) => {
                return (
                    <>
                        <div className={classes.figure}>{figure}</div>
                        <div className={classes.figureTitle}>
                            {`${figureTitle}${index === figures.length - 1 ? '' : ' |'}`}&nbsp;
                        </div>
                    </>
                )
            })}
        </div>
    )

    return (
        <div className={classes.container}>
            {!isMobile && (
                <div className={`${classes.doughnut} ${doughnutStyle}`}>
                    <Grid container className={classes.doughnutLine}>
                        <Grid item xs={4} className={classes.doughnutElement}>
                            <FontAwesomeIcon icon={faArrowRight} className={`${classes.fourToOne} ${classes.arrow}`} />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={`${classes.doughnutElement} ${doughnutStepStyle[0]}`}
                            onMouseEnter={() => setStepStyle(0, true)}
                            onMouseLeave={() => setStepStyle(0, false)}
                        >
                            <div className={classes.number}>1</div>
                            <Text variant="H6">{steps.first.title}</Text>
                            {doughnutStepStyle[0] === classes.doughnutElementHover && (
                                <Text variant="P5">{steps.first.tagline}</Text>
                            )}
                        </Grid>
                        <Grid item xs={4} className={classes.doughnutElement}>
                            <FontAwesomeIcon icon={faArrowRight} className={`${classes.oneToTwo} ${classes.arrow}`} />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.doughnutLine}>
                        <Grid
                            item
                            xs={4}
                            className={`${classes.doughnutElement} ${doughnutStepStyle[3]}`}
                            onMouseEnter={() => setStepStyle(3, true)}
                            onMouseLeave={() => setStepStyle(3, false)}
                        >
                            <div className={classes.number}>4</div>
                            <Text variant="H6">{steps.fourth.title}</Text>
                            {doughnutStepStyle[3] === classes.doughnutElementHover && (
                                <Text variant="P5">{steps.fourth.tagline}</Text>
                            )}
                        </Grid>
                        <Grid item xs={4} className={classes.doughnutElement}>
                            <Logo withoutText={true} />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={`${classes.doughnutElement} ${doughnutStepStyle[1]}`}
                            onMouseEnter={() => setStepStyle(1, true)}
                            onMouseLeave={() => setStepStyle(1, false)}
                        >
                            <div className={classes.number}>2</div>
                            <Text variant="H6">{steps.second.title}</Text>
                            {doughnutStepStyle[1] === classes.doughnutElementHover && (
                                <Text variant="P5">{steps.second.tagline}</Text>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.doughnutLine}>
                        <Grid item xs={4} className={classes.doughnutElement}>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className={`${classes.threeToFour} ${classes.arrow}`}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={`${classes.doughnutElement} ${doughnutStepStyle[2]}`}
                            onMouseEnter={() => setStepStyle(2, true)}
                            onMouseLeave={() => setStepStyle(2, false)}
                        >
                            <div className={classes.number}>3</div>
                            <Text variant="H6">{steps.third.title}</Text>
                            {doughnutStepStyle[2] === classes.doughnutElementHover && (
                                <Text variant="P5">{steps.third.tagline}</Text>
                            )}
                        </Grid>
                        <Grid item xs={4} className={classes.doughnutElement}>
                            <FontAwesomeIcon icon={faArrowRight} className={`${classes.twoToThree} ${classes.arrow}`} />
                        </Grid>
                    </Grid>
                </div>
            )}

            <div className={classes.boxContainer}>
                <Grid item xs={12} className={`${classes.box} ${boxStepStyle[0]}`}>
                    <div
                        className={classes.boxTitle}
                        onClick={() => setStepStyle(0, boxStepStyle[0] != classes.boxHover)}
                        onKeyDown={(e: React.KeyboardEvent) => setStepStyleOnKeyPress(e.key)}
                        tabIndex={-1}
                    >
                        <div className={classes.number}>1</div>
                        <Text variant="H6" className={classes.boxTitleText}>
                            {steps.first.title}
                        </Text>
                        {isMobile && (
                            <FontAwesomeIcon
                                icon={boxStepStyle[0] != classes.boxHover ? faChevronDown : faChevronUp}
                                className={classes.mobileChevron}
                            />
                        )}
                    </div>
                    {isMobile && (
                        <Text variant="P5" className={`${classes.mobileBoxDescription} ${boxStepStyle[0]}`}>
                            {steps.first.tagline}
                        </Text>
                    )}
                    {renderCard(steps.first.figures)}
                    {steps.first.actions}
                </Grid>
                <Grid item xs={12} className={`${classes.box} ${boxStepStyle[1]}`}>
                    <div
                        className={classes.boxTitle}
                        onClick={() => setStepStyle(1, boxStepStyle[1] != classes.boxHover)}
                        onKeyDown={(e: React.KeyboardEvent) => setStepStyleOnKeyPress(e.key)}
                        tabIndex={-1}
                    >
                        <div className={classes.number}>2</div>
                        <Text variant="H6" className={classes.boxTitleText}>
                            {steps.second.title}
                        </Text>
                        {isMobile && (
                            <FontAwesomeIcon
                                icon={boxStepStyle[1] != classes.boxHover ? faChevronDown : faChevronUp}
                                className={classes.mobileChevron}
                            />
                        )}
                    </div>
                    {isMobile && (
                        <Text variant="P5" className={`${classes.mobileBoxDescription} ${boxStepStyle[1]}`}>
                            {steps.second.tagline}
                        </Text>
                    )}
                    {renderCard(steps.second.figures)}
                    {steps.second.actions}
                </Grid>
                <Grid item xs={12} className={`${classes.box} ${boxStepStyle[2]}`}>
                    <div
                        className={classes.boxTitle}
                        onClick={() => setStepStyle(2, boxStepStyle[2] != classes.boxHover)}
                        onKeyDown={(e: React.KeyboardEvent) => setStepStyleOnKeyPress(e.key)}
                        tabIndex={-1}
                    >
                        <div className={classes.number}>3</div>
                        <Text variant="H6" className={classes.boxTitleText}>
                            {steps.third.title}
                        </Text>
                        {isMobile && (
                            <FontAwesomeIcon
                                icon={boxStepStyle[2] != classes.boxHover ? faChevronDown : faChevronUp}
                                className={classes.mobileChevron}
                            />
                        )}
                    </div>
                    {isMobile && (
                        <Text variant="P5" className={`${classes.mobileBoxDescription} ${boxStepStyle[2]}`}>
                            {steps.third.tagline}
                        </Text>
                    )}
                    {renderCard(steps.third.figures)}
                    {steps.third.actions}
                </Grid>
                <Grid item xs={12} className={`${classes.box} ${boxStepStyle[3]}`}>
                    <div
                        className={classes.boxTitle}
                        onClick={() => setStepStyle(3, boxStepStyle[3] != classes.boxHover)}
                        onKeyDown={(e: React.KeyboardEvent) => setStepStyleOnKeyPress(e.key)}
                        tabIndex={-1}
                    >
                        <div className={classes.number}>4</div>
                        <Text variant="H6" className={classes.boxTitleText}>
                            {steps.fourth.title}
                        </Text>
                        {isMobile && (
                            <FontAwesomeIcon
                                icon={boxStepStyle[3] != classes.boxHover ? faChevronDown : faChevronUp}
                                className={classes.mobileChevron}
                            />
                        )}
                    </div>
                    {isMobile && (
                        <Text variant="P5" className={`${classes.mobileBoxDescription} ${boxStepStyle[3]}`}>
                            {steps.fourth.tagline}
                        </Text>
                    )}
                    {renderCard(steps.fourth.figures)}
                    {steps.fourth.actions}
                </Grid>
            </div>
        </div>
    )
}

export default DoughnutAndCards
