import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import TopSearchSection from '_molecules/TopSearchSection'
import ProductsTable from '_pages/instructionGroupsManagement/ProductsTable'
import { IInstructionGroup, IProductWithEans } from 'interfaces'
import rmsApi from 'utils/api'
import { REFIT_GROUP_UNLINK_PRODUCTS_ROUTE } from 'utils/routes/backend'
import { faUnlink } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import NoResultTip from '_molecules/NoResultTip'
import DeleteModal from '_organisms/DeleteModal'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up('md')]: {
                padding: theme.space(6, 0),
            },
        },
        unlink: {
            color: theme.palette.error.main,
            fontWeight: 500,
        },
        noProductsText: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '100px',
            color: theme.palette.neutral[400],
        },
    }),
)

type LinkedProductsTabProps = {
    group: IInstructionGroup
    products: IProductWithEans[]
    refreshGroup: () => void
}

const LinkedProductsTab: React.FC<LinkedProductsTabProps> = ({ group, products, refreshGroup }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [selectedProducts, setSelectedProducts] = useState<number[]>([])
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
    const [linkedProductFilter, setLinkedProductFilter] = useState<string>('')

    async function unlinkProducts() {
        try {
            setIsModalOpened(false)
            await rmsApi.delete(REFIT_GROUP_UNLINK_PRODUCTS_ROUTE(group.id!, selectedProducts))
            refreshGroup()
            setSelectedProducts([])
            toast.success(t('refit.instructionGroups.successMessage.unlinkProduct'))
        } catch (e: any) {
            toast.error(t('refit.instructionGroups.errors.unlinkProduct'))
        }
    }

    const filteredLinkedProducts = products?.filter((linkedProduct) =>
        linkedProduct.name.toLowerCase().includes(linkedProductFilter.toLowerCase()),
    )

    return (
        <div className={classes.container}>
            <TopSearchSection
                placeholder={t('refit.instructionGroups.searchInputPlaceholder.product')}
                filter={linkedProductFilter}
                setFilter={setLinkedProductFilter}
                clearFilter={() => setLinkedProductFilter('')}
                input={products?.length > 0}
            >
                {selectedProducts.length > 0 && (
                    <Button
                        className={classes.unlink}
                        color="error"
                        variant="stroke"
                        shape={isMobile ? 'rounded' : undefined}
                        onClick={() => setIsModalOpened(true)}
                        startIcon={faUnlink}
                        data-testid="unlinkFromGroupButton"
                    >
                        {!isMobile && t('refit.instructionGroups.manageGroup.linkedProductsTab.unlink')}
                    </Button>
                )}
            </TopSearchSection>
            {filteredLinkedProducts?.length ? (
                <ProductsTable
                    products={filteredLinkedProducts}
                    selectedIds={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            ) : (
                <NoResultTip
                    text={
                        linkedProductFilter
                            ? t('refit.instructionGroups.manageGroup.linkedProductsTab.noProductWithFilter')
                            : t('refit.instructionGroups.manageGroup.linkedProductsTab.noProduct')
                    }
                />
            )}
            <DeleteModal
                isOpen={isModalOpened}
                onSubmit={unlinkProducts}
                onClose={() => setIsModalOpened(false)}
                title={t('refit.instructionGroups.manageGroups.productsTab.unlinkFromGroup')}
                label={t('refit.instructionGroups.manageGroups.productsTab.instructionGroup')}
                inputValue={group?.name}
                tipText={t('refit.instructionGroups.manageGroups.productsTab.tips')}
                buttonLabel={t('refit.instructionGroups.manageGroups.productsTab.validate')}
            />
        </div>
    )
}

export default LinkedProductsTab
