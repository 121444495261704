import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import theme from '../../theme'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hrContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            maxWidth: '60%',
        },
        hrIcon: {
            height: '2em',
            width: '2em',
            marginRight: theme.spacing(0.5),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(0.25),
                height: '1.5em',
                width: '1.5em',
            },
        },
        sectionTitle: {
            fontWeight: 500,
        },
    }),
)

type Props = {
    divHeight?: number
    mdiIcon: JSX.Element
    title: string
}

const TitledHr: React.FC<Props> = ({ mdiIcon, title, divHeight = 30 }) => {
    const classes = useStyles()

    return (
        <div style={{ borderBottom: '1px solid #000000', marginBottom: theme.spacing(2) }}>
            <div className={classes.hrContainer} style={{ margin: `0 auto ${-divHeight / 2}px` }}>
                {React.cloneElement(mdiIcon, { className: classes.hrIcon })}
                <Typography className={classes.sectionTitle} variant="h4">
                    {title}
                </Typography>
            </div>
        </div>
    )
}

export default TitledHr
