import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import CustomRadioGroup, { RadioOption } from '_atoms/inputs/RadioGroup'

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            width: theme.space(55),
        },
    }),
)

type RadioListFilterProps = {
    applyFilter?: (value: any) => void // Injected in children component
    options: RadioOption[]
    selected?: string | number
}

const RadioListFilter: React.FC<RadioListFilterProps> = ({ applyFilter, options, selected }) => {
    const classes = useStyles()

    function onChange(value: string) {
        applyFilter!(options.find((option) => option.value === parseInt(value))!.label)
    }
    return <CustomRadioGroup className={classes.container} onChange={onChange} options={options} value={selected} />
}

export default RadioListFilter
