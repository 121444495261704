import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { REFIT_INSTRUCTIONS_ROUTE } from 'utils/routes/backend'
import InstructionModal from '_pages/instructionsManagement/modals/ManageInstructionModal'
import { InstructionModalType } from '_organisms/instructionModal/InstructionModal'
import DeleteModal from '_organisms/DeleteModal'
import { InstructionActionType, IRefitInstruction } from 'interfaces'
import InstructionCard from './InstructionCard'
import { InstructionCategoriesLevels } from './InstructionsManagement'

const actionTypeToModalType = {
    [InstructionActionType.VIEW]: InstructionModalType.VIEW,
    [InstructionActionType.ADD]: InstructionModalType.CREATE,
    [InstructionActionType.EDIT]: InstructionModalType.EDIT,
    [InstructionActionType.CLONE]: InstructionModalType.CLONE,
    [InstructionActionType.DELETE]: '',
}

type HandleInstructionsProps = {
    instructions: IRefitInstruction[]
    handleRefresh: () => void
    selectedCategory: string
    selectedSubCategory: string
    deleteItem: (url: string) => void
}

const HandleInstructions: React.FC<HandleInstructionsProps> = ({
    instructions,
    handleRefresh,
    selectedCategory,
    selectedSubCategory,
    deleteItem,
}) => {
    const { t } = useTranslation()

    const [openInstructionActionModal, setOpenInstructionActionModal] = useState<InstructionActionType | undefined>()
    const [instruction, setInstruction] = useState<IRefitInstruction | undefined>()

    function handleClose() {
        setOpenInstructionActionModal(undefined)
    }

    function handleDelete() {
        deleteItem(`${REFIT_INSTRUCTIONS_ROUTE}/${instruction?.id}`)
        setOpenInstructionActionModal(undefined)
    }

    const level = InstructionCategoriesLevels.INSTRUCTIONS

    return (
        <>
            {instructions?.map((instruction: any, index: number) => (
                <InstructionCard
                    key={index}
                    instruction={instruction}
                    setOpenInstructionActionModal={setOpenInstructionActionModal}
                    setInstruction={setInstruction}
                />
            ))}
            {openInstructionActionModal && openInstructionActionModal !== InstructionActionType.DELETE && (
                <InstructionModal
                    type={actionTypeToModalType[openInstructionActionModal!]}
                    instruction={instruction}
                    handleRefresh={handleRefresh}
                    handleClose={handleClose}
                    category={selectedCategory}
                    subCategory={selectedSubCategory}
                />
            )}
            {openInstructionActionModal === InstructionActionType.DELETE && (
                <DeleteModal
                    onSubmit={handleDelete}
                    onClose={handleClose}
                    title={t(`refit.instructionCategories.modal.${level}.deleteTitle`)}
                    label={t(`refit.instructionCategories.modal.${level}.deleteLabel`)}
                    inputValue={instruction!.title}
                    tipText={t(`refit.instructionCategories.modal.${level}.deleteTip`)}
                    buttonLabel={t('refit.instructionCategories.modal.deleteButton')}
                />
            )}
        </>
    )
}

export default HandleInstructions
