import * as React from 'react'
import HowItWorks from '_organisms/HowItWorks'
import { useTranslation } from 'react-i18next'

const RefitHowItWorks: React.FC = () => {
    const { t } = useTranslation()

    return (
        <HowItWorks
            title={t('global.howItWorks.title')}
            description={t('new_refit.home.howItWorks.description')}
            instructions={[
                t('new_refit.home.howItWorks.step1'),
                t('new_refit.home.howItWorks.step2'),
                t('new_refit.home.howItWorks.step3'),
                t('new_refit.home.howItWorks.step4'),
            ]}
        />
    )
}

export default RefitHowItWorks
