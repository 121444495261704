import { useTheme } from '@material-ui/core'
import { IUniqueProduct } from 'interfaces'
import * as React from 'react'
import { useEffect, useState } from 'react'
import Confetti from 'react-dom-confetti'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { RMSFile, uploadFiles } from 'utils/files'
import { SECOND_HAND_PUBLISH_URL, SECOND_HAND_UNIQUE_PRODUCTS_URL } from 'utils/routes/backend'
import Stepper from '_organisms/Stepper'
import PublishHowItWorks from '_pages/publish/PublishHowItWorks'
import Review from '_pages/publish/Review'
import Shooting from '_pages/publish/Shooting'
import usePrismicQualitiesStore, { PrismicQualitiesState } from '../utils/store/usePrismicQualities.store'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const Publish: React.FC = () => {
    const theme = useTheme()
    const [uniqueProduct, setUniqueProduct] = useState<IUniqueProduct | undefined>(undefined)
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()

    const [pictures, setPictures] = useState<RMSFile[]>([])
    const [uniqueProductNote, setUniqueProductNote] = useState<string>('')
    const [congrats, setCongrats] = useState(false)
    const [currentStep, setCurrentStep] = useState<number>(0)
    const loadPrismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.loadPrismicQualities)
    const isShootingOptional = !!uniqueProduct?.productReference.product?.customer?.bypassSecondHandPhotos
    const [setDisabledSelectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.setDisabledSelectedCustomers,
    ])
    const [setDisabledSelectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.setDisabledSelectedWarehouse,
    ])

    const confettiConf = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: '10px',
        height: '10px',
        perspective: '500px',
        left: '50%',
        colors: [theme.palette.primary.main, theme.palette.secondary.main],
    }

    useEffect(() => {
        if (congrats) toast.success(t('publish.congrats'))
        // eslint-disable-next-line
    }, [congrats])

    useEffect(() => {
        if (uniqueProduct) loadPrismicQualities(uniqueProduct.productReference.product!.customer!.prismicTemplate!)
        if (uniqueProduct !== undefined) {
            setDisabledSelectedCustomers(true)
            setDisabledSelectedWarehouse(true)
        } else {
            setDisabledSelectedCustomers(false)
            setDisabledSelectedWarehouse(false)
        }
    }, [uniqueProduct])

    async function fetchUniqueProduct(barcodeUid: string) {
        if (!barcodeUid) return
        toast.info(t('publish.scan.loading'))

        try {
            const response = await rmsApi.get(SECOND_HAND_UNIQUE_PRODUCTS_URL(barcodeUid!))
            toast.dismiss()
            setUniqueProduct(response.data)
        } catch (e: any) {
            toast.dismiss()
            if (e?.response?.status === 404) toast.error(t(`publish.scan.${e.response.status}`, { barcodeUid }))
            else toast.error(t('publish.scan.unknownError', { barcodeUid }))
        }
    }

    const resetPublishAction = () => {
        setUniqueProduct(undefined)
        setUniqueProductNote('')
        setPictures([])
        if (currentStep > 0) setCurrentStep(0)
    }

    const onSubmit = async () => {
        try {
            await uploadFiles(pictures)
        } catch (e: any) {
            toast.error(t('files.uploadError', { message: e.message }))
            return
        }

        try {
            await rmsApi.post(SECOND_HAND_PUBLISH_URL(uniqueProduct!.barcodeUid!), {
                photos: pictures.map((picture: RMSFile) => ({
                    name: picture.remoteName,
                    type: picture.type,
                    uniqueProductId: uniqueProduct!.id,
                })),
                note: uniqueProductNote,
            })
            resetPublishAction()
            setCongrats(true)
        } catch (e) {
            toast.error(
                buildErrorMessage(e, { barcodeUid: uniqueProduct!.barcodeUid! }, 'wms.uniqueProducts.failedToLoad'),
            )
        }
    }

    return (
        <>
            <div style={{ position: 'relative', left: '50%' }}>
                <Confetti active={congrats} config={confettiConf} />
            </div>
            {!uniqueProduct ? (
                <PublishHowItWorks scanInput={fetchUniqueProduct} />
            ) : (
                <Stepper
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    onSubmit={onSubmit}
                    onCancel={resetPublishAction}
                >
                    <Shooting
                        pictures={pictures}
                        setPictures={setPictures}
                        isOptional={isShootingOptional}
                        customerIdPrivateBucket={uniqueProduct?.productReference?.product?.customer?.id}
                    />
                    <Review
                        pictures={pictures}
                        uniqueProduct={uniqueProduct}
                        updateComment={setUniqueProductNote}
                        setUniqueProduct={setUniqueProduct}
                    />
                </Stepper>
            )}
        </>
    )
}

export default Publish
