import * as React from 'react'
import { createStyles, DialogContent, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TitledHr from '../reusable/TitledHr'
import ProductVariantDetail from '../reusable/ProductVariantDetail'
import ArrowSplitVerticalIcon from 'mdi-react/ArrowSplitVerticalIcon'
import PaletteIcon from 'mdi-react/PaletteIcon'
import AndroidStudioIcon from 'mdi-react/AndroidStudioIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            background: theme.palette.common.white,
            padding: theme.spacing(0.5, 2, 1),
            maxHeight: '100%',
            overflow: 'unset',
        },
        variantDetailContainer: {
            marginLeft: '20px',
        },
    }),
)

type ProductVariationProps = {
    color?: string | null
    size?: string | null
}

const ProductReferenceVariationsSection: React.FC<ProductVariationProps> = ({ color, size }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <DialogContent className={classes.dialogContent}>
            <TitledHr title={t('wms.productReferences.variations')} mdiIcon={<AndroidStudioIcon />} />
            <div className={classes.variantDetailContainer}>
                {size && (
                    <ProductVariantDetail
                        label={t('wms.productReferences.size')}
                        value={size}
                        icon={<ArrowSplitVerticalIcon />}
                    />
                )}
                {color && (
                    <ProductVariantDetail
                        label={t('wms.productReferences.color')}
                        value={color}
                        icon={<PaletteIcon />}
                    />
                )}
            </div>
        </DialogContent>
    )
}

export default ProductReferenceVariationsSection
