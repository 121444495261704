import * as React from 'react'
import { ButtonGroup as MuiButtonGroup } from '@material-ui/core'
import Button from '_atoms/buttons/Button'

type ButtonGroupProps = {
    options: { value: string; label: string }[]
    value: string
    setValue: (value: string) => void
    className?: string
    buttonClass?: string
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ options, value, setValue, className, buttonClass }) => {
    return (
        <MuiButtonGroup className={className}>
            {options.map((option) => (
                <Button
                    size="small"
                    variant={option.value === value ? 'fill' : 'stroke'}
                    color={option.value === value ? 'primary' : 'neutral'}
                    key={option.value}
                    label={option.label}
                    onClick={() => setValue(option.value)}
                    className={buttonClass}
                    data-testid={`buttonGroup_${option.value}`}
                />
            ))}
        </MuiButtonGroup>
    )
}

export default ButtonGroup
