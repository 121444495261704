import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import Tips from '_atoms/containers/Tips'
import { ICustomer } from 'interfaces/Customer'
import { IInstructionGroup, InstructionGroupActionType } from 'interfaces/RefitInstructionGroup.interface'
import { useTranslation } from 'react-i18next'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import ModalFooter from '_molecules/ModalFooter'
import InputWithLabel from '_molecules/InputWithLabel'
import InputField from '_atoms/inputs/InputField'
import { JuneEvent, trackJuneEvent } from 'utils/june'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nameContainer: {
            marginBottom: theme.space(4),
        },
        descriptionContainer: {
            marginBottom: theme.space(6),
        },
    }),
)

function getTitleByAction(t: any) {
    return {
        [InstructionGroupActionType.CREATE]: t('refit.instructionGroups.createOrEditGroup.create'),
        [InstructionGroupActionType.CLONE]: t('refit.instructionGroups.createOrEditGroup.clone'),
        [InstructionGroupActionType.EDIT]: t('refit.instructionGroups.createOrEditGroup.edit'),
        [InstructionGroupActionType.DELETE]: '',
    }
}

type CreateOrEditGroupModalModalProps = {
    isOpen?: boolean
    group?: IInstructionGroup
    action: InstructionGroupActionType
    customer: ICustomer | undefined
    onSubmit: (group: IInstructionGroup, action: InstructionGroupActionType) => any
    onClose: () => void
}

const CreateOrEditGroupModal: React.FC<CreateOrEditGroupModalModalProps> = ({
    isOpen = true,
    group,
    action,
    customer,
    onSubmit,
    onClose,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [name, setName] = useState<string>(
        action === InstructionGroupActionType.CLONE ? `${group?.name} (${t('global.copied')})` : group?.name || '',
    )
    const [description, setDescription] = useState<string>(group?.description || '')
    const [addMore, setAddMore] = useState<boolean>(false)

    function validate(name: string, description: string) {
        onSubmit({ id: group?.id, name, description }, action)
        setName('')
        setDescription('')
        if (!addMore) {
            onClose()
        }
        setAddMore(false)

        if (action === InstructionGroupActionType.CREATE) {
            trackJuneEvent(JuneEvent.NEW_REFIT_GROUP)
        }
    }

    const title = getTitleByAction(t)[action]

    return (
        <ModalWithHeader title={title} headerType={HeaderType.DIVIDER} open={isOpen} handleClose={onClose} width={600}>
            <InputWithLabel
                label={t('refit.instructionGroups.createOrEditGroup.groupNameLabel')}
                className={classes.nameContainer}
            >
                <InputField
                    fullWidth
                    placeholder={t('refit.instructionGroups.createOrEditGroup.namePlaceholder')}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                    autoFocus
                    data-testid={'groupNameField'}
                />
            </InputWithLabel>
            <InputWithLabel
                label={t('refit.instructionGroups.createOrEditGroup.groupDescriptionLabel')}
                className={classes.descriptionContainer}
            >
                <InputField
                    multiline={true}
                    rows={3}
                    fullWidth={true}
                    placeholder={t('refit.instructionGroups.createOrEditGroup.descriptionPlaceholder')}
                    value={description}
                    onChange={(e: any) => setDescription(e.target.value)}
                    data-testid={'groupDescriptionField'}
                />
            </InputWithLabel>
            <Tips>
                {t('refit.instructionGroups.createOrEditGroup.forCustomer')} <strong>{customer?.name}</strong>
            </Tips>
            <ModalFooter
                label={t('refit.instructionGroups.createOrEditGroup.validate')}
                submitDisabled={!name}
                onSubmit={() => validate(name, description)}
                onCancel={onClose}
                leftWidget={
                    !group && (
                        <BasicCheckbox
                            label={t('refit.instructionGroups.createOrEditGroup.addMoreCheckboxLabel')}
                            setValue={setAddMore}
                            checked={addMore}
                            dataTestId="createAnotherGroup"
                        />
                    )
                }
            />
        </ModalWithHeader>
    )
}

export default CreateOrEditGroupModal
