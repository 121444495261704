import create from 'zustand/vanilla'
import { ICustomer } from 'interfaces/Customer'
import { persist } from 'zustand/middleware'

export interface SelectedCustomersState {
    selectedCustomers: ICustomer[] | []
    disabledSelectedCustomers: boolean
    shouldDisplaySecondHandElements: () => boolean
    setSelectedCustomers: (customers: ICustomer[] | []) => void
    setDisabledSelectedCustomers: (disabledSelectedCustomers: boolean) => void
}

const selectedCustomersStore = create<SelectedCustomersState>()(
    persist(
        (set, get) => ({
            selectedCustomers: [],
            disabledSelectedCustomers: false,
            shouldDisplaySecondHandElements: () => {
                return get().selectedCustomers.some((customer: ICustomer) => customer.secondHand)
            },
            setSelectedCustomers: (selectedCustomers: ICustomer[] | []) => set({ selectedCustomers }),
            setDisabledSelectedCustomers: (disabledSelectedCustomers: boolean) => set({ disabledSelectedCustomers }),
        }),
        {
            name: 'selectedCustomers',
        },
    ),
)

export default selectedCustomersStore
