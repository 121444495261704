import * as React from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { faArrowRight, faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import ModalFooter from '_molecules/ModalFooter'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            color: theme.palette.neutral[600],
        },
        footer: {
            [theme.breakpoints.up('md')]: {
                padding: theme.space(10, 0, 0, 0),
            },
        },
    }),
)

type StartAutonomousInstructionModalProps = {
    onClose: () => void
    onStart: () => void
    onIgnore: () => void
    instructionTitle: string
}

const StartAutonomousInstructionModal: React.FC<StartAutonomousInstructionModalProps> = ({
    onClose,
    onStart,
    onIgnore,
    instructionTitle,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const modalTitle = `${t('new_refit.home.awaitingStartOrIgnore.waiting')} : ${instructionTitle}`

    return (
        <ModalWithHeader
            title={modalTitle}
            tagline={t('new_refit.home.awaitingStartOrIgnore.instruction')}
            open={true}
            width={520}
            handleClose={onClose}
            disableRestoreFocus
            headerType={HeaderType.MOBILE}
            headerIcon={faCircleExclamation}
            headerIconColor={theme.palette.neutral[900]!}
            withDesktopTagline
        >
            <ModalFooter
                className={classes.footer}
                label={t('new_refit.home.awaitingStartOrIgnore.start')}
                cancelLabel={t('new_refit.home.awaitingStartOrIgnore.ignore')}
                onCancel={onIgnore}
                onSubmit={onStart}
                endIcon={faArrowRight}
                withDivider={false}
            />
        </ModalWithHeader>
    )
}

export default StartAutonomousInstructionModal
