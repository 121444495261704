import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IconButton, Divider } from '@material-ui/core'
import Text from '_atoms/text/Text'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HeaderProps } from './Header'
import useIsDrawerOpenStore, { IsDrawerOpenState } from 'utils/store/useIsDrawerOpen.store'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            padding: theme.space(7, 12, 6, 12),
        },
        homepageHeaderContainer: {
            padding: theme.space(7, 12, 12, 12),
        },
        titleRoot: {
            display: 'flex',
            alignItems: 'center',
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            height: theme.space(9),
            flex: 1,
        },
        section: {
            color: theme.palette.neutral[600],
            fontWeight: 300,
        },
        sectionDivider: {
            fontSize: theme.typography.pxToRem(30),
            color: theme.palette.neutral[200],
            padding: theme.space(0, 3),
            transform: 'scaleX(0.5) rotate(15deg)',
            fontWeight: 250,
        },
        title: {
            fontWeight: 600,
            color: theme.palette.neutral[800],
        },
        homepageTitle: {
            margin: 'auto',
            color: theme.palette.primary[600],
        },
        toggleNavTitle: {
            marginLeft: theme.space(9.5),
        },
        previous: {
            marginRight: theme.space(2),
            '& svg': {
                color: theme.palette.neutral[600],
                width: theme.space(3),
                height: 'auto',
            },
        },
        tagline: {
            marginTop: theme.space(6),
            color: theme.palette.neutral[800],
            opacity: 0.75,
        },
        homepageTagline: {
            marginTop: theme.space(2),
            textAlign: 'center',
            color: theme.palette.primary[400],
        },
        toggleNavButton: {
            marginLeft: theme.space(-14.5),
            width: theme.space(5),
            height: theme.space(5),
            backgroundColor: theme.palette.secondary[600],
            padding: 0,
            borderRadius: '50%',
            zIndex: 1250,
            '& svg': {
                color: theme.palette.secondary[900],
                fontSize: theme.typography.pxToRem(16),
            },
        },
        titleBloc: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
    }),
)

const HeaderDesktop: React.FC<HeaderProps> = ({
    title,
    section,
    tagline,
    handlePrevious,
    rightAddon,
    isHomepage = false,
    withDivider = true,
    withToggleNav = true,
}) => {
    const classes = useStyles()

    const [isDrawerOpen, setIsDrawerOpen] = useIsDrawerOpenStore((state: IsDrawerOpenState) => [
        state.isDrawerOpen,
        state.setIsDrawerOpen,
    ])

    return (
        <div>
            <div className={`${classes.headerContainer} ${isHomepage ? classes.homepageHeaderContainer : ''}`}>
                <div className={classes.titleRoot}>
                    {withToggleNav && (
                        <Button
                            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                            className={classes.toggleNavButton}
                            variant="fill"
                            color="secondary"
                        >
                            {isDrawerOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                        </Button>
                    )}
                    <div className={`${classes.titleContainer} ${withToggleNav ? classes.toggleNavTitle : ''}`}>
                        {handlePrevious && (
                            <IconButton
                                onClick={handlePrevious}
                                data-testid="previousStep"
                                className={classes.previous}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </IconButton>
                        )}
                        {section && (
                            <>
                                <Text variant="P3" className={classes.section}>
                                    {section}
                                </Text>
                                <div className={classes.sectionDivider}>/</div>
                            </>
                        )}
                        <Text
                            variant={isHomepage ? 'H1' : 'H5'}
                            className={`${classes.title} ${isHomepage ? classes.homepageTitle : ''}`}
                        >
                            {title}
                        </Text>
                    </div>
                    {rightAddon?.addon}
                </div>
                {tagline && (
                    <Text
                        variant={isHomepage ? 'P3' : 'P5'}
                        className={`${classes.tagline} ${isHomepage ? classes.homepageTagline : ''}`}
                    >
                        {tagline}
                    </Text>
                )}
            </div>
            {withDivider && <Divider />}
        </div>
    )
}

export default HeaderDesktop
