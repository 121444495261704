import * as React from 'react'
import { useState } from 'react'
import { InstructionCategoriesLevels } from '../InstructionsManagement'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { REFIT_CATEGORIES_ROUTE } from 'utils/routes/backend'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import ModalFooter from '_molecules/ModalFooter'
import InputWithLabel from '_molecules/InputWithLabel'
import InputField from '_atoms/inputs/InputField'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import { IRefitCategory } from 'interfaces'

type ManageCategoryModalProps = {
    parentId?: number
    category?: IRefitCategory
    isOpen?: boolean
    handleClose: () => void
    handleRefresh: () => void
}

const ManageCategoryModal: React.FC<ManageCategoryModalProps> = ({
    category,
    isOpen = true,
    handleClose,
    handleRefresh,
    parentId,
}) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState<string>(category?.title || '')
    const [addMore, setAddMore] = useState<boolean>(false)

    const [selectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])

    async function submit() {
        try {
            if (category) {
                await rmsApi.patch(`${REFIT_CATEGORIES_ROUTE}/${category.id}`, { title })
            } else {
                if (!parentId) {
                    await rmsApi.post(`${REFIT_CATEGORIES_ROUTE}?customerId=${selectedCustomer?.id}`, { title })
                } else {
                    await rmsApi.post(`${REFIT_CATEGORIES_ROUTE}/${parentId}/sub-categories`, { title })
                }
            }

            setTitle('')

            if (!addMore) {
                handleClose()
            }
            setAddMore(false)
            handleRefresh()

            toast.success(
                t(
                    `refit.instructionCategories.callbackMessages.${
                        category ? 'categorySuccessfullyModified' : 'categorySuccessfullyCreated'
                    }`,
                ),
            )
        } catch (e: any) {}
    }

    const level = parentId ? InstructionCategoriesLevels.SUB_CATEGORIES : InstructionCategoriesLevels.CATEGORIES

    return (
        <ModalWithHeader
            title={
                category
                    ? t(`refit.instructionCategories.modal.${level}.editTitle`)
                    : t(`refit.instructionCategories.modal.${level}.addTitle`)
            }
            headerType={HeaderType.DIVIDER}
            open={isOpen}
            handleClose={handleClose}
            width={600}
        >
            <InputWithLabel label={t(`refit.instructionCategories.modal.${level}.titleLabel`)}>
                <InputField
                    fullWidth={true}
                    value={title || ''}
                    onChange={(e: any) => {
                        setTitle(e.target.value)
                    }}
                    data-testid="categoryModal-title"
                    placeholder={t(`refit.instructionCategories.modal.${level}.titlePlaceholder`)}
                    autoFocus
                />
            </InputWithLabel>
            <ModalFooter
                label={
                    category
                        ? t(`refit.instructionCategories.modal.editButton`)
                        : t(`refit.instructionCategories.modal.createButton`)
                }
                submitDisabled={title.length === 0 || title === category?.title}
                onSubmit={submit}
                onCancel={handleClose}
                leftWidget={
                    !category ? (
                        <BasicCheckbox
                            label={t(`refit.instructionCategories.modal.${level}.addMoreCheckboxLabel`)}
                            setValue={setAddMore}
                            checked={addMore}
                            dataTestId="createAnotherCategory"
                        />
                    ) : (
                        <></>
                    )
                }
            />
        </ModalWithHeader>
    )
}

export default ManageCategoryModal
