import * as React from 'react'
import ReportIssueModal from '_organisms/ReportIssueModal'
import { IUniqueProduct } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { EntityType, RMSFile } from 'utils/files'

export type ProductIssueModalProps = {
    isOpen: boolean
    uniqueProduct: IUniqueProduct | undefined
    handleClose: () => void
    handleSubmit: (comment: string, selectedIssues: number[], files: RMSFile[]) => void
}

const ProductIssueModal: React.FC<ProductIssueModalProps> = ({ isOpen, uniqueProduct, handleClose, handleSubmit }) => {
    const { t } = useTranslation()

    const productIssues = uniqueProduct?.productReference?.product?.customer?.productIssues

    const returnItem = uniqueProduct?.returnItems?.length ? uniqueProduct?.returnItems[0] : undefined
    const initialFiles = (returnItem?.files || []) as RMSFile[]
    const initialComment = returnItem?.comment || ''
    const initialIssues = returnItem?.productIssues || []

    return (
        <ReportIssueModal
            isOpen={isOpen}
            tagline={t('reportIssue.tagline.product')}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            initialFiles={initialFiles}
            initialComment={initialComment}
            initialIssues={initialIssues}
            issueOptions={productIssues}
            entityType={EntityType.RETURN_ITEM_OR_RETURN_PACKAGING}
        />
    )
}

export default ProductIssueModal
