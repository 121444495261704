import * as React from 'react'
import FitText from '@kennethormandy/react-fittext'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import Card from '../reusable/Card'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardFigure: {
            fontSize: theme.typography.pxToRem(48),
            lineHeight: 1,
            '& span': {
                color: theme.palette.secondary.main, // ie11 fallback
                background: `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            },
            '& small': {
                fontSize: '50%',
                fontWeight: 400,
                color: theme.palette.grey[600],
            },
        },
        cardFigureExtra: {
            fontSize: theme.typography.pxToRem(32),
            color: theme.palette.grey[600],
            lineHeight: 1,
            fontWeight: 700,
            '& span': {},
            '& small': {
                fontSize: '50%',
            },
        },
    }),
)

type StatCardProps = {
    title: string
    mainValue: number | string | undefined
    secondaryValue?: number | string | undefined
    size: 'big' | 'normal'
    currency?: string
    suffix?: 'TTC'
    extraSuffix?: 'HT'
    loading: boolean
}

const StatCard: React.FC<StatCardProps> = ({
    title,
    size,
    mainValue,
    secondaryValue,
    currency = '€',
    suffix,
    extraSuffix,
    loading = true,
}) => {
    const classes = useStyles()

    return (
        <Grid item xs={12} md={6} lg={size === 'big' ? 5 : 3}>
            <Card title={title} loading={loading}>
                <Typography variant="h2" component="h5" noWrap className={`figure ${classes.cardFigure}`}>
                    <FitText compressor={0.5} minFontSize={24} maxFontSize={48}>
                        <>
                            <span>
                                {mainValue} {currency && currency}{' '}
                            </span>
                            <small>{suffix}</small>
                        </>
                    </FitText>
                </Typography>
                {secondaryValue && (
                    <Typography component="div" noWrap className={classes.cardFigureExtra}>
                        <FitText compressor={0.5} minFontSize={16} maxFontSize={32}>
                            <>
                                <span>
                                    {secondaryValue}
                                    {currency && currency}{' '}
                                </span>
                                <small>{extraSuffix}</small>
                            </>
                        </FitText>
                    </Typography>
                )}
            </Card>
        </Grid>
    )
}

export default StatCard
