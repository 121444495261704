import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { Theme } from '@material-ui/core/styles'

export enum StepBulletStatus {
    DEFAULT = 'DEFAULT',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        stepChip: {
            textAlign: 'center',
            borderRadius: theme.space(2),
            height: theme.space(2.2),
            width: theme.space(2.2),
            fontWeight: 300,
            fontSize: theme.typography.pxToRem(11),
        },
        step: {
            width: theme.space(4),
            height: theme.space(4),
            lineHeight: theme.typography.pxToRem(20),
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary[600],
        },
        completed: {
            padding: theme.space(4.5 / 5),
            color: theme.palette.success[600],
            backgroundColor: theme.palette.success[50],
        },
        canceled: {
            padding: theme.space(4.5 / 5),
            color: theme.palette.error[600],
            backgroundColor: theme.palette.error[50],
        },
    }),
)

type StepBulletProps = {
    step: number
    status?: StepBulletStatus
}

const StepBullet: React.FC<StepBulletProps> = ({ step, status = StepBulletStatus.DEFAULT }) => {
    const classes = useStyles()
    if (status === StepBulletStatus.SUCCESS)
        return <FontAwesomeIcon icon={faCheck} className={`${classes.stepChip} ${classes.completed}`} />
    if (status === StepBulletStatus.ERROR)
        return <FontAwesomeIcon icon={faXmark} className={`${classes.stepChip} ${classes.cancelled}`} />
    return <div className={`${classes.stepChip} ${classes.step}`}>{step}</div>
}

export default StepBullet
