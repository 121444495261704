import { AxiosResponse } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import * as React from 'react'
import { toast } from 'utils/toast'
import theme from '../../../theme'
import rmsApi from 'utils/api'

export function getStatsStyles(): any {
    return {
        root: {
            '& a': {
                textDecoration: 'none',
            },
            '& .MuiTable-root': {
                '& .MuiFormControl-root': {
                    verticalAlign: 'middle',
                    marginRight: theme.spacing(2),
                },
            },
        },
        tabsBox: {
            [theme.breakpoints.down('xs')]: {
                margin: 0,
            },
            borderBottom: '1px solid #e8e8e8',
        },
        tabs: {
            [theme.breakpoints.down('xs')]: {
                margin: 0,
            },
            '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: theme.typography.pxToRem(14),
            },
        },
        tabContent: {
            '& .MuiTypography-body1': {
                color: theme.palette.grey[600],
                fontSize: theme.typography.pxToRem(12),
            },
            '& .MuiGrid-container': {
                marginBottom: theme.spacing(2),
                '&:nth-of-type(1)': {
                    marginBottom: theme.spacing(6),
                },
            },
            '& .MuiGrid-item > *': {
                margin: 'auto',
                flexGrow: 1,
            },
        },
        tabContentTitle: {
            fontWeight: 100,
            marginBottom: theme.spacing(2),
        },
        dateRangePicker: {
            marginTop: theme.spacing(5),
        },
        chartContainer: {
            position: 'relative',
            minWidth: 250,
            maxWidth: 500,
            height: 180,
            margin: 'auto',
        },
        lineChartContainer: {
            minWidth: theme.breakpoints.width('xs'),
            maxWidth: theme.breakpoints.width('md'),
            height: 250,
            margin: 'auto',
        },
        barChartContainer: {
            position: 'relative',
            minWidth: 250,
            height: 230,
        },
        verticalBarChartContainer: {
            position: 'relative',
            minWidth: 250,
            height: 300,
        },
        spacer: {
            width: '100%',
            height: theme.spacing(2),
        },
        biggerSpacer: {
            width: '100%',
            height: theme.spacing(6),
        },
        gridContainer: {
            backgroundColor: theme.palette.primary[50],
            borderRadius: 5,
            marginTop: theme.spacing(2),
        },
        mainExportButton: {
            width: 'auto',
            height: 20,
            padding: theme.spacing(2),
            background: 'rgba(47, 72, 209, 0.75)',
            color: theme.palette.common.white,
            textTransform: 'uppercase',
            fontSize: 11,
            borderRadius: 2,
            alignSelf: 'end',
            '&:hover, &:focus': {
                background: 'rgba(47, 72, 209, 1)',
            },
            '& svg': {
                width: 16,
                marginRight: theme.spacing(1),
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        mainExportButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        accessUniqueProductsListLink: {
            color: theme.palette.primary[600],
            fontSize: theme.typography.pxToRem(12),
            paddingTop: theme.spacing(1),
            alignSelf: 'end',
        },
        matomoLinkContainer: {
            display: 'flex',
            alignItems: 'baseline',
        },
        matomoLink: {
            color: theme.palette.primary[600],
            fontSize: theme.typography.pxToRem(14),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            fontWeight: 500,
        },
        matomoLinkIcon: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        customerSelectMargins: {
            display: 'flex',
            justifyContent: 'end',
            margin: '0 0 8px 5px',
        },
    }
}

export function getDoughnutOptions(): any {
    return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 50,
        borderRadius: 5,
        plugins: {
            legend: {
                position: 'left',
                labels: {
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: {
                        size: 11,
                    },
                },
                align: 'end',
            },
            title: {
                display: true,
                position: 'top',
                padding: {
                    top: 0,
                    left: 0,
                    bottom: 10,
                },
            },
            tooltip: {
                enabled: true,
                usePointStyle: true,
                callbacks: {
                    label: (context: any) => {
                        const currentValue = context.raw,
                            total = context.chart._metasets[context.datasetIndex].total

                        const percentage = parseFloat(((currentValue / total) * 100).toFixed(2))

                        return ' ' + currentValue + ' (' + percentage + '%)'
                    },
                },
            },
        },
    }
}

export function getVerticalBarChartOptions(): any {
    return {
        responsive: true,
        maintainAspectRatio: false,
        borderRadius: 5,
        barPercentage: 0.2,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: {
                        size: 11,
                    },
                },
            },
            title: {
                display: true,
                text: undefined,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    // This function allows to display all datasets labels and values on hover
                    // Thanks to https://stackoverflow.com/a/43965785
                    label: function (t: any, d: any) {
                        if (t.datasetIndex === 0) {
                            return '$' + t.yLabel.toFixed(2)
                        } else if (t.datasetIndex === 1) {
                            if (t.yLabel.toString().length === 9) {
                                return Math.round(+t.yLabel.toString().replace(/(\d{3})(.*)/, '$1.$2')) + 'M'
                            } else return Math.round(+t.yLabel.toString().replace(/(\d{2})(.*)/, '$1.$2')) + 'M'
                        }
                    },
                },
            },
        },
    }
}

export function getHorizontalBarChartOptions(): any {
    return {
        indexAxis: 'y' as const,
        maintainAspectRatio: false,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Title',
            },
        },
    }
}

export function getLineChartOptions(): any {
    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: {
                        size: 11,
                    },
                },
            },
            title: {
                display: true,
                text: 'title',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    // This function allows to display all datasets labels and values on hover
                    // Thanks to https://stackoverflow.com/a/43965785
                    label: function (t: any, d: any) {
                        if (t.datasetIndex === 0) {
                            return '$' + t.yLabel.toFixed(2)
                        } else if (t.datasetIndex === 1) {
                            if (t.yLabel.toString().length === 9) {
                                return Math.round(+t.yLabel.toString().replace(/(\d{3})(.*)/, '$1.$2')) + 'M'
                            } else return Math.round(+t.yLabel.toString().replace(/(\d{2})(.*)/, '$1.$2')) + 'M'
                        }
                    },
                },
            },
        },
    }
}

export function getChartsColors(): string[] {
    return [
        chartColorBlue,
        theme.palette.primary[400],
        theme.palette.primary[300],
        chartColorMiddleBlue,
        chartColorRed,
        theme.palette.secondary[500],
        theme.palette.secondary[300],
        chartColorMiddleRed,
    ]
}

export function getChartsColorsSecondHand(): string[] {
    return [
        chartColorBlue,
        theme.palette.primary[300],
        chartColorMiddleBlue,
        chartColorRed,
        theme.palette.secondary[300],
        chartColorMiddleRed,
    ]
}

export const chartColorBlue = theme.palette.primary[600]
export const chartColorMiddleBlue = theme.palette.primary[100]
export const chartColorMiddleRed = theme.palette.secondary[100]
export const chartColorRed = theme.palette.secondary[600]
export const chartColorGrey = '#aaa'
export const chartColorWhite = '#fff'

export async function fetchStats(
    setIsLoading: (value: React.SetStateAction<string | boolean>) => void,
    statsUrl: string,
    formatStatsData: any,
    startDate?: Dayjs,
    endDate?: Dayjs,
) {
    try {
        setIsLoading(true)
        let params = ''

        if (startDate && endDate) {
            const from = dayjs(startDate).format('YYYY-MM-DD')
            const to = dayjs(endDate).format('YYYY-MM-DD')
            params = `?from=${from}&to=${to}`
        }

        statsUrl += params

        const statsRequest: AxiosResponse = await rmsApi.get(statsUrl)
        formatStatsData(statsRequest.data)
    } catch (e: any) {
        setIsLoading('error')
        toast.error(e)
    }
}

export function sortByNumberDescending<T extends { [key: string]: number }>(arr: T[], property: keyof T) {
    return arr.sort((a: T, b: T) => b[property] - a[property])
}
