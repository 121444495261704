import * as React from 'react'
import { faCamera } from '@fortawesome/pro-regular-svg-icons'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons'
import FileInput from '_atoms/inputs/FileInput'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            border: `1px dashed ${theme.palette.neutral[200]}`,
            color: theme.palette.neutral[600],
            background: theme.palette.neutral[50],
            fontSize: `${theme.typography.pxToRem(11)} !important`,
            padding: `${theme.spacing(3)}px !important`,
            boxShadow: 'unset',
            '&:hover:enabled': {
                backgroundColor: '#F8F9FF',
            },
        },
        icon: {
            fontSize: theme.typography.pxToRem(24),
            color: theme.palette.neutral[300],
        },
    }),
)

type FileButtonProps = {
    text: string
    handleFile: (files: File[]) => void
    multiple?: boolean
    className?: string
    disabled?: boolean
    accept?: string
}

const FileButton: React.FC<FileButtonProps> = ({ text, handleFile, multiple, className, disabled = false, accept }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

    function fileManagement(files: File[]) {
        handleFile(files)
    }

    return (
        <Button
            color="neutral"
            component="label"
            startIcon={isMobile ? faCamera : faCloudArrowUp}
            className={`${classes.button} ${className}`}
            iconClass={classes.icon}
        >
            <FileInput multiple={multiple} handleFile={fileManagement} disabled={disabled} accept={accept} />
            {text}
        </Button>
    )
}

export default FileButton
