export const HOME_ROUTE = '/'
export const REFIT_ROUTE = '/refit'
export const REFIT_INSTRUCTION_CATEGORIES_ROUTE = '/refit-instruction-categories'
export const REFIT_INSTRUCTION_GROUPS_ROUTE = '/refit-instruction-groups'
export const ADMIN_PRODUCT_ROUTE = '/product-admin'
export const HISTORY_ROUTE = '/product-history'
export const ORDERS_ROUTE = '/orders'
export const ORDERS_DELIVERED_ROUTE = '/orders/delivered/:orderId'
export const RETURN_PARCEL_ROUTE = '/parcels'
export const FINANCES_ROUTE = '/finances'
export const INVOICES_ROUTE = '/invoices'
export const STOCK_STATS_ROUTE = '/stock-stats'
export const SHIPMENT_STATS_ROUTE = '/shipment-stats'
export const RETURN_STATS_ROUTE = '/return-stats'
export const REFIT_STATS_ROUTE = '/refit-stats'
export const LOGISTICS_ACTIVITY_STATS_ROUTE = '/logistics-activity-stats'
export const OMS_STATS_ROUTE = '/oms-stats'
export const AFTERSALES_STATS_ROUTE = '/aftersales-stats'
export const SECOND_HAND_STATS_ROUTE = '/second-hand-stats'
export const OMS_RENTAL_STATS_ROUTE = '/rental-stats'
export const WMS_INSERT_UNIQUE_PRODUCT = '/insert-product'
export const WMS_LINK_LOCATION = '/link-location'
export const WMS_LINK_LOCATION_TO_PRODUCT_ROUTE = '/link-location-to-product'
export const WMS_LINK_LOCATION_TO_PRODUCTS_ROUTE = '/link-location-to-multiple-products'
export const WMS_CHANGE_SKU_LOCATION_ROUTE = '/change-sku-location'
export const WMS_PUBLISH = '/publish'
export const WMS_UNIQUE_PRODUCT_LIST = '/unique-product-list'
export const MANAGE_UNIQUE_PRODUCT = (uniqueProductId: number | string = ':uniqueProductId') =>
    `/unique-product/${uniqueProductId}/manage`
export const WMS_PRODUCT_REFERENCE_STOCK = '/product-reference-stock'
export const APPOINTMENTS_ROUTE = '/appointments'
export const RETURNS_LIST_ROUTE = '/returns'
export const RETURNED_PARCEL_DETAILS_ROUTE = (parcelId: number | string = ':parcelId') => `/return/${parcelId}/details`
export const RETURNED_PARCEL_ROUTE = '/return'
export const FORTH_PARCEL_LIST_ROUTE = '/forth-parcels'
export const PARCEL_COLLECTION_ROUTE = '/parcel-collection'
export const DEPLOY_DEMO_ROUTE = '/deploy-rp-demo-stack'
export const TAKEBACK_DETAILS_ROUTE = '/takeback/:takebackId/details'
export const TAKEBACK_LIST_ROUTE = '/takeback'
export const TEMPLATE_ROUTE = '/design-system'
export const WEB_ANALYTICS_ROUTE = 'https://analytics.lizee.io/'
export const PICKING_ROUTE = (batchId: number | string = ':batchId') => `/picking/${batchId}`
export const PACKING_ROUTE = (batchId: number | string = ':batchId') => `/packing-batch/${batchId}`
export const ORDER_PACKING_ROUTE = (orderId: number | string = ':orderId') => `/packing-order/${orderId}`
export const PICKPACK_ISSUES_LIST_ROUTE = '/picking-packing-issues'

export const orderTabs = ['order-list', 'picking-packing', 'unitary-preparation', 'orders-to-withdraw']
export const PICKING_PACKING_ROUTE = `${ORDERS_ROUTE}#${orderTabs[1]}`

export const UNITARY_PREPARATION_ROUTE = `${ORDERS_ROUTE}#${orderTabs[2]}`
export const ORDER_UNITARY_PREPARATION_ROUTE = (preparationId: number | string = ':preparationId') =>
    `/orders/unitary-preparation/${preparationId}`
