import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChartColumn,
    faPalletBox,
    faRecycle,
    faShelves,
    faTruckRampBox,
    faHouse,
} from '@fortawesome/pro-light-svg-icons'

import {
    AFTERSALES_STATS_ROUTE,
    APPOINTMENTS_ROUTE,
    FORTH_PARCEL_LIST_ROUTE,
    HOME_ROUTE,
    INVOICES_ROUTE,
    LOGISTICS_ACTIVITY_STATS_ROUTE,
    OMS_RENTAL_STATS_ROUTE,
    OMS_STATS_ROUTE,
    ORDERS_ROUTE,
    PARCEL_COLLECTION_ROUTE,
    REFIT_INSTRUCTION_CATEGORIES_ROUTE,
    REFIT_INSTRUCTION_GROUPS_ROUTE,
    REFIT_ROUTE,
    REFIT_STATS_ROUTE,
    RETURN_PARCEL_ROUTE,
    RETURN_STATS_ROUTE,
    RETURNS_LIST_ROUTE,
    SECOND_HAND_STATS_ROUTE,
    SHIPMENT_STATS_ROUTE,
    STOCK_STATS_ROUTE,
    TAKEBACK_LIST_ROUTE,
    WEB_ANALYTICS_ROUTE,
    WMS_CHANGE_SKU_LOCATION_ROUTE,
    WMS_INSERT_UNIQUE_PRODUCT,
    WMS_LINK_LOCATION,
    WMS_PRODUCT_REFERENCE_STOCK,
    WMS_PUBLISH,
    WMS_UNIQUE_PRODUCT_LIST,
} from 'utils/routes/frontend'

import {
    ALL_ROLES,
    ALL_ROLES_BUT_ACCOUNTANT,
    BRAND_ADMIN,
    IN_STORE_RECEPTIONIST,
    IN_STORE_ROLES,
    LIZEE_SUPER_ADMIN,
    MANAGER_ROLES,
    WAREHOUSE_MANAGER,
    WAREHOUSE_ROLES,
} from 'utils/roles'

export interface RmsMenuItem {
    index?: string
    name: string
    roles: string[]
    icon?: React.ReactElement | undefined
    route?: string | undefined
    external?: boolean | undefined
    freshDeskWidget?: boolean | undefined
    disabled?: boolean | undefined
    id?: string
    submenu?: RmsMenuItem[]
}

export function buildRmsMenuCategories(
    t: any,
    shouldDisplaySecondHandElements: boolean,
    calendarDisabled: boolean,
): RmsMenuItem[] {
    return [
        {
            index: 'home',
            name: t('global.home'),
            roles: [...ALL_ROLES, IN_STORE_RECEPTIONIST],
            icon: <FontAwesomeIcon icon={faHouse} color="#fff" />,
            route: HOME_ROUTE,
        },
        {
            index: 'stock',
            name: t('menu.stock.title'),
            roles: ALL_ROLES,
            icon: <FontAwesomeIcon icon={faShelves} color="#fff" />,
            submenu: [
                {
                    name: t('menu.stock.uniqueProducts'),
                    roles: ALL_ROLES,
                    route: WMS_UNIQUE_PRODUCT_LIST,
                },
                {
                    name: t('menu.stock.addUniqueProducts'),
                    roles: WAREHOUSE_ROLES,
                    route: WMS_INSERT_UNIQUE_PRODUCT,
                },
                {
                    name: t('menu.stock.linkLocation'),
                    roles: WAREHOUSE_ROLES,
                    route: WMS_LINK_LOCATION,
                },
                {
                    name: t('menu.stock.changeLocation'),
                    roles: WAREHOUSE_ROLES,
                    route: WMS_CHANGE_SKU_LOCATION_ROUTE,
                },
                {
                    name: t('menu.stock.publish'),
                    roles: WAREHOUSE_ROLES,
                    route: WMS_PUBLISH,
                    disabled: !shouldDisplaySecondHandElements,
                },
            ],
        },
        {
            index: 'shipment',
            name: t('menu.shipmentsAndWithdrawals.title'),
            roles: [...ALL_ROLES, IN_STORE_RECEPTIONIST],
            icon: <FontAwesomeIcon icon={faTruckRampBox} color="#fff" />,
            submenu: [
                {
                    name: t('menu.shipmentsAndWithdrawals.orderManagement'),
                    roles: [...ALL_ROLES, IN_STORE_RECEPTIONIST],
                    route: ORDERS_ROUTE,
                },
                {
                    name: t('menu.shipmentsAndWithdrawals.forthParcelList'),
                    roles: ALL_ROLES,
                    route: FORTH_PARCEL_LIST_ROUTE,
                },
                {
                    name: t('menu.shipmentsAndWithdrawals.forecastRequirement'),
                    roles: ALL_ROLES_BUT_ACCOUNTANT,
                    route: WMS_PRODUCT_REFERENCE_STOCK,
                },
                {
                    name: t('menu.shipmentsAndWithdrawals.calendar'),
                    roles: [...IN_STORE_ROLES, IN_STORE_RECEPTIONIST],
                    route: APPOINTMENTS_ROUTE,
                    disabled: calendarDisabled,
                },
                {
                    name: t('menu.shipmentsAndWithdrawals.parcelCollection'),
                    roles: [LIZEE_SUPER_ADMIN, BRAND_ADMIN, WAREHOUSE_MANAGER],
                    route: PARCEL_COLLECTION_ROUTE,
                },
            ],
        },
        {
            index: 'returns',
            name: t('menu.returns.title'),
            roles: [...ALL_ROLES, IN_STORE_RECEPTIONIST],
            icon: <FontAwesomeIcon icon={faPalletBox} color="#fff" />,
            submenu: [
                {
                    name: t('menu.returns.returnList'),
                    roles: [...ALL_ROLES, IN_STORE_RECEPTIONIST],
                    route: RETURNS_LIST_ROUTE,
                },
                {
                    name: shouldDisplaySecondHandElements
                        ? t('menu.returns.registerSecondHand')
                        : t('menu.returns.register'),
                    roles: [...WAREHOUSE_ROLES, IN_STORE_RECEPTIONIST],
                    route: RETURN_PARCEL_ROUTE,
                },
                {
                    name: t('menu.takebacks.takebackList'),
                    roles: [...ALL_ROLES, IN_STORE_RECEPTIONIST],
                    route: TAKEBACK_LIST_ROUTE,
                    disabled: !shouldDisplaySecondHandElements,
                },
            ],
        },
        {
            index: 'refit',
            name: t('menu.refit.title'),
            roles: ALL_ROLES,
            icon: <FontAwesomeIcon icon={faRecycle} color="#fff" />,
            submenu: [
                {
                    name: t('menu.refit.uniqueProductsToRefit'),
                    roles: ALL_ROLES,
                    // route: 'TODO', // todo
                },
                {
                    name: t('menu.refit.refit'),
                    roles: WAREHOUSE_ROLES,
                    route: REFIT_ROUTE,
                },
                {
                    name: t('menu.refit.handleInstructionCategories'),
                    roles: MANAGER_ROLES,
                    route: REFIT_INSTRUCTION_CATEGORIES_ROUTE,
                },
                {
                    name: t('menu.refit.instructionGroups'),
                    roles: MANAGER_ROLES,
                    route: REFIT_INSTRUCTION_GROUPS_ROUTE,
                },
            ],
        },
        {
            index: 'analytics',
            name: t('menu.analytics.title'),
            roles: ALL_ROLES,
            icon: <FontAwesomeIcon icon={faChartColumn} color="#fff" />,
            submenu: [
                {
                    name: t('menu.analytics.margin'),
                    roles: MANAGER_ROLES,
                    // route: 'TODO', // todo sylius
                },
                {
                    name: t('menu.analytics.customerPerformance'),
                    roles: ALL_ROLES,
                    // route: CUSTOMER_PERFORMANCE_ROUTE, // todo to replace when the real page is done
                },
                {
                    name: t('menu.analytics.servicePerformance'),
                    roles: ALL_ROLES,
                    // route: CUSTOMER_SERVICE_PERFORMANCE_ROUTE, // todo to delete when the real page is done
                },
                {
                    name: t('menu.analytics.finances'),
                    roles: ALL_ROLES,
                    // route: FINANCES_ROUTE,
                },
                {
                    name: t('menu.analytics.stock'),
                    roles: ALL_ROLES,
                    route: STOCK_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.logisticsActivity'),
                    roles: ALL_ROLES,
                    route: LOGISTICS_ACTIVITY_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.shipmentsAndWithdrawals'),
                    roles: ALL_ROLES,
                    route: SHIPMENT_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.returns'),
                    roles: ALL_ROLES,
                    route: RETURN_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.refit'),
                    roles: ALL_ROLES,
                    route: REFIT_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.aftersales'),
                    roles: ALL_ROLES,
                    route: AFTERSALES_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.secondHand'),
                    roles: ALL_ROLES,
                    route: SECOND_HAND_STATS_ROUTE,
                    disabled: !shouldDisplaySecondHandElements,
                },
                {
                    name: t('menu.analytics.oms'),
                    roles: ALL_ROLES,
                    route: OMS_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.rental'),
                    roles: ALL_ROLES,
                    route: OMS_RENTAL_STATS_ROUTE,
                },
                {
                    name: t('menu.analytics.puidHistory'),
                    roles: ALL_ROLES,
                    // route: HISTORY_ROUTE, // todo to delete when the real page is done
                },
                {
                    name: t('menu.analytics.traffic'),
                    roles: ALL_ROLES,
                    // route: 'TODO', // todo matomo
                },
                {
                    name: t('menu.analytics.emailing'),
                    roles: ALL_ROLES,
                    // route: 'TODO', // todo sendinblue
                },
                {
                    name: t('menu.analytics.invoices'),
                    roles: MANAGER_ROLES,
                    route: INVOICES_ROUTE,
                },
                {
                    name: t('menu.analytics.web'),
                    roles: MANAGER_ROLES,
                    route: WEB_ANALYTICS_ROUTE,
                    external: true,
                },
            ],
        },
    ]
}
