import * as React from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { IParcel } from 'interfaces'
import WhiteAndBluePartsCard from '_molecules/WhiteAndBluePartsCard'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import Text from '_atoms/text/Text'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            padding: theme.space(0, 5),
            marginBottom: theme.space(5),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.space(3),
            },
        },
        whiteAndBlueCard: {
            marginBottom: theme.space(2),
        },
        whitePart: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: theme.space(10),
            padding: theme.space(3),
            color: theme.palette.neutral[800],
        },
        bluePart: {
            padding: theme.space(2, 4),
        },
        chevronIcon: {
            color: theme.palette.primary.main,
            fontStyle: 'normal',
            fontWeight: 900,
            lineHeight: 'normal',
        },
    }),
)

type SuggestedParcelListProps = {
    parcels: IParcel[] | undefined
    onPickParcel: (parcelBarcode: string) => void
}

const SuggestedParcelList: React.FC<SuggestedParcelListProps> = ({ parcels, onPickParcel }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()

    return (
        <>
            <BlueBackgroundHeader
                title={t('returnParcel.searchByProduct.SuggestedParcelListPage.title')}
                tagline={t('returnParcel.searchByProduct.SuggestedParcelListPage.tagline')}
                className={classes.sectionHeader}
            />

            {parcels?.map((parcel) => (
                <WhiteAndBluePartsCard
                    className={classes.whiteAndBlueCard}
                    key={parcel.barcode}
                    onClick={() => onPickParcel(parcel.barcode)}
                    whiteClass={classes.whitePart}
                    blueClass={classes.bluePart}
                    dataTestId="suggested-parcel-card"
                >
                    <>
                        <Text variant="S2">
                            {t('returnParcel.searchByProduct.SuggestedParcelListPage.parcelId', {
                                barcode: parcel.barcode,
                            })}
                        </Text>
                        {isMobile && <FontAwesomeIcon icon={faChevronRight} className={classes.chevronIcon} />}
                    </>
                    <>
                        <Text variant="P5">
                            {t('returnParcel.searchByProduct.SuggestedParcelListPage.shippingDate', {
                                shippingDate: parcel.shipment.shippingDate,
                            })}
                        </Text>
                        <Text variant="P5">
                            {t('returnParcel.searchByProduct.SuggestedParcelListPage.sender', {
                                name: `${parcel.shipment.renter?.firstName} ${parcel.shipment.renter?.lastName}`,
                            })}
                        </Text>
                    </>
                </WhiteAndBluePartsCard>
            ))}
        </>
    )
}

export default SuggestedParcelList
