import * as React from 'react'
import { Route, Routes as RouterRoutes } from 'react-router-dom'

// Routes
import {
    AFTERSALES_STATS_ROUTE,
    APPOINTMENTS_ROUTE,
    DEPLOY_DEMO_ROUTE,
    FINANCES_ROUTE,
    FORTH_PARCEL_LIST_ROUTE,
    HISTORY_ROUTE,
    HOME_ROUTE,
    INVOICES_ROUTE,
    LOGISTICS_ACTIVITY_STATS_ROUTE,
    MANAGE_UNIQUE_PRODUCT,
    OMS_RENTAL_STATS_ROUTE,
    OMS_STATS_ROUTE,
    ORDER_PACKING_ROUTE,
    ORDER_UNITARY_PREPARATION_ROUTE,
    ORDERS_DELIVERED_ROUTE,
    ORDERS_ROUTE,
    PACKING_ROUTE,
    PARCEL_COLLECTION_ROUTE,
    PICKING_ROUTE,
    PICKPACK_ISSUES_LIST_ROUTE,
    REFIT_INSTRUCTION_CATEGORIES_ROUTE,
    REFIT_INSTRUCTION_GROUPS_ROUTE,
    REFIT_ROUTE,
    REFIT_STATS_ROUTE,
    RETURN_PARCEL_ROUTE,
    RETURN_STATS_ROUTE,
    RETURNED_PARCEL_DETAILS_ROUTE,
    RETURNS_LIST_ROUTE,
    SECOND_HAND_STATS_ROUTE,
    SHIPMENT_STATS_ROUTE,
    STOCK_STATS_ROUTE,
    TAKEBACK_DETAILS_ROUTE,
    TAKEBACK_LIST_ROUTE,
    TEMPLATE_ROUTE,
    WMS_CHANGE_SKU_LOCATION_ROUTE,
    WMS_INSERT_UNIQUE_PRODUCT,
    WMS_LINK_LOCATION,
    WMS_LINK_LOCATION_TO_PRODUCT_ROUTE,
    WMS_LINK_LOCATION_TO_PRODUCTS_ROUTE,
    WMS_PRODUCT_REFERENCE_STOCK,
    WMS_PUBLISH,
    WMS_UNIQUE_PRODUCT_LIST,
} from 'utils/routes/frontend'
import { PrivateRoute } from 'components/auth/PrivateRoute'

// Roles
import {
    ALL_ROLES,
    ALL_ROLES_BUT_ACCOUNTANT,
    BRAND_ADMIN,
    IN_STORE_RECEPTIONIST,
    IN_STORE_ROLES,
    LIZEE_SUPER_ADMIN,
    MANAGER_ROLES,
    WAREHOUSE_MANAGER,
    WAREHOUSE_ROLES,
} from 'utils/roles'

// Apps
import Home from '_pages/home/Home'
import FinancesApp from './FinancesApp'
import InvoicesApp from './InvoicesApp'
import StockStatsApp from '../_pages/stats/StockStatsApp'
import ShipmentStatsApp from '../_pages/stats/ShipmentStatsApp'
import ReturnStatsApp from '../_pages/stats/ReturnStatsApp'
import RefitStatsApp from '../_pages/stats/RefitStatsApp'
import OrderManagementStatsApp from '../_pages/stats/OrderManagementStatsApp'
import HistoryApp from './HistoryApp'
import OrderDeliveredApp from './OrderDeliveredApp'
import LinkLocation from './LinkLocation'
import LinkLocationBulk from './LinkLocationBulk'
import LinkLocationIntermediaryPage from './LinkLocationIntermediaryPage'
import ChangeSkuLocation from './ChangeSkuLocation'
import StockForecastTable from '_pages/stockForecastTable/StockForecastTable'
import Appointments from './Appointments'
import RentalStatsApp from '../_pages/stats/RentalStatsApp'
import DemoAdminApp from './DemoAdminApp'
import NotFoundPage from './NotFoundPage'
import DesignSystem from '../_template/DesignSystem'
import AftersalesStatsApp from '../_pages/stats/AftersalesStatsApp'
import SecondHandStatsApp from '../_pages/stats/SecondHandStatsApp'
import Publish from './Publish'
import ManageUniqueProduct from './ManageUniqueProduct'
import Takeback from './Takeback'
import InstructionsManagement from '_pages/instructionsManagement/InstructionsManagement'
import InstructionGroupsManagement from '_pages/instructionGroupsManagement/InstructionGroupsManagement'
import InsertProductHome from '_pages/insertUniqueProduct/InsertProductHome'
import Refit from '_pages/refit/Refit'
import OrdersManagement from '_pages/ordersManagement/OrdersManagement'
import Picking from '_pages/ordersManagement/picking/Picking'
import PickPackIssueList from '_pages/ordersManagement/pickPackIssueList/IssueList'
import BatchPacking from '_pages/ordersManagement/packing/BatchPacking'
import OrderPacking from '_pages/ordersManagement/packing/OrderPacking'
import LogisticsActivityStats from '../_pages/stats/LogisticsActivityStats'
import OrderUnitaryPreparation from '_pages/ordersManagement/unitaryPreparation/OrderUnitaryPreparation'
import UniqueProductTable from '_pages/uniqueProductTable/UniqueProductTable'
import TakebackTable from '_pages/takebackTable/TakebackTable'
import ForthParcelTable from '_pages/forthParcelTable/ForthParcelTable'
import ReturnParcel from '_pages/returnParcel/ReturnParcel'
import ReturnedParcel from '_pages/returnedParcels/ReturnedParcel'
import ReturnParcelTable from '_pages/returnParcelTable/ReturnParcelTable'
import ParcelCollectionTable from '_pages/parcelCollectionTable/ParcelCollectionTable'

const Routes: React.FC = () => {
    return (
        <RouterRoutes>
            <Route path={HOME_ROUTE} element={<Home />} />
            <Route
                path={REFIT_ROUTE}
                element={<PrivateRoute component={Refit} roles={[...WAREHOUSE_ROLES, IN_STORE_RECEPTIONIST]} />}
            />
            <Route
                path={REFIT_INSTRUCTION_CATEGORIES_ROUTE}
                element={<PrivateRoute roles={MANAGER_ROLES} component={InstructionsManagement} />}
            />
            <Route
                path={REFIT_INSTRUCTION_GROUPS_ROUTE}
                element={<PrivateRoute roles={MANAGER_ROLES} component={InstructionGroupsManagement} />}
            />
            <Route
                path={ORDERS_ROUTE}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={OrdersManagement} />}
            />
            <Route
                path={PICKING_ROUTE()}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={Picking} />}
            />
            <Route
                path={PACKING_ROUTE()}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={BatchPacking} />}
            />
            <Route
                path={ORDER_PACKING_ROUTE()}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={OrderPacking} />}
            />
            <Route
                path={ORDER_UNITARY_PREPARATION_ROUTE()}
                element={
                    <PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={OrderUnitaryPreparation} />
                }
            />
            <Route
                path={PICKPACK_ISSUES_LIST_ROUTE}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={PickPackIssueList} />}
            />
            <Route
                path={APPOINTMENTS_ROUTE}
                element={<PrivateRoute roles={[...IN_STORE_ROLES, IN_STORE_RECEPTIONIST]} component={Appointments} />}
            />
            <Route
                path={FORTH_PARCEL_LIST_ROUTE}
                element={<PrivateRoute roles={ALL_ROLES} component={ForthParcelTable} />}
            />
            <Route
                path={PARCEL_COLLECTION_ROUTE}
                element={
                    <PrivateRoute
                        roles={[LIZEE_SUPER_ADMIN, BRAND_ADMIN, WAREHOUSE_MANAGER]}
                        component={ParcelCollectionTable}
                    />
                }
            />
            <Route
                path={RETURNS_LIST_ROUTE}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={ReturnParcelTable} />}
            />
            <Route
                path={TAKEBACK_LIST_ROUTE}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={TakebackTable} />}
            />
            <Route
                path={RETURNED_PARCEL_DETAILS_ROUTE()}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={ReturnedParcel} />}
            />
            <Route
                path={TAKEBACK_DETAILS_ROUTE}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={Takeback} />}
            />
            <Route path={INVOICES_ROUTE} element={<PrivateRoute roles={MANAGER_ROLES} component={InvoicesApp} />} />
            <Route
                path={WMS_INSERT_UNIQUE_PRODUCT}
                element={<PrivateRoute roles={WAREHOUSE_ROLES} component={InsertProductHome} />}
            />
            <Route
                path={WMS_LINK_LOCATION}
                element={<PrivateRoute roles={WAREHOUSE_ROLES} component={LinkLocationIntermediaryPage} />}
            />
            <Route
                path={WMS_LINK_LOCATION_TO_PRODUCT_ROUTE}
                element={<PrivateRoute roles={WAREHOUSE_ROLES} component={LinkLocation} />}
            />
            <Route
                path={WMS_LINK_LOCATION_TO_PRODUCTS_ROUTE}
                element={<PrivateRoute roles={WAREHOUSE_ROLES} component={LinkLocationBulk} />}
            />
            <Route
                path={WMS_CHANGE_SKU_LOCATION_ROUTE}
                element={<PrivateRoute roles={WAREHOUSE_ROLES} component={ChangeSkuLocation} />}
            />
            <Route path={WMS_PUBLISH} element={<PrivateRoute roles={WAREHOUSE_ROLES} component={Publish} />} />
            <Route
                path={WMS_UNIQUE_PRODUCT_LIST}
                element={<PrivateRoute roles={ALL_ROLES} component={UniqueProductTable} />}
            />
            <Route
                path={MANAGE_UNIQUE_PRODUCT()}
                element={<PrivateRoute roles={MANAGER_ROLES} component={ManageUniqueProduct} />}
            />
            <Route
                path={WMS_PRODUCT_REFERENCE_STOCK}
                element={<PrivateRoute roles={ALL_ROLES_BUT_ACCOUNTANT} component={StockForecastTable} />}
            />
            <Route
                path={ORDERS_DELIVERED_ROUTE}
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={OrderDeliveredApp} />}
            />
            <Route path={FINANCES_ROUTE} element={<PrivateRoute roles={ALL_ROLES} component={FinancesApp} />} />
            <Route path={STOCK_STATS_ROUTE} element={<PrivateRoute roles={ALL_ROLES} component={StockStatsApp} />} />
            <Route
                path={SHIPMENT_STATS_ROUTE}
                element={<PrivateRoute roles={ALL_ROLES} component={ShipmentStatsApp} />}
            />
            <Route path={RETURN_STATS_ROUTE} element={<PrivateRoute roles={ALL_ROLES} component={ReturnStatsApp} />} />
            <Route path={REFIT_STATS_ROUTE} element={<PrivateRoute roles={ALL_ROLES} component={RefitStatsApp} />} />
            <Route
                path={LOGISTICS_ACTIVITY_STATS_ROUTE}
                element={<PrivateRoute roles={ALL_ROLES} component={LogisticsActivityStats} />}
            />
            <Route
                path={AFTERSALES_STATS_ROUTE}
                element={<PrivateRoute roles={ALL_ROLES} component={AftersalesStatsApp} />}
            />
            <Route
                path={SECOND_HAND_STATS_ROUTE}
                element={<PrivateRoute roles={ALL_ROLES} component={SecondHandStatsApp} />}
            />
            <Route
                path={OMS_RENTAL_STATS_ROUTE}
                element={<PrivateRoute roles={ALL_ROLES} component={RentalStatsApp} />}
            />
            <Route
                path={OMS_STATS_ROUTE}
                element={<PrivateRoute roles={ALL_ROLES} component={OrderManagementStatsApp} />}
            />
            <Route path={HISTORY_ROUTE} element={<PrivateRoute roles={ALL_ROLES} component={HistoryApp} />} />
            <Route
                path={DEPLOY_DEMO_ROUTE}
                element={<PrivateRoute roles={[LIZEE_SUPER_ADMIN]} component={DemoAdminApp} />}
            />
            <Route
                path={TEMPLATE_ROUTE}
                element={<PrivateRoute roles={[LIZEE_SUPER_ADMIN]} component={DesignSystem} />}
            />
            <Route
                path={RETURN_PARCEL_ROUTE}
                element={<PrivateRoute roles={[...WAREHOUSE_ROLES, IN_STORE_RECEPTIONIST]} component={ReturnParcel} />}
            />
            <Route
                path="*"
                element={<PrivateRoute roles={[...ALL_ROLES, IN_STORE_RECEPTIONIST]} component={NotFoundPage} />}
            />
        </RouterRoutes>
    )
}

export default Routes
