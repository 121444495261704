import * as React from 'react'
import { createStyles, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import Button from '_atoms/buttons/Button'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& button': {
                height: theme.space(8),
                fontSize: theme.typography.pxToRem(12),
            },
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                gap: `${theme.space(4)}px`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                gap: `${theme.space(2)}px`,
                '& button:last-of-type': {
                    flex: 1,
                    height: theme.space(8),
                },
            },
        },
        waitForScan: {
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            '& svg': {
                color: theme.palette.primary[600],
            },
            '&:disabled': {
                color: theme.palette.primary[500],
                background: theme.palette.common.white,
                opacity: 1,
            },
        },
        waitForScanWithIgnore: {
            boxShadow: 'none',
        },
        divider: {
            border: 'none',
            borderLeft: `dashed 1px ${theme.palette.neutral[200]}`,
            margin: theme.space(0, 3),
            height: theme.space(8),
        },
    }),
)

type BackgroundWithFooterProps = {
    children: React.ReactElement
    scanOnly: boolean
    isMandatory: boolean
    isLastInstruction: boolean
    handleIgnoreInstruction: () => void
    handleNextInstruction: () => void
}

const BackgroundWithFooter: React.FC<BackgroundWithFooterProps> = ({
    children,
    scanOnly,
    isMandatory,
    isLastInstruction,
    handleIgnoreInstruction,
    handleNextInstruction,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })

    const ignoreButton = () => (
        <Button variant="ghost" color="neutral" onClick={handleIgnoreInstruction} data-testid="ignoreButton">
            {isLastInstruction
                ? t('new_refit.instruction.ignoreAndFinishInstruction')
                : t('new_refit.instruction.ignoreInstruction')}
        </Button>
    )

    const finishButton = () => (
        <Button
            label={t('new_refit.instruction.finishRefit')}
            color="success"
            startIcon={faScannerGun}
            onClick={handleNextInstruction}
            size="large"
            data-testid="finishButton"
        />
    )

    const scanOnlyFinishButton = () => (
        <Button
            label={t('new_refit.instruction.scanAndFinishRefit')}
            color="success"
            startIcon={faScannerGun}
            disabled
            size="large"
            data-testid="scanOnlyFinishButton"
        />
    )

    const waitForScanButton = (className = '') => (
        <Button
            disabled
            startIcon={faScannerGun}
            className={`${classes.waitForScan} ${className}`}
            variant="ghost"
            size={isMobile ? 'medium' : 'large'}
            data-testid="scanOnlyButton"
        >
            {t('new_refit.instruction.waitingForScan')}
        </Button>
    )

    const nextInstructionButton = () => (
        <Button
            label={t('new_refit.instruction.nextInstruction')}
            startIcon={faScannerGun}
            size={isMobile ? 'medium' : 'large'}
            onClick={handleNextInstruction}
            data-testid="nextInstructionButton"
        />
    )

    const finishAndIgnoreButtons = () => (
        <div className={classes.container}>
            {ignoreButton()}
            {scanOnly ? scanOnlyFinishButton() : finishButton()}
        </div>
    )

    const nextInstructionAndIgnoreButtons = () => (
        <div className={classes.container}>
            {ignoreButton()}
            {nextInstructionButton()}
        </div>
    )

    const waitForScanAndIgnoreButtons = () => (
        <div className={classes.container}>
            {ignoreButton()}
            <hr className={classes.divider} />
            {waitForScanButton(classes.waitForScanWithIgnore)}
        </div>
    )

    let footerInBoxMobile = false
    let footerInBoxDesktop = false
    let footer

    if (isLastInstruction && !scanOnly && isMandatory) {
        footer = finishButton()
    }

    if (isLastInstruction && scanOnly && isMandatory) {
        footer = scanOnlyFinishButton()
    }

    if (isLastInstruction && !isMandatory) {
        footer = finishAndIgnoreButtons()
        footerInBoxMobile = true
        footerInBoxDesktop = true
    }

    if (!isLastInstruction && scanOnly && isMandatory) {
        footer = waitForScanButton()
    }

    if (!isLastInstruction && !scanOnly && isMandatory) {
        footer = nextInstructionButton()
    }

    if (!isLastInstruction && scanOnly && !isMandatory) {
        footer = waitForScanAndIgnoreButtons()
        footerInBoxMobile = true
        footerInBoxDesktop = true
    }

    if (!isLastInstruction && !scanOnly && !isMandatory) {
        footer = nextInstructionAndIgnoreButtons()
        footerInBoxMobile = true
        footerInBoxDesktop = true
    }

    return (
        <BackgroundWithScanAndFooter
            footer={footer}
            footerInBoxMobile={footerInBoxMobile}
            footerInBoxDesktop={footerInBoxDesktop}
        >
            {children}
        </BackgroundWithScanAndFooter>
    )
}

export default BackgroundWithFooter
