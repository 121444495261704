import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import dayjs from 'dayjs'
import { BasicText } from '_atoms/text/BasicText'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overdue: {
            color: theme.palette.common.black,
        },
        overdueWarning: {
            color: theme.palette.warning.main,
        },
        overdueError: {
            color: theme.palette.error.main,
        },
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        labelText: {
            color: theme.palette.grey[600],
        },
        parcelText: {
            color: theme.palette.grey[800],
            fontWeight: 500,
            marginTop: theme.spacing(1),
        },
    }),
)

type TakebackDateOverviewProps = {
    takebackDate: string | undefined
    shipmentDates:
        | {
              shippedAt: string | undefined
              arrivedAt: string | undefined
              processedAt: string | undefined
          }[]
        | undefined
}

const TakebackDateOverview: React.FC<TakebackDateOverviewProps> = ({ takebackDate, shipmentDates }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <SectionTitle className={classes.sectionTitle} size="large">
                {t('takebacks.takebackDates.takebackDates')}
            </SectionTitle>
            <Grid container>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('takebacks.takebackDates.takebackDate')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {dayjs(takebackDate).format('DD/MM/YYYY')}
                </Grid>
                {shipmentDates?.map((parcel, index) => {
                    return (
                        <Grid xs={12} container key={index}>
                            {shipmentDates.length > 1 && (
                                <Grid xs={12} item>
                                    <BasicText className={classes.parcelText}>{`${t('menu.returns.parcel')} ${
                                        index + 1
                                    }`}</BasicText>
                                </Grid>
                            )}
                            <Grid xs={6} item>
                                <BasicText className={classes.labelText}>
                                    {t('takebacks.takebackDates.sendAt')}
                                </BasicText>
                            </Grid>
                            <Grid xs={6} item>
                                {parcel.shippedAt
                                    ? dayjs(parcel.shippedAt).format('DD/MM/YYYY')
                                    : t('takebacks.takebackDates.notSentYet')}
                            </Grid>
                            <Grid xs={6} item>
                                <BasicText className={classes.labelText}>
                                    {t('takebacks.takebackDates.arrivedAt')}
                                </BasicText>
                            </Grid>
                            <Grid xs={6} item>
                                {parcel.arrivedAt
                                    ? dayjs(parcel.arrivedAt).format('DD/MM/YYYY')
                                    : t('takebacks.takebackDates.notArrivedYet')}
                            </Grid>
                            <Grid xs={6} item>
                                <BasicText className={classes.labelText}>
                                    {t('takebacks.takebackDates.processedAt')}
                                </BasicText>
                            </Grid>
                            <Grid xs={6} item>
                                {parcel.processedAt
                                    ? dayjs(parcel.processedAt).format('DD/MM/YYYY')
                                    : t('takebacks.takebackDates.notProcessedYet')}
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

export default TakebackDateOverview
