import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import TakebackDateOverview from './takebackDateOverview'
import TakebackParcelInfosOverview from './TakebackParcelInfosOverview'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionContainer: {
            margin: theme.spacing(4, 0),
            justifyContent: 'center',
        },
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        soonAvailableLink: {
            color: 'grey',
            pointerEvents: 'none',
            cursor: 'default',
        },
        labelText: {
            color: theme.palette.grey[600],
        },
    }),
)

type TakebackParcelSummarySectionProps = {
    renterInfos?: {
        email: string | undefined
        fullName: string | undefined
    }
    takebackDate: string | undefined
    shipmentDates: {
        shippedAt: string | undefined
        arrivedAt: string | undefined
        processedAt: string | undefined
    }[]
    customerName: string
    carrier?: string
    parcelsInfos?: {
        barcode?: string
        trackingLink?: string
        trackingCode?: string
        pdfUrl?: string
    }[]
}

const TakebackParcelSummarySection: React.FC<TakebackParcelSummarySectionProps> = ({
    renterInfos,
    takebackDate,
    shipmentDates,
    customerName,
    carrier,
    parcelsInfos,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid container className={classes.sectionContainer}>
            <Grid item xs={12} sm={4}>
                <SectionTitle className={classes.sectionTitle} size="large">
                    {t('takebacks.client')}
                </SectionTitle>
                <div>{renterInfos?.fullName}</div>
                <strong>{renterInfos?.email}</strong>
            </Grid>

            <Grid item xs={12} sm={4}>
                <TakebackDateOverview takebackDate={takebackDate} shipmentDates={shipmentDates} />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TakebackParcelInfosOverview
                    customerName={customerName}
                    carrier={carrier}
                    parcelsInfos={parcelsInfos}
                />
            </Grid>
        </Grid>
    )
}

export default TakebackParcelSummarySection
