import * as React from 'react'
import { useState, useEffect } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { faPause, faTimer } from '@fortawesome/pro-light-svg-icons'
import { IOngoingOperation, IOngoingOperations, RefitOperationStatus } from 'interfaces'
import RefitHowItWorks from './RefitHowItWorks'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { useTranslation } from 'react-i18next'
import InstructionOverviewList from './InstructionOverviewList'
import StartAutonomousInstructionModal from './StartAutonomousInstructionModal'
import { toast } from 'utils/toast'
import AwaitingScanModal from '_molecules/AwaitingScanModal'

type RefitHomeProps = {
    handleUniqueProductScan: (scannedCode: string) => void
    ongoingOperations?: IOngoingOperations
    startAutonomousRefitOperation: (operationId: number) => void
    ignoreAutonomousRefitOperation: (operation: IOngoingOperation) => void
    finishRefitOperation: (operationId: number, barcodeUid?: string, isLastOperationOfRefit?: boolean) => void
}

const RefitHome: React.FC<RefitHomeProps> = ({
    ongoingOperations,
    handleUniqueProductScan,
    startAutonomousRefitOperation,
    ignoreAutonomousRefitOperation,
    finishRefitOperation,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    const [waitingOperation, setWaitingOperation] = useState<IOngoingOperation>()
    const [terminatedOrInProgressOperation, setTerminatedOrInProgressOperation] = useState<IOngoingOperation>()

    useEffect(() => {
        setWaitingOperation(undefined)
        setTerminatedOrInProgressOperation(undefined)
    }, [ongoingOperations])

    function handleClickOnPausedTable(ongoingInstruction: IOngoingOperation) {
        handleUniqueProductScan(ongoingInstruction.uniqueProductsToProcess[0].barcodeUid)
    }

    function handleClickOnTerminatedTable(ongoingInstruction: IOngoingOperation) {
        const isMultiple = ongoingInstruction.uniqueProductsToProcess.length > 1
        const isScanOnly = ongoingInstruction.refitInstruction.scanOnly
        if (isMultiple || isScanOnly) {
            setTerminatedOrInProgressOperation(ongoingInstruction)
        } else {
            handleUniqueProductScan(ongoingInstruction.uniqueProductsToProcess[0].barcodeUid)
        }
    }

    function handleScan(scannedCode: string) {
        const uniqueProducts = terminatedOrInProgressOperation!.uniqueProductsToProcess.map(
            (uniqueProduct) => uniqueProduct.barcodeUid,
        )
        if (uniqueProducts?.includes(scannedCode)) {
            handleUniqueProductScan(scannedCode)
        } else {
            toast.error(t('new_refit.errors.scanWrongPuidDuringRefit'))
        }
    }

    function handleHomeScan(scannedCode: string) {
        if (terminatedOrInProgressOperation) {
            if (isMobile) {
                handleScan(scannedCode)
            }
        } else {
            const autonomousOperationInProgress = ongoingOperations?.inProgress?.find((operation) =>
                operation.uniqueProductsToProcess
                    .map((uniqueProduct) => uniqueProduct.barcodeUid)
                    .includes(scannedCode),
            )
            if (autonomousOperationInProgress) {
                setTerminatedOrInProgressOperation(autonomousOperationInProgress)
            } else {
                handleUniqueProductScan(scannedCode)
            }
        }
    }

    const displayOngoing =
        ongoingOperations?.paused?.length ||
        ongoingOperations?.waiting?.length ||
        ongoingOperations?.inProgress?.length ||
        ongoingOperations?.finished?.length ||
        ongoingOperations?.ignored?.length

    return (
        <Page title={t('new_refit.home.title')} headerStyle={HeaderStyleEnum.WITH_TOOLBAR}>
            <BackgroundWithScanAndFooter
                scanProps={{
                    onSubmit: handleHomeScan,
                    placeholder: t('new_refit.home.scanPlaceholder'),
                    disabled: !!terminatedOrInProgressOperation,
                }}
            >
                {!displayOngoing ? (
                    <RefitHowItWorks />
                ) : (
                    <>
                        <InstructionOverviewList
                            ongoingOperations={ongoingOperations!.paused}
                            onClick={handleClickOnPausedTable}
                            icon={faPause}
                            title="new_refit.home.paused"
                        />
                        <InstructionOverviewList
                            ongoingOperations={ongoingOperations!.waiting}
                            onClick={setWaitingOperation}
                            icon={faTimer}
                            title="new_refit.home.waiting"
                        />
                        <InstructionOverviewList
                            ongoingOperations={ongoingOperations!.inProgress}
                            onClick={setTerminatedOrInProgressOperation}
                            icon={faTimer}
                            title="new_refit.home.inProgress"
                            finishRefitOperation={finishRefitOperation}
                        />
                        <InstructionOverviewList
                            ongoingOperations={ongoingOperations!.finished}
                            onClick={handleClickOnTerminatedTable}
                            icon={faTimer}
                            title="new_refit.home.finished"
                        />
                        <InstructionOverviewList
                            ongoingOperations={ongoingOperations!.ignored}
                            onClick={handleClickOnTerminatedTable}
                            icon={faTimer}
                            title="new_refit.home.ignored"
                        />
                    </>
                )}
            </BackgroundWithScanAndFooter>
            {terminatedOrInProgressOperation && (
                <AwaitingScanModal
                    title={t('new_refit.home.awaitingScan.waiting')}
                    tagline={
                        terminatedOrInProgressOperation.status === RefitOperationStatus.IN_PROGRESS
                            ? t(`new_refit.home.awaitingScan.inProgress${isMobile ? '' : 'Desktop'}`)
                            : t(`new_refit.home.awaitingScan.terminated${isMobile ? '' : 'Desktop'}`)
                    }
                    onClose={() => setTerminatedOrInProgressOperation(undefined)}
                    onSubmit={handleScan}
                    placeholder={t('new_refit.home.awaitingScan.placeholder')}
                />
            )}
            {waitingOperation && (
                <StartAutonomousInstructionModal
                    onClose={() => setWaitingOperation(undefined)}
                    onStart={() => startAutonomousRefitOperation(waitingOperation.id)}
                    onIgnore={() => ignoreAutonomousRefitOperation(waitingOperation)}
                    instructionTitle={waitingOperation.refitInstruction.title}
                />
            )}
        </Page>
    )
}

export default RefitHome
