import * as React from 'react'
import { useEffect, useState } from 'react'
import OrderPackingFinish from './OrderPackingFinish'
import OrderPackingScanProducts from './OrderPackingScanProducts'
import { IOrderPackingList, IPackingLocationSearchInfos } from 'interfaces/Packing.interface'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { ORDER_PACKING_LIST_URL } from 'utils/routes/backend'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import OrderPackingLabelError from './OrderPackingLabelError'
import Page from '_organisms/Page'
import EnrichedIcon from '_molecules/EnrichedIcon'
import { faLoader, faClose } from '@fortawesome/pro-light-svg-icons'
import LocationSearch from './LocationSearch'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { PACKING_ROUTE } from 'utils/routes/frontend'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        warningIcon: {
            backgroundColor: theme.palette.error[100],
            color: theme.palette.error.main,
        },
    }),
)

const OrderPacking: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { orderId } = useParams()
    const navigate = useNavigate()
    const buildErrorMessage = useErrorMessage()

    const [locationSearchInfos, setLocationSearchInfos] = useState<IPackingLocationSearchInfos>()
    const [finishPacking, setFinishPacking] = useState<boolean>(false)
    const [labelError, setLabelError] = useState<boolean>(false)

    const [parcelNumber, setParcelNumber] = useState<number>(1)
    const [orderPackingData, setOrderPackingData] = useState<IOrderPackingList>()

    async function fetchOrderPackingData() {
        try {
            const packingRequest = await rmsApi.get(ORDER_PACKING_LIST_URL(orderId!))
            setOrderPackingData(packingRequest.data)
        } catch (e) {
            toast.error(buildErrorMessage(e, { orderId }, 'new_orders.errors.fetchPackingInfosError'))
        }
    }

    useEffect(() => {
        fetchOrderPackingData()
    }, [])

    const preparationFinishedOrCanceled =
        (orderPackingData?.isCanceled && !orderPackingData?.canceledUnprocessed) || orderPackingData?.isFinished

    if (preparationFinishedOrCanceled) {
        return (
            <Page
                title={`${orderPackingData!.customer.name} #${orderPackingData!.omsOrderId}`}
                handlePrevious={() => navigate(PACKING_ROUTE(orderPackingData!.batchId))}
            >
                <BackgroundWithScanAndFooter>
                    <EnrichedIcon
                        icon={faClose}
                        title={t('new_orders.packing.noAccessToOrder')}
                        iconProps={{ className: classes.warningIcon }}
                    />
                </BackgroundWithScanAndFooter>
            </Page>
        )
    }

    async function resetPage() {
        setFinishPacking(false)
        setLabelError(false)
        setLocationSearchInfos(undefined)
        await fetchOrderPackingData()
    }

    if (!orderPackingData) {
        return (
            <Page title={t('new_orders.packing.loadingPage.title')}>
                <EnrichedIcon
                    icon={faLoader}
                    title={t('new_orders.packing.loadingPage.title')}
                    tagline={t('new_orders.packing.loadingPage.orderTagline')}
                    iconProps={{ spin: true }}
                />
            </Page>
        )
    }

    if (locationSearchInfos) {
        const { omsOrderId, shipments, customer } = orderPackingData
        const shipmentId = shipments.find((shipment) => !shipment.isReturn)!.id

        return (
            <LocationSearch
                product={locationSearchInfos.product}
                isAddedProduct={locationSearchInfos.isAddedProduct}
                omsOrderId={omsOrderId}
                customerName={customer.name}
                shipmentId={shipmentId}
                orderId={+orderId!}
                resetPage={resetPage}
            />
        )
    }

    if (!finishPacking) {
        return (
            <OrderPackingScanProducts
                setFinishPacking={setFinishPacking}
                orderPackingData={orderPackingData}
                fetchOrderPackingData={fetchOrderPackingData}
                parcelNumber={parcelNumber}
                setParcelNumber={setParcelNumber}
                setLocationSearchInfos={setLocationSearchInfos}
            />
        )
    }

    if (!labelError) {
        return (
            <OrderPackingFinish
                onLeavePage={resetPage}
                setLabelError={setLabelError}
                orderPackingData={orderPackingData}
                parcelNumber={parcelNumber}
            />
        )
    }

    return <OrderPackingLabelError onLeavePage={resetPage} orderPackingData={orderPackingData} />
}

export default OrderPacking
