import * as React from 'react'
import { useTranslation } from 'react-i18next'
import OrderListTab from './orderList/OrderListTab'
import Page from '_organisms/Page'
import { ShipmentStatus } from 'interfaces'
import UnitaryPreparationTab from './unitaryPreparation/UnitaryPreparationTab'
import BatchListTab from './batchList/BatchListTab'
import {
    BRAND_ADMIN,
    BRAND_PROJECT_MANAGER,
    IN_STORE_MANAGER,
    IN_STORE_OPERATOR,
    IN_STORE_RECEPTIONIST,
    LIZEE_SUPER_ADMIN,
    WAREHOUSE_MANAGER,
    WAREHOUSE_OPERATOR,
} from 'utils/roles'
import useKeycloakStore from '../../utils/store/useKeycloak.store'
import TabSystem from '_organisms/TabSystem'
import { KeycloakUserState } from 'utils/store/keycloak.store'
import { useLocation, useNavigate } from 'react-router-dom'
import { ORDERS_ROUTE, orderTabs } from 'utils/routes/frontend'

const ADMIN_ROLES = [BRAND_ADMIN, LIZEE_SUPER_ADMIN, BRAND_PROJECT_MANAGER]
const IN_STORE_ROLES = [IN_STORE_MANAGER, IN_STORE_OPERATOR, IN_STORE_RECEPTIONIST]
const WAREHOUSE_ROLES = [WAREHOUSE_MANAGER, WAREHOUSE_OPERATOR]

const OrdersManagement: React.FC = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const hasOneOfRoles = useKeycloakStore((state: KeycloakUserState) => state.hasOneOfRoles)

    const { hash } = location
    const initialTab = orderTabs.indexOf(hash.slice(1))

    const tabs = [
        {
            title: { label: t('new_orders.ordersListTab'), value: 'ordersListTab' },
            component: <OrderListTab />,
            roles: [...ADMIN_ROLES, ...WAREHOUSE_ROLES, ...IN_STORE_ROLES],
        },
        {
            title: { label: t('new_orders.pickPackTab'), value: 'pickPackTab' },
            component: <BatchListTab />,
            roles: [...ADMIN_ROLES, ...WAREHOUSE_ROLES],
        },
        {
            title: { label: t('new_orders.unitaryPreparationTab'), value: 'unitaryPreparationTab' },
            component: <UnitaryPreparationTab />,
            roles: [...ADMIN_ROLES, ...WAREHOUSE_ROLES, ...IN_STORE_ROLES],
        },
        {
            title: { label: t('new_orders.ordersToWithdrawTab'), value: 'ordersToWithdrawTab' },
            component: <OrderListTab status={ShipmentStatus.TO_WITHDRAW} />,
            roles: [...ADMIN_ROLES, ...IN_STORE_ROLES],
        },
    ]

    const tabLabels = tabs.map((tab) => hasOneOfRoles(tab.roles) && tab.title).filter(Boolean) as {
        label: string
        value: string
    }[]

    const tabComponents = tabs.map((tab) => hasOneOfRoles(tab.roles) && tab.component).filter(Boolean)

    return (
        <Page title={t('new_orders.headerTitle')} section={t('new_orders.headerSection')} withDivider={false}>
            <TabSystem
                tabLabels={tabLabels}
                initialTab={initialTab < 0 ? 0 : initialTab}
                onClick={(tab: number) => navigate({ pathname: ORDERS_ROUTE, hash: orderTabs[tab] })}
            >
                {...tabComponents}
            </TabSystem>
        </Page>
    )
}

export default OrdersManagement
