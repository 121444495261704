import * as React from 'react'
import { useState } from 'react'
import { createStyles, IconButton, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core'
import BarcodeScanIcon from 'mdi-react/BarcodeScanIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: '0 auto',
                width: '100%',
            },
            '& button.Mui-focusVisible': {
                color: theme.palette.primary.main,
            },
        },
        scanIcon: {
            color: theme.palette.primary.main,
        },
    }),
)

type CodeScannerProps = {
    label: string
    defaultValue?: string
    disabled?: boolean
    onSubmit: (submittedText: string) => void
    onChange?: (submittedText: string) => void
    lockFocus?: boolean
    setKeyboardListener?: (value: boolean) => void
}
const empty = () => {
    return null
}
const CodeScanner: React.FC<CodeScannerProps> = ({
    label,
    defaultValue = '',
    disabled = false,
    onChange = empty,
    onSubmit,
    lockFocus = false,
    setKeyboardListener,
}) => {
    const [value, setValue] = useState(defaultValue)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
        setValue(e.target.value)
    }

    const handleSubmit = () => {
        onSubmit(value)
        setValue('')
    }

    const classes = useStyles()
    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                }}
                className={classes.root}
            >
                <TextField
                    data-testid="scanInput"
                    value={value}
                    onChange={handleChange}
                    autoFocus
                    label={label}
                    onBlur={() => {
                        if (lockFocus) document.getElementById('codeScanner')?.focus()
                        if (setKeyboardListener) setKeyboardListener(true)
                    }}
                    onFocus={() => {
                        if (setKeyboardListener) setKeyboardListener(false)
                    }}
                    variant="outlined"
                    disabled={disabled}
                    InputProps={{
                        endAdornment: !disabled ? (
                            <InputAdornment position="end">
                                <IconButton className={classes.scanIcon} aria-label="Search" type="submit">
                                    <BarcodeScanIcon className="MuiSvgIcon-root" />
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            <></>
                        ),
                    }}
                />
            </form>
        </>
    )
}

export default CodeScanner
