import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import Text from '_atoms/text/Text'
import ScanInput from '_molecules/ScanInput'
import { IRefitStep } from 'interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { useStyles as useFooterStyles } from './BackgroundWithFooter'
import Button from '_atoms/buttons/Button'
import Counter from '_atoms/badges/Counter'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scanTrayContainer: {
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                paddingBottom: theme.space(8),
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.space(24),
            },
        },
        scanTrayIcon: {
            width: theme.space(7),
            height: theme.space(7),
            padding: theme.space(3),
            borderRadius: '50%',
            color: theme.palette.primary[600],
            background: theme.palette.primary[100],
            marginBottom: theme.space(6),
        },
        scanTrayTitle: {
            fontWeight: 500,
            marginBottom: theme.space(1),
        },
    }),
)

type InstructionProps = {
    refitStep: IRefitStep
    startRefitOperation: (refitLocationName?: string) => void
}

const RefitTrayScan: React.FC<InstructionProps> = ({ refitStep, startRefitOperation }) => {
    const classes = useStyles()
    const footerClasses = useFooterStyles()
    const { t } = useTranslation()
    const { totalInstructions, instruction, currentInstructionPosition } = refitStep
    const { title } = instruction

    const renderHeaderAddon = () => <Counter current={currentInstructionPosition} total={totalInstructions} inHeader />

    const renderFooter = () => (
        <div className={footerClasses.container}>
            <Button
                label={t('new_refit.instruction.ignoreInstruction')}
                color="neutral"
                variant="ghost"
                onClick={() => startRefitOperation(undefined)}
                data-testid="ignoreButton"
            />
            <hr className={footerClasses.divider} />
            <Button
                label={t('new_refit.instruction.waitingForScan')}
                disabled
                startIcon={faScannerGun}
                className={`${footerClasses.waitForScan} ${footerClasses.waitForScanWithIgnore}`}
                size="large"
                data-testid="scanOnlyButton"
            />
        </div>
    )

    return (
        <Page
            title={title}
            section={t('new_refit.home.title')}
            rightAddon={{ addon: renderHeaderAddon(), displayOnMobile: true }}
            withMenuButton={false}
            headerStyle={HeaderStyleEnum.DEFAULT}
        >
            <ScanInput onSubmit={startRefitOperation} invisible />
            <BackgroundWithScanAndFooter footer={renderFooter()} footerInBoxDesktop>
                <div className={classes.scanTrayContainer}>
                    <FontAwesomeIcon icon={faScannerGun} className={classes.scanTrayIcon} />
                    <Text variant="S2" className={classes.scanTrayTitle}>
                        {t('new_refit.instruction.scanTrayTitle')}
                    </Text>
                    <Text variant="P4">{t('new_refit.instruction.scanTrayTagline')}</Text>
                </div>
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default RefitTrayScan
