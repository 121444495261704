import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { IUniqueProduct, UniqueProductStatus } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { deleteS3File, EntityType, getSignedUrl, RMSFile, uploadFiles } from 'utils/files'
import { FILE_DOWNLOAD_URL, SECOND_HAND_UPDATE_PHOTOS_URL, UNIQUE_PRODUCTS_BY_ID_URL } from 'utils/routes/backend'
import FileButton from '_atoms/inputs/FileButton'
import { SectionTitle } from '_atoms/text/SectionTitle'
import Page from '_organisms/Page'
import ThumbnailGrid from '_organisms/images/shooting/ThumbnailGrid'
import Tips from '../_atoms/containers/Tips'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        fileButton: {
            width: '35%',
            border: '1px solid #CDD1DA',
            margin: '0 auto',
            borderRadius: '4px',
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        contentBox: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            margin: theme.spacing(5, 5, 0),
            padding: theme.spacing(4, 5),
            backgroundColor: '#F8F9FD',
            borderRadius: '10px',
            [theme.breakpoints.down('sm')]: {
                minHeight: 'calc(100vh - 200px)',
                backgroundColor: theme.palette.common.white,
                margin: theme.spacing(1.5, 0, 1.5),
                padding: theme.spacing(2, 2),
                borderTop: '1px solid rgba(36, 43, 61, 0.05)',
                borderRadius: 0,
            },
        },
        tip: {
            [theme.breakpoints.up('sm')]: {
                backgroundColor: theme.palette.primary[100],
                marginBottom: theme.spacing(4),
            },
            marginBottom: theme.spacing(2.5),
        },
    }),
)

const ManageUniqueProduct: React.FC = () => {
    const [uniqueProduct, setUniqueProduct] = useState<IUniqueProduct | undefined>(undefined)
    const classes = useStyles()
    const buildErrorMessage = useErrorMessage()
    const { t } = useTranslation()

    const { uniqueProductId: id } = useParams()
    const [currentPictures, setCurrentPictures] = useState<RMSFile[]>([])
    const [toUpdate, setToUpdate] = useState<boolean>(false)

    const picturesTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/SVG', 'image/heif', 'image/heic']

    const fetchPictureOfUniqueProduct = async function () {
        try {
            let url = FILE_DOWNLOAD_URL
            const params = uniqueProduct?.photos?.map((file) => `filenames[]=${file.name}`).join('&')

            if (params) {
                url += `?${params}`

                const res = await rmsApi.get(url)
                const files: RMSFile[] = res.data?.length
                    ? res.data.map((name: string): RMSFile => new File([], name))
                    : []

                for (let i = 0; i < files.length; i++) {
                    if (uniqueProduct?.photos && uniqueProduct?.photos[i]) {
                        files[i].id = uniqueProduct?.photos[i].id
                        files[i].remoteName = uniqueProduct?.photos[i].name
                    }
                }

                setCurrentPictures(files)
            }
        } catch (e: any) {
            toast.error(t('wms.pickPackTable.errorDownloadPhoto'))
        }
    }

    const fetchUniqueProduct = async () => {
        if (!id) return
        if (!toUpdate) toast.info(t('publish.scan.loading'))

        try {
            const response = await rmsApi.get(UNIQUE_PRODUCTS_BY_ID_URL(id))
            if (!toUpdate) toast.dismiss()

            setToUpdate(false)
            setUniqueProduct(response.data)
        } catch (e: any) {
            toast.dismiss()
            // { barcodeUid: id } put id instead of barcodeUid because we don't have the barcodeUid yet
            toast.error(buildErrorMessage(e, { barcodeUid: id }, 'publish.scan.unknownError'))
        }
    }

    const updateUniqueProductPhotos = async () => {
        try {
            await rmsApi.patch(SECOND_HAND_UPDATE_PHOTOS_URL(uniqueProduct!.id!), {
                photos: currentPictures.map((picture: RMSFile) => ({
                    id: picture.id,
                    name: picture.remoteName,
                    type: picture.type,
                    uniqueProductId: uniqueProduct!.id!,
                })),
            })

            toast.success(t('wms.uniqueProducts.addPictureSuccess'))
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { barcodeUid: uniqueProduct!.barcodeUid }))
        }

        await fetchUniqueProduct()
    }

    const handleFileInput = async (files: RMSFile[]) => {
        try {
            const promises = []
            for (const file of files) {
                if (picturesTypes.includes(file.type)) {
                    promises.push(
                        await getSignedUrl(
                            file,
                            EntityType.UNIQUE_PRODUCT,
                            uniqueProduct?.productReference?.product?.customer?.id,
                        ),
                    )
                } else toast.error(t('publish.shooting.wrongType'))
            }

            const uploadablePictures = await Promise.all(promises)

            await uploadFiles(uploadablePictures)

            setCurrentPictures([...currentPictures, ...uploadablePictures])
            setToUpdate(true)
        } catch (e) {
            toast.error('Failed to get signedUrl')
        }
    }

    const handleDelete = async (file: RMSFile) => {
        // Delete file in s3
        if (file.id) {
            const error = await deleteS3File(file.id)
            if (error) {
                toast.error(t('files.deleteError') + error.message)
                return
            }
        }
        // Delete picture from interface
        // Avoid to remove two files of the same name
        const picturesCopy = [...currentPictures]
        let index = -1
        picturesCopy.forEach((picture, idx) => {
            if (picture.name === file.name) {
                index = idx
                return
            }
        })
        if (index > -1) {
            picturesCopy.splice(index, 1)
            setCurrentPictures(picturesCopy)
        }

        toast.success(t('wms.uniqueProducts.deletePictureSuccess'))
    }

    useEffect(() => {
        if (toUpdate) {
            updateUniqueProductPhotos()
        }
        // eslint-disable-next-line
    }, [currentPictures, toUpdate])

    useEffect(() => {
        if (uniqueProduct) {
            fetchPictureOfUniqueProduct()
        }
        // eslint-disable-next-line
    }, [uniqueProduct])

    useEffect(() => {
        fetchUniqueProduct()
        // eslint-disable-next-line
    }, [id])

    const isUniqueProductManageable = (uniqueProduct: IUniqueProduct) => {
        return uniqueProduct.status !== UniqueProductStatus.SOLD
    }

    return (
        <Page title={t('wms.uniqueProducts.manageUniqueProduct')}>
            <Grid container>
                {uniqueProduct && (
                    <Box className={classes.contentBox}>
                        {uniqueProduct.isSecondHand ? (
                            <>
                                {!isUniqueProductManageable(uniqueProduct) && (
                                    <Tips className={classes.tip}>
                                        {t('wms.uniqueProducts.tipSoldUniqueProduct', UniqueProductStatus.SOLD)}
                                        <strong> {t(`uniqueProductStatus.${UniqueProductStatus.SOLD}`)}</strong>
                                    </Tips>
                                )}

                                <SectionTitle className={classes.sectionTitle}>
                                    {t('wms.uniqueProducts.managePictures')}
                                </SectionTitle>
                                <ThumbnailGrid
                                    pictures={currentPictures}
                                    handleDelete={isUniqueProductManageable(uniqueProduct) ? handleDelete : undefined}
                                    isS3File={true}
                                />
                                <FileButton
                                    text={t('reportIssue.takePicture.buttonLabel')}
                                    handleFile={(files) => handleFileInput(files)}
                                    className={classes.fileButton}
                                    multiple={true}
                                    data-testid="buttonTakePicture"
                                />
                            </>
                        ) : (
                            <SectionTitle className={classes.sectionTitle}>
                                {t('wms.uniqueProducts.notSecondHandError')}
                            </SectionTitle>
                        )}
                    </Box>
                )}
            </Grid>
        </Page>
    )
}

export default ManageUniqueProduct
