import * as React from 'react'
import { IInstructionGroup, IProductWithEans } from 'interfaces'
import InstructionGroupList from '_pages/instructionGroupsManagement/InstructionGroupListTab'
import TabSystem from '_organisms/TabSystem'
import { ICustomer } from 'interfaces/Customer'
import { useTranslation } from 'react-i18next'
import UnlinkedProductsTab from '_pages/instructionGroupsManagement/UnlinkedProductsTab'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import CustomerPicker from '_organisms/header/addons/CustomerPicker'

type HandleSingleGroupProps = {
    selectedCustomer?: ICustomer
    groups: IInstructionGroup[]
    unlinkedProducts: IProductWithEans[]
    selectInstructionGroup: (group: IInstructionGroup) => void
    refreshData: () => void
}

const HandleSingleGroup: React.FC<HandleSingleGroupProps> = ({
    selectedCustomer,
    groups,
    unlinkedProducts,
    selectInstructionGroup,
    refreshData,
}) => {
    const { t } = useTranslation()

    return (
        <Page
            title={t('refit.instructionGroups.title')}
            section={t('menu.refit.title')}
            tagline={t('refit.instructionGroups.tagline')}
            rightAddon={{
                addon: <CustomerPicker initWithRandomCustomer truncateLabelAbove={140} />,
                displayOnMobile: false,
            }}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            withDivider={false}
        >
            <TabSystem
                bottomOnMobile
                tabLabels={[
                    { label: t('refit.instructionGroups.manageGroups.groupsTab.title'), value: 'groupsTab' },
                    { label: t('refit.instructionGroups.manageGroups.productsTab.title'), value: 'productsTab' },
                ]}
            >
                <InstructionGroupList
                    selectedCustomer={selectedCustomer}
                    instructionGroups={groups}
                    selectInstructionGroup={(instructionGroup) => selectInstructionGroup(instructionGroup)}
                    refreshData={refreshData}
                />
                <UnlinkedProductsTab groups={groups} unlinkedProducts={unlinkedProducts} refreshData={refreshData} />
            </TabSystem>
        </Page>
    )
}

export default HandleSingleGroup
