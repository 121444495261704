import * as React from 'react'
import { createStyles, DialogTitle, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import theme from '../../theme'
import Pill from '_atoms/badges/Pill'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogHeader: {
            color: theme.palette.common.white,
            fontFamily: theme.typography.fontFamily,
            height: theme.spacing(18),
            background: theme.palette.primary.gradient,
            paddingTop: theme.spacing(2),
        },
        title: {
            fontWeight: 'normal',
        },
        closeIcon: {
            color: theme.palette.grey[200],
            fontSize: '40px',
        },
    }),
)

type HeaderProps = {
    title: string
    isSecondHand?: boolean
    handleClose: () => void
}

const ProductOverviewHeader: React.FC<HeaderProps> = ({ title, isSecondHand, handleClose, children }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <Grid className={classes.dialogHeader} container wrap="nowrap" justify="space-between">
                <Grid item xs={'auto'}>
                    <DialogTitle id="customized-dialog-title" disableTypography style={{ paddingTop: '0px' }}>
                        <Typography className={classes.title} variant="h3" component="h3">
                            {title}
                            {isSecondHand && (
                                <Pill label={t('wms.uniqueProducts.type.secondHand')} variant="basic" isSmall />
                            )}
                        </Typography>
                    </DialogTitle>
                </Grid>
                <Grid item xs={'auto'}>
                    <div style={{ paddingRight: '8px', borderLeft: `1px solid ${theme.palette.grey[200]}` }}>
                        <IconButton
                            style={{ marginLeft: theme.spacing(2) }}
                            size="small"
                            onClick={handleClose}
                            data-testid="uniqueProductModalClose"
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            {children}
        </>
    )
}

export default ProductOverviewHeader
