import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import TabSystem from '_organisms/TabSystem'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import InsertProductHowItWorks from './InsertProductHowItWorks'
import { ProductType } from './InsertProductHome'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scanTabsMargin: {
            [theme.breakpoints.up('md')]: {
                marginTop: theme.space(12),
            },
        },
    }),
)

type SelectTypeAndEanProps = {
    checkEanAndUpdate: (ean: string) => void
    setTypeFromTab: (tabNumber: number) => void
    hidden?: boolean
}

const SelectTypeAndEan: React.FC<SelectTypeAndEanProps> = ({ checkEanAndUpdate, setTypeFromTab, hidden }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [shouldDisplaySecondHandElements] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.shouldDisplaySecondHandElements,
    ])

    const tabLabels = shouldDisplaySecondHandElements()
        ? [
              { label: t('wms.scanUniqueProduct.rentalTab.title'), value: 'rentalTab' },
              { label: t('wms.scanUniqueProduct.secondHandTab.title'), value: 'secondHandTab' },
          ]
        : [{ label: t('wms.scanUniqueProduct.rentalTab.title'), value: 'rentalTab' }]

    if (hidden) return <></>

    return (
        <Page
            title={t('wms.scanUniqueProduct.title')}
            section={t('menu.stock.title')}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            withDivider={false}
        >
            <TabSystem tabLabels={tabLabels} onClick={setTypeFromTab}>
                <BackgroundWithScanAndFooter
                    className={classes.scanTabsMargin}
                    scanProps={{
                        placeholder: t('wms.scanUniqueProduct.eanPlaceholder'),
                        onSubmit: (ean) => checkEanAndUpdate(ean),
                    }}
                >
                    <InsertProductHowItWorks type={ProductType.RENTAL} />
                </BackgroundWithScanAndFooter>
                {shouldDisplaySecondHandElements() && (
                    <BackgroundWithScanAndFooter
                        className={classes.scanTabsMargin}
                        scanProps={{
                            placeholder: t('wms.scanUniqueProduct.eanPlaceholder'),
                            onSubmit: (ean) => checkEanAndUpdate(ean),
                        }}
                    >
                        <InsertProductHowItWorks type={ProductType.SECOND_HAND} />
                    </BackgroundWithScanAndFooter>
                )}
            </TabSystem>
        </Page>
    )
}

export default SelectTypeAndEan
