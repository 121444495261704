import * as React from 'react'
import { useState } from 'react'
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import InstructionGroupCard from '_pages/instructionGroupsManagement/InstructionGroupCard'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { IInstructionGroup, InstructionGroupActionType } from 'interfaces'
import TopSearchSection from '_molecules/TopSearchSection'
import CreateOrEditGroupModal from '_pages/instructionGroupsManagement/modals/CreateOrEditGroupModal'
import rmsApi from 'utils/api'
import { REFIT_GROUP_ROUTE } from 'utils/routes/backend'
import { ICustomer } from 'interfaces/Customer'
import { toast } from 'utils/toast'
import NoResultTip from '_molecules/NoResultTip'
import DeleteModal from '_organisms/DeleteModal'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructionGroupListContainer: {
            [theme.breakpoints.up('md')]: {
                padding: theme.space(6, 0),
            },
        },
        instructionGroupList: {
            backgroundColor: '#fff',
            transform: `translate(0, ${theme.space(3)}px)`,
        },
        instructionGroup: {
            marginBottom: theme.spacing(1),
        },
        addButton: {
            height: theme.space(8),
        },
        addIcon: {
            fontSize: theme.space(3),
            fontWeight: 400,
        },
        noGroupText: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
        },
        tip: {
            width: '100%',
        },
    }),
)

type InstructionGroupListTabProps = {
    selectedCustomer: ICustomer | undefined
    instructionGroups: IInstructionGroup[]
    selectInstructionGroup: (i: IInstructionGroup) => void
    refreshData: () => void
}

const InstructionGroupListTab: React.FC<InstructionGroupListTabProps> = ({
    selectedCustomer,
    instructionGroups,
    selectInstructionGroup,
    refreshData,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [filter, setFilter] = useState<string>('')
    const [openGroupActionModal, setOpenGroupActionModal] = useState<InstructionGroupActionType | undefined>()
    const [selectedGroup, setSelectedGroup] = useState<IInstructionGroup | undefined>()

    async function executeActionOnGroup(group: IInstructionGroup, action: InstructionGroupActionType) {
        const { name, description } = group || {}
        try {
            if (action === InstructionGroupActionType.CREATE || action === InstructionGroupActionType.CLONE) {
                await rmsApi.post(REFIT_GROUP_ROUTE(selectedCustomer!.id), { name, description })
            } else if (action === InstructionGroupActionType.EDIT) {
                await rmsApi.patch(REFIT_GROUP_ROUTE(selectedCustomer!.id, group.id!), group)
            } else if (action === InstructionGroupActionType.DELETE) {
                await rmsApi.delete(REFIT_GROUP_ROUTE(undefined, group.id!))
            }
            refreshData()
            toast.success(t(`refit.instructionGroups.successMessage.${action}`))
        } catch (e: any) {
            toast.error(`${t('refit.instructionGroups.errors.' + action)}: ${e.message}`)
        }
    }

    const filteredInstructionGroups = filter
        ? instructionGroups?.filter((group) => group?.name?.toLowerCase().includes(filter.toLowerCase()))
        : instructionGroups

    const createButton = () => (
        <Button
            onClick={() => setOpenGroupActionModal(InstructionGroupActionType.CREATE)}
            iconClass={classes.addIcon}
            startIcon={faPlus}
            shape={isMobile ? 'rounded' : undefined}
            data-testid="createGroupButton"
        >
            {!isMobile && t('refit.instructionGroups.manageGroups.groupsTab.newGroup')}
        </Button>
    )

    return (
        <div className={classes.instructionGroupListContainer} data-testid="groupList">
            <TopSearchSection
                placeholder={t('refit.instructionGroups.searchInputPlaceholder.group')}
                filter={filter}
                setFilter={setFilter}
                input={instructionGroups.length > 0}
            >
                {createButton()}
            </TopSearchSection>
            {filteredInstructionGroups?.length ? (
                filteredInstructionGroups?.map((group, index) => (
                    <InstructionGroupCard
                        key={index}
                        group={group}
                        setSelectedGroup={setSelectedGroup}
                        setOpenGroupActionModal={setOpenGroupActionModal}
                        className={classes.instructionGroup}
                        selectInstructionGroup={selectInstructionGroup}
                        data-testid={'groupCard_' + index}
                    />
                ))
            ) : (
                <NoResultTip
                    text={
                        filter
                            ? t('refit.instructionGroups.manageGroups.groupsTab.noGroupWithFilter')
                            : t('refit.instructionGroups.manageGroups.groupsTab.noGroup')
                    }
                >
                    {createButton()}
                </NoResultTip>
            )}
            {openGroupActionModal === InstructionGroupActionType.CREATE && (
                <CreateOrEditGroupModal
                    action={InstructionGroupActionType.CREATE}
                    customer={selectedCustomer}
                    onSubmit={executeActionOnGroup}
                    onClose={() => setOpenGroupActionModal(undefined)}
                />
            )}
            {openGroupActionModal === InstructionGroupActionType.EDIT && (
                <CreateOrEditGroupModal
                    action={InstructionGroupActionType.EDIT}
                    group={selectedGroup}
                    customer={selectedCustomer}
                    onSubmit={executeActionOnGroup}
                    onClose={() => setOpenGroupActionModal(undefined)}
                />
            )}
            {openGroupActionModal === InstructionGroupActionType.CLONE && (
                <CreateOrEditGroupModal
                    action={InstructionGroupActionType.CLONE}
                    group={selectedGroup}
                    customer={selectedCustomer}
                    onSubmit={(group) => executeActionOnGroup(group, InstructionGroupActionType.CREATE)}
                    onClose={() => setOpenGroupActionModal(undefined)}
                />
            )}
            {openGroupActionModal === InstructionGroupActionType.DELETE && (
                <DeleteModal
                    isOpen={openGroupActionModal === InstructionGroupActionType.DELETE}
                    onSubmit={() => {
                        executeActionOnGroup(selectedGroup!, InstructionGroupActionType.DELETE)
                        setOpenGroupActionModal(undefined)
                    }}
                    onClose={() => setOpenGroupActionModal(undefined)}
                    title={t('refit.instructionGroups.deleteGroup.title')}
                    label={t('refit.instructionGroups.manageGroups.productsTab.instructionGroup')}
                    inputValue={selectedGroup!.name}
                    tipText={t('refit.instructionGroups.manageGroups.groupsTab.deleteTip')}
                    buttonLabel={t('refit.instructionGroups.deleteGroup.delete')}
                />
            )}
        </div>
    )
}

export default InstructionGroupListTab
