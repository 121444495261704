import * as React from 'react'
import { Chip, createStyles, makeStyles, Paper, TextField, Theme } from '@material-ui/core'
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons'
import { Autocomplete } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            '& > .MuiAutocomplete-inputRoot': {
                flexWrap: 'nowrap',
            },
        },
        option: {
            cursor: 'pointer',
            color: theme.palette.neutral[800],
            fontWeight: 300,
            fontSize: '12px',
            height: theme.space(5),
            padding: theme.space(1, 3),
            whiteSpace: 'nowrap',
        },
        input: {
            display: 'flex',
            height: '40px',
            alignItems: 'center',
            border: `1px solid ${theme.palette.neutral[200]}`,
            backgroundColor: theme.palette.neutral[50],
            borderRadius: '2px',
            color: theme.palette.neutral[800],
            fontSize: theme.typography.pxToRem(12),
            '&:hover': {
                border: `1px solid ${theme.palette.primary[500]}`,
            },
            '&.Mui-focused': {
                color: theme.palette.neutral[800],
            },
        },
        menu: {
            margin: '10px 0',
            borderRadius: '2px',
            border: `1px solid ${theme.palette.neutral[200]}`,
            '& > div': {
                overflow: 'scroll',
                backgroundColor: theme.palette.primary[200],
            },
        },
        chip: {
            backgroundColor: theme.palette.neutral[600],
            color: theme.palette.common.white,
            borderRadius: '2px',
            fontSize: '12px',
            fontWeight: 400,
            height: theme.space(4),
            marginRight: theme.space(1),
        },
        chipCount: {
            padding: theme.space(0, 2),
            backgroundColor: theme.palette.primary[400],
        },
        chipIcon: {
            backgroundColor: 'transparent',
            color: theme.palette.neutral[200],
            height: theme.space(2),
        },
        magnifyingGlassIcon: {
            padding: theme.space(0, 2),
        },
        howToCustomizeOption: {}, // Keep it because it's hard to understand
    }),
)

type DropDownSearchProps = {
    placeholder: string
    options: any[]
    optionLabelKey: string // label to display in option objects
    onChangeSelection: (value: any | any[]) => void
    onClearSelection?: () => void
    multiple?: boolean
    limitTags?: number
    customRenderOption?: (value: string) => JSX.Element
    renderNoResult?: JSX.Element
    dataTestId?: string
}

const DropDownSearch: React.FC<DropDownSearchProps> = ({
    placeholder,
    options,
    optionLabelKey,
    onChangeSelection,
    onClearSelection,
    multiple = false,
    limitTags = 2,
    customRenderOption,
    renderNoResult,
    dataTestId,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const renderIcon = (icon: any) => <FontAwesomeIcon className={classes.magnifyingGlassIcon} icon={icon} />

    function onInputChange(event: any, value: string, eventType: string) {
        if (eventType === 'clear' && onClearSelection) {
            onClearSelection()
        } else if (eventType === 'select-option') {
            onChangeSelection(value)
        }
    }

    function renderTextField(params: any) {
        const ref = params.InputProps.ref
        delete params.InputProps.ref
        const InputProps = {
            ...params.InputProps,
            disableUnderline: true,
        }

        return (
            <div ref={ref} className={classes.input}>
                {renderIcon(faMagnifyingGlass)}
                <TextField
                    {...params}
                    className={classes.textField}
                    variant="standard"
                    placeholder={placeholder}
                    InputProps={InputProps}
                    data-testid={dataTestId}
                />
            </div>
        )
    }

    const renderOptions = (option: any, selected: boolean) => {
        const label = option[optionLabelKey as any]
        let renderedOption = <div className={classes.option}>{label}</div>
        if (customRenderOption) renderedOption = customRenderOption(label)
        else if (multiple)
            renderedOption = (
                <BasicCheckbox
                    className={classes.option}
                    checked={selected}
                    setValue={() => null}
                    label={label}
                    preventFormEvent
                />
            )
        return renderedOption
    }

    const renderSelectedOptions = (value: string[], getTagProps: any) => {
        return multiple ? (
            <>
                {value.slice(0, limitTags).map((option, index) => (
                    <Chip
                        key={index}
                        variant="default"
                        deleteIcon={<FontAwesomeIcon icon={faXmark} className={classes.chipIcon} />}
                        label={option[optionLabelKey as any]}
                        {...getTagProps({ index })}
                        className={classes.chip}
                    />
                ))}
                {value?.length > limitTags && (
                    <div className={`${classes.chip} ${classes.chipCount}`}>+{value?.length - limitTags}</div>
                )}
            </>
        ) : (
            value[optionLabelKey as any]
        )
    }

    return (
        <Autocomplete
            multiple={multiple}
            classes={{ option: classes.howToCustomizeOption }}
            options={options}
            PaperComponent={({ children }) => <Paper className={classes.menu}>{children}</Paper>}
            limitTags={3}
            onChange={onInputChange}
            filterSelectedOptions={!multiple}
            disableCloseOnSelect={multiple}
            getOptionLabel={(option: any) => option[optionLabelKey]}
            renderOption={(option: any, { selected }) => renderOptions(option, selected)}
            renderTags={renderSelectedOptions}
            renderInput={renderTextField}
            noOptionsText={renderNoResult || t('global.noMatch')}
        />
    )
}

export default DropDownSearch
