import * as React from 'react'

type FileInputProps = {
    handleFile: (file: File[]) => void
    multiple?: boolean
    disabled?: boolean
    accept?: string
}

// This component is not to be used independently. You must use it via FileButton.tsx component
const FileInput: React.FC<FileInputProps> = ({ handleFile, multiple, accept, disabled = false }) => {
    function submitPicture(e: any) {
        e.preventDefault()
        if (e.target.files.length) handleFile(e.target.files)
    }

    return (
        <input
            type="file"
            accept={accept ? accept : 'image/*'}
            multiple={multiple}
            onChange={(e) => submitPicture(e)}
            data-testid="buttonTakePicture"
            // This input must be invisible but cypress needs this style to be able to click on it
            style={{ width: '3px', border: 'none', opacity: 0 }}
            disabled={disabled}
        />
    )
}

export default FileInput
