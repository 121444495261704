import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import { BasicText } from '_atoms/text/BasicText'
import { IParcel } from 'interfaces'
import { RETURNED_PARCEL_ROUTE } from 'utils/routes/frontend'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        labelText: {
            color: theme.palette.grey[600],
        },
    }),
)

type ParcelInfosOverviewProps = {
    parcelInfos?: {
        customerName: string
        carrier?: string
        relatedOrder: string
        barcode?: string
        trackingLink?: string
        trackingCode?: string
        pdfUrl?: string
        relatedParcels: IParcel[]
    }
    hideBarcode?: boolean
}

const ParcelInfosOverview: React.FC<ParcelInfosOverviewProps> = ({ parcelInfos, hideBarcode = false }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const displayTwoColumns = parcelInfos?.relatedParcels?.length ? 12 : 6
    const relatedParcels = parcelInfos?.relatedParcels.length
        ? parcelInfos?.relatedParcels?.map((parcel, index) => {
              return (
                  <div key={index}>
                      <a href={`${window.location.origin}${RETURNED_PARCEL_ROUTE}/${parcel.id}/details`}>
                          {parcel.barcode}
                      </a>
                  </div>
              )
          })
        : null
    return (
        <>
            <SectionTitle className={classes.sectionTitle} size="large">
                {t('returns.parcelInfos.parcelInfos')}
            </SectionTitle>
            <Grid container>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.customer')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {parcelInfos?.customerName}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.carrier')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {parcelInfos?.carrier}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.relatedOrder')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {parcelInfos?.relatedOrder}
                </Grid>
                {!hideBarcode && (
                    <>
                        <Grid xs={6} item>
                            <BasicText className={classes.labelText}>{t('returns.parcelInfos.barcode')}</BasicText>
                        </Grid>
                        <Grid xs={6} item>
                            {parcelInfos?.barcode}
                        </Grid>
                    </>
                )}

                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.tracking')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {<a href={parcelInfos?.trackingLink}>{parcelInfos?.trackingCode}</a>}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.label')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {<a href={parcelInfos?.pdfUrl}>{t('returns.parcelInfos.label')}</a>}
                </Grid>
                <Grid xs={displayTwoColumns} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.relatedParcels')}</BasicText>
                </Grid>
                <Grid xs={displayTwoColumns} item>
                    {relatedParcels || 'N/A'}
                </Grid>
            </Grid>
        </>
    )
}

export default ParcelInfosOverview
