import * as React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page from '_organisms/Page'
import { FromSearchParam, IOrderPackingList } from 'interfaces/Packing.interface'
import Button from '_atoms/buttons/Button'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { faBox, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import EnrichedIcon from '_molecules/EnrichedIcon'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import rmsApi from 'utils/api'
import {
    PICK_PACK_ISSUE_URL,
    PREPARATIONS_ROUTE_BY_ID,
    SHIPMENTS_URL,
    SHIPMENT_PARCELS_URL,
} from 'utils/routes/backend'
import { toast } from 'utils/toast'
import { Label, PreparationStatus, Shipment } from 'interfaces'
import { PACKING_ROUTE, PICKPACK_ISSUES_LIST_ROUTE } from 'utils/routes/frontend'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import axios from 'axios'
import PDFMerger from 'pdf-merger-js/browser'
import dayjs from 'dayjs'
import { PickPackIssueType } from 'interfaces/PickPackIssue.interface'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import { JuneEvent, trackJuneEvent } from 'utils/june'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            gap: `${theme.space(2)}px`,
        },
        wrongReference: {
            [theme.breakpoints.down('sm')]: {
                flex: 6,
            },
        },
        print: {
            flex: 'auto',
        },
    }),
)

type OrderPackingFinishProps = {
    onLeavePage: () => void
    setLabelError: (labelError: boolean) => void
    orderPackingData: IOrderPackingList
    parcelNumber: number
}

const OrderPackingFinish: React.FC<OrderPackingFinishProps> = ({
    onLeavePage,
    setLabelError,
    orderPackingData,
    parcelNumber,
}) => {
    const classes = useStyles()
    const { orderId } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = useTheme()
    const [searchParams] = useSearchParams()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const buildErrorMessage = useErrorMessage()

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [pdf, setPdf] = useState<string>()

    const { customer, omsOrderId, batchId, preparationId, shipments } = orderPackingData
    const from = searchParams.get('from') as FromSearchParam

    async function finishPacking() {
        try {
            await rmsApi.patch(PREPARATIONS_ROUTE_BY_ID(preparationId), { status: PreparationStatus.FINISHED })
            trackJuneEvent(JuneEvent.PACKING_COMPLETED)
            navigate(from ? PICKPACK_ISSUES_LIST_ROUTE : PACKING_ROUTE(batchId))
            toast.success(t('new_orders.packing.packingOrderSuccess'))
        } catch (e) {
            toast.error(buildErrorMessage(e, { preparationId }, 'new_orders.errors.finishOrderPackingError'))
        }
    }

    async function reportIssue() {
        try {
            await rmsApi.post(PICK_PACK_ISSUE_URL, {
                orderId,
                issueType: PickPackIssueType.OTHER,
            })
            navigate(from ? PICKPACK_ISSUES_LIST_ROUTE : PACKING_ROUTE(batchId))
            toast.success(t('new_orders.packing.packingOrderErrorIssue'))
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { orderId }, 'new_orders.errors.createIssueError'))
        }
    }

    async function downloadBlob(url: string) {
        const res = await axios.get(url, { responseType: 'blob' })
        return res.data
    }

    async function createPdfs(forthParcels: Label[], returnParcels: Label[], deliveryNoteUrl: string) {
        const newFiles = []
        try {
            if (forthParcels?.length) {
                for (const forthParcel of forthParcels) {
                    const file = await downloadBlob(forthParcel.label)
                    newFiles.push(file)
                }
            }
            if (returnParcels?.length) {
                for (const returnParcel of returnParcels) {
                    const file = await downloadBlob(returnParcel.label)
                    newFiles.push(file)
                }
            }
            if (deliveryNoteUrl) {
                const file = await downloadBlob(deliveryNoteUrl)
                newFiles.push(file)
            }
        } catch (e) {
            setIsLoading(false)
            setLabelError(true)
            toast.error(t('wms.preparation.labels.downloadError'))
        }
        return newFiles
    }

    async function mergePdfs(files: File[]) {
        try {
            const merger = new PDFMerger()
            await Promise.all(files.map(async (file) => await merger.add(file)))
            const mergedPdf = await merger.saveAsBlob()
            setPdf(URL.createObjectURL(mergedPdf))
        } catch (e) {
            setIsLoading(false)
            setLabelError(true)
            toast.error(t('wms.preparation.labels.generationError'))
        }
    }

    function formatParcelData(shipments: Shipment[], isReturn: boolean) {
        const shipment = shipments.find((shipment) => shipment.isReturn === isReturn)
        if (shipment && shipment.parcels) {
            return shipment.parcels.map((parcel) => ({
                createdAt: new Date(parcel.createdAt),
                label: parcel.pdfUrl,
                barcode: parcel.barcode,
            }))
        }
        return []
    }

    async function createParcelsAndBuildPdf(amount: number) {
        const shipment = shipments.find((shipment) => !shipment.isReturn)!
        try {
            let forthParcels = formatParcelData(shipments, false)
            let returnParcels = formatParcelData(shipments, true)

            if (forthParcels.length === 0) {
                const parcelsData = await rmsApi.post(SHIPMENT_PARCELS_URL(shipment.id), { amount })
                forthParcels = parcelsData.data.forthParcels
                returnParcels = parcelsData.data.returnParcels
            }

            let { deliveryNoteUrl } = shipment
            if (!deliveryNoteUrl && orderPackingData.customer.deliveryNote) {
                const deliveryNoteData = await rmsApi.post(`${SHIPMENTS_URL}/${shipment.id}/delivery-note`)
                deliveryNoteUrl = deliveryNoteData.data
            }
            const files = await createPdfs(forthParcels, returnParcels, deliveryNoteUrl)
            await mergePdfs(files)
        } catch (e) {
            setLabelError(true)
            toast.error(buildErrorMessage(e, { shipmentId: shipment.id }, 'new_orders.errors.createParcelError'))
        }
    }

    async function shareMobile(blobFile: Blob, fileName: string) {
        try {
            const pdf = new File([blobFile], fileName, { type: 'application/pdf' })
            const files = { files: [pdf] } as ShareData
            await navigator.share(files)
        } catch {
            setIsLoading(false)
            toast.error(t('label.printError'))
        }
    }

    const printIframe = (fileType: string) => {
        const iframe = (document as any).frames ? (document as any).frames[fileType] : document.getElementById(fileType)
        const iframeWindow = iframe?.contentWindow || iframe

        // diasble on local because cypress tests fail on local if iframe is open
        if (import.meta.env.REACT_APP_BUGSNAG_STAGE !== 'local') {
            if (iframe) iframe.focus()
            if (iframeWindow) iframeWindow.print()
        }
    }

    async function togglePrintDialog(filesUrl: string | undefined, name: string, fileType: string) {
        if (!filesUrl) return
        const fileName = `${name}-${dayjs(new Date()).format('HHmmssDDMMYY')}.pdf`

        if (isMobile && navigator['share']) {
            const downloadedFileQuery = await axios.get(filesUrl, {
                responseType: 'blob',
            })
            await shareMobile(downloadedFileQuery?.data, fileName)
        } else {
            printIframe(fileType)
        }
    }

    useEffect(() => {
        createParcelsAndBuildPdf(parcelNumber)
    }, [])

    useEffect(() => {
        if (pdf) {
            setTimeout(() => {
                togglePrintDialog(pdf, t('wms.preparation.labels.filename'), 'iframe-pdfs')
                setIsLoading(false)
            }, 500)
        }
    }, [pdf])

    const footer = () => (
        <div className={classes.footer}>
            <Button
                variant="stroke"
                color="neutral"
                onClick={reportIssue}
                startIcon={faTriangleExclamation}
                className={classes.wrongReference}
                data-testid="report-issue-button"
                label={t('new_orders.packing.reportIssue')}
            />
            <Button
                onClick={finishPacking}
                endIcon={faArrowRight}
                className={classes.print}
                data-testid="finish-packing-button"
                label={t('new_orders.packing.finish')}
            />
        </div>
    )

    const renderIframe = () => (
        <iframe data-testid="pdfsIframe" id="iframe-pdfs" title="iframe-pdfs" src={pdf} style={{ display: 'none' }} />
    )

    if (isLoading) {
        return (
            <>
                <Page title={t('new_orders.packing.generatingPdfs')} handlePrevious={() => onLeavePage()}>
                    <EnrichedIcon
                        icon={faLoader}
                        title={t('new_orders.packing.loadingPage.title')}
                        tagline={t('new_orders.packing.loadingPage.pdfTagline')}
                        iconProps={{ spin: true }}
                    />
                </Page>
                {pdf && renderIframe()}
            </>
        )
    }

    return (
        <>
            <Page title={`${customer.name} #${omsOrderId}`} handlePrevious={() => onLeavePage()}>
                <BackgroundWithScanAndFooter footer={footer()} footerInBoxDesktop={true}>
                    <EnrichedIcon
                        icon={faBox}
                        title={t('new_orders.packing.packingProcesedTitle')}
                        tagline={t('new_orders.packing.packingProcesedTagline')}
                    />
                </BackgroundWithScanAndFooter>
            </Page>
            {pdf && renderIframe()}
        </>
    )
}

export default OrderPackingFinish
