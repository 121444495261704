import * as React from 'react'
import ProductCard from '_organisms/ProductCard'
import { createStyles, makeStyles } from '@material-ui/core'
import Button, { ButtonProps } from '_atoms/buttons/Button'
import { IUniqueProductOrProductReference } from 'interfaces/Batch.interface'

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            backdropFilter: 'blur(1px)',
            display: 'flex',
            '& button': {
                margin: 'auto',
            },
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            '& div': {
                gridRowStart: 1,
                gridColumnStart: 1,
            },
        },
    }),
)

type PickPackProductCardProps = {
    product: IUniqueProductOrProductReference
    dataTestId?: string
    buttonProps?: ButtonProps
}

const PickPackProductCard: React.FC<PickPackProductCardProps> = ({ product, dataTestId = '', buttonProps }) => {
    const classes = useStyles()

    return (
        <div className={classes.grid} data-testid={dataTestId}>
            {buttonProps && (
                <div className={classes.buttonContainer}>
                    <Button {...buttonProps} />
                </div>
            )}
            <ProductCard
                barcodeUid={product.barcodeUid}
                ean={product.eans ? product.eans[0]?.ean : product.productReference!.eans[0]?.ean}
                image={product.image || product.productReference?.image || null}
                name={product.name || product.productReference!.name}
            />
        </div>
    )
}

export default PickPackProductCard
