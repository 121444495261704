import * as React from 'react'
import { useState } from 'react'
import { IParcel, IUniqueProduct, UniqueProductStatus } from 'interfaces'
import Stepper from '_organisms/Stepper'
import CheckReferenceStep from '_pages/returnParcel/SecondHand/CheckReferenceStep'
import CheckConditionStep from '_pages/returnParcel/SecondHand/CheckConditionStep'
import SetPUIDStep from '_pages/returnParcel/SecondHand/SetPUIDStep'
import rmsApi from 'utils/api'
import { PROCESS_UNIQUE_PRODUCT_RETURN, UNIQUE_PRODUCT_UPDATE_PUID_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import { RejectSecondHandReason } from 'interfaces/SecondHand.interface'
import SecondHandProductList from './SecondHandProductList'
import { isSecondHandUntreatedProduct } from '../../../components/reusable/utils/UniqueProductsUtils'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'

type ParcelContentProps = {
    parcel: IParcel
    possibleUniqueProducts: IUniqueProduct[]
    processedUniqueProducts: IUniqueProduct[] | undefined
    refreshProductList: () => void
    onSubmit: () => void
    onCancel: () => void
}

const SecondHandParcelPage: React.FC<ParcelContentProps> = ({
    parcel,
    possibleUniqueProducts,
    processedUniqueProducts,
    refreshProductList,
    onSubmit,
    onCancel,
}) => {
    const buildErrorMessage = useErrorMessage()

    const [selectedUniqueProduct, setSelectedUniqueProduct] = useState<IUniqueProduct>()
    const [step, setStep] = useState(0)

    const postSecondHandProcessedStatus = async (
        status: string,
        reason?: RejectSecondHandReason,
        barcodeUid?: string,
    ) => {
        await rmsApi.post(PROCESS_UNIQUE_PRODUCT_RETURN(parcel.id), {
            status: status,
            comment: reason,
            barcodeUid: barcodeUid || selectedUniqueProduct?.barcodeUid,
            files: [],
        })
    }

    const publishNewProduct = async (newBarcodeUid?: string) => {
        // SH- is the only way to identify a second hand product who is not set yet
        if (isSecondHandUntreatedProduct(selectedUniqueProduct) && newBarcodeUid) {
            const success = await applyNewPuid(newBarcodeUid)
            if (!success) return
            await postSecondHandProcessedStatus(UniqueProductStatus.TO_REFIT, undefined, newBarcodeUid)
        } else {
            await postSecondHandProcessedStatus(UniqueProductStatus.TO_REFIT)
        }
        setSelectedUniqueProduct(undefined)
        refreshProductList()
        setStep(0)
    }

    const handleRefuse = async (reason: RejectSecondHandReason) => {
        await postSecondHandProcessedStatus(UniqueProductStatus.REFUSED, reason)
        refreshProductList()
        setSelectedUniqueProduct(undefined)
        setStep(0)
    }

    const applyNewPuid = async (newBarcodeUid: string): Promise<boolean> => {
        const oldBarcode = selectedUniqueProduct?.barcodeUid
        try {
            await rmsApi.post(UNIQUE_PRODUCT_UPDATE_PUID_URL, {
                barcodeUid: selectedUniqueProduct?.barcodeUid,
                newBarcodeUid,
            })
            return true
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { barcodeUid: oldBarcode, newBarcodeUid }, 'refreshPUID.unknownError'))
            return false
        }
    }

    const stepperChildren = [
        selectedUniqueProduct && (
            <CheckReferenceStep
                key="CheckReferenceStep"
                selectedUniqueProduct={selectedUniqueProduct}
                setSelectedUniqueProduct={setSelectedUniqueProduct}
                handleRefuse={handleRefuse}
            />
        ),
        selectedUniqueProduct && (
            <CheckConditionStep
                key="CheckConditionStep"
                uniqueProduct={selectedUniqueProduct}
                handleRefuse={handleRefuse}
            />
        ),
        (selectedUniqueProduct?.status === UniqueProductStatus.NOT_SET ||
            isSecondHandUntreatedProduct(selectedUniqueProduct)) && <SetPUIDStep key="setPUIDStep" />,
    ].filter(Boolean) as JSX.Element[]

    return (
        <>
            {!selectedUniqueProduct ? (
                <SecondHandProductList
                    parcel={parcel}
                    possibleUniqueProducts={possibleUniqueProducts}
                    processedUniqueProducts={processedUniqueProducts}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    setSelectedUniqueProduct={setSelectedUniqueProduct}
                />
            ) : (
                <Stepper
                    currentStep={step}
                    setCurrentStep={setStep}
                    onSubmit={(newBarcodeUid: string) => publishNewProduct(newBarcodeUid)}
                    onCancel={() => setSelectedUniqueProduct(undefined)}
                >
                    {stepperChildren}
                </Stepper>
            )}
        </>
    )
}

export default SecondHandParcelPage
