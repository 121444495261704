import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Pill from '_atoms/badges/Pill'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.space(2 / 5, 1),
            borderRadius: theme.space(4 / 5),
            textTransform: 'uppercase',
            height: 'fit-content',
            fontWeight: 400,
            '& .MuiChip-label': {
                padding: 0,
                lineHeight: theme.typography.pxToRem(14),
                fontSize: theme.typography.pxToRem(9),
            },
        },
    }),
)

const UrgentChip: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Pill
            label={t('new_orders.pickPack.batchCard.urgentLabel')}
            variant="stroke"
            isSmall
            className={classes.root}
        />
    )
}

export default UrgentChip
