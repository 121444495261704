import { IRefitInstruction } from './RefitInstruction.interface'

export enum RefitOperationStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    PAUSED = 'PAUSED',
    WAITING = 'WAITING',
    FINISHED = 'FINISHED',
    ABORTED = 'ABORTED',
    IGNORED = 'IGNORED',
}

export interface IRefitOperation {
    id: number
    refitInstructionId: number
    refitInstruction: IRefitInstruction
    status: RefitOperationStatus
    uniqueProductId: number
    duration: number
}

export interface INewRefitOperation {
    refitInstructionId: number
    status: RefitOperationStatus
    uniqueProductId: number
    refitLocationName?: string
    isFirstRefitOperation?: boolean
}
