import { useState, useEffect } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, createStyles } from '@material-ui/core'
import { RETURN_STATS_URL } from 'utils/routes/backend'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import Figure from 'components/statistics/Figure'
import dayjs, { Dayjs } from 'dayjs'
import { fetchStats, getChartsColors, getDoughnutOptions, getStatsStyles } from 'components/reusable/utils/StatsUtils'
import PageContentHeader from 'components/reusable/PageContentHeader'
import Page from '_organisms/Page'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

ChartJS.register(...registerables)

const statsStyles = getStatsStyles()
const useStyles = makeStyles(() => createStyles(statsStyles))

const ReturnStatsApp: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [stats, setStats] = useState<any>(null)
    const [fullChartOptions, setFullChartOptions] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean | string>(true)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    useEffect(() => {
        fetchStats(setIsLoading, RETURN_STATS_URL, formatStatsData, startDate, endDate)
        // eslint-disable-next-line
    }, [selectedCustomers, selectedWarehouse, startDate, endDate])

    const doughnutOptions: any = getDoughnutOptions()
    const chartColors: string[] = getChartsColors()
    ChartJS.overrides.doughnut.plugins.tooltip = doughnutOptions.plugins.tooltip

    const formatStatsData = (statsToFormat: any) => {
        const formattedData: any = {}
        const buildingChartOptions: any = {}
        setFullChartOptions({})

        formattedData.distributionOfParcelsReturnedByCarrier = {
            labels: statsToFormat.distributionOfParcelsReturnedByCarrier.map((item: any) => {
                return item.name
            }),
            datasets: [
                {
                    label: 'label',
                    backgroundColor: chartColors,
                    data: statsToFormat.distributionOfParcelsReturnedByCarrier.map((item: any) => {
                        return item.nb_return_parcels_sent
                    }),
                },
            ],
        }
        buildingChartOptions.distributionOfParcelsReturnedByCarrier = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.distributionOfParcelsReturnedByCarrier.plugins.title.text = t(
            'returnStats.figures.distributionOfParcelsReturnedByCarrier.description',
        )
        buildingChartOptions.distributionOfParcelsReturnedByCarrier.plugins.title.align = 'start'
        formattedData.parcelsReturnedOnDaterange = {
            figureDescription: 'returnStats.figures.parcelsReturnedOnDaterange.description',
            value: Number(statsToFormat.parcelsReturnedOnDaterange[0].nb_returned_parcels).toLocaleString(),
        }
        formattedData.parcelsProcessedOnDaterange = {
            figureDescription: 'returnStats.figures.parcelsProcessedOnDaterange.description',
            value: Number(statsToFormat.parcelsProcessedOnDaterange[0].nb_return_parcels_processed).toLocaleString(),
        }
        formattedData.uniqueProductsProcessedOnDaterange = {
            figureDescription: 'returnStats.figures.uniqueProductsProcessedOnDaterange.description',
            value: Number(
                statsToFormat.uniqueProductsProcessedOnDaterange[0].nb_returned_products_processed,
            ).toLocaleString(),
        }

        setFullChartOptions(buildingChartOptions)
        setStats(formattedData)
        setIsLoading(false)
    }

    const handleEndDateChange = (endDateToSet: Dayjs) => {
        if (endDateToSet.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDateToSet).subtract(1, 'day'))
            setEndDate(endDateToSet)
        } else {
            setEndDate(endDateToSet)
        }
    }

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.returns')}
            tagline={t('returnStats.tagline')}
        >
            <PageContentHeader
                title="returnStats.content.title"
                tagline="returnStats.content.tagline"
                isLoading={isLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={handleEndDateChange}
            />
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={6}>
                    {stats?.parcelsProcessedOnDaterange && (
                        <Figure
                            testId="parcels-processed-on-daterange"
                            figure={stats.parcelsProcessedOnDaterange}
                            main={true}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.chartContainer}>
                        {stats?.distributionOfParcelsReturnedByCarrier && (
                            <Chart
                                type="doughnut"
                                data={stats?.distributionOfParcelsReturnedByCarrier}
                                options={fullChartOptions?.distributionOfParcelsReturnedByCarrier}
                            />
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {stats?.parcelsReturnedOnDaterange && (
                        <Figure
                            testId="parcels-returned-on-daterange"
                            figure={stats.parcelsReturnedOnDaterange}
                            withColor={true}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {stats?.uniqueProductsProcessedOnDaterange && (
                        <Figure
                            testId="unique-products-processed-on-daterange"
                            figure={stats.uniqueProductsProcessedOnDaterange}
                            withColor={true}
                        />
                    )}
                </Grid>
            </Grid>
        </Page>
    )
}

export default ReturnStatsApp
