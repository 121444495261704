import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { toUpper } from 'lodash'
import { IUniqueProduct, UniqueProductStatus } from 'interfaces'
import { WMS_UNIQUE_PRODUCT_LIST_URL_TO_CSV } from 'utils/routes/backend'
import rmsApi from 'utils/api'

type UniqueProductFilters = {
    orderDirection: string | undefined
    orderBy: any
    searchBarValue?: string
    statusFilter: UniqueProductStatus | null
    customerFilter: string | null
}

export async function downloadProductListToCsv(filters?: UniqueProductFilters): Promise<any> {
    try {
        const filtered = filters?.searchBarValue || filters?.statusFilter || filters?.customerFilter
        const upperOrder = filters?.orderDirection ? toUpper(filters.orderDirection) : ''
        const downloadDate = dayjs().format('YYYY-MM-DD-HH-mm-ss')
        const filteredFile = filtered ? '-filtered' : ''
        let uniqueProductCsv = null

        if (filtered) {
            let productListToCsvQuery = `${WMS_UNIQUE_PRODUCT_LIST_URL_TO_CSV}?sortBy=${filters.orderBy}&sortOrder=${upperOrder}&searchBarParameter=${filters?.searchBarValue}`
            if (filters?.statusFilter) productListToCsvQuery += `&status=${filters.statusFilter}`
            if (filters?.customerFilter) productListToCsvQuery += `&customerName=${filters.customerFilter}`
            uniqueProductCsv = await rmsApi.get(productListToCsvQuery, { responseType: 'blob' })
        } else uniqueProductCsv = await rmsApi.get(`${WMS_UNIQUE_PRODUCT_LIST_URL_TO_CSV}`, { responseType: 'blob' })

        fileDownload(uniqueProductCsv.data, `unique-products${filteredFile}-${downloadDate}.csv`)
    } catch (e: any) {
        console.error(e.message)
    }
}

export function isSecondHandUntreatedProduct(uniqueProduct: IUniqueProduct | undefined): boolean | undefined {
    if (uniqueProduct) return uniqueProduct?.barcodeUid.startsWith('SH-')
}
