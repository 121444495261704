import { useState } from 'react'
import * as React from 'react'
import DropdownValuePicker from './DropDownValuePicker'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type MonthPickerProps = {
    value?: number | undefined
    onChange: (arg: any) => void
}

export const MonthPicker: React.FC<MonthPickerProps> = ({ value, onChange }) => {
    const { t } = useTranslation()
    const [month, setMonth] = useState(value || dayjs().month())

    const possibleValues: { value: string | number; label: string }[] = []
    for (let index = 0; index <= 11; index++) possibleValues.push({ value: index, label: t(`dates.months.${index}`) })

    function handleChange(event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) {
        value = event.target.value as number
        onChange(value)
        setMonth(value)
    }
    return <DropdownValuePicker value={month} choices={possibleValues} onChange={handleChange}></DropdownValuePicker>
}

type YearPickerProps = {
    value?: number | undefined
    range: number
    onChange: (arg: any) => void
}

export const YearPicker: React.FC<YearPickerProps> = ({ value, range, onChange }) => {
    const [year, setYear] = useState(value || dayjs().year())

    const currentYear = dayjs().year()
    const possibleValues = []
    for (let i = 0; i < range; i++) possibleValues.push({ value: currentYear - i, label: (currentYear - i).toString() })

    function handleChange(event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) {
        value = event.target.value as number
        onChange(value)
        setYear(value)
    }
    return <DropdownValuePicker value={year} choices={possibleValues} onChange={handleChange}></DropdownValuePicker>
}

type DayPickerProps = {
    value?: number | string | undefined
    range: number
    onChange: (arg: any) => void
}

export const DayPicker: React.FC<DayPickerProps> = ({ value, range, onChange }) => {
    const [day, setDay] = useState(value || dayjs().format('YYYY-MM-DD'))

    const possibleValues = []
    for (let i = 0; i < range; i++) {
        const currentDay = dayjs().add(i, 'day')
        possibleValues.push({ value: dayjs().add(i, 'day').format('YYYY-MM-DD'), label: currentDay.format('DD-MMM') })
    }

    function handleChange(event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) {
        value = event.target.value as number
        onChange(value)
        setDay(value)
    }
    return <DropdownValuePicker value={day} choices={possibleValues} onChange={handleChange}></DropdownValuePicker>
}
