import { useEffect, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import 'chart.js'

import { ACCOUNTING } from 'utils/routes/backend'
import { MonthPicker, YearPicker } from 'components/reusable/DropDownDatePickers'
import SectionHeader from 'components/financeApp/HeaderSection'
import Section from 'components/reusable/Section'
import StatCard from 'components/financeApp/StatCard'
import { RMS_BIRTH } from 'utils/common'
import rmsApi from 'utils/api'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            paddingLeft: theme.spacing(3),
            '& .MuiTypography-root': {
                '& .MuiSvgIcon-root': {
                    paddingLeft: theme.spacing(1),
                    height: '1.25em',
                    width: '1.25em',
                    verticalAlign: '-20%',
                },
            },
            '& .MuiTypography-gutterBottom': {
                marginTop: '1em',
                marginBottom: '1em',
            },
        },
    }),
)

type rawNetType = {
    raw: string
    net: string
}
type overviewType = {
    monthlyPayments: {
        amount: string
        logisticFees: string
        paymentFees: string
    }[]
    rawIncome: rawNetType
    netIncome: rawNetType
}

type financialDataType = {
    margins: {
        margin: string
        percent: string
    }
    outcomes: {
        lizeeFees: rawNetType
        stripeFees: rawNetType
        refund: string
    }
    incomes: {
        grossIncome: rawNetType
        netIncome: rawNetType
    }
}

const FinancesApp: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [year, setYear] = useState<number>(dayjs().get('year'))
    const [month, setMonth] = useState<number>(dayjs().get('month'))
    const [financialData, setFinancialData] = useState<financialDataType | null>(null)
    const [overview, setOverview] = useState<overviewType | null>(null)

    useEffect(() => {
        fetchAccounting()
        // eslint-disable-next-line
    }, [month, year])

    async function fetchAccounting() {
        const promises = [
            rmsApi.get(`${ACCOUNTING}?fromYear=${year}&fromMonth=${month}`),
            rmsApi.get(`${ACCOUNTING}/year?year=${year}&fromMonth=${month}`),
        ]
        const [monthly, yearly] = await Promise.all(promises)

        setFinancialData(monthly.data)
        setOverview(yearly.data)
    }

    return (
        <Container maxWidth="md">
            <Grid container direction="column" spacing={6}>
                <Grid item>
                    <div className={classes.sectionHeader}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="h1">{t('finances.moneyFeedTitle')}</Typography>
                            </Grid>
                            <Grid item>
                                <YearPicker range={dayjs().year() - RMS_BIRTH} onChange={setYear} />
                            </Grid>
                        </Grid>
                        <SectionHeader overview={overview} />
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.sectionHeader}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="h2">{t('finances.monthlyFeedTitle')}</Typography>
                            </Grid>
                            <Grid item>
                                <MonthPicker onChange={setMonth} />
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container direction="column" spacing={3}>
                        <Section title={t('finances.incomes.title')}>
                            <StatCard
                                title={t('finances.incomes.raw')}
                                mainValue={financialData?.incomes.grossIncome.raw}
                                secondaryValue={financialData?.incomes.grossIncome.net}
                                size="big"
                                loading={financialData === null}
                            />
                            <StatCard
                                title={t('finances.incomes.net')}
                                mainValue={financialData?.incomes.netIncome.raw}
                                secondaryValue={financialData?.incomes.netIncome.net}
                                size="big"
                                loading={financialData === null}
                            />
                        </Section>
                        <Section title={t('finances.outcomes.title')}>
                            <StatCard
                                title={t('finances.outcomes.stripes')}
                                mainValue={financialData?.outcomes.stripeFees.raw}
                                secondaryValue={financialData?.outcomes.stripeFees.net}
                                suffix="TTC"
                                extraSuffix="HT"
                                size="big"
                                loading={financialData === null}
                            />
                            <StatCard
                                title={t('finances.outcomes.lizee')}
                                mainValue={financialData?.outcomes.lizeeFees.raw}
                                secondaryValue={financialData?.outcomes.lizeeFees.net}
                                suffix="TTC"
                                extraSuffix="HT"
                                size="big"
                                loading={financialData === null}
                            />
                        </Section>
                        <Section title={t('finances.margins.title')}>
                            <StatCard
                                title={t('finances.margins.numbers')}
                                mainValue={financialData?.margins.margin}
                                suffix="TTC"
                                extraSuffix="HT"
                                size="big"
                                loading={financialData === null}
                            />
                            <StatCard
                                title={t('finances.margins.percentage')}
                                mainValue={financialData?.margins.percent ? financialData?.margins.percent : 0}
                                currency={'%'}
                                size="big"
                                loading={financialData === null}
                            />
                        </Section>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default FinancesApp
