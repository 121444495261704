import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Grid, makeStyles, Theme, createStyles, Typography, Button, useTheme, useMediaQuery } from '@material-ui/core'
import Lottie from 'react-lottie-player'
import lottieJson from '../assets/404.json'
import Page from '_organisms/Page'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        message: {
            margin: theme.spacing(1, 0, 3, 0),
        },
    }),
)

const WIDTH_DESKTOP = 800
const WIDTH_MOBILE = 300

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Page withHeader={false}>
            <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: isMobileDevice ? WIDTH_MOBILE : WIDTH_DESKTOP }}
                />
                <Typography variant="h1">{t('notFound.title')}</Typography>
                <Typography component="div" className={classes.message}>
                    {t('notFound.message')}
                </Typography>
                <Link to={'/'}>
                    <Button variant="contained" color="primary">
                        {t('notFound.button')}
                    </Button>
                </Link>
            </Grid>
        </Page>
    )
}

export default NotFoundPage
