import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { color: string; size: string }>((theme: Theme) =>
    createStyles({
        dashContainer: {
            flex: 0,
            flexGrow: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        horizontal: {
            width: (props) => props.size || '100%',
            height: '1px',
            backgroundColor: (props) => props.color || theme.palette.neutral[200],
        },
        vertical: {
            width: '1px',
            height: (props) => props.size || theme.space(4),
            margin: theme.space(0, 3),
            backgroundColor: (props) => props.color || theme.palette.neutral[200],
        },
        oblic: {
            width: '1px',
            height: (props) => props.size || theme.space(4),
            margin: theme.space(0, 3),
            backgroundColor: (props) => props.color || theme.palette.neutral[200],
            // This modifies z-index and makes the dash appear on top of the content
            // to prevent this behaviour add a wrapper with position relative and z-index 1
            transform: 'rotate(25deg)',
        },
    }),
)

type DashProps = {
    color?: string
    orientation?: 'horizontal' | 'vertical' | 'oblic'
    size?: string
    children?: React.ReactNode
    className?: string
    [props: string]: any
}

const Dash: React.FC<DashProps> = ({ color = '', orientation = 'horizontal', size = '10px', className }) => {
    const classes = useStyles({ color, size })
    return (
        <div className={classes.dashContainer}>
            <div className={`${classes.default} ${classes[orientation]} ${className}`} />
        </div>
    )
}

export default Dash
