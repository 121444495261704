import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { IUniqueProduct } from 'interfaces'
import ProductVariantDetail from '../components/reusable/ProductVariantDetail'
import Pill from '_atoms/badges/Pill'
import { FILE_DOWNLOAD_URL } from 'utils/routes/backend'

import rmsApi from '../utils/api'
import { toast } from 'utils/toast'
import ImageOffOutlineIcon from 'mdi-react/ImageOffOutlineIcon'
import BarcodeScanIcon from 'mdi-react/BarcodeScanIcon'
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon'
import ArrowSplitVerticalIcon from 'mdi-react/ArrowSplitVerticalIcon'
import PaletteIcon from 'mdi-react/PaletteIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            borderRadius: 5,
            margin: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
            boxShadow: '2px 5px 12px 0px #545454',
        },
        cardGrid: {
            alignItems: 'center',
            padding: '0 5px',
        },
        productPictureContainer: {
            position: 'relative',
            flexBasis: 'auto',
            height: theme.spacing(18),
            width: '100%',
            overflow: 'hidden',
            borderRadius: '4%',
        },
        productPicture: {
            objectFit: 'contain',
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            margin: 'auto',
        },
        cardContent: {
            padding: `${theme.spacing(1)}px`,
            '&:last-child': {
                paddingBottom: theme.spacing(1),
            },
        },
        productName: {
            paddingLeft: theme.spacing(0.5),
        },
        variantDetailContainer: {
            marginLeft: theme.spacing(1),
        },
        quantity: {
            justifyContent: 'flex-end',
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(0.5),
        },
        box: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 3,
            backgroundColor: theme.palette.grey[500],
        },
        quantityCounter: {
            margin: theme.spacing(0.5, 1),
            color: theme.palette.common.white,
        },
        counterComplete: {
            backgroundColor: theme.palette.success.main,
        },
        iconNoPicture: {
            textAlign: 'center',
            color: theme.palette.grey[400],
            height: '10em',
            width: '10em',
        },
        marginBottom: {
            marginBottom: theme.spacing(0.5),
        },
    }),
)

type UniqueProductPreparationCardProps = {
    uniqueProduct: IUniqueProduct
    picked: boolean
    dataTestId: string
    onClick: (uniqueProduct: IUniqueProduct, photo: string | null) => void
}

const UniqueProductPreparationCard: React.FC<UniqueProductPreparationCardProps> = ({
    uniqueProduct,
    picked = false,
    dataTestId,
    onClick,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [uniqueProductPhoto, setUniqueProductPhoto] = useState<string | null>(null)

    const { productReference, storageLocation, barcodeUid, photos } = uniqueProduct
    const { name, image, color, size } = productReference

    async function fetchFirstPhotoOfUniqueProduct() {
        const customerId = productReference.product?.customerId
        if (!photos?.length || !customerId) {
            setUniqueProductPhoto(image)
            return
        }

        try {
            const firstPhoto = photos[0]
            const res = await rmsApi.get(`${FILE_DOWNLOAD_URL}?filenames[]=${firstPhoto.name}`)
            setUniqueProductPhoto(res.data?.length ? res.data[0] : null)
        } catch (e: any) {
            toast.error(t('wms.pickPackTable.errorDownloadPhoto'))
            setUniqueProductPhoto(image)
        }
    }

    useEffect(() => {
        fetchFirstPhotoOfUniqueProduct()
        // eslint-disable-next-line
    }, [])

    return (
        <Card
            className={classes.card}
            onClick={() => onClick(uniqueProduct, uniqueProductPhoto)}
            data-testid={dataTestId}
        >
            <Grid className={classes.cardGrid} container>
                <Grid className={classes.productPictureContainer} item xs={4}>
                    {uniqueProductPhoto ? (
                        <CardMedia
                            component="img"
                            className={classes.productPicture}
                            image={uniqueProductPhoto}
                            alt="Product image"
                        />
                    ) : (
                        <ImageOffOutlineIcon className={classes.iconNoPicture} />
                    )}
                </Grid>
                <Grid item xs={8}>
                    <CardContent className={classes.cardContent}>
                        <Pill
                            label={t('wms.pickPackTable.secondHandProductChipLabel')}
                            variant="stroke"
                            className={classes.marginBottom}
                        />
                        <Typography className={classes.productName} variant="h5" component="div" gutterBottom>
                            {name}
                        </Typography>
                        <Divider></Divider>
                        <div className={classes.variantDetailContainer}>
                            {barcodeUid && (
                                <ProductVariantDetail
                                    label={t('wms.uniqueProducts.columnID')}
                                    value={barcodeUid}
                                    icon={<BarcodeScanIcon />}
                                    valueUppercase={false}
                                />
                            )}
                            {storageLocation && (
                                <ProductVariantDetail
                                    label={t('wms.uniqueProducts.storageLocation')}
                                    value={storageLocation}
                                    icon={<GoogleMapsIcon />}
                                />
                            )}
                            {size && (
                                <ProductVariantDetail
                                    label={t('wms.productReferences.size')}
                                    value={size}
                                    icon={<ArrowSplitVerticalIcon />}
                                />
                            )}
                            {color && (
                                <ProductVariantDetail
                                    label={t('wms.productReferences.color')}
                                    value={color}
                                    icon={<PaletteIcon />}
                                />
                            )}
                        </div>
                        <Grid container className={classes.quantity}>
                            <Box className={`${classes.box} ${picked ? classes.counterComplete : ''}`}>
                                <Typography className={classes.quantityCounter} variant="h5" component="div">
                                    {picked ? '1/1' : '0/1'}
                                </Typography>
                            </Box>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export default UniqueProductPreparationCard
