import {
    FILE_DELETE_URL,
    FILE_DOWNLOAD_URL,
    FILE_SIGNED_URL_CUSTOMER_BUCKET,
    FILE_SIGNED_URL_RMS_BUCKET,
} from './routes/backend'
import axios from 'axios'
import rmsApi from './api'

export enum EntityType {
    RETURN_ITEM_OR_RETURN_PACKAGING = 'return',
    UNIQUE_PRODUCT = 'unique_product',
    INSTRUCTION = 'instruction',
    REFIT_ISSUE = 'refit_issue',
}

export interface RMSFile extends File {
    id?: number
    signedUrl?: string
    remoteName?: string // file name on S3 storage (will replace file name on db)
    createdAt?: Date
    updatedAt?: Date
    uniqueProductId?: number
    returnItemId?: number
    returnPackagingId?: number
    refitInstructionId?: number
    url?: string
    position?: number
}

export async function uploadFiles(files: RMSFile[]): Promise<any> {
    const allPromises = files?.map((file) => {
        if (file.signedUrl)
            return axios.put(file!.signedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            })
    })
    return await Promise.all(allPromises)
}

// If customerId is specified, file will be uploaded on customer private sylius bucket
export async function getSignedUrl(file: RMSFile, entityType: EntityType, customerId?: number): Promise<RMSFile> {
    let baseUrl = FILE_SIGNED_URL_RMS_BUCKET
    if (customerId) baseUrl = FILE_SIGNED_URL_CUSTOMER_BUCKET(customerId)

    const encodedFileName = encodeURIComponent(file.name.toLowerCase())
    const res = await rmsApi.get(`${baseUrl}?name=${encodedFileName}&type=${file.type}&entityType=${entityType}`)
    file.remoteName = res.data?.remoteFileName
    file.signedUrl = res.data?.signedUrl
    return file
}

export async function deleteS3File(fileId: number | undefined): Promise<Error | null> {
    if (!fileId) return null
    const res = await rmsApi.delete(FILE_DELETE_URL.replace(':id', fileId.toString()))
    return res.data.error || null
}

export async function downloadFiles(
    files: RMSFile[] | Partial<RMSFile>[] | null | undefined,
    path?: string,
): Promise<string[] | undefined> {
    if (!files?.length) return

    const params = files.map((file) => `filenames[]=${file?.name}`).join('&')
    const url = `${path || FILE_DOWNLOAD_URL}?${params}`
    const pics = await rmsApi.get(url)
    return pics?.data
}
