import { Packaging } from './Packaging.interface'
import { ReturnItem } from './ReturnItem.interface'
import { Shipment } from './Shipment.interface'
import { SecondHandRequest } from './SecondHand.interface'

export enum ParcelStatus {
    CREATED = 'CREATED',
    TO_WITHDRAW = 'TO_WITHDRAW',
    PENDING = 'PENDING',
    SHIPPED = 'SHIPPED',
    IN_TRANSIT = 'IN_TRANSIT',
    ARRIVED = 'ARRIVED',
    PROCESSED = 'PROCESSED',
}

export enum ParcelStatusDisplay {
    CREATED = 'neutral',
    TO_WITHDRAW = 'neutral',
    PENDING = 'neutral',
    SHIPPED = 'success',
    IN_TRANSIT = 'primary',
    ARRIVED = 'success',
    PROCESSED = 'success',
}

export interface IParcel {
    id: number
    pdfUrl: string
    carrierParcelRef: string
    barcode: string
    trackingLink?: string
    trackingCode?: string
    shipment: Shipment
    shippedAt: string
    createdAt: string
    updatedAt: string
    status: ParcelStatus
    statusHistory: any
    isReturn: boolean
    returnPackaging: Packaging
    returnItems: ReturnItem[]
    secondHandRequest: SecondHandRequest
}

export enum ReturnParcelStep {
    START = 'START',
    PARCEL = 'PARCEL',
    PRODUCT = 'PRODUCT',
}

export enum ReturnParcelSecondHandStep {
    PARCEL = 'PARCEL',
    CHECK_REFERENCE = 'CHECK_REFERENCE',
    CHECK_QUALITY = 'CHECK_QUALITY',
    ATTACH_PUID = 'ATTACH_PUID',
}

export interface ParcelReturnListFilter {
    currentPage?: number
    itemsPerPage?: number
    quickSearch?: string
    parcelStatus?: ParcelStatus[]
    receivedPagination?: any
    returnDeadline?: { startDate: any; endDate: any }
    effectiveReturn?: { startDate: any; endDate: any }
    hasIssues?: boolean
}
