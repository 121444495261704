import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { PaletteType } from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        status: () => ({
            textTransform: 'uppercase',
            fontSize: theme.typography.pxToRem(9),
            lineHeight: theme.typography.pxToRem(20),
            opacity: 0.9,
            borderRadius: '2px',
            padding: theme.space(0, 1),
            fontWeight: 500,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            width: 'fit-content',
        }),
        neutral: {
            backgroundColor: theme.palette.neutral[100],
            color: theme.palette.neutral[800],
        },
        primary: {
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary[800],
        },
        secondary: {
            backgroundColor: theme.palette.secondary[50],
            color: theme.palette.secondary[700],
        },
        error: {
            backgroundColor: theme.palette.error[50],
            color: theme.palette.error[700],
        },
        warning: {
            backgroundColor: theme.palette.warning[50],
            color: theme.palette.warning[900],
        },
        success: {
            backgroundColor: theme.palette.success[50],
            color: theme.palette.success[800],
        },
        info: {
            backgroundColor: theme.palette.info[50],
            color: theme.palette.info[900],
        },
    }),
)

interface StatusProps {
    label: string
    color?: PaletteType
    className?: string
    dataTestId?: string
}

const Status: React.FC<StatusProps> = ({ label, color = 'neutral', className = '', dataTestId = '' }) => {
    const classes = useStyles()
    return (
        <div className={`${classes.status} ${className} ${classes[color]}`} data-testid={dataTestId}>
            {label}
        </div>
    )
}

export default Status
