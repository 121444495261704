import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            border: `solid 1px ${theme.palette.neutral[200]}`,
            borderRadius: theme.space(1),
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            '& svg': {
                width: theme.space(14 / 5),
                height: theme.space(14 / 5),
                cursor: 'pointer',
            },
        },
    }),
)

type MoveWidgetProps = {
    handleUpClick?: () => void
    handleDownClick?: () => void
    upClickDisabled?: boolean
    downClickDisbled?: boolean
}

const MoveWidget: React.FC<MoveWidgetProps> = ({
    handleUpClick,
    handleDownClick,
    upClickDisabled,
    downClickDisbled,
}) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <div className={classes.container}>
            <FontAwesomeIcon
                icon={faAngleUp}
                onClick={upClickDisabled ? () => null : handleUpClick}
                color={upClickDisabled ? theme.palette.neutral[200] : theme.palette.neutral[400]}
            />
            <FontAwesomeIcon
                icon={faAngleDown}
                onClick={downClickDisbled ? () => null : handleDownClick}
                color={downClickDisbled ? theme.palette.neutral[200] : theme.palette.neutral[400]}
            />
        </div>
    )
}

export default MoveWidget
