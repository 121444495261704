import { useEffect, useState } from 'react'
import * as React from 'react'
import RefreshPUIDModal from '_organisms/uniqueProduct/RefreshPUIDModal'
import { IUniqueProduct } from 'interfaces'
import UniqueProductModal from './modal/UniqueProductModal'

type UniqueProductCardProps = {
    uniqueProduct: IUniqueProduct
}

const UniqueProductCard: React.FC<UniqueProductCardProps> = ({ uniqueProduct }) => {
    const [isEditPUIDModaleOpened, setIsEditPUIDModaleOpened] = useState<boolean>(false)
    const [uniqueProductDisplayed, setUniqueProductDisplayed] = useState<IUniqueProduct>(uniqueProduct)

    const handleSuccess = (newBarcodeUid: string) => {
        const newProduct: IUniqueProduct = uniqueProductDisplayed
        newProduct.barcodeUid = newBarcodeUid
        setUniqueProductDisplayed(newProduct)
    }

    useEffect(() => {
        setUniqueProductDisplayed(uniqueProduct)
    }, [uniqueProduct])

    return (
        <>
            <UniqueProductModal
                uniqueProduct={uniqueProductDisplayed}
                setIsEditPUIDModaleOpened={setIsEditPUIDModaleOpened}
            />
            <RefreshPUIDModal
                initialPUID={uniqueProductDisplayed.barcodeUid}
                handleSuccess={handleSuccess}
                handleClose={() => setIsEditPUIDModaleOpened(false)}
                isOpen={isEditPUIDModaleOpened}
            />
        </>
    )
}

export default UniqueProductCard
