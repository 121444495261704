import { IUniqueProduct } from './UniqueProduct.interface'
import { IRefitInstruction } from './RefitInstruction.interface'
import { IRefitOperation, RefitOperationStatus } from './RefitOperation.interface'
import { IProductIssue } from './Product.interface'

export enum RefitScanErrorEnum {
    UNIQUE_PRODUCT_NOT_FOUND = 'UNIQUE_PRODUCT_NOT_FOUND',
    PRODUCT_NOT_LINKED_WITH_REFIT_GROUP = 'PRODUCT_NOT_LINKED_WITH_REFIT_GROUP',
    LINKED_TO_WAITING_OPERATION = 'LINKED_TO_WAITING_OPERATION',
    UNKNOWN = 'UNKNOWN',
    REFIT_LOCATION_NOT_FOUND = 'REFIT_LOCATION_NOT_FOUND',
    REFIT_LOCATION_ALREADY_USED = 'REFIT_LOCATION_ALREADY_USED',
}

export interface IRefitStep {
    uniqueProduct: IUniqueProduct
    productIssues: IProductIssue[]
    instruction: IRefitInstruction
    totalInstructions: number
    lastRefitOperation: IRefitOperation
    currentInstructionPosition: number
    useRefitLocations: boolean
    shouldFinishOperation: boolean
}

export interface IOngoingOperation {
    id: number
    status: RefitOperationStatus
    startedAt: Date
    refitInstruction: IRefitInstruction
    refitLocation: { name: string }
    uniqueProductsToProcess: [{ barcodeUid: string }]
}

export interface IOngoingOperations {
    inProgress: IOngoingOperation[]
    paused: IOngoingOperation[]
    waiting: IOngoingOperation[]
    finished: IOngoingOperation[]
    ignored: IOngoingOperation[]
}
