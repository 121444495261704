import { useEffect, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles } from '@material-ui/core'
import { SHIPMENT_STATS_URL } from 'utils/routes/backend'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import Figure from 'components/statistics/Figure'
import dayjs, { Dayjs } from 'dayjs'
import { fetchStats, getChartsColors, getDoughnutOptions, getStatsStyles } from 'components/reusable/utils/StatsUtils'
import PageContentHeader from 'components/reusable/PageContentHeader'
import Page from '_organisms/Page'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

ChartJS.register(...registerables)

const statsStyles = getStatsStyles()
const useStyles = makeStyles(() => createStyles(statsStyles))

const ShipmentStatsApp: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [stats, setStats] = useState<any>(null)
    const [fullChartOptions, setFullChartOptions] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean | string>(true)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    useEffect(() => {
        fetchStats(setIsLoading, SHIPMENT_STATS_URL, formatStatsData, startDate, endDate)
        // eslint-disable-next-line
    }, [selectedCustomers, selectedWarehouse, startDate, endDate])

    const doughnutOptions: any = getDoughnutOptions()
    const chartColors: string[] = getChartsColors()
    ChartJS.overrides.doughnut.plugins.tooltip = doughnutOptions.plugins.tooltip

    const formatStatsData = (statsToFormat: any) => {
        const formattedData: any = {}
        const buildingChartOptions: any = {}
        setFullChartOptions({})

        formattedData.ordersPreparedOnDaterange = {
            figureDescription: 'shipmentStats.figures.ordersPreparedOnDaterange.description',
            value: Number(statsToFormat.ordersPreparedOnDaterange[0].nb_orders_prepared).toLocaleString(),
        }
        formattedData.uniqueProductsSentOnDaterange = {
            figureDescription: 'shipmentStats.figures.uniqueProductsSentOnDaterange.description',
            value: Number(statsToFormat.uniqueProductsSentOnDaterange[0].nb_products_sent).toLocaleString(),
        }
        formattedData.parcelsSentOnDaterange = {
            figureDescription: 'shipmentStats.figures.parcelsSentOnDaterange.description',
            value: Number(statsToFormat.parcelsSentOnDaterange[0].nb_parcels_sent).toLocaleString(),
        }
        formattedData.canceledPreparationsOnDaterange = {
            figureDescription: 'shipmentStats.figures.canceledPreparationsOnDaterange.description',
            value: Number(statsToFormat.canceledPreparationsOnDaterange[0].count).toLocaleString(),
        }
        formattedData.ordersCancelationRate = {
            figureDescription: 'shipmentStats.figures.ordersCancelationRate.description',
            value: Number(statsToFormat.ordersCancelationRate[0].rate).toLocaleString() + '%',
        }
        formattedData.distributionOfParcelsByCarrierAndShipmentType = {
            labels: statsToFormat.distributionOfParcelsByCarrierAndShipmentType.map((item: any) => {
                return (
                    item.carrier +
                    (item.is_urgent
                        ? ` (${t('shipmentStats.figures.distributionOfParcelsByCarrierAndShipmentType.urgent')})`
                        : '')
                )
            }),
            datasets: [
                {
                    label: 'label',
                    backgroundColor: chartColors,
                    data: statsToFormat.distributionOfParcelsByCarrierAndShipmentType.map((item: any) => {
                        return item.nb_parcels_sent
                    }),
                },
            ],
        }
        buildingChartOptions.distributionOfParcelsByCarrierAndShipmentType = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.distributionOfParcelsByCarrierAndShipmentType.plugins.title.text = t(
            'shipmentStats.figures.distributionOfParcelsByCarrierAndShipmentType.description',
        )
        buildingChartOptions.distributionOfParcelsByCarrierAndShipmentType.plugins.title.align = 'start'

        setFullChartOptions(buildingChartOptions)
        setStats(formattedData)
        setIsLoading(false)
    }

    const handleEndDateChange = (endDateToSet: Dayjs) => {
        if (endDateToSet.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDateToSet).subtract(1, 'day'))
            setEndDate(endDateToSet)
        } else {
            setEndDate(endDateToSet)
        }
    }

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.shipmentsAndWithdrawals')}
            tagline={t('shipmentStats.tagline')}
        >
            <PageContentHeader
                title="shipmentStats.content.title"
                tagline="shipmentStats.content.tagline"
                isLoading={isLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={handleEndDateChange}
            />
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={6}>
                    {stats?.ordersPreparedOnDaterange && (
                        <Figure
                            testId="orders-prepared-on-daterange"
                            figure={stats.ordersPreparedOnDaterange}
                            main={true}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.chartContainer}>
                        {stats?.distributionOfParcelsByCarrierAndShipmentType && (
                            <Chart
                                type="doughnut"
                                data={stats?.distributionOfParcelsByCarrierAndShipmentType}
                                options={fullChartOptions?.distributionOfParcelsByCarrierAndShipmentType}
                            />
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {stats?.uniqueProductsSentOnDaterange && (
                        <Figure
                            testId="unique-products-sent-on-daterange"
                            figure={stats.uniqueProductsSentOnDaterange}
                            withColor={true}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {stats?.parcelsSentOnDaterange && (
                        <Figure
                            testId="parcels-sent-on-daterange"
                            figure={stats.parcelsSentOnDaterange}
                            withColor={true}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {stats?.canceledPreparationsOnDaterange && (
                        <Figure
                            testId="preparations-canceled-on-daterange"
                            figure={stats.canceledPreparationsOnDaterange}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {stats?.ordersCancelationRate && (
                        <Figure testId="order-cancelation-rate" figure={stats.ordersCancelationRate} />
                    )}
                </Grid>
            </Grid>
        </Page>
    )
}

export default ShipmentStatsApp
