import * as React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
    createStyles,
    Fade,
    Grow,
    makeStyles,
    Paper,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@material-ui/lab'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { HistorySingle } from 'interfaces/History.interface'
import Page from '_organisms/Page'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            textAlign: 'center',
            position: 'relative',
            margin: theme.spacing(3, 0),
            padding: theme.spacing(0, 12),
            '& .MuiButton-root': {
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
            },
        },
        dot: {
            minWidth: 34,
            textAlign: 'center',
            display: 'block',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        timeline: {
            '& .MuiTimelineItem-root:before': {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            '& .MuiPaper-root': {
                display: 'inline-block',
            },
        },
        card: {
            padding: theme.spacing(2, 3),
        },
        time: {
            display: 'inline-flex',
            alignItems: 'center',
            border: `1px solid ${theme.palette.grey[500]}`,
            padding: theme.spacing(0.5, 1),
            borderRadius: theme.spacing(0.5),
            '& *': {
                color: theme.palette.grey[700],
            },
            '& .MuiSvgIcon-root': {
                height: theme.typography.pxToRem(16),
                width: theme.typography.pxToRem(16),
                margin: theme.spacing(0, 1),
                verticalAlign: '-15%',
                '&:first-child': {
                    marginLeft: 0,
                },
            },
        },
        timeEnd: {
            borderColor: theme.palette.secondary.main,
            '& *': {
                color: theme.palette.secondary.main,
            },
        },
    }),
)

type HistoryDetailAppProps = {
    operations: HistorySingle[]
    data: string[]
    handleBack: () => void
}

const HistoryDetailApp: React.FC<HistoryDetailAppProps> = ({ operations, data, handleBack }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const timeFormat = 'HH:mm:ss'
    const currentTime = dayjs(Date.now()).format(timeFormat)

    return (
        <Fade in>
            <Page title={data.length > 0 ? `${data[0]} - UID : ${data[1]}` : ''} handlePrevious={handleBack}>
                <Timeline
                    data-testid="timeline"
                    className={classes.timeline}
                    align={useMediaQuery(theme.breakpoints.up('md')) ? 'alternate' : 'left'}
                >
                    {operations.map((operation: HistorySingle, index: number, arr: any) => {
                        const { instruction_title, operation_created_at, operation_finished_at } = operation
                        const isLast = arr.length - 1 === index
                        const isFirst = index === 0

                        return (
                            <Grow in timeout={200 * index + 1} key={index} mountOnEnter>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot
                                            className={classes.dot}
                                            color={isLast || isFirst ? 'secondary' : 'grey'}
                                        >
                                            {arr.length - index}
                                        </TimelineDot>
                                        {!isLast && <TimelineConnector />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper className={classes.card}>
                                            {operation_finished_at !== null ? (
                                                <>
                                                    <Typography variant="h3" gutterBottom>
                                                        {instruction_title}
                                                    </Typography>
                                                    <div className={classes.time}>
                                                        <Typography variant="caption">
                                                            {t('history.operation.start')}:{' '}
                                                            {dayjs(operation_created_at).format(timeFormat)}
                                                        </Typography>
                                                        <ArrowForwardIcon />
                                                        <Typography variant="caption">
                                                            {t('history.operation.end')}:{' '}
                                                            {dayjs(operation_finished_at).format(timeFormat)}
                                                        </Typography>
                                                    </div>
                                                </>
                                            ) : currentTime > dayjs(operation_created_at).format(timeFormat) &&
                                              instruction_title !== null ? (
                                                <Typography variant="h3" color="secondary">
                                                    {t('history.refitProgress')}
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Typography variant="h3" gutterBottom>
                                                        {t('history.refitEnd')}
                                                    </Typography>
                                                    <div className={`${classes.time} ${classes.timeEnd}`}>
                                                        <Typography variant="caption">
                                                            <ScheduleIcon />
                                                            {dayjs(operation_created_at).format(timeFormat)}
                                                        </Typography>
                                                    </div>
                                                </>
                                            )}
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                            </Grow>
                        )
                    })}
                </Timeline>
            </Page>
        </Fade>
    )
}

export default HistoryDetailApp
