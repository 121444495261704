import * as React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Oval } from 'react-loader-spinner'

import CodeScanner from './CodeScanner'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(3),
            '& .MuiTextField-root': {
                maxWidth: 660,
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(1),
            },
        },
        paper: {
            color: theme.palette.text.secondary,
        },
        alert: {
            marginTop: theme.spacing(3),
        },
        title: {
            margin: theme.spacing(0, 0, 2, 2),
            maxWidth: 630,
        },
        loading: {
            color: theme.palette.primary.main,
            lineHeight: 1.1,
        },
        loadingIcon: {
            '&>[aria-busy]': {
                height: theme.spacing(4),
            },
        },
    }),
)

type UIDSelectorProps = {
    setScannedCode: (uniqueProductBarcode: string) => void
    defaultValue?: string
    loadingMessage?: string
    title?: string
    label?: string
    lockFocus?: boolean
    setKeyboardListener?: (value: boolean) => void
}

const UIDSelector: React.FC<UIDSelectorProps> = ({
    setScannedCode,
    defaultValue,
    loadingMessage = '',
    title = '',
    label = '',
    lockFocus = false,
    setKeyboardListener,
}) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            {title && (
                <Typography variant="h3" component="h3" gutterBottom className={classes.title}>
                    {title}
                </Typography>
            )}
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>
                    <CodeScanner
                        label={label}
                        onSubmit={setScannedCode}
                        defaultValue={defaultValue}
                        lockFocus={lockFocus}
                        setKeyboardListener={setKeyboardListener}
                    />
                </Grid>
                {loadingMessage && (
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2} wrap="nowrap" alignItems="center">
                            <Grid item className={classes.loadingIcon}>
                                <Oval
                                    color={theme.palette.primary.main}
                                    height={theme.spacing(4)}
                                    width={theme.spacing(4)}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" className={classes.loading}>
                                    {loadingMessage}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

export default UIDSelector
