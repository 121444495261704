import * as React from 'react'
import { CardMedia, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import theme from '../../theme'
import DropDownButton from '../reusable/DropDownButton'
import { useTranslation } from 'react-i18next'
import ImageOffOutlineIcon from 'mdi-react/ImageOffOutlineIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        productBase: {
            margin: `${-theme.spacing(9)}px auto ${theme.spacing(1)}px`,
            maxWidth: '450px',
            flexWrap: 'nowrap',
        },
        productBaseText: {
            height: '100%',
            paddingLeft: theme.spacing(1),
        },
        productPictureContainer: {
            position: 'relative',
            flexBasis: 'auto',
            height: theme.spacing(18),
            width: '100%',
            overflow: 'hidden',
            boxShadow: theme.shadows[5],
            borderRadius: '4%',
        },
        productPicture: {
            objectFit: 'contain',
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            margin: 'auto',
        },
        puidContainer: {
            paddingRight: theme.spacing(1),
            maxWidth: '75%',
        },
        dropDownButtonContainer: {
            marginRight: theme.spacing(1),
        },
        infoAndDropDown: {
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        label: {
            textTransform: 'uppercase',
            fontWeight: 400,
            color: theme.palette.grey[700],
            fontSize: '14px',
        },
        value: {
            fontWeight: 800,
            fontSize: '14px',
            overflowWrap: 'break-word',
        },
        iconNoPicture: {
            color: theme.palette.grey[400],
            backgroundColor: theme.palette.common.white,
        },
    }),
)

type ProductOverviewProps = {
    imgUrl: string | null | undefined
    barcodeUid?: string
    customerName?: string
    customActions?: { label: string; action: () => void }[]
}

const ProductOverview: React.FC<ProductOverviewProps> = ({ imgUrl, barcodeUid, customerName, customActions }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid className={classes.productBase} container>
            <Grid className={classes.productPictureContainer} item xs={5} sm={4}>
                {imgUrl && imgUrl !== '' ? (
                    <CardMedia component="img" className={classes.productPicture} image={imgUrl} alt="Product image" />
                ) : (
                    <ImageOffOutlineIcon className={classes.iconNoPicture} />
                )}
            </Grid>
            <Grid item xs={7} sm={8}>
                <Grid className={classes.productBaseText} container justify="center">
                    <Grid
                        item
                        xs={12}
                        className={classes.infoAndDropDown}
                        style={{ visibility: barcodeUid ? 'visible' : 'hidden' }}
                    >
                        <div className={classes.puidContainer}>
                            <Typography
                                className={classes.label}
                                style={{ color: theme.palette.grey[100] }}
                                variant="h5"
                                component="p"
                            >{`${t('global.PUID')}`}</Typography>
                            <Typography className={classes.value} variant="h5" component="p">
                                {barcodeUid}
                            </Typography>
                        </div>
                        <div className={classes.dropDownButtonContainer}>
                            <DropDownButton label="..." pickable={customActions} />
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <Typography className={classes.label} variant="h6" component="p">{`${t(
                                'wms.uniqueProducts.columnCustomerName',
                            )}`}</Typography>
                            <Typography className={classes.value} variant="h6" component="p">
                                {customerName}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProductOverview
