import * as React from 'react'
import { useState, useEffect } from 'react'
import { StepBulletStatus } from '_atoms/badges/StepBullet'

export type HandleSteps = {
    step: number
    disabled: boolean
    updateStatuses: (status: StepBulletStatus) => void
}

type VerticalStepperProps = {
    currentStep: number
    children: React.ReactElement[]
    setStep: (step: number) => void
    setSubmitButtonStatus: (buttonState: StepBulletStatus) => void
}

const VerticalStepper: React.FC<VerticalStepperProps> = ({ currentStep, children, setStep, setSubmitButtonStatus }) => {
    const totalSteps = children.length
    const [statuses, setStatuses] = useState(Array(totalSteps).fill(StepBulletStatus.DEFAULT))

    function resetStepsAfterError(isError: boolean, errorIndex: number) {
        if (isError) {
            for (let i = 0; i < totalSteps; i++) {
                if (i > errorIndex) {
                    children[i].props.reset()
                }
            }
        }
    }

    function updateSubmitButtonStatus(isError: boolean, isLastSuccessStep: boolean) {
        if (isError) {
            setSubmitButtonStatus(StepBulletStatus.ERROR)
        } else if (isLastSuccessStep) {
            setSubmitButtonStatus(StepBulletStatus.SUCCESS)
        } else {
            setSubmitButtonStatus(StepBulletStatus.DEFAULT)
        }
    }

    function updateCurrentStep(isError: boolean, errorIndex: number, isSuccess: boolean, successIndex: number) {
        if (isError) {
            if (errorIndex !== currentStep) setStep(errorIndex)
        } else if (isSuccess) {
            if (successIndex + 1 !== currentStep) setStep(successIndex + 1)
        }
    }

    useEffect(() => {
        const errorIndex = statuses.indexOf(StepBulletStatus.ERROR)
        const successIndex = statuses.lastIndexOf(StepBulletStatus.SUCCESS)

        const isError = errorIndex > -1
        const isSuccess = successIndex > -1
        const isLastSuccessStep = isSuccess && successIndex === statuses.length - 1

        updateCurrentStep(isError, errorIndex, isSuccess, successIndex)
        updateSubmitButtonStatus(isError, isLastSuccessStep)
        resetStepsAfterError(isError, errorIndex)
    }, [statuses])

    useEffect(() => {
        if (totalSteps !== statuses.length) setStatuses((prevStatuses) => prevStatuses.slice(0, totalSteps))
    }, [totalSteps])

    function updateStatuses(step: number, status: StepBulletStatus) {
        const newStatuses = [
            ...statuses.slice(0, step),
            status,
            ...statuses.slice(step + 1).fill(StepBulletStatus.DEFAULT),
        ]
        setStatuses(newStatuses)
    }

    return (
        <>
            {children.map((child, index) => {
                return (
                    <div key={index}>
                        {React.cloneElement(child, {
                            handleSteps: {
                                step: index,
                                disabled: index > currentStep,
                                updateStatuses: (status: StepBulletStatus) => updateStatuses(index, status),
                            },
                        })}
                    </div>
                )
            })}
        </>
    )
}

export default VerticalStepper
