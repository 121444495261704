import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import ModalWithHeader, { HeaderType as ModalHeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import { useTranslation } from 'react-i18next'
import Pill from '_atoms/badges/Pill'
import { HeaderType as TableHeaderType } from './TableV2'
import Text from '_atoms/text/Text'
import { faArrowsLeftRightToLine, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import ModalFooter from '_molecules/ModalFooter'
import { toast } from 'utils/toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(3)}px`,
            marginBottom: theme.space(12),
        },
        columnContainer: {
            borderRadius: '2px',
            border: `1px solid ${theme.palette.neutral[200]}`,
            display: 'flex',
            flexWrap: 'wrap',
            width: theme.space(136),
            padding: theme.space(1),
            gap: `${theme.space(1)}px`,
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        subtitle: {
            color: theme.palette.neutral[800],
        },
        clickable: {
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            padding: 0,
        },
        draggable: {
            cursor: 'grab',
            display: 'flex',
            alignItems: 'center',
            gap: 'inherit',
        },
        dropIcon: {
            color: theme.palette.neutral[600],
            display: 'none',
        },
        dragOver: {
            '& svg': {
                display: 'flex',
            },
        },
        dragged: {
            opacity: 0.4,
        },
    }),
)

type ColumnsSettingsModalProps = {
    header: TableHeaderType[]
    open: boolean
    handleClose: () => void
    setHeader: (header: TableHeaderType[]) => void
}

const ColumnsSettingsModal: React.FC<ColumnsSettingsModalProps> = ({ header, open, handleClose, setHeader }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [draggedIndex, setDraggedIndex] = useState<number>()
    const [droppedAtIndex, setDroppedAtIndex] = useState<number>()
    const [headerList, setHeaderList] = useState<any[]>(header)

    const onDragOver = (e: React.DragEvent<HTMLDivElement>, index: number) => {
        e.preventDefault()
        setDroppedAtIndex(index)
    }

    const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
        setDraggedIndex(index)
    }

    const onDrop = () => {
        const newHeaderList = [...headerList]
        const dragged = newHeaderList.splice(draggedIndex!, 1)
        newHeaderList.splice(droppedAtIndex! > draggedIndex! ? droppedAtIndex! - 1 : droppedAtIndex!, 0, dragged[0])
        setHeaderList(newHeaderList)
        setDroppedAtIndex(undefined)
        setDraggedIndex(undefined)
    }

    function toggleDisplayColumn(index: number) {
        const newHeaderList = [...headerList]
        const visibleColumnsCount = newHeaderList.filter((column) => !column.hidden).length
        if (visibleColumnsCount > 1 || newHeaderList[index].hidden) {
            newHeaderList[index].hidden = !newHeaderList[index].hidden
            setHeaderList(newHeaderList)
        } else {
            toast.error(t('table.tableSettings.allHiddenColumnError'))
        }
    }

    function apply() {
        setHeader(headerList)
        handleClose()
    }

    return (
        <ModalWithHeader
            title={t('table.tableSettings.title')}
            open={open}
            headerType={ModalHeaderType.MOBILE}
            handleClose={handleClose}
        >
            <div className={classes.content}>
                <Text variant="S2" className={classes.subtitle}>
                    {t('table.tableSettings.columnToDisplay')}
                </Text>
                <div id="dropable" className={classes.columnContainer} onDrop={onDrop}>
                    {headerList.map(({ label, hidden }, index) => (
                        <button key={index} className={classes.clickable} onClick={() => toggleDisplayColumn(index)}>
                            <Pill
                                label={t(label)}
                                endIcon={hidden ? faEyeSlash : faEye}
                                variant={hidden ? 'basic' : 'fill'}
                                dataTestId={`${t(label)}-toggleColumn`}
                            />
                        </button>
                    ))}
                </div>
                {!isMobile && (
                    <>
                        <Text variant="S2" className={classes.subtitle}>
                            {t('table.tableSettings.columnOrder')}
                        </Text>
                        <div className={classes.columnContainer} onDrop={onDrop}>
                            {headerList.map(
                                ({ label, hidden }, index) =>
                                    !hidden && (
                                        <div
                                            key={index}
                                            draggable={true}
                                            onDragStart={(e) => onDragStart(e, index)}
                                            onDragOver={(e) => onDragOver(e, index)}
                                            className={`${classes.draggable} ${
                                                index === droppedAtIndex ? classes.dragOver : ''
                                            } ${index === draggedIndex ? classes.dragged : ''}`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowsLeftRightToLine}
                                                className={classes.dropIcon}
                                            />
                                            <Pill
                                                variant="basic"
                                                label={t(label)}
                                                dataTestId={`${t(label)}-orderColumn`}
                                            />
                                        </div>
                                    ),
                            )}
                        </div>
                    </>
                )}
            </div>
            <ModalFooter label={t('global.apply')} onSubmit={apply} onCancel={handleClose} withDivider={false} />
        </ModalWithHeader>
    )
}

export default ColumnsSettingsModal
