import { ReactNode } from 'react'
import * as React from 'react'
import { createStyles, Grid, GridSize, makeStyles, Theme } from '@material-ui/core'
import { SimpleLabel } from '_atoms/text/Labels'
import { BasicText } from '_atoms/text/BasicText'

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    }),
)

type GridLabeledValueProps = {
    xsLabel?: GridSize
    smLabel?: GridSize
    mdLabel?: GridSize
    labelClassName?: string
    valueClassName?: string
    label: string & ReactNode
    children: any
    labelAddon?: () => JSX.Element
    valueAddon?: () => JSX.Element
}

const GridLabeledValue: React.FC<GridLabeledValueProps> = ({
    label,
    xsLabel = 3,
    smLabel = 3,
    mdLabel = 3,
    children,
    labelClassName,
    valueClassName,
    labelAddon,
    valueAddon,
}) => {
    const classes = useStyles()

    const SIZE_GRID = 12
    let xsValue: GridSize, smValue: GridSize, mdValue: GridSize

    if (xsLabel === 'auto') {
        xsValue = 'auto'
    } else {
        xsValue = (SIZE_GRID - xsLabel) as GridSize
    }

    if (smLabel === 'auto') {
        smValue = 'auto'
    } else {
        smValue = (SIZE_GRID - smLabel) as GridSize
    }

    if (mdLabel === 'auto') {
        mdValue = 'auto'
    } else {
        mdValue = (SIZE_GRID - mdLabel) as GridSize
    }

    return (
        <>
            <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} className={classes.flex}>
                <SimpleLabel className={`${labelClassName || ''}`}>{label}</SimpleLabel>
                {labelAddon && labelAddon()}
            </Grid>
            <Grid item xs={xsValue} sm={smValue} md={mdValue} className={classes.flex}>
                <BasicText className={`${valueClassName || ''}`}>{children}</BasicText>
                {valueAddon && valueAddon()}
            </Grid>
        </>
    )
}

export default GridLabeledValue
