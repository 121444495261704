import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import FileButton from '_atoms/inputs/FileButton'
import { SectionTitle } from '_atoms/text/SectionTitle'
import Tagline from '_atoms/text/Tagline'
import { deleteS3File, EntityType, getSignedUrl, RMSFile } from 'utils/files'
import { toast } from 'utils/toast'
import ThumbnailGrid from '_organisms/images/shooting/ThumbnailGrid'
import { StepHandlerType } from '_organisms/Stepper'
import Page from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: {
            textAlign: 'left',
        },
        tagline: {
            paddingBottom: theme.spacing(3),
        },
        label: {
            marginBottom: theme.spacing(0.5),
        },
        fileButton: {
            width: '35%',
            border: '1px solid #CDD1DA',
            margin: '0 auto',
            borderRadius: '4px',
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginTop: theme.spacing(2),
            },
        },
        stickyButton: {
            position: 'fixed',
            bottom: '0px',
            left: '0px',
            width: '100%',
            padding: '10px',
        },
        filesContainer: {
            margin: `${theme.spacing(2)}px auto`,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        pictures: {
            marginBottom: theme.space(2),
        },
    }),
)

type ShootingProps = {
    pictures: RMSFile[]
    setPictures: (file: RMSFile[]) => void
    isOptional?: boolean // Can we go to next step without picture
    customerIdPrivateBucket?: number

    // Injected Stepper props
    handleSteps?: StepHandlerType
}

const picturesTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/SVG', 'image/heif', 'image/heic']

const Shooting: React.FC<ShootingProps> = ({
    pictures,
    setPictures,
    isOptional = false,
    handleSteps,
    customerIdPrivateBucket,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const handleFileInput = async (files: RMSFile[]) => {
        try {
            const promises = []
            for (const file of files) {
                if (picturesTypes.includes(file.type)) {
                    promises.push(await getSignedUrl(file, EntityType.UNIQUE_PRODUCT, customerIdPrivateBucket))
                    setPictures([...pictures, file])
                } else toast.error(t('publish.shooting.wrongType'))
            }
            const uploadablePictures = await Promise.all(promises)
            setPictures([...pictures, ...uploadablePictures])
        } catch (e) {
            toast.error('Failed to get signedUrl')
        }
    }

    const handleDelete = async (file: RMSFile) => {
        // Delete file in s3
        if (file.id) {
            const error = await deleteS3File(file.id)
            if (error) {
                toast.error(t('files.DeleteError') + error.message)
                return
            }
        }
        // Delete picture from interface
        // Avoid to remove two files of the same name
        const picturesCopy = [...pictures]
        let index = -1
        picturesCopy.forEach((picture, idx) => {
            if (picture.name === file.name) {
                index = idx
                return
            }
        })
        if (index > -1) {
            picturesCopy.splice(index, 1)
            setPictures(picturesCopy)
        }
    }

    const renderButton = () => {
        if (isOptional || pictures.length > 0)
            return (
                <Button
                    label={t('global.next')}
                    size="large"
                    onClick={() => handleSteps!.nextStep()}
                    endIcon={faArrowRight}
                    data-testid="nextStep-button"
                />
            )
    }

    const title = `${t('publish.shooting.title')} ${isOptional ? t('publish.shooting.optional') : ''}`

    return (
        <Page
            title={t('publish.shooting.header')}
            rightAddon={{ addon: handleSteps!.getCounter(), displayOnMobile: true }}
            handlePrevious={() => handleSteps!.previousStep()}
        >
            <BackgroundWithScanAndFooter footer={renderButton()}>
                <SectionTitle className={classes.sectionTitle}>{title}</SectionTitle>
                <Tagline className={classes.tagline}>{t('publish.shooting.tagline')}</Tagline>
                <FileButton
                    text={t('reportIssue.takePicture.buttonLabel')}
                    handleFile={(files) => handleFileInput(files)}
                    className={classes.fileButton}
                    multiple={true}
                />
                <div className={classes.pictures}>
                    <ThumbnailGrid pictures={pictures} handleDelete={handleDelete} size="medium" />
                </div>
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default Shooting
