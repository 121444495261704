import create from 'zustand'
import { prismicLang, PrismicQuality, PrismicQualityCondition } from '../prismic'
import * as prismic from '@prismicio/client'
import Bugsnag from '@bugsnag/js'
import i18n from 'i18next'

export interface PrismicQualitiesState {
    qualities: PrismicQuality[]
    loadPrismicQualities: (repositoryName: string) => Promise<PrismicQuality[]>
}

const usePrismicQualitiesStore = create<PrismicQualitiesState>()((set) => ({
    qualities: [],
    loadPrismicQualities: async (repositoryName) => {
        try {
            const client = prismic.createClient(repositoryName)
            const params: any = {
                lang: prismicLang[i18n.language as keyof typeof prismicLang] || prismicLang.en,
            }
            const res = await client.getSingle('take_back', params)
            const takebackDocument = res.data
            const qualities: PrismicQuality[] = []

            if (takebackDocument) {
                for (const quality of Object.values(PrismicQualityCondition)) {
                    const qualityLabel = takebackDocument[quality.toLowerCase()]
                    const qualityDescription = takebackDocument[`${quality.toLowerCase()}definition`]
                    const prismicQuality: PrismicQuality = {
                        value: quality,
                        label: (qualityLabel && qualityLabel[0].text) || quality,
                        description: (qualityDescription && qualityDescription[0].text) || '',
                    }

                    // set the qualities in the right order
                    if (quality === PrismicQualityCondition.REFUSED_CONDITION) {
                        qualities[0] = prismicQuality
                    } else if (quality === PrismicQualityCondition.GOOD_CONDITION) {
                        qualities[1] = prismicQuality
                    } else if (quality === PrismicQualityCondition.VERY_GOOD_CONDITION) {
                        qualities[2] = prismicQuality
                    } else if (quality === PrismicQualityCondition.PERFECT_CONDITION) {
                        qualities[3] = prismicQuality
                    } else {
                        throw new Error('Unexpected quality from Prismic')
                    }
                }
            }

            set(() => ({ qualities }))
            return qualities
        } catch (err) {
            console.error(err)
            Bugsnag.notify(`Error during the fetching of the Prismic Qualities`, (event) =>
                event.addMetadata('payload', { err, repositoryName }),
            )
            return []
        }
    },
}))

export default usePrismicQualitiesStore
