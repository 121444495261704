import { withStyles } from '@material-ui/core/styles'
import MuiLinearProgress from '@material-ui/core/LinearProgress'

const LinearProgress = withStyles((theme) => ({
    root: {
        height: theme.space(6 / 5),
        borderRadius: theme.space(2 / 5),
    },
    colorPrimary: {
        backgroundColor: theme.palette.neutral[100],
    },
    bar: {
        borderRadius: theme.space(2 / 5),
        backgroundColor: theme.palette.neutral[600],
    },
}))(MuiLinearProgress)

export default LinearProgress
