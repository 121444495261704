import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import rmsApi from 'utils/api'
import { REFIT_CATEGORIES_ROUTE } from 'utils/routes/backend'
import TopSearchSection from '_molecules/TopSearchSection'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { InstructionModalType } from '_organisms/instructionModal/InstructionModal'
import NoResultTip from '_molecules/NoResultTip'
import { toast } from 'utils/toast'
import ManageCategoryModal from './modals/ManageCategoryModal'
import ManageInstructionModal from './modals/ManageInstructionModal'
import HandleInstructions from './HandleInstructions'
import HandleCategories from './HandleCategories'
import { IRefitCategory } from 'interfaces'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import Button from '_atoms/buttons/Button'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import CustomerPicker from '_organisms/header/addons/CustomerPicker'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        mainExportButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        addNewButton: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: theme.spacing(2),
                right: theme.spacing(2),
                width: theme.spacing(6),
                height: theme.spacing(6),
                minWidth: 'unset',
                borderRadius: '50%',
            },
        },
        customerSelectMargins: {
            display: 'flex',
            justifyContent: 'end',
            margin: '0 0 8px 5px',
        },
    }),
)

export type InstructionCategoriesItemsProps = {
    categories?: any
    subCategories?: any
    instructions?: any
}

export type InstructionCategoriesSelectedProps = {
    category?: IRefitCategory
    subCategory?: IRefitCategory
}

export enum InstructionCategoriesLevels {
    CATEGORIES = 'CATEGORIES',
    SUB_CATEGORIES = 'SUB_CATEGORIES',
    INSTRUCTIONS = 'INSTRUCTIONS',
}

const initialItems = {
    categories: [],
    subCategories: [],
    instructions: [],
}

const InstructionsManagement: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))
    const [selectedCustomer, setSelectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])

    const [level, setLevel] = useState<InstructionCategoriesLevels>(InstructionCategoriesLevels.CATEGORIES)
    const [items, setItems] = useState<InstructionCategoriesItemsProps>(initialItems)
    const [searchValue, setSearchValue] = useState<string>('')
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
    const [selected, setSelected] = useState<InstructionCategoriesSelectedProps | undefined>()

    function filterItems(items: any[]) {
        return items.filter((item: any) => {
            return item.title.toLowerCase().includes(searchValue.toLowerCase())
        })
    }

    useEffect(() => {
        if (selectedCustomers[0]) setSelectedCustomer(selectedCustomers[0])
    }, [selectedCustomers])

    useEffect(() => {
        setSearchValue('')
    }, [items, selected])

    async function fetchAndSetItems() {
        if (level === InstructionCategoriesLevels.CATEGORIES) {
            const fetchedItems = await rmsApi.get(`${REFIT_CATEGORIES_ROUTE}?customerId=${selectedCustomer?.id}`)

            setItems({
                categories: fetchedItems?.data,
                subCategories: [],
                instructions: [],
            })
        }
        if (level === InstructionCategoriesLevels.SUB_CATEGORIES) {
            const fetchedItems = await rmsApi.get(`${REFIT_CATEGORIES_ROUTE}/${selected!.category!.id}/sub-categories`)

            setItems({
                ...items,
                subCategories: fetchedItems?.data,
                instructions: [],
            })
        }
        if (level === InstructionCategoriesLevels.INSTRUCTIONS) {
            const fetchedItems = await rmsApi.get(`${REFIT_CATEGORIES_ROUTE}/${selected!.subCategory!.id}/instructions`)

            setItems({
                ...items,
                instructions: fetchedItems?.data,
            })
        }
    }

    useEffect(() => {
        if (selectedCustomer) {
            fetchAndSetItems().catch(console.error)
        }
    }, [level, selectedCustomer])

    function selectCategory(category: IRefitCategory) {
        setSelected({ category })
        setLevel(InstructionCategoriesLevels.SUB_CATEGORIES)
    }

    function selectSubCategory(subCategory: IRefitCategory) {
        setSelected({ ...selected, subCategory })
        setLevel(InstructionCategoriesLevels.INSTRUCTIONS)
    }

    function deselectCategory() {
        setSelected(undefined)
        setLevel(InstructionCategoriesLevels.CATEGORIES)
    }

    function deselectSubCategory() {
        setSelected({ category: selected?.category })
        setLevel(InstructionCategoriesLevels.SUB_CATEGORIES)
    }

    const headerProps = useMemo(() => {
        if (level === InstructionCategoriesLevels.CATEGORIES) {
            return {
                title: t('menu.refit.instructionCategories'),
                section: t('menu.refit.title'),
                tagline: t('refit.instructionCategories.tagline'),
                rightAddon: {
                    addon: <CustomerPicker initWithRandomCustomer truncateLabelAbove={140} />,
                    displayOnMobile: false,
                },
            }
        }
        if (level === InstructionCategoriesLevels.SUB_CATEGORIES) {
            return {
                title: selected!.category!.title,
                section: t('menu.refit.instructionCategories'),
                handlePrevious: deselectCategory,
            }
        }
        if (level === InstructionCategoriesLevels.INSTRUCTIONS) {
            return {
                title: selected?.subCategory?.title,
                section: t('menu.refit.instructionCategories'),
                handlePrevious: deselectSubCategory,
            }
        }
    }, [level])

    async function deleteItem(url: string) {
        try {
            await rmsApi.delete(url)
            toast.success(t('refit.instructionCategories.callbackMessages.successfullyDeleted'))
            fetchAndSetItems()
        } catch (e: any) {
            toast.error(e.response.data?.message || e.response.message)
        }
    }

    const filteredItems = { ...items }
    if (level === InstructionCategoriesLevels.CATEGORIES) filteredItems.categories = filterItems(items.categories)
    else if (level === InstructionCategoriesLevels.SUB_CATEGORIES)
        filteredItems.subCategories = filterItems(items.subCategories)
    else filteredItems.instructions = filterItems(items.instructions)

    const isSearchBar =
        (level === InstructionCategoriesLevels.CATEGORIES && items?.categories?.length > 0) ||
        (level === InstructionCategoriesLevels.SUB_CATEGORIES && items?.subCategories?.length > 0) ||
        (level === InstructionCategoriesLevels.INSTRUCTIONS && items?.instructions?.length > 0)

    const isEmpty =
        (level === InstructionCategoriesLevels.CATEGORIES && filteredItems?.categories?.length === 0) ||
        (level === InstructionCategoriesLevels.SUB_CATEGORIES && filteredItems?.subCategories?.length === 0) ||
        (level === InstructionCategoriesLevels.INSTRUCTIONS && filteredItems?.instructions?.length === 0)

    const renderAddNewButton = () => (
        <Button
            label={!isMobile && t(`refit.instructionCategories.addNew.${level}`)}
            startIcon={faPlus}
            className={classes.addNewButton}
            onClick={() => setIsCreateModalOpen(true)}
            data-testid="createItemButton"
        />
    )

    return (
        <Page
            title={headerProps?.title}
            section={headerProps?.section}
            tagline={headerProps?.tagline}
            handlePrevious={headerProps?.handlePrevious}
            rightAddon={headerProps?.rightAddon}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            contentPaddingTopDesktop={0}
        >
            <TopSearchSection
                placeholder={t(`refit.instructionCategories.searchInputPlaceholder.${level}`)}
                filter={searchValue}
                setFilter={setSearchValue}
                input={isSearchBar}
            >
                {renderAddNewButton()}
            </TopSearchSection>
            {level === InstructionCategoriesLevels.CATEGORIES && (
                <HandleCategories
                    updateSelection={selectCategory}
                    categories={filteredItems.categories}
                    handleRefresh={fetchAndSetItems}
                    deleteItem={deleteItem}
                />
            )}
            {level === InstructionCategoriesLevels.SUB_CATEGORIES && (
                <HandleCategories
                    updateSelection={selectSubCategory}
                    categories={filteredItems.subCategories}
                    handleRefresh={fetchAndSetItems}
                    deleteItem={deleteItem}
                    level={level}
                />
            )}
            {level === InstructionCategoriesLevels.INSTRUCTIONS && (
                <HandleInstructions
                    instructions={filteredItems.instructions}
                    handleRefresh={fetchAndSetItems}
                    deleteItem={deleteItem}
                    selectedCategory={selected!.category!.title}
                    selectedSubCategory={selected!.subCategory!.title}
                />
            )}
            {isEmpty && (
                <NoResultTip
                    text={
                        searchValue
                            ? t(`refit.instructionCategories.noItemWithFilter.${level}`)
                            : t(`refit.instructionCategories.noItem.${level}`)
                    }
                >
                    {renderAddNewButton()}
                </NoResultTip>
            )}
            {isCreateModalOpen && level === InstructionCategoriesLevels.INSTRUCTIONS && (
                <ManageInstructionModal
                    type={InstructionModalType.CREATE}
                    parentId={selected?.subCategory?.id}
                    handleClose={() => setIsCreateModalOpen(false)}
                    handleRefresh={fetchAndSetItems}
                    category={selected!.category!.title}
                    subCategory={selected!.subCategory!.title}
                />
            )}
            {isCreateModalOpen && level !== InstructionCategoriesLevels.INSTRUCTIONS && (
                <ManageCategoryModal
                    parentId={selected?.subCategory?.id ?? selected?.category?.id}
                    handleClose={() => setIsCreateModalOpen(false)}
                    handleRefresh={fetchAndSetItems}
                />
            )}
        </Page>
    )
}

export default InstructionsManagement
