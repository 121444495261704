import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useAutocomplete } from '@material-ui/lab'
import SearchInput from '_molecules/SearchInput'
import SecondHandProductCard from './SecondHandProductCard'
import { SquaredImageSize } from '_atoms/images/SquaredImage'
import { toast } from 'utils/toast'
import { useTranslation } from 'react-i18next'
import rmsApi from 'utils/api'
import { IProductReferenceWithLabelReference } from 'interfaces'
import { PRODUCT_REFERENCES_BY_LABEL_REFERENCE } from 'utils/routes/backend'
import { useDebouncedEffect } from 'utils/hooks/UseDebounceEffect'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.space(4),
        },
        menu: {
            maxHeight: theme.space(55),
            overflow: 'scroll',
            right: theme.space(24),
            left: theme.space(24),
            margin: theme.space(1, 0, 0),
            padding: theme.space(2, 1),
            position: 'absolute',
            backgroundColor: theme.palette.common.white,
            zIndex: 2,
            listStyle: 'none',
            border: `1px solid ${theme.palette.neutral[200]}`,
            borderRadius: '2px',
            boxShadow: '2px 2px 15px rgba(19, 39, 86, 0.05)',
            '& > *:not(:last-child)': {
                paddingBottom: theme.space(2),
                marginBottom: theme.space(2),
                borderBottom: `1px solid ${theme.palette.neutral[100]}`,
            },
            [theme.breakpoints.down('sm')]: {
                right: 0,
                left: 0,
            },
        },
        productCard: {
            cursor: 'pointer',
            padding: theme.space(2),
            boxShadow: 'none',
            borderRadius: theme.space(1),
            '&:hover': {
                background: theme.palette.primary[50],
            },
        },
    }),
)

type SearchReferenceProps = {
    updateProductReference: (productReference: IProductReferenceWithLabelReference) => void
}

const SearchReference: React.FC<SearchReferenceProps> = ({ updateProductReference }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [productReferences, setProductReferences] = useState([] as IProductReferenceWithLabelReference[])
    const [value, setValue] = useState('')

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        options: productReferences,
        getOptionLabel: (option) => String(option.product.labelReference),
        onChange: (e, value) => {
            updateProductReference(value!)
            setValue('')
        },
        onInputChange(e, value) {
            setValue(value)
        },
        blurOnSelect: true,
    })

    async function getProductReferences(value: string) {
        try {
            if (value.length > 3) {
                const response = await rmsApi.get(PRODUCT_REFERENCES_BY_LABEL_REFERENCE(value))
                setProductReferences(response.data)
            } else {
                setProductReferences([])
            }
        } catch (e: any) {
            toast.error(t('secondHand.checkReference.getReferencesError'))
        }
    }

    useDebouncedEffect(() => getProductReferences(value), [value], 500)

    function handleClearInput() {
        setValue('')
    }

    return (
        <div className={classes.root} {...getRootProps()}>
            <SearchInput
                {...getInputProps()}
                inHeader={false}
                fullWidth
                value={value}
                handleClear={handleClearInput}
                autoFocus
                data-testid="searchReferenceInput"
                placeholder={t('secondHand.checkReference.searchReference')}
            />
            {groupedOptions.length > 0 ? (
                <ul className={classes.menu} {...getListboxProps()}>
                    {groupedOptions.slice(0, 50).map((option, index) => (
                        <li {...getOptionProps({ option, index })} key={option.id} data-testid="referenceOption">
                            <SecondHandProductCard
                                image={option.image!}
                                name={option.name}
                                reference={option.product.labelReference}
                                size={option.size}
                                className={classes.productCard}
                                imageSize={SquaredImageSize.XXS}
                            />
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    )
}

export default SearchReference
