import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'

import { IUniqueProduct } from 'interfaces'
import rmsApi from 'utils/api'
import { UNIQUE_PRODUCT_UPDATE_PUID_URL } from 'utils/routes/backend'
import ScanInput from '_molecules/ScanInput'
import { faArrowRight, faScannerGun } from '@fortawesome/pro-light-svg-icons'
import { faBarcodeScan } from '@fortawesome/pro-regular-svg-icons'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import ModalFooter from '_molecules/ModalFooter'
import { toast } from 'utils/toast'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            '& .MuiTypography-body1': {
                marginBottom: theme.space(2),
            },
        },
        scanInput: {
            width: '100%',
            marginTop: theme.space(3),
            [theme.breakpoints.down('md')]: {
                marginTop: 0,
            },
            '& .MuiInput-root': {
                backgroundColor: '#F7F8FB',
                boxShadow: 'none',
            },
        },
    }),
)

export type RefreshPUIDSubmitType = {
    uniqueProduct: IUniqueProduct | null
    error: string
}

type RefreshPUIDModalProps = {
    isOpen: boolean
    initialPUID?: string
    handleClose: () => void
    handleSuccess?: (barcodeUid: string) => void
}

const RefreshPUIDModal: React.FC<RefreshPUIDModalProps> = ({
    initialPUID = '',
    handleSuccess,
    handleClose,
    isOpen,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const buildErrorMessage = useErrorMessage()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [value, setValue] = useState<string>('')

    async function refreshUniqueProductBarcode(newPUID: string): Promise<void> {
        try {
            const uniqueProductRequest = await rmsApi.post(UNIQUE_PRODUCT_UPDATE_PUID_URL, {
                barcodeUid: initialPUID,
                newBarcodeUid: newPUID,
            })

            const newBarcodeUid = uniqueProductRequest.data.barcodeUid

            toast.success(t('refreshPUID.success', { OLD: initialPUID, NEW: newBarcodeUid }))
            handleSuccess && handleSuccess(newBarcodeUid)
            handleClose()
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { barcodeUid: initialPUID, newBarcodeUid: newPUID }))
        }
    }

    return (
        <ModalWithHeader
            headerType={HeaderType.MOBILE}
            title={`${t('refreshPUID.title')} ${initialPUID}`}
            tagline={t('refreshPUID.tagline')}
            handleClose={handleClose}
            headerIcon={faBarcodeScan}
            headerIconColor={theme.palette.warning[600]}
            withDesktopTagline
            width={520}
            open={isOpen}
            className={classes.modal}
        >
            <>
                <ScanInput
                    placeholder={t('returnParcel.scanProductInputPlaceholder')}
                    onSubmit={refreshUniqueProductBarcode}
                    iconStart={faScannerGun}
                    className={`${classes.scanInput} noKeyboardListener`}
                    value={value}
                    onChange={setValue}
                />
                {!isMobile && (
                    <ModalFooter
                        label={t('addComment.submitComment')}
                        endIcon={faArrowRight}
                        onSubmit={() => refreshUniqueProductBarcode(value)}
                        onCancel={handleClose}
                        submitDisabled={value === ''}
                    />
                )}
            </>
        </ModalWithHeader>
    )
}

export default RefreshPUIDModal
