import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import InputField, { InputFieldProps } from '_atoms/inputs/InputField'
import { faClose, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchInput: {
            '& .MuiInputAdornment-positionStart svg': {
                color: theme.palette.primary[600],
            },
            '& .MuiInput-root': {
                height: theme.space(8),
            },
            '& svg': {
                color: theme.palette.neutral[800],
            },
        },
        clear: {
            cursor: 'pointer',
        },
        inHeaderSearchInput: {
            '& .MuiInput-root': {
                [theme.breakpoints.down('sm')]: {
                    height: theme.space(12),
                    borderRadius: 4,
                    border: 'none',
                    backgroundColor: theme.palette.common.white,
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                },
            },
        },
    }),
)

interface SearchInputProps extends InputFieldProps {
    inHeader?: boolean
    handleClear?: () => void
}

const SearchInput: React.FC<SearchInputProps> = React.forwardRef(
    ({ className = '', inHeader = true, handleClear, value, ...props }, ref) => {
        const classes = useStyles()
        const displayClear = Boolean(handleClear) && Boolean(value)

        return (
            <InputField
                className={`${classes.searchInput} ${className} ${inHeader ? classes.inHeaderSearchInput : ''}`}
                iconStart={faMagnifyingGlass}
                endAdornment={
                    displayClear && <FontAwesomeIcon icon={faClose} onClick={handleClear} className={classes.clear} />
                }
                value={value}
                ref={ref}
                {...props}
            />
        )
    },
)

export default SearchInput
