import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, Grid, makeStyles, Tab, Theme, Typography } from '@material-ui/core'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { AFTERSALES_STATS_URL, REVIEWS_TO_CSV_URL } from 'utils/routes/backend'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import Figure from 'components/statistics/Figure'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import {
    fetchStats,
    getChartsColors,
    getDoughnutOptions,
    getHorizontalBarChartOptions,
    getStatsStyles,
} from 'components/reusable/utils/StatsUtils'
import LoadingIndicator from 'components/reusable/LoadingIndicator'
import Page from '_organisms/Page'
import fileDownload from 'js-file-download'
import DateRangePicker from '_atoms/inputs/DateRangePicker'
import rmsApi from 'utils/api'
import ExportButton from '../../_atoms/inputs/ExportButton'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

ChartJS.register(...registerables)

const statsStyles = getStatsStyles()
const useStyles = makeStyles((_theme: Theme) => createStyles(statsStyles))

const AftersalesStatsApp: React.FC = () => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const [activeTab, setActiveTab] = useState<string>('general')
    const [stats, setStats] = useState<any>(null)
    const [fullChartOptions, setFullChartOptions] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean | string>(true)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    dayjs.locale(i18n.language)

    useEffect(() => {
        fetchStats(setIsLoading, AFTERSALES_STATS_URL, formatStatsData, startDate, endDate)
        // eslint-disable-next-line
    }, [selectedCustomers, selectedWarehouse, startDate, endDate])

    const doughnutOptions: any = getDoughnutOptions()
    const barChartOptions: any = getHorizontalBarChartOptions()
    const chartColors: string[] = getChartsColors()
    ChartJS.overrides.doughnut.plugins.tooltip = doughnutOptions.plugins.tooltip

    const formatStatsData = (statsToFormat: any) => {
        const formattedData: any = {}
        const buildingChartOptions: any = {}
        setFullChartOptions({})
        formattedData.globalScore = {
            figureDescription: 'aftersalesStats.figures.globalScore.description',
            value: Number(statsToFormat.allScores[0].global_score).toLocaleString(),
        }
        formattedData.npsScore = {
            figureDescription: 'aftersalesStats.figures.npsScore.description',
            value: Number(statsToFormat.allScores[0].nps_score).toLocaleString(),
            hint: 'aftersalesStats.figures.npsScore.hint',
        }
        formattedData.shippingScore = {
            figureDescription: 'aftersalesStats.figures.shippingScore.description',
            value: Number(statsToFormat.allScores[0].shipping_score).toLocaleString(),
            hint: 'aftersalesStats.figures.shippingScore.hint',
        }
        formattedData.returnScore = {
            figureDescription: 'aftersalesStats.figures.returnScore.description',
            value: Number(statsToFormat.allScores[0].return_score).toLocaleString(),
            hint: 'aftersalesStats.figures.returnScore.hint',
        }

        formattedData.npsScoreDistribution = {
            labels: statsToFormat.npsScoreDistribution.map((item: any) => {
                return item.score
            }),
            datasets: [
                {
                    backgroundColor: chartColors,
                    data: statsToFormat.npsScoreDistribution.map((item: any) => {
                        return item.nb_answers
                    }),
                },
            ],
        }
        buildingChartOptions.npsScoreDistribution = JSON.parse(JSON.stringify(barChartOptions))
        buildingChartOptions.npsScoreDistribution.plugins.title.text = t(
            'aftersalesStats.figures.npsScoreDistribution.title',
        )

        formattedData.shippingScoreDistribution = {
            labels: statsToFormat.shippingScoreDistribution.map((item: any) => {
                return item.score
            }),
            datasets: [
                {
                    backgroundColor: chartColors,
                    data: statsToFormat.shippingScoreDistribution.map((item: any) => {
                        return item.nb_answers
                    }),
                },
            ],
        }
        buildingChartOptions.shippingScoreDistribution = JSON.parse(JSON.stringify(barChartOptions))
        buildingChartOptions.shippingScoreDistribution.plugins.title.text = t(
            'aftersalesStats.figures.shippingScoreDistribution.title',
        )

        formattedData.returnScoreDistribution = {
            labels: statsToFormat.returnScoreDistribution.map((item: any) => {
                return item.score
            }),
            datasets: [
                {
                    backgroundColor: chartColors,
                    data: statsToFormat.returnScoreDistribution.map((item: any) => {
                        return item.nb_answers
                    }),
                },
            ],
        }
        buildingChartOptions.returnScoreDistribution = JSON.parse(JSON.stringify(barChartOptions))
        buildingChartOptions.returnScoreDistribution.plugins.title.text = t(
            'aftersalesStats.figures.returnScoreDistribution.title',
        )

        setFullChartOptions(buildingChartOptions)
        setStats(formattedData)
        setIsLoading(false)
    }

    const handleTabChange = (event: any, newValue: any) => {
        setActiveTab(newValue)
    }

    const handleEndDateChange = (endDateToSet: Dayjs) => {
        if (endDateToSet.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDateToSet).subtract(1, 'day'))
            setEndDate(endDateToSet)
        } else {
            setEndDate(endDateToSet)
        }
    }

    const downloadReviewsToCsv = async () => {
        try {
            const downloadDate = dayjs().format('YYYY-MM-DD-HH-mm-ss')

            let url = REVIEWS_TO_CSV_URL
            let filename = `reviews-${downloadDate}`
            if (startDate && endDate) {
                const from = dayjs(startDate).format('YYYY-MM-DD')
                const to = dayjs(endDate).format('YYYY-MM-DD')
                filename += `-from-${from}-to-${to}`
                url += `?from=${from}&to=${to}`
            }
            filename += `.csv`
            const res = await rmsApi.get(url, { responseType: 'blob' })
            fileDownload(res.data, filename)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    const exportAllButton = () => {
        if (activeTab === 'general') {
            return <ExportButton onClick={() => downloadReviewsToCsv()} text={t('aftersalesStats.exportButton')} />
        }
    }

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.aftersales')}
            tagline={t('aftersalesStats.tagline')}
            rightAddon={{ addon: exportAllButton(), displayOnMobile: false }}
            withDivider={false}
        >
            <TabContext value={activeTab}>
                <Box className={classes.tabsBox}>
                    <TabList
                        className={classes.tabs}
                        onChange={handleTabChange}
                        aria-label="stat-tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label={t('aftersalesStats.tabs.general.menuTitle')} value="general" />
                        <Tab label={t('aftersalesStats.tabs.netPromoterScore.menuTitle')} value="netPromoterScore" />
                        <Tab label={t('aftersalesStats.tabs.shipping.menuTitle')} value="shipping" />
                        <Tab label={t('aftersalesStats.tabs.return.menuTitle')} value="return" />
                    </TabList>
                </Box>
                <TabPanel className={classes.tabContent} value="general">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('aftersalesStats.tabs.general.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('aftersalesStats.tabs.general.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                        disabled
                    />
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.globalScore && (
                                <Figure testId="global-score" figure={stats.globalScore} main={true} />
                            )}
                        </Grid>
                        <Grid item md={12} className={classes.spacer} />
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.npsScore && <Figure testId="nps-score" figure={stats.npsScore} withColor={true} />}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.shippingScore && (
                                <Figure testId="shipping-score" figure={stats.shippingScore} withColor={true} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.returnScore && (
                                <Figure testId="return-score" figure={stats.returnScore} withColor={true} />
                            )}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="netPromoterScore">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('aftersalesStats.tabs.netPromoterScore.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('aftersalesStats.tabs.netPromoterScore.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                        disabled
                    />
                    <Grid container spacing={3} className={classes.gridContainer} justify="space-between">
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.npsScore && <Figure testId="nps-score" figure={stats.npsScore} withColor={true} />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.verticalBarChartContainer}>
                                {stats?.npsScoreDistribution && (
                                    <Chart
                                        type="bar"
                                        data={stats?.npsScoreDistribution}
                                        options={fullChartOptions?.npsScoreDistribution}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="shipping">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('aftersalesStats.tabs.shipping.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('aftersalesStats.tabs.shipping.tagline')}</Typography>
                    <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                        disabled
                    />
                    <Grid container spacing={3} className={classes.gridContainer} justify="space-between">
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.shippingScore && (
                                <Figure testId="shipping-score" figure={stats.shippingScore} withColor={true} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.verticalBarChartContainer}>
                                {stats?.shippingScoreDistribution && (
                                    <Chart
                                        type="bar"
                                        data={stats?.shippingScoreDistribution}
                                        options={fullChartOptions?.shippingScoreDistribution}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="return">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('aftersalesStats.tabs.return.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('aftersalesStats.tabs.return.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                        disabled
                    />
                    <Grid container spacing={3} className={classes.gridContainer} justify="space-between">
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.returnScore && (
                                <Figure testId="return-score" figure={stats.returnScore} withColor={true} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.verticalBarChartContainer}>
                                {stats?.returnScoreDistribution && (
                                    <Chart
                                        type="bar"
                                        data={stats?.returnScoreDistribution}
                                        options={fullChartOptions?.returnScoreDistribution}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </Page>
    )
}

export default AftersalesStatsApp
