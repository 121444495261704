import * as React from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { UniqueProductStatus, UniqueProductStatusFront } from 'interfaces'
import { SquaredImageSize } from '_atoms/images/SquaredImage'
import LabeledValue from '_molecules/LabeledValue'
import { useTranslation } from 'react-i18next'
import ReturnProductCard from '../ReturnProductCard'
import { PrismicQuality } from 'utils/prismic'
import Button from '../../../_atoms/buttons/Button'
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
            fontWeight: 300,
            width: theme.space(7),
            marginRight: theme.space(1),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(10),
                fontWeight: 400,
                lineHeight: theme.typography.pxToRem(15),
            },
        },
        value: {
            fontFamily: 'Hanken Grotesk',
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 300,
            lineHeight: theme.typography.pxToRem(22),
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(10),
                fontWeight: 400,
                lineHeight: theme.typography.pxToRem(15),
            },
        },
        refValue: {
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(11),
                lineHeight: theme.typography.pxToRem(16.5),
            },
        },
        labelsAndActions: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.up('sm')]: {
                paddingRight: theme.space(5),
            },
        },
        edit: {
            border: `1px solid ${theme.palette.neutral[300]}`,
        },
    }),
)

type SecondHandProductCardProps = {
    name: string
    image: string | null
    reference?: string
    size?: string | null
    quality?: string
    status?: UniqueProductStatus | UniqueProductStatusFront
    onClick?: () => void
    onClickEdit?: () => void
    className?: string
    imageSize?: SquaredImageSize
    prismicQualities?: PrismicQuality[]
}

const SecondHandProductCard: React.FC<SecondHandProductCardProps> = ({
    name,
    reference,
    size,
    quality,
    status,
    image,
    onClick,
    onClickEdit,
    className,
    imageSize,
    prismicQualities,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    let uniqueProductQuality
    if (quality) {
        const prismicQuality = prismicQualities?.find((prismicQuality) => prismicQuality.value === quality)
        uniqueProductQuality = prismicQuality?.label || quality
    }

    return (
        <ReturnProductCard
            name={name}
            image={image}
            onClick={onClick}
            status={status}
            imageSize={imageSize}
            className={className}
        >
            <>
                <LabeledValue
                    testId={`uniqueProductCard_${reference}`}
                    labelClassName={classes.label}
                    valueClassName={`${classes.refValue} ${classes.value}`}
                    label={t('wms.uniqueProducts.labelReference')}
                    type="row"
                >
                    {reference}
                </LabeledValue>
                <div className={classes.labelsAndActions}>
                    <div>
                        {quality && (
                            <LabeledValue
                                labelClassName={classes.label}
                                valueClassName={classes.value}
                                label={t('wms.uniqueProducts.quality')}
                                type="row"
                            >
                                {uniqueProductQuality}
                            </LabeledValue>
                        )}
                        {size && (
                            <LabeledValue
                                labelClassName={classes.label}
                                valueClassName={classes.value}
                                label={t('wms.uniqueProducts.size')}
                                type="row"
                            >
                                {size}
                            </LabeledValue>
                        )}
                    </div>
                    {onClickEdit && (
                        <Button
                            data-testid="edit-button"
                            key={`button-${reference}`}
                            startIcon={faPenToSquare}
                            color="neutral"
                            className={classes.edit}
                            onClick={onClickEdit}
                            size={isMobile ? 'small' : 'medium'}
                        />
                    )}
                </div>
            </>
        </ReturnProductCard>
    )
}

export default SecondHandProductCard
