import { useEffect, useState } from 'react'
import { downloadFiles, RMSFile } from 'utils/files'
import { toast } from 'utils/toast'

export const useDisplayFiles = (
    anyFiles: RMSFile[] | undefined,
): { files: RMSFile[]; setFiles: (files: RMSFile[]) => void } => {
    const [files, setFiles] = useState<RMSFile[]>([])

    async function retrieveExistingFiles() {
        try {
            const urls = await downloadFiles(anyFiles!.filter((file) => file.id))

            const newFiles = anyFiles!.filter((file) => !file.id)

            const existingFiles = anyFiles!.map((file) => {
                const urlsLength = urls?.length || 0

                for (let i = 0; i < urlsLength; i++) {
                    if (urls && urls[i].includes(file?.name || '')) {
                        file.url = urls[i]
                        break
                    }
                }
                return file
            })

            setFiles([...existingFiles, ...newFiles])
        } catch (e) {
            toast.error('Files exists but')
        }
    }

    useEffect(() => {
        if (anyFiles?.length) retrieveExistingFiles()
        // eslint-disable-next-line
    }, [])

    return { files, setFiles }
}
