import * as React from 'react'
import { createStyles, makeStyles, Theme, Paper } from '@material-ui/core'
import { UniqueProductStatus, UniqueProductStatusFront } from 'interfaces'
import SquaredImage, { SquaredImageSize } from '_atoms/images/SquaredImage'
import SquaredImageWithStatus from '_molecules/SquaredImageWithStatus'
import Text from '_atoms/text/Text'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
            padding: theme.space(3),
            alignItems: 'stretch',
        },
        content: {
            flexGrow: 1,
            marginLeft: theme.space(4),
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.space(2),
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            },
        },
        infos: {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        name: {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.space(1),
            },
        },
        clickable: {
            cursor: 'pointer',
        },
    }),
)

type ReturnProductCardProps = {
    name: string
    children: JSX.Element | JSX.Element[]
    image: string | null
    status?: UniqueProductStatus | UniqueProductStatusFront
    onClick?: () => void
    imageSize?: SquaredImageSize
    className?: string
}

const ReturnProductCard: React.FC<ReturnProductCardProps> = ({
    name,
    children,
    status,
    image,
    onClick,
    className,
    imageSize,
}) => {
    const classes = useStyles()

    return (
        <Paper
            onClick={onClick}
            className={`${classes.root} ${onClick ? classes.clickable : ''} ${className}`}
            data-testid="returnProductCard"
        >
            {status ? (
                <SquaredImageWithStatus src={image} status={status} size={imageSize} />
            ) : (
                <SquaredImage src={image} size={imageSize} />
            )}
            <div className={classes.content}>
                <Text variant="S2" className={classes.name}>
                    {name}
                </Text>
                <div className={classes.infos}>{children}</div>
            </div>
        </Paper>
    )
}

export default ReturnProductCard
