import * as React from 'react'
import { faBoxesStacked, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Text from '_atoms/text/Text'
import InstructionOverviewTable from './InstructionOverviewTable'
import { IOngoingOperation, RefitOperationStatus } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import dayjs from 'dayjs'
import Status from '_atoms/badges/Status'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            marginBottom: theme.space(4),
        },
        sectionTitle: {
            marginBottom: theme.space(4),
            color: theme.palette.neutral[800],
        },
        chevronCTA: {
            cursor: 'pointer',
            marginRight: theme.space(1),
        },
    }),
)

type InstructionOverviewListProps = {
    onClick: (ongoingOperation: IOngoingOperation) => void
    icon: IconProp
    title: string
    ongoingOperations?: IOngoingOperation[]
    finishRefitOperation?: (refitOperationId: number) => void
}

const InstructionOverviewList: React.FC<InstructionOverviewListProps> = ({
    ongoingOperations,
    onClick,
    icon,
    title,
    finishRefitOperation,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    if (!ongoingOperations || ongoingOperations.length === 0) {
        return <></>
    }

    function buildPuidList(uniqueProductsToProcess: { barcodeUid: string }[]) {
        const count = uniqueProductsToProcess.length
        const label = count > 1 ? t('new_refit.home.product.plural') : t('new_refit.home.product.singular')
        const list = uniqueProductsToProcess.map((uniqueProducts) => uniqueProducts.barcodeUid).join(', ')
        return `${count} ${label} : ${list}`
    }

    return (
        <div className={classes.section}>
            <Text className={classes.sectionTitle} variant="S1">
                {t(`${title}.${ongoingOperations.length > 1 ? 'plural' : 'singular'}`)}
            </Text>
            {ongoingOperations.map((ongoingOperation, index) => (
                <InstructionOverviewTable
                    key={ongoingOperation.id}
                    onClick={() => onClick(ongoingOperation)}
                    sectionTitle={ongoingOperation.refitInstruction.title}
                    sectionIcon={icon}
                    sectionRightAddon={<FontAwesomeIcon className={classes.chevronCTA} icon={faChevronRight} />}
                    itemLabel={buildPuidList(ongoingOperation.uniqueProductsToProcess)}
                    itemIcon={faBoxesStacked}
                    itemRightAddon={
                        ongoingOperation.refitLocation && <Status label={ongoingOperation.refitLocation?.name} />
                    }
                    timer={
                        ongoingOperation.status === RefitOperationStatus.IN_PROGRESS
                            ? ongoingOperation.refitInstruction.estimatedDuration -
                              dayjs().diff(dayjs(ongoingOperation.startedAt), 'second')
                            : 0
                    }
                    onTimerExpire={finishRefitOperation && (() => finishRefitOperation(ongoingOperation.id))}
                    dataTestId={`instructionOverviewTable_${ongoingOperation.refitLocation?.name || index}`}
                />
            ))}
        </div>
    )
}

export default InstructionOverviewList
