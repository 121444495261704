import * as React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Check, ErrorOutline } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingIcon: {
            width: 12,
            height: 12,
        },
        loadingContainer: {
            display: 'inline-flex',
            marginLeft: theme.spacing(2),
            fontSize: theme.typography.pxToRem(12),
        },
        loading: {
            color: theme.palette.grey[400],
        },
        loaded: {
            color: theme.palette.success.main,
        },
        loadingError: {
            color: theme.palette.error.main,
        },
    }),
)

type LoadingIndicatorProps = {
    isLoading: boolean | string
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ isLoading }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.loadingContainer}>
            {isLoading === true && (
                <span className={classes.loading}>
                    <CircularProgress size={12} color="inherit" /> {t('loader.loading')}
                </span>
            )}
            {isLoading === false && (
                <span className={classes.loaded}>
                    <Check className={classes.loadingIcon} /> {t('loader.loaded')}
                </span>
            )}
            {isLoading === 'error' && (
                <span className={classes.loadingError}>
                    <ErrorOutline className={classes.loadingIcon} /> {t('loader.loadingError')}
                </span>
            )}
        </div>
    )
}

export default LoadingIndicator
