import * as React from 'react'
import { createStyles, Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ColumnChart } from 'react-chartkick'
import 'chart.js'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            paddingLeft: theme.spacing(6),
            '& .MuiTypography-root': {
                '& .MuiSvgIcon-root': {
                    paddingLeft: theme.spacing(1),
                    height: '1.25em',
                    width: '1.25em',
                    verticalAlign: '-20%',
                },
            },
            '& .MuiTypography-gutterBottom': {
                marginTop: '1em',
                marginBottom: '1em',
            },
        },
    }),
)

type ChartProps = {
    title: string
    data: any | null
}

const Chart: React.FC<ChartProps> = ({ title, data }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    const chartOpts = {
        label: t('finances.Incomes'),
        borderWidth: 0,
        hoverBackgroundColor: theme.palette.primary.dark,
    }

    // Add translation as a key to get {'janvier': 1234, 'février':134343...}
    let i = -1
    const monthlyData = data?.reduce(
        (accumulator: any, item: any) => ({
            ...accumulator,
            ...{ [t(`dates.months.${++i}`)]: item.amount },
        }),
        {},
    )

    return (
        <Grid item xs={12} sm={12}>
            <div className={classes.sectionHeader}>
                <Typography variant="h4" component="h2" gutterBottom>
                    {title}
                </Typography>
            </div>
            <ColumnChart dataset={chartOpts} messages={{ empty: t('global.noData') }} data={monthlyData} suffix="€" />
        </Grid>
    )
}

export default Chart
