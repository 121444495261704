import * as React from 'react'
import { Grid, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Text from '_atoms/text/Text'
import Button from '_atoms/buttons/Button'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import { useNavigate } from 'react-router-dom'
import { WMS_INSERT_UNIQUE_PRODUCT } from 'utils/routes/frontend'
import hpThumbOms from 'assets/hp_thumb_oms.jpg'
import hpThumbRms from 'assets/hp_thumb_rms.jpg'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        number: {
            width: theme.space(4),
            height: theme.space(4),
            padding: theme.space(2),
            fontSize: theme.typography.pxToRem(11),
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary[600],
            backgroundColor: theme.palette.primary[100],
            marginRight: theme.space(2),
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        gridContainer: {
            maxWidth: theme.space(160),
            margin: 'auto',
        },
        gridItem: {
            borderRadius: theme.space(24 / 5),
            padding: theme.space(4),
            margin: theme.space(2),
            backgroundColor: theme.palette.common.white,
            [theme.breakpoints.down('md')]: {
                margin: theme.space(2, 0),
            },
        },
        stepGridItemContainer: {
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.space(4),
                minWidth: theme.space(58),
            },
        },
        stepGridFirstItem: {
            [theme.breakpoints.down('md')]: {
                marginRight: theme.space(4),
            },
        },
        stepGridItem: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        stepImageContainer: {
            minHeight: theme.space(40),
            background: theme.palette.primary[100],
            marginBottom: theme.space(3),
            borderRadius: theme.space(4 / 5),
        },
        stepImage: {
            width: '100%',
        },
        stepTitle: {
            color: theme.palette.primary[600],
        },
        stepTagline: {
            marginTop: theme.space(2),
        },
        stepButton: {
            marginTop: theme.space(2),
            width: '100%',
        },
        mobileOverflow: {
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                overflowX: 'auto',
            },
        },
    }),
)

const CatalogConfiguration: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [user] = useConnectedUserStore((state: ConnectedUserState) => [state.user])

    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
                <Text variant={isMobile ? 'H4' : 'H6'}>
                    {t('homepage.catalogConfiguration.manageYourProductsTitle')}
                </Text>
                <Text variant="P4">{t('homepage.catalogConfiguration.manageYourProductsTagline')}</Text>
            </Grid>
            <div className={classes.mobileOverflow}>
                <Grid item xs={6} className={`${classes.stepGridItemContainer} ${classes.stepGridFirstItem}`}>
                    <div className={`${classes.gridItem} ${classes.stepGridItem}`}>
                        <div className={classes.stepImageContainer}>
                            <img src={hpThumbOms} className={classes.stepImage} />
                        </div>
                        <div className={classes.titleContainer}>
                            <div className={classes.number}>1</div>
                            <Text variant="S1" className={classes.stepTitle}>
                                {t('homepage.catalogConfiguration.firstStep.title')}
                            </Text>
                        </div>
                        <Text variant="P4" className={classes.stepTagline}>
                            {t('homepage.catalogConfiguration.firstStep.tagline')}
                        </Text>
                        {user.customers[0]?.rpUrl && (
                            <Button
                                className={classes.stepButton}
                                endIcon={faExternalLink}
                                onClick={() => window.open(user.customers[0]?.rpUrl, '_blank')?.focus()}
                            >
                                {t('homepage.catalogConfiguration.firstStep.action')}
                            </Button>
                        )}
                    </div>
                </Grid>
                <Grid item xs={6} className={classes.stepGridItemContainer}>
                    <div className={`${classes.gridItem} ${classes.stepGridItem}`}>
                        <div className={classes.stepImageContainer}>
                            <img src={hpThumbRms} className={classes.stepImage} />
                        </div>
                        <div className={classes.titleContainer}>
                            <div className={classes.number}>2</div>
                            <Text variant="S1" className={classes.stepTitle}>
                                {t('homepage.catalogConfiguration.secondStep.title')}
                            </Text>
                        </div>
                        <Text variant="P4" className={classes.stepTagline}>
                            {t('homepage.catalogConfiguration.secondStep.tagline')}
                        </Text>
                        <Button className={classes.stepButton} onClick={() => navigate(WMS_INSERT_UNIQUE_PRODUCT)}>
                            {t('homepage.catalogConfiguration.secondStep.action')}
                        </Button>
                    </div>
                </Grid>
            </div>
        </Grid>
    )
}

export default CatalogConfiguration
