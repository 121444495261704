import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Tab, Tabs, Theme } from '@material-ui/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TabPanel } from './TabSystem'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabContainer: {
            '& .MuiTabs-flexContainer > *:not(:last-child)': {
                marginRight: theme.space(2),
            },
            minHeight: 0,
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.space(7),
            padding: theme.space(2),
            boxShadow: '0px 0px 10px 0px #0000000D',
            position: 'sticky',
            top: theme.space(3),
            margin: `${theme.space(-11)}px auto ${theme.space(5)}px auto`,
            zIndex: 1,
            width: 'fit-content',
            [theme.breakpoints.down('sm')]: {
                top: theme.space(23),
            },
        },
        tab: {
            transition: 'all linear 0.15s',
            lineHeight: theme.typography.pxToRem(28),
            fontSize: theme.typography.pxToRem(14),
            [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(12),
            },
            fontWeight: 400,
            textTransform: 'none',
            color: theme.palette.neutral[600],
            opacity: 1,
            padding: theme.space(1, 3),
            borderRadius: theme.space(4),
            minWidth: 0,
            minHeight: 0,
            '&.Mui-selected': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary[600],
            },
            '& > .MuiTab-wrapper': {
                flexDirection: 'row',
            },
        },
        startIcon: {
            marginRight: theme.space(2),
        },
        customTabIndicator: {
            background: 'none',
        },
    }),
)

type FloatingTabSystemProps = {
    tabAttributes: { label: string; startIcon?: IconProp }[]
    children: any[]
}

const FloatingTabSystem: React.FC<FloatingTabSystemProps> = ({ tabAttributes, children }) => {
    const classes = useStyles()
    const [currentTab, setCurrentTab] = useState<number>(0)

    function handleTabChange(_e: any, tab: number) {
        setCurrentTab(tab)
    }

    const generateLabel = (tabAttribute: { label: string; startIcon?: IconProp }) => (
        <>
            {tabAttribute.startIcon && <FontAwesomeIcon icon={tabAttribute.startIcon} className={classes.startIcon} />}
            {tabAttribute.label}
        </>
    )

    return (
        <>
            <Tabs
                className={classes.tabContainer}
                value={currentTab}
                onChange={handleTabChange}
                classes={{
                    indicator: classes.customTabIndicator,
                }}
            >
                {tabAttributes.map((tabAttribute) => (
                    <Tab
                        key={tabAttribute.label}
                        className={classes.tab}
                        label={generateLabel(tabAttribute)}
                        data-testid={tabAttribute.label + '_tab'}
                        disableRipple
                    />
                ))}
            </Tabs>
            {children!.map((tabContent, index) => (
                <TabPanel
                    key={`tabContent_${tabAttributes[index].label}`}
                    selectedTabIndex={currentTab}
                    currentTabIndex={index}
                >
                    {tabContent}
                </TabPanel>
            ))}
        </>
    )
}

export default FloatingTabSystem
