import * as React from 'react'
import { useState } from 'react'
import { createStyles, Dialog, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import { IProductReference, IUniqueProduct } from 'interfaces'
import theme from '../../theme'
import ProductOverviewHeader from './ProductOverviewHeader'
import ProductReferenceDetails from './ProductReferenceDetails'
import ProductReferenceVariationsSection from './ProductReferenceVariationsSection'
import ProductOverview from './ProductOverview'
import { ICustomer } from 'interfaces/Customer'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        modale: {
            width: theme.spacing(61),
        },
    }),
)

type ProductOverviewModalProps = {
    productReference: IProductReference
    uniqueProduct?: IUniqueProduct | null
    customer: ICustomer | undefined
    uniqueProductPhoto?: string | null
    customActions?: { label: string; action: () => void }[]
    handleClose: () => void
}

const ProductOverviewModal: React.FC<ProductOverviewModalProps> = ({
    productReference,
    uniqueProduct,
    customer,
    uniqueProductPhoto,
    handleClose,
    customActions,
    children,
}) => {
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
    const [isOpen, setIsOpen] = useState<boolean>(true)

    const { name, sku, eans, image, color, size, product } = productReference

    let createdAt, updatedAt
    if (uniqueProduct) {
        createdAt = uniqueProduct.createdAt
        updatedAt = uniqueProduct.updatedAt
    } else {
        createdAt = productReference.createdAt
        updatedAt = productReference.updatedAt
    }
    function closeModal() {
        setIsOpen(false)
        handleClose()
    }

    return (
        <Dialog open={isOpen} onClose={closeModal} fullScreen={isMobile} data-testid="productOverviewModale">
            <div className={isMobile ? '' : classes.modale}>
                <ProductOverviewHeader
                    title={name}
                    handleClose={handleClose}
                    isSecondHand={uniqueProduct?.isSecondHand}
                >
                    <ProductOverview
                        imgUrl={uniqueProductPhoto || image || product?.image}
                        barcodeUid={uniqueProduct?.barcodeUid}
                        customerName={customer?.name}
                        customActions={customActions}
                    />
                </ProductOverviewHeader>
                <ProductReferenceDetails
                    sku={sku ?? ''}
                    eans={eans ?? []}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                />
                {(color || size) && <ProductReferenceVariationsSection color={color} size={size} />}
                <div style={{ padding: `${theme.spacing(1)}px 0px`, backgroundColor: theme.palette.common.white }}>
                    {children}
                </div>
            </div>
        </Dialog>
    )
}

export default ProductOverviewModal
