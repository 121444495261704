import create from 'zustand/vanilla'

export interface AccessTokenState {
    accessToken: string | undefined
    setAccessToken: (accessToken: string | undefined) => void
    clearAccessToken: () => void
}

const accessTokenStore = create<AccessTokenState>()((set) => ({
    accessToken: undefined,
    setAccessToken: (accessToken) => set(() => ({ accessToken })),
    clearAccessToken: () => set({ accessToken: undefined }),
}))

export default accessTokenStore
