import * as React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        commentContainer: {
            position: 'relative',
            backgroundColor: theme.palette.primary[100],
            borderRadius: 4,
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2, 3),
        },
        details: {
            position: 'absolute',
            right: theme.spacing(1),
            bottom: 0,
            fontStyle: 'italic',
            color: theme.palette.neutral[700],
        },
    }),
)

type CommentBubbleProps = { comment: string; detail?: string }

const CommentBubble: React.FC<CommentBubbleProps> = ({ comment, detail }) => {
    const classes = useStyles()
    return (
        <Box data-testid="comment" className={classes.commentContainer}>
            <div>{comment}</div>
            <div className={classes.details}>{detail}</div>
        </Box>
    )
}

export default CommentBubble
