import * as React from 'react'
import { useEffect, useState } from 'react'
import SwipeableCarousel from './SwipeableCarousel'
import { toast } from 'utils/toast'
import { downloadFiles } from 'utils/files'
import { useTranslation } from 'react-i18next'

type SwipeableCarouselProps = {
    files: any[] | undefined | null // must be of type RMS Files entity
    handleFetchFailure?: () => void
}

const S3SwipeableCarousel: React.FC<SwipeableCarouselProps> = ({ files, handleFetchFailure }) => {
    const [images, setImages] = useState<string[]>([])
    const { t } = useTranslation()

    async function fetchImages() {
        try {
            const imagesData = await downloadFiles(files)
            setImages(imagesData || [])
        } catch (e: any) {
            toast.error(t('files.fetchError', { message: e.message }))
            if (handleFetchFailure) handleFetchFailure()
        }
    }

    useEffect(() => {
        fetchImages()
        // eslint-disable-next-line
    }, [])

    return <SwipeableCarousel images={images} />
}

export default S3SwipeableCarousel
