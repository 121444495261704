import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { faArrowRight, faCamera } from '@fortawesome/pro-regular-svg-icons'
import { deleteS3File, EntityType, getSignedUrl, RMSFile, uploadFiles } from 'utils/files'
import ModalWithHeader, { HeaderType } from '../_molecules/ModalWithHeader/ModalWithHeader'
import InputField from '_atoms/inputs/InputField'
import Multiselect from '_atoms/inputs/Multiselect'
import FileInput from '_atoms/inputs/FileInput'
import BasicCheckbox, { BasicCheckboxProps } from '_atoms/inputs/Checkbox'
import RadioGroup, { RadioGroupProps } from '_atoms/inputs/RadioGroup'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { useDisplayFiles } from 'utils/hooks/useDisplayFiles'
import { toast } from 'utils/toast'
import PictureGrid from '_molecules/pictures/PictureGrid'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filesContainer: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                overflow: 'auto',
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(3)}px`,
            marginBottom: theme.space(5),
        },
        fileButton: {
            border: `1px solid ${theme.palette.neutral[300]}`,
        },
        radio: {
            display: 'flex',
            flexDirection: 'row',
            gap: `${theme.space(2)}px`,
        },
        checkbox: {
            margin: 0,
            marginBottom: theme.space(1),
            '& .MuiButtonBase-root': {
                padding: theme.space(1),
                marginRight: theme.space(2),
            },
        },
        button: {
            minHeight: theme.space(8),
            width: '100%',
        },
    }),
)

type ReportIssueProps = {
    isOpen: boolean
    handleClose: () => void
    handleSubmit: (comment: string, selectedIssues: number[], files: RMSFile[]) => void
    tagline: string
    initialFiles: RMSFile[]
    initialComment: string
    entityType: EntityType
    initialIssues?: { name: string; id: number }[]
    issueOptions?: { name: string; id: number }[]
    checkboxProps?: BasicCheckboxProps
    radioProps?: RadioGroupProps
}

const ReportIssueModal: React.FC<ReportIssueProps> = ({
    isOpen,
    handleClose,
    handleSubmit,
    tagline,
    checkboxProps,
    radioProps,
    initialFiles,
    initialIssues,
    initialComment,
    issueOptions,
    entityType,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    const { files, setFiles } = useDisplayFiles(initialFiles || [])

    const [issues, setIssues] = useState(initialIssues || [])
    const [comment, setComment] = useState<string>(initialComment || '')
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true)

    const translatedIssues = issueOptions?.map((issue) => ({
        name: t(`productIssue.${issue.name.toLowerCase()}`),
        id: issue.id,
    }))

    useEffect(() => {
        if (files.length || comment.length || issues.length) {
            setIsSubmitButtonDisabled(false)
        } else {
            setIsSubmitButtonDisabled(true)
        }
    }, [files, comment, issues])

    const handleFileDelete = async (fileToDelete: RMSFile) => {
        const fileId = fileToDelete.id
        if (fileId) {
            const error = await deleteS3File(fileId)
            if (error) {
                toast.error(t('files.deleteError') + error.message)
                return
            }
        }
        setFiles(files.filter((file) => file.name !== fileToDelete.name))
    }

    const handleFileInput = async (inputFiles: RMSFile[]) => {
        const newFiles = []
        for (const file of inputFiles) {
            const newFile = await getSignedUrl(file, entityType)
            newFiles.push(newFile)
        }
        setFiles([...files, ...newFiles])
    }

    const submit = useCallback(async () => {
        window.scrollTo(0, 0)
        try {
            const newFiles = files.filter((file) => !file.id)
            await uploadFiles(newFiles)

            const newIssues = issues.map((e) => e.id)
            handleSubmit(comment, newIssues, newFiles)
        } catch (e) {
            toast.error(t('reportIssue.uploadError'))
        }
    }, [comment, issues, files, handleSubmit])

    return (
        <ModalWithHeader
            title={t('reportIssue.title')}
            tagline={tagline}
            width={520}
            handleClose={handleClose}
            disableRestoreFocus
            open={isOpen}
            headerIcon={faCircleExclamation}
            headerIconColor={theme.palette.warning[600]}
            headerType={HeaderType.MOBILE}
        >
            <Box className={classes.content}>
                {radioProps && <RadioGroup {...radioProps} className={classes.radio} />}
                {translatedIssues && (
                    <Multiselect
                        options={translatedIssues}
                        getOptionLabel={(option) => option.name}
                        noOptionsText={t('reportIssue.multiselect.emptyRecordMsg')}
                        placeholder={t('reportIssue.multiselect.placeholder')}
                        value={issues}
                        onChange={(event, values) => setIssues(values)}
                        limitTags={2}
                    />
                )}
                <InputField
                    fullWidth
                    multiline
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    inputProps={{ maxLength: 500 }}
                    data-testid="issueTextarea-comment"
                    placeholder={t('reportIssue.comment.placeholder')}
                    className="noKeyboardListener"
                    helperText={t('reportIssue.comment.helper')}
                />
                <Button
                    component="label"
                    variant="fill"
                    color="neutral"
                    size="small"
                    startIcon={faCamera}
                    className={classes.fileButton}
                >
                    <FileInput multiple handleFile={handleFileInput} />
                    {t('reportIssue.takePicture.addPicture')}
                </Button>
                {files?.length > 0 && <PictureGrid pictures={files} handleDelete={handleFileDelete} />}
            </Box>
            {checkboxProps ? <BasicCheckbox {...checkboxProps} className={classes.checkbox} /> : <></>}
            <Button
                label={t('reportIssue.submitIssue')}
                endIcon={faArrowRight}
                onClick={submit}
                disabled={isSubmitButtonDisabled}
                className={classes.button}
                data-testid="issueButton-submit"
            />
        </ModalWithHeader>
    )
}

export default ReportIssueModal
