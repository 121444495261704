import { AnalyticsBrowser } from '@june-so/analytics-next'
import keycloakStore from 'utils/store/keycloak.store'

export enum JuneEvent {
    REFIT_COMPLETED = 'Refit terminé',
    NEW_REFIT_INSTRUCTION = 'Instruction de refit créée',
    NEW_REFIT_GROUP = 'Groupe de refit créé',
    NEW_BATCH = 'Batch créé',
    PICKING_COMPLETED = 'Picking terminé',
    PACKING_COMPLETED = 'Packing terminé',
    PARCEL_COLLECTION_PDF_CREATED = "PDF d'enlèvement des colis créé",
    INVOICES_DOWNLOADED = 'Factures téléchargées',
}

export let june: AnalyticsBrowser

if (import.meta.env.REACT_APP_JUNE_WRITE_KEY) {
    june = AnalyticsBrowser.load({
        writeKey: import.meta.env.REACT_APP_JUNE_WRITE_KEY.toString(),
    })
}

export const identifyJuneUser = (keycloak: any) => {
    const { profile, realmAccess } = keycloak
    const rmsRoles = realmAccess.roles?.filter((role: string) => /^\p{Lu}/u.test(role)) // check if role starts with uppercase, if so, it's a rms role otherwise it's a june role

    if (june && profile) {
        june.identify(profile.id, {
            email: profile.email || profile.username,
            username: profile.username,
            name: profile.fullName || `${profile.firstName} ${profile.lastName}`,
            roles: rmsRoles?.join(', ') || '',
        })
    }
}
export const trackJuneEvent = (event: JuneEvent) => {
    const keycloakUser = keycloakStore.getState().keycloakUser

    if (june && keycloakUser) {
        june.track({
            userId: keycloakUser.id,
            type: 'track',
            event,
        })
    }
}
