import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { IParcel, IUniqueProduct, UniqueProductStatusFront } from 'interfaces'
import Pill from '_atoms/badges/Pill'
import { useTranslation } from 'react-i18next'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import Button from '_atoms/buttons/Button'
import Page from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import SecondHandProductCard from './SecondHandProductCard'
import ListWithCounter from '_molecules/ListWithCounter'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            [theme.breakpoints.up('sm')]: {
                padding: theme.space(0, 12),
            },
        },
        header: {
            padding: theme.space(0, 5),
            marginBottom: theme.space(5),
            alignItems: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.space(3),
            },
        },
        secondHandChip: {
            margin: theme.spacing(0, 3, 1),
            color: theme.palette.primary[500],
            borderColor: theme.palette.primary[500],
            textTransform: 'uppercase',
        },
    }),
)

type SecondHandProductListProps = {
    parcel: IParcel
    possibleUniqueProducts: IUniqueProduct[]
    processedUniqueProducts: IUniqueProduct[] | undefined
    onSubmit: () => void
    onCancel: () => void
    setSelectedUniqueProduct: (product: IUniqueProduct) => void
}

const SecondHandProductList: React.FC<SecondHandProductListProps> = ({
    parcel,
    possibleUniqueProducts,
    processedUniqueProducts,
    setSelectedUniqueProduct,
    onSubmit,
    onCancel,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const prismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.qualities)

    const customer = parcel.shipment!.renter!.customer

    const renderFinishButton = () => (
        <Button
            label={t('parcel.scan.processed')}
            size="large"
            onClick={() => onSubmit()}
            endIcon={faArrowRight}
            disabled={possibleUniqueProducts?.length > 0}
            data-testid="uncompleteParcel"
        />
    )

    const productsToProcessCount = possibleUniqueProducts.length
    const processedProductsCount = processedUniqueProducts?.length || 0
    const totalProductsCount = productsToProcessCount + processedProductsCount

    return (
        <Page
            title={t('returnParcel.parcelNumber', { parcelNumber: parcel.barcode })}
            section={t('secondHand.secondHandSection')}
            handlePrevious={onCancel}
        >
            <BackgroundWithScanAndFooter footer={renderFinishButton()}>
                <div className={classes.content}>
                    <Pill
                        label={t('secondHand.pillLabel')}
                        variant="stroke"
                        className={classes.secondHandChip}
                        isSmall
                    />
                    <BlueBackgroundHeader
                        title={t('returnParcel.parcelSectionTitle')}
                        tagline={t('returnParcel.parcelSectionTagline')}
                        rightAddon={customer?.name && <Pill label={customer.name} variant="basic" />}
                        className={classes.header}
                    />

                    <ListWithCounter
                        counterProps={{ current: productsToProcessCount, total: totalProductsCount, variant: 'stroke' }}
                        title={t('returnParcel.toProcessProducts')}
                    >
                        {possibleUniqueProducts.map((uniqueProduct, index) => (
                            <SecondHandProductCard
                                key={index}
                                name={uniqueProduct.productReference.name}
                                reference={uniqueProduct.productReference.product!.labelReference}
                                quality={uniqueProduct.quality}
                                size={uniqueProduct.productReference.size}
                                status={UniqueProductStatusFront.TO_PROCESS}
                                image={uniqueProduct.productReference.image}
                                onClick={() => setSelectedUniqueProduct(uniqueProduct)}
                                prismicQualities={prismicQualities}
                            />
                        ))}
                    </ListWithCounter>

                    <ListWithCounter
                        counterProps={{
                            current: processedProductsCount,
                            total: totalProductsCount,
                            variant: processedProductsCount === totalProductsCount ? 'fill' : 'stroke',
                        }}
                        title={t('returnParcel.processedProducts')}
                    >
                        {processedUniqueProducts!.map((uniqueProduct: IUniqueProduct, index: number) => (
                            <SecondHandProductCard
                                key={index}
                                name={uniqueProduct.productReference.name}
                                reference={uniqueProduct.productReference.product!.labelReference}
                                quality={uniqueProduct.quality}
                                size={uniqueProduct.productReference.size}
                                status={uniqueProduct.status}
                                image={uniqueProduct.productReference.image}
                                prismicQualities={prismicQualities}
                                onClickEdit={() => setSelectedUniqueProduct(uniqueProduct)}
                            />
                        ))}
                    </ListWithCounter>
                </div>
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default SecondHandProductList
