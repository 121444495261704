import * as React from 'react'
import { useState } from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page from '_organisms/Page'
import EnrichedIcon from '_molecules/EnrichedIcon'
import { faArrowRight, faBox } from '@fortawesome/pro-light-svg-icons'
import BlueBackground from '_molecules/backgrounds/BlueBackground'
import Button from '_atoms/buttons/Button'
import { IParcel } from 'interfaces'
import { StepHandlerType } from '_organisms/Stepper'
import { EntityType, RMSFile } from 'utils/files'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'
import ReportIssueModal from '_organisms/ReportIssueModal'
import useSelectedCustomersStore from '../../../utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from '../../../utils/store/selectedCustomers.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: theme.space(5),
        },
        firstButton: {
            alignSelf: 'center',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: theme.space(66),
            },
        },
        secondButton: {
            alignSelf: 'center',
            marginTop: theme.space(2),
        },
    }),
)

type CheckPackagingStepProps = {
    parcel: IParcel
    handleSteps?: StepHandlerType
}

const CheckPackagingStep: React.FC<CheckPackagingStepProps> = ({ parcel, handleSteps }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [isIssueModalOpen, setIsIssueModalOpen] = useState<boolean>(false)
    const [shouldDisplaySecondHandElements] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.shouldDisplaySecondHandElements,
    ])

    async function postPackagingIssue(comment?: string, files?: RMSFile[]) {
        const payload = {
            files:
                files?.map((file: RMSFile) => ({
                    name: file.remoteName,
                    type: file.type,
                })) || [],
            comment: comment,
        }

        try {
            const returnPackaging = await rmsApi.post(`app/parcels/${parcel.id}/process-packaging-return`, payload)
            setIsIssueModalOpen(false)
            handleSteps!.onSubmit(returnPackaging)
        } catch (e: any) {
            toast.error(t('reportIssue.postPackagingIssueError', e))
        }
    }

    return (
        <Page
            title={t('returnParcel.parcelNumber', { parcelNumber: parcel.barcode })}
            section={
                shouldDisplaySecondHandElements() ? t('menu.returns.registerSecondHand') : t('menu.returns.register')
            }
            handlePrevious={handleSteps!.previousStep}
        >
            <BlueBackground>
                <EnrichedIcon
                    icon={faBox}
                    title={t('returnParcel.packagingIssueTitle')}
                    tagline={t('returnParcel.packagingIssueTagline')}
                />
                <Box className={classes.buttonContainer}>
                    <Button
                        className={classes.firstButton}
                        data-testid="validateNoPackagingIssue"
                        endIcon={faArrowRight}
                        onClick={() => handleSteps!.onSubmit()}
                        label={t('returnParcel.noPackagingProblem')}
                    />
                    <Button
                        className={classes.secondButton}
                        color="neutral"
                        variant="stroke"
                        size="small"
                        data-testid="reportPackagingIssue"
                        onClick={() => setIsIssueModalOpen(true)}
                        label={t('returnParcel.packagingIssueButton')}
                    />
                </Box>
            </BlueBackground>
            {isIssueModalOpen && (
                <ReportIssueModal
                    isOpen={isIssueModalOpen}
                    tagline={t('reportIssue.tagline.product')}
                    handleClose={() => setIsIssueModalOpen(false)}
                    handleSubmit={(comment: string, _selectedIssues: number[], files: RMSFile[]) =>
                        postPackagingIssue(comment, files)
                    }
                    initialFiles={[]}
                    initialComment=""
                    entityType={EntityType.RETURN_ITEM_OR_RETURN_PACKAGING}
                />
            )}
        </Page>
    )
}

export default CheckPackagingStep
