import { PropsWithChildren } from 'react'
import * as React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        width: '100%',
        minWidth: '100%',
    },
    card: {
        padding: theme.spacing(3),
    },
    storageLocation: {
        textAlign: 'left',
        '&>b': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.common.black,
            padding: '1px 4px',
            borderRadius: 4,
        },
    },
    grey: {
        color: theme.palette.grey[600],
    },
    bold: {
        fontWeight: 'bold',
    },
    small: {
        '&>p': {
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
    },
}))

type Props = {
    stepTitle: string
    stepSubTitle: string
    stepId: number
    expanded: number | null
    handleChange: (panel: number) => (event: React.ChangeEvent<any>, isExpanded: boolean) => void
}

const Step: React.FC<PropsWithChildren<any>> = ({
    stepTitle,
    stepSubTitle,
    stepId,
    expanded,
    handleChange,
    uniqueProduct,
    puidsLocation,
    puidSKU,
    children,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            {uniqueProduct ? (
                <Paper className={classes.card}>
                    <Typography variant="h3">{uniqueProduct.barcodeUid}</Typography>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Typography className={classes.grey}>
                                {t('wms.linkLocation.storageLocation.last')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography className={classes.storageLocation}>
                                {uniqueProduct.storageLocation ? <b>{uniqueProduct.storageLocation}</b> : 'N/A'}
                            </Typography>
                        </Grid>
                        {puidsLocation && (
                            <Grid item xs={8} sm={8} className={classes.small}>
                                <Typography className={classes.grey}>
                                    {t('wms.linkLocation.storageLocation.puidsLocation')}
                                </Typography>
                                {Object.entries(puidsLocation).map(([key, val]) => (
                                    <Typography className={classes.storageLocation} key={key}>
                                        <b>{key}</b> / {val}
                                    </Typography>
                                ))}
                            </Grid>
                        )}
                        {puidSKU && (
                            <Grid item xs={4} sm={4} className={classes.small}>
                                <Typography className={classes.grey}>
                                    {t('wms.linkLocation.storageLocation.sku')}
                                </Typography>
                                <Typography className={classes.bold}>{puidSKU}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            ) : (
                <Accordion
                    expanded={expanded === stepId}
                    onChange={handleChange(stepId)}
                    TransitionProps={{ unmountOnExit: true }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${stepId}bh-content`}
                        id={`${stepId}bh-header`}
                    >
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Typography className={classes.heading}>{stepTitle}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography className={classes.secondaryHeading}>{stepSubTitle}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <div className={classes.details}>{React.cloneElement(children, { stepId })}</div>
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    )
}

export default Step
