import { Theme, createStyles, makeStyles } from '@material-ui/core'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import Button from '_atoms/buttons/Button'
import { faArrowRight, faWarning } from '@fortawesome/pro-regular-svg-icons'
import Text from '_atoms/text/Text'
import { IPickingLocation } from 'interfaces/Picking.interface'
import ListWithCounter from '_molecules/ListWithCounter'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'
import { PICK_PACK_ISSUE_URL, PICK_UNIQUE_PRODUCT_URL } from 'utils/routes/backend'
import { PickPackIssueType } from 'interfaces/PickPackIssue.interface'
import Tips from '_atoms/containers/Tips'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import PickPackProductCard from '_organisms/PickPackProductCard'
import { IUniqueProductOrProductReference } from 'interfaces/Batch.interface'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
        },
        locationPickingTagline: {
            color: theme.palette.neutral[600],
            padding: theme.space(1, 5),
            [theme.breakpoints.up('md')]: {
                padding: theme.space(5, 0),
            },
        },
        issueButtonContainer: {
            backdropFilter: 'blur(1px)',
            display: 'flex',
            '& button': {
                margin: 'auto',
            },
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            '& div': {
                gridRowStart: 1,
                gridColumnStart: 1,
            },
        },
        tip: {
            marginBottom: theme.space(4),
        },
    }),
)

type LocationPickingProps = {
    batchId: number
    location: IPickingLocation
    setCurrentLocation: (location: IPickingLocation | null) => void
}

const LocationPicking: React.FC<LocationPickingProps> = ({ location, setCurrentLocation, batchId }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()

    const [pickedProducts, setPickedProducts] = useState<IUniqueProductOrProductReference[]>([])
    const [productsWithIssue, setProductsWithIssue] = useState<IUniqueProductOrProductReference[]>([])
    const [productsToPick, setProductsToPick] = useState<IUniqueProductOrProductReference[]>(location.products)
    const [issueStep, setIssueStep] = useState<boolean>(false)

    const productListLength = location.products.length
    const isLocationFinished = productListLength - pickedProducts.length - productsWithIssue.length === 0

    function updateListOnPickingUniqueProduct(uniqueProduct: IUniqueProductOrProductReference) {
        let index = productsToPick.findIndex((p) => p.barcodeUid === uniqueProduct.barcodeUid)
        if (index === -1) {
            index = productsToPick
                .filter((p) => !p.barcodeUid)
                .findIndex((p) => p.id === uniqueProduct.productReference!.id)
        }
        const newProductsToPick = [...productsToPick]
        newProductsToPick.splice(index, 1)
        setProductsToPick(newProductsToPick)
        setPickedProducts([...pickedProducts, uniqueProduct])
    }

    function updateListOnCreatingPickPackIssue(index: number) {
        const newProductList = [...productsToPick]
        newProductList.splice(index, 1)
        setProductsWithIssue([...productsWithIssue, productsToPick[index]])
        setProductsToPick(newProductList)
        if (newProductList.length === 0) {
            setIssueStep(false)
        }
    }

    function finishLocation() {
        if (isLocationFinished) setCurrentLocation(null)
        else if (issueStep) toast.error(t('new_orders.errors.processAllIssuesBeforeFinishing'))
        else setIssueStep(true)
    }

    async function createPickPackIssue(product: IUniqueProductOrProductReference, index: number) {
        const uniqueProductId = product.barcodeUid ? product.id : null
        const productReferenceId = uniqueProductId ? null : product.id
        try {
            await rmsApi.post(PICK_PACK_ISSUE_URL, {
                batchId,
                uniqueProductId,
                productReferenceId,
                issueType: PickPackIssueType.PICKING_MISSING_PRODUCT,
            })
            updateListOnCreatingPickPackIssue(index)
            toast.success(t('new_orders.picking.pickingIssueSubmitted'))
        } catch (e: any) {
            toast.error(buildErrorMessage(e, undefined, 'new_orders.errors.createIssueError'))
        }
    }

    async function pickUniqueProduct(barcodeUid: string) {
        try {
            if (productsToPick.length === 0) {
                toast.error(t('new_orders.errors.allProductPickedScanned'))
            } else {
                const uniqueProduct = await rmsApi.post(PICK_UNIQUE_PRODUCT_URL(batchId), {
                    barcodeUid,
                    storageLocation: location.location,
                })
                updateListOnPickingUniqueProduct(uniqueProduct.data)
            }
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { barcodeUid }, 'new_orders.errors.pickUniqueProductError'))
        }
    }

    const renderFinishedButton = (
        <Button
            endIcon={faArrowRight}
            size="large"
            onClick={() => finishLocation()}
            data-testid="finishLocation"
            color={isLocationFinished ? 'success' : 'primary'}
        >
            {t('new_orders.picking.finishedLocationButton')}
        </Button>
    )

    useEffect(() => {
        if (productsToPick.length === 0) {
            setIssueStep(false)
        }
    }, [productsToPick])

    return (
        <Page
            title={`${t('new_orders.picking.location')} ${
                location.location || t('new_orders.picking.unknownLocation')
            }`}
            section={`${t('new_orders.picking.headerTitle')} ${batchId}`}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
            handlePrevious={() => (!issueStep || isLocationFinished ? setCurrentLocation(null) : setIssueStep(false))}
        >
            <BackgroundWithScanAndFooter
                footer={renderFinishedButton}
                scanProps={{
                    placeholder: t('new_orders.picking.puidPlaceholder'),
                    onSubmit: pickUniqueProduct,
                }}
            >
                {issueStep ? (
                    <Tips title={t('new_orders.picking.pickingIssueTip')} className={classes.tip} />
                ) : (
                    <Text variant="P5" className={classes.locationPickingTagline}>
                        {t('new_orders.picking.locationPickingTagline')}
                    </Text>
                )}
                <div className={classes.list}>
                    <ListWithCounter
                        title={t('new_orders.picking.productToPick')}
                        counterProps={{
                            current: productListLength - pickedProducts.length - productsWithIssue.length,
                            total: productListLength,
                            variant: 'stroke',
                        }}
                    >
                        {productsToPick.map((product, index) => (
                            <PickPackProductCard
                                product={product}
                                key={index}
                                dataTestId="productsToPickCard"
                                buttonProps={
                                    issueStep
                                        ? {
                                              label: t('new_orders.picking.addMissingIssueButton'),
                                              startIcon: faWarning,
                                              color: 'error',
                                              onClick: () => createPickPackIssue(product, index),
                                          }
                                        : undefined
                                }
                            />
                        ))}
                    </ListWithCounter>
                    <ListWithCounter
                        title={t('new_orders.picking.issuesToAddress')}
                        counterProps={{
                            current: productsWithIssue.length,
                            total: productListLength,
                            variant: 'stroke',
                            color: 'error',
                        }}
                    >
                        {productsWithIssue.map((product, index) => (
                            <PickPackProductCard product={product} key={index} />
                        ))}
                    </ListWithCounter>
                    <ListWithCounter
                        title={t('new_orders.picking.productPicked')}
                        counterProps={{
                            current: pickedProducts.length,
                            total: productListLength,
                            variant: 'stroke',
                            color: 'success',
                        }}
                    >
                        {pickedProducts.map((product, index) => (
                            <PickPackProductCard product={product} key={index} />
                        ))}
                    </ListWithCounter>
                </div>
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default LocationPicking
