import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    createStyles,
    makeStyles,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    useMediaQuery,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Text from '_atoms/text/Text'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import Pill from '_atoms/badges/Pill'
import Pagination from '_organisms/tableV2/Pagination'
import SettingsHeader from './SettingsHeader'
import SearchField from './SearchField'
import {
    ButtonAction,
    DEFAULT_CURRENT_PAGE,
    DEFAULT_ITEMS_PER_PAGE,
    filterOption,
    PaginationInfos,
} from '../../interfaces/Table.interfaces'
import dayjs from 'dayjs'
import theme from 'theme'
import { TailSpin } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faArrowUpWideShort, faEllipsis } from '@fortawesome/pro-light-svg-icons'
import DropDownPicker from '_molecules/DropDownPicker'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            maxHeight: `calc(100vh - ${theme.space(300 / 5)}px)`,
            overflowY: 'scroll',
            overflowX: 'initial',
            boxShadow: 'none',
            borderRadius: '6px 6px 0 0',
            marginTop: theme.space(2),
            marginBottom: theme.space(8),
        },
        row: {
            borderBottom: `1px solid ${theme.palette.neutral[100]}`,
            maxHeight: theme.space(14),
            '&:hover': {
                backgroundColor: theme.palette.primary[50],
                '& > *:first-child > *:first-child': {
                    backgroundColor: theme.palette.primary[50],
                },
                '& > *:last-child > *:last-child': {
                    backgroundColor: theme.palette.primary[50],
                },
            },
        },
        emptyRowCell: {
            color: theme.palette.grey[500],
            textAlign: 'center',
        },
        headerStyle: {
            textTransform: 'uppercase',
            height: theme.space(7),
            padding: theme.space(1, 3),
            borderBottom: 'none',
            position: 'sticky',
            top: 0,
            zIndex: 2,
        },
        headerCell: {
            backgroundColor: theme.palette.primary[50],
        },
        checkboxHeader: {
            width: theme.space(7),
            padding: '0',
            zIndex: 200,
            textAlign: 'center',
        },
        cell: {
            padding: `${theme.spacing(1.5)}px`,
            color: theme.palette.neutral[700],
            fontSize: theme.typography.pxToRem(12),
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: `${theme.space(4)}px`,
            borderBottom: 'none',
        },
        pointerCursor: {
            cursor: 'pointer',
        },
        checkboxCell: {
            width: theme.space(7),
            borderBottom: 'none',
            padding: 0,
        },
        checkbox: {
            margin: '0 auto',
        },
        checkboxContainer: {
            backgroundColor: theme.palette.common.white,
            width: theme.space(8),
            position: 'absolute',
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
        },
        stickyLeftColumnHeader: {
            position: 'sticky',
            minWidth: theme.space(8),
            left: 0,
            top: 0,
            zIndex: 2,
            paddingLeft: 0,
            paddingRight: 0,
            boxShadow: `2px 0px 2px -2px ${theme.palette.neutral[400]}`,
        },
        stickyLeftColumn: {
            position: 'sticky',
            height: '100%',
            width: theme.space(8),
            left: 0,
            zIndex: 1,
            boxShadow: `2px 2px 2px -2px ${theme.palette.neutral[400]}`,
        },
        stickyRightColumnHeader: {
            position: 'sticky',
            minWidth: theme.space(8),
            right: 0,
            top: 0,
            zIndex: 2,
            paddingLeft: 0,
            paddingRight: 0,
            boxShadow: `-2px 0px 2px -2px ${theme.palette.neutral[400]}`,
        },
        stickyRightColumn: {
            position: 'sticky',
            height: '100%',
            width: theme.space(8),
            right: 0,
            zIndex: 1,
            boxShadow: `-2px 0px 2px -2px ${theme.palette.neutral[400]}`,
        },
        numberBoxLabel: {
            fontSize: theme.typography.pxToRem(11),
            padding: theme.space(2 / 5, 8 / 5),
        },
        loader: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            [theme.breakpoints.down('md')]: {
                left: '40%',
            },
        },
        headerTextContainer: {
            display: 'flex',
            gap: `${theme.space(2)}px`,
            alignItems: 'center',
            flexDirection: 'row',
        },
        headerText: {
            whiteSpace: 'nowrap',
        },
        rowActions: {
            border: 'none!important',
            '& svg': {
                padding: 0,
            },
        },
        selectedRow: {
            backgroundColor: theme.palette.primary[100],
            borderBottom: `1px solid ${theme.palette.primary[200]}`,
            '& > *:first-child > *:first-child': {
                backgroundColor: theme.palette.primary[100],
            },
            '& > *:last-child > *:last-child': {
                backgroundColor: theme.palette.primary[100],
            },
            '&:hover': {
                backgroundColor: theme.palette.primary[100],
                '& > *:first-child > *:first-child': {
                    backgroundColor: theme.palette.primary[100],
                },
                '& > *:last-child > *:last-child': {
                    backgroundColor: theme.palette.primary[100],
                },
            },
        },
    }),
)

/** For a better usage of this component (you can copy/paste this snippet in your component and remove comments)
    const { t } = useTranslation()
    // your data 
    const [rows, setRows] = useState<any[]>([])

    // You might add filterOptions, a function which takes the applyFilter function and the filters itself provided by Table component an array of filterOption (from table interfaces) 
    // to display a filter button in the header of the table. The component you add to it will accept a JSX component
    // as a <TableFilter applyFilter={applyFilter} filterTitle={string}/> children. This component will be displayed when it's filterKey will be clicked-on.
    // Note that in case of searchField, the object below must be like this:
   const filterOptions = (applyFilter: (obj: any) => void, filters: any) => [
        {
            filterKey: SearchFieldDefaultFilterKey,
            label: t('table.searchField'),
            component: (
                <TableFilter
                    filterTitle={t('table.searchField')}
                    applyFilter={(searchField: string) => applyFilter({ searchField })}
                >
                    <MobileSearchField />
                </TableFilter>
            ),
        },
        {
            filterKey: 'key_of_your_filter',
            label: 'Label of your filter',
            component: (
                <TableFilter
                    filterTitle={t('table.searchField')}
                    applyFilter={(searchField: string) => applyFilter({ searchField })}
                >
                    <MY_CUSTOM_FILTER_COMPONENT />
                </TableFilter>
            ),
        },
    ]
 
   // Finally, a BatchActions function  can be added to the table to display a batch action button in the header of the table.
   // if defaultAction=true, the button will always be displayed. Otherwise, it will be displayed only if at least one row is selected. 
   const batchActions = (filters: any, selectedRows: any[]) => [
        {
            label: t(`wms.productReferences.availableActions.${BatchActionsLabels.allToCSV}`),
            action: () => downloadForecastNeedListToCsv(filters),
            isDefaultAction: true, 
        },
    ]

    const headers = ['colonne1', 'colonne2']

    return (
        <TableV2
                fetchRows={fetchProductList}
                header={header}
                rows={productList}
                filterOptions={filterOptions}
                batchActions={batchActions}
                searchFieldPlaceholder={t('wms.productReferences.searchFieldPlaceholder')}
            />
    )
 */

export type SortType = {
    column: string
    ascending: boolean
}

export type HeaderType = {
    label: string
    value: string
    sortable?: boolean
    sortValue?: string
    hidden?: boolean
}

type TableProps = {
    initialHeader: HeaderType[]
    rows: any[]
    fetchRows: (
        filters: any,
        currentPage: number,
        itemsPerPage: number,
        setPagination: (p: PaginationInfos) => void,
        sort?: SortType,
    ) => Promise<any>
    onRowClick?: (row: any, index: number) => void
    defaultAction?: (filters?: any) => ButtonAction
    batchActions?: (filters: any, selectedRows: any[], fetchData: () => void) => ButtonAction[]
    rowActions?: (row: any, index: number, fetchData: () => void) => ButtonAction[]
    searchFieldPlaceholder?: string
    filterOptions?: (filters: any) => filterOption[]
    localStorageName: string
    defaultFilters?: any
}

const TableV2: React.FC<TableProps> = ({
    initialHeader,
    rows,
    fetchRows,
    onRowClick,
    defaultAction,
    batchActions,
    rowActions,
    searchFieldPlaceholder = '',
    filterOptions = () => [],
    localStorageName,
    defaultFilters,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    const [search, setSearch] = useState<string>('')
    const [filters, setFilters] = useState<any>(defaultFilters || {})
    const [sort, setSort] = useState<SortType>()
    const [pagination, setPagination] = useState<PaginationInfos>({
        currentPage: DEFAULT_CURRENT_PAGE,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    })
    const [selectedRows, setSelectedRows] = useState<{ index: number; row: any }[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    let localStorageHeader = localStorage.getItem(localStorageName)
    // Remove hidden property from localStorage if it exists to be able to compare the header with the initialHeader (if we added a column, a sort, etc.)
    const localStorageHeaderWithoutHidden =
        localStorageHeader?.replace(',"hidden":true', '').replace(',"hidden":false', '') || ''
    if (localStorageHeaderWithoutHidden !== JSON.stringify(initialHeader)) {
        localStorage.removeItem(localStorageName)
        localStorageHeader = null
    }
    const [header, setHeader] = useState<HeaderType[]>(
        localStorageHeader ? JSON.parse(localStorageHeader) : initialHeader,
    )

    async function fetchData() {
        setIsLoading(true)
        await fetchRows(
            filters,
            pagination?.currentPage || DEFAULT_CURRENT_PAGE,
            pagination?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE,
            setPagination,
            sort,
        )
        setSelectedRows([])
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [selectedCustomers, selectedWarehouse, filters, pagination?.currentPage, pagination?.itemsPerPage, sort])

    useEffect(() => {
        localStorage.setItem(localStorageName, JSON.stringify(header))
    }, [header])

    function toggleSelectRow(index: number) {
        if (!batchActions || batchActions.length === 0) return
        if (!selectedRows) return

        let newSelection: any[]
        if (index === -1 && selectedRows.length < rows.length) {
            // select all
            newSelection = rows.map((row, index) => ({ row, index }))
        } else if (index === -1 && selectedRows.length === rows.length) {
            // unselect all
            newSelection = []
        } else {
            const clickedRow = rows[index]

            if (selectedRows.map((selected) => selected.index).includes(index)) {
                // unselect one
                newSelection = selectedRows.filter((selected) => selected.index !== index)
            } else {
                // select one
                newSelection = [...selectedRows, { index, row: clickedRow }]
            }
        }
        setSelectedRows(newSelection)
    }

    function applyFilter(newFilter: any) {
        // Check if values were erased but key remain
        const [key, value] = Object.entries(newFilter)[0]
        if (!value || (value instanceof Array && value?.length === 0)) {
            const newFilters = Object.assign({}, filters)
            delete newFilters[key]
            setFilters(newFilters)
        } else {
            setFilters({ ...filters, ...newFilter })
        }
        setSelectedRows([])
    }

    function updatePaginationAndResetSelectedRows(itemsPerPage: number, currentPage: number) {
        setPagination({ ...pagination!, itemsPerPage, currentPage })
        setSelectedRows([])
    }

    function resetFilters() {
        setFilters(defaultFilters || {})
        setSearch('')
        updatePaginationAndResetSelectedRows(pagination?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE, 1)
    }

    const renderedFilterOptions = filterOptions(filters)

    function inferTypeAndRender(value: any) {
        if (!value) {
            return '-'
        }

        const label = value instanceof Date ? dayjs(value.toLocaleString().substr(0, 10)).format('DD/MM/YY') : value

        if (typeof value === 'number' || value instanceof Date)
            return <Pill label={label} color="neutral" variant="basic" isNumber className={classes.numberBoxLabel} />

        return value
    }

    function handleSortByColumnClick(column: string) {
        if (!sort || sort.column !== column) {
            setSort({ ascending: true, column })
        } else {
            setSort({ ascending: !sort.ascending, column })
        }
    }

    const renderSortIcon = (column: string) => (
        <FontAwesomeIcon
            icon={
                (sort?.column === column && sort?.ascending) || sort?.column !== column
                    ? faArrowDownWideShort
                    : faArrowUpWideShort
            }
            color={sort?.column === column ? theme.palette.primary[600] : theme.palette.neutral[400]}
        />
    )

    const renderLoader = () => (
        <tr className={classes.loader}>
            <td>
                <TailSpin visible color={theme.palette.primary.main} height={90} width={90} />
            </td>
        </tr>
    )

    const renderEmptyTable = () => (
        <TableRow key={1} className={classes.row}>
            <TableCell colSpan={header?.length} className={classes.emptyRowCell}>
                {t('table.emptyTable')}
            </TableCell>
        </TableRow>
    )

    return (
        <>
            {!isMobile && (
                <SearchField
                    key={JSON.stringify(filters)}
                    placeholder={searchFieldPlaceholder}
                    onSubmit={(searchField: string) => applyFilter({ searchField })}
                    value={search}
                    setValue={setSearch}
                />
            )}
            <SettingsHeader
                nbSelectedItems={selectedRows?.length || 0}
                filterOptions={renderedFilterOptions}
                defaultAction={defaultAction && defaultAction(filters)}
                availableActions={batchActions ? batchActions(filters, selectedRows || [], fetchData) : []}
                activeFilters={filters}
                updateFilters={setFilters}
                resetFilters={resetFilters}
                setSearch={setSearch}
                applyFilter={applyFilter}
                header={header}
                setHeader={setHeader}
                defaultFilters={defaultFilters}
            />
            <div className={classes.tableContainer}>
                <MuiTable>
                    <TableHead data-testid="tableHead">
                        <TableRow className={`${classes.row} ${classes.headerStyle}`}>
                            {batchActions && (
                                <TableCell
                                    className={`${classes.cell} ${classes.checkboxHeader} ${classes.headerCell} ${classes.stickyLeftColumnHeader}`}
                                >
                                    <BasicCheckbox
                                        label={''}
                                        className={classes.checkbox}
                                        checked={selectedRows?.length === rows.length}
                                        setValue={() => toggleSelectRow(-1)}
                                        dataTestId="selectAllCheckbox"
                                        stopPropagation
                                    />
                                </TableCell>
                            )}
                            {header?.map(
                                ({ label, sortable, sortValue, hidden }: any, index: number) =>
                                    !hidden && (
                                        <TableCell
                                            className={`${classes.cell} ${classes.headerCell} ${
                                                sortable ? classes.pointerCursor : ''
                                            }`}
                                            key={index}
                                            onClick={sortable ? () => handleSortByColumnClick(sortValue) : undefined}
                                        >
                                            <div className={classes.headerTextContainer}>
                                                <Text variant="S3" className={classes.headerText}>
                                                    {t(label)}
                                                </Text>
                                                {sortable && renderSortIcon(sortValue)}
                                            </div>
                                        </TableCell>
                                    ),
                            )}
                            {rowActions && (
                                <TableCell
                                    className={`${classes.cell} ${classes.checkboxHeader} ${classes.headerCell} ${classes.stickyRightColumnHeader}`}
                                />
                            )}
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody data-testid="tableBody">{renderLoader()}</TableBody>
                    ) : (
                        <TableBody data-testid="tableBody">
                            {rows.length > 0
                                ? rows.map((row, index: number) => (
                                      <TableRow
                                          key={index}
                                          onClick={
                                              onRowClick ? () => onRowClick(row, index) : () => toggleSelectRow(index)
                                          }
                                          className={`${classes.row} ${onRowClick ? classes.pointerCursor : ''} ${
                                              selectedRows.map((selected) => selected.index).includes(index)
                                                  ? classes.selectedRow
                                                  : ''
                                          }`}
                                      >
                                          {batchActions && (
                                              <TableCell
                                                  className={`${classes.checkboxCell} ${classes.stickyLeftColumn}`}
                                                  onClick={(event: React.MouseEvent) => {
                                                      event?.stopPropagation()
                                                  }}
                                              >
                                                  <div className={classes.checkboxContainer}>
                                                      <BasicCheckbox
                                                          label={''}
                                                          className={classes.checkbox}
                                                          checked={selectedRows
                                                              .map((selected) => selected.index)
                                                              .includes(index)}
                                                          setValue={() => toggleSelectRow(index)}
                                                          dataTestId="rowCheckbox"
                                                      />
                                                  </div>
                                              </TableCell>
                                          )}
                                          {header?.map(({ value }, index) => {
                                              if (!header[index].hidden) {
                                                  return (
                                                      <TableCell className={classes.cell} key={index}>
                                                          {inferTypeAndRender(row[value])}
                                                      </TableCell>
                                                  )
                                              }
                                          })}
                                          {rowActions && (
                                              <TableCell
                                                  className={`${classes.checkboxCell} ${classes.stickyRightColumn}`}
                                                  onClick={(event: React.MouseEvent) => {
                                                      event?.stopPropagation()
                                                  }}
                                              >
                                                  <div className={classes.checkboxContainer}>
                                                      <DropDownPicker
                                                          value=""
                                                          withFilter={false}
                                                          submitSelection={(actionLabel: string) =>
                                                              rowActions(row, index, fetchData)
                                                                  ?.find((action) => action.label === actionLabel)
                                                                  ?.action()
                                                          }
                                                          options={
                                                              rowActions(row, index, fetchData).map(
                                                                  (action) => action.label,
                                                              ) || []
                                                          }
                                                          data-testid="rowActionsDropDown"
                                                          variant="ghost"
                                                          color="neutral"
                                                          endIcon={faEllipsis}
                                                          className={classes.rowActions}
                                                      />
                                                  </div>
                                              </TableCell>
                                          )}
                                      </TableRow>
                                  ))
                                : renderEmptyTable()}
                        </TableBody>
                    )}
                </MuiTable>
            </div>
            {pagination && (
                <Pagination
                    itemsPerPage={pagination?.itemsPerPage}
                    totalItems={pagination.totalItems}
                    itemCount={pagination.itemCount}
                    onChangeItemsPerPage={(itemsPerPage: number) =>
                        updatePaginationAndResetSelectedRows(itemsPerPage, 1)
                    }
                    totalPages={pagination.totalPages}
                    currentPage={pagination.currentPage}
                    onChangePage={(value: number) =>
                        updatePaginationAndResetSelectedRows(pagination.itemsPerPage!, value)
                    }
                />
            )}
        </>
    )
}

export default TableV2
