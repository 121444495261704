import * as React from 'react'
import { useState } from 'react'
import { Box, makeStyles, Theme, useTheme, Divider, useMediaQuery } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import Dash from '_atoms/Dash'
import Text from '_atoms/text/Text'
import DotsActions, { TooltipAction } from '_molecules/DotsActions'
import { InstructionActionType } from 'interfaces'
import { faEye, faLinkSlash, faBars } from '@fortawesome/pro-light-svg-icons'
import { IRefitInstructionLink } from 'interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from '_atoms/containers/Card'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContentBox: {
            display: 'flex',
            alignItems: 'center',
        },
        actionContentBox: {
            display: 'flex',
            alignItems: 'center',
            direciton: 'row',
        },
        position: {
            padding: theme.space(2, 2, 2, 0),
            fontWeight: 600,
            fontSize: '12px',
            height: theme.space(8),
            width: theme.space(6),
            color: theme.palette.neutral[600],
            textAlign: 'center',
        },
        divider: {
            backgroundColor: theme.palette.neutral[200],
            width: '1px',
            height: theme.space(5),
            marginRight: theme.space(4),
        },
        instructionCategories: {
            color: theme.palette.neutral[700],
        },
        instructionTitle: {
            color: theme.palette.neutral[700],
        },
        actionDivider: {
            borderLeft: `dashed 1px ${theme.palette.neutral[200]}`,
            backgroundColor: 'transparent',
            height: theme.space(5),
            marginLeft: theme.space(4),
            marginRight: theme.space(4),
        },
        dragIcon: {
            cursor: 'move',
        },
    }),
)

type InstructionCardProps = {
    setRefitInstruction: React.Dispatch<React.SetStateAction<IRefitInstructionLink | undefined>>
    setOpenInstructionActionModal: (action: InstructionActionType) => void
    refitInstructionLink: IRefitInstructionLink
    dataTestId: string
}

const InstructionCard: React.FC<InstructionCardProps> = ({
    setRefitInstruction,
    setOpenInstructionActionModal,
    refitInstructionLink,
    dataTestId,
}) => {
    const theme = useTheme()
    const classes = useStyles()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const { t } = useTranslation()

    const [actionsVisible, setActionsVisible] = useState<boolean>(false)

    const { position, refitInstruction } = refitInstructionLink

    function clickTooltipAction(event: Event, action: InstructionActionType) {
        event.stopPropagation() // Prevent triggering parent element click
        setRefitInstruction(refitInstructionLink)
        setOpenInstructionActionModal(action)
    }

    const renderDotAction = () => (
        <DotsActions visible={actionsVisible}>
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionGroups.actionButtons.view')}
                icon={faEye}
                onClick={(e) => clickTooltipAction(e, InstructionActionType.VIEW)}
                data-testid={`${dataTestId}_preview`}
            />
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionGroups.actionButtons.unlink')}
                color={theme.palette.error[600]}
                icon={faLinkSlash}
                onClick={(e) => clickTooltipAction(e, InstructionActionType.DELETE)}
                data-testid={`${dataTestId}_unlink`}
            />
        </DotsActions>
    )

    return (
        <Card
            rightAddon={
                <Box className={classes.actionContentBox}>
                    {renderDotAction()}
                    <Divider className={classes.actionDivider} orientation="vertical" />
                    <FontAwesomeIcon icon={faBars} className={classes.dragIcon} />
                </Box>
            }
            onMouseEnter={() => setActionsVisible(true)}
            onMouseLeave={() => setActionsVisible(false)}
            data-testid={dataTestId}
        >
            <Box className={classes.cardContentBox}>
                <div className={classes.position}>{position}</div>
                <Divider className={classes.divider} orientation="vertical" />
                <Text className={classes.instructionTitle} variant="S1">
                    {refitInstruction.title}
                </Text>
                {isDesktop && (
                    <>
                        <Dash color="#E54C80" size="16px" orientation="oblic" />
                        <Text className={classes.instructionCategories} variant="P4">
                            {refitInstruction.refitCategory.title}
                        </Text>
                        <Dash color="#E54C80" size="16px" orientation="oblic" />
                        <Text className={classes.instructionCategories} variant="P4">
                            {refitInstruction.refitCategory.parentCategory?.title}
                        </Text>
                    </>
                )}
            </Box>
        </Card>
    )
}

export default InstructionCard
