import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import RefitHome from './RefitHome'
import Instruction from './Instruction'
import {
    INewRefitOperation,
    IOngoingOperation,
    IOngoingOperations,
    IRefitOperation,
    IRefitStep,
    RefitOperationStatus,
    RefitScanErrorEnum,
} from 'interfaces'
import { REFIT_OPERATIONS, REFIT_SCAN_UNIQUE_PRODUCT, UPDATE_REFIT_OPERATION } from 'utils/routes/backend'
import RefitTrayScan from './RefitTrayScan'
import { useConfetti } from '_organisms/UseConfetti'
import { JuneEvent, trackJuneEvent } from 'utils/june'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSocketStore, { SocketState } from 'utils/store/useSocketStore'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const errors = {
    [RefitScanErrorEnum.UNIQUE_PRODUCT_NOT_FOUND]: 'new_refit.errors.puidNotFoundError',
    [RefitScanErrorEnum.PRODUCT_NOT_LINKED_WITH_REFIT_GROUP]: 'new_refit.errors.noRefitGroupError',
    [RefitScanErrorEnum.LINKED_TO_WAITING_OPERATION]: 'new_refit.errors.linkedToWaitingOperationError',
    [RefitScanErrorEnum.UNKNOWN]: 'new_refit.errors.unknownError',
    [RefitScanErrorEnum.REFIT_LOCATION_NOT_FOUND]: 'new_refit.errors.locationNotFoundError',
    [RefitScanErrorEnum.REFIT_LOCATION_ALREADY_USED]: 'new_refit.errors.locationAlreadyUsedError',
}

const Refit: React.FC = () => {
    const { t } = useTranslation()
    const { confetti, trigConfetti } = useConfetti()

    const [refitStep, setRefitStep] = useState<IRefitStep | null>()
    const [currentOperation, setCurrentOperation] = useState<IRefitOperation | null | null>()
    const [displayRefitTrayScan, setDisplayRefitTrayScan] = useState(false)
    const [ongoingOperations, setOngoingOperations] = useState<IOngoingOperations>()
    const [lastOperationIgnored, setLastOperationIgnored] = useState<string>()
    const [
        selectedCustomers,
        setDisabledSelectedCustomers,
    ] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.selectedCustomers,
        state.setDisabledSelectedCustomers,
    ])
    const [
        selectedWarehouse,
        setDisabledSelectedWarehouse,
    ] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.selectedWarehouse,
        state.setDisabledSelectedWarehouse,
    ])
    const wsApi = useSocketStore((state: SocketState) => state.socket)

    const fetchOngoingInstructions = useCallback(async () => {
        try {
            const ongoingInstructionsRequest = await rmsApi.get(REFIT_OPERATIONS)
            setOngoingOperations(ongoingInstructionsRequest.data)
        } catch (e) {
            toast.error(t('new_refit.errors.fetchOngoingError'))
        }
    }, [])

    function goToHomePage() {
        if (refitStep) setRefitStep(null)
        if (currentOperation) setCurrentOperation(null)
        if (displayRefitTrayScan) setDisplayRefitTrayScan(false)
    }

    function checkIsLastInstructionIfOperator() {
        const isLastRefitOperation = refitStep?.currentInstructionPosition === refitStep?.totalInstructions
        const operationId = currentOperation!.id
        return { isLastRefitOperation, operationId }
    }

    function checkIsLastInstructionIfAutonomous(ongoingInstruction: IOngoingOperation) {
        const { refitInstructionsToRefitGroups } = ongoingInstruction.refitInstruction
        const currentInstructionPosition = refitInstructionsToRefitGroups[0].position
        const totalInstructions = refitInstructionsToRefitGroups[0].refitGroup.refitInstructionsToRefitGroups.length
        const isLastRefitOperation = currentInstructionPosition === totalInstructions
        const operationId = ongoingInstruction.id
        return { isLastRefitOperation, operationId }
    }

    async function fetchUniqueProductRefitInfos(barcode: string) {
        try {
            const uniqueProductRequest = await rmsApi.get(REFIT_SCAN_UNIQUE_PRODUCT(barcode))
            setRefitStep(uniqueProductRequest.data)
        } catch (e: any) {
            const error: RefitScanErrorEnum = e.response?.data?.message || RefitScanErrorEnum.UNKNOWN
            const message = error === RefitScanErrorEnum.UNKNOWN ? e.message : ''
            toast.error(`${t(errors[error], { PUID: barcode })}${message}`)
        }
    }

    async function startRefitOperation(refitLocationName?: string) {
        try {
            const isFirstRefitOperation = refitStep!.currentInstructionPosition === 1
            const operation: INewRefitOperation = {
                status: RefitOperationStatus.IN_PROGRESS,
                refitInstructionId: refitStep!.instruction.id!,
                uniqueProductId: refitStep!.uniqueProduct.id,
                isFirstRefitOperation,
                refitLocationName,
            }
            const currentOperationRequest = await rmsApi.post(REFIT_OPERATIONS, operation)

            const isAutonomousAndNotMultiple = refitStep!.instruction.isAutonomous && !refitStep!.instruction.isMultiple
            const isMultipleAndNotUseRefitLocations = refitStep!.instruction.isMultiple && !refitStep!.useRefitLocations

            if (displayRefitTrayScan || isAutonomousAndNotMultiple || isMultipleAndNotUseRefitLocations) {
                goToHomePage()
            } else {
                setCurrentOperation(currentOperationRequest.data)
            }
        } catch (e: any) {
            const error: RefitScanErrorEnum = e.response?.data?.message || RefitScanErrorEnum.UNKNOWN
            const message = error === RefitScanErrorEnum.UNKNOWN ? e.message : ''
            toast.error(`${t(errors[error], { refitLocationName })}${message}`)
        }
    }

    async function pauseOperatorRefitOperation(operationId: number) {
        try {
            await rmsApi.patch(UPDATE_REFIT_OPERATION(operationId), {
                status: RefitOperationStatus.PAUSED,
            })
            goToHomePage()
        } catch {
            toast.error(t('new_refit.errors.pauseOperationError'))
        }
    }

    async function resumeOperatorRefitOperation(operation: IRefitOperation) {
        try {
            await rmsApi.patch(UPDATE_REFIT_OPERATION(operation.id), { status: RefitOperationStatus.IN_PROGRESS })
            setCurrentOperation(operation)
        } catch (e) {
            toast.error(t('new_refit.errors.resumeOperationError'))
        }
    }

    async function startAutonomousRefitOperation(operationId: number) {
        try {
            await rmsApi.patch(UPDATE_REFIT_OPERATION(operationId), { status: RefitOperationStatus.IN_PROGRESS })
            await fetchOngoingInstructions()
        } catch (e) {
            toast.error(t('new_refit.errors.startAutonomousOperationError'))
        }
    }

    async function finishRefitOperation(
        operationId: number,
        status: RefitOperationStatus.FINISHED | RefitOperationStatus.IGNORED,
        barcodeUid?: string,
        isLastRefitOperation = false,
    ) {
        try {
            await rmsApi.patch(UPDATE_REFIT_OPERATION(operationId), { status, isLastRefitOperation, barcodeUid })
            if (!barcodeUid) {
                fetchOngoingInstructions()
            }
        } catch (e) {
            toast.error(t('new_refit.errors.finishOperationError'))
        }
    }

    async function ignoreAutonomousRefitOperation(operation: IOngoingOperation) {
        try {
            const barcodeUid = operation.uniqueProductsToProcess[0].barcodeUid
            const { isLastRefitOperation } = checkIsLastInstructionIfAutonomous(operation)
            const isMultiple = operation.uniqueProductsToProcess.length > 1
            const isScanOnly = operation.refitInstruction.scanOnly

            await finishRefitOperation(operation.id, RefitOperationStatus.IGNORED, undefined, isLastRefitOperation)

            if (!isLastRefitOperation && !isMultiple && !isScanOnly) {
                setLastOperationIgnored(barcodeUid)
            } else {
                await fetchOngoingInstructions()
            }
        } catch (e) {
            toast.error(t('new_refit.errors.startAutonomousOperationError'))
        }
    }

    async function handleLastOperationAndStartCurrentOperation() {
        const { lastRefitOperation, uniqueProduct, shouldFinishOperation } = refitStep!

        if (lastRefitOperation) {
            if (lastRefitOperation!.status === RefitOperationStatus.PAUSED) {
                await resumeOperatorRefitOperation(lastRefitOperation)
            } else {
                if (shouldFinishOperation) {
                    const status = lastOperationIgnored ? RefitOperationStatus.IGNORED : RefitOperationStatus.FINISHED
                    await finishRefitOperation(lastRefitOperation.id, status, uniqueProduct.barcodeUid)
                }
            }
        }

        const isMultipleAndUseRefitLocations = refitStep?.instruction.isMultiple && refitStep?.useRefitLocations
        const shouldStartingRefitOperation =
            lastRefitOperation?.status !== RefitOperationStatus.PAUSED && !isMultipleAndUseRefitLocations

        if (shouldStartingRefitOperation) {
            await startRefitOperation()
        }

        if (isMultipleAndUseRefitLocations) {
            setDisplayRefitTrayScan(true)
        }
    }

    useEffect(() => {
        if (refitStep) {
            handleLastOperationAndStartCurrentOperation()
            if (lastOperationIgnored) setLastOperationIgnored(undefined)
        } else {
            fetchOngoingInstructions()
        }
    }, [selectedCustomers, selectedWarehouse, refitStep])

    function checkIsLastRefitInstruction(barcode: string) {
        if (refitStep) {
            return checkIsLastInstructionIfOperator()
        }
        if (ongoingOperations) {
            const terminatedAndInProgressOperations = [
                ...ongoingOperations!.inProgress,
                ...ongoingOperations!.finished,
                ...ongoingOperations!.ignored,
            ]
            const currentOperation = terminatedAndInProgressOperations.filter((operation) =>
                operation.uniqueProductsToProcess.map((uniqueProduct) => uniqueProduct.barcodeUid).includes(barcode),
            )
            if (currentOperation?.length > 0) {
                return checkIsLastInstructionIfAutonomous(currentOperation[0])
            }
        }
        return { isLastRefitOperation: false, operationId: undefined }
    }

    const handleUniqueProductScan = useCallback(
        async (barcode: string) => {
            setCurrentOperation(null)

            const { isLastRefitOperation, operationId } = checkIsLastRefitInstruction(barcode)

            if (isLastRefitOperation && operationId) {
                const status = lastOperationIgnored ? RefitOperationStatus.IGNORED : RefitOperationStatus.FINISHED
                await finishRefitOperation(operationId, status, barcode, true)
                if (refitStep) {
                    goToHomePage()
                } else {
                    fetchOngoingInstructions()
                }
                trigConfetti()
                toast.success(t('new_refit.home.congrats'))

                trackJuneEvent(JuneEvent.REFIT_COMPLETED)
            } else {
                await fetchUniqueProductRefitInfos(barcode)
            }
        },
        [refitStep, lastOperationIgnored, ongoingOperations, currentOperation],
    )

    useEffect(() => {
        if (lastOperationIgnored) {
            handleUniqueProductScan(lastOperationIgnored)
        }
    }, [lastOperationIgnored])

    useEffect(() => {
        return () => {
            wsApi?.emit('pauseCurrentRefitOperation')
        }
    }, [])

    const isNotMultiple = !refitStep?.instruction.isAutonomous
    const isMultipleAndUseRefitLocations = refitStep?.instruction.isMultiple && refitStep?.useRefitLocations
    const displayInstruction = refitStep && (isNotMultiple || isMultipleAndUseRefitLocations)

    useEffect(() => {
        if ((refitStep && displayRefitTrayScan) || displayInstruction) {
            setDisabledSelectedCustomers(true)
            setDisabledSelectedWarehouse(true)
        } else {
            setDisabledSelectedCustomers(false)
            setDisabledSelectedWarehouse(false)
        }
    }, [refitStep, displayRefitTrayScan, displayInstruction])

    if (refitStep && displayRefitTrayScan) {
        return <RefitTrayScan refitStep={refitStep} startRefitOperation={startRefitOperation} />
    }

    if (displayInstruction) {
        // We tell the API which operation is currently processed, in case the user leaves the browser without pausing the operation
        wsApi?.emit('setCurrentRefitOperation', currentOperation)

        return (
            <Instruction
                refitStep={refitStep}
                currentOperation={currentOperation}
                handleUniqueProductScan={handleUniqueProductScan}
                goToHomePage={goToHomePage}
                handleIgnoreInstruction={setLastOperationIgnored}
                handlePauseInstruction={pauseOperatorRefitOperation}
            />
        )
    } else {
        // When we're not displaying an operation, the API have nothing to pause if the user leaves the browser
        wsApi?.emit('setCurrentRefitOperation', undefined)
    }

    return (
        <>
            {confetti()}
            <RefitHome
                ongoingOperations={ongoingOperations}
                handleUniqueProductScan={handleUniqueProductScan}
                startAutonomousRefitOperation={startAutonomousRefitOperation}
                ignoreAutonomousRefitOperation={ignoreAutonomousRefitOperation}
                finishRefitOperation={(operationId) => finishRefitOperation(operationId, RefitOperationStatus.FINISHED)}
            />
        </>
    )
}

export default Refit
