import * as React from 'react'
import { FormControl, Select, MenuItem, createStyles, makeStyles, Theme } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            '& .MuiSelect-root': {
                minWidth: '5ch',
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
            },
        },
    }),
)

type ValuePicker = {
    value: number | string | null | undefined
    choices: { value: string | number; label: string }[]
    onChange: (event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) => void
}

const DropdownValuePicker: React.FC<ValuePicker> = ({ choices, value, onChange }) => {
    const classes = useStyles()
    return (
        <FormControl variant="outlined" className={classes.select}>
            <Select
                id="value-picker"
                name="value-picker"
                value={value}
                onChange={onChange}
                IconComponent={KeyboardArrowDownIcon}
            >
                {choices.map((choice, index) => (
                    <MenuItem key={index} value={choice.value}>
                        {choice.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default DropdownValuePicker
