import * as React from 'react'
import { useState } from 'react'
import VerticalStep from '_organisms/VerticalStepper/VerticalStep'
import RadioButtons from '_atoms/inputs/RadioButtons'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import SimpleSelect from '_atoms/inputs/SimpleSelect'
import { IProductReference, IUniqueProduct } from 'interfaces'
import { HandleSteps } from '_organisms/VerticalStepper/VerticalStepper'
import { toast } from 'utils/toast'
import { StepBulletStatus } from '_atoms/badges/StepBullet'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkSizes: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        select: {
            marginLeft: theme.space(2),
            height: theme.space(8),
        },
    }),
)

type SizeOption = {
    value: number | undefined
    label: string
}[]

type CheckSizeStepProps = {
    handleSteps?: HandleSteps
    uniqueProduct: IUniqueProduct
    productReferences: IProductReference[]
    size: number | ''
    setSize: (size: number | '') => void
    isSizeCorrect: boolean | undefined
    setIsSizeCorrect: (isSizeCorrect: boolean | undefined) => void
    reset?: () => void
}

const CheckSizeStep: React.FC<CheckSizeStepProps> = ({
    uniqueProduct,
    productReferences,
    size,
    handleSteps,
    setSize,
    isSizeCorrect,
    setIsSizeCorrect,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

    const [sizes, setSizes] = useState<SizeOption>([])

    const product = uniqueProduct.productReference.product

    function buildSizeOptionsFromProductReferences(productReferences: IProductReference[]): SizeOption {
        productReferences = productReferences.filter((productReference) => {
            return productReference.id !== uniqueProduct.productReference.id && productReference.size !== null
        })

        productReferences.sort((a, b) => {
            if (a.size && b.size) {
                return a.size.localeCompare(b.size, undefined, { numeric: true, sensitivity: 'base' })
            }
            return 0
        })
        return productReferences.map(
            (productReference) => ({
                value: productReference.id,
                label: productReference.size?.toUpperCase() || '',
            }),
            [],
        )
    }

    async function handleSizeCheck(target: any): Promise<void> {
        const value = target.value
        if (value === isSizeCorrect) return
        setIsSizeCorrect(value)
        if (value) setSize('')
        if (sizes.length === 0 && product) {
            try {
                setSizes(buildSizeOptionsFromProductReferences(productReferences))
            } catch (e: any) {
                toast.error(t('secondHand.checkQuality.errorProduct'))
            }
        }
    }

    function handleSizeChange(event: React.ChangeEvent<{ value: number; name: string }>) {
        setSize(event.target.value as number)
    }

    function checkSizeStatus() {
        if (isSizeCorrect === true) return StepBulletStatus.SUCCESS
        if (size !== '' && isSizeCorrect === false) return StepBulletStatus.SUCCESS
        if (isSizeCorrect === false) return StepBulletStatus.ERROR
        return StepBulletStatus.DEFAULT
    }

    return (
        <VerticalStep
            title={`${t('secondHand.checkQuality.sizeTitle')} : ${uniqueProduct.productReference.size}`}
            handleSteps={handleSteps}
            tagline={t('secondHand.checkQuality.isSizeCorrect')}
            status={checkSizeStatus()}
            dataTestId="CheckSizeStep"
        >
            <div className={classes.checkSizes}>
                <RadioButtons
                    options={[
                        {
                            value: true,
                            label: t('global.yes'),
                        },
                        {
                            value: false,
                            label: t('global.no'),
                        },
                    ]}
                    value={isSizeCorrect}
                    onChange={handleSizeCheck}
                    disabled={false}
                    dataTestId="check-size-radio-buttons"
                    enabledBorder={isMobile}
                    size={isMobile ? 'small' : 'medium'}
                />
                {isSizeCorrect === false && sizes.length > 0 && (
                    <SimpleSelect
                        className={classes.select}
                        value={size}
                        possibleSelections={sizes}
                        handleChange={handleSizeChange}
                        dataTestId="check-size-select"
                        placeholder={t('secondHand.checkQuality.sizePlaceholder')}
                        formatSelectedValue={(label: string) =>
                            `${t('secondHand.checkQuality.sizeRenderValue')} ${label}`
                        }
                    />
                )}
            </div>
        </VerticalStep>
    )
}

export default CheckSizeStep
