import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
    realm: import.meta.env.REACT_APP_KEYCLOAK_REALM?.toString() || 'LizeeRMS-localdev',
    url: import.meta.env.REACT_APP_KEYCLOAK_LOCAL_INSTANCE?.toString() || 'https://keycloak.lizee.io/auth',
    clientId: import.meta.env.REACT_APP_KEYCLOAK_CLIENT?.toString() || 'rms-local-client',
})

export default keycloak
