import * as React from 'react'
import { useTheme } from '@material-ui/core'
import Confetti from 'react-dom-confetti'

type ConfettisProps = {
    active?: boolean
}

const Confettis: React.FC<ConfettisProps> = ({ active = false }) => {
    const theme = useTheme()

    const confettiConf = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: '10px',
        height: '10px',
        perspective: '500px',
        left: '50%',
        colors: [theme.palette.primary.main, theme.palette.secondary.main],
    }

    return (
        <div style={{ height: 0, width: 0, overflowX: 'hidden' }}>
            <div style={{ position: 'fixed', left: '50%' }}>
                <Confetti active={active} config={confettiConf} />
            </div>
        </div>
    )
}

export default Confettis
