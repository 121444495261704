import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from '@material-ui/core'
import debounce from 'lodash/debounce'
import { CollapsibleTable, TPagination } from 'components/reusable/Table'
import { SHIPMENT_BY_FILTERS_URL } from 'utils/routes/backend'
import { FORTH_SHIPMENT_STATUS, IProductReference, ShipmentStatus, ShipmentStatusDisplay } from 'interfaces'
import TableHeaderSelector from 'components/reusable/TableHeaderSelector'
import TableHeaderMenuItem from 'components/reusable/TableHeaderMenuItem'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@material-ui/icons/Warning'
import dayjs, { Dayjs } from 'dayjs'
import LabeledDateRangePicker from '_molecules/LabeledDateRangePicker'
import InputField from '_atoms/inputs/InputField'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import rmsApi from 'utils/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import Status from '_atoms/badges/Status'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.space(6),
        },
        renterInfosTable: {
            marginBottom: theme.spacing(2),
        },
        priority: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.warning.main,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(10),
            },
        },
        filterContainer: {
            justifyContent: 'space-between',
        },
        quickSearchContainer: {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'right',
            },
        },
        pickPackIssue: {
            textAlign: 'center',
            width: theme.space(3),
            height: theme.space(3),
            color: theme.palette.neutral[600],
        },
        activePickPackIssue: {
            color: theme.palette.error[600],
        },
        status: {
            margin: 'auto',
        },
    }),
)

type ProductReferenceQuantity = {
    id: number
    quantity: number
    productReference: IProductReference
}

type OrderListTabProps = {
    status?: ShipmentStatus
}

const OrderListTab: React.FC<OrderListTabProps> = ({ status }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const navigate = useNavigate()
    const [shipmentList, setShipmentList] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [statusFilter, setStatus] = useState<ShipmentStatus | undefined>(status)
    const [receivedPagination, setReceivedPagination] = useState<TPagination>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<number>(30)
    const [searchField, setSearchField] = useState<string>('')
    const [searchValue, setSearchValue] = useState<string>('')
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs().add(15, 'day'))
    const [customers, setCustomers] = useState<any[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<any>()
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    useEffect(() => {
        setCustomers([{ id: 0, name: t('uniqueProductCustomers.all') }, ...selectedCustomers])
    }, [selectedCustomers, t])

    const handleSetCustomer = (customerId: number) => {
        setSelectedCustomerId(customerId)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setCurrentPage(newPage + 1)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setCurrentPage(1)
    }

    const debouncedOmsIdSave = useCallback(
        debounce((nextValue) => setSearchValue(nextValue), 1000),
        [], // will be created only once initially
    )

    const handleUpdateOmsId = (event: any) => {
        const { value: nextValue } = event.target
        setSearchField(nextValue)
        debouncedOmsIdSave(nextValue)
    }

    const handleSetStatus = (status: string) => {
        if (status) {
            navigate(`/orders?status=${status}`)
        } else {
            navigate(`/orders`)
        }
        setStatus(status as ShipmentStatus)
    }

    const handleEndDateChange = (endDate: Dayjs) => {
        if (endDate.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDate).subtract(1, 'day'))
            setEndDate(endDate)
        } else {
            setEndDate(endDate)
        }
    }

    const cellTitles = [
        t('wms.preparation.collapsed.name'),
        t('wms.preparation.collapsed.ean'),
        t('wms.preparation.collapsed.sku'),
        t('wms.preparation.collapsed.quantity'),
        'PUID(s)',
    ]

    async function fetchOrders(): Promise<void> {
        try {
            setLoading(true)

            let params = `?page=${currentPage}&limit=${rowsPerPage}&isReturn=false`
            if (searchValue) {
                params += `&search=${searchValue}`
            } else if (statusFilter) {
                params += `&status=${statusFilter}`
            }

            if (selectedCustomerId) {
                params += `&customerIdFilter=${selectedCustomerId}`
            }

            if (startDate && endDate) {
                const from = dayjs(startDate).format('YYYY-MM-DD')
                const to = dayjs(endDate).format('YYYY-MM-DD')
                params += `&from=${from}&to=${to}`
            }

            const shipmentRequest = await rmsApi.get(`${SHIPMENT_BY_FILTERS_URL}${params}`)

            const { items: shipments, meta } = shipmentRequest.data

            const shipmentToDisplay: any[] = []

            shipments
                .filter((shipment: any) => shipment.order)
                .forEach((shipment: any) => {
                    const row: any = {
                        items: [],
                    }
                    row.nbItems = 0

                    const syliusUrl =
                        shipment.order?.customer?.omsWebhook &&
                        `${shipment.order.customer.omsWebhook.split('rms-api')[0]}admin/orders/by-number/${
                            shipment.order.omsOrderId
                        }`
                    row.omsOrderId = syliusUrl ? (
                        <a href={syliusUrl} target="_blank" rel="noopener noreferrer">
                            {shipment.order.omsOrderId}
                        </a>
                    ) : (
                        shipment.order.omsOrderId
                    )

                    row.rentalDate = `${dayjs(shipment.order.reservedFrom).format('DD/MM/YY')} → ${dayjs(
                        shipment.order.reservedTo,
                    ).format('DD/MM/YY')}`
                    row.shippingDate = dayjs(shipment.shippingDate).format('DD/MM/YY')
                    row.paidAmount = `${shipment.order.paidAmount / 100}€`
                    shipment.parcels.forEach((parcel: any) => {
                        if (!parcel.isReturn)
                            row.forthTrackingCodes = `<a target='_blank' href='${parcel.trackingLink}'>${parcel.trackingCode}</a>`
                        else
                            row.returnTrackingCodes = `<a target='_blank' href='${parcel.trackingLink}'>${parcel.trackingCode}</a>`
                    })
                    shipment.order.productReferences.forEach((productReferenceQuantity: any) => {
                        productReferenceQuantity.productReference.quantity = productReferenceQuantity.quantity
                        row.items.push(productReferenceQuantity.productReference)
                        row.nbItems += productReferenceQuantity.quantity
                    })
                    if (row.forthTrackingCodes)
                        row.trackingCodes = (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `${row.forthTrackingCodes}`,
                                }}
                            />
                        )
                    else row.trackingCodes = ''
                    row.status = t(`shipmentStatus.${shipment.status}`)

                    row.carrierName = shipment.carrier.name
                    row.priority = shipment.order.preparation?.isUrgent ? (
                        <div className={classes.priority}>
                            <WarningIcon /> {t('wms.preparation.highPriority')}
                        </div>
                    ) : (
                        t('wms.preparation.lowPriority')
                    )
                    row.customerName = shipment.order.customer.name
                    row.store = shipment.order.rmsParcelShop?.name || ''
                    row.renterName = shipment.renter.firstName + ' ' + shipment.renter.lastName
                    row.renterEmail = shipment.renter.email

                    row.pickPackIssues = (
                        <FontAwesomeIcon
                            icon={faCircle}
                            className={`${classes.pickPackIssue} ${
                                shipment.order.pickPackIssues.length > 0 ? classes.activePickPackIssue : ''
                            }`}
                        />
                    )

                    row.batchId = shipment.order.batch?.idPerWarehouse

                    const puidsByProductReference = new Map<number, string[]>()
                    for (const uniqueProduct of shipment.uniqueProducts) {
                        const existingPuids = puidsByProductReference.get(uniqueProduct.productReferenceId) || []
                        puidsByProductReference.set(uniqueProduct.productReferenceId, [
                            ...existingPuids,
                            uniqueProduct.barcodeUid,
                        ])
                    }

                    shipmentToDisplay.push({
                        displayed: [
                            row.priority,
                            row.pickPackIssues,
                            row.omsOrderId,
                            row.shippingDate,
                            row.customerName,
                            row.batchId,
                            <Status
                                key={shipment.id}
                                color={ShipmentStatusDisplay[shipment.status as ShipmentStatus]}
                                label={t(`shipmentStatus.${shipment.status}`)}
                                dataTestId={`${shipment.status}`}
                                className={classes.status}
                            />,
                            row.carrierName,
                            row.store,
                            row.trackingCodes,
                            row.rentalDate,
                        ],
                        collapsed: (
                            <>
                                <Table size="small" aria-label="renterInfos" className={classes.renterInfosTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('orders.tableRenterInfos')}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key="renterName">
                                            <TableCell component="th" scope="row">
                                                <strong>{t('orders.rowRenterName')}</strong>
                                            </TableCell>
                                            <TableCell>{row.renterName}</TableCell>
                                        </TableRow>
                                        <TableRow key="renterEmail">
                                            <TableCell component="th" scope="row">
                                                <strong>{t('orders.rowRenterEmail')}</strong>
                                            </TableCell>
                                            <TableCell>{row.renterEmail}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <div className={classes.productTitleContainer}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        {row.nbItems} {`${t('orders.product')}${row.nbItems > 1 ? 's' : ''}`}
                                    </Typography>
                                </div>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            {cellTitles.map((title, index) => (
                                                <TableCell key={index}>{title}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {shipment.order.productReferences.map(
                                            (productReferenceQuantity: ProductReferenceQuantity, idx: number) => (
                                                <TableRow key={idx}>
                                                    <TableCell component="th" scope="row">
                                                        {productReferenceQuantity.productReference.name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {productReferenceQuantity.productReference.eans?.length &&
                                                        productReferenceQuantity.productReference.eans.length > 1
                                                            ? productReferenceQuantity.productReference.eans?.map(
                                                                  (ean, i) => {
                                                                      const EanCode = ean.ean
                                                                      return i === 0 ? EanCode : `, ${EanCode}`
                                                                  },
                                                              )
                                                            : productReferenceQuantity.productReference.eans?.length &&
                                                              productReferenceQuantity.productReference.eans[0].ean}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {productReferenceQuantity.productReference.sku}
                                                    </TableCell>
                                                    <TableCell>{productReferenceQuantity.quantity}</TableCell>
                                                    <TableCell>
                                                        {puidsByProductReference
                                                            .get(productReferenceQuantity.productReference.id!)
                                                            ?.join(', ') || 'N/A'}
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        ),
                    })
                })
            if (meta) setReceivedPagination({ ...meta, handleChangePage, handleChangeRowsPerPage })
            setShipmentList(shipmentToDisplay)
            setLoading(false)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    useEffect(() => {
        fetchOrders()
        // eslint-disable-next-line
    }, [selectedCustomers, selectedWarehouse, statusFilter, currentPage, rowsPerPage, searchValue, startDate, endDate, selectedCustomerId])

    useEffect(() => {
        setCurrentPage(1)
    }, [statusFilter, searchValue, startDate, endDate, selectedCustomerId])

    return (
        <div className={classes.root}>
            <Grid className={classes.filterContainer} container>
                <Grid item xs={12} sm={12} md={8}>
                    <LabeledDateRangePicker
                        label={t('dateRangePicker.selectPeriodExpectedPreparationDate')}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <div className={classes.quickSearchContainer}>
                        <InputField
                            data-testid="ordersSearchInput"
                            value={searchField}
                            placeholder={t('orders.searchInput.placeholder')}
                            fullWidth
                            onChange={handleUpdateOmsId}
                            iconStart={faMagnifyingGlass}
                        />
                    </div>
                </Grid>
            </Grid>
            <Box paddingTop={3}>
                <CollapsibleTable
                    loading={loading}
                    header={[
                        { id: 'priority', label: t('orders.columnPriority') },
                        { id: 'pickPackIssues', label: t('orders.columnPickPackIssues') },
                        { id: 'omsId', label: t('orders.columnOmsOrderId') },
                        { id: 'shippingDate', label: t('orders.columnShippingDate') },
                        { id: 'customer', label: t('orders.columnCustomer') },
                        { id: 'batchId', label: t('orders.columnBatchId') },
                        { id: 'status', label: t('orders.columnOrderStatus') },
                        { id: 'carrier', label: t('orders.columnCarrier') },
                        { id: 'store', label: t('orders.columnStore') },
                        { id: 'tracking', label: t('orders.columnTracking') },
                        { id: 'rentalDate', label: t('orders.columnRentalDate') },
                    ]}
                    overrideHeader={[
                        {},
                        {},
                        {},
                        {},
                        {
                            component: (
                                <TableHeaderSelector>
                                    {customers.map((customer) => (
                                        <TableHeaderMenuItem
                                            key={customer.id}
                                            selected={selectedCustomerId === customer}
                                            name={customer.id}
                                            label={customer.name}
                                            handleUpdate={handleSetCustomer}
                                        />
                                    ))}
                                </TableHeaderSelector>
                            ),
                        },
                        {},
                        {
                            component: (
                                <TableHeaderSelector>
                                    {[null, ...FORTH_SHIPMENT_STATUS].map((status, idx) => (
                                        <TableHeaderMenuItem
                                            key={idx}
                                            selected={statusFilter === status}
                                            name={status}
                                            label={t(`shipmentStatus.${status}`)}
                                            handleUpdate={handleSetStatus}
                                        />
                                    ))}
                                </TableHeaderSelector>
                            ),
                        },
                    ]}
                    paginationProps={receivedPagination}
                    rows={shipmentList}
                    hideCollapsedArrow={true}
                />
            </Box>
        </div>
    )
}

export default OrderListTab
