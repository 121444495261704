import { Container } from '@material-ui/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import UIDSelector from '../refitApp/UIDSelector'
import { toast } from 'utils/toast'

type KeyboardEventListenerProps = {
    onSubmit: (value: string) => void
    onChange?: (value: string) => void
    lockFocus?: boolean
    label?: string
    displayHint?: boolean
}

const empty = () => null

const KeyboardEventListener: React.FC<KeyboardEventListenerProps> = ({
    onSubmit,
    onChange = empty,
    lockFocus = false,
    label = '',
}) => {
    const { t } = useTranslation()

    const [isListenerActive, setListenerActive] = useState<boolean>(false)

    const isNoKeyboardListenerActive = useCallback((maxRec: number, element?: any): boolean | null => {
        const currentElement = element ?? document.activeElement
        if (currentElement && maxRec > 0) {
            if (currentElement.classList.contains('noKeyboardListener')) {
                return true
            }
            return currentElement.parentElement && isNoKeyboardListenerActive(maxRec - 1, currentElement.parentElement)
        }
        return false
    }, [])

    useEffect(() => {
        const handleInput = ({ key }: any) => {
            if (!isNoKeyboardListenerActive(3)) {
                if (key === 'Enter') {
                    if (valueRef.current) onSubmit(valueRef.current)
                    valueRef.current = ''
                } else {
                    onChange(key)
                    valueRef.current = `${valueRef.current}${key}`
                }
            }
        }

        if (isListenerActive) {
            document.addEventListener('keypress', handleInput)
        } else {
            document.removeEventListener('keypress', handleInput)
        }

        return () => {
            document.removeEventListener('keypress', handleInput)
        }
    }, [isListenerActive, isNoKeyboardListenerActive, onChange, onSubmit])

    const valueRef = useRef('')

    const handleScannedCode = (barcode: string) => {
        if (!isNoKeyboardListenerActive(3)) {
            if (barcode === '') {
                toast.error(t('scan.emptyScan'))
            } else {
                onSubmit(barcode)
            }
        }
    }

    const setKeyboardListener = (value: boolean) => {
        setListenerActive(value)
    }

    return (
        <Container>
            <UIDSelector
                label={label}
                setScannedCode={handleScannedCode}
                lockFocus={lockFocus}
                setKeyboardListener={setKeyboardListener}
            />
        </Container>
    )
}
export default KeyboardEventListener
