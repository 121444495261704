import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import SquaredImage, { SquaredImageSize } from '_atoms/images/SquaredImage'
import Text from '_atoms/text/Text'
import SquaredImageWithStatus from '_molecules/SquaredImageWithStatus'
import { UniqueProductStatusFront } from 'interfaces'

const useStyles = makeStyles<Theme>((theme) => ({
    card: {
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.05)',
        padding: theme.space(2),
        display: 'flex',
        gap: `${theme.space(1)}px`,
        alignItems: 'center',
        background: theme.palette.common.white,
    },
    row: {
        display: 'flex',
    },
    label: {
        color: theme.palette.neutral[600],
        textTransform: 'uppercase',
        width: theme.space(7),
    },
    value: {
        color: theme.palette.neutral[900],
    },
    name: {
        color: theme.palette.neutral[800],
    },
}))

export interface ProductCardProps {
    barcodeUid?: string
    ean?: string
    name: string
    image: string | null
    status?: UniqueProductStatusFront
    dataTestId?: string
}

const ProductCard: React.FC<ProductCardProps> = ({
    barcodeUid,
    ean,
    name,
    image,
    status,
    dataTestId = 'productCard',
}) => {
    const classes = useStyles()

    const renderLabelValue = (label: string, value: string) => (
        <div className={classes.row}>
            <Text variant="P4" className={classes.label}>
                {label}
            </Text>
            <Text variant="P4" className={classes.value}>
                {value}
            </Text>
        </div>
    )

    return (
        <div className={classes.card} data-testid={dataTestId}>
            {status ? (
                <SquaredImageWithStatus src={image} status={status} size={SquaredImageSize.S} />
            ) : (
                <SquaredImage src={image} size={SquaredImageSize.S} />
            )}
            <div>
                {barcodeUid && renderLabelValue('puid', barcodeUid)}
                {ean && renderLabelValue('ean', ean)}
                <Text variant="P6" className={classes.name}>
                    {name}
                </Text>
            </div>
        </div>
    )
}

export default ProductCard
