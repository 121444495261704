import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, Popover, useMediaQuery } from '@material-ui/core'
import { faChevronRight, faSliders } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import Button from '_atoms/buttons/Button'
import Text from '_atoms/text/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from 'theme'
import { SearchFieldDefaultFilterKey, filterOption } from '../../interfaces/Table.interfaces'
import TableFilter from './TableFilter'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            padding: theme.space(3),
            marginTop: theme.space(2),
            border: `1px solid ${theme.palette.neutral[200]}`,
            fontSize: theme.space(12 / 5),
            fontWeight: 400,
            borderRadius: 0,
            boxShadow: '2px 2px 15px 0px rgba(19, 39, 86, 0.05)',
            overflow: 'scroll',
        },
        filterTitle: { marginBottom: theme.space(2) },
        optionButton: {
            display: 'flex',
            justifyContent: 'space-between',
            width: theme.space(50),
        },
        flex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
    }),
)

type TableFilterSystemProps = {
    filters: filterOption[]
    applyFilter: (obj: any) => void
}

const TableFilterSystem: React.FC<TableFilterSystemProps> = ({ filters = [], applyFilter }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)
    const [pickedFilter, setPickedFilter] = useState<JSX.Element>()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!anchorEl) setAnchorEl(event.currentTarget)
        setIsMenuOpened(true)
    }

    function handleClose() {
        setIsMenuOpened(false)
        setPickedFilter(undefined)
    }

    if (!filters) return null

    return (
        <>
            <Button
                onClick={handleClick}
                label={t('table.filter')}
                variant="stroke"
                size="small"
                color="neutral"
                startIcon={faSliders}
                data-testid="filterButton"
            />
            <Popover
                anchorEl={anchorEl}
                open={isMenuOpened}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                keepMounted
                PaperProps={{
                    className: classes.menu,
                }}
            >
                {pickedFilter || (
                    <>
                        <Text variant="S1" color="primary" align="center" className={classes.filterTitle}>
                            {t('table.filterBy')}
                        </Text>
                        {filters.map(({ filterKey, label, component }) => {
                            if (!isMobile && filterKey === SearchFieldDefaultFilterKey) return undefined
                            return (
                                <Button
                                    color="neutral"
                                    size="small"
                                    variant="ghost"
                                    key={filterKey}
                                    className={classes.optionButton}
                                    onClick={() =>
                                        setPickedFilter(
                                            <TableFilter
                                                filterTitle={label}
                                                applyFilter={(filterValue) => applyFilter({ [filterKey]: filterValue })}
                                                closeFilterPopover={handleClose}
                                                previousStep={() => setPickedFilter(undefined)}
                                            >
                                                {component}
                                            </TableFilter>,
                                        )
                                    }
                                    data-testid={`${filterKey}Filter`}
                                >
                                    <div className={classes.flex}>
                                        <Text variant="S3">{label.toUpperCase()}</Text>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Button>
                            )
                        })}
                    </>
                )}
            </Popover>
        </>
    )
}

export default TableFilterSystem
