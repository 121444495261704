import * as React from 'react'
import { toast as reactToast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faClose,
    faTriangleExclamation,
    faCircleExclamation,
    faCircleInfo,
} from '@fortawesome/pro-light-svg-icons'
import theme from 'theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ToastType = 'success' | 'error' | 'warning' | 'info'

function buildOptions(color: ToastType, icon: IconProp, progressColorVariant: number) {
    return {
        icon: <FontAwesomeIcon icon={icon} color={theme.palette[color][600]} fontSize={16} />,
        closeButton: (props: { closeToast: () => void }) => (
            <FontAwesomeIcon
                onClick={props.closeToast}
                icon={faClose}
                color={theme.palette[color][900]}
                fontSize={16}
                style={{ padding: '7px 10px' }}
                className="toast-closeButton"
            />
        ),
        style: { backgroundColor: theme.palette[color][50], color: theme.palette[color][900] },
        progressStyle: { backgroundColor: theme.palette[color][progressColorVariant], height: 3 },
    }
}

function setToast() {
    return {
        success: (message: string) => reactToast.success(message, buildOptions('success', faCheck, 400)),
        error: (message: string) => reactToast.error(message, buildOptions('error', faTriangleExclamation, 200)),
        warning: (message: string) => reactToast.warning(message, buildOptions('warning', faCircleExclamation, 200)),
        info: (message: string) => reactToast.info(message, buildOptions('info', faCircleInfo, 200)),
        dismiss: () => reactToast.dismiss(),
    }
}

export const toast = setToast()
