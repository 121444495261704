import { useState } from 'react'
import * as React from 'react'
import { Grid } from '@material-ui/core'
import DropdownValuePicker from '../reusable/DropDownValuePicker'

type Props = {
    stepId?: number
    defaultValue?: string
    possibleValues: { value: string | number; label: string }[]
    onChange: (stepId: number, value: any) => void
}

const ProductStepSelect: React.FC<Props> = ({ defaultValue, possibleValues, stepId = 0, onChange }) => {
    const [value, setValue] = useState(defaultValue)

    function handleChange(event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) {
        defaultValue = event.target.value as string
        onChange(stepId, defaultValue)
        setValue(defaultValue)
    }

    return (
        <Grid item xs={12}>
            <DropdownValuePicker value={value} choices={possibleValues} onChange={handleChange}></DropdownValuePicker>
        </Grid>
    )
}

export default ProductStepSelect
