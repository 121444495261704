import { OrderType } from './Order.interface'
import { PickPackIssueStatus } from './PickPackIssue.interface'

export enum BatchStatus {
    TO_PICK = 'TO_PICK',
    PICKING_IN_PROGRESS = 'PICKING_IN_PROGRESS',
    TO_PACK = 'TO_PACK',
    PACKING_IN_PROGRESS = 'PACKING_IN_PROGRESS',
    FINISHED = 'FINISHED',
}

export enum BatchType {
    SINGLE = 'SINGLE',
    MULTIPLE = 'MULTIPLE',
    MIXED = 'MIXED',
}

export enum BatchFilter {
    ALL = 'all',
    PICKING = 'picking',
    PACKING = 'packing',
}

export const batchPickingStatuses = [BatchStatus.TO_PICK, BatchStatus.PICKING_IN_PROGRESS]
export const batchPackingStatuses = [BatchStatus.TO_PACK, BatchStatus.PACKING_IN_PROGRESS]

export interface IBatchUniqueProduct {
    id: number
}

export interface IBatchShipment {
    id: number
    shippingDate: string
    uniqueProducts: IBatchUniqueProduct[]
}

export interface IBatchOrderToProductReference {
    id: number
    quantity: number
}

export interface IBatchPickPackIssue {
    id: number
    status: PickPackIssueStatus
}

export interface IBatchOrder {
    id: number
    productReferences: IBatchOrderToProductReference[]
    shipments: IBatchShipment[]
    pickPackIssues: IBatchPickPackIssue[]
}

export interface IBatch {
    id: number
    idPerWarehouse: number
    status: BatchStatus
    isUrgent: boolean
    batchType: BatchType
    orderType: OrderType
    orders: IBatchOrder[]
    pickedUniqueProducts: IBatchUniqueProduct[]
    createdAt: string
}

export interface IBatchNeeds {
    productReferences: {
        productReference: IUniqueProductOrProductReference
        quantity: number
        locations?: {
            location: string
            quantity: number
        }[]
    }[]
    uniqueProducts: {
        uniqueProduct: IUniqueProductOrProductReference
        location?: string
    }[]
}

export interface IOrder {
    id: number
    omsOrderId: string
    shipments: {
        id: number
        isReturn: boolean
    }[]
    customer: {
        id: number
        name: string
    }
}

export interface IProductReference {
    id: number
    name: string
    image: string
    eans: {
        id: number
        ean: string
    }[]
    isOptional: boolean
    orders: {
        order: IOrder
    }[]
}

export interface IUniqueProduct {
    id: number
    barcodeUid: string
    productReference: IProductReference
    pickPackIssues?: {
        id: number
        status: PickPackIssueStatus
    }[]
}

export interface IUniqueProductOrProductReference {
    id: number
    barcodeUid?: string
    productReference?: IProductReference
    name?: string
    image?: string
    eans?: {
        id: number
        ean: string
    }[]
    storageLocation?: string
    sku?: string
    pickPackIssues?: {
        id: number
        status: PickPackIssueStatus
    }[]
    savActions?: {
        id: number
        order: IOrder
    }[]
}
