import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import WhiteAndBluePartsCard from '_molecules/WhiteAndBluePartsCard'
import Text from '_atoms/text/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { IPickPackIssueDisplay } from 'interfaces/PickPackIssue.interface'
import { ORDER_PACKING_ROUTE } from 'utils/routes/frontend'
import { FromSearchParam } from 'interfaces/Packing.interface'
import { returnPluralPath } from 'utils/stringConvertion'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.neutral[800],
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            textTransform: 'capitalize',
            justifyContent: 'space-between',
        },
        content: {
            color: theme.palette.neutral[800],
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(1)}px`,
            justifyContent: 'center',
        },
        infoContainer: {
            display: 'flex',
            gap: `${theme.space(1)}px`,
            alignItems: 'center',
            '& svg': {
                width: theme.space(3),
                fontSize: theme.typography.pxToRem(12),
            },
        },
        checkIcon: {
            color: theme.palette.success[700],
        },
        circleIcon: {
            color: theme.palette.warning[600],
        },
        rightAddon: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        chip: {
            borderRadius: '4px',
            backgroundColor: theme.palette.neutral[100],
            color: theme.palette.neutral[600],
            padding: theme.space(2 / 5, 1),
            height: theme.space(16 / 5),
            '& .MuiChip-label': {
                padding: 0,
                fontSize: theme.typography.pxToRem(9),
                fontWeight: 500,
            },
        },
        title: {
            textTransform: 'none',
        },
    }),
)

const PickPackIssueCard: React.FC<IPickPackIssueDisplay> = ({
    orderId,
    omsOrderId,
    customerName,
    batchIdPerWarehouse,
    nbPackedProducts,
    nbDeletedProducts,
    nbAddedProducts,
    orderIsCanceled,
    nbUnknownIssues,
    nbPdfIssues,
    nbMissingProducts,
    needSavAction,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()

    function onClick() {
        navigate(
            `${ORDER_PACKING_ROUTE(orderId)}?from=${
                needSavAction ? FromSearchParam.SAV_ISSUE_LIST : FromSearchParam.OPERATOR_ISSUE_LIST
            }`,
        )
    }

    function buildInfoString(path: string, count: number) {
        return returnPluralPath(t, `new_orders.issueList.${path}`, count)
    }

    let infos = []

    if (orderIsCanceled) {
        infos = [t('new_orders.issueList.canceledOrder')]
    } else if (needSavAction) {
        infos = [buildInfoString('productsWithIssue', nbMissingProducts)]
    } else if (nbMissingProducts) {
        infos = [t('new_orders.issueList.locationSearch')]
    } else if (nbUnknownIssues) {
        infos = [t('new_orders.issueList.unknownError')]
    } else if (nbPdfIssues) {
        infos = [t('new_orders.issueList.labelError')]
    } else {
        if (nbAddedProducts) infos.push(buildInfoString('addedProducts', nbAddedProducts))
        if (nbDeletedProducts) infos.push(buildInfoString('deletedProducts', nbDeletedProducts))
    }

    return (
        <WhiteAndBluePartsCard
            className={classes.root}
            onClick={onClick}
            dataTestId="pickPackIssueCard"
            whiteClass={classes.header}
            blueClass={classes.content}
        >
            <>
                <Text variant="S1" className={classes.title}>
                    {t('returns.parcelInfos.order', { omsOrderId })}
                </Text>
                <div className={classes.rightAddon}>
                    <Text variant="S2">{`${t('new_orders.pickPack.batch')} ${batchIdPerWarehouse}`}</Text>
                    <Chip className={classes.chip} label={customerName} />
                </div>
            </>
            <>
                {nbPackedProducts ? (
                    <div className={classes.infoContainer}>
                        <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
                        <Text variant="P4">
                            {returnPluralPath(t, 'new_orders.issueList.packedProducts', nbPackedProducts)}
                        </Text>
                    </div>
                ) : (
                    <></>
                )}
                {infos.map((info) => (
                    <div key={info} className={classes.infoContainer}>
                        <FontAwesomeIcon icon={faCircleSmall} className={classes.circleIcon} />
                        <Text variant="P4">{info}</Text>
                    </div>
                ))}
            </>
        </WhiteAndBluePartsCard>
    )
}

export default PickPackIssueCard
