import { createStyles, makeStyles, Theme } from '@material-ui/core'
import i18next from 'i18next'
import { DateRange as DateRangeIcon, Remove as LineIcon } from '@material-ui/icons'
import { subDays } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import * as React from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datepicker: {
            fontFamily: "'Oswald', sans-serif",
            fontSize: theme.typography.pxToRem(16),
            width: 95,
            height: 48,
            borderRadius: 10,
            color: theme.palette.primary[800],
            textAlign: 'center',
            border: 0,
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
            [theme.breakpoints.down('md')]: {
                minWidth: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                appearance: 'none',
                '& input': {
                    color: theme.palette.primary.main,
                    margin: 0,
                    '&:webkit-calendar-picker-indicator': {
                        display: 'none',
                    },
                },
                '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
                    display: 'none',
                },
            },
            [theme.breakpoints.up('md')]: {
                fontSize: theme.typography.pxToRem(14),
            },
        },
        calendarContainer: {
            border: 'none',
            boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)', // cf elevation 1
            '& .react-datepicker__header': {
                backgroundColor: 'white',
            },
            '& .react-datepicker__triangle': {
                borderBottomColor: 'white !important',
            },
            '& .react-datepicker__triangle:before': {
                borderBottomColor: 'white !important',
            },
            '& .react-datepicker__day--highlighted': {
                backgroundColor: theme.palette.primary.main,
            },
            '& .react-datepicker__day--selected': {
                backgroundColor: theme.palette.secondary.main,
            },
            '& .react-datepicker__day--keyboard-selected': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        datePickerContainer: {
            zIndex: 10,
            width: 'fit-content',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
            borderRadius: 10,
            background: theme.palette.secondary[50],
            '& input': {
                backgroundColor: theme.palette.secondary[50],
            },
        },
        lastChild: {
            marginRight: 16,
        },
        lineIcon: {
            color: theme.palette.primary[600],
        },
        calendarIcon: {
            marginLeft: 16,
            marginRight: 5,
            color: theme.palette.primary[600],
        },
        sizeException: {
            [theme.breakpoints.between('xs', 1920)]: {
                '& .react-datepicker__triangle': {
                    left: '130px !important',
                },
            },
        },
        popper: {
            [theme.breakpoints.between('xs', 1920)]: {
                left: '-95px !important',
            },
        },
        disabled: {
            opacity: 0.5,
            cursor: 'default',
        },
    }),
)

type DateRangPickerProps = {
    className?: string
    startDate: Dayjs
    setStartDate: (date: Dayjs) => void
    endDate: Dayjs
    setEndDate: (date: Dayjs) => void
    disabled?: boolean
}

const DateRangePicker: React.FC<DateRangPickerProps> = ({
    className,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    disabled,
}: DateRangPickerProps) => {
    const classes = useStyles()
    const [dateRangeSelected, setDateRangeSelected] = useState<Date[]>([])

    useEffect(() => {
        const dateRange = []
        for (let i = 0; i < dayjs(endDate).diff(dayjs(startDate), 'day'); i++) {
            dateRange.push(subDays(endDate.toDate(), i))
        }
        setDateRangeSelected(dateRange)
    }, [startDate, endDate])

    const setStartDateWithCheck = (date: dayjs.Dayjs) => {
        if (date.isValid()) setStartDate(date)
    }

    const setEndDateWithCheck = (date: dayjs.Dayjs) => {
        if (date.isValid()) setEndDate(date)
    }

    return (
        <div id="datepicker-container" className={`react-datePicker ${classes.datePickerContainer} ${className}`}>
            <DateRangeIcon className={classes.calendarIcon} />
            <DatePicker
                selected={startDate.toDate()}
                dateFormat="dd/MM/yyyy"
                onChange={(date: Date) => setStartDateWithCheck(dayjs(date))}
                className={disabled ? `${classes.datepicker} ${classes.disabled}` : classes.datepicker}
                calendarClassName={classes.calendarContainer}
                highlightDates={dateRangeSelected}
                selectsStart
                locale={i18next.language}
                dayClassName={(date) => `cy-datePicker-${dayjs(date).format('MM-DD')}`}
                disabled={disabled}
            />
            <LineIcon className={classes.lineIcon} />
            <DatePicker
                selected={endDate.toDate()}
                dateFormat="dd/MM/yyyy"
                onChange={(date: Date) => setEndDateWithCheck(dayjs(date))}
                className={
                    disabled
                        ? `${classes.datepicker} ${classes.lastChild} ${classes.disabled}`
                        : `${classes.datepicker} ${classes.lastChild}`
                }
                calendarClassName={`${classes.calendarContainer} ${classes.sizeException}`}
                selectsEnd
                highlightDates={dateRangeSelected}
                popperClassName={classes.popper}
                locale={i18next.language}
                dayClassName={(date) => `cy-datePicker-${dayjs(date).format('MM-DD')}`}
                disabled={disabled}
            />
        </div>
    )
}

export default DateRangePicker
