import * as React from 'react'
import { createStyles, makeStyles, Theme, Divider } from '@material-ui/core'
import Text from '_atoms/text/Text'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles<Theme, { width: number }>((theme: Theme) =>
    createStyles({
        header: {
            position: 'fixed',
            background: theme.palette.common.white,
            borderRadius: theme.space(2),
            [theme.breakpoints.up('sm')]: {
                padding: theme.space(8, 12, 0, 12),
                width: ({ width }) => width,
            },
            [theme.breakpoints.down('sm')]: {
                right: 0,
                width: '100%',
                padding: theme.space(6, 3, 0),
            },
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        title: {
            color: theme.palette.neutral[800],
        },
        closeButton: {
            fontWeight: 300,
            '& svg': {
                height: theme.space(16 / 5),
            },
            [theme.breakpoints.down('sm')]: {
                background: theme.palette.neutral[50],
                fontWeight: 400,
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.space(-3),
            },
        },
        divider: {
            marginTop: theme.space(5),
            background: theme.palette.neutral[200],
        },
    }),
)

type DividerHeaderProps = {
    title: string
    width: number
    handleClose?: () => void
}

const DividerHeader = React.forwardRef<HTMLDivElement, DividerHeaderProps>(({ title, handleClose, width }, ref) => {
    const classes = useStyles({ width })

    return (
        <div className={classes.header} ref={ref}>
            <div className={classes.container}>
                <Text variant="H6" className={classes.title} data-testid="modal-title">
                    {title}
                </Text>
                {handleClose && (
                    <Button
                        onClick={handleClose}
                        startIcon={faXmark}
                        shape="squared"
                        size="medium"
                        color="neutral"
                        variant="ghost"
                        className={classes.closeButton}
                        data-testid="close-modal"
                    />
                )}
            </div>
            <Divider className={classes.divider} />
        </div>
    )
})

export default DividerHeader
