export const prismicLang = {
    fr: 'fr-fr',
    en: 'en-us',
    de: 'de-de',
    es: 'es-es',
}

export class PrismicQuality {
    constructor(value: string, label: string, description: string) {
        this.value = value
        this.label = label
        this.description = description
    }

    value: string
    label: string
    description: string
}

export enum PrismicQualityCondition {
    PERFECT_CONDITION = 'perfectCondition',
    VERY_GOOD_CONDITION = 'veryGoodCondition',
    GOOD_CONDITION = 'goodCondition',
    REFUSED_CONDITION = 'refusedCondition',
}
