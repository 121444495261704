import * as React from 'react'
import { useState } from 'react'
import {
    createStyles,
    Divider,
    makeStyles,
    Tab,
    Tabs,
    TabScrollButton,
    TabScrollButtonProps,
    Theme,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabSystem: {
            '&.bottomOnMobile': {
                [theme.breakpoints.down('md')]: {
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    left: 0,
                },
            },
            '&.topOnMobile': {
                [theme.breakpoints.down('md')]: {
                    position: 'fixed',
                    zIndex: 1001,
                    top: 90,
                    right: 0,
                    left: 0,
                },
            },
        },
        tabContainer: {
            [theme.breakpoints.up('md')]: {
                '& .MuiTabs-flexContainer > *:not(:last-child)': {
                    marginRight: theme.space(2),
                },
                minHeight: 0,
            },
            [theme.breakpoints.down('md')]: {
                background: theme.palette.neutral[50],
            },
            '&.bottomOnMobile': {
                [theme.breakpoints.down('md')]: {
                    padding: theme.space(0, 3, 1, 3),
                },
            },
            '&.topOnMobile': {
                [theme.breakpoints.down('md')]: {
                    minHeight: theme.space(7),
                },
            },
        },
        mobileTabLine: {
            display: 'none',
            '&.bottomOnMobile': {
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    height: '2px',
                    backgroundColor: theme.palette.neutral[200],
                    position: 'absolute',
                    display: 'block',
                },
            },
        },
        desktopTabLine: {
            '&.bottomOnMobile': {
                [theme.breakpoints.down('md')]: {
                    display: 'none',
                },
            },
            '&.topOnMobile': {
                [theme.breakpoints.down('md')]: {
                    zIndex: 1002,
                    position: 'fixed',
                    top: 123,
                    right: 0,
                    left: 0,
                    height: 2,
                },
            },
            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.palette.neutral[200],
                transform: 'translate(0, -1px)',
                margin: theme.space(0, -12),
            },
        },
        tab: {
            lineHeight: theme.typography.pxToRem(28),
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 400,
            textTransform: 'none',
            color: theme.palette.neutral[700],
            opacity: 1,
            padding: theme.space(2, 2),
            '&.bottomOnMobile': {
                [theme.breakpoints.down('md')]: {
                    flexGrow: 1,
                    flexBasis: 0,
                },
            },
            '&.topOnMobile': {
                maxWidth: 'fit-content',
                [theme.breakpoints.down('md')]: {
                    lineHeight: theme.typography.pxToRem(15),
                    fontSize: theme.typography.pxToRem(10),
                    fontWeight: 500,
                    minHeight: theme.space(7),
                    padding: theme.space(2, 3),
                },
            },
        },
        customTabIndicator: {
            background: theme.palette.secondary[600],
            borderRadius: theme.space(2),
            zIndex: 2,
            '&.bottomOnMobile': {
                [theme.breakpoints.down('md')]: {
                    top: 0,
                },
            },
        },
    }),
)

type TabPanelProps = {
    selectedTabIndex: number
    currentTabIndex: number
    children: any
}

export const TabPanel: React.FC<TabPanelProps> = ({ selectedTabIndex, currentTabIndex, children }) => (
    <div hidden={selectedTabIndex !== currentTabIndex}>{selectedTabIndex === currentTabIndex && children}</div>
)

type TabSystemProps = {
    tabLabels: { label: string; value: string }[]
    onClick?: (tabNumber: number) => void
    children: any[]
    bottomOnMobile?: boolean
    initialTab?: number
}

const TabSystem: React.FC<TabSystemProps> = ({
    tabLabels,
    children,
    onClick,
    bottomOnMobile = false,
    initialTab = 0,
}) => {
    const classes = useStyles()

    const [currentTab, setCurrentTab] = useState<number>(initialTab)

    function handleTabChange(_e: any, tab: number) {
        setCurrentTab(tab)
        if (onClick) onClick(tab)
    }

    function addMobilePositionClass(className: string) {
        return `${className} ${bottomOnMobile ? 'bottomOnMobile' : 'topOnMobile'}`
    }

    const renderScrollButton = (props: TabScrollButtonProps) => {
        if (props.direction === 'left') {
            return (
                <TabScrollButton {...props}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </TabScrollButton>
            )
        } else if (props.direction === 'right') {
            return (
                <TabScrollButton {...props}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </TabScrollButton>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <div className={addMobilePositionClass(classes.tabSystem)}>
                <Divider className={addMobilePositionClass(classes.mobileTabLine)} />
                <Tabs
                    variant="scrollable"
                    className={addMobilePositionClass(classes.tabContainer)}
                    value={currentTab}
                    onChange={handleTabChange}
                    scrollButtons="on"
                    ScrollButtonComponent={(props) => renderScrollButton(props)}
                    classes={{
                        indicator: addMobilePositionClass(classes.customTabIndicator),
                    }}
                >
                    {tabLabels.map(({ label, value }, index) => (
                        <Tab
                            key={index}
                            className={addMobilePositionClass(classes.tab)}
                            label={label}
                            data-testid={value}
                            disableRipple
                        />
                    ))}
                </Tabs>
            </div>
            <Divider className={addMobilePositionClass(classes.desktopTabLine)} />
            {children!.map((tabContent, index) => (
                <TabPanel key={index} selectedTabIndex={currentTab} currentTabIndex={index}>
                    {tabContent}
                </TabPanel>
            ))}
        </>
    )
}

export default TabSystem
