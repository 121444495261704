import * as React from 'react'
import { useState } from 'react'
import Page from '_organisms/Page'
import { useTranslation } from 'react-i18next'
import { PaginationInfos, SearchFieldDefaultFilterKey } from 'interfaces/Table.interfaces'
import rmsApi from 'utils/api'
import {
    UNIQUE_PRODUCT_UPDATE_URL,
    UNIQUE_PRODUCTS_PATCH_STATUS_URL,
    UNIQUE_PRODUCTS_URL,
    WMS_UNIQUE_PRODUCT_LIST_URL,
    WMS_UNIQUE_PRODUCT_LIST_URL_TO_CSV,
} from 'utils/routes/backend'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import dayjs from 'dayjs'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'
import { StoreType } from 'interfaces/Customer'
import fileDownload from 'js-file-download'
import {
    forbiddenStatusByCurrentUniqueProductStatus,
    UniqueProductStatus,
    UniqueProductStatusDisplay,
    uniqueProductStatusResponsabilityLevel,
} from 'interfaces'
import MobileSearchFilter from '_organisms/tableV2/filters/MobileSearchFilter'
import CheckboxListFilter from '_organisms/tableV2/filters/CheckboxListFilter'
import SquaredImage, { SquaredImageSize } from '_atoms/images/SquaredImage'
import SetQualityModal from '_organisms/uniqueProduct/SetQualityModal'
import RefreshPUIDModal from '_organisms/uniqueProduct/RefreshPUIDModal'
import Status from '_atoms/badges/Status'
import TableV2, { SortType } from '_organisms/tableV2/TableV2'
import { toast } from 'utils/toast'
import { PrismicQuality } from 'utils/prismic'
import { useNavigate } from 'react-router-dom'
import { MANAGE_UNIQUE_PRODUCT } from 'utils/routes/frontend'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import UpdateStatusModal from '_organisms/UpdateStatusModal'
import DeleteModal from '_organisms/DeleteModal'
import { returnPluralPath } from 'utils/stringConvertion'
import UpdateEanOrSkuModal from '_organisms/UpdateEanOrSkuModal'

interface TableRowUniqueProduct {
    uniqueProductId: number
    uniqueProductStatus: UniqueProductStatus
    isSecondHand: boolean
    image: JSX.Element
    barcodeUid: string
    productName: string
    sku: string
    eans: string
    storageLocation: string
    status: JSX.Element
    quality: string
    customerName: string
    createdAt: string
    updatedAt: string
    rotations: number
    rentedDays: number
}

const UniqueProductTable: React.FC = () => {
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()
    const navigate = useNavigate()

    const loadPrismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.loadPrismicQualities)
    const selectedCustomers = useSelectedCustomersStore((state: SelectedCustomersState) => state.selectedCustomers)

    const [rawProductList, setRawProductList] = useState<any[]>([])
    const [productList, setProductList] = useState<TableRowUniqueProduct[]>([])
    const [prismicQualitiesByCustomer, setPrismicQualitiesByCustomer] = useState<Record<string, PrismicQuality[]>>({})
    const [statusesModal, setStatusesModal] = useState<JSX.Element>()
    const [qualitiesModal, setQualitiesModal] = useState<JSX.Element>()
    const [barcodeUidModal, setBarcodeUidModal] = useState<JSX.Element>()
    const [deleteBarcodeUidModal, setDeleteBarcodeUidModal] = useState<JSX.Element>()
    const [updateEanOrSkuModal, setUpdateEanOrSkuModal] = useState<JSX.Element>()

    const uniqueProductStatusOptions = Object.keys(UniqueProductStatus).map((status) => ({
        value: status,
        label: t(`uniqueProductStatus.${status}`),
    }))

    async function buildRows(products: any) {
        const rows: TableRowUniqueProduct[] = []
        const qualitiesByCustomer = { ...prismicQualitiesByCustomer }

        for (const product of products) {
            const {
                productReference_name: name,
                productReference_size: size,
                productReference_sku: sku,
                uniqueProduct_barcode_uid: barcodeUid,
                uniqueProduct_storage_location: storageLocation,
                customer_name: customerName,
                eansList: eans,
                rotations,
            } = product

            const productName = size ? `${name} - ${size}` : name
            const createdAt = dayjs(product.uniqueProduct_created_at.toLocaleString().substr(0, 10)).format('DD/MM/YY')
            const updatedAt = dayjs(product.uniqueProduct_updated_at.toLocaleString().substr(0, 10)).format('DD/MM/YY')

            let quality = '-'
            if (product.uniqueProduct_is_second_hand) {
                if (!Object.keys(qualitiesByCustomer).includes(customerName)) {
                    const prismicQualities = await loadPrismicQualities(product.customer_prismic_template)
                    qualitiesByCustomer[customerName] = prismicQualities
                }
                const prismicQuality = qualitiesByCustomer[customerName]?.find(
                    (prismicQuality) => prismicQuality.value === product.uniqueProduct_quality,
                )
                quality = prismicQuality?.label || t(`secondHand.qualities.${product.uniqueProduct_quality}`)
            }

            const rentedDays =
                (product.customer_store_type === StoreType.DATE_TO_DATE
                    ? product.dad_rented_days
                    : product.sub_rented_days) || 0

            const status = (
                <Status
                    key={product.uniqueProduct_barcode_uid}
                    label={t(`uniqueProductStatus.${product.uniqueProduct_status}`)}
                    color={UniqueProductStatusDisplay[product.uniqueProduct_status as UniqueProductStatus]}
                />
            )

            const uniqueProductId = product.uniqueProduct_id
            const uniqueProductStatus = product.uniqueProduct_status
            const isSecondHand = product.uniqueProduct_is_second_hand
            const image = <SquaredImage src={product.productReference_image} size={SquaredImageSize.XXXS} />

            rows.push({
                uniqueProductId,
                uniqueProductStatus,
                isSecondHand,
                image,
                barcodeUid,
                productName,
                sku,
                eans,
                storageLocation,
                status,
                quality,
                customerName,
                createdAt,
                updatedAt,
                rotations,
                rentedDays,
            })
        }

        setProductList(rows)
        setPrismicQualitiesByCustomer(qualitiesByCustomer)
    }

    function buildSearchParams(filters: any, sort?: SortType, selectedBarcodeUids?: string[]) {
        const search = filters.searchField ? `&search=${filters.searchField}` : ''

        const buildCustomersParams = (name: string) =>
            `&customerIds[]=${selectedCustomers.find((customer) => customer.name === name)?.id}`
        const customers = filters.customers?.map(buildCustomersParams).join('') || ''

        const buildStatusesParams = (label: string) =>
            `&statuses[]=${uniqueProductStatusOptions.find((status) => status.label === label)?.value}`
        const statuses = filters.statuses?.map(buildStatusesParams).join('') || ''

        const sortBy = sort ? `&sortBy=${sort.column}` : ''
        const sortOrder = sort ? `&sortOrder=${sort.ascending ? 'ASC' : 'DESC'}` : ''

        const barcodeUids = selectedBarcodeUids
            ? selectedBarcodeUids.map((barcodeUid) => `&barcodeUids[]=${barcodeUid}`).join('')
            : ''

        return `${search}${customers}${statuses}${barcodeUids}${sortBy}${sortOrder}`
    }

    async function fetchProductList(
        filters: any,
        currentPage: number,
        itemsPerPage: number,
        setPagination: (p: PaginationInfos) => void,
        sort?: SortType,
    ): Promise<void> {
        const params = buildSearchParams(filters, sort)

        try {
            const request = await rmsApi.get(
                `${WMS_UNIQUE_PRODUCT_LIST_URL}?page=${currentPage}&limit=${itemsPerPage}${params}`,
            )
            const { items, meta } = request.data
            const products = items.filter((product: any) => product.productReference_name)

            if (meta) setPagination(meta)
            await buildRows(products)
            setRawProductList(products)
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function uniqueProductListToCsv(filters: any, barcodeUids?: string[]): Promise<void> {
        try {
            const filtered = filters?.searchField || filters?.statuses || filters?.customers || barcodeUids
            const params = filtered ? `?${buildSearchParams(filters, undefined, barcodeUids)}` : ''
            const uniqueProductListCsv = await rmsApi.get(`${WMS_UNIQUE_PRODUCT_LIST_URL_TO_CSV}${params}`, {
                responseType: 'blob',
            })

            const downloadDate = dayjs().format('YYYY-MM-DD-HH-mm-ss')
            fileDownload(uniqueProductListCsv.data, `unique-products${filtered ? '-filtered' : ''}-${downloadDate}.csv`)
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function updateUniqueProductStatus(barcodeUid: string, status: UniqueProductStatus): Promise<void> {
        try {
            await rmsApi.patch(UNIQUE_PRODUCTS_PATCH_STATUS_URL(barcodeUid), { newProductStatus: status })
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function updateUniqueProductQuality(barcodeUid: string, quality: string): Promise<void> {
        try {
            const id = rawProductList.find((product) => product.uniqueProduct_barcode_uid === barcodeUid)
                .uniqueProduct_id
            await rmsApi.patch(UNIQUE_PRODUCT_UPDATE_URL(id), { quality })
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function updateUniqueProductsStatus(uniqueProductIds: number[], status: UniqueProductStatus): Promise<void> {
        try {
            await rmsApi.patch(UNIQUE_PRODUCTS_URL, { uniqueProductIds, status })
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function updateUniqueProductsQuality(uniqueProductIds: number[], quality: string): Promise<void> {
        try {
            await rmsApi.patch(UNIQUE_PRODUCTS_URL, { uniqueProductIds, quality })
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function deletePuid(uniqueProductIds: number[], barcodeUids: string[]) {
        const paramsForSuccessOrError =
            barcodeUids.length === 1 ? { barcodeUid: barcodeUids[0] } : { barcodeUids: barcodeUids.join(', ') }

        try {
            await rmsApi.delete(UNIQUE_PRODUCTS_URL, { data: { uniqueProductIds } })
            toast.success(
                returnPluralPath(
                    t,
                    'wms.uniqueProducts.deleteBarcodeUidModal.success',
                    barcodeUids.length,
                    false,
                    paramsForSuccessOrError,
                ),
            )
        } catch (e: any) {
            toast.error(buildErrorMessage(e, paramsForSuccessOrError))
        }
    }

    const defaultAction = (filters: any) => {
        return {
            label: t('wms.uniqueProducts.availableActions.allToCSV'),
            action: () => uniqueProductListToCsv(filters),
        }
    }

    function buildUniqueProductStatusOptions(initialStatuses: UniqueProductStatus[]) {
        const forbiddenStatuses = initialStatuses.flatMap(
            (status) => forbiddenStatusByCurrentUniqueProductStatus[status],
        )
        const levelStatuses = uniqueProductStatusResponsabilityLevel['high']
        const authorizedStatuses =
            forbiddenStatuses.length > 0
                ? levelStatuses.filter((status: UniqueProductStatus) => ![...forbiddenStatuses].includes(status))
                : levelStatuses

        return authorizedStatuses.map((status) => ({
            value: status,
            label: t(`uniqueProductStatus.${status}`),
        }))
    }

    function batchActions(
        filters: any,
        selectedRows: { index: number; row: TableRowUniqueProduct }[],
        fetchData: () => void,
    ) {
        const selectedStatuses = selectedRows.map((selectedRow) => selectedRow.row.uniqueProductStatus)
        const selectedIds = selectedRows.map((selectedRow) => selectedRow.row.uniqueProductId)
        const selectedBarcodeUids = selectedRows.map((selectedRow) => selectedRow.row.barcodeUid)

        const actions = [
            {
                label: t('wms.uniqueProducts.availableActions.selectedToCSV'),
                action: () =>
                    uniqueProductListToCsv(
                        filters,
                        selectedRows.map((s) => s.row.barcodeUid),
                    ),
            },
            {
                label: t('wms.uniqueProducts.availableActions.updateStatus'),
                action: () =>
                    setStatusesModal(
                        <UpdateStatusModal
                            isOpen
                            initialStatuses={selectedStatuses}
                            handleClose={() => setStatusesModal(undefined)}
                            updateStatus={async (status) => {
                                await updateUniqueProductsStatus(selectedIds, status as UniqueProductStatus)
                                await fetchData()
                            }}
                            statusOptions={buildUniqueProductStatusOptions(selectedStatuses)}
                        />,
                    ),
            },
            {
                label: returnPluralPath(
                    t,
                    'wms.uniqueProducts.availableActions.deleteBarcodeUid',
                    selectedBarcodeUids.length,
                    false,
                ),
                action: () =>
                    setDeleteBarcodeUidModal(
                        <DeleteModal
                            isOpen
                            onSubmit={async () => {
                                await deletePuid(selectedIds, selectedBarcodeUids)
                                setDeleteBarcodeUidModal(undefined)
                                await fetchData()
                            }}
                            onClose={() => setDeleteBarcodeUidModal(undefined)}
                            title={returnPluralPath(
                                t,
                                'wms.uniqueProducts.deleteBarcodeUidModal.title',
                                selectedBarcodeUids.length,
                                false,
                            )}
                            label={returnPluralPath(
                                t,
                                'wms.uniqueProducts.deleteBarcodeUidModal.label',
                                selectedBarcodeUids.length,
                                false,
                            )}
                            inputValue={selectedBarcodeUids.join(', ')}
                            buttonLabel={t('wms.uniqueProducts.deleteBarcodeUidModal.buttonLabel')}
                        />,
                    ),
            },
        ]

        const customers = [...new Set(selectedRows.map((selected) => selected.row.customerName))]
        const selectedIsSecondHand = selectedRows.every((selectedRow) => selectedRow.row.isSecondHand)

        if (customers.length === 1 && selectedIsSecondHand) {
            actions.push({
                label: t('wms.uniqueProducts.availableActions.updateQuality'),
                action: () =>
                    setQualitiesModal(
                        <SetQualityModal
                            isOpen
                            prismicQualities={prismicQualitiesByCustomer[customers[0]]}
                            handleClose={() => setQualitiesModal(undefined)}
                            handleSetQuality={async (quality: string) => {
                                await updateUniqueProductsQuality(selectedIds, quality)
                                await fetchData()
                            }}
                            applyButton={true}
                        />,
                    ),
            })
        }

        return actions
    }

    function rowActions(row: TableRowUniqueProduct, index: number, fetchData: () => void) {
        const data = rawProductList[index]
        const { uniqueProduct_status, uniqueProduct_quality, uniqueProduct_is_second_hand, uniqueProduct_id } = data
        const initialStatuses = [uniqueProduct_status]

        const actions = [
            {
                label: t('wms.uniqueProducts.availableActions.updateStatus'),
                action: () =>
                    setStatusesModal(
                        <UpdateStatusModal
                            isOpen
                            initialStatuses={[uniqueProduct_status]}
                            handleClose={() => setStatusesModal(undefined)}
                            updateStatus={async (status) => {
                                await updateUniqueProductStatus(row.barcodeUid, status as UniqueProductStatus)
                                await fetchData()
                            }}
                            statusOptions={buildUniqueProductStatusOptions(initialStatuses)}
                        />,
                    ),
            },
            {
                label: t('wms.uniqueProducts.availableActions.updateBarcodeUid'),
                action: () =>
                    setBarcodeUidModal(
                        <RefreshPUIDModal
                            isOpen
                            initialPUID={row.barcodeUid}
                            handleClose={() => setBarcodeUidModal(undefined)}
                            handleSuccess={fetchData}
                        />,
                    ),
            },
            {
                label: t('wms.uniqueProducts.availableActions.updateEanSku'),
                action: () =>
                    setUpdateEanOrSkuModal(
                        <UpdateEanOrSkuModal
                            initialUniqueProduct={{
                                id: row.uniqueProductId,
                                puid: row.barcodeUid,
                                initialSku: row.sku,
                                initialEan: row.eans,
                            }}
                            title={t('wms.uniqueProducts.updateEanSkuModal.title', { EAN: row.eans, SKU: row.sku })}
                            tagline={t('wms.uniqueProducts.updateEanSkuModal.tagline')}
                            handleClose={() => setUpdateEanOrSkuModal(undefined)}
                            handleSuccess={fetchData}
                        />,
                    ),
            },
            {
                label: t('wms.uniqueProducts.availableActions.deleteBarcodeUid.singular'),
                action: () =>
                    setDeleteBarcodeUidModal(
                        <DeleteModal
                            isOpen
                            onSubmit={async () => {
                                await deletePuid([row.uniqueProductId], [row.barcodeUid])
                                setDeleteBarcodeUidModal(undefined)
                                await fetchData()
                            }}
                            onClose={() => setDeleteBarcodeUidModal(undefined)}
                            title={t('wms.uniqueProducts.deleteBarcodeUidModal.title.singular')}
                            label={t('wms.uniqueProducts.deleteBarcodeUidModal.label.singular')}
                            inputValue={row.barcodeUid}
                            buttonLabel={t('wms.uniqueProducts.deleteBarcodeUidModal.buttonLabel')}
                        />,
                    ),
            },
        ]

        if (uniqueProduct_is_second_hand) {
            const { customerName, barcodeUid } = row

            actions.push(
                {
                    label: t('wms.uniqueProducts.availableActions.updateQuality'),
                    action: () =>
                        setQualitiesModal(
                            <SetQualityModal
                                isOpen
                                prismicQualities={prismicQualitiesByCustomer[customerName]}
                                handleClose={() => setQualitiesModal(undefined)}
                                handleSetQuality={async (quality: string) => {
                                    await updateUniqueProductQuality(barcodeUid, quality)
                                    await fetchData()
                                }}
                                puidQuality={uniqueProduct_quality}
                                applyButton={true}
                            />,
                        ),
                },
                {
                    label: t('wms.uniqueProducts.availableActions.managePhotos'),
                    action: () => {
                        navigate(MANAGE_UNIQUE_PRODUCT(uniqueProduct_id))
                    },
                },
            )
        }

        return actions
    }

    const header = [
        { value: 'image', label: 'wms.uniqueProducts.columnImage' },
        { value: 'barcodeUid', label: 'wms.uniqueProducts.barcode' },
        { value: 'productName', label: 'wms.uniqueProducts.columnProductReference' },
        { value: 'sku', label: 'wms.uniqueProducts.columnSku' },
        { value: 'eans', label: 'wms.uniqueProducts.columnEans' },
        { value: 'storageLocation', label: 'wms.uniqueProducts.storageLocation' },
        { value: 'status', label: 'wms.uniqueProducts.columnStatus' },
        { value: 'quality', label: 'wms.uniqueProducts.columnCondition' },
        { value: 'customerName', label: 'wms.uniqueProducts.columnCustomerName' },
        { value: 'createdAt', label: 'wms.uniqueProducts.columnCreatedAt', sortable: true, sortValue: 'createdAt' },
        { value: 'updatedAt', label: 'wms.uniqueProducts.columnUpdatedAt', sortable: true, sortValue: 'updatedAt' },
        { value: 'rotations', label: 'wms.uniqueProducts.columnRotations' },
        { value: 'rentedDays', label: 'wms.uniqueProducts.columnRentedDays' },
    ]

    const filterOptions = (filters: any) => [
        {
            filterKey: SearchFieldDefaultFilterKey,
            label: t('table.searchField'),
            component: <MobileSearchFilter placeholder={t('wms.productReferences.searchFieldPlaceholder')} />,
        },
        {
            filterKey: 'customers',
            label: t('wms.uniqueProducts.filters.customers'),
            component: (
                <CheckboxListFilter
                    options={selectedCustomers.map((customer) => customer.name!) || []}
                    filteredOptions={filters?.customers || []}
                />
            ),
        },
        {
            filterKey: 'statuses',
            label: t('wms.uniqueProducts.filters.statuses'),
            component: (
                <CheckboxListFilter
                    options={uniqueProductStatusOptions.map((status) => status.label) || []}
                    filteredOptions={filters?.statuses || []}
                />
            ),
        },
    ]

    return (
        <Page title={t('menu.stock.uniqueProducts')} section={t('menu.stock.title')} contentPaddingTopDesktop={2}>
            <TableV2
                fetchRows={fetchProductList}
                initialHeader={header}
                rows={productList}
                filterOptions={filterOptions}
                defaultAction={defaultAction}
                batchActions={batchActions}
                rowActions={rowActions}
                searchFieldPlaceholder={t('wms.uniqueProducts.filters.searchFieldPlaceholder')}
                localStorageName="uniqueProductTable"
            />
            {statusesModal}
            {qualitiesModal}
            {barcodeUidModal}
            {deleteBarcodeUidModal}
            {updateEanOrSkuModal}
        </Page>
    )
}

export default UniqueProductTable
