import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Page from '_organisms/Page'
import TableV2, { SortType } from '_organisms/tableV2/TableV2'
import { useTranslation } from 'react-i18next'
import { PaginationInfos, SearchFieldDefaultFilterKey } from 'interfaces/Table.interfaces'
import { toast } from 'utils/toast'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { ParcelStatus, ParcelStatusDisplay } from 'interfaces'
import {
    PARCEL_BY_ID_URL,
    PARCELS_BY_FILTERS_TO_CSV_URL,
    PARCELS_BY_FILTERS_URL,
    PARCELS_URL,
} from 'utils/routes/backend'
import rmsApi from 'utils/api'
import Status from '_atoms/badges/Status'
import dayjs from 'dayjs'
import { BasicText } from '_atoms/text/BasicText'
import Pill from '_atoms/badges/Pill'
import { RETURNED_PARCEL_DETAILS_ROUTE } from 'utils/routes/frontend'
import MobileSearchFilter from '_organisms/tableV2/filters/MobileSearchFilter'
import CheckboxListFilter from '_organisms/tableV2/filters/CheckboxListFilter'
import UpdateStatusModal from '_organisms/UpdateStatusModal'
import fileDownload from 'js-file-download'
import DatePickerFilter from '_organisms/tableV2/filters/DatePickerFilter'
import RadioListFilter from '_organisms/tableV2/filters/RadioListFilter'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overdue: {
            color: theme.palette.common.black,
        },
        overdueWarning: {
            color: theme.palette.warning.main,
        },
        overdueError: {
            color: theme.palette.error.main,
        },
    }),
)

interface TableRowReturnParcel {
    parcelId: number
    parcelStatus: string
    hasIssues: JSX.Element
    tracking: JSX.Element
    omsOrderId: string
    renterEmail: string
    daysLate?: JSX.Element
    shippingDate: string
    shippedAt?: string
    type: string
    customerName: string
    status: JSX.Element
}

const defaultStartDate = dayjs().startOf('year')
const defaultEndDate = dayjs().add(15, 'day')

const ReturnParcelTable: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()
    const selectedCustomers = useSelectedCustomersStore((state: SelectedCustomersState) => state.selectedCustomers)

    const [rawParcelList, setRawParcelList] = useState<any[]>([])
    const [parcelList, setParcelList] = useState<TableRowReturnParcel[]>([])
    const [statusesModal, setStatusesModal] = useState<JSX.Element>()

    const parcelStatusOptions = Object.keys(ParcelStatus).map((status) => ({
        value: status,
        label: t(`parcelStatus.${status}`),
    }))

    const hasIssuesOptions = [
        { value: 1, label: t('returns.filters.hasIssues.yes') },
        { value: 0, label: t('returns.filters.hasIssues.no') },
    ]

    function buildRows(rawParcels: any[]) {
        const rows: TableRowReturnParcel[] = []

        for (const rawParcel of rawParcels) {
            const {
                parcel_status,
                parcel_shipped_at,
                shipment_shipping_date,
                shipment_right_of_withdrawal,
                days_late,
            } = rawParcel

            const renterEmail = rawParcel.renter_email
            const customerName = rawParcel.customer_name
            const omsOrderId = rawParcel.order_oms_order_id
            const type = shipment_right_of_withdrawal ? t('returns.withdrawalReturnLabel') : '-'

            const hasIssues = (
                <Pill
                    id={parcel_status}
                    label={t(rawParcel.parcel_has_issues ? 'returns.issues' : 'returns.noIssue').toUpperCase()}
                    color={rawParcel.parcel_has_issues ? 'warning' : 'neutral'}
                    isSmall
                />
            )

            const tracking = (
                <a target="_blank" rel="noopener noreferrer" href={rawParcel.parcel_tracking_link}>
                    {rawParcel.parcel_tracking_code}
                </a>
            )

            const status = (
                <Status
                    label={t(`parcelStatus.${parcel_status}`)}
                    color={ParcelStatusDisplay[parcel_status as ParcelStatus]}
                />
            )

            const daysLate =
                days_late > 0 ? (
                    <BasicText
                        className={`${classes.overdue} ${days_late > 0 ? classes.overdueWarning : ''} ${
                            days_late > 3 ? classes.overdueError : ''
                        }`}
                    >
                        {`${days_late} ${t('returns.rentalDates.days')}`}
                    </BasicText>
                ) : undefined

            const shippingDate = dayjs(shipment_shipping_date.toLocaleString()).format('DD/MM/YY')
            const shippedAt = parcel_shipped_at && dayjs(parcel_shipped_at.toLocaleString()).format('DD/MM/YY')
            const parcelId = rawParcel.parcel_id
            const parcelStatus = parcel_status

            rows.push({
                parcelId,
                parcelStatus,
                hasIssues,
                tracking,
                omsOrderId,
                renterEmail,
                daysLate,
                shippingDate,
                shippedAt,
                type,
                customerName,
                status,
            })
        }

        setParcelList(rows)
    }

    function buildSearchParams(filters: any, sort?: SortType, selectedParcelIds?: number[]) {
        const search = filters.searchField ? `&search=${filters.searchField}` : ''
        const isReturn = '&isReturn=1'
        const from = `&from=${(filters.dateRange?.startDate || defaultStartDate).format('YYYY-MM-DD')}`
        const to = `&to=${(filters.dateRange?.endDate || defaultEndDate).format('YYYY-MM-DD')}`

        const hasIssues =
            filters.hasIssues !== undefined
                ? `&hasIssues=${hasIssuesOptions.find((option) => option.label === filters.hasIssues)?.value}`
                : ''

        const buildCustomersParams = (name: string) =>
            `&customerIds[]=${selectedCustomers.find((customer) => customer.name === name)?.id}`
        const customers = filters.customers?.map(buildCustomersParams).join('') || ''

        const buildStatusesParams = (label: string) =>
            `&statuses[]=${parcelStatusOptions.find((status) => status.label === label)?.value}`
        const statuses = filters.statuses?.map(buildStatusesParams).join('') || ''

        const sortBy = sort ? `&sortBy=${sort.column}` : ''
        const sortOrder = sort ? `&sortOrder=${sort.ascending ? 'ASC' : 'DESC'}` : ''

        const parcelIds = selectedParcelIds ? selectedParcelIds.map((id) => `&parcelIds[]=${id}`).join('') : ''

        return `${search}${isReturn}${hasIssues}${from}${to}${customers}${statuses}${parcelIds}${sortBy}${sortOrder}`
    }

    async function fetchParcelList(
        filters: any,
        currentPage: number,
        itemsPerPage: number,
        setPagination: (p: PaginationInfos) => void,
        sort?: SortType,
    ) {
        const params = buildSearchParams(filters, sort)

        try {
            const request = await rmsApi.get(
                `${PARCELS_BY_FILTERS_URL}?page=${currentPage}&limit=${itemsPerPage}${params}`,
            )
            const { items: parcels, meta } = request.data
            if (meta) setPagination(meta)
            buildRows(parcels)
            setRawParcelList(parcels)
        } catch (e) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function parcelListToCsv(filters: any, parcelIds?: number[]): Promise<void> {
        try {
            const filtered = filters?.searchField || filters?.statuses || filters?.customers || parcelIds
            const params = `?${buildSearchParams(filters, undefined, parcelIds)}`

            const uniqueProductListCsv = await rmsApi.get(`${PARCELS_BY_FILTERS_TO_CSV_URL}${params}`, {
                responseType: 'blob',
            })

            const downloadDate = dayjs().format('YYYY-MM-DD-HH-mm-ss')
            fileDownload(uniqueProductListCsv.data, `parcels${filtered ? '-filtered' : ''}-${downloadDate}.csv`)
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function updateParcelsStatus(parcelIds: number[], status: ParcelStatus): Promise<void> {
        try {
            await rmsApi.patch(PARCELS_URL, { parcelIds, status })
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function updateParcelStatus(parcelId: string, status: ParcelStatus): Promise<void> {
        try {
            await rmsApi.patch(PARCEL_BY_ID_URL(parcelId), { status })
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    const header = [
        { value: 'hasIssues', label: 'returns.issues' },
        { value: 'tracking', label: 'returns.columnTracking' },
        { value: 'omsOrderId', label: 'orders.columnOmsOrderId', sortable: true, sortValue: 'omsOrderId' },
        { value: 'renterEmail', label: 'shipments.columnRenterEmail' },
        { value: 'daysLate', label: 'returns.columnDaysLate' /*sortable: true, sortValue: 'daysLate' */ },
        { value: 'shippingDate', label: 'returns.columnShippingDate' },
        { value: 'shippedAt', label: 'returns.columnShippedAt' },
        { value: 'type', label: 'returns.columnType' },
        { value: 'customerName', label: 'returns.parcelInfos.customer' },
        { value: 'status', label: 'shipments.columnStatus' },
    ]

    function onRowClick(row: any, index: number) {
        const id = rawParcelList[index].parcel_id
        const parcelURL = `${window.location.origin}${RETURNED_PARCEL_DETAILS_ROUTE(id)}`
        window.open(parcelURL, '_blank')
    }

    const filterOptions = (filters: any) => [
        {
            filterKey: SearchFieldDefaultFilterKey,
            label: t('table.searchField'),
            component: <MobileSearchFilter placeholder={t('returns.filters.searchFieldPlaceholder')} />,
        },
        {
            filterKey: 'dateRange',
            label: t('returns.filters.dateRange'),
            component: (
                <DatePickerFilter
                    startDate={filters?.dateRange?.startDate || defaultStartDate}
                    endDate={filters?.dateRange?.endDate || defaultEndDate}
                    range
                />
            ),
        },
        {
            filterKey: 'customers',
            label: t('returns.filters.customers'),
            component: (
                <CheckboxListFilter
                    options={selectedCustomers.map((customer) => customer.name!) || []}
                    filteredOptions={filters?.customers || []}
                />
            ),
        },
        {
            filterKey: 'statuses',
            label: t('returns.filters.statuses'),
            component: (
                <CheckboxListFilter
                    options={parcelStatusOptions.map((status) => status.label) || []}
                    filteredOptions={filters?.statuses || []}
                />
            ),
        },
        {
            filterKey: 'hasIssues',
            label: t('returns.filters.hasIssues.title'),
            component: (
                <RadioListFilter
                    options={hasIssuesOptions}
                    selected={hasIssuesOptions.find((option) => option.label === filters.hasIssues)?.value}
                />
            ),
        },
    ]

    function defaultAction(filters: any) {
        return {
            label: t('wms.productReferences.availableActions.allToCSV'),
            action: () => parcelListToCsv(filters),
        }
    }

    function batchActions(
        filters: any,
        selectedRows: { index: number; row: TableRowReturnParcel }[],
        fetchData: () => void,
    ) {
        const parcelIds = selectedRows.map((selectedRow) => selectedRow.row.parcelId)
        const parcelStatuses = selectedRows.map((selectedRow) => selectedRow.row.parcelStatus)

        return [
            {
                label: t('wms.productReferences.availableActions.selectedToCSV'),
                action: () => parcelListToCsv(filters, parcelIds),
            },
            {
                label: t('wms.uniqueProducts.availableActions.updateStatus'),
                action: () =>
                    setStatusesModal(
                        <UpdateStatusModal
                            isOpen
                            initialStatuses={parcelStatuses}
                            handleClose={() => setStatusesModal(undefined)}
                            updateStatus={async (status) => {
                                await updateParcelsStatus(parcelIds, status as ParcelStatus)
                                await fetchData()
                            }}
                            statusOptions={parcelStatusOptions}
                        />,
                    ),
            },
        ]
    }

    function rowActions(row: TableRowReturnParcel, index: number, fetchData: () => void) {
        const data = rawParcelList[index]
        const { parcel_id, parcel_status } = data
        const initialStatuses = [parcel_status]

        return [
            {
                label: t('wms.uniqueProducts.availableActions.updateStatus'),
                action: () =>
                    setStatusesModal(
                        <UpdateStatusModal
                            isOpen
                            initialStatuses={initialStatuses}
                            handleClose={() => setStatusesModal(undefined)}
                            updateStatus={async (status) => {
                                await updateParcelStatus(parcel_id, status as ParcelStatus)
                                await fetchData()
                            }}
                            statusOptions={parcelStatusOptions}
                        />,
                    ),
            },
        ]
    }

    const defaultFilters = { dateRange: { startDate: defaultStartDate, endDate: defaultEndDate } }

    return (
        <Page title={t('menu.returns.returnList')} section={t('menu.returns.title')} contentPaddingTopDesktop={2}>
            <TableV2
                fetchRows={fetchParcelList}
                initialHeader={header}
                rows={parcelList}
                searchFieldPlaceholder={t('returns.filters.searchFieldPlaceholder')}
                onRowClick={onRowClick}
                filterOptions={filterOptions}
                defaultAction={defaultAction}
                batchActions={batchActions}
                rowActions={rowActions}
                defaultFilters={defaultFilters}
                localStorageName="returnParcelTable"
            />
            {statusesModal}
        </Page>
    )
}

export default ReturnParcelTable
