import * as React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { createStyles, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import WhiteAndBluePartsCard from '_molecules/WhiteAndBluePartsCard'
import Text from '_atoms/text/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/pro-solid-svg-icons'
import { PICKPACK_ISSUES_LIST_ROUTE } from 'utils/routes/frontend'
import { ORDERS_WITH_PICK_PACK_ISSUES_COUNT_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.space(1),
            marginTop: theme.space(6),
        },
        header: {
            display: 'flex',
            gap: `${theme.space(3)}px`,
            alignItems: 'center',
            color: theme.palette.error[600],
            '& svg': {
                width: theme.space(3),
                height: theme.space(3),
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'center',
            },
        },
        content: {
            color: theme.palette.neutral[800],
            display: 'flex',
            alignItems: 'center',
        },
    }),
)

const WarningIssueCard: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const navigate = useNavigate()

    const [issuesCount, setIssuesCount] = useState<number>(0)

    async function fetchIssuesCount() {
        try {
            const issuesCountRequest = await rmsApi.get(ORDERS_WITH_PICK_PACK_ISSUES_COUNT_URL)
            setIssuesCount(issuesCountRequest.data)
        } catch (e) {
            toast.error(t('new_orders.errors.fetchIssuesCountError'))
        }
    }

    useEffect(() => {
        fetchIssuesCount()
    }, [])

    function onClick() {
        navigate(PICKPACK_ISSUES_LIST_ROUTE)
    }

    if (issuesCount === 0) {
        return <></>
    }

    return (
        <WhiteAndBluePartsCard
            onClick={onClick}
            className={classes.root}
            dataTestId="warningIssueCard"
            whiteClass={classes.header}
            blueClass={classes.content}
        >
            <>
                <FontAwesomeIcon icon={faWarning} />
                <Text variant="S2">{t('new_orders.pickPack.warningIssueCard')}</Text>
            </>
            {!isMobile && (
                <Text variant="P5" className={classes.content}>{`${issuesCount} ${t(
                    'new_orders.pickPack.warningIssueCard',
                ).toLowerCase()}`}</Text>
            )}
        </WhiteAndBluePartsCard>
    )
}

export default WarningIssueCard
