import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import Text from '_atoms/text/Text'

const useStyles = makeStyles((theme) =>
    createStyles({
        group: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.neutral[400],
            },
            '& .Mui-checked .MuiSvgIcon-root': {
                color: theme.palette.primary[600],
            },
            '& .MuiButtonBase-root': {
                padding: theme.space(1),
                marginRight: theme.space(1),
            },
            '& .MuiFormControlLabel-root': {
                marginLeft: theme.space(-1),
            },
        },
    }),
)

export interface RadioOption {
    value: any
    label: string
    disabled?: boolean
}

export interface RadioGroupProps {
    options: RadioOption[]
    value: any
    onChange: (a: any) => void
    name?: string
    dataTestId?: string
    className?: string
}

const CustomRadioGroup: React.FC<RadioGroupProps> = ({
    options,
    value,
    onChange,
    dataTestId,
    name,
    className,
    ...props
}) => {
    const classes = useStyles()

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange(event.currentTarget.value)
    }

    return (
        <RadioGroup
            name={name}
            value={value}
            onChange={handleChange}
            data-testid={dataTestId}
            className={`${classes.group} ${className}`}
            {...props}
        >
            {options.map((option) => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio size="small" color="primary" />}
                    label={<Text variant="P5">{option.label}</Text>}
                    disabled={option.disabled}
                />
            ))}
        </RadioGroup>
    )
}

export default CustomRadioGroup
