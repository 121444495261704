import * as React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, useMediaQuery } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-container': {
            '& .MuiPaper-root': {
                width: '100%',
                height: '100%',
                maxWidth: '100vw',
                maxHeight: '100vh',
            },
        },
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#ffffff',
    },
    dialogContent: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'hidden',
    },
    divider: {
        borderLeft: `1px solid ${theme.palette.grey[200]}`,
        marginRight: '15px',
    },
    quitButton: {
        marginLeft: theme.spacing(1),
        height: '30px',
        width: '30px',
    },
}))

type DialogCarouselProps = {
    title: string
    isOpen: boolean
    handleClose: () => void
    children: any
}

const DialogCarousel: React.FC<DialogCarouselProps> = ({ title, isOpen, handleClose, children }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    if (children?.length === 0) {
        handleClose()
    }
    return (
        <Dialog
            // aria-labelledby="customized-dialog-title"
            className={classes.dialog}
            open={isOpen}
            fullScreen={isMobile}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                },
            }}
            BackdropProps={{
                style: {
                    backgroundColor: '#000000',
                    opacity: '80%',
                    boxShadow: 'none',
                },
            }}
        >
            <Grid container className={classes.dialogHeader}>
                <DialogTitle>{title}</DialogTitle>
                <div className={classes.divider}>
                    <IconButton className={classes.quitButton} onClick={handleClose}>
                        <FontAwesomeIcon color="#ffffff" icon={faXmarkLarge} />
                    </IconButton>
                </div>
            </Grid>
            <DialogContent className={classes.dialogContent}>{children}</DialogContent>
        </Dialog>
    )
}

export default DialogCarousel
