import * as React from 'react'
import { useEffect, useState } from 'react'
import rmsApi from 'utils/api'
import { CUSTOMER_REFIT_INSTRUCTIONS_ROUTE, REFIT_GROUP_ROUTE, UNLINKED_PRODUCTS_ROUTE } from 'utils/routes/backend'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import { IInstructionGroup, IProductWithEans } from 'interfaces'
import HandleGroupsAndProducts from '_pages/instructionGroupsManagement/HandleGroupsAndProducts'
import HandleSingleGroup from '_pages/instructionGroupsManagement/HandleSingleGroup'
import { toast } from 'utils/toast'
import { useTranslation } from 'react-i18next'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'

const InstructionGroupsManagement: React.FC = () => {
    const { t } = useTranslation()

    const [selectedCustomer, setSelectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])

    const [instructionGroups, setInstructionGroups] = useState<IInstructionGroup[]>([])
    const [selectedInstructionGroup, setSelectedInstructionGroup] = useState<IInstructionGroup | undefined>()
    const [unlinkedProducts, setUnlinkedProducts] = useState<IProductWithEans[]>([])
    const [customerRefitInstructions, setCustomerRefitInstructions] = useState<any[]>([])

    useEffect(() => {
        if (selectedCustomers) setSelectedCustomer(selectedCustomers[0])
    }, [selectedCustomers])

    async function fetchGroups() {
        try {
            const refitGroupList = await rmsApi.get(`${REFIT_GROUP_ROUTE(selectedCustomer!.id)}`)
            setInstructionGroups(refitGroupList.data)
        } catch (e) {
            toast.error(t('refit.instructionGroups.errors.fetchRefitGroups'))
        }
    }

    async function fetchUnlinkedProducts() {
        try {
            const unlinkedProductResponse = await rmsApi.get(UNLINKED_PRODUCTS_ROUTE(selectedCustomer!.id))
            setUnlinkedProducts(unlinkedProductResponse.data)
        } catch (e) {
            toast.error(t('refit.instructionGroups.errors.fetchUnlinkedProducts'))
        }
    }

    async function fetchCustomerRefitInstructions() {
        try {
            const refitInstructionsResponse = await rmsApi.get(CUSTOMER_REFIT_INSTRUCTIONS_ROUTE(selectedCustomer!.id))
            setCustomerRefitInstructions(refitInstructionsResponse.data)
        } catch (e) {
            toast.error(t('refit.instructionGroups.errors.fetchRefitInstructions'))
        }
    }

    function fetchData() {
        fetchGroups()
        fetchUnlinkedProducts()
        fetchCustomerRefitInstructions()
    }

    useEffect(() => {
        if (selectedCustomer) {
            fetchData()
        }
    }, [selectedCustomer])

    return (
        <>
            {!selectedInstructionGroup ? (
                <HandleGroupsAndProducts
                    selectedCustomer={selectedCustomer}
                    groups={instructionGroups}
                    unlinkedProducts={unlinkedProducts}
                    selectInstructionGroup={setSelectedInstructionGroup}
                    refreshData={fetchData}
                />
            ) : (
                <HandleSingleGroup
                    customerRefitInstructions={customerRefitInstructions}
                    group={selectedInstructionGroup}
                    unSelectGroup={() => {
                        setSelectedInstructionGroup(undefined)
                        fetchData()
                    }}
                />
            )}
        </>
    )
}

export default InstructionGroupsManagement
