import * as React from 'react'
import { createStyles, makeStyles, Typography, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pickerLabel: {
            fontFamily: "'Oswald', sans-serif",
            fontWeight: 100,
            color: 'rgba(20, 34, 61, 0.5)',
            display: 'flex',
            padding: theme.spacing(1.5, 0),
        },
        simpleLabel: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 1.9,
            color: 'rgba(20, 34, 61, 0.5)',
            padding: theme.spacing(0, 0.5),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
        itemLabel: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.neutral[700],
            fontWeight: 500,
            padding: theme.spacing(0, 0.5),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
        itemLightLabel: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.neutral[700],
            fontWeight: 300,
            padding: theme.spacing(0, 0.5),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
    }),
)

type PickerLabelProps = {
    className?: string
    children: string
    [typographyProps: string]: any
}

// Header Title is the biggest title we find, it's located in app's header
export const PickerLabel: React.FC<PickerLabelProps> = ({ className, children, ...typographyProps }) => {
    const classes = useStyles()
    return (
        <Typography
            className={`${classes.pickerLabel} ${className}`}
            variant="body1"
            component="span"
            data-testid="title"
            {...typographyProps}
        >
            {children}
        </Typography>
    )
}

type SimpleLabelProps = {
    className?: string
    children: string
    [typographyProps: string]: any
}

export const SimpleLabel: React.FC<SimpleLabelProps> = ({ className, children, ...typographyProps }) => {
    const classes = useStyles()
    return (
        <Typography
            className={`${classes.simpleLabel} ${className || ''}`}
            variant="body1"
            component="span"
            data-testid="title"
            {...typographyProps}
        >
            {children}
        </Typography>
    )
}

type ItemLabelProps = {
    className?: string
    children: any
    light?: boolean
    [typographyProps: string]: any
}

export const ItemLabel: React.FC<ItemLabelProps> = ({ className, children, light = false, ...typographyProps }) => {
    const classes = useStyles()
    return (
        <Typography
            className={`${classes.itemLabel} ${light ? classes.itemLightLabel : ''} ${className || ''}`}
            variant="body1"
            component="span"
            data-testid="title"
            {...typographyProps}
        >
            {children}
        </Typography>
    )
}
