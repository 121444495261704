import * as React from 'react'
import { Button as MuiButton, ButtonProps as MuiButtonProps, createStyles, makeStyles, Theme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import theme from 'theme'

export type PaletteType = 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'neutral' | 'info'

function getPaddingForIcons(startIcon: boolean, endIcon: boolean, paddingValue: number): string | number {
    let left = 0
    let right = 0
    if (startIcon) left = paddingValue
    if (endIcon) right = paddingValue
    return theme.space(0, left, 0, right)
}

type StyleProps = {
    color: PaletteType
    startIcon: boolean
    endIcon: boolean
    iconOnly: boolean
    shape?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        buttonBase: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            border: '1px solid transparent',
            boxSizing: 'border-box',
            fontWeight: 500,
            '&:disabled': {
                opacity: 0.3,
            },
        },
        fill: ({ color }: StyleProps) => ({
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: color === 'neutral' ? theme.palette.common.white : 'transparent',
            backgroundColor: color === 'neutral' ? theme.palette.common.white : theme.palette[color][600],
            color: color === 'neutral' ? theme.palette.neutral[600] : theme.palette[color].contrastText,
            '&:hover:enabled, &:focus:enabled': {
                backgroundColor: () => {
                    if (color === 'neutral') return theme.palette[color][100]
                    if (color === 'primary') return theme.palette[color][400]
                    if (color === 'secondary') return theme.palette[color][700]
                    if (color === 'error') return theme.palette[color][700]
                    if (color === 'warning') return theme.palette[color][500]
                    if (color === 'success') return theme.palette[color][400]
                    if (color === 'info') return theme.palette[color][400]
                },
            },
            '&:focus:enabled': {
                borderColor: () => {
                    if (color === 'neutral') return theme.palette[color][800]
                    if (color === 'primary') return theme.palette[color][300]
                    if (color === 'secondary') return theme.palette[color][500]
                    if (color === 'error') return theme.palette[color][500]
                    if (color === 'warning') return theme.palette[color][400]
                    if (color === 'success') return theme.palette[color][300]
                    if (color === 'info') return theme.palette[color][300]
                },
            },
            '&:disabled': {
                color: color === 'neutral' ? theme.palette.neutral[600] : theme.palette[color].contrastText,
            },
        }),
        stroke: ({ color }: StyleProps) => ({
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            color: theme.palette[color][600],
            borderColor: theme.palette[color][color === 'neutral' ? 300 : 600],
            '&:hover:enabled': {
                color: theme.palette[color][800],
                borderColor: theme.palette[color][color === 'neutral' ? 500 : 800],
                backgroundColor: 'transparent',
            },
            '&:focus:enabled': {
                borderWidth: 2,
            },
            '&:disabled': {
                color: theme.palette[color][600],
            },
        }),
        ghost: ({ color }: StyleProps) => ({
            backgroundColor: 'transparent',
            color: theme.palette[color][600],
            '&:hover:enabled': {
                color: theme.palette[color][800],
                backgroundColor: 'transparent',
            },
            '&:focus:enabled': {
                outline: `2px solid ${theme.palette[color][800]}`,
            },
            '&:disabled': {
                color: theme.palette[color][600],
            },
        }),
        small: ({ shape, iconOnly, startIcon, endIcon }: StyleProps) => ({
            height: theme.space(6),
            minWidth: theme.space(6),
            width: shape ? theme.space(6) : undefined,
            fontSize: theme.typography.pxToRem(12),
            padding: iconOnly ? theme.space(0, 1.5) : theme.space(0, 3),
            '& svg': {
                padding: iconOnly ? 'none' : getPaddingForIcons(startIcon, endIcon, 2),
            },
        }),
        medium: ({ shape, iconOnly, startIcon, endIcon }: StyleProps) => ({
            minWidth: theme.space(5),
            height: theme.space(8),
            width: shape ? theme.space(8) : undefined,
            fontSize: theme.typography.pxToRem(12),
            padding: iconOnly ? theme.space(0, 2) : theme.space(0, 3),
            '& svg': {
                fontSize: theme.typography.pxToRem(16),
                padding: iconOnly ? 'none' : getPaddingForIcons(startIcon, endIcon, 3),
            },
        }),
        large: ({ shape, iconOnly, startIcon, endIcon }: StyleProps) => ({
            padding: theme.space(0, 4),
            minWidth: theme.space(5),
            height: theme.space(12),
            width: shape ? theme.space(12) : undefined,
            fontSize: theme.typography.pxToRem(14),
            '& svg': {
                fontSize: theme.typography.pxToRem(16),
                padding: iconOnly ? 'none' : getPaddingForIcons(startIcon, endIcon, 3),
            },
        }),
        rounded: {
            padding: theme.space(1.2),
            borderRadius: theme.space(5),
        },
        startIcon: {
            paddingRight: theme.space(2),
        },
        endIcon: {
            paddingLeft: theme.space(2),
        },
    }),
)

export interface ButtonProps extends Omit<MuiButtonProps, 'color' | 'variant' | 'icon'> {
    label?: string | React.ReactNode
    color?: PaletteType
    variant?: 'fill' | 'stroke' | 'ghost' | 'fab'
    shape?: 'squared' | 'rounded'
    endIcon?: IconProp
    startIcon?: IconProp
    width?: string
    iconClass?: string
    component?: any // solves https://github.com/mui/material-ui/issues/16846
}

const Button: React.FC<ButtonProps> = React.forwardRef(
    (
        {
            label,
            color = 'primary',
            variant = 'fill',
            size = 'medium',
            shape,
            className = '',
            startIcon,
            endIcon,
            iconClass = '',
            width,
            children,
            ...buttonProps
        },
        ref,
    ) => {
        const classes = useStyles({
            color,
            startIcon: !!startIcon,
            endIcon: !!endIcon,
            iconOnly: !label && !!startIcon,
            shape,
        })

        return (
            <MuiButton
                ref={ref}
                disableRipple
                className={`${className || ''} ${classes.buttonBase} ${classes[variant]} ${classes[size]} ${
                    shape ? classes[shape] : ''
                }`}
                {...buttonProps}
                style={{ width }}
            >
                {startIcon && (
                    <FontAwesomeIcon
                        icon={startIcon}
                        className={`${classes.icon} ${label || children ? classes.startIcon : ''} ${iconClass || ''}`}
                    />
                )}
                {label}
                {children}
                {endIcon && (
                    <FontAwesomeIcon icon={endIcon} className={`${classes.icon} ${classes.endIcon} ${iconClass}`} />
                )}
            </MuiButton>
        )
    },
)

export default Button
