import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import dayjs from 'dayjs'

import { MonthPicker, YearPicker } from '../../reusable/DropDownDatePickers'
import InvoiceRow from './InvoiceRow'
import { RMS_BIRTH } from 'utils/common'
import InvoiceSection from './InvoiceSection'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            marginBottom: theme.spacing(2),
            '& .MuiTypography-root': {
                '& .MuiSvgIcon-root': {
                    paddingLeft: theme.spacing(1),
                    height: '1.25em',
                    width: '1.25em',
                    verticalAlign: '-20%',
                },
            },
            '& .MuiTypography-gutterBottom': {
                marginTop: '1em',
                marginBottom: '1em',
            },
            '& .MuiTypography-body1': {
                color: theme.palette.grey[600],
            },
        },
    }),
)

type InvoicesPageProps = {
    invoicesB2C: any
    setMonth: (month: number) => void
    setYear: (year: number) => void
}

const InvoicesPage: React.FC<InvoicesPageProps> = ({ invoicesB2C, setMonth, setYear }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid container direction="column" spacing={6}>
            <Grid item>
                <div className={classes.sectionHeader}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <MonthPicker onChange={setMonth} />
                        </Grid>
                        <Grid item>
                            <YearPicker range={dayjs().year() - RMS_BIRTH} onChange={setYear} />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <InvoiceSection title={t('finances.invoices.b2c.title')}>
                <InvoiceRow category={t('finances.invoices.b2c.invoices')} invoices={invoicesB2C?.invoices} />
                <InvoiceRow category={t('finances.invoices.b2c.refunds')} invoices={invoicesB2C?.refunds} />
            </InvoiceSection>
        </Grid>
    )
}

export default InvoicesPage
