import * as React from 'react'
import { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import type { KeycloakInstance } from 'keycloak-js'
import i18n from 'i18next'
import { withTranslation } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Box, CircularProgress, CssBaseline, makeStyles, useTheme } from '@material-ui/core'
import Chartkick from 'react-chartkick'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import { toast } from 'utils/toast'
import theme from '../theme'
import Login from './Login'
import { USER_INFO } from 'utils/routes/backend'
import { ToastContainer } from 'react-toastify'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import rmsApi from 'utils/api'
import { useAuth } from 'utils/hooks/useAuth'
import DrawerMenu from '_organisms/drawerMenu/DrawerMenu'
import Routes from './Routes'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'react-toastify/dist/ReactToastify.css'
import { identifyJuneUser } from 'utils/june'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'
import { datadogRum } from '@datadog/browser-rum'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw',
        backGroundColor: '#FFFFFF',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    loader: {
        padding: theme.spacing(3, 2),
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    toast: {
        boxShadow: 'none',
        padding: 0,
    },
    toastBody: {
        justifyContent: 'flex-start',
        gap: '10px',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
        padding: '15px 10px 15px 15px',
        margin: 0,
        '& .Toastify__toast-icon': {
            margin: 0,
            height: 16,
            width: 'auto',
        },
    },
}))

declare global {
    interface Window {
        _mtm?: any
    }
}

const App: React.FC = () => {
    const classes = useStyles()
    const themeForGraphs = useTheme()
    const { keycloak, initialized } = useKeycloak<KeycloakInstance>()
    const { hasRealmRole, logout, roles, isAuthenticated } = useAuth()
    const [user, setUser] = useConnectedUserStore((state: ConnectedUserState) => [state.user, state.setUser])
    const [datePickerLocale, setDatePickerLocale] = useState('fr')
    const [selectedCustomers, setSelectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.selectedCustomers,
        state.setSelectedCustomers,
    ])
    const [selectedWarehouse, setSelectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.selectedWarehouse,
        state.setSelectedWarehouse,
    ])

    Chartkick.options = {
        colors: [
            themeForGraphs.palette.primary.main,
            themeForGraphs.palette.primary.light,
            themeForGraphs.palette.secondary.light,
        ],
    }

    useEffect(() => {
        if (initialized && keycloak?.tokenParsed) {
            const tokenParsed: any = keycloak?.tokenParsed
            if (tokenParsed.locale !== null && tokenParsed.locale !== 'fr') {
                i18n.changeLanguage(tokenParsed.locale)
                setDatePickerLocale('en')
            }
        }
    }, [initialized, keycloak])

    useEffect(() => {
        const _mtm = (window._mtm = window._mtm || [])
        _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
        const d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0]
        g.type = 'text/javascript'
        g.async = true
        g.src = 'https://analytics.lizee.io/js/container_' + import.meta.env.REACT_APP_MATOMO_CONTAINER_ID + '.js'
        s.parentNode?.insertBefore(g, s)
    }, [])

    const fetchUserInfo = async () => {
        try {
            const request = await rmsApi.get(USER_INFO)
            const user = request.data
            setUser(user)
            datadogRum.setUserProperty('email', user.email)
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserInfo()
            identifyJuneUser(keycloak)
        }
    }, [isAuthenticated])

    function isAnySelectedCustomerNotInUserCustomers() {
        if (user.customers.length !== 0) {
            return selectedCustomers.some(
                (selectedCustomer) => !user.customers.some((customer) => customer.id === selectedCustomer.id),
            )
        }
    }

    function isAnySelectedWarehouseNotInUserWarehouses() {
        if (user.warehouses.length !== 0 && selectedWarehouse) {
            return !user.warehouses.some((warehouse) => warehouse.id === selectedWarehouse.id)
        }
    }

    useEffect(() => {
        if (selectedCustomers === null || selectedCustomers.length === 0 || isAnySelectedCustomerNotInUserCustomers()) {
            setSelectedCustomers(user.customers.map((customer) => customer))
        }
    }, [user])

    useEffect(() => {
        if (
            selectedWarehouse === undefined ||
            selectedWarehouse.id === 0 ||
            isAnySelectedWarehouseNotInUserWarehouses()
        ) {
            if (user.warehouses.length === 0) {
                setSelectedWarehouse(user.warehouse)
            } else {
                setSelectedWarehouse(user.warehouses[0])
            }
        }
    }, [user])

    if (!initialized) {
        return (
            <MuiThemeProvider theme={theme}>
                <Box flexGrow={1} className={classes.loader}>
                    <CircularProgress />
                </Box>
            </MuiThemeProvider>
        )
    }

    if (!isAuthenticated || !user) {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Login />
            </MuiThemeProvider>
        )
    }

    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale={datePickerLocale}>
                <CssBaseline />
                <ToastContainer
                    position="bottom-right"
                    theme="colored"
                    toastClassName={classes.toast}
                    bodyClassName={classes.toastBody}
                />
                <BrowserRouter>
                    <div className={classes.root}>
                        <DrawerMenu hasRealmRole={hasRealmRole} logout={logout} />
                        <Routes />
                    </div>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    )
}

export default withTranslation()(App)
