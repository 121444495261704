import { useCallback, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import type { KeycloakInstance } from 'keycloak-js'
import useKeycloakStore from 'utils/store/useKeycloak.store'
import { toast } from 'utils/toast'
import useAccessTokenStore from 'utils/store/useAccessToken.store'
import { AccessTokenState } from 'utils/store/accessToken.store'
import { KeycloakUserState } from 'utils/store/keycloak.store'
import useSocketStore, { SocketState } from 'utils/store/useSocketStore'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'

export interface KeycloakUserProfile {
    id?: string
    username?: string
    firstName?: string
    lastName?: string
    email?: string
    emailVerified?: boolean
    userProfileMetadata?: {
        attributes: any[]
    }
    attributes?: { customers: string[]; locale: string[]; warehouse: string[]; warehouses: number[] }
    fullName: string
}

/**
 * Returns the auth info and some auth strategies.
 *
 */
export const useAuth = (): any => {
    const { keycloak, initialized } = useKeycloak<KeycloakInstance>()

    const [
        keycloakUser,
        setKeycloakUser,
        clearKeycloakUser,
        setRoles,
        clearRoles,
    ] = useKeycloakStore((state: KeycloakUserState) => [
        state.keycloakUser,
        state.setKeycloakUser,
        state.clearKeycloakUser,
        state.setRoles,
        state.clearRoles,
    ])

    const [setAccessToken, clearAccessToken] = useAccessTokenStore((state: AccessTokenState) => [
        state.setAccessToken,
        state.clearAccessToken,
    ])

    const [wsConnect, ws] = useSocketStore((state: SocketState) => [state.connect, state.socket])

    // fetch user profile
    useEffect(() => {
        if (!initialized) {
            return
        }

        const fetchUserInfo = async () => {
            try {
                const userProfile = await keycloak?.loadUserProfile()

                const completeUser = {
                    ...userProfile,
                    fullName: `${userProfile?.firstName} ${userProfile?.lastName}`,
                }

                setKeycloakUser(completeUser)
            } catch (err: any) {
                if (err) toast.error(err.message)
            }
        }

        if (keycloak?.authenticated) {
            setAccessToken(keycloak.token)
            setRoles(keycloak.realmAccess?.roles || [])
            fetchUserInfo()
            if (!ws) wsConnect()
        }
    }, [keycloak, initialized, setKeycloakUser, setAccessToken, setRoles])

    return {
        isAuthenticated: keycloakUser?.fullName !== '',
        initialized,
        meta: {
            keycloak,
        },
        token: keycloak?.token,
        user: {
            fullName: keycloakUser?.fullName,
        },
        roles: keycloak?.realmAccess,
        login: useCallback(() => {
            keycloak?.login()
        }, [keycloak]),
        logout: useCallback(() => {
            clearAccessToken()
            useSelectedCustomersStore.persist.clearStorage()
            useSelectedWarehouseStore.persist.clearStorage()
            clearKeycloakUser()
            clearRoles()
            keycloak?.logout()
        }, [keycloak, clearKeycloakUser, clearRoles, clearAccessToken]),
        hasRealmRole: useCallback(
            (roles: string[]) => {
                let hasOneofRoles = false
                roles.forEach((role) => {
                    if (keycloak?.hasRealmRole(role)) hasOneofRoles = true
                })

                return hasOneofRoles
            },
            [keycloak],
        ),
    }
}

export default {
    useAuth,
}
