import Page from '_organisms/Page'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { PICKING_PACKING_ROUTE } from 'utils/routes/frontend'
import Button from '_atoms/buttons/Button'
import EnrichedIcon from '_molecules/EnrichedIcon'
import { faArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { UPDATE_BATCH_URL } from 'utils/routes/backend'
import { BatchStatus } from 'interfaces/Batch.interface'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import { JuneEvent, trackJuneEvent } from 'utils/june'

type FinishedPickingProps = {
    nbIssues: number
}

const FinishedPicking: React.FC<FinishedPickingProps> = ({ nbIssues }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { batchId } = useParams()
    const buildErrorMessage = useErrorMessage()

    async function finishPicking() {
        try {
            await rmsApi.patch(UPDATE_BATCH_URL(batchId!), { status: BatchStatus.TO_PACK })
            trackJuneEvent(JuneEvent.PICKING_COMPLETED)
            navigate(PICKING_PACKING_ROUTE)
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { batchId }, 'new_orders.errors.finishPickingError'))
        }
    }

    const renderFinishedButton = (
        <Button endIcon={faArrowRight} size="large" onClick={() => finishPicking()} data-testid="finishPicking">
            {t('new_orders.picking.finishBatchButton')}
        </Button>
    )

    return (
        <Page
            title={`${t('new_orders.picking.headerTitle')} ${batchId}`}
            section={t('new_orders.picking.headerSection')}
            handlePrevious={() => navigate(PICKING_PACKING_ROUTE)}
        >
            <BackgroundWithScanAndFooter footer={renderFinishedButton}>
                <EnrichedIcon
                    icon={faCheck}
                    title={t('new_orders.picking.pickingFinished')}
                    tagline={`${nbIssues} ${t('new_orders.picking.pickingFinishedTagline')}`}
                />
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default FinishedPicking
