import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DialogStepper from '_pages/ordersManagement/unitaryPreparation/DialogStepper'
import PickPack from './PickPack'
import LabelPrinter from './labelPrinter'
import { useNavigate, useParams } from 'react-router-dom'
import { PreparationBase, PreparationStatus } from 'interfaces'
import rmsApi from 'utils/api'
import { PREPARATIONS_ROUTE_BY_ID, SHIPMENTS_DELIVERY_NOTE_URL, SHIPMENTS_PARCELS_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import { ChevronLeft, Schedule, ArrowForward } from '@material-ui/icons'
import { UNITARY_PREPARATION_ROUTE } from 'utils/routes/frontend'

const OrderUnitaryPreparation: React.FC = () => {
    const { t } = useTranslation()
    const { preparationId } = useParams()
    const buildErrorMessage = useErrorMessage()
    const navigate = useNavigate()

    const [preparation, setPreparation] = useState<PreparationBase>()
    const [preparationStep, setPreparationStep] = useState<number>(0)
    const [deliveryNoteUrl, setDeliveryNoteUrl] = useState<string | undefined>('')
    const [validatePreparation, setValidatePreparation] = useState<boolean>(false)

    async function fetchPreparation() {
        try {
            const res = await rmsApi.get(PREPARATIONS_ROUTE_BY_ID(preparationId!))
            setPreparation(res.data)
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    useEffect(() => {
        fetchPreparation()
    }, [])

    useEffect(() => {
        setDeliveryNoteUrl(deliveryNoteUrl || preparation?.forthShipment?.deliveryNoteUrl)
    }, [preparation, deliveryNoteUrl])

    if (!preparation) {
        return <></>
    }

    async function updatePreparation(status: PreparationStatus) {
        try {
            await rmsApi.patch(PREPARATIONS_ROUTE_BY_ID(preparationId!), { status })
            navigate(UNITARY_PREPARATION_ROUTE)
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { preparationId }))
        }
    }

    async function createLabels(shipmentId: number, amount: number): Promise<void> {
        try {
            const labelData = await rmsApi.post(SHIPMENTS_PARCELS_URL(shipmentId), { amount })
            const { forthParcels, returnParcels } = labelData.data

            const selectedPreparationCopy = Object.assign({}, preparation)
            selectedPreparationCopy.forthShipment.labels = forthParcels
            if (returnParcels) selectedPreparationCopy.returnShipment.labels = returnParcels

            setPreparation(selectedPreparationCopy)
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    async function createDeliveryNote(shipmentId: number): Promise<void> {
        try {
            const labelData = await rmsApi.post(SHIPMENTS_DELIVERY_NOTE_URL(shipmentId))
            setDeliveryNoteUrl(labelData.data)
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    const dialogStepperActions = [
        {
            label: t('wms.preparation.back'),
            action: () => {
                updatePreparation(PreparationStatus.TO_PREPARE)
            },
            disabled: false,
            startIcon: <ChevronLeft />,
        },
        {
            label: t('wms.preparation.delay'),
            action: () => {
                updatePreparation(PreparationStatus.DELAYED)
            },
            disabled: false,
            startIcon: <Schedule />,
        },
    ]

    const finishAction = {
        label: t('wms.preparation.send'),
        action: () => updatePreparation(PreparationStatus.FINISHED),
        hidden: preparationStep === 0,
        disabled: !validatePreparation,
        endIcon: <ArrowForward />,
    }

    return (
        <DialogStepper
            step={preparationStep}
            isOpen={!!preparation}
            appBarAction={dialogStepperActions}
            handleClose={() => setPreparation(undefined)}
            title={t('wms.preparation.omsOrderIdTitle', {
                omsOrderId: preparation.order.omsId,
            })}
            finishAction={finishAction}
        >
            <PickPack
                preparationId={preparation.id}
                handleNextStep={() => setPreparationStep(preparationStep + 1)}
                productList={preparation.products}
                reservedUniqueProducts={preparation.reservedUniqueProducts}
                customer={preparation.customer}
            />
            <LabelPrinter
                forthLabels={preparation.forthShipment?.labels}
                returnLabels={preparation.returnShipment?.labels}
                deliveryNoteEnabled={preparation.customer?.deliveryNote}
                fetchLabels={(nbParcels: number) => createLabels(preparation.forthShipment.id, nbParcels)}
                deliveryNoteUrl={deliveryNoteUrl}
                fetchDeliveryNote={() => createDeliveryNote(preparation.forthShipment.id)}
                setValidatePreparation={setValidatePreparation}
            />
        </DialogStepper>
    )
}

export default OrderUnitaryPreparation
