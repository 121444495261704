import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles } from '@material-ui/core'
import { AxiosResponse } from 'axios'
import { OMS_RENTAL_STATS_URL } from 'utils/routes/backend'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import Figure from 'components/statistics/Figure'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { getChartsColors, getDoughnutOptions, getStatsStyles } from 'components/reusable/utils/StatsUtils'
import { toast } from 'utils/toast'
import Page from '_organisms/Page'
import PageContentHeader from 'components/reusable/PageContentHeader'
import rmsApi from 'utils/api'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import CustomerPicker from '_organisms/header/addons/CustomerPicker'

ChartJS.register(...registerables)

const statsStyles = getStatsStyles()
const useStyles = makeStyles(() => createStyles(statsStyles))

const RentalStatsApp: React.FC = () => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const [selectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])
    const [stats, setStats] = useState<any>(null)
    const [fullChartOptions, setFullChartOptions] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean | string>(true)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())

    dayjs.locale(i18n.language)

    useEffect(() => {
        if (selectedCustomer) fetchStats()
        // eslint-disable-next-line
    }, [selectedCustomer, startDate, endDate])

    async function fetchStats() {
        try {
            setIsLoading(true)
            const statsUrl = OMS_RENTAL_STATS_URL
            let params = `?customerId=${selectedCustomer?.id}&date=${dayjs().format('YYYY-MM-DD')}`
            if (startDate && endDate) {
                const from = dayjs(startDate).format('YYYY-MM-DD')
                const to = dayjs(endDate).format('YYYY-MM-DD')
                params += `&from=${from}&to=${to}`
            }
            const statsRequest: AxiosResponse = await rmsApi.get(statsUrl + params)
            formatStatsData(statsRequest.data)
        } catch (e: any) {
            setIsLoading('error')
            toast.error(e)
        }
    }

    const doughnutOptions: any = getDoughnutOptions()
    const chartColors: string[] = getChartsColors()
    ChartJS.overrides.doughnut.plugins.tooltip = doughnutOptions.plugins.tooltip

    const formatStatsData = (statsToFormat: any) => {
        const formattedData: any = {}
        const buildingChartOptions: any = {}
        setFullChartOptions({})
        formattedData.leasingAvg = {
            figureDescription: 'rentalStats.figures.leasingAvg.description',
            value: statsToFormat.leasingAvg ? Number(statsToFormat.leasingAvg).toLocaleString() : '0',
        }
        formattedData.leasingUsageTime = {
            labels: statsToFormat.leasingUsageTime.map((data: { total: number; leasingTime: string }) => {
                return data.leasingTime
            }),
            datasets: [
                {
                    label: 'label',
                    backgroundColor: chartColors,
                    data: statsToFormat.leasingUsageTime.map((data: { total: number; leasingTime: string }) => {
                        return data.total
                    }),
                },
            ],
        }
        buildingChartOptions.leasingUsageTime = doughnutOptions
        buildingChartOptions.leasingUsageTime = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.leasingUsageTime.plugins.title.text = t('rentalStats.figures.leasingUsageTime.description')

        formattedData.leasingStartingDay = {
            labels: statsToFormat.leasingStartingDay.map(
                (data: { totalPercentage: number; day: string; total: number }) => {
                    return t(`weekdays.${data.day}`)
                },
            ),
            datasets: [
                {
                    label: 'label',
                    backgroundColor: chartColors,
                    data: statsToFormat.leasingStartingDay.map(
                        (data: { totalPercentage: number; day: string; total: number }) => {
                            return data.totalPercentage
                        },
                    ),
                },
            ],
        }
        buildingChartOptions.leasingStartingDay = doughnutOptions
        buildingChartOptions.leasingStartingDay = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.leasingStartingDay.plugins.title.text = t(
            'rentalStats.figures.leasingStartingDay.description',
        )

        formattedData.leasingEndingDay = {
            labels: statsToFormat.leasingEndingDay.map(
                (data: { totalPercentage: number; day: string; total: number }) => {
                    return t(`weekdays.${data.day}`)
                },
            ),
            datasets: [
                {
                    label: 'label',
                    backgroundColor: chartColors,
                    data: statsToFormat.leasingEndingDay.map(
                        (data: { totalPercentage: number; day: string; total: number }) => {
                            return data.totalPercentage
                        },
                    ),
                },
            ],
        }
        buildingChartOptions.leasingEndingDay = doughnutOptions
        buildingChartOptions.leasingEndingDay = JSON.parse(JSON.stringify(doughnutOptions))
        buildingChartOptions.leasingEndingDay.plugins.title.text = t('rentalStats.figures.leasingEndingDay.description')

        setFullChartOptions(buildingChartOptions)
        setStats(formattedData)
        setIsLoading(false)
    }

    const handleEndDateChange = (endDateToSet: Dayjs) => {
        if (endDateToSet.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDateToSet).subtract(1, 'day'))
            setEndDate(endDateToSet)
        } else {
            setEndDate(endDateToSet)
        }
    }

    const customerPicker = () => <CustomerPicker initWithRandomCustomer truncateLabelAbove={140} />

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.rental')}
            tagline={t('rentalStats.tagline')}
            rightAddon={{ addon: customerPicker(), displayOnMobile: true }}
        >
            <PageContentHeader
                title="rentalStats.content.title"
                tagline="rentalStats.content.tagline"
                isLoading={isLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={handleEndDateChange}
            />
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={6}>
                    {stats?.leasingAvg && <Figure testId="rental-main-figure" figure={stats.leasingAvg} main />}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.chartContainer}>
                        {stats?.leasingUsageTime && (
                            <Chart
                                type="doughnut"
                                data={stats?.leasingUsageTime}
                                options={fullChartOptions?.leasingUsageTime}
                            />
                        )}
                    </div>
                </Grid>
                <Grid item md={12} className={classes.spacer} />
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.chartContainer}>
                        {stats?.leasingStartingDay && (
                            <Chart
                                type="doughnut"
                                data={stats?.leasingStartingDay}
                                options={fullChartOptions?.leasingStartingDay}
                            />
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.chartContainer}>
                        {stats?.leasingEndingDay && (
                            <Chart
                                type="doughnut"
                                data={stats?.leasingEndingDay}
                                options={fullChartOptions?.leasingEndingDay}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default RentalStatsApp
