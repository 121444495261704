import * as React from 'react'
import { useEffect } from 'react'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import DropDownPicker from '_molecules/DropDownPicker'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ICustomer } from 'interfaces/Customer'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            width: theme.space(52),
            '& .MuiInputBase-root svg': {
                color: theme.palette.primary[600],
            },
        },
    }),
)

type CustomerPickerProps = {
    initWithRandomCustomer: boolean
    truncateLabelAbove?: number
}

const CustomerPicker: React.FC<CustomerPickerProps> = ({ initWithRandomCustomer, truncateLabelAbove }) => {
    const classes = useStyles()
    const user = useConnectedUserStore((state: ConnectedUserState) => state.user)

    const { t } = useTranslation()
    const [selectedCustomer, setSelectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [
        state.selectedCustomer,
        state.setSelectedCustomer,
    ])
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])

    useEffect(() => {
        // init with random customer in case of customer is required or force customer selection if only one exists
        if (user.customers.length && !selectedCustomer && (initWithRandomCustomer || user.customers.length === 1))
            setSelectedCustomer(user.customers[0])
    }, [selectedCustomer, user])

    function onChangeSelectedCustomer(customerName: string) {
        const pickedCustomer = user.customers.find((customer: ICustomer) => customer.name === customerName)
        if (pickedCustomer) setSelectedCustomer(pickedCustomer)
    }

    return (
        <>
            {user.customers.length > 0 && (
                <DropDownPicker
                    value={selectedCustomer?.name || ''}
                    placeholder={t('global.customer')}
                    submitSelection={onChangeSelectedCustomer}
                    options={selectedCustomers?.map((customer: ICustomer) => customer!.name!) || []}
                    menuClassName={classes.menu}
                    truncateLabelAbove={truncateLabelAbove}
                    data-testid={'customerPicker'}
                    disabled={user.customers.length === 1}
                />
            )}
        </>
    )
}

export default CustomerPicker
