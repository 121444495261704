import * as React from 'react'
import { useEffect, useState } from 'react'
import { IInstructionGroup, IProductWithEans } from 'interfaces'
import rmsApi from 'utils/api'
import { LINKED_PRODUCTS_ROUTE, REFIT_GROUP_INSTRUCTION_ROUTE } from 'utils/routes/backend'
import TabSystem from '_organisms/TabSystem'
import InstructionGroupTab from '_pages/instructionGroupsManagement/InstructionGroupTab'
import { useTranslation } from 'react-i18next'
import LinkedProductsTab from '_pages/instructionGroupsManagement/LinkedProductsTab'
import { toast } from 'utils/toast'
import Page from '_organisms/Page'

type HandleSingleGroupProps = {
    group: IInstructionGroup
    customerRefitInstructions: any[]
    unSelectGroup: () => void
}

const HandleSingleGroup: React.FC<HandleSingleGroupProps> = ({ group, customerRefitInstructions, unSelectGroup }) => {
    const { t } = useTranslation()
    const [linkedProducts, setLinkedProducts] = useState<IProductWithEans[]>([])
    const [instructions, setInstructions] = useState<any[]>([])

    async function fetchGroupInfos() {
        const promises = []
        promises.push(rmsApi.get(REFIT_GROUP_INSTRUCTION_ROUTE(group.id!)))
        promises.push(rmsApi.get(LINKED_PRODUCTS_ROUTE(group.id!)))
        try {
            const [instructionResponse, linkedProductsResponse] = await Promise.all(promises)
            setInstructions(instructionResponse.data)
            setLinkedProducts(linkedProductsResponse.data)
        } catch (e) {
            toast.error(t('refit.instructionGroups.manageGroup.fetchFailed'))
        }
    }

    useEffect(() => {
        fetchGroupInfos()
        // eslint-disable-next-line
    }, [])

    return (
        <Page
            title={group.name}
            section={t('refit.instructionGroups.title')}
            tagline={group.description}
            handlePrevious={unSelectGroup}
        >
            <TabSystem
                bottomOnMobile
                tabLabels={[
                    { label: t('refit.instructionGroups.manageGroup.instructionsTab.title'), value: 'instructionsTab' },
                    {
                        label: t('refit.instructionGroups.manageGroup.linkedProductsTab.title'),
                        value: 'linkedProductsTab',
                    },
                ]}
            >
                <InstructionGroupTab
                    instructions={instructions}
                    customerRefitInstructions={customerRefitInstructions}
                    groupId={group.id!}
                    setInstructions={setInstructions}
                    refreshGroup={fetchGroupInfos}
                />
                <LinkedProductsTab group={group} products={linkedProducts} refreshGroup={fetchGroupInfos} />
            </TabSystem>
        </Page>
    )
}

export default HandleSingleGroup
