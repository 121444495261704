import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import { RMSFile, downloadFiles } from 'utils/files'
import FileButton from '_atoms/inputs/FileButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileImage, faFileVideo, faFile, faClose } from '@fortawesome/pro-light-svg-icons'
import { faEye } from '@fortawesome/pro-regular-svg-icons'
import Text from '_atoms/text/Text'
import FileCarousel from '_organisms/images/carousel/FileCarousel'
import MoveWidget from '_atoms/MoveWidget'
import { REFIT_INSTRUCTION_DOWNLOAD_FILES } from 'utils/routes/backend'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filesContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(1)}px`,
            marginTop: theme.space(2),
        },
        fileWrapper: {
            position: 'relative',
            '&:hover > *:first-child': {
                display: 'flex',
            },
        },
        fileButton: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(2),
            },
        },
        file: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '100%',
        },
        fileNameContainer: {
            maxWidth: `calc(100% - ${theme.space(14 / 5)}px)`,
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            gap: `${theme.space(2)}px`,
        },
        fileTypeIcon: {
            color: theme.palette.neutral[400],
            width: theme.space(16 / 5),
            height: theme.space(16 / 5),
        },
        fileName: {
            textDecorationLine: 'underline',
            letterSpacing: '0em',
            color: theme.palette.neutral[700],
            maxWidth: '85%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        fileActionIcon: {
            cursor: 'pointer',
            width: theme.space(14 / 5),
            height: theme.space(14 / 5),
        },
        moveWidget: {
            display: 'none',
            position: 'absolute',
            top: `-${theme.space(7 / 5)}px`,
            left: `-${theme.space(21 / 5)}px`,
            paddingRight: theme.space(1),
            '&:hover': {
                display: 'flex',
            },
        },
    }),
)

type ManageFilesProps = {
    files: RMSFile[]
    setFiles: (files: RMSFile[]) => void
    inputDisabled: boolean
    instructionId?: number
}

const ManageFiles: React.FC<ManageFilesProps> = ({ files, setFiles, inputDisabled, instructionId }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

    const [openedFilePosition, setOpenedFilePosition] = useState(-1)

    function handleOpen(i: number) {
        setOpenedFilePosition(i)
    }

    async function buildFilesURLs(files: RMSFile[]) {
        try {
            const sortedFiles = files.sort((a, b) => a.position! - b.position!)
            const data = await downloadFiles(sortedFiles, REFIT_INSTRUCTION_DOWNLOAD_FILES(instructionId!))
            for (let i = 0; i < files.length; i += 1) {
                sortedFiles[i].url = data![i]
            }
            setFiles(sortedFiles)
        } catch (e: any) {
            toast.error(t('files.fetchError', { message: e.message }))
        }
    }

    useEffect(() => {
        if (files?.length) {
            buildFilesURLs(files)
        }
    }, [])

    function setFileTypeIcon(type: string) {
        if (type.includes('image')) {
            return faFileImage
        } else if (type.includes('video')) {
            return faFileVideo
        } else if (type.includes('pdf')) {
            return faFilePdf
        }
        return faFile
    }

    async function handleFileInput(inputFiles: RMSFile[]) {
        for (let i = 0; i < inputFiles.length; i += 1) {
            inputFiles[i].position = files.length + i
            inputFiles[i].url = URL.createObjectURL(inputFiles[i])
        }
        setFiles([...files, ...inputFiles])
    }

    function handleFileDelete(fileIndex: number) {
        const newFiles = [...files.filter((e, i) => i !== fileIndex)]
        newFiles.forEach((file, index) => (file.position = index))
        setFiles(newFiles)
    }

    function handleMoveClick(fileIndex: number, delta: number) {
        const newFiles = [...files]
        const file = newFiles[fileIndex]
        newFiles.splice(fileIndex, 1)
        newFiles.splice(fileIndex + delta, 0, file)
        newFiles.forEach((file, index) => (file.position = index))
        setFiles(newFiles)
    }

    return (
        <div>
            {!inputDisabled && (
                <FileButton
                    multiple
                    text={
                        isMobile
                            ? t('refit.instructionCategories.modal.INSTRUCTIONS.upload.buttonLabel')
                            : t('refit.instructionCategories.modal.INSTRUCTIONS.upload.buttonLabelDesktop')
                    }
                    handleFile={(files) => handleFileInput(files)}
                    className={classes.fileButton}
                    accept="image/*, video/*, application/pdf"
                />
            )}
            {files?.length > 0 && (
                <Box className={classes.filesContainer}>
                    {files
                        .sort((a, b) => a.position! - b.position!)
                        .map((file, index) => (
                            <div className={classes.fileWrapper} key={index}>
                                <div className={classes.moveWidget}>
                                    {!inputDisabled && (
                                        <MoveWidget
                                            handleDownClick={() => handleMoveClick(index, 1)}
                                            handleUpClick={() => handleMoveClick(index, -1)}
                                            downClickDisbled={file.position! === files.length - 1}
                                            upClickDisabled={file.position === 0}
                                        />
                                    )}
                                </div>
                                <div className={classes.file}>
                                    <div className={classes.fileNameContainer} onClick={() => handleOpen(index)}>
                                        <FontAwesomeIcon
                                            icon={setFileTypeIcon(file.type)}
                                            className={classes.fileTypeIcon}
                                        />
                                        <Text variant="N9" className={classes.fileName}>
                                            {file.name}
                                        </Text>
                                    </div>
                                    {inputDisabled ? (
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            className={classes.fileActionIcon}
                                            color={theme.palette.neutral[400]}
                                            onClick={() => handleOpen(index)}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faClose}
                                            className={classes.fileActionIcon}
                                            color={theme.palette.error[300]}
                                            onClick={() => handleFileDelete(index)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                </Box>
            )}
            {openedFilePosition > -1 && (
                <FileCarousel
                    files={files}
                    openedFilePosition={openedFilePosition}
                    setOpenedFilePosition={setOpenedFilePosition}
                />
            )}
        </div>
    )
}

export default ManageFiles
