import * as React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCheck, faCircleExclamation, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import Text from '_atoms/text/Text'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type TipVariant = 'primary' | 'info' | 'warning' | 'error' | 'success'

const iconFromVariant: Record<TipVariant, IconProp> = {
    primary: faInfoCircle,
    info: faInfoCircle,
    warning: faCircleExclamation,
    error: faTriangleExclamation,
    success: faCheck,
}

type StyleProps = {
    variant: TipVariant
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        box: ({ variant }) => ({
            padding: theme.space(3, 3, 3, 5),
            backgroundColor: theme.palette[variant][variant === 'primary' ? 100 : 50],
            display: 'flex',
            alignItems: 'center',
        }),
        icon: ({ variant }) => ({
            fontSize: '16px',
            color: theme.palette[variant][600],
            marginRight: theme.space(2),
        }),
        text: ({ variant }) => ({
            color: theme.palette[variant][900],
            whiteSpace: 'pre-wrap',
        }),
        title: ({ variant }) => ({
            color: theme.palette[variant][900],
            fontWeight: 600,
        }),
    }),
)

export type TipsProps = {
    title?: string
    text?: string
    children?: React.ReactNode
    className?: string
    variant?: TipVariant
}

const Tips: React.FC<TipsProps> = ({ text, title, className = '', children, variant = 'primary' }) => {
    const classes = useStyles({ variant })

    return (
        <Box className={`${classes.box} ${className}`}>
            <FontAwesomeIcon className={classes.icon} icon={iconFromVariant[variant]} />
            <div>
                <Text className={classes.title} variant="P5">
                    {title}
                </Text>
                <Text className={classes.text} variant="P5">
                    {text || children}
                </Text>
            </div>
        </Box>
    )
}

export default Tips
