import create from 'zustand/vanilla'
import { KeycloakUserProfile } from 'utils/hooks/useAuth'

export interface KeycloakUserState {
    keycloakUser: KeycloakUserProfile
    roles: string[]
    setKeycloakUser: (keycloakUser: KeycloakUserProfile) => void
    clearKeycloakUser: () => void
    setRoles: (roles: string[]) => void
    clearRoles: () => void
    hasOneOfRoles: (roles: string[]) => boolean
}

const keycloakStore = create<KeycloakUserState>()((set, get) => ({
    keycloakUser: { fullName: '' },
    roles: [],
    setKeycloakUser: (keycloakUser) => set(() => ({ keycloakUser })),
    clearKeycloakUser: () => set({ keycloakUser: { fullName: '' } }),
    setRoles: (roles) => set(() => ({ roles })),
    clearRoles: () => set(() => ({ roles: [] })),
    hasOneOfRoles: (roles) => {
        for (const role of roles) {
            if (get().roles.includes(role)) return true
        }
        return false
    },
}))

export default keycloakStore
