import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, Theme, createStyles } from '@material-ui/core'
import QuickAction from 'components/home/QuickAction'
import { WMS_LINK_LOCATION_TO_PRODUCT_ROUTE, WMS_LINK_LOCATION_TO_PRODUCTS_ROUTE } from 'utils/routes/frontend'
import Page from '_organisms/Page'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridMain: {
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                marginTop: 0,
            },
        },
    }),
)

const LinkLocationIntermediaryPage: React.FC = () => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Page title={t('wms.locationIntermediaryPage.title')}>
            <Grid container spacing={3} className={classes.gridMain}>
                <Grid item xs={12} sm={4} md={3}>
                    <QuickAction
                        title={t('wms.locationIntermediaryPage.linkLocationTitle')}
                        location={WMS_LINK_LOCATION_TO_PRODUCT_ROUTE}
                        testId={'linkLocationCard'}
                        ctaText={t('wms.locationIntermediaryPage.cta')}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <QuickAction
                        title={t('wms.locationIntermediaryPage.linkBatchLocationTitle')}
                        location={WMS_LINK_LOCATION_TO_PRODUCTS_ROUTE}
                        testId={'linkBatchLocationCard'}
                        ctaText={t('wms.locationIntermediaryPage.cta')}
                    />
                </Grid>
            </Grid>
        </Page>
    )
}

export default LinkLocationIntermediaryPage
