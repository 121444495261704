import { useEffect, useState } from 'react'
import rmsApi from '../api'

export enum QueryState {
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export const useApi = (url: string): { state: QueryState; error: any; result: any } => {
    const [data, setData] = useState({ state: QueryState.LOADING, error: undefined, result: undefined })

    const setPartData = (partialData: any) => setData({ ...data, ...partialData })

    async function fetchData(url: string): Promise<void> {
        try {
            setPartData({ state: QueryState.LOADING })
            const response = await rmsApi.get(url)
            setPartData({
                state: QueryState.SUCCESS,
                result: response.data,
            })
        } catch (e) {
            setPartData({
                state: QueryState.ERROR,
                error: e,
            })
        }
    }

    useEffect(() => {
        fetchData(url)
        // eslint-disable-next-line
    }, [])

    return data
}
