import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from './LoadingIndicator'
import { Dayjs } from 'dayjs'
import DateRangePicker from '_atoms/inputs/DateRangePicker'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabContentTitle: {
            fontWeight: 100,
            marginBottom: theme.spacing(2),
        },
        dateRangePicker: {
            marginTop: theme.spacing(5),
        },
    }),
)

type PageContentHeaderProps = {
    title: string
    tagline: string
    isLoading: string | boolean
    startDate: Dayjs
    endDate: Dayjs
    setStartDate: any
    setEndDate: any
}

const PageContentHeader: React.FC<PageContentHeaderProps> = ({
    title,
    tagline,
    isLoading,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <Typography className={classes.tabContentTitle} variant="h3">
                {t(title)}
                <LoadingIndicator isLoading={isLoading} />
            </Typography>
            <Typography variant="body1">{t(tagline)}</Typography>
            <DateRangePicker
                className={classes.dateRangePicker}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
        </>
    )
}

export default PageContentHeader
