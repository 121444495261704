import * as React from 'react'
import { Grid } from '@material-ui/core'
import CodeScanner from '../refitApp/CodeScanner'

type Props = {
    label: string
    stepId?: number
    defaultValue?: string
    disabled?: boolean
    handleStepperUpdated: (stepUpdated: number, submittedStep: string) => Promise<void>
}

const ProductWithoutDisabledStepInput: React.FC<Props> = ({
    defaultValue,
    label,
    stepId = 0,
    disabled = false,
    handleStepperUpdated,
}) => {
    const handleSubmit = (submittedText: string) => {
        handleStepperUpdated(stepId, submittedText)
    }

    return (
        <Grid item xs={12}>
            <CodeScanner label={label} defaultValue={defaultValue} disabled={disabled} onSubmit={handleSubmit} />
        </Grid>
    )
}

export default ProductWithoutDisabledStepInput
