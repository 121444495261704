import * as React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tagline: {
            paddingTop: '1em',
            color: theme.palette.grey[600],
            fontSize: theme.typography.pxToRem(12),
        },
    }),
)

type TaglineProps = {
    className?: string
    children?: React.ReactNode
    [typographyProps: string]: any
}

const Tagline: React.FC<TaglineProps> = ({ className, children, ...typographyProps }) => {
    const classes = useStyles()
    return (
        <Typography
            variant="body1"
            className={`${classes.tagline} ${className || ''}`}
            component="p"
            {...typographyProps}
        >
            {children}
        </Typography>
    )
}

export default Tagline
