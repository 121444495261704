import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { IParcel, IUniqueProduct, UniqueProductStatus, UniqueProductStatusFront } from 'interfaces'
import Pill from '_atoms/badges/Pill'
import { useTranslation } from 'react-i18next'
import { RMSFile } from 'utils/files'
import rmsApi from 'utils/api'
import {
    PROCESS_UNIQUE_PRODUCT_RETURN,
    RETURN_ITEM_UPDATE_URL,
    UNIQUE_PRODUCTS_BY_BARCODEUID_URL,
} from 'utils/routes/backend'
import { toast } from 'utils/toast'
import UniqueProductProcessingDrawer from '_organisms/uniqueProduct/drawer/UniqueProductProcessingDrawer'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import AddCommentModal from '_organisms/uniqueProduct/AddCommentModal'
import { ReturnItem } from 'interfaces/ReturnItem.interface'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import RentalProductCard from './RentalProductCard'
import ListWithCounter from '_molecules/ListWithCounter'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import { StepHandlerType } from '_organisms/Stepper'
import Button from '_atoms/buttons/Button'
import CheckPackaging from './CheckPackaging'
import Tips from '_atoms/containers/Tips'
import ProductIssueModal from './ProductIssueModal'
import useSelectedCustomersStore from '../../../utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from '../../../utils/store/selectedCustomers.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            [theme.breakpoints.up('sm')]: {
                padding: theme.space(0, 12),
            },
        },
        rightOfWithdrawalChip: {
            marginBottom: theme.spacing(2),
            color: theme.palette.primary[500],
            borderColor: theme.palette.primary[500],
            marginLeft: theme.spacing(2),
        },
        header: {
            padding: theme.space(0, 5),
            marginBottom: theme.space(5),
            alignItems: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.space(3),
            },
        },
        packaging: {
            margin: theme.space(7, 0),
        },
        tips: {
            marginBottom: theme.space(3),
            [theme.breakpoints.up('sm')]: {
                border: `1px solid ${theme.palette.primary[200]}`,
                borderRadius: '4px',
                marginBottom: theme.space(4),
            },
        },
    }),
)

type ProcessProductsStepProps = {
    parcel: IParcel
    pickableUniqueProducts: IUniqueProduct[]
    processedUniqueProducts: IUniqueProduct[] | undefined
    addPickableUniqueProduct: (product: IUniqueProduct) => void
    refreshProductList: () => void
    hasReturnAddon: boolean
    handleSteps?: StepHandlerType
    setParcel: (parcel: IParcel) => void
}

const ProcessProductsStep: React.FC<ProcessProductsStepProps> = ({
    parcel,
    processedUniqueProducts,
    pickableUniqueProducts,
    addPickableUniqueProduct,
    refreshProductList,
    hasReturnAddon,
    handleSteps,
    setParcel,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [selectedUniqueProduct, setSelectedUniqueProduct] = useState<IUniqueProduct>()
    const [editReturnItem, setEditReturnItem] = useState<ReturnItem | undefined>()
    const [isIssueModalOpened, setIsIssueModalOpened] = useState<boolean>(false)
    const [isPackagingChecked, setIsPackagingChecked] = useState<boolean>(false)
    const [shouldDisplaySecondHandElements] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.shouldDisplaySecondHandElements,
    ])

    const customer = parcel?.shipment!.renter!.customer
    const displayPackagingCheckStep = hasReturnAddon && !isPackagingChecked

    async function fetchUnlistedUniqueProduct(barcodeUid: string): Promise<IUniqueProduct | undefined> {
        try {
            const uniqueProductRequest = await rmsApi.get(UNIQUE_PRODUCTS_BY_BARCODEUID_URL(barcodeUid))
            return uniqueProductRequest?.data
        } catch (e: any) {
            if (e.response.status === 403) {
                const { warehouse, PUID } = e.response.data.error
                const { name, address, city } = warehouse
                toast.error(t('parcel.scan.badWarehouse', { barcode: PUID, name, address, city }))
            } else {
                toast.error(t('parcel.scan.errorCode', { barcode: barcodeUid }))
            }
        }
    }

    async function pickScannedProduct(barcodeUid: string): Promise<void> {
        let scannedUniqueProduct = pickableUniqueProducts.find(
            (uniqueProduct) => uniqueProduct.barcodeUid === barcodeUid,
        )
        if (!scannedUniqueProduct) {
            scannedUniqueProduct = await fetchUnlistedUniqueProduct(barcodeUid)
            if (!scannedUniqueProduct) return
            addPickableUniqueProduct(scannedUniqueProduct)
        }
        setSelectedUniqueProduct(scannedUniqueProduct)
    }

    const handleScan = (scannedValue: string) => {
        if (scannedValue === 'PARCEL_PROCESSED') {
            displayPackagingCheckStep ? handleSteps!.nextStep() : handleSteps!.onSubmit()
            return
        }
        if (Object.values(UniqueProductStatus).includes(scannedValue as UniqueProductStatus)) {
            if (!selectedUniqueProduct) {
                toast.error(t('returns.scanTrayWitoutProduct'))
                return
            }
            onPickUniqueProductStatus(scannedValue as UniqueProductStatus)
        } else {
            pickScannedProduct(scannedValue)
        }
    }

    // Update product status with processed operation
    async function postUniqueProductCondition(
        status: string,
        comment?: string,
        productIssueIds?: number[] | undefined,
        files?: RMSFile[],
    ): Promise<any> {
        const payload = {
            barcodeUid: selectedUniqueProduct?.barcodeUid,
            status: status,
            files:
                files?.map((file: RMSFile) => ({
                    name: file.remoteName,
                    type: file.type,
                })) || [],
            productIssueIds: productIssueIds,
            comment: comment,
        }
        try {
            const shipmentData = await rmsApi.post(PROCESS_UNIQUE_PRODUCT_RETURN(parcel!.id), payload)
            refreshProductList()
            if (isIssueModalOpened) setIsIssueModalOpened(false)
            setSelectedUniqueProduct(undefined)
            return shipmentData.data
        } catch (e: any) {
            toast.error(t('parcel.productUpdateError', { error: e }))
        }
    }

    function onPickUniqueProductStatus(status: UniqueProductStatus) {
        if (
            hasReturnAddon &&
            (status === UniqueProductStatus.OUT_OF_ORDER || status === UniqueProductStatus.TO_REPAIR)
        ) {
            setSelectedUniqueProduct({ ...selectedUniqueProduct!, status })
            setIsIssueModalOpened(true)
        } else {
            if (selectedUniqueProduct?.returnItems?.length)
                editReturnCondition({ ...selectedUniqueProduct?.returnItems[0], status })
            else postUniqueProductCondition(status)
        }
    }

    function selectUniqueProduct(uniqueProduct: IUniqueProduct, clicked: boolean) {
        const similarUniqueProducts =
            pickableUniqueProducts?.filter((currentUniqueProduct) => {
                if (currentUniqueProduct.productReference.sku === uniqueProduct?.productReference.sku)
                    return currentUniqueProduct
            }) || []

        if (clicked && similarUniqueProducts?.length > 1)
            toast.warning(
                t('parcel.scan.possibleSimilarProducts', {
                    PUIDs: similarUniqueProducts.map((similarProduct) => similarProduct.barcodeUid).join(', '),
                }),
            )
        setSelectedUniqueProduct(uniqueProduct)
    }

    async function submitIssue(comment: string, selectedIssueIds: number[] | undefined, files: RMSFile[]) {
        let success
        if (selectedUniqueProduct?.returnItems?.length) {
            success = await editReturnCondition({
                ...selectedUniqueProduct?.returnItems[0],
                status: selectedUniqueProduct.status,
                comment,
                files,
                productIssues: selectedIssueIds as any,
            })
        } else {
            success = await postUniqueProductCondition(selectedUniqueProduct!.status, comment, selectedIssueIds, files)
        }
        if (success) {
            setIsIssueModalOpened(false)
        }
    }

    async function editReturnCondition(returnItem: ReturnItem) {
        const newFiles = returnItem.files?.filter((file) => !file.id) || []
        if (returnItem.status === UniqueProductStatus.TO_REFIT || returnItem.status === UniqueProductStatus.TO_RESTOCK)
            returnItem.productIssues = []

        const payload = {
            ...returnItem,
            files: newFiles.map((file) => ({
                name: file.remoteName,
                type: file.type,
            })),
            productIssueIds: returnItem.productIssues,
        }
        try {
            await rmsApi.patch(RETURN_ITEM_UPDATE_URL(returnItem.id), payload)
            if (isIssueModalOpened) setIsIssueModalOpened(false)
            setEditReturnItem(undefined)
            setSelectedUniqueProduct(undefined)
            refreshProductList()
        } catch (e: any) {
            toast.error(t('returnParcel.editFailed', { error: e.message }))
        }
    }

    const productsToProcessCount = pickableUniqueProducts.length
    const processedProductsCount = processedUniqueProducts?.length || 0
    const totalProductsCount = productsToProcessCount + processedProductsCount

    const renderButton = () => (
        <Button
            size="large"
            color="success"
            onClick={() => (displayPackagingCheckStep ? handleSteps!.nextStep() : handleSteps!.onSubmit())}
            endIcon={faArrowRight}
            disabled={processedProductsCount === 0}
            data-testid="uncompleteParcel"
        >
            {t('parcel.scan.processed')}
        </Button>
    )

    return (
        <Page
            title={t('returnParcel.parcelNumber', { parcelNumber: parcel.barcode })}
            section={
                shouldDisplaySecondHandElements() ? t('menu.returns.registerSecondHand') : t('menu.returns.register')
            }
            handlePrevious={handleSteps!.onCancel}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
        >
            <BackgroundWithScanAndFooter
                footer={renderButton()}
                scanProps={{ onSubmit: handleScan, placeholder: t('returnParcel.scanProductInputPlaceholder') }}
            >
                <div className={classes.content}>
                    {parcel.shipment.rightOfWithdrawal && (
                        <Pill
                            label={t('rightOfWithdrawal.label')}
                            variant="stroke"
                            className={classes.rightOfWithdrawalChip}
                            isSmall
                        />
                    )}
                    <BlueBackgroundHeader
                        title={t('returnParcel.parcelSectionTitle')}
                        tagline={t('returnParcel.parcelSectionTagline')}
                        rightAddon={customer?.name && <Pill label={customer.name} variant="basic" />}
                        className={classes.header}
                    />

                    {productsToProcessCount === 0 && (
                        <Tips className={classes.tips}>
                            {t('parcel.scan.scanIfNoProduct', { barcode: t('parcel.scan.barcode') })}
                        </Tips>
                    )}

                    <ListWithCounter
                        counterProps={{ current: productsToProcessCount, total: totalProductsCount, variant: 'stroke' }}
                        title={t('returnParcel.toProcessProducts')}
                    >
                        {pickableUniqueProducts.map((uniqueProduct, index) => (
                            <RentalProductCard
                                key={index}
                                name={uniqueProduct.productReference.name}
                                barcodeUid={uniqueProduct.barcodeUid}
                                eans={uniqueProduct.productReference.eans}
                                status={UniqueProductStatusFront.TO_PROCESS}
                                image={uniqueProduct.productReference.image}
                                onClick={() => selectUniqueProduct(uniqueProduct, true)}
                            />
                        ))}
                    </ListWithCounter>

                    <ListWithCounter
                        counterProps={{
                            current: processedProductsCount,
                            total: totalProductsCount,
                            variant: processedProductsCount === totalProductsCount ? 'fill' : 'stroke',
                        }}
                        title={t('returnParcel.processedProducts')}
                    >
                        {processedUniqueProducts!.map((uniqueProduct: IUniqueProduct, index: number) => (
                            <RentalProductCard
                                key={index}
                                name={uniqueProduct.productReference.name}
                                barcodeUid={uniqueProduct.barcodeUid}
                                eans={uniqueProduct.productReference.eans}
                                status={uniqueProduct.status}
                                image={uniqueProduct.productReference.image}
                                handleEditClick={
                                    hasReturnAddon ? () => setSelectedUniqueProduct(uniqueProduct) : undefined
                                }
                                handleCommentClick={() =>
                                    hasReturnAddon
                                        ? setEditReturnItem(
                                              uniqueProduct?.returnItems ? uniqueProduct?.returnItems[0] : undefined,
                                          )
                                        : undefined
                                }
                            />
                        ))}
                    </ListWithCounter>

                    {hasReturnAddon && (
                        <CheckPackaging
                            parcel={parcel}
                            setParcel={setParcel}
                            isPackagingChecked={isPackagingChecked}
                            setIsPackagingChecked={setIsPackagingChecked}
                            className={classes.packaging}
                        />
                    )}
                </div>
            </BackgroundWithScanAndFooter>

            {selectedUniqueProduct && (
                <UniqueProductProcessingDrawer
                    isOpen={!!selectedUniqueProduct?.barcodeUid}
                    uniqueProduct={selectedUniqueProduct!}
                    onSubmit={onPickUniqueProductStatus}
                    onCancel={() => setSelectedUniqueProduct(undefined)}
                    addComment={() =>
                        setEditReturnItem(
                            selectedUniqueProduct?.returnItems ? selectedUniqueProduct?.returnItems[0] : undefined,
                        )
                    }
                />
            )}
            {isIssueModalOpened && (
                <ProductIssueModal
                    isOpen={isIssueModalOpened}
                    uniqueProduct={selectedUniqueProduct}
                    handleClose={() => setIsIssueModalOpened(false)}
                    handleSubmit={submitIssue}
                />
            )}
            {editReturnItem && (
                <AddCommentModal
                    isOpen={true}
                    returnItem={editReturnItem}
                    handleClose={() => setEditReturnItem(undefined)}
                    handleSubmit={(returnItem: ReturnItem) => editReturnCondition(returnItem)}
                />
            )}
        </Page>
    )
}

export default ProcessProductsStep
