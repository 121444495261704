import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, Select } from '@material-ui/core'
import { MenuProps as MenuPropsType } from '@material-ui/core/Menu'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 160,
        backgroundColor: theme.palette.neutral[50],
        border: `1px solid ${theme.palette.neutral[300]}`,
        borderRadius: 2,
        transition: 'all 0.2s ease-out',
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${theme.space(1)}px`,
        '&::before': {
            display: 'none',
        },
        '&:hover': {
            borderColor: theme.palette.neutral[400],
            borderWidth: '1px',
        },
        '&:focus-within': {
            borderColor: theme.palette.primary[500],
        },
        '& .MuiInput-underline': {
            '&:after': {
                content: '',
                borderBottom: 'none',
            },
        },
        '& .MuiInputBase-input': {
            paddingLeft: theme.space(3),
            height: '100%',
            display: 'flex',
            paddingTop: 0,
            paddingBottom: 0,
            alignItems: 'center',
            backgroundColor: 'none',
        },
    },
    selectIcon: {
        marginRight: '5%',
        marginTop: '3%',
    },
    renderValue: {
        color: theme.palette.neutral[700],
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
    },
    placeholder: {
        color: theme.palette.neutral[500],
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
    },
    selectMenu: {
        bottom: 0,
    },
    dropdown: {
        padding: 0,
        '& :hover': {
            backgroundColor: theme.palette.primary[50],
        },
    },
}))

type SimpleSelectProps = {
    value: number | ''
    possibleSelections: { value: any; label: string }[]
    handleChange: (value: any) => void
    placeholder?: string
    formatSelectedValue?: (label: string) => string
    className?: string
    dataTestId?: string
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
    value,
    possibleSelections,
    handleChange,
    placeholder,
    className,
    dataTestId,
    formatSelectedValue,
}) => {
    const classes = useStyles()

    function formatValue(value: number) {
        const label = possibleSelections.find((possibleSelection) => possibleSelection.value === value)!.label
        if (formatSelectedValue) {
            return formatSelectedValue(label)
        }
        return label
    }

    const menuProps: Partial<MenuPropsType> = {
        classes: {
            list: classes.dropdown,
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }
    return (
        <Select
            disableUnderline
            value={value}
            displayEmpty
            onChange={handleChange}
            className={`${classes.input} ${className}`}
            MenuProps={menuProps}
            data-testid={dataTestId}
            IconComponent={(props) => <FontAwesomeIcon icon={faAngleDown} {...props} />}
            classes={{
                icon: classes.selectIcon,
            }}
            renderValue={() => (
                <div className={value === '' ? classes.placeholder : classes.renderValue}>
                    {value === '' ? placeholder : formatValue(value)}
                </div>
            )}
        >
            {possibleSelections.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    )
}

export default SimpleSelect
