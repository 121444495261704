import * as React from 'react'
import { useState } from 'react'
import ReportIssueModal from '_organisms/ReportIssueModal'
import { IRefitStep, UniqueProductStatus } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { EntityType, RMSFile } from 'utils/files'
import rmsApi from 'utils/api'
import { CREATE_REFIT_ISSUE } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import { BasicCheckboxProps } from '_atoms/inputs/Checkbox'
import { RadioGroupProps } from '_atoms/inputs/RadioGroup'

type IssueStatus = UniqueProductStatus.OUT_OF_ORDER | UniqueProductStatus.TO_REPAIR

export type RefitIssueModalProps = {
    isOpen: boolean
    refitStep: IRefitStep
    refitOperationId: number
    handleClose: () => void
    goToHomePage: () => void
}

const RefitIssueModal: React.FC<RefitIssueModalProps> = ({
    isOpen,
    refitStep,
    handleClose,
    refitOperationId,
    goToHomePage,
}) => {
    const { t } = useTranslation()

    const [status, setStatus] = useState<IssueStatus>(UniqueProductStatus.OUT_OF_ORDER)
    const [continueRefit, setContinueRefit] = useState(true)

    const { uniqueProduct, productIssues } = refitStep

    async function handleSubmit(comment: string, issues: number[], files: RMSFile[]) {
        const payload = {
            refitOperationId: refitOperationId,
            barcodeUid: uniqueProduct.barcodeUid,
            status: status,
            comment,
            shouldAbortOperation: !continueRefit,
            productIssueIds: issues,
            files:
                files?.map((file) => ({
                    name: file.remoteName,
                    type: file.type,
                })) || [],
        }

        try {
            await rmsApi.post(CREATE_REFIT_ISSUE(refitOperationId), payload)
            if (!continueRefit) {
                goToHomePage()
            } else {
                handleClose()
            }
        } catch (e: any) {
            toast.error(t('parcel.productUpdateError', { error: e }))
        }
    }

    const checkboxProps: BasicCheckboxProps = {
        label: t('new_refit.instruction.reportIssue.continueRefit'),
        checked: continueRefit,
        setValue: setContinueRefit,
        dataTestId: 'continueRefit-checkbox',
    }

    const radioProps: RadioGroupProps = {
        options: [
            { label: t('uniqueProductStatus.OUT_OF_ORDER'), value: UniqueProductStatus.OUT_OF_ORDER },
            { label: t('uniqueProductStatus.TO_REPAIR'), value: UniqueProductStatus.TO_REPAIR },
        ],
        value: status,
        onChange: setStatus,
        name: 'productStatus',
    }

    return (
        <ReportIssueModal
            isOpen={isOpen}
            tagline={t('reportIssue.tagline.product')}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            initialFiles={[]}
            initialComment=""
            initialIssues={[]}
            issueOptions={productIssues}
            checkboxProps={checkboxProps}
            radioProps={radioProps}
            entityType={EntityType.REFIT_ISSUE}
        />
    )
}

export default RefitIssueModal
