import * as React from 'react'

import { MenuItem, makeStyles, createStyles, Theme, Button, Menu } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        button: {
            width: theme.spacing(6),
            minWidth: theme.spacing(6),
            height: theme.spacing(3),
            paddingBottom: '22px',
            fontSize: theme.spacing(4),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[800],
            [theme.breakpoints.up('sm')]: {
                opacity: '45%',
            },
            borderRadius: '2px',
            '&:disabled': {
                backgroundColor: theme.palette.grey[300],
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[800],
                opacity: '100%',
            },
        },
    }),
)

type PropsType = {
    label: string
    pickable?: {
        label: string
        action: () => void
    }[]
}

const DropDownButton: React.FC<PropsType> = ({ label, pickable = [] }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelectAction = (action: () => void) => {
        setAnchorEl(null)
        action()
    }

    return (
        <>
            <Button
                className={classes.button}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disabled={pickable.length === 0}
            >
                {label}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {pickable.map((pickableAction, index) => (
                    <MenuItem key={index} onClick={() => handleSelectAction(pickableAction.action)}>
                        {pickableAction.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default DropDownButton
