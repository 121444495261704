import * as React from 'react'
import { useState } from 'react'
import Page from '_organisms/Page'
import { useTranslation } from 'react-i18next'
import { PaginationInfos, SearchFieldDefaultFilterKey } from 'interfaces/Table.interfaces'
import rmsApi from 'utils/api'
import { SECOND_HAND_POLICY_BOOK_URL, SECOND_HAND_REQUEST_BY_FILTERS_URL } from 'utils/routes/backend'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { ParcelStatus, UniqueProductStatus } from 'interfaces'
import MobileSearchFilter from '_organisms/tableV2/filters/MobileSearchFilter'
import CheckboxListFilter from '_organisms/tableV2/filters/CheckboxListFilter'
import Status from '_atoms/badges/Status'
import TableV2 from '_organisms/tableV2/TableV2'
import { toast } from 'utils/toast'
import { useNavigate } from 'react-router-dom'
import { TAKEBACK_LIST_ROUTE } from 'utils/routes/frontend'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import { SecondHandRequestStatus, SecondHandRequestStatusType } from '../../interfaces/SecondHand.interface'
import Pill from '../../_atoms/badges/Pill'
import DatePickerFilter from '../../_organisms/tableV2/filters/DatePickerFilter'

interface TableRowTakeback {
    hasRefused: JSX.Element
    secondHandRequestId: number
    renterName: string
    renterEmail: string
    takebackDate: string
    secondHandRequestStatus: JSX.Element
    customerName: string
    voucherLocation: string
}

const TakebackTable: React.FC = () => {
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()
    const navigate = useNavigate()

    const selectedCustomers = useSelectedCustomersStore((state: SelectedCustomersState) => state.selectedCustomers)
    const [takebacks, setTakebacks] = useState<TableRowTakeback[]>([])

    const secondHandRequestStatusOptions = Object.keys(SecondHandRequestStatus).map((status) => ({
        value: status,
        label: t(`secondHandRequestStatus.${status}`),
    }))

    const defaultStartDate = dayjs().startOf('year')
    const defaultEndDate = dayjs().add(15, 'day')

    async function buildRows(secondHandRequests: any) {
        const rows: TableRowTakeback[] = []

        for (const secondHandRequest of secondHandRequests) {
            const hasUniqueProducts = !!secondHandRequest.shipment.uniqueProducts?.length

            let hasRefused = <></>
            if (hasUniqueProducts) {
                const hasOneProductRefused = secondHandRequest.shipment.uniqueProducts?.find(
                    (uniqueProduct: any) => uniqueProduct.status === UniqueProductStatus.REFUSED,
                )

                hasRefused = (
                    <Pill
                        id={`${secondHandRequest.id}_hasRefused`}
                        key={secondHandRequest.id}
                        label={hasOneProductRefused ? t('global.yes').toUpperCase() : t('global.no').toUpperCase()}
                        color={hasOneProductRefused ? 'warning' : 'neutral'}
                        isSmall
                    />
                )
            }

            const secondHandRequestId = secondHandRequest.id
            const renterName = secondHandRequest.renter
                ? `${secondHandRequest.renter.firstName} ${secondHandRequest.renter.lastName}`
                : ''
            const renterEmail = secondHandRequest.renter ? secondHandRequest.renter.email : ''
            const customerName = secondHandRequest.renter.customer.name
            const takebackDate = dayjs(secondHandRequest.createdAt).format('DD/MM/YY')
            const voucherLocation = secondHandRequest.voucherLocation

            let status = SecondHandRequestStatus.PENDING
            const parcelStatuses = []

            for (const parcel of secondHandRequest.shipment.parcels) {
                parcelStatuses.push(parcel.status)
            }

            if (parcelStatuses.includes(ParcelStatus.PROCESSED)) status = SecondHandRequestStatus.IN_PROGRESS
            if (parcelStatuses.every((status) => status === ParcelStatus.PROCESSED))
                status = SecondHandRequestStatus.PROCESSED

            const secondHandRequestStatus = (
                <Status label={t(`secondHandRequestStatus.${status}`)} color={SecondHandRequestStatusType[status]} />
            )

            rows.push({
                hasRefused,
                secondHandRequestId,
                renterName,
                renterEmail,
                takebackDate,
                secondHandRequestStatus,
                customerName,
                voucherLocation,
            })
        }

        setTakebacks(rows)
    }

    function buildSearchParams(filters: any) {
        const search = filters.searchField ? `&search=${filters.searchField}` : ''

        const buildCustomersParams = (name: string) =>
            `&customerIds[]=${selectedCustomers.find((customer) => customer.name === name)?.id}`
        const customers = filters.customers?.map(buildCustomersParams).join('') || ''

        const buildStatusesParams = (label: string) =>
            `&statuses[]=${secondHandRequestStatusOptions.find((status) => status.label === label)?.value}`
        const statuses = filters.statuses?.map(buildStatusesParams).join('') || ''

        const hasRefused = filters.hasRefused?.length ? `&hasRefused=1` : ''
        const from = `&from=${(filters.dateRange?.startDate || defaultStartDate).format('YYYY-MM-DD')}`
        const to = `&to=${(filters.dateRange?.endDate || defaultEndDate).format('YYYY-MM-DD')}`

        return `${search}${customers}${statuses}${hasRefused}${from}${to}`
    }

    async function fetchTakebacks(
        filters: any,
        currentPage: number,
        itemsPerPage: number,
        setPagination: (p: PaginationInfos) => void,
    ): Promise<void> {
        const params = buildSearchParams(filters)

        try {
            const request = await rmsApi.get(
                `${SECOND_HAND_REQUEST_BY_FILTERS_URL}?page=${currentPage}&limit=${itemsPerPage}${params}`,
            )
            const { items, meta } = request.data

            if (meta) setPagination(meta)
            await buildRows(items)
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    async function downloadPolicyBook() {
        try {
            const downloadDate = dayjs().format('YYYY-MM-DD-HH-mm-ss')
            const policyBookToPdf = await rmsApi.get(SECOND_HAND_POLICY_BOOK_URL)

            const arrayBuffer = new Uint8Array(policyBookToPdf.data.data)
            const blob = new Blob([arrayBuffer])

            fileDownload(blob, `policy-book-${downloadDate}.pdf`, 'application/pdf')

            toast.success(t('takebacks.policyBook.downloadSuccess'))
        } catch (e: any) {
            console.error(e.message)
            toast.error(t('takebacks.policyBook.downloadError'))
        }
    }

    function defaultAction() {
        return {
            label: t('takebacks.policyBook.exportPolicyBook'),
            action: () => downloadPolicyBook(),
        }
    }

    const header = [
        { value: 'hasRefused', label: 'takebacks.refused' },
        { value: 'secondHandRequestId', label: 'takebacks.takebackNumber' },
        { value: 'renterName', label: 'takebacks.clientName' },
        { value: 'renterEmail', label: 'takebacks.clientEmail' },
        { value: 'takebackDate', label: 'takebacks.takebackDate' },
        { value: 'secondHandRequestStatus', label: 'shipments.columnStatus' },
        { value: 'customerName', label: 'takebacks.brand' },
        { value: 'voucherLocation', label: 'takebacks.compensationType' },
    ]

    const filterOptions = (filters: any) => [
        {
            filterKey: SearchFieldDefaultFilterKey,
            label: t('table.searchField'),
            component: <MobileSearchFilter placeholder={t('wms.productReferences.searchFieldPlaceholder')} />,
        },
        {
            filterKey: 'customers',
            label: t('takebacks.filters.customers'),
            component: (
                <CheckboxListFilter
                    options={selectedCustomers.map((customer) => customer.name!) || []}
                    filteredOptions={filters?.customers || []}
                />
            ),
        },
        {
            filterKey: 'statuses',
            label: t('takebacks.filters.statuses'),
            component: (
                <CheckboxListFilter
                    options={secondHandRequestStatusOptions.map((status) => status.label) || []}
                    filteredOptions={filters?.statuses || []}
                />
            ),
        },
        {
            filterKey: 'hasRefused',
            label: t('takebacks.filters.hasRefused'),
            component: <CheckboxListFilter options={[t('global.yes')]} filteredOptions={filters?.hasRefused || ''} />,
        },
        {
            filterKey: 'dateRange',
            label: t('returns.filters.dateRange'),
            component: (
                <DatePickerFilter
                    startDate={filters?.dateRange?.startDate || defaultStartDate}
                    endDate={filters?.dateRange?.endDate || defaultEndDate}
                    range
                />
            ),
        },
    ]

    const defaultFilters = { dateRange: { startDate: defaultStartDate, endDate: defaultEndDate } }

    return (
        <Page title={t('menu.takebacks.takebackList')} section={t('menu.returns.title')} contentPaddingTopDesktop={2}>
            <TableV2
                fetchRows={fetchTakebacks}
                initialHeader={header}
                rows={takebacks}
                filterOptions={filterOptions}
                defaultAction={defaultAction}
                searchFieldPlaceholder={t('takebacks.searchInput.placeholder')}
                onRowClick={(row) => navigate(`${TAKEBACK_LIST_ROUTE}/${row.secondHandRequestId}/details`)}
                defaultFilters={defaultFilters}
                localStorageName="takebackTable"
            />
        </Page>
    )
}

export default TakebackTable
