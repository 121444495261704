import { ReactElement } from 'react'
import * as React from 'react'
import { Menu, IconButton, makeStyles, Theme, createStyles, useMediaQuery, useTheme, Button } from '@material-ui/core'
import { Sort } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        container: {
            display: 'inline-flex',
        },
        iconButton: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        selector: {
            color: theme.palette.grey[600],
        },
        popperContent: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
)

type StatusProps = {
    children: ReactElement[] | ReactElement
    implicitClose?: boolean
    className?: string
}

const TableHeaderSelector: React.FC<StatusProps> = ({ children, className = '', implicitClose = true }) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null)
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { t } = useTranslation()

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <div>
            {isMobile ? (
                <Button size="small" variant="contained" color="primary" onClick={handlePopoverOpen} endIcon={<Sort />}>
                    {t('table.filter')}
                </Button>
            ) : (
                <IconButton className={classes.iconButton} onClick={handlePopoverOpen} data-testid="tableFilter">
                    <Sort className={classes.selector + ' MuiSvgIcon-root'} />
                </IconButton>
            )}
            <Menu
                className={className}
                id="simple-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={open}
                onClose={handlePopoverClose}
            >
                <div data-testid="tableHeaderSelectorMenu">
                    {implicitClose
                        ? React.Children.map(children, (child: React.ReactElement) =>
                              React.cloneElement(child, { handleMenuClose: handlePopoverClose }),
                          )
                        : children}
                </div>
            </Menu>
        </div>
    )
}

export default TableHeaderSelector
