import * as React from 'react'
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            height: '1.5em',
            width: '1.5em',
            color: theme.palette.grey[600],
            marginRight: theme.spacing(0.5),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(0.25),
                height: '1.3em',
                width: '1.3em',
            },
        },
        variantDetail: {
            marginTop: theme.spacing(1),
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
        },
        variantDetailTitle: {
            display: 'flex',
            flexWrap: 'nowrap',
        },
        label: {
            textTransform: 'uppercase',
            fontWeight: 400,
            color: theme.palette.grey[700],
            marginRight: theme.spacing(1),
            lineHeight: '1.9',
        },
        value: {
            fontWeight: 800,
        },
        uppercase: {
            textTransform: 'uppercase',
        },
    }),
)

type ProductVariantDetailProps = { label: string; value?: any; icon?: JSX.Element; valueUppercase?: boolean }

const ProductVariantDetail: React.FC<ProductVariantDetailProps> = ({
    label,
    value,
    icon,
    valueUppercase = true,
    children,
}) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.variantDetail} spacing={2}>
            <div className={classes.variantDetailTitle}>
                {icon && React.cloneElement(icon, { className: classes.icon })}
                <Typography className={classes.label} variant="h6" component="p">
                    {label} :
                </Typography>
            </div>
            {value && (
                <Typography
                    className={`${classes.value} ${valueUppercase ? classes.uppercase : ''}`}
                    variant="h6"
                    component="p"
                >
                    {value}
                </Typography>
            )}
            {children}
        </Grid>
    )
}

export default ProductVariantDetail
