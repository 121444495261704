import * as React from 'react'
import { Box, createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import FitText from '@kennethormandy/react-fittext'

const useHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleContainer: {
            display: 'inline-flex',
        },
        title: {
            fontFamily: theme.typography.fontFamily,
            color: '#484E5A',
            fontWeight: 500,
        },
        mobileTitle: {
            width: '100%',
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 500,
        },
        sectionTitle: {
            color: theme.palette.neutral[600],
            fontWeight: 100,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: '45px',
            verticalAlign: 'middle',
            [theme.breakpoints.down('sm')]: {
                color: 'rgba(255, 255, 255, 0.75)',
            },
        },
        sectionDivider: {
            display: 'inline-block',
            width: '11px',
            height: '45px',
            margin: theme.spacing(0, 1.75),
            color: theme.palette.neutral[200],
            fontWeight: 250,
            fontSize: theme.typography.pxToRem(30),
            lineHeight: '45px',
            verticalAlign: 'middle',
            transform: 'scaleX(0.5) rotate(15deg)',
            [theme.breakpoints.down('sm')]: {
                color: 'rgba(255, 255, 255, 0.75)',
            },
        },
        small: {
            fontSize: theme.typography.pxToRem(12),
        },
        medium: {
            fontSize: theme.typography.pxToRem(14),
        },
        large: {
            fontSize: theme.typography.pxToRem(18),
        },
    }),
)

type HeaderTitleProps = {
    className?: string
    children: string | JSX.Element
    section?: string
    [typographyProps: string]: any
}

// Header Title is the biggest title we find, it's located in app's header
export const HeaderTitle: React.FC<HeaderTitleProps> = ({ className, children, section, ...typographyProps }) => {
    const classes = useHeaderStyles()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Typography
            className={`${classes.titleContainer} ${className}`}
            variant="h1"
            component="div"
            data-testid="title"
            {...typographyProps}
        >
            {isMobile ? (
                <Box className={classes.mobileTitle}>{children}</Box>
            ) : (
                <FitText compressor={2} minFontSize={12} maxFontSize={22}>
                    <>
                        {section && (
                            <>
                                <span className={classes.sectionTitle}>{section}</span>
                                <span className={classes.sectionDivider}>/</span>
                            </>
                        )}
                        {children}
                    </>
                </FitText>
            )}
        </Typography>
    )
}

type H1TitleProps = {
    className?: string
    children: string | JSX.Element
    [typographyProps: string]: any
}

// H1Title Title is the biggest title we find, it's located in app's H1Title
export const H1Title: React.FC<H1TitleProps> = ({ className, children, ...typographyProps }) => {
    return (
        <Typography className={className} variant="h1" component="div" data-testid="title" {...typographyProps}>
            <FitText compressor={2} minFontSize={12} maxFontSize={25}>
                {children}
            </FitText>
        </Typography>
    )
}
