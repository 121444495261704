import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InsertProduct from './InsertProduct'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { CHECK_IF_EAN_EXISTS_URL, WMS_ADD_UNIQUE_PRODUCT_URL } from 'utils/routes/backend'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'
import FinishedInsert from './FinishedInsert'
import SelectTypeAndEan from './SelectTypeAndEan'
import Stepper from '_organisms/Stepper'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

export enum ProductType {
    RENTAL = 'RENTAL',
    SECOND_HAND = 'SECOND_HAND',
}

export type newProductsType = {
    barcode: string
    quality?: string
}[]

const InsertProductHome: React.FC = () => {
    const { t } = useTranslation()

    const loadPrismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.loadPrismicQualities)
    const [productType, setProductType] = useState<ProductType>(ProductType.RENTAL)
    const [ean, setEan] = useState<string>('')
    const [step, setStep] = useState<number>(0)
    const [setDisabledSelectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.setDisabledSelectedCustomers,
    ])
    const [setDisabledSelectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.setDisabledSelectedWarehouse,
    ])

    const checkEanAndUpdate = async (ean: string) => {
        toast.info(t('wms.scanUniqueProduct.loading'))

        if (ean === '') {
            toast.dismiss()
            toast.error(t('wms.scanUniqueProduct.eanEmpty'))

            return
        }

        try {
            const eanRequest = await rmsApi.get(`${CHECK_IF_EAN_EXISTS_URL}/${ean}`)
            const eanInfos = eanRequest.data

            toast.dismiss()

            if (eanInfos) {
                setEan(ean)
                const prismicTemplate = eanInfos.productReference?.product?.customer?.prismicTemplate

                if (prismicTemplate) {
                    loadPrismicQualities(prismicTemplate)
                }

                setStep(1)
            } else {
                toast.error(t('wms.scanUniqueProduct.NOT_FOUND', { EAN: ean }))
            }
        } catch (e: any) {
            const err =
                e.response?.data?.error?.toUpperCase() === 'NOT FOUND'
                    ? 'wms.scanUniqueProduct.eanDoesNotExist'
                    : 'wms.scanUniqueProduct.unknownError'
            if (err) toast.error(t(err, { EAN: ean }))
        }
    }

    async function addUniqueProduct(puidList: newProductsType): Promise<void> {
        const payload = {
            productType: productType,
            productEan: ean,
            products: puidList,
        }

        try {
            await rmsApi.post(WMS_ADD_UNIQUE_PRODUCT_URL, payload)
            setStep(2)
        } catch (e: any) {
            const err =
                e.response?.data?.error?.toUpperCase() === 'NOT FOUND'
                    ? 'wms.scanUniqueProduct.eanDoesNotExist'
                    : 'wms.scanUniqueProduct.unknownError'
            if (err) toast.error(t(err, { EAN: payload.productEan }))
        }
    }

    function goToHome() {
        setEan('')
        setProductType(ProductType.RENTAL)
        setStep(0)
    }

    function setTypeFromTab(tabNumber: number) {
        if (tabNumber === 0) setProductType(ProductType.RENTAL)
        if (tabNumber === 1) setProductType(ProductType.SECOND_HAND)
    }

    useEffect(() => {
        if (step !== 0) {
            setDisabledSelectedCustomers(true)
            setDisabledSelectedWarehouse(true)
        } else {
            setDisabledSelectedCustomers(false)
            setDisabledSelectedWarehouse(false)
        }
    }, [step])

    return (
        <Stepper
            currentStep={step}
            setCurrentStep={setStep}
            onSubmit={(puidList: newProductsType) => {
                addUniqueProduct(puidList)
            }}
            onCancel={() => goToHome()}
        >
            <SelectTypeAndEan checkEanAndUpdate={checkEanAndUpdate} setTypeFromTab={setTypeFromTab} />
            <InsertProduct productType={productType} ean={ean} goToHome={goToHome} />
            <FinishedInsert productType={productType} ean={ean} goToHome={goToHome} />
        </Stepper>
    )
}

export default InsertProductHome
