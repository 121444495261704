import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { UniqueProductStatus } from 'interfaces'
import Button from '_atoms/buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faEdit } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        productAddon: {
            display: 'flex',
            justifyContent: 'start',
            gap: `${theme.space(1)}px`,
            [theme.breakpoints.up('md')]: {
                marginTop: theme.space(2),
            },
        },
        editButtons: {
            display: 'inline-flex',
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    }),
)

type ReturnAddonProps = {
    handleEdit: () => void
    handleComment: () => void
    status?: UniqueProductStatus
    barcodeUid: string
}

const ReturnAddon: React.FC<ReturnAddonProps> = ({ handleEdit, handleComment, status, barcodeUid }) => {
    const classes = useStyles()
    const withCommentButton = status === UniqueProductStatus.TO_REFIT || status === UniqueProductStatus.TO_RESTOCK

    return (
        <div className={classes.productAddon}>
            {withCommentButton && (
                <Button
                    variant="stroke"
                    shape="squared"
                    color="neutral"
                    size="small"
                    data-testid={`commentProcessedProduct_${barcodeUid}`}
                    onClick={handleComment}
                >
                    <FontAwesomeIcon icon={faComment} />
                </Button>
            )}
            <Button
                variant="stroke"
                shape="squared"
                color="neutral"
                size="small"
                data-testid={`editProcessedProduct_${barcodeUid}`}
                onClick={handleEdit}
            >
                <FontAwesomeIcon icon={faEdit} />
            </Button>
        </div>
    )
}

export default ReturnAddon
