import * as React from 'react'
import { Chip, createStyles, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { useTranslation } from 'react-i18next'
import Chart from './Chart'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            paddingLeft: theme.spacing(3),
            '& .MuiTypography-root': {
                '& .MuiSvgIcon-root': {
                    paddingLeft: theme.spacing(1),
                    height: '1.25em',
                    width: '1.25em',
                    verticalAlign: '-20%',
                },
            },
            '& .MuiTypography-gutterBottom': {
                marginTop: '1em',
                marginBottom: '1em',
            },
        },
        incomesBig: {
            color: theme.palette.secondary.main,
            fontSize: theme.typography.pxToRem(48),
            lineHeight: 1,
            fontWeight: 700,
            '& small': {
                fontWeight: 400,
                color: theme.palette.common.black,
                textTransform: 'uppercase',
                fontSize: theme.typography.pxToRem(24),
            },
        },
        incomesSmall: {
            color: theme.palette.grey[700],
            fontSize: theme.typography.pxToRem(32),
            lineHeight: 1,
            fontWeight: 700,
            '& small': {
                textTransform: 'uppercase',
                fontSize: theme.typography.pxToRem(12),
            },
            '& .MuiChip-root': {
                marginLeft: theme.spacing(1),
            },
        },
    }),
)

type RightSectionProps = {
    title: string
    tooltipTitle: string
    bigValue: string | null | undefined
    smallValue: string | null | undefined
}

const RightSection: React.FC<RightSectionProps> = ({ title, tooltipTitle, bigValue, smallValue }) => {
    const classes = useStyles()

    return (
        <div className={classes.sectionHeader}>
            <Typography variant="h4" component="h2" gutterBottom>
                {title}
                <Tooltip arrow placement="top" title={tooltipTitle}>
                    <InfoIcon />
                </Tooltip>
            </Typography>
            <Typography component="h3" className={classes.incomesBig}>
                {bigValue || null} € <small>TTC</small>
            </Typography>
            <Typography component="h3" className={classes.incomesSmall}>
                {smallValue || null} € <small>HT</small>
                <Chip component="span" size="small" variant="outlined" label="TVA 20%" />
            </Typography>
        </div>
    )
}

type SectionHeaderProps = {
    overview: { rawIncome: any; netIncome: any; monthlyPayments: any } | null
}
const SectionHeader: React.FC<SectionHeaderProps> = ({ overview }) => {
    const { t } = useTranslation()
    const { rawIncome, netIncome, monthlyPayments } = overview || {}
    return (
        <>
            <Grid item>
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <Chart title={t('finances.incomes.year')} data={monthlyPayments} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RightSection
                            title={t('finances.incomes.raw')}
                            tooltipTitle={t('finances.incomes.rawHelp')}
                            bigValue={rawIncome?.raw}
                            smallValue={rawIncome?.net}
                        />
                        <RightSection
                            title={t('finances.incomes.net')}
                            tooltipTitle={t('finances.incomes.netHelp')}
                            bigValue={netIncome?.raw}
                            smallValue={netIncome?.net}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SectionHeader
