import create from 'zustand'
import { ICustomer } from 'interfaces/Customer'
import { IWarehouse } from 'interfaces/Warehouse'

export interface IParcelShop {
    id: number
    name: string
    address: string
    addressDetails: string
    zipcode: string
    country: string
}

export interface User {
    name?: string
    email?: string
    warehouse: IWarehouse
    warehouses: IWarehouse[]
    parcelShop?: IParcelShop
    customers: ICustomer[]
}
export interface ConnectedUserState {
    user: User
    setUser: (user: User) => void
}

const defaultUser: User = {
    name: 'User',
    customers: [] as ICustomer[],
    warehouse: {
        id: 0,
        name: '',
        defaultProductNumberByBatch: 30,
        calendlyToken: '',
        hasCalendlyToken: false,
    },
    warehouses: [] as IWarehouse[],
}

const useConnectedUserStore = create<ConnectedUserState>()((set) => ({
    user: defaultUser,
    setUser: (user) => set(() => ({ user })),
}))

export default useConnectedUserStore
