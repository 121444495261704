import { Navigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js'
import * as React from 'react'

interface Props {
    component: React.ComponentType
    path?: string
    roles: string[]
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, roles }) => {
    const { keycloak } = useKeycloak<KeycloakInstance>()

    const isAuthorized = (roles: string[]) => {
        if (keycloak && roles) {
            return roles.some((r) => {
                const realm = keycloak.hasRealmRole(r)
                const resource = keycloak.hasResourceRole(r)
                return realm || resource
            })
        }
        return false
    }

    if (isAuthorized(roles)) {
        return <RouteComponent />
    }

    return <Navigate to="/" />
}
