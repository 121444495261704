import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import rmsApi from 'utils/api'
import { EntityType, RMSFile } from 'utils/files'
import { toast } from 'utils/toast'
import { IParcel } from 'interfaces'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import Button from '_atoms/buttons/Button'
import ReportIssueModal from '_organisms/ReportIssueModal'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: theme.space(0, 5),
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.space(1),
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.space(5),
            },
        },
    }),
)

type CheckPackagingProps = {
    parcel: IParcel
    isPackagingChecked: boolean
    setIsPackagingChecked: (isPackagingChecked: boolean) => void
    className?: string
    setParcel: (parcel: IParcel) => void
}

const CheckPackaging: React.FC<CheckPackagingProps> = ({
    parcel,
    isPackagingChecked,
    setIsPackagingChecked,
    className,
    setParcel,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [isIssueModalOpen, setIsIssueModalOpen] = useState<boolean>(false)

    async function postPackagingIssue(comment?: string, files?: RMSFile[]) {
        const payload = {
            files:
                files?.map((file: RMSFile) => ({
                    name: file.remoteName,
                    type: file.type,
                })) || [],
            comment: comment,
        }

        try {
            const returnPackaging = await rmsApi.post(`app/parcels/${parcel.id}/process-packaging-return`, payload)
            const parcelCopy = Object.assign({}, parcel)
            parcelCopy.returnPackaging = returnPackaging.data
            setParcel(parcelCopy)
            setIsIssueModalOpen(false)
            setIsPackagingChecked(true)
        } catch (e: any) {
            toast.error(t('reportIssue.postPackagingIssueError', e))
        }
    }

    return (
        <>
            <div className={className}>
                <BlueBackgroundHeader
                    title={t('returnParcel.packagingIssueTitle')}
                    tagline={t('returnParcel.packagingIssueTagline')}
                    className={classes.header}
                />
                <Button
                    color="warning"
                    onClick={() => setIsIssueModalOpen(true)}
                    startIcon={faWarning}
                    disabled={isPackagingChecked}
                    data-testid="reportPackagingIssue"
                    className={classes.button}
                >
                    {t('returnParcel.packagingIssueButton')}
                </Button>
            </div>
            {isIssueModalOpen && (
                <ReportIssueModal
                    isOpen={isIssueModalOpen}
                    tagline={t('reportIssue.tagline.product')}
                    handleClose={() => setIsIssueModalOpen(false)}
                    handleSubmit={(comment: string, _selectedIssues: number[], files: RMSFile[]) =>
                        postPackagingIssue(comment, files)
                    }
                    initialFiles={[]}
                    initialComment=""
                    entityType={EntityType.RETURN_ITEM_OR_RETURN_PACKAGING}
                />
            )}
        </>
    )
}

export default CheckPackaging
