import * as React from 'react'
import { createStyles, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'

type StyleProps = {
    clickable: boolean
    hasContent: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.palette.primary[100]}`,
            borderRadius: theme.space(4 / 5),
            cursor: ({ clickable }) => (clickable ? 'pointer' : 'default'),
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
            '&:hover': {
                borderColor: ({ clickable }) => (clickable ? theme.palette.primary[200] : theme.palette.primary[100]),
            },
        },
        header: {
            padding: theme.space(2),
            backgroundColor: theme.palette.common.white,
            minHeight: theme.space(12),
            [theme.breakpoints.down('md')]: {
                borderRadius: (props: StyleProps) =>
                    props.hasContent ? theme.space(4 / 5, 4 / 5, 0, 0) : theme.space(4 / 5),
            },
            [theme.breakpoints.up('md')]: {
                width: theme.space(68),
                padding: theme.space(2, 5),
                minHeight: theme.space(13),
                borderRadius: theme.space(4 / 5, 0, 0, 4 / 5),
            },
        },
        content: {
            padding: theme.space(3, 2),
            backgroundColor: theme.palette.primary[50],
            borderRadius: theme.space(0, 0, 3 / 5, 3 / 5),
            [theme.breakpoints.up('md')]: {
                flex: 1,
                padding: theme.space(3),
                borderRadius: (props: StyleProps) =>
                    props.clickable ? theme.space(0) : theme.space(0, 3 / 5, 3 / 5, 0),
            },
        },
        clickable: {
            width: theme.space(6),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                color: theme.palette.primary[600],
            },
        },
    }),
)

type WhiteAndBluePartsCardProps = {
    children: [React.ReactNode, React.ReactNode]
    onClick?: () => void
    className?: string
    whiteClass?: string
    blueClass?: string
    dataTestId?: string
}

const WhiteAndBluePartsCard: React.FC<WhiteAndBluePartsCardProps> = ({
    children,
    onClick,
    className,
    dataTestId,
    whiteClass,
    blueClass,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const hasContent = !!children[1]
    const clickable = !!onClick
    const classes = useStyles({ clickable, hasContent })

    return (
        <div className={`${classes.root} ${className}`} onClick={onClick} data-testid={dataTestId}>
            <div className={`${classes.header} ${whiteClass}`}>{children[0]}</div>
            {children[1] && <div className={`${classes.content} ${blueClass}`}>{children[1]}</div>}
            {clickable && !isMobile && (
                <div className={classes.clickable}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            )}
        </div>
    )
}

export default WhiteAndBluePartsCard
