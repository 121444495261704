import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Pill, { PillProps } from './Pill'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inHeader: {
            [theme.breakpoints.down('sm')]: {
                backgroundColor: 'rgb(256, 256, 256, 0.16)',
                color: theme.palette.common.white,
            },
        },
    }),
)

export interface CounterProps extends Omit<PillProps, 'label' | 'isNumber'> {
    current: number | string
    total: number | string
    inHeader?: boolean
}

const Counter: React.FC<CounterProps> = ({ current, total, className = '', inHeader = false, variant, ...props }) => {
    const classes = useStyles()
    return (
        <Pill
            className={`${inHeader ? classes.inHeader : ''} ${className}`}
            variant={inHeader ? 'basic' : variant}
            label={`${current}/${total}`}
            isNumber
            {...props}
        />
    )
}

export default Counter
