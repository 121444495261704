import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { Box, CardMedia, createStyles, makeStyles, Theme } from '@material-ui/core'
import { RMSFile } from 'utils/files'

const useStyles = makeStyles<Theme, { size: string }>((theme: Theme) =>
    createStyles({
        mediaContainer: {
            width: 'fit-content',
            margin: 'auto',
        },
        iconContainer: {
            width: '100%',
            position: 'relative',
            display: 'inline-block',
            verticalAlign: '4px',
        },
        deleteIcon: {
            position: 'absolute',
            padding: theme.spacing(0.5),
            cursor: 'pointer',
            right: '-10px',
            height: '12px',
            width: '12px',
            top: '-20px',
            backgroundColor: '#484E5A',
            color: theme.palette.common.white,
            borderRadius: '20px',
        },
        fileIcon: {
            color: '#CDD1DA',
        },
        fileName: {
            fontSize: theme.typography.pxToRem(11),
            color: '#555E73',
            textDecoration: 'underline',
            width: '84%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
        },
        media: {
            maxWidth: ({ size }) => size,
            maxHeight: ({ size }) => size,
            objectFit: 'contain',
            backgroundColor: '#EBEFFB',
            borderRadius: '2px',
            margin: 'auto',
        },
        mediaWithDeleteButton: {
            marginTop: '-20px',
        },
    }),
)

type PictureThumbnailProps = {
    file: RMSFile
    size: 'big' | 'medium' | 'small' | 'extra-small'
    handleDelete?: (file: RMSFile) => void
    className?: string
    isS3File?: boolean
}

enum Size {
    'big' = '250px',
    'medium' = '160px',
    'small' = '95px',
    'extra-small' = '60px',
}

const PictureThumbnail: React.FC<PictureThumbnailProps> = ({
    file,
    size = 'medium',
    handleDelete,
    className,
    isS3File = false,
}) => {
    const classes = useStyles({ size: Size[size] })

    return (
        <Box className={`${classes.mediaContainer} ${className ? className : ''}`}>
            {handleDelete && (
                <Box className={classes.iconContainer}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={classes.deleteIcon}
                        onClick={() => handleDelete(file)}
                        data-testid="deleteImage"
                    />
                </Box>
            )}
            <div data-testid="thumbnail">
                <CardMedia
                    component="img"
                    src={isS3File ? file.name : URL.createObjectURL(file)}
                    alt="Uploaded image"
                    className={`${classes.media} ${handleDelete ? classes.mediaWithDeleteButton : ''}`}
                />
            </div>
        </Box>
    )
}

export default PictureThumbnail
