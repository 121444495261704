import * as React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, Card as MuiCard, CardActions, CardContent, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: 290,
                margin: 'auto',
            },
        },
        cardBig: {
            maxWidth: 400,
            margin: 'auto',
            '& .MuiCardContent-root': {
                padding: theme.spacing(3),
                textAlign: 'left',
                '& .figure': {
                    fontSize: theme.typography.pxToRem(64),
                },
            },
        },
        cardContent: {
            paddingBottom: 0,
            textAlign: 'center',
        },
        cardTitle: {
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(24),
            lineHeight: 1.04,
            minHeight: 24 * 1.04 * 2,
        },
        pointer: {
            cursor: 'pointer',
        },
    }),
)

type CardProps = {
    title?: string
    children: any
    loading?: boolean
    className?: string
    onClick?: () => void
    actions?: {
        title: string
        link: string
    }[]
}

const Card: React.FC<CardProps> = ({ title, children, loading = true, className, onClick, actions }) => {
    const classes = useStyles()

    return (
        <MuiCard
            className={`${classes.card} ${className} ${onClick && classes.pointer}`}
            onClick={() => onClick && onClick()}
        >
            {loading ? (
                <Skeleton variant="rect" width="100%" height="200px" />
            ) : (
                <>
                    <CardContent className={classes.cardContent}>
                        {title && (
                            <Typography variant="h3" component="h4" className={classes.cardTitle}>
                                {title}
                            </Typography>
                        )}
                        {children}
                    </CardContent>
                    {actions && (
                        <CardActions>
                            {actions.map((action) => (
                                <Button size="small" href={action.link} target="_blank" key={action.title}>
                                    {action.title}
                                </Button>
                            ))}
                        </CardActions>
                    )}
                </>
            )}
        </MuiCard>
    )
}

export default Card
