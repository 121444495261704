import * as React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import EnrichedIcon from '_molecules/EnrichedIcon'
import { faArrowRight, faHandsClapping } from '@fortawesome/pro-light-svg-icons'
import BlueBackground from '_molecules/backgrounds/BlueBackground'
import { useNavigate } from 'react-router-dom'
import { WMS_LINK_LOCATION, WMS_PUBLISH } from 'utils/routes/frontend'
import { ProductType } from './InsertProductHome'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: theme.space(4),
        },
        buttons: {
            alignSelf: 'center',
            marginTop: theme.space(2),
        },
    }),
)

type FinishedInsertProps = {
    productType: ProductType
    ean: string
    goToHome: () => void
    hidden?: boolean
}

const FinishedInsert: React.FC<FinishedInsertProps> = ({ productType, ean, goToHome, hidden }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const navigate = useNavigate()

    if (hidden) return <></>

    return (
        <Page
            title={t('wms.scanUniqueProduct.eanHeaderTitle', { EAN: ean })}
            section={t('wms.scanUniqueProduct.title')}
            headerStyle={HeaderStyleEnum.DEFAULT}
        >
            <BlueBackground>
                <EnrichedIcon
                    icon={faHandsClapping}
                    title={t('wms.scanUniqueProduct.finished.title')}
                    tagline={
                        productType === ProductType.RENTAL
                            ? t('wms.scanUniqueProduct.finished.rentalTagline')
                            : t('wms.scanUniqueProduct.finished.secondHandTagline')
                    }
                />
                <Box className={classes.buttonContainer}>
                    {productType === ProductType.RENTAL && (
                        <Button
                            className={classes.buttons}
                            data-testid="linkToLocation"
                            endIcon={faArrowRight}
                            onClick={() => navigate(WMS_LINK_LOCATION)}
                            label={t('wms.scanUniqueProduct.finished.linkToLocationButton')}
                        />
                    )}
                    {productType === ProductType.SECOND_HAND && (
                        <Button
                            className={classes.buttons}
                            data-testid="publish"
                            endIcon={faArrowRight}
                            onClick={() => navigate(WMS_PUBLISH)}
                            label={t('wms.scanUniqueProduct.finished.publishButton')}
                        />
                    )}
                    <Button
                        className={classes.buttons}
                        color="neutral"
                        size="small"
                        data-testid="createProduct"
                        onClick={goToHome}
                        label={t('wms.scanUniqueProduct.finished.createProductButton')}
                    />
                </Box>
            </BlueBackground>
        </Page>
    )
}

export default FinishedInsert
