import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Text from '_atoms/text/Text'
import { Box } from '@material-ui/core'
import { faCircle, faCircleDot } from '@fortawesome/pro-regular-svg-icons'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles<Theme, { disabled: boolean }>((theme: Theme) =>
    createStyles({
        rootDisabled: {
            border: 'none',
        },
        hidden: {
            display: 'none',
        },
        verticalRoot: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.space(1),
        },
        verticalButton: {
            padding: theme.space(2, 1),
            display: 'flex',
            height: 'fit-content',
        },
        verticalButtonMargin: {
            marginTop: theme.space(2),
        },
        verticalButtonContent: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        verticalButtonText: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        verticalButtonLabel: {
            alignSelf: 'start',
            textTransform: 'lowercase',
            '&:first-letter': {
                textTransform: 'uppercase',
            },
        },
        verticalButtonDescription: {
            alignSelf: 'start',
            textAlign: 'start',
            lineHeight: 'normal',
        },
        verticalButtonIcon: {
            alignSelf: 'center',
            margin: theme.space(3),
        },
        verticalButtonSelected: {
            border: `2px solid ${theme.palette.primary[500]}`,
        },
        verticalButtonSelectedIcon: {
            color: theme.palette.primary[700],
        },
        verticalButtonSelectedLabel: {
            color: theme.palette.primary[700],
        },
    }),
)

export interface Option {
    value: any
    label: string
    description?: string
    icon?: IconDefinition
    disabledOption?: boolean
}

interface RadioButtonsProps {
    options: Option[]
    value: any
    onChange: (a: any) => void
    disabled: boolean
    dataTestId?: string
}

export const VerticalRadioButtons: React.FC<RadioButtonsProps> = ({
    options,
    value,
    onChange,
    disabled,
    dataTestId,
}) => {
    const classes = useStyles({ disabled: disabled })

    function handleChange(event: React.MouseEvent<HTMLButtonElement>) {
        onChange(options.find((option) => option.label === event.currentTarget.value))
    }

    return (
        <div className={`${classes.verticalRoot} ${disabled && classes.rootDisabled}`} data-testid={dataTestId}>
            {options.map((option, index) => {
                const marginClass = index > 0 ? classes.verticalButtonMargin : ''
                const hiddenClass = option.value !== value && (disabled || option.disabledOption) ? classes.hidden : ''
                return (
                    <Button
                        key={index}
                        color="neutral"
                        variant="stroke"
                        size="large"
                        onClick={handleChange}
                        value={option.label}
                        className={`${classes.verticalButton} ${
                            option.value === value && classes.verticalButtonSelected
                        } ${marginClass} ${hiddenClass}`}
                        disabled={disabled || option.disabledOption}
                    >
                        <Box className={classes.verticalButtonContent}>
                            {option.icon ? (
                                <FontAwesomeIcon icon={option.icon} className={classes.verticalButtonIcon} />
                            ) : (
                                <FontAwesomeIcon
                                    icon={option.value === value ? faCircleDot : faCircle}
                                    className={`${classes.verticalButtonIcon} ${
                                        option.value === value && classes.verticalButtonSelectedIcon
                                    }`}
                                />
                            )}
                            <Box className={classes.verticalButtonText}>
                                <Text
                                    variant="S1"
                                    className={`${classes.verticalButtonLabel} ${
                                        option.value === value && classes.verticalButtonSelectedLabel
                                    }`}
                                >
                                    {option.label}
                                </Text>
                                <Text variant="P5" className={classes.verticalButtonDescription}>
                                    {option.description}
                                </Text>
                            </Box>
                        </Box>
                    </Button>
                )
            })}
        </div>
    )
}

export default VerticalRadioButtons
