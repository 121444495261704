import dayjs, { Dayjs } from 'dayjs'
import { ICustomer } from 'interfaces/Customer'
import fileDownload from 'js-file-download'
import { OMS_ORDERS_LIST_TO_CSV, OMS_PRODUCTS_LIST_TO_CSV } from 'utils/routes/backend'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'

type DownloadCSVFilters = {
    customer: ICustomer | undefined
    startDate: Dayjs
    endDate: Dayjs
}

export enum CsvType {
    ORDERS = 'orders',
    PRODUCTS = 'products',
}

export async function downloadOrdersOrProductsCsv(filters: DownloadCSVFilters, csvType: CsvType): Promise<any> {
    const haveFilters = filters?.startDate && filters?.endDate && filters?.customer
    if (!haveFilters) {
        toast.error('No date range or customer specified for the export')
        return
    }

    const downloadDate = dayjs().format('YYYY-MM-DD-HH-mm-ss')
    const startDate = filters.startDate.format('YYYY-MM-DD')
    const endDate = filters.endDate.format('YYYY-MM-DD')
    const customer = filters.customer
    const filteredFileName = `from-${startDate}-to-${endDate}`
    let csv = null

    let baseUrl = ''
    if (csvType === CsvType.ORDERS) {
        baseUrl = OMS_ORDERS_LIST_TO_CSV
    } else if (csvType === CsvType.PRODUCTS) {
        baseUrl = OMS_PRODUCTS_LIST_TO_CSV
    } else {
        toast.error('Unknown CSV type')
        return
    }
    const csvUrl = `${baseUrl}?customerId=${customer?.id}&from=${startDate}&to=${endDate}`
    csv = await rmsApi.get(csvUrl, { responseType: 'blob' })

    fileDownload(csv.data, `${csvType}-${filteredFileName}__${downloadDate}.csv`)
}
