import * as React from 'react'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { RMSFile } from 'utils/files'
import PictureThumbnail from '_molecules/pictures/PictureThumbnail'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filesContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
    }),
)

type ThumbnailGridProps = {
    pictures: RMSFile[]
    handleDelete?: (file: RMSFile) => void
    size?: 'big' | 'medium' | 'small' | 'extra-small'
    isS3File?: boolean
}

const ThumbnailGrid: React.FC<ThumbnailGridProps> = ({ pictures, size = 'medium', handleDelete, isS3File = false }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.filesContainer} spacing={2} data-testid="thumbnailGrid">
            {pictures.map((picture, index) => {
                return (
                    <Grid key={index} item xs={4} sm={3}>
                        <div>
                            <PictureThumbnail
                                file={picture}
                                size={size}
                                handleDelete={handleDelete}
                                isS3File={isS3File}
                            />
                        </div>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ThumbnailGrid
