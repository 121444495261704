import * as React from 'react'
import CalendarDatePicker from '_atoms/inputs/CalendarDatePicker'
import { Dayjs } from 'dayjs'

type DatePickerFilterProps = {
    applyFilter?: (date: any) => void // Injected in children component
    startDate?: Dayjs
    endDate?: Dayjs
    range?: boolean
}

const DatePickerFilter: React.FC<DatePickerFilterProps> = ({ applyFilter, startDate, endDate, range }) => {
    function onDateChange(startDate: Dayjs, endDate?: Dayjs) {
        if (range) {
            applyFilter!({ startDate, endDate })
        } else {
            applyFilter!(startDate)
        }
    }
    return <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={onDateChange} range={range} />
}

export default DatePickerFilter
