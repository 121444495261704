import * as React from 'react'
import { Box, createStyles, makeStyles, Theme, BoxProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            backgroundColor: theme.palette.neutral[100],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: theme.space(12),
            gap: theme.space(2),
            padding: theme.space(0, 5),
            marginBottom: theme.space(2),
            borderRadius: theme.space(2 / 5),
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                padding: theme.space(0, 3),
            },
        },
        children: {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                alignItems: 'center',
            },
        },
    }),
)

interface CardProps extends BoxProps {
    children?: React.ReactNode
    className?: string
    rightAddon?: React.ReactNode
}

const Card: React.FC<CardProps> = ({ children, className = '', rightAddon, ...props }) => {
    const classes = useStyles()
    return (
        <Box className={`${classes.box} ${className}`} {...props}>
            <div className={classes.children}>{children}</div>
            {rightAddon}
        </Box>
    )
}

export default Card
