import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PACKING_ROUTE, PICKPACK_ISSUES_LIST_ROUTE } from 'utils/routes/frontend'
import { ORDER_UNIQUE_PRODUCTS_TO_RESTOCK, PACK_UNIQUE_PRODUCT_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import {
    DataPackUniqueProduct,
    FromSearchParam,
    IOrderPackingList,
    IPackingLocationSearchInfos,
    PackingListFront,
    PackingType,
} from 'interfaces/Packing.interface'
import Tips, { TipsProps } from '_atoms/containers/Tips'
import SimpleSelect from '_atoms/inputs/SimpleSelect'
import Button, { ButtonProps } from '_atoms/buttons/Button'
import { returnPluralPath } from 'utils/stringConvertion'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import ListWithCounter from '_molecules/ListWithCounter'
import PickPackProductCard from '_organisms/PickPackProductCard'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { IUniqueProductOrProductReference } from 'interfaces/Batch.interface'

const MAX_PARCEL_NUMBER = 5

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        tip: {
            marginBottom: theme.space(2),
        },
        footer: {
            display: 'flex',
            gap: `${theme.space(2)}px`,
        },
        select: {
            flex: 1,
        },
    }),
)

type OrderPackingListProps = {
    setFinishPacking: (finishPacking: boolean) => void
    orderPackingData: IOrderPackingList
    fetchOrderPackingData: () => void
    parcelNumber: number
    setParcelNumber: (parcelNumber: number) => void
    setLocationSearchInfos: (locationSearchInfos: IPackingLocationSearchInfos) => void
}

const OrderPackingScanProducts: React.FC<OrderPackingListProps> = ({
    setFinishPacking,
    orderPackingData,
    fetchOrderPackingData,
    parcelNumber,
    setParcelNumber,
    setLocationSearchInfos,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { orderId } = useParams()
    const buildErrorMessage = useErrorMessage()

    const [loading, setLoading] = useState<boolean>(false)
    const [isPdfsGenerated, setIsPdfsGenerated] = useState<boolean>(false)

    const {
        customer,
        omsOrderId,
        batchId,
        shipments,
        nbDeletedProducts,
        isCanceled,
        labelIssue,
        unknownIssue,
        missingProducts,
        productsToSearch,
        productsAddedBySav,
        productsToPack,
        packedUniqueProducts,
        optionalProducts,
    } = orderPackingData

    const from = searchParams.get('from') as FromSearchParam
    const fromSavIssueList = from === FromSearchParam.SAV_ISSUE_LIST
    const fromOperatorIssueList = from === FromSearchParam.OPERATOR_ISSUE_LIST

    function handlePrevious() {
        if (fromOperatorIssueList || fromSavIssueList) {
            navigate(PICKPACK_ISSUES_LIST_ROUTE)
        } else {
            navigate(PACKING_ROUTE(batchId))
        }
    }

    useEffect(() => {
        function arePdfsAlreadyGenerated() {
            let allPdfsGenerated = false
            if (shipments[0]?.parcels?.length) {
                allPdfsGenerated = shipments[0]?.parcels.every((parcel) => parcel.pdfUrl)
            }
            setIsPdfsGenerated(allPdfsGenerated)
        }

        arePdfsAlreadyGenerated()
    }, [orderPackingData])

    const parcelNumberOptions = Array.from({ length: MAX_PARCEL_NUMBER }, (_, i) => ({
        value: i + 1,
        label: `${i + 1} ${t('new_orders.packing.parcel')}`,
    }))

    async function handleScan(barcodeUid: string) {
        setLoading(true)
        try {
            const packingType =
                orderPackingData.productsToPack.length === 0 ? PackingType.OPTIONAL : PackingType.PICK_PACK

            const shipmentId = shipments.find((shipment) => !shipment.isReturn)!.id
            const payload: DataPackUniqueProduct = { barcodeUid, shipmentId, packingType }

            await rmsApi.post(PACK_UNIQUE_PRODUCT_URL(orderId!), payload)
            fetchOrderPackingData()
        } catch (e) {
            toast.error(buildErrorMessage(e, { barcodeUid }, 'new_orders.errors.packUniqueProductError'))
        }
        setLoading(false)
    }

    async function handleRestockOrder() {
        try {
            await rmsApi.patch(ORDER_UNIQUE_PRODUCTS_TO_RESTOCK(orderId))
            navigate(PICKPACK_ISSUES_LIST_ROUTE)
            toast.success(t('new_orders.packing.updateUniqueProductStatusesSuccess'))
        } catch (e) {
            toast.error(buildErrorMessage(e, { orderId }, 'new_orders.errors.orderToRestockError'))
        }
    }

    const nbMissingProducts = missingProducts.length
    const nbProductsAddedBySav = productsAddedBySav!.length
    const nbProductsToSearch = productsToSearch!.length
    const nbProductsToPack = productsToPack.length
    const nbPackedUniqueProducts = packedUniqueProducts.length

    const footer = () => {
        if (isCanceled) {
            const nbToRestock = nbPackedUniqueProducts + nbProductsToPack > 0
            return (
                <Button
                    label={
                        nbToRestock
                            ? t('new_orders.packing.canceledOrderWithRestock')
                            : t('new_orders.packing.canceledOrderWithoutRestock')
                    }
                    size="large"
                    disabled={!!nbProductsToPack}
                    onClick={handleRestockOrder}
                    data-testid="restocking-button"
                />
            )
        }

        if (nbMissingProducts || (nbProductsToSearch && !fromOperatorIssueList)) {
            return <Button label={t('new_orders.packing.pauseLabel')} size="large" onClick={handlePrevious} />
        }

        if (isPdfsGenerated) {
            const hasMultipleParcels = shipments[0].parcels!.length > 1
            const label = t(`new_orders.packing.printLabelGenerated.${hasMultipleParcels ? 'plural' : 'singular'}`)
            return (
                <Button
                    label={label}
                    onClick={() => setFinishPacking(true)}
                    data-testid="print-button"
                    disabled={!!nbProductsToPack || !!nbProductsToSearch}
                />
            )
        }

        return (
            <div className={classes.footer}>
                <SimpleSelect
                    value={parcelNumber}
                    possibleSelections={parcelNumberOptions}
                    handleChange={(e) => setParcelNumber(e.target.value)}
                    className={classes.select}
                />
                <Button
                    label={t('new_orders.packing.printLabel')}
                    onClick={() => setFinishPacking(true)}
                    data-testid="print-button"
                    disabled={!!nbProductsToPack || !!nbProductsToSearch}
                />
            </div>
        )
    }

    function buildTips() {
        const tips: TipsProps[] = []

        if (fromOperatorIssueList && nbProductsToSearch) {
            tips.push({
                title: t('new_orders.packing.locationSearchTipTitle'),
                text: t('new_orders.packing.locationSearchTipText'),
                variant: 'warning',
            })
        } else if (isCanceled) {
            tips.push({
                title: t('new_orders.packing.canceledOrderTipTitle'),
                text: t('new_orders.packing.canceledOrderTipText'),
                variant: 'warning',
            })
        } else if (nbMissingProducts || (nbProductsToSearch && !fromOperatorIssueList)) {
            tips.push({
                title: t('new_orders.packing.packOrderWithIssuesTipTitle'),
                text: t('new_orders.packing.packOrderWithIssuesTipText'),
                variant: 'warning',
            })
        } else if (nbDeletedProducts || nbProductsAddedBySav) {
            if (nbDeletedProducts)
                tips.push({
                    title: returnPluralPath(t, 'new_orders.packing.deletedProductTipTitle', nbDeletedProducts),
                    text: t('new_orders.packing.deletedProductTipText'),
                    variant: 'warning',
                })
            if (nbProductsAddedBySav)
                tips.push({
                    title: returnPluralPath(t, 'new_orders.packing.addedProductTipTitle', nbProductsAddedBySav),
                    text: t('new_orders.packing.addedProductTipText'),
                    variant: 'warning',
                })
        } else if (nbProductsToPack) {
            tips.push({
                title: t('new_orders.packing.packOrderTipTitle'),
                text: t('new_orders.packing.packOrderTipText'),
            })
        } else if (unknownIssue) {
            tips.push({
                title: t('new_orders.packing.unknownIssueTipTitle'),
                text: t('new_orders.packing.unknownIssueTipText'),
                variant: 'warning',
            })
        } else if (labelIssue && isPdfsGenerated) {
            tips.push({
                title: t('new_orders.packing.labelIssueResolvedTipTitle'),
                text: t('new_orders.packing.labelIssueResolvedTipText'),
                variant: 'warning',
            })
        } else if (labelIssue && !isPdfsGenerated) {
            tips.push({
                title: t('new_orders.packing.labelIssueTipTitle'),
                text: t('new_orders.packing.labelIssueTipText'),
                variant: 'warning',
            })
        }
        return tips
    }

    function buildButtonProps(product: IUniqueProductOrProductReference, isAddedProduct: boolean): ButtonProps {
        return {
            label: t('new_orders.packing.locationSearchButton'),
            startIcon: faMagnifyingGlass,
            color: 'info',
            onClick: () => setLocationSearchInfos({ product, isAddedProduct }),
        }
    }

    const orderPacking: PackingListFront = {
        optionalProducts: {
            products: optionalProducts,
            variant: 'stroke',
            color: 'success',
        },
        productsToPack: {
            products: isCanceled ? [] : productsToPack,
            variant: loading ? 'fill' : 'stroke',
        },
        productsToScanToRestock: {
            products: isCanceled ? productsToPack : [],
            variant: loading ? 'fill' : 'stroke',
        },
        productsAddedBySav: {
            products: productsAddedBySav,
            variant: 'stroke',
            buttonProps: (product: IUniqueProductOrProductReference) => buildButtonProps(product, true),
        },
        productsToSearch: {
            products: fromOperatorIssueList ? productsToSearch : [],
            variant: 'stroke',
            buttonProps: (product: IUniqueProductOrProductReference) => buildButtonProps(product, false),
        },
        missingProducts: {
            products: fromOperatorIssueList ? missingProducts : [...missingProducts, ...productsToSearch],
            variant: 'stroke',
            color: 'error',
        },
        packedUniqueProducts: {
            products: isCanceled ? [] : packedUniqueProducts,
            variant: 'stroke',
            color: 'success',
        },
        productsToRestock: {
            products: isCanceled ? packedUniqueProducts : [],
            variant: 'stroke',
        },
    }

    const total = Object.values(orderPacking).reduce((count, { products }) => count + products.length, 0)

    return (
        <Page
            title={`${customer.name} #${omsOrderId}`}
            handlePrevious={handlePrevious}
            headerStyle={fromSavIssueList ? HeaderStyleEnum.DEFAULT : HeaderStyleEnum.WITH_TOOLBAR}
        >
            <BackgroundWithScanAndFooter
                scanProps={
                    fromSavIssueList
                        ? undefined
                        : { onSubmit: handleScan, placeholder: t('new_orders.packing.scanPlaceholder') }
                }
                footer={fromSavIssueList ? undefined : footer()}
                footerInBoxDesktop={true}
            >
                {buildTips().map((tip, index) => (
                    <Tips key={index} variant={tip.variant} className={classes.tip} title={tip.title} text={tip.text} />
                ))}
                {Object.entries(orderPacking).map(([key, { products, variant, color, buttonProps }], index) => (
                    <ListWithCounter
                        key={index}
                        title={t(`new_orders.packing.${key}`)}
                        counterProps={{
                            current: products.length,
                            total,
                            variant,
                            color,
                        }}
                    >
                        {products.map((product, index) => (
                            <PickPackProductCard
                                key={index}
                                product={product}
                                dataTestId={`${key}Card`}
                                buttonProps={buttonProps && buttonProps(product)}
                            />
                        ))}
                    </ListWithCounter>
                ))}
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default OrderPackingScanProducts
