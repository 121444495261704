export enum ErrorCode {
    ALREADY_FINISHED = 'PREPARATION_ALREADY_FINISHED',
    SECOND_HAND_WITHOUT_PHOTOS = 'SECOND_HAND_WITHOUT_PHOTOS',
    OPTIONAL_PRODUCT_NOT_FOUND = 'OPTIONAL_PRODUCT_NOT_FOUND',
    OPTIONAL_PRODUCT_CANNOT_BE_PACKED = 'OPTIONAL_PRODUCT_CANNOT_BE_PACKED',
    UNIQUE_PRODUCT_NOT_FOUND = 'UNIQUE_PRODUCT_NOT_FOUND',
    UNIQUE_PRODUCT_ALREADY_LINKED_WITH_SHIPMENT = 'UNIQUE_PRODUCT_ALREADY_LINKED_WITH_SHIPMENT',
    NOT_FOUND = 'UNIQUE_PRODUCT_NOT_FOUND',
}

export const errorCodeExists = (code: string): ErrorCode => {
    return ErrorCode[code as keyof typeof ErrorCode]
}
