import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, createStyles, Fab, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

import {
    CHECK_UNIQUE_PRODUCT_RESERVED_STATUS,
    WMS_ADD_PRODUCT_REFERENCE_TO_PREPARATION_URL,
    WMS_ADD_PRODUCTS_TO_PREPARATION_URL,
    WMS_GET_SKU_FROM_PUID_URL,
    WMS_REMOVE_PRODUCT_REFERENCE_TO_PREPARATION_URL,
} from 'utils/routes/backend'
import { IPreparationRequiredProductReference, IProductReference, IUniqueProduct } from 'interfaces'
import KeyboardEventListener from 'components/reusable/KeyboardEventListener'
import { ProductOverviewModal } from '../../../components/productOverviewModal'
import ProductPreparationCard from '../../../components/reusable/ProductPreparationCard'
import StorageLocationGrid from '../../../components/reusable/StorageLocationGrid'
import { ICustomer } from 'interfaces/Customer'
import { toast } from 'utils/toast'
import RemoveIcon from '@material-ui/icons/Remove'
import rmsApi from 'utils/api'
import UniqueProductPreparationCard from '../../../_organisms/UniqueProductPreparationCard'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sticky: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: theme.zIndex.modal,
                background: theme.palette.success.main,
                boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)',
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(2),
            },
        },
        finishButton: {
            [theme.breakpoints.down('sm')]: {
                borderRadius: 0,
                width: '100%',
                padding: theme.spacing(2),
            },
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.success.dark,
            },
            '&:disabled': {
                backgroundColor: theme.palette.success.light,
                opacity: 0.5,
                color: '#FFF',
            },
        },
        helperText: {
            marginTop: theme.spacing(1),
            color: theme.palette.grey[600],
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.1,
            [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(12),
                marginBottom: theme.spacing(6),
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(7),
            },
        },
        productRefContainer: {
            flexBasis: 'auto',
            backgroundColor: '#000a0',
            [theme.breakpoints.up('md')]: {
                maxWidth: '70%',
                margin: '0px auto',
            },
        },
        fab: {
            position: 'relative',
            left: '95%',
            backgroundColor: theme.palette.error.main,
            width: '25px',
            height: '25px',
            minHeight: '25px', // necessary to override MUI Fab class
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.error.dark,
            },
        },
    }),
)

type PickPackProps = {
    productList: IProductReference[]
    reservedUniqueProducts: IUniqueProduct[]
    preparationId: number
    customer: ICustomer
    handleNextStep: () => void
}

const PickPack: React.FC<PickPackProps> = ({
    preparationId,
    productList,
    reservedUniqueProducts,
    customer,
    handleNextStep,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [requiredProducts, setRequiredProducts] = useState<IPreparationRequiredProductReference[]>([])
    const [pickedUniqueProducts, setPickedUniqueProducts] = useState<IUniqueProduct[]>([])
    const [pickedProductListValid, setPickedProductListValid] = useState<boolean>(false)
    const [productReferenceInfo, setProductReferenceInfo] = useState<IProductReference | null>(null)
    const [currentUniqueProduct, setCurrentUniqueProduct] = useState<IUniqueProduct | null>(null)
    const [currentUniqueProductPhoto, setCurrentUniqueProductPhoto] = useState<string | null>(null)

    useEffect(() => {
        const productReferenceList: IPreparationRequiredProductReference[] = []
        productList.forEach((product: any) => {
            delete product.id
            const requiredProduct = { ...product }
            requiredProduct.optional = false
            requiredProduct.PUIDs = []

            // In case of optional we will display distinct product reference card
            if (requiredProduct!.optionalQuantity! > 0) {
                if (requiredProduct.optionalQuantity === requiredProduct.quantity) {
                    // product reference is only optional
                    requiredProduct.optional = true
                } else {
                    // we have both optional and required products to display
                    const optionalProductReference = { ...requiredProduct }
                    optionalProductReference.PUIDs = []
                    optionalProductReference.quantity = requiredProduct.optionalQuantity
                    requiredProduct.quantity! -= requiredProduct!.optionalQuantity!
                    optionalProductReference.optional = true
                    productReferenceList.push(optionalProductReference)
                }
            }
            // We delete it to avoid confusion in what quantity should be incremented
            delete requiredProduct.optionalQuantity
            productReferenceList.push(requiredProduct)
        })

        // const sortedProductReferenceList = productReferenceList.sort((productRef) => (productRef.optional ? 1 : -1))
        setRequiredProducts(productReferenceList)
    }, [productList])

    useEffect(() => {
        let isProductListValid = true
        if (requiredProducts.length === 0 && pickedUniqueProducts.length === 0) isProductListValid = false
        if (requiredProducts.length) {
            requiredProducts.forEach((productRef) => {
                if (productRef.quantity !== productRef.PUIDs.length && !productRef.optional) isProductListValid = false
            })
        }

        if (pickedUniqueProducts.length !== reservedUniqueProducts.length) {
            isProductListValid = false
        }

        if (isProductListValid !== pickedProductListValid) setPickedProductListValid(isProductListValid)
        // eslint-disable-next-line
    }, [requiredProducts, pickedUniqueProducts])

    async function getSkuFromUniqueProduct(puid: string) {
        const response: { sku: string | null; error: string | null; warning: string | null } = {
            sku: null,
            error: null,
            warning: null,
        }

        try {
            const productRequest = await rmsApi.get(`${WMS_GET_SKU_FROM_PUID_URL}/${puid}`)
            response.sku = productRequest.data
            return response
        } catch (e: any) {
            const data = e.response.data.error
            if (e.response.status === 403) {
                response.warning = t('wms.pickPackTable.warningStatus', {
                    PUID: puid,
                    status: t(`uniqueProductStatus.${data.status}`),
                })
            } else response.error = t('wms.pickPackTable.notFound', { PUID: puid })
            return response
        }
    }

    const isUniqueProductReserved = async (puid: string): Promise<IUniqueProduct> => {
        let uniqueProduct
        try {
            const res = await rmsApi.get(CHECK_UNIQUE_PRODUCT_RESERVED_STATUS(preparationId, puid))
            uniqueProduct = res.data
        } catch (err: any) {
            if (err.response.status === 403) {
                toast.error(t('wms.pickPackTable.notAvailable', { PUID: puid }))
            } else {
                toast.error(t('wms.pickPackTable.notFound', { PUID: puid }))
            }
        }
        return uniqueProduct
    }

    async function addProductReferenceToOrder(sku: string) {
        const payload = {
            preparationId: preparationId,
            sku: sku,
        }
        const response: { productReference: IPreparationRequiredProductReference | null; error: string } = {
            productReference: null,
            error: '',
        }

        try {
            const apiResponse = await rmsApi.post(
                WMS_ADD_PRODUCT_REFERENCE_TO_PREPARATION_URL.replace(':id', preparationId.toString()),
                payload,
            )
            response.productReference = apiResponse.data
            return response
        } catch (e: any) {
            response.error = e.message
            return response
        }
    }

    async function removeProductReferenceFromOrder(sku: string) {
        await rmsApi.post(WMS_REMOVE_PRODUCT_REFERENCE_TO_PREPARATION_URL.replace(':id', preparationId.toString()), {
            sku,
        })
    }

    async function finishPreparation() {
        const puidsInPreparation: string[] = []
        for (const requiredProduct of requiredProducts) {
            puidsInPreparation.push(...requiredProduct.PUIDs)
        }

        for (const pickedUniqueProduct of pickedUniqueProducts) {
            puidsInPreparation.push(pickedUniqueProduct.barcodeUid)
        }

        const payload = {
            preparationId: preparationId,
            puids: puidsInPreparation,
        }

        try {
            await rmsApi.post(`${WMS_ADD_PRODUCTS_TO_PREPARATION_URL}/${preparationId}`, payload)
            toast.success(t('wms.pickPackTable.preparationSuccess'))
        } catch (e: any) {
            toast.error(t('wms.pickPackTable.unknownError'))
            return
        }
        handleNextStep()
    }

    // Check if scanned barcode has already been scanned
    function isDuplicate(scannedBarcode: string): boolean {
        return requiredProducts.some((productReference) => productReference.PUIDs.indexOf(scannedBarcode) >= 0)
    }

    // eslint-disable-next-line
    async function removePickedProduct(selectedProductBarcode: string): Promise<void> {
        const requiredProductCopy = [...requiredProducts]
        let found = false
        for (let i = 0; i < requiredProductCopy.length; i++) {
            const indexToDelete = requiredProductCopy[i].PUIDs.indexOf(selectedProductBarcode)
            if (indexToDelete >= 0 && requiredProductCopy[i].sku) {
                found = true
                requiredProductCopy[i].PUIDs.splice(indexToDelete, 1)
                setRequiredProducts(requiredProductCopy)
                toast.success(t('wms.pickPackTable.removeUniqueProductSuccess', { PUID: selectedProductBarcode }))
            }
        }
        if (!found) toast.error(t('wms.pickPackTable.removeUniqueProductError', { PUID: selectedProductBarcode }))
    }

    async function removeOptionalProductReference(
        productReference: IPreparationRequiredProductReference,
        selectedProductBarcode?: string,
    ): Promise<void> {
        try {
            await removeProductReferenceFromOrder(productReference.sku!)
            const requiredProductsCopy = [...requiredProducts]
            requiredProducts.forEach((requiredProduct, index) => {
                if (requiredProduct.sku === productReference.sku && requiredProduct.optional) {
                    if (selectedProductBarcode) {
                        const indexToDelete = requiredProduct.PUIDs.indexOf(selectedProductBarcode)
                        requiredProductsCopy[index].PUIDs.splice(indexToDelete, 1)
                        toast.success(
                            t('wms.pickPackTable.removeUniqueProductSuccess', { PUID: selectedProductBarcode }),
                        )
                    }
                    if (requiredProduct!.quantity! === 1) {
                        requiredProductsCopy.splice(index, 1)
                        toast.success(
                            t('wms.pickPackTable.removeProductReferenceSuccess', { sku: productReference.sku }),
                        )
                    } else if (requiredProduct!.quantity! > 1) {
                        requiredProductsCopy[index].quantity!--
                    }
                    setRequiredProducts(requiredProductsCopy)
                }
            })
        } catch (e: any) {
            toast.error(t('wms.pickPackTable.removeProductReferenceError', { error: e }))
        }
    }

    async function addOptionalItem(scannedBarcode: string, sku: string) {
        const requiredProductCopy = [...requiredProducts]

        // retrieve existing optional product reference
        let existingProductReferenceIndex = null

        // Find index of existing optional productReference
        for (let i = 0; i < requiredProductCopy.length; i++) {
            if (requiredProductCopy[i].sku === sku && requiredProductCopy[i].optional) {
                existingProductReferenceIndex = i
                break
            }
        }

        if (existingProductReferenceIndex) {
            const optionalProductRef = requiredProductCopy[existingProductReferenceIndex]

            if (optionalProductRef.quantity === optionalProductRef.PUIDs.length) {
                const { error } = await addProductReferenceToOrder(sku!)
                if (error) {
                    toast.error(t('wms.pickPackTable.removeProductReference', { error }))
                    return
                }
                requiredProductCopy[existingProductReferenceIndex].quantity!++
            }
            requiredProductCopy[existingProductReferenceIndex].PUIDs.push(scannedBarcode)
        }

        // or create a new one in case it doesn't exist in list
        if (existingProductReferenceIndex === null) {
            const { productReference, error } = await addProductReferenceToOrder(sku!)
            if (error) {
                toast.error(t('wms.pickPackTable.removeProductReference', { error }))
                return
            }

            const newProductReference = productReference!
            newProductReference.PUIDs = [scannedBarcode]
            newProductReference.quantity = 1
            newProductReference.optional = true
            requiredProductCopy.push(newProductReference)
        }

        toast.success(t('wms.pickPackTable.addUniqueProductSuccess', { PUID: scannedBarcode }))
        setRequiredProducts(requiredProductCopy)
    }

    function addRequiredItem(scannedBarcode: string, sku: string) {
        const requiredProductCopy = [...requiredProducts]

        for (let i = 0; i < requiredProductCopy.length; i++) {
            if (
                requiredProductCopy[i].sku === sku &&
                !requiredProductCopy[i].optional &&
                requiredProductCopy[i].PUIDs.length < requiredProductCopy[i].quantity!
            ) {
                requiredProductCopy[i].PUIDs.push(scannedBarcode)
                break
            }
        }

        // Will display mandatory cards first
        const sortedRequiredProduct = requiredProductCopy.sort((productRef) => (productRef.optional ? 1 : -1))
        setRequiredProducts(sortedRequiredProduct)
    }

    async function handleSubmit(scannedBarcode: string) {
        if (isDuplicate(scannedBarcode)) {
            const scannedProductOptionnal = requiredProducts.find((requiredProductRef) => {
                if (requiredProductRef.optional && requiredProductRef.PUIDs.includes(scannedBarcode))
                    return requiredProductRef
            })
            if (scannedProductOptionnal) {
                await removeOptionalProductReference(scannedProductOptionnal, scannedBarcode)
            } else {
                await removePickedProduct(scannedBarcode)
            }
            return
        }

        // if the scanned barcode is part of the expected second hand products, there is a special handling
        if (reservedUniqueProducts.length) {
            if (pickedUniqueProducts.some((uniqueProduct) => uniqueProduct.barcodeUid === scannedBarcode)) {
                toast.warning(t('wms.pickPackTable.secondHandProductAlreadyPicked', { PUID: scannedBarcode }))
                return
            }

            const requiredUniqueProductPUIDs =
                reservedUniqueProducts.map((uniqueProduct) => uniqueProduct.barcodeUid) || []
            if (requiredUniqueProductPUIDs.includes(scannedBarcode)) {
                const uniqueProduct = await isUniqueProductReserved(scannedBarcode)
                if (uniqueProduct) {
                    setPickedUniqueProducts([...pickedUniqueProducts, uniqueProduct])
                }
                return
            }
        }

        const { sku, error, warning } = await getSkuFromUniqueProduct(scannedBarcode)
        if (error || warning) {
            if (error) toast.error(error)
            else if (warning) toast.warning(warning)
            return
        }

        // Find scanned product in expected productList
        const isScannedProductRequired = requiredProducts.some((requiredProductRef) => {
            return (
                requiredProductRef.sku === sku &&
                !requiredProductRef.optional &&
                requiredProductRef.PUIDs.length < requiredProductRef!.quantity!
            )
        })

        if (!isScannedProductRequired && !pickedProductListValid) {
            toast.error(t('wms.pickPackTable.wrongProduct', { PUID: scannedBarcode }))
            return
        } else if (!isScannedProductRequired && pickedProductListValid) {
            await addOptionalItem(scannedBarcode, sku!)
        } else {
            addRequiredItem(scannedBarcode, sku!)
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <KeyboardEventListener
                        onSubmit={handleSubmit}
                        lockFocus={true}
                        label={t('wms.pickPackTable.title')}
                    />
                </div>
            </div>
            <Grid className={classes.productRefContainer} container>
                {requiredProducts?.map((productReference, index) => (
                    <Grid key={index} item xs={12} sm={6} lg={6} style={{ padding: '4px 15px' }}>
                        <div>
                            <div style={{ height: '0px' }}>
                                {productReference.optional && productReference.PUIDs.length == 0 && (
                                    <Fab
                                        className={classes.fab}
                                        onClick={() => removeOptionalProductReference(productReference)}
                                        data-testid={`remove-product-ref-${productReference.sku}-${
                                            productReference.optional ? 'optional' : ''
                                        }`}
                                    >
                                        <RemoveIcon />
                                    </Fab>
                                )}
                            </div>
                            <ProductPreparationCard
                                productReference={productReference}
                                dataTestId={`productPreparationCard_${index}`}
                                onClick={() => setProductReferenceInfo(productReference)}
                            />
                        </div>
                    </Grid>
                ))}
                {reservedUniqueProducts?.map((uniqueProduct, index) => (
                    <Grid key={index} item xs={12} sm={6} lg={6} style={{ padding: '4px 15px' }}>
                        <UniqueProductPreparationCard
                            uniqueProduct={uniqueProduct}
                            picked={pickedUniqueProducts.some(
                                (pickedUniqueProduct) => pickedUniqueProduct.barcodeUid === uniqueProduct.barcodeUid,
                            )}
                            dataTestId={`secondHandUniqueProductPreparationCard_${index}`}
                            onClick={(uniqueProduct, photo) => {
                                setCurrentUniqueProduct(uniqueProduct)
                                setCurrentUniqueProductPhoto(photo)
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            {productReferenceInfo && (
                <ProductOverviewModal
                    productReference={productReferenceInfo as any}
                    customer={customer}
                    handleClose={() => setProductReferenceInfo(null)}
                >
                    <StorageLocationGrid productReference={productReferenceInfo} />
                </ProductOverviewModal>
            )}
            {currentUniqueProduct && (
                <ProductOverviewModal
                    productReference={currentUniqueProduct.productReference}
                    uniqueProduct={currentUniqueProduct}
                    uniqueProductPhoto={currentUniqueProductPhoto}
                    customer={customer}
                    handleClose={() => setCurrentUniqueProduct(null)}
                />
            )}
            {pickedProductListValid && (
                <>
                    <Container maxWidth="sm">
                        <Box pb={2}>
                            <Typography className={classes.helperText}>{t('wms.pickPackTable.helper1')}</Typography>
                        </Box>
                    </Container>
                    <Container maxWidth="sm">
                        <Box className={classes.sticky} textAlign="center">
                            <Button
                                key={`btn-scan-finish`}
                                onClick={finishPreparation}
                                variant="contained"
                                className={classes.finishButton}
                                data-testid={`buttonScanFinish`}
                            >
                                {t('wms.pickPackTable.scanFinishedButton')}
                            </Button>
                        </Box>
                    </Container>
                </>
            )}
        </>
    )
}

export default PickPack
