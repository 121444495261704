import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import Page from '_organisms/Page'
import CustomerPicker from '_organisms/header/addons/CustomerPicker'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import { LOGISTICS_ACTIVITY_STATS_URL } from 'utils/routes/backend'
import { AxiosResponse } from 'axios'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'
import WhalyIframe from '_molecules/WhalyIframe'
import TabSystem from '_organisms/TabSystem'

enum LogisticsActivityTab {
    RENTAL = 'rental',
    SECOND_HAND = 'secondHand',
}
const LogisticsActivityStats: React.FC = () => {
    const { t, i18n } = useTranslation()
    const selectedCustomer = useSelectedCustomerStore((state: SelectedCustomerState) => state.selectedCustomer)
    const [dashboardUrls, setDashboardUrls] = useState<Map<string, string>>(new Map())

    dayjs.locale(i18n.language)

    async function fetchStats() {
        if (!selectedCustomer) return
        try {
            const params = `?customerId=${selectedCustomer?.id}`
            const statsRequest: AxiosResponse = await rmsApi.get(LOGISTICS_ACTIVITY_STATS_URL + params)

            const dashboardUrls = new Map([
                [LogisticsActivityTab.RENTAL, statsRequest.data.rentalDashboardUrl],
                [LogisticsActivityTab.SECOND_HAND, statsRequest.data.secondHandDashboardUrl],
            ])
            setDashboardUrls(dashboardUrls)
        } catch (e: any) {
            toast.error(e)
        }
    }

    useEffect(() => {
        if (selectedCustomer) fetchStats()
    }, [selectedCustomer])

    const customerPicker = () => <CustomerPicker initWithRandomCustomer truncateLabelAbove={140} />

    const tabLabels = [{ label: t('logisticsActivityStats.tabs.rental.menuTitle'), value: 'rentalTab' }]
    if (selectedCustomer?.secondHand)
        tabLabels.push({ label: t('logisticsActivityStats.tabs.secondHand.menuTitle'), value: 'secondHandTab' })

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.logisticsActivity')}
            tagline={t('logisticsActivityStats.tagline')}
            rightAddon={{ addon: customerPicker(), displayOnMobile: false }}
            withDivider={false}
        >
            <TabSystem tabLabels={tabLabels}>
                <WhalyIframe dashboardUrl={dashboardUrls.get(LogisticsActivityTab.RENTAL)} />
                {selectedCustomer?.secondHand && (
                    <WhalyIframe dashboardUrl={dashboardUrls.get(LogisticsActivityTab.SECOND_HAND)} />
                )}
            </TabSystem>
        </Page>
    )
}

export default LogisticsActivityStats
