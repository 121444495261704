import { useEffect } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { GET_SKUS_FOR_LOCATION_URL, SKU_LOCATION_UPDATE_URL } from 'utils/routes/backend'
import AccordionStepper from 'components/wmsStepper/AccordionStepper'
import Step from 'components/wmsStepper/Step'
import ProductWithoutDisabledStepInput from 'components/WMS/ProductWithoutDisabledStepInput'
import ProductStepSelect from 'components/WMS/ProductStepSelect'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page from '_organisms/Page'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const errors = new Map([['NOT_FOUND', 'wms.changeSkuLocation.error']])

const ChangeSkuLocation: React.FC = () => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = React.useState<number | null>(null)
    const [currentStep, setCurrentStep] = React.useState<number>(0)
    const [stepperValues, setStepperValues] = React.useState<string[]>([])
    const [possibleValues, setPossibleValues] = React.useState<{ value: string | number; label: string }[]>([])
    const [setDisabledSelectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.setDisabledSelectedCustomers,
    ])
    const [setDisabledSelectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.setDisabledSelectedWarehouse,
    ])

    const updateStorageLocation = async function (): Promise<{
        updatedCount: number
        error: string
    }> {
        const response: { updatedCount: number; error: string } = {
            updatedCount: 0,
            error: '',
        }

        const payload = {
            fromLocation: stepperValues[0],
            sku: stepperValues[1],
            toLocation: stepperValues[2],
        }

        try {
            const updateLocationRequest = await rmsApi.patch(SKU_LOCATION_UPDATE_URL, payload)
            response.updatedCount = updateLocationRequest.data
        } catch (e: any) {
            const err = errors.get(e.response?.data?.error)

            response.error = err
                ? t(err, { fromLocation: payload.fromLocation, SKU: payload.sku, toLocation: payload.toLocation })
                : t('wms.changeSkuLocation.unknownError', {
                      fromLocation: payload.fromLocation,
                      SKU: payload.sku,
                      toLocation: payload.toLocation,
                  }) + `: ${e.message}`
        }
        return response
    }

    const fetchSkusForLocation = async function (location: string): Promise<any> {
        const url = encodeURI(`${GET_SKUS_FOR_LOCATION_URL}/${location}`)
        const fetchSkusRequest = await rmsApi.get(url)

        return fetchSkusRequest.data
    }

    useEffect(() => {
        setExpanded(currentStep)
    }, [currentStep])

    useEffect(() => {
        if (stepperValues.length >= 1) updateProduct()
        // eslint-disable-next-line
    }, [stepperValues])

    useEffect(() => {
        if (possibleValues.length > 0) {
            setDisabledSelectedCustomers(true)
            setDisabledSelectedWarehouse(true)
        } else {
            setDisabledSelectedCustomers(false)
            setDisabledSelectedWarehouse(false)
        }
    }, [possibleValues])

    const handleChange = (panel: number) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
        if (isExpanded) setCurrentStep(panel)
    }

    const handleStepperUpdated = async (stepUpdated: number, submittedStep: any) => {
        let errorFetchingSkus = false

        if (stepUpdated === 0 || stepUpdated === 2) {
            const values = []

            if (stepUpdated === 0 && submittedStep) {
                const response = await fetchSkusForLocation(submittedStep)

                if (response.length <= 0) {
                    errorFetchingSkus = true
                    setPossibleValues([])
                    toast.error(t('wms.changeSkuLocation.errors.noSkuAtGivenLocation', { location: submittedStep }))
                } else {
                    for (const value of response) {
                        values.push({ label: value, value: value })
                    }
                    setPossibleValues(values)
                }
            } else if (!submittedStep) {
                errorFetchingSkus = true
                setPossibleValues([])
                toast.error(t('wms.changeSkuLocation.errors.noLocationGiven'))
            }
        }

        if (!errorFetchingSkus) {
            const newStepperValues = [...stepperValues]
            newStepperValues[stepUpdated] = submittedStep
            setStepperValues(newStepperValues)
        }
    }

    const handleNextStep = (overrideStep?: number) => {
        if (overrideStep != null && overrideStep >= 0) {
            setCurrentStep(overrideStep)
        } else {
            setCurrentStep(currentStep + 1)
        }
    }

    async function updateProduct() {
        if (stepperValues[0] && stepperValues[1] && !stepperValues[2]) {
            handleNextStep()
        }
        if (!stepperValues[0] || !stepperValues[1] || !stepperValues[2]) {
            handleNextStep()
        }
        if (stepperValues[0] && stepperValues[1] && stepperValues[2]) {
            toast.info(t('wms.changeSkuLocation.loading'))
            const response = await updateStorageLocation()
            toast.dismiss()
            if (!response.updatedCount) {
                toast.error(response.error)
            } else {
                toast.success(
                    t('wms.changeSkuLocation.success', {
                        fromLocation: stepperValues[0],
                        SKU: stepperValues[1],
                        toLocation: stepperValues[2],
                        updatedCount: response.updatedCount,
                    }),
                )
            }
            handleNextStep(0)
            setStepperValues([])
        }
    }

    return (
        <Page title={t('wms.changeSkuLocation.title')}>
            <AccordionStepper>
                <Step
                    expanded={expanded}
                    handleChange={handleChange}
                    stepTitle={t('wms.changeSkuLocation.fromLocation.title')}
                    stepSubTitle={t('wms.changeSkuLocation.fromLocation.subTitle')}
                >
                    <ProductWithoutDisabledStepInput
                        label={t('wms.changeSkuLocation.fromLocation.inputLabel')}
                        handleStepperUpdated={handleStepperUpdated}
                        defaultValue={stepperValues[0]}
                    />
                </Step>
                <Step
                    expanded={expanded}
                    handleChange={handleChange}
                    stepTitle={t('wms.changeSkuLocation.sku.title')}
                    stepSubTitle={t('wms.changeSkuLocation.sku.subTitle')}
                >
                    <ProductStepSelect
                        possibleValues={possibleValues}
                        onChange={handleStepperUpdated}
                        defaultValue={stepperValues[1] || ''}
                    />
                </Step>
                <Step
                    expanded={expanded}
                    handleChange={handleChange}
                    stepTitle={t('wms.changeSkuLocation.toLocation.title')}
                    stepSubTitle={t('wms.changeSkuLocation.toLocation.subTitle')}
                >
                    <ProductWithoutDisabledStepInput
                        label={t('wms.changeSkuLocation.toLocation.inputLabel')}
                        handleStepperUpdated={handleStepperUpdated}
                        defaultValue={stepperValues[2]}
                    />
                </Step>
            </AccordionStepper>
        </Page>
    )
}

export default ChangeSkuLocation
