import * as React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Logo from 'components/Logo'
import Text from '_atoms/text/Text'
import Button from '_atoms/buttons/Button'
import { useAuth } from 'utils/hooks/useAuth'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary[600],
    },
    text: {
        color: theme.palette.common.white,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        gap: `${theme.space(8)}px`,
    },
    logo: {
        width: 'fit-content',
        '& .MuiSvgIcon-root': {
            height: 150,
        },
    },
    button: {
        width: '100%',
    },
}))

const Login: React.FC = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { login } = useAuth()

    return (
        <Box className={classes.root}>
            <Container component="main" maxWidth="xs" className={classes.container}>
                <Box width={160} className={classes.logo}>
                    <Logo withTagline />
                </Box>
                <Text variant="H1" className={classes.text}>
                    {t('login.title')}
                </Text>
                <Button
                    variant="fill"
                    color="secondary"
                    data-testid="loginKeycloak"
                    className={classes.button}
                    type="submit"
                    onClick={login}
                >
                    {t('login.signin')}
                </Button>
            </Container>
        </Box>
    )
}
export default Login
