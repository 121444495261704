import create from 'zustand'
import { persist } from 'zustand/middleware'

export interface IsDrawerOpenState {
    isDrawerOpen: boolean
    setIsDrawerOpen: (isDrawerOpen: boolean) => void
}

const useIsDrawerOpenStore = create<IsDrawerOpenState>()(
    persist(
        (set) => ({
            isDrawerOpen: false,
            setIsDrawerOpen: (isDrawerOpen: boolean) => set(() => ({ isDrawerOpen: isDrawerOpen })),
        }),
        {
            name: 'is-open-drawer',
        },
    ),
)

export default useIsDrawerOpenStore
