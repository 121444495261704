import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import StorageLocation from './StorageLocation'
import TitledHr from './TitledHr'
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        storageLocationGrid: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: `${theme.spacing(1)}px`,
            flexDirection: 'column',
            padding: theme.spacing(0.5, 2, 1),
        },
        locationContainer: {
            flex: '1 0 30%',
            height: theme.spacing(7),
        },
        icon: {
            height: '2em',
            width: '2em',
            marginRight: theme.spacing(0.5),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(0.25),
                height: '1.5em',
                width: '1.5em',
            },
        },
    }),
)

type StorageLocationGridProps = {
    productReference: any
}

const StorageLocationGrid: React.FC<StorageLocationGridProps> = ({ productReference }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const highestLocationNameLength = Object.keys(productReference.uniqueProductsLocation).reduce(
        (current, next) => (current.length > next.length ? current : next),
        'N/A',
    ).length

    const locationsQuantity = Object.values(productReference.uniqueProductsLocation) as number[]
    const highestLocationQuantityLength = locationsQuantity
        .reduce((current: number, next: number): number => (current > next ? current : next), 0)
        .toString().length

    return (
        <Grid className={classes.storageLocationGrid} container data-testid={`productLocations`}>
            {Object.keys(productReference.uniqueProductsLocation).length > 0 && (
                <>
                    <TitledHr title={t('wms.storageLocations')} mdiIcon={<GoogleMapsIcon />} />
                    {Object.keys(productReference.uniqueProductsLocation).map((locationName, index) => {
                        const quantity = productReference.uniqueProductsLocation[locationName]
                        return (
                            <Grid key={index} className={classes.locationContainer} item xs={12} sm={12} lg={12}>
                                <StorageLocation
                                    location={locationName}
                                    quantity={quantity}
                                    highestLocationNameLength={highestLocationNameLength}
                                    highestLocationQuantityLength={highestLocationQuantityLength}
                                />
                            </Grid>
                        )
                    })}
                </>
            )}
        </Grid>
    )
}

export default StorageLocationGrid
