export const ACCOUNTING = '/app/accounting'
export const AVAILABLE_DEMO_STACK = '/app/demo/customers'
export const B2C_INVOICES = '/app/accounting/invoices/b2c'
export const CALENDAR_EVENTS_URL = '/app/calendar/events'
export const CHECK_IF_EAN_EXISTS_URL = '/app/eans'
export const DEPLOY_DEMO_STACK = '/app/demo/deploy-demo'
export const FILE_DELETE_URL = '/app/file/:id'
export const FILE_SIGNED_URL_RMS_BUCKET = '/app/file/signed-url-upload'
export const FILE_SIGNED_URL_CUSTOMER_BUCKET = (customerId: number | string): string =>
    `/app/file/signed-url-upload/${customerId}`
export const FILE_DOWNLOAD_URL = '/app/file/download'
export const GET_SKUS_FOR_LOCATION_URL = '/app/wms/products/skus-at-location'
export const INSTRUCTION_IMAGE_DOWNLOAD_ROUTE = '/app/instructions/image-download-url'
export const INSTRUCTION_IMAGE_UPLOAD_ROUTE = '/app/instructions/image-upload-url'
export const OMS_ORDERS_LIST_TO_CSV = 'app/statisticsOms/orders-list-to-csv'
export const OMS_PRODUCTS_LIST_TO_CSV = 'app/statisticsOms/products-list-to-csv'
export const OMS_RENTAL_STATS_URL = 'app/statisticsOms/rental-stats'
export const OMS_STATS_URL = '/app/statisticsOms/stats-ecommerce'
export const OPERATION_TIME_AVERAGE_URL = '/app/operations/timeAverage'
export const ORDER_LIST_URL = '/app/orders'
export const PARCELS_BY_FILTERS_URL = 'app/parcels/by-filters'
export const PARCELS_BY_FILTERS_TO_CSV_URL = 'app/parcels/by-filters/to-csv'
export const PARCELS_URL = '/app/parcels'
export const PARCEL_BY_ID_URL = (parcelId: number | string) => `${PARCELS_URL}/${parcelId}`
export const PARCEL_BY_BARCODE_UID_URL = (puid: string) => `/app/parcels/by-puid/${encodeURIComponent(puid)}`
export const PREPARATIONS_ROUTE = '/app/wms/preparations'
export const PREPARATIONS_ROUTE_BY_ID = (id: number | string) => `${PREPARATIONS_ROUTE}/${id}`
export const PREPARATIONS_TOSHIP_ROUTE = '/app/wms/preparations/to-ship'
export const PRODUCT_REFERENCE_URL = '/app/product-references'
export const PRODUCT_REFERENCE_BY_PRODUCT_ID_URL = (productId?: number) =>
    `/app/products/${productId}/product-references`
export const PRODUCT_TIME_AVERAGE_URL = '/app/products/refitTimeAverage'
export const PROJECT_MANAGER_STAT_URL = '/app/statistics/projectManagerMenu'
export const REFIT_STATS_URL = '/app/statistics/refit-stats'
export const LOGISTICS_ACTIVITY_STATS_URL = '/app/statistics/logistics-activity-stats'
export const REFIT_CATEGORIES_ROUTE = 'app/refit-categories'
export const REFIT_INSTRUCTIONS_ROUTE = 'app/refit-instructions'
export const CUSTOMER_REFIT_INSTRUCTIONS_ROUTE = (customerId: number) =>
    `app/refit-instructions/titles-and-id?customerId=${customerId}`
export const REFIT_GROUP_ROUTE = (customerId?: number, groupId?: number) =>
    `app/refit-groups/${groupId || ''}${customerId ? '?customerId=' + customerId : ''}`
export const REFIT_GROUP_INSTRUCTION_ROUTE = (groupId: number) => `app/refit-groups/${groupId}/instructions-to-groups`
export const LINKED_PRODUCTS_ROUTE = (groupId: number) => `app/refit-groups/${groupId}/products`
export const UNLINKED_PRODUCTS_ROUTE = (customerId: number) =>
    `app/products/without-refit-group?customerId=${customerId}`
export const REFIT_GROUP_LINK_PRODUCTS_ROUTE = (groupId: number, productIds: number[]) =>
    `app/refit-groups/${groupId}/products?${productIds.map((productId) => 'productIds[]=' + productId).join('&')}`
export const REFIT_GROUP_UNLINK_PRODUCTS_ROUTE = (groupId: number, productIds: number[]) =>
    `app/refit-groups/${groupId}/products?${productIds.map((productId) => 'productIds[]=' + productId).join('&')}`
export const UPDATE_INSTRUCTION_POSITION_ROUTE = (groupId: number, instructionToRefitGroupRelationId: number) =>
    `app/refit-groups/${groupId}/instructions-to-groups/${instructionToRefitGroupRelationId}`
export const ADD_INSTRUCTION_TO_GROUP = (groupId: number, instructionId: number) =>
    `app/refit-groups/${groupId}/instructions/${instructionId}`
export const REMOVE_INSTRUCTION_FROM_GROUP = (groupId: number, instructionLinkId: number) =>
    `app/refit-groups/${groupId}/instructions-to-groups/${instructionLinkId}`
export const REFIT_INSTRUCTION_ROUTE = (instructionId: number) => `app/refit-instructions/${instructionId}`
export const REFIT_SCAN_UNIQUE_PRODUCT = (barcode: string) => `/app/uniqueproducts/${barcode}/refit`
export const REFIT_OPERATIONS = 'app/refit-operations'
export const UPDATE_REFIT_OPERATION = (operationId: number) => `${REFIT_OPERATIONS}/${operationId}`
export const REFIT_INSTRUCTION_DOWNLOAD_FILES = (instructionId: number) =>
    `app/refit-instructions/${instructionId}/download-files`
export const CREATE_REFIT_ISSUE = (refitOperationId: number) => `app/refit-operations/${refitOperationId}/refit-issue`
export const AFTERSALES_STATS_URL = '/app/statistics/aftersales-stats'
export const TAKEBACKS_STATS_URL = '/app/statistics/second-hand/takebacks'
export const VOUCHERS_STATS_URL = '/app/statistics/second-hand/vouchers'
export const REVIEWS_TO_CSV_URL = 'app/reviews/export-to-csv'
export const RETURN_ITEM_BY_PRODUCT_AND_PARCEL_URL = '/app/return-item/by-unique-product-and-parcel'
export const RETURN_ITEM_UPDATE_URL = (returnItemId: string | number): string => `app/return-item/${returnItemId}`
export const RETURN_STATS_URL = '/app/statistics/return-stats'
export const RETURNED_PARCEL_URL = (parcelId: string | number | undefined): string => `app/parcels/${parcelId}/return`
export const SHIPMENT_BY_FILTERS_URL = 'app/shipments/by-filters'
export const SHIPMENT_STATS_URL = '/app/statistics/shipment-stats'
export const SHIPMENTS_URL = '/app/shipments'
export const SHIPMENTS_PARCELS_URL = (shipmentId: number | string) => `${SHIPMENTS_URL}/${shipmentId}/parcels`
export const SHIPMENTS_DELIVERY_NOTE_URL = (shipmentId: number | string) =>
    `${SHIPMENTS_URL}/${shipmentId}/delivery-note`
export const PARCELS_READY_TO_SEND_URL = 'app/parcels/ready-to-send'
export const GENERATE_PARCEL_COLLECTION_URL = 'app/parcels/generate-parcel-collection-pdf'
export const SKU_LOCATION_UPDATE_URL = '/app/wms/products/update-sku-location'
export const STOCK_STATS_LOGISTICS_URL = '/app/statistics/logistics-stats'
export const STOCK_STATS_UNIQUE_PRODUCT_STATUSES_URL = '/app/statistics/unique-product-statuses-stats'
export const STOCK_STATS_UNIQUE_PRODUCT_TURNOVER_URL = '/app/statistics/unique-product-turnover-stats'
export const SUPERADMIN_STAT_URL = '/app/statistics/superAdminMenu'
export const UNIQUE_PRODUCT_UPDATE_LOCATION_URL = '/app/uniqueproducts/storage-location'
export const UNIQUE_PRODUCT_UPDATE_PUID_URL = 'app/uniqueproducts/barcode-uid'
export const UNIQUE_PRODUCT_UPDATE_EAN_OR_SKU = (id: string | number) => `app/uniqueproducts/${id}/ean-or-sku`
export const UNIQUE_PRODUCTS_URL = '/app/uniqueproducts'
export const UNIQUE_PRODUCTS_BY_ID_URL = (id: string | number) => `/app/uniqueproducts/${id}`
export const UNIQUE_PRODUCTS_PATCH_STATUS_URL = (barcodeUid: string) =>
    `/app/uniqueproducts/${encodeURIComponent(barcodeUid)}/status`
export const UNIQUE_PRODUCTS_LAST_COMMENT_URL = (uniqueProductIds: number[]) =>
    `/app/uniqueproducts/last-order-comment?${uniqueProductIds
        .map((uniqueProductId) => 'uniqueProductIds[]=' + uniqueProductId)
        .join('&')}`
export const UNIQUE_PRODUCTS_BY_BARCODEUID_URL = (barcodeUid: string) =>
    `/app/uniqueproducts/by-barcodeuid/${barcodeUid}`
export const SECOND_HAND_REQUEST_BY_FILTERS_URL = 'app/second-hand/by-filters'
export const SECOND_HAND_UNIQUE_PRODUCTS_URL = (barcodeUid: string) =>
    `/app/uniqueproducts/${encodeURIComponent(barcodeUid)}/unpublished`
export const SECOND_HAND_PUBLISH_URL = (barcodeUid: string) =>
    `/app/uniqueproducts/${encodeURIComponent(barcodeUid)}/publish`
export const SECOND_HAND_UPDATE_PHOTOS_URL = (id: number) => `/app/uniqueproducts/${id}/photos`
export const UNIQUE_PRODUCT_UPDATE_URL = (id: number) => `/app/uniqueproducts/${id}`
export const UNIQUE_PRODUCT_DELETE_URL = (barcodeUid: string) => `/app/uniqueproducts/${encodeURIComponent(barcodeUid)}`
export const SECOND_HAND_REQUEST_URL = '/app/second-hand/:secondHandRequestId'
export const SECOND_HAND_POLICY_BOOK_URL = '/app/second-hand/policy-book'
export const SELLER_HP_STATS_ROUTE = '/app/statistics/seller-hp-stats'
export const OPERATOR_HP_STATS_ROUTE = '/app/statistics/operator-hp-stats'
export const SECOND_HAND_HP_STATS_ROUTE = '/app/statistics/second-hand-hp-stats'
export const PROCESS_UNIQUE_PRODUCT_RETURN = (parcelId: number): string =>
    `app/parcels/${parcelId}/process-unique-product-return`
export const UNIQUEPRODUCTS_HISTORY_URL = '/app/uniqueproducts/history/list'
export const USER_INFO = '/app/me'
export const WMS_ADD_PRODUCT_REFERENCE_TO_PREPARATION_URL = '/app/wms/preparations/:id/add-optional-product-reference'
export const WMS_ADD_PRODUCTS_TO_PREPARATION_URL = '/app/wms/preparations/unique-products'
export const WMS_ADD_UNIQUE_PRODUCT_URL = '/app/wms/products/add-unique-products'
export const WMS_GET_SKU_FROM_PUID_URL = '/app/wms/products/unique-product-sku'
export const CHECK_UNIQUE_PRODUCT_RESERVED_STATUS = (preparationId: number, barcodeUid: string): string =>
    `app/wms/preparations/${preparationId}/is-unique-product-reserved?barcodeUid=${encodeURIComponent(barcodeUid)}`
export const WMS_PRODUCT_REFERENCE_STOCK_TO_CSV_URL = '/app/wms/products/product-reference-stock-to-csv'
export const WMS_PRODUCT_REFERENCE_STOCK_URL = '/app/wms/products/product-reference-stock'
export const WMS_REMOVE_PRODUCT_REFERENCE_TO_PREPARATION_URL =
    '/app/wms/preparations/:id/remove-optional-product-reference'
export const WMS_UNIQUE_PRODUCT_LIST_URL = '/app/wms/products/uniqueProductList'
export const WMS_UNIQUE_PRODUCT_LIST_URL_TO_CSV = '/app/wms/products/unique-product-list-to-csv'
export const WORKER_STAT_URL = '/app/statistics/workerMenu'
export const BATCHES_URL = '/app/batches'
export const PREPARATION_STATS_URL = '/app/orders/statistics'
export const ORDERS_NOT_IN_BATCH_URL = 'app/orders/not-in-batch'
export const PRODUCT_REFERENCES_BY_LABEL_REFERENCE = (labelReference: string) =>
    `${PRODUCT_REFERENCE_URL}/search?ref=${labelReference}`
export const UNPROCESSED_PICK_PACK_ISSUES_URL = 'app/orders/pick-pack-issues'
export const BATCH_PACKING_INFOS_URL = (idPerWarehouse: string) =>
    `app/batches/by-id-per-warehouse/${idPerWarehouse}/packing-infos`
export const BATCH_PICKING_INFOS_URL = (idPerWarehouse: string) =>
    `app/batches/by-id-per-warehouse/${idPerWarehouse}/picking-infos`
export const UPDATE_BATCH_URL = (idPerWarehouse: string | number) => `app/batches/by-id-per-warehouse/${idPerWarehouse}`
export const PICK_UNIQUE_PRODUCT_URL = (batchId: string | number) => `app/batches/${batchId}/pick-product`
export const ORDER_PACKING_LIST_URL = (orderId: string) => `app/orders/${orderId}/packing-info`
export const SHIPMENT_PARCELS_URL = (shipmentId: string | number) => `${SHIPMENTS_URL}/${shipmentId}/parcels`
export const PICK_PACK_ISSUE_URL = `app/pick-pack-issues`
export const ORDER_UNIQUE_PRODUCTS_TO_RESTOCK = (orderId: number | string = ':orderId') =>
    `app/orders/${orderId}/unique-products/to-restock`
export const LOCATIONS_FROM_SKU = (sku: string) => `${PRODUCT_REFERENCE_URL}/${sku}/puids-location`
export const PACK_UNIQUE_PRODUCT_URL = (orderId: string | number) => `app/orders/${orderId}/pack-product`
export const ORDERS_WITH_PICK_PACK_ISSUES_COUNT_URL = 'app/orders/pick-pack-issues-count'
export const REMOVE_ORDER_FROM_BATCH_ROUTE = (orderId: string | number) => `app/orders/${orderId}/batch`
