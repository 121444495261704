import * as React from 'react'
import { useState, useCallback } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Dialog, Drawer, ModalProps, useMediaQuery, useTheme } from '@material-ui/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import MobileHeader from './MobileHeader'
import DividerHeader from './DividerHeader'
import CloseHeader from './CloseHeader'
import Text from '_atoms/text/Text'

const useStyles = makeStyles<Theme, { mobileDarkTheme: boolean; width: number }>((theme) =>
    createStyles({
        drawer: {
            maxHeight: '90vh',
            borderRadius: theme.space(3, 3, 0, 0),
            padding: theme.space(0, 4, 4),
            background: ({ mobileDarkTheme }) =>
                mobileDarkTheme ? theme.palette.neutral[900] : theme.palette.common.white,
        },
        contentMobile: {
            paddingTop: theme.space(3),
        },
        modal: {
            maxHeight: '90vh',
            borderRadius: theme.space(2),
            width: ({ width }) => width,
        },
        contentDesktop: {
            padding: theme.space(7, 12, 8, 12),
        },
        tagline: {
            opacity: 0.5,
            color: theme.palette.common.black,
            marginBottom: theme.space(4),
        },
    }),
)

export enum HeaderType {
    NONE = 'NONE',
    CLOSE = 'CLOSE',
    DIVIDER = 'DIVIDER',
    MOBILE = 'MOBILE',
}

interface CommonModalProps extends Omit<ModalProps, 'children'> {
    children: JSX.Element | JSX.Element[]
    backdropClose?: boolean
    mobileDarkTheme?: boolean
    mobileDrawer?: boolean
    width?: number
}

type NoneHeaderProps = {
    headerType: HeaderType.NONE
    title?: never
    tagline?: never
    headerIcon?: never
    headerIconColor?: never
    handleClose?: never
    withDesktopTagline?: never
}

type CloseHeaderProps = {
    headerType: HeaderType.CLOSE
    title?: never
    tagline?: never
    headerIcon?: never
    headerIconColor?: never
    handleClose: () => void
    withDesktopTagline?: never
}

type DividerHeaderProps = {
    headerType: HeaderType.DIVIDER
    title: string
    tagline?: never
    headerIcon?: never
    headerIconColor?: never
    handleClose?: () => void
    withDesktopTagline?: never
}

type MobileHeaderProps = {
    headerType: HeaderType.MOBILE
    title: string
    tagline?: string
    headerIcon?: IconProp
    headerIconColor?: string
    handleClose?: () => void
    withDesktopTagline?: boolean
}

type ConditionalModalProps = NoneHeaderProps | CloseHeaderProps | DividerHeaderProps | MobileHeaderProps

export type ModalWithHeaderProps = CommonModalProps & ConditionalModalProps

const ModalWithHeader: React.FC<ModalWithHeaderProps> = ({
    children,
    title,
    tagline,
    headerIcon,
    headerIconColor,
    handleClose,
    className = '',
    width = 800,
    backdropClose = true,
    mobileDarkTheme = false,
    mobileDrawer = true,
    withDesktopTagline = false,
    headerType = HeaderType.DIVIDER,
    ...props
}) => {
    const classes = useStyles({ mobileDarkTheme, width })
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
    const [contentMarginTop, setContentMarginTop] = useState(0)

    const headerRef = useCallback((node: HTMLDivElement) => {
        if (node) {
            setContentMarginTop(node.clientHeight)
        }
    }, [])

    function onBackdropClick() {
        if (backdropClose && handleClose) handleClose()
    }

    const switchHeader = (headerType: HeaderType) => {
        switch (headerType) {
            case HeaderType.NONE:
                return <></>
            case HeaderType.CLOSE:
                return <CloseHeader handleClose={handleClose!} width={width} ref={headerRef} />
            case HeaderType.DIVIDER:
                return <DividerHeader title={title!} handleClose={handleClose} width={width} ref={headerRef} />
            case HeaderType.MOBILE:
                if (isMobile) {
                    return (
                        <MobileHeader
                            title={title!}
                            tagline={tagline}
                            handleClose={handleClose}
                            mobileDarkTheme={mobileDarkTheme}
                            headerIcon={headerIcon!}
                            headerIconColor={headerIconColor!}
                            ref={headerRef}
                        />
                    )
                }
                return <DividerHeader title={title!} handleClose={handleClose} width={width} ref={headerRef} />
        }
    }

    if (isMobile && mobileDrawer) {
        return (
            <Drawer
                {...props}
                anchor={'bottom'}
                transitionDuration={200}
                PaperProps={{ className: `${classes.drawer} ${className}` }}
            >
                <div className={tagline ? classes.contentMobile : ''} style={{ marginTop: contentMarginTop }}>
                    {children}
                </div>
                {switchHeader(headerType)}
            </Drawer>
        )
    }

    return (
        <Dialog
            {...props}
            maxWidth={false}
            BackdropProps={{ timeout: 500 }}
            onBackdropClick={onBackdropClick}
            PaperProps={{ className: `${classes.modal} ${className}` }}
        >
            <>
                <div className={classes.contentDesktop} style={{ marginTop: contentMarginTop }}>
                    {withDesktopTagline && (
                        <Text variant="P5" className={classes.tagline}>
                            {tagline}
                        </Text>
                    )}
                    {children}
                </div>
                {switchHeader(headerType)}
            </>
        </Dialog>
    )
}

export default ModalWithHeader
