import React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import { BasicText } from '_atoms/text/BasicText'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        labelText: {
            color: theme.palette.grey[600],
        },
        parcelText: {
            color: theme.palette.grey[800],
            fontWeight: 500,
            marginTop: theme.spacing(1),
        },
    }),
)

type TakebackParcelInfosOverviewProps = {
    customerName: string
    carrier?: string
    parcelsInfos?: {
        barcode?: string
        trackingLink?: string
        trackingCode?: string
        pdfUrl?: string
    }[]
    hideBarcode?: boolean
}

const TakebackParcelInfosOverview: React.FC<TakebackParcelInfosOverviewProps> = ({
    customerName,
    carrier,
    parcelsInfos,
    hideBarcode = false,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <SectionTitle className={classes.sectionTitle} size="large">
                {t('returns.parcelInfos.parcelInfos')}
            </SectionTitle>
            <Grid container>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('takebacks.brand')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {customerName}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.parcelInfos.carrier')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {carrier}
                </Grid>
                {parcelsInfos?.map((parcel, index) => {
                    return (
                        <Grid xs={12} container key={index}>
                            {parcelsInfos.length > 1 && (
                                <Grid xs={12} item>
                                    <BasicText className={classes.parcelText}>{`${t('menu.returns.parcel')} ${
                                        index + 1
                                    }`}</BasicText>
                                </Grid>
                            )}
                            {!hideBarcode && (
                                <>
                                    <Grid xs={6} item>
                                        <BasicText className={classes.labelText}>
                                            {t('returns.parcelInfos.barcode')}
                                        </BasicText>
                                    </Grid>
                                    <Grid xs={6} item>
                                        {parcel?.barcode}
                                    </Grid>
                                </>
                            )}
                            <Grid xs={6} item>
                                <BasicText className={classes.labelText}>{t('returns.parcelInfos.tracking')}</BasicText>
                            </Grid>
                            <Grid xs={6} item>
                                {<a href={parcel?.trackingLink}>{parcel?.trackingCode}</a>}
                            </Grid>
                            <Grid xs={6} item>
                                <BasicText className={classes.labelText}>{t('returns.parcelInfos.label')}</BasicText>
                            </Grid>
                            <Grid xs={6} item>
                                {<a href={parcel?.pdfUrl}>{t('returns.parcelInfos.label')}</a>}
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

export default TakebackParcelInfosOverview
