import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import Page from '_organisms/Page'
import CustomerPicker from '_organisms/header/addons/CustomerPicker'
import useSelectedCustomerStore, { SelectedCustomerState } from 'utils/store/useSelectedCustomer.store'
import { REFIT_STATS_URL } from 'utils/routes/backend'
import { AxiosResponse } from 'axios'
import rmsApi from 'utils/api'
import { toast } from 'utils/toast'
import WhalyIframe from '_molecules/WhalyIframe'
import TabSystem from '_organisms/TabSystem'

enum RefitTab {
    GENERAL = 'general',
    TIME = 'time',
}
const RefitStatsApp: React.FC = () => {
    const { t, i18n } = useTranslation()
    const [selectedCustomer] = useSelectedCustomerStore((state: SelectedCustomerState) => [state.selectedCustomer])
    const [dashboardUrls, setDashboardUrls] = useState<Map<string, string>>(new Map())

    dayjs.locale(i18n.language)

    async function fetchStats() {
        if (!selectedCustomer) return
        try {
            const params = `?customerId=${selectedCustomer?.id}`
            const statsRequest: AxiosResponse = await rmsApi.get(REFIT_STATS_URL + params)

            const dashboardUrls = new Map([
                [RefitTab.GENERAL, statsRequest.data.generalDashboardUrl],
                [RefitTab.TIME, statsRequest.data.timeDashboardUrl],
            ])
            setDashboardUrls(dashboardUrls)
        } catch (e: any) {
            toast.error(e)
        }
    }

    useEffect(() => {
        if (selectedCustomer) fetchStats()
    }, [selectedCustomer])

    const customerPicker = () => <CustomerPicker initWithRandomCustomer truncateLabelAbove={140} />

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.refit')}
            tagline={t('refitStats.tagline')}
            rightAddon={{ addon: customerPicker(), displayOnMobile: false }}
            withDivider={false}
        >
            <TabSystem
                tabLabels={[
                    { label: t('refitStats.tabs.general.menuTitle'), value: 'generalTab' },
                    { label: t('refitStats.tabs.time.menuTitle'), value: 'timeTab' },
                ]}
            >
                <WhalyIframe dashboardUrl={dashboardUrls.get(RefitTab.GENERAL)} />
                <WhalyIframe dashboardUrl={dashboardUrls.get(RefitTab.TIME)} />
            </TabSystem>
        </Page>
    )
}

export default RefitStatsApp
