import * as React from 'react'
import { Box, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import Header, { HeaderProps } from '_organisms/header/Header'
import { BASIC_HEADER_HEIGHT } from './header/HeaderMobile'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'

type StyleProps = {
    contentPaddingTopMobile?: number
    contentPaddingTopDesktop?: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    page: {
        position: 'relative',
        minHeight: '100vh',
        height: 'fit-content',
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        paddingBottom: theme.space(4),
        [theme.breakpoints.down('sm')]: {
            paddingTop: ({ contentPaddingTopMobile }: StyleProps) => theme.space(contentPaddingTopMobile),
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(100vw - 340px)',
        },
    },
    content: {
        [theme.breakpoints.up('md')]: {
            padding: ({ contentPaddingTopDesktop }: StyleProps) => theme.space(contentPaddingTopDesktop, 12),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.space(0, 3),
            backgroundColor: '#fff',
        },
    },
    footerBox: {
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            padding: theme.space(3, 4),
            backgroundColor: theme.palette.common.white,
            boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
        },
    },
    footer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}))

export enum HeaderStyleEnum {
    DEFAULT = 'DEFAULT',
    WITH_TOOLBAR = 'WITH_TOOLBAR',
    HOMEPAGE = 'HOMEPAGE',
}

const headerStylesParams = {
    [HeaderStyleEnum.DEFAULT]: {
        paddingBottom: 4,
        blueHeight: 3,
    },
    [HeaderStyleEnum.WITH_TOOLBAR]: {
        paddingBottom: 8,
        blueHeight: 9,
    },
    [HeaderStyleEnum.HOMEPAGE]: {
        paddingBottom: 0,
        blueHeight: 0,
    },
}

export interface PageProps extends HeaderProps, StyleProps {
    children?: React.ReactNode
    withHeader?: boolean
    headerStyle?: HeaderStyleEnum
    className?: string
    footerOnMobile?: React.ReactElement | null
}

//list of url who need to disable selected customers
const urlsToDisableSelectedCustomers = [/\/picking\/\d+/, /\/packing-batch\/\d+/, /\/packing-order\/\d+/]

const Page: React.FC<PageProps> = ({
    children,
    contentPaddingTopMobile = 3,
    contentPaddingTopDesktop,
    withHeader = true,
    headerStyle = HeaderStyleEnum.DEFAULT,
    className = '',
    footerOnMobile = null,
    ...headerProps
}) => {
    const location = useLocation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const blueHeight = headerStylesParams[headerStyle].blueHeight
    const paddingBottom = headerStylesParams[headerStyle].paddingBottom
    const { withDivider } = headerProps
    const [
        disabledSelectedCustomers,
        setDisabledSelectedCustomers,
    ] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.disabledSelectedCustomers,
        state.setDisabledSelectedCustomers,
    ])
    const [disabledSelectedWarehouse, setDisabledSelectedWarehouse] = useSelectedWarehouseStore((state) => [
        state.disabledSelectedWarehouse,
        state.setDisabledSelectedWarehouse,
    ])

    const classes = useStyles({
        contentPaddingTopMobile: BASIC_HEADER_HEIGHT + paddingBottom + blueHeight + contentPaddingTopMobile + 2,
        contentPaddingTopDesktop:
            contentPaddingTopDesktop !== undefined ? contentPaddingTopDesktop : withDivider === false ? 0 : 6,
    })

    useEffect(() => {
        if (disabledSelectedCustomers) {
            setDisabledSelectedCustomers(false)
        }
        if (disabledSelectedWarehouse) {
            setDisabledSelectedWarehouse(false)
        }
        //check if location.pathname is included in urlToDisableSelectedCustomers
        if (urlsToDisableSelectedCustomers.some((url) => new RegExp(url).test(location.pathname))) {
            setDisabledSelectedCustomers(true)
        }
    }, [location])

    return (
        <div className={`${classes.page} ${className}`} data-testid="pageContainer">
            {withHeader && (
                <Header
                    {...headerProps}
                    mobileStyle={{ blueHeight, paddingBottom }}
                    isHomepage={headerStyle === HeaderStyleEnum.HOMEPAGE}
                />
            )}
            {footerOnMobile && isMobile && (
                <Box textAlign="center" className={classes.footerBox}>
                    {React.cloneElement(footerOnMobile!, {
                        className: `${classes.footer} ${footerOnMobile.props.className}`,
                    })}
                </Box>
            )}
            <div className={classes.content}>{children}</div>
        </div>
    )
}

export default Page
