import * as React from 'react'
import { useEffect, useState } from 'react'
import { createStyles, makeStyles, MenuItem, Theme, Divider, Tooltip, Popover } from '@material-ui/core'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import InputField from '_atoms/inputs/InputField'
import BasicCheckbox from '_atoms/inputs/Checkbox'
import { useTranslation } from 'react-i18next'
import Button from '_atoms/buttons/Button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dropDownButton: {
            height: theme.space(6),
            width: '100%',
            border: `solid 1px ${theme.palette.neutral[200]}`,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.space(1, 2),
            lineHeight: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(12),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        placeholder: {
            color: theme.palette.neutral[500],
        },
        inputFilter: {
            height: '100%',
            width: '100%',
        },
        menu: {
            padding: theme.space(3),
            marginTop: theme.space(1),
            border: `1px solid ${theme.palette.neutral[200]}`,
            fontSize: '12px',
            fontWeight: 400,
            borderRadius: 0,
            boxShadow: 'none',
            overflow: 'scroll',
            maxHeight: theme.space(80),
            '& ul': {
                padding: 0,
            },
        },
        option: {
            cursor: 'pointer',
            color: theme.palette.neutral[800],
            fontWeight: 300,
            lineHeight: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(12),
            padding: theme.space(2 / 5, 2),
            '&:hover': {
                backgroundColor: theme.palette.neutral[100],
            },
        },
        match: {
            fontWeight: 500,
        },
        searchInput: {
            width: '100%',
        },
        divider: {
            marginTop: theme.space(3),
            marginBottom: theme.space(2),
        },
    }),
)

type DropDownPickerProps = {
    placeholder?: string
    options: string[] | undefined
    value: string | string[]
    submitSelection: (value: string) => void
    multiple?: boolean
    truncateLabelAbove?: number
    withFilter?: boolean
    disabled?: boolean
    className?: string
    menuClassName?: string
    color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'neutral'
    variant?: 'fill' | 'stroke' | 'ghost'
    endIcon?: IconProp
    iconClass?: string
    [props: string]: any
}

const DropDownPicker: React.FC<DropDownPickerProps> = ({
    placeholder = '',
    multiple = false,
    value = multiple ? [] : '',
    submitSelection,
    options,
    truncateLabelAbove,
    withFilter = true,
    disabled = false,
    color = 'primary',
    variant = 'fill',
    className = '',
    menuClassName = '',
    endIcon,
    iconClass,
    ...props
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)
    const [filter, setFilter] = useState<string>('')

    function changeSelection(option: string) {
        handleClose()
        submitSelection(option)
    }

    function handleClick(event: any) {
        if (disabled) return
        if (!anchorEl) setAnchorEl(event.currentTarget)
        setIsMenuOpened(true)
    }

    function handleClose() {
        setIsMenuOpened(false)
        setFilter('')
    }

    useEffect(() => {
        if (value && !multiple) {
            handleClose()
        }
        // eslint-disable-next-line
    }, [value])

    const filteredOptions = options?.reduce(function (filtered: any[], option: string, index: number) {
        if (!filter)
            filtered.push(
                multiple ? (
                    <BasicCheckbox
                        className={classes.option}
                        checked={value.includes(option)}
                        setValue={() => changeSelection(option)}
                        label={option}
                        data-testid={option}
                    />
                ) : (
                    <MenuItem
                        key={index}
                        className={classes.option}
                        onClick={() => changeSelection(option)}
                        data-testid={option}
                    >
                        {option}
                    </MenuItem>
                ),
            )
        else {
            const label = option.replace(/ /g, '\u00A0') // handle space display and match
            const filterValue = filter?.replace(/ /g, '\u00A0') || ''

            const matchIndex = label.toLowerCase().indexOf(filterValue.toLowerCase())
            if (matchIndex !== -1) {
                const before = matchIndex === 0 ? '' : label.substring(0, matchIndex)
                const after =
                    label.length > matchIndex + filterValue.length
                        ? label.substring(matchIndex + filterValue.length)
                        : ''

                const filteredString = (
                    <>
                        {before}
                        <span className={classes.match}>{filterValue}</span>
                        {after}
                    </>
                )

                filtered.push(
                    <div key={option}>
                        {multiple ? (
                            <BasicCheckbox
                                className={classes.option}
                                checked={value.includes(option)}
                                setValue={() => changeSelection(option)}
                                label={filteredString}
                            />
                        ) : (
                            <div className={classes.option} onClick={() => changeSelection(option)}>
                                {filteredString}
                            </div>
                        )}
                    </div>,
                )
            }
        }
        return filtered
    }, [])

    function handleTruncateLabel(label: string) {
        if (!truncateLabelAbove || label?.length * 7 < truncateLabelAbove - 52) return label // 52 is the width of the button without the label
        return label.substring(0, (truncateLabelAbove - 52) / 7) + '...'
    }

    const displayedMultiplesValue: string = multiple ? (value as string[])?.join(', ') : (value as string)
    const displayedValue = handleTruncateLabel(displayedMultiplesValue)
    const orientedChevron = isMenuOpened ? faChevronUp : faChevronDown
    return (
        <div>
            <div className={className} onClick={handleClick}>
                <Tooltip title={displayedMultiplesValue} arrow disableHoverListener={displayedValue === value}>
                    {/* Do not remove this div which avoid warning of functional component given as children for tooltip  */}
                    <div>
                        <Button
                            label={displayedValue || <div className={classes.placeholder}>{placeholder}</div>}
                            variant={variant}
                            color={color}
                            className={`${classes.dropDownButton} ${className}`}
                            data-testid={props ? props['data-testid'] : ''}
                            endIcon={disabled ? undefined : endIcon || orientedChevron}
                            iconClass={iconClass}
                        />
                    </div>
                </Tooltip>
            </div>
            <Popover
                anchorEl={anchorEl}
                open={isMenuOpened}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                PaperProps={{
                    className: `${classes.menu} ${menuClassName}`,
                }}
            >
                {withFilter && (
                    <div
                        onKeyDown={(e: any) => {
                            // Prevent textField focus loss
                            if (e.code !== 'ArrowUp' && e.code !== 'ArrowDown') e.stopPropagation()
                        }}
                    >
                        <InputField
                            value={filter}
                            autoFocus={true}
                            placeholder={placeholder}
                            iconStart={faMagnifyingGlass}
                            onChange={(e: any) => setFilter(e.target.value)}
                            fullWidth
                        />
                        <Divider className={classes.divider} />
                    </div>
                )}
                {filteredOptions && filteredOptions?.length > 0 ? filteredOptions : t('global.noMatch')}
            </Popover>
        </div>
    )
}

export default DropDownPicker
