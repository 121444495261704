import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { RMSFile } from 'utils/files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowUpRightAndArrowDownLeftFromCenter,
    faCloudExclamation,
    faFilePdf,
    faPlay,
} from '@fortawesome/pro-light-svg-icons'
import ReactPlayer from 'react-player'
import FileCarousel from '_organisms/images/carousel/FileCarousel'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.space(2)}px`,
            marginBottom: theme.space(3),
        },
        twoFileGrid: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.space(2)}px`,
        },
        oneFileGrid: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .imageWrapper': {
                height: 'fit-content',
                [theme.breakpoints.up('sm')]: {
                    width: '60%',
                },
            },
            '& button': {
                bottom: theme.space(2),
            },
            '& img': {
                width: '100%',
                height: 'auto',
                maxWidth: '100vw',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: 'auto',
                },
            },
        },
        imageWrapper: {
            height: theme.space(32),
            position: 'relative',
        },
        imagePreview: {
            width: theme.space(32),
            height: theme.space(32),
            objectFit: 'cover',
            borderRadius: '2px',
        },
        videoPreview: {
            borderRadius: '2px',
        },
        openButton: {
            opacity: 0.7,
            position: 'absolute',
            bottom: theme.space(1),
            right: theme.space(1),
            minWidth: theme.space(6),
            height: theme.space(6),
            padding: 0,
            background: 'rgba(0, 0, 0, 0.25)',
            backdropFilter: 'blur(2px)',
            '& svg': {
                width: '12px',
                height: '12px',
                color: theme.palette.common.white,
            },
        },
        failedToLoad: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: theme.space(32),
            width: theme.space(32),
            background: theme.palette.neutral[200],
            '& svg': {
                width: theme.space(5),
                height: theme.space(5),
                color: theme.palette.common.white,
            },
        },
        playButton: {
            opacity: 0.7,
            position: 'absolute',
            minWidth: theme.space(10),
            height: theme.space(10),
            background: 'rgba(0, 0, 0, 0.16)',
            backdropFilter: 'blur(2px)',
            top: `calc(50% - ${theme.space(5)}px)`,
            left: `calc(50% - ${theme.space(5)}px)`,
            '& svg': {
                width: theme.space(3),
                height: theme.space(3),
            },
        },
    }),
)

type MediaWrapperProps = {
    className?: string
    children: React.ReactNode
    onClick: () => void
}

const MediaWrapper: React.FC<MediaWrapperProps> = ({ className = '', children, onClick }) => {
    const classes = useStyles()

    return (
        <div className={`${className} ${classes.imageWrapper}`}>
            {children}
            <Button
                startIcon={faArrowUpRightAndArrowDownLeftFromCenter}
                color="neutral"
                className={classes.openButton}
                onClick={onClick}
            />
        </div>
    )
}

const FailedToLoad: React.FC = () => {
    const classes = useStyles()

    return (
        <div className={classes.failedToLoad}>
            <FontAwesomeIcon icon={faCloudExclamation} />
        </div>
    )
}

type MediaProps = {
    src?: string
    onClick: () => void
}

const Pdf: React.FC<MediaProps> = ({ onClick }) => {
    const classes = useStyles()

    return (
        <MediaWrapper onClick={onClick}>
            <div className={classes.failedToLoad}>
                <FontAwesomeIcon icon={faFilePdf} />
            </div>
        </MediaWrapper>
    )
}

const Image: React.FC<MediaProps> = ({ src, onClick }) => {
    const classes = useStyles()
    const [error, setError] = useState(false)

    if (error) {
        return <FailedToLoad />
    }

    return (
        <MediaWrapper onClick={onClick} className="imageWrapper">
            <img src={src} className={classes.imagePreview} onError={() => setError(true)} />
        </MediaWrapper>
    )
}

const Video: React.FC<MediaProps> = ({ src, onClick }) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <MediaWrapper onClick={onClick}>
            <div className={classes.videoPreview}>
                <ReactPlayer url={src} height={theme.space(32)} width="auto" />
                <Button startIcon={faPlay} className={classes.playButton} color="neutral" onClick={onClick} />
            </div>
        </MediaWrapper>
    )
}

type MediaGridProps = {
    files: RMSFile[]
}

const MediaGrid: React.FC<MediaGridProps> = ({ files }) => {
    const classes = useStyles()

    const [openedFilePosition, setOpenedFilePosition] = useState(-1)

    function handleOpen(i: number) {
        setOpenedFilePosition(i)
    }

    function renderFile(index: number, onClick: () => void) {
        const { url, type } = files![index]

        if (url) {
            if (type.includes('image')) {
                return <Image src={url} onClick={onClick} key={index} />
            }
            if (type.includes('pdf')) {
                return <Pdf onClick={onClick} key={index} />
            }
            if (type.includes('video')) {
                return <Video src={url} onClick={onClick} key={index} />
            }
        }

        return <FailedToLoad />
    }

    function gridClass(filesNumber: number) {
        if (filesNumber === 1) return classes.oneFileGrid
        else if (filesNumber === 2) return classes.twoFileGrid
        return classes.grid
    }

    return (
        <>
            <div className={gridClass(files!.length)}>{files!.map((e, i) => renderFile(i, () => handleOpen(i)))}</div>
            {openedFilePosition > -1 && (
                <FileCarousel
                    files={files}
                    openedFilePosition={openedFilePosition}
                    setOpenedFilePosition={setOpenedFilePosition}
                />
            )}
        </>
    )
}

export default MediaGrid
