import * as React from 'react'
import { StepHandlerType } from '_organisms/Stepper'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import EnrichedIcon from '_molecules/EnrichedIcon'

type SetPUIDStepProps = {
    handleSteps?: StepHandlerType
}

const SetPUIDStep: React.FC<SetPUIDStepProps> = ({ handleSteps }) => {
    const { t } = useTranslation()

    return (
        <Page
            title={t('secondHand.refreshPuid.title')}
            rightAddon={{ addon: handleSteps!.getCounter(), displayOnMobile: true }}
            handlePrevious={() => handleSteps!.previousStep()}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
        >
            <BackgroundWithScanAndFooter
                scanProps={{
                    placeholder: t('returnParcel.scanProductInputPlaceholder'),
                    onSubmit: handleSteps!.onSubmit,
                }}
            >
                <EnrichedIcon icon={faScannerGun} title={t('secondHand.refreshPuid.sectionTitle')} />
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default SetPUIDStep
