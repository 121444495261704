import * as React from 'react'
import { useCallback } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import debounce from 'lodash/debounce'
import InputField from '_atoms/inputs/InputField'
import { faClose, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(2),
            marginLeft: 0,
            display: 'inline-flex',
            alignItems: 'center',
            position: 'absolute',
            right: 0,
            top: theme.space(4),
            width: '300px',
        },
        clear: {
            cursor: 'pointer',
        },
    }),
)

type SearchFieldProps = {
    placeholder?: string
    onSubmit: (search: string) => void
    value: string
    setValue: (value: string) => void
}

/**
 * SearchField is an inputField to search by api with integrated debounce effect to avoid too many api calls
 */
const SearchField: React.FC<SearchFieldProps> = ({ placeholder = '', onSubmit, value, setValue }) => {
    const classes = useStyles()

    const debouncedSearchSave = useCallback(
        debounce((nextValue) => {
            onSubmit(nextValue)
        }, 1000),
        [],
    )

    function handleUpdateSearch(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null) {
        const newValue = event?.target?.value || ''
        setValue(newValue)
        debouncedSearchSave(newValue)
    }

    function clearSearchInput() {
        setValue('')
        onSubmit('')
    }

    return (
        <InputField
            data-testid="searchField"
            value={value}
            placeholder={placeholder}
            fullWidth
            onChange={handleUpdateSearch}
            iconStart={faMagnifyingGlass}
            className={classes.textField}
            endAdornment={
                value && (
                    <FontAwesomeIcon
                        icon={faClose}
                        onClick={clearSearchInput}
                        className={classes.clear}
                        data-testid="clearSearch"
                    />
                )
            }
        />
    )
}

export default SearchField
