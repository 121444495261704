import * as React from 'react'
import { useState, useEffect } from 'react'
import { createStyles, makeStyles, Theme, Backdrop, useTheme, useMediaQuery } from '@material-ui/core'
import { RMSFile } from 'utils/files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines, faClose, faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons'

import { Document, Page } from 'react-pdf/dist/esm/entry.vite'
import ReactPlayer from 'react-player'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            [theme.breakpoints.up('sm')]: {
                maxHeight: '90vh',
                height: 'auto',
                maxWidth: '90vw',
                width: 'auto',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: `calc(100vw - ${theme.space(4)}px)`,
                width: 'auto',
                maxHeight: `calc(100vh - ${theme.space(4)}px)`,
                height: 'auto',
            },
        },
        pdf: {
            zIndex: 1,
            '& canvas': {
                [theme.breakpoints.up('sm')]: {
                    height: '90vh!important',
                    width: 'auto!important',
                },
                [theme.breakpoints.down('sm')]: {
                    width: `calc(100vw - ${theme.space(4)}px)!important`,
                    height: 'auto!important',
                },
                margin: 'auto',
            },

            '& .textLayer': {
                display: 'none',
            },
            '& .annotationLayer': {
                display: 'none',
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        backdropButton: {
            position: 'absolute',
            background: 'rgba(0, 0, 0, 0.16)',
            backdropFilter: 'blur(2px)',
            minWidth: theme.space(6),
            '& svg': {
                width: theme.space(4),
                height: theme.space(4),
                color: theme.palette.common.white,
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        closeButton: {
            background: 'none',
            right: theme.space(5),
            top: theme.space(5),
            height: theme.space(6),
            [theme.breakpoints.down('sm')]: {
                right: theme.space(1),
                top: theme.space(1),
            },
        },
        previousButton: {
            zIndex: 2,
            left: `${theme.space(20)}px`,
            top: `calc(50% - ${theme.space(2)}px)`,
            [theme.breakpoints.down('sm')]: {
                left: theme.space(4),
            },
        },
        nextButton: {
            zIndex: 2,
            right: `${theme.space(20)}px`,
            top: `calc(50% - ${theme.space(2)}px)`,
            [theme.breakpoints.down('sm')]: {
                right: theme.space(4),
            },
        },
        failedToLoad: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: theme.space(32),
            width: theme.space(32),
            background: theme.palette.neutral[200],
            '& svg': {
                width: theme.space(5),
                height: theme.space(5),
                color: theme.palette.common.white,
            },
        },
    }),
)

type FileCarouselProps = {
    files: RMSFile[]
    openedFilePosition: number
    setOpenedFilePosition: (openedFilePosition: number) => void
}

const FileCarousel: React.FC<FileCarouselProps> = ({ files, openedFilePosition, setOpenedFilePosition }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))
    const [totalPagesInCurrentPdf, setTotalPagesInCurrentPdf] = useState(0)
    const [currentPageInPdf, setCurrentPageInPdf] = useState(1)
    const [fromPrevious, setFromPrevious] = useState(false)

    useEffect(() => {
        if (totalPagesInCurrentPdf !== 0) {
            if (fromPrevious) {
                setCurrentPageInPdf(totalPagesInCurrentPdf)
            } else {
                setCurrentPageInPdf(1)
            }
        } else {
            setCurrentPageInPdf(0)
        }
    }, [totalPagesInCurrentPdf])

    function handleClose() {
        setOpenedFilePosition(-1)
    }

    // On previous button click :
    //    - if current view is not a pdf : display the previous file (1)
    //    - if current view is the first page of a pdf : display the previous file (1) and note that we are not on a pdf anymore (2)
    //    - if current view is a page of a pdf but not the first page : display the previous page of the pdf (3)
    function handlePrevious() {
        setFromPrevious(true)
        if (totalPagesInCurrentPdf > 0 && currentPageInPdf > 1) {
            setCurrentPageInPdf(currentPageInPdf - 1) // (3)
        } else {
            setOpenedFilePosition(openedFilePosition - 1) // (1)
            if (totalPagesInCurrentPdf > 0) {
                setTotalPagesInCurrentPdf(0) // (2)
            }
        }
    }

    // On next button click :
    //    - if current view is not a pdf : display the next file (1)
    //    - if current view is the last page of a pdf : display the next file (1) and note that we are not on a pdf anymore (2)
    //    - if current view is a page of a pdf but not the last page : display the next page of the pdf
    function handleNext() {
        setFromPrevious(false)
        if (totalPagesInCurrentPdf > 0 && currentPageInPdf < totalPagesInCurrentPdf) {
            setCurrentPageInPdf(currentPageInPdf + 1) // (3)
        } else {
            setOpenedFilePosition(openedFilePosition + 1) // (1)
            if (totalPagesInCurrentPdf > 0) {
                setTotalPagesInCurrentPdf(0) // (2)
            }
        }
    }

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setTotalPagesInCurrentPdf(numPages)
    }

    const renderImage = (file: string) => <img className={classes.image} src={file} />

    const renderPdf = (file: string) => (
        <Document file={file} className={classes.pdf} onLoadSuccess={onDocumentLoadSuccess}>
            {currentPageInPdf > 0 && <Page pageNumber={currentPageInPdf} />}
        </Document>
    )

    const renderVideo = (file: string) => (
        <ReactPlayer
            url={file}
            controls
            width={isMobile ? `calc(100vw - ${theme.space(4)}px)` : 'auto'}
            height={isMobile ? 'auto' : '90vh'}
        />
    )

    const renderFailedToLoad = () => (
        <div className={classes.failedToLoad}>
            <FontAwesomeIcon icon={faFileLines} />
        </div>
    )

    function renderFile() {
        const { url, type } = files![openedFilePosition]

        if (url) {
            if (type.includes('image')) {
                return renderImage(url)
            }
            if (type.includes('pdf')) {
                return renderPdf(url)
            }
            if (type.includes('video')) {
                return renderVideo(url)
            }
        }

        return renderFailedToLoad()
    }

    const isPrev = totalPagesInCurrentPdf > 0 ? currentPageInPdf > 1 || openedFilePosition > 0 : openedFilePosition > 0

    const isNext =
        totalPagesInCurrentPdf > 0
            ? currentPageInPdf < totalPagesInCurrentPdf || openedFilePosition < files!.length - 1
            : openedFilePosition < files!.length - 1

    return (
        <Backdrop className={classes.backdrop} open={openedFilePosition > -1}>
            <Button
                variant="ghost"
                color="neutral"
                startIcon={faClose}
                className={`${classes.closeButton} ${classes.backdropButton}`}
                onClick={handleClose}
            />
            {isPrev && (
                <Button
                    variant="ghost"
                    color="neutral"
                    onClick={handlePrevious}
                    className={`${classes.previousButton} ${classes.backdropButton}`}
                    startIcon={faArrowLeft}
                />
            )}
            {renderFile()}
            {isNext && (
                <Button
                    variant="ghost"
                    color="neutral"
                    onClick={handleNext}
                    className={`${classes.nextButton} ${classes.backdropButton}`}
                    startIcon={faArrowRight}
                />
            )}
        </Backdrop>
    )
}

export default FileCarousel
