import * as React from 'react'

import {
    createStyles,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
    Theme,
    Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            paddingLeft: theme.spacing(3),
            marginBottom: theme.spacing(2),
            '& .MuiTypography-root': {
                '& .MuiSvgIcon-root': {
                    paddingLeft: theme.spacing(1),
                    height: '1.25em',
                    width: '1.25em',
                    verticalAlign: '-20%',
                },
            },
            '& .MuiTypography-gutterBottom': {
                marginTop: '1em',
                marginBottom: '1em',
            },
            '& .MuiTypography-body1': {
                color: theme.palette.grey[600],
            },
        },
        sectionTitle: {
            display: 'inline-flex',
            marginRight: theme.spacing(2),
        },
    }),
)

type InvoiceSectionProps = {
    title: string
}

const InvoiceSection: React.FC<InvoiceSectionProps> = ({ title, children }) => {
    const classes = useStyles()
    return (
        <Grid item>
            <div className={classes.sectionHeader}>
                <Typography className={classes.sectionTitle} variant="h3">
                    {title}
                </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>{children}</TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default InvoiceSection
