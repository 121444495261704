import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IconButton, AppBar, Toolbar } from '@material-ui/core'
import Text from '_atoms/text/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HeaderProps } from './Header'
import useIsDrawerOpenStore, { IsDrawerOpenState } from 'utils/store/useIsDrawerOpen.store'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { faArrowLeft, faCog } from '@fortawesome/pro-light-svg-icons'
import AccountPopover from '_organisms/drawerMenu/AccountPopover'
import { useAuth } from 'utils/hooks/useAuth'
import { useState } from 'react'

export type MobileStyleProps = {
    paddingBottom: number
    blueHeight: number
}

export const BASIC_HEADER_HEIGHT = 12

const useStyles = makeStyles<Theme, MobileStyleProps>((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: 1000,
            boxShadow: 'none',
            boxSizing: 'border-box',
            backgroundColor: theme.palette.primary[600],
        },
        toolBar: {
            boxSizing: 'border-box',
            height: ({ paddingBottom }) => theme.space(2 + BASIC_HEADER_HEIGHT + paddingBottom),
            backgroundColor: theme.palette.primary[600],
            padding: ({ paddingBottom }) => theme.space(2, 0, paddingBottom),
            justifyContent: 'space-between',
        },
        withPrevious: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
        },
        menuButton: {
            color: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(16),
            opacity: 0.5,
            width: theme.space(12),
            height: theme.space(12),
            borderRadius: 0,
            margin: theme.space(0, 1),
        },
        previousButton: {
            paddingRight: theme.space(6),
            borderRight: 'solid 1px rgba(255, 255, 255, 0.25)',
        },
        titleWithPrevious: {
            lineHeight: `${theme.space(8)}px`,
            paddingLeft: theme.space(2),
        },
        titleCenter: {
            textAlign: 'center',
        },
        title: {
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            lineClamp: 2,
            '-webkit-box-orient': 'vertical',
            lineHeight: `${theme.space(4)}px`,
        },
        titleWithoutMenu: {
            marginLeft: theme.space(2),
        },
        bluePadding: {
            width: '100%',
            height: ({ blueHeight }) => theme.space(blueHeight),
            borderBottom: `solid 1px ${theme.palette.neutral[200]}`,
            backgroundColor: theme.palette.primary[50],
        },
        homepageTagline: {
            textAlign: 'center',
            color: theme.palette.common.white,
            width: '66%',
            margin: `0px auto ${theme.space(2)}px auto`,
            zIndex: 1,
        },
    }),
)

const HeaderMobile: React.FC<HeaderProps> = ({
    title,
    tagline,
    handlePrevious,
    rightAddon,
    mobileStyle,
    isHomepage = false,
    withMenuButton = true,
}) => {
    const classes = useStyles(mobileStyle!)
    const { logout } = useAuth()
    const [setIsDrawerOpen] = useIsDrawerOpenStore((state: IsDrawerOpenState) => [state.setIsDrawerOpen])
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar variant="dense" disableGutters className={classes.toolBar}>
                {handlePrevious ? (
                    <>
                        <div className={classes.withPrevious}>
                            <IconButton
                                onClick={handlePrevious}
                                data-testid="previousStep"
                                className={`${classes.menuButton} ${classes.previousButton}`}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </IconButton>
                            <Text variant="H3" className={`${classes.titleWithPrevious} ${classes.title}`}>
                                {title}
                            </Text>
                        </div>
                        {rightAddon?.displayOnMobile ? rightAddon.addon : <div className={classes.menuButton} />}
                    </>
                ) : (
                    <>
                        {withMenuButton && (
                            <IconButton onClick={() => setIsDrawerOpen(true)} className={classes.menuButton}>
                                <FontAwesomeIcon icon={faBars} />
                            </IconButton>
                        )}
                        <Text
                            variant="H3"
                            className={`${classes.title} ${
                                withMenuButton ? classes.titleCenter : classes.titleWithoutMenu
                            }`}
                        >
                            {title}
                        </Text>
                        {rightAddon?.displayOnMobile && rightAddon.addon ? (
                            rightAddon.addon
                        ) : (
                            <IconButton onClick={handleClick} className={classes.menuButton}>
                                <FontAwesomeIcon icon={faCog} />
                            </IconButton>
                        )}
                    </>
                )}
                <AccountPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} logout={logout} />
            </Toolbar>
            {tagline && isHomepage && (
                <Text variant="P5" className={classes.homepageTagline}>
                    {tagline}
                </Text>
            )}
            <div className={classes.bluePadding} />
        </AppBar>
    )
}

export default HeaderMobile
