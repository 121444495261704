import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, Grid, makeStyles, Tab, Typography } from '@material-ui/core'
import { AxiosResponse } from 'axios'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import {
    STOCK_STATS_LOGISTICS_URL,
    STOCK_STATS_UNIQUE_PRODUCT_STATUSES_URL,
    STOCK_STATS_UNIQUE_PRODUCT_TURNOVER_URL,
} from 'utils/routes/backend'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import Figure from 'components/statistics/Figure'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import {
    getChartsColors,
    getDoughnutOptions,
    getStatsStyles,
    getVerticalBarChartOptions,
} from 'components/reusable/utils/StatsUtils'
import { toast } from 'utils/toast'
import LoadingIndicator from 'components/reusable/LoadingIndicator'
import { downloadProductListToCsv } from 'components/reusable/utils/UniqueProductsUtils'
import { Link } from 'react-router-dom'
import { WMS_UNIQUE_PRODUCT_LIST } from 'utils/routes/frontend'
import Page from '_organisms/Page'
import DateRangePicker from '_atoms/inputs/DateRangePicker'
import rmsApi from 'utils/api'
import ExportButton from '../../_atoms/inputs/ExportButton'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

ChartJS.register(...registerables)

const statsStyles = getStatsStyles()
const useStyles = makeStyles(() => createStyles(statsStyles))

const StockStatsApp: React.FC = () => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const [activeTab, setActiveTab] = useState<string>('uniqueProductStatuses')
    const [stats, setStats] = useState<any>(null)
    const [fullChartOptions, setFullChartOptions] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean | string>(true)
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    const [endDate, setEndDate] = useState<Dayjs>(dayjs())
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])
    dayjs.locale(i18n.language)

    useEffect(() => {
        fetchStats()
        // eslint-disable-next-line
    }, [selectedCustomers, selectedWarehouse, startDate, endDate, activeTab])

    async function fetchStats() {
        try {
            setIsLoading(true)
            let statsUrl = STOCK_STATS_UNIQUE_PRODUCT_STATUSES_URL
            let params = ''
            switch (activeTab) {
                case 'uniqueProductTurnover':
                    statsUrl = STOCK_STATS_UNIQUE_PRODUCT_TURNOVER_URL
                    break
                case 'logistics':
                    if (startDate && endDate) {
                        const from = dayjs(startDate).format('YYYY-MM-DD')
                        const to = dayjs(endDate).format('YYYY-MM-DD')
                        params = `?from=${from}&to=${to}`
                    }
                    statsUrl = STOCK_STATS_LOGISTICS_URL + params
                    break
            }
            const statsRequest: AxiosResponse = await rmsApi.get(statsUrl)
            formatStatsData(statsRequest.data)
        } catch (e: any) {
            setIsLoading('error')
            toast.error(e)
        }
    }

    const doughnutOptions: any = getDoughnutOptions()
    const barChartOptions: any = getVerticalBarChartOptions()
    const chartColors: string[] = getChartsColors()
    ChartJS.overrides.doughnut.plugins.tooltip = doughnutOptions.plugins.tooltip

    const formatStatsData = (statsToFormat: any) => {
        const formattedData: any = {}
        const buildingChartOptions: any = {}
        setFullChartOptions({})
        switch (activeTab) {
            case 'uniqueProductStatuses':
                formattedData.productsInService = {
                    figureDescription: 'stockStats.figures.productsInService.description',
                    value: Number(statsToFormat.productsInService.nbProducts).toLocaleString(),
                    hint: 'stockStats.figures.productsInService.hint',
                }
                formattedData.productsInServiceByStatuses = {
                    labels: statsToFormat.productsInServiceByStatuses.map((item: any) => {
                        return t(`uniqueProductStatus.${item.status}`)
                    }),
                    datasets: [
                        {
                            label: 'label',
                            backgroundColor: chartColors,
                            data: statsToFormat.productsInServiceByStatuses.map((item: any) => {
                                return item.nb_products
                            }),
                        },
                    ],
                }
                formattedData.productsRentedOnce = {
                    figureDescription: 'stockStats.figures.productsRentedOnce.description',
                    value: Number(statsToFormat.productsRentedOnce.nbProducts).toLocaleString(),
                    hint: 'stockStats.figures.productsRentedOnce.hint',
                }
                buildingChartOptions.productsInServiceByStatuses = JSON.parse(JSON.stringify(doughnutOptions))
                buildingChartOptions.productsInServiceByStatuses.plugins.title.text = t(
                    'stockStats.figures.productsInServiceByStatuses.description',
                )
                formattedData.productsInStock = {
                    figureDescription: 'stockStats.figures.productsInStock.description',
                    value: Number(statsToFormat.productsInStock.nbProducts).toLocaleString(),
                    hint: 'stockStats.figures.productsInStock.hint',
                }
                formattedData.productsAvailableAtWarehouse = {
                    figureDescription: 'stockStats.figures.productsAvailableAtWarehouse.description',
                    value: Number(statsToFormat.productsAvailableAtWarehouse.nbProducts).toLocaleString(),
                    hint: 'stockStats.figures.productsAvailableAtWarehouse.hint',
                }
                formattedData.availabilityRate = {
                    figureDescription: 'stockStats.figures.availabilityRate.description',
                    value: Number(statsToFormat.availabilityRate[0].rate).toLocaleString() + '%',
                    hint: 'stockStats.figures.availabilityRate.hint',
                }
                formattedData.productsAtRenterHome = {
                    figureDescription: 'stockStats.figures.productsAtRenterHome.description',
                    value: Number(statsToFormat.productsAtRenterHome.nbProducts).toLocaleString(),
                    hint: 'stockStats.figures.productsAtRenterHome.hint',
                }
                formattedData.productsOutOfOrder = {
                    figureDescription: 'stockStats.figures.productsOutOfOrder.description',
                    value: Number(statsToFormat.productsOutOfOrder.nbProducts).toLocaleString(),
                    hint: 'stockStats.figures.productsOutOfOrder.hint',
                }
                formattedData.productsLost = {
                    figureDescription: 'stockStats.figures.productsLost.description',
                    value: Number(statsToFormat.productsLost.nbProducts).toLocaleString(),
                    hint: 'stockStats.figures.productsLost.hint',
                }
                formattedData.rentedRate = {
                    figureDescription: 'stockStats.figures.rentedRate.description',
                    value: Number(statsToFormat.rentedRate[0].rate).toLocaleString() + '%',
                    hint: 'stockStats.figures.rentedRate.hint',
                }
                formattedData.outOfOrderRate = {
                    figureDescription: 'stockStats.figures.outOfOrderRate.description',
                    value: Number(statsToFormat.outOfOrderRate[0].rate).toLocaleString() + '%',
                    hint: 'stockStats.figures.outOfOrderRate.hint',
                }
                formattedData.lostRate = {
                    figureDescription: 'stockStats.figures.lostRate.description',
                    value: Number(statsToFormat.lostRate[0].rate).toLocaleString() + '%',
                    hint: 'stockStats.figures.lostRate.hint',
                }
                break
            case 'uniqueProductTurnover':
                formattedData.productsAverageRotations = {
                    figureDescription: 'stockStats.figures.productsAverageRotations.description',
                    value: Number(statsToFormat.productsAverageRotations[0].average_rotations).toLocaleString(),
                }
                formattedData.distributionOfUniqueProductsByNumberOfRotations = {
                    labels: [
                        t('stockStats.figures.distributionOfUniqueProductsByNumberOfRotation.labels.zero'),
                        t('stockStats.figures.distributionOfUniqueProductsByNumberOfRotation.labels.oneThree'),
                        t('stockStats.figures.distributionOfUniqueProductsByNumberOfRotation.labels.fourSix'),
                        t('stockStats.figures.distributionOfUniqueProductsByNumberOfRotation.labels.sevenTen'),
                        t('stockStats.figures.distributionOfUniqueProductsByNumberOfRotation.labels.moreThanTen'),
                    ],
                    datasets: [
                        {
                            label: 'label',
                            backgroundColor: chartColors,
                            data: [
                                statsToFormat.distributionOfUniqueProductsByNumberOfRotations[0].zero ?? 0,
                                statsToFormat.distributionOfUniqueProductsByNumberOfRotations[0].one_three ?? 0,
                                statsToFormat.distributionOfUniqueProductsByNumberOfRotations[0].four_six ?? 0,
                                statsToFormat.distributionOfUniqueProductsByNumberOfRotations[0].seven_ten ?? 0,
                                statsToFormat.distributionOfUniqueProductsByNumberOfRotations[0].more_than_ten ?? 0,
                            ],
                        },
                    ],
                }
                buildingChartOptions.distributionOfUniqueProductsByNumberOfRotation = JSON.parse(
                    JSON.stringify(doughnutOptions),
                )
                buildingChartOptions.distributionOfUniqueProductsByNumberOfRotation.plugins.title.text = t(
                    'stockStats.figures.distributionOfUniqueProductsByNumberOfRotation.description',
                )
                formattedData.uniqueProductWithMaxRotations = {
                    figureDescription: 'stockStats.figures.uniqueProductWithMaxRotations.description',
                    value:
                        statsToFormat.uniqueProductWithMaxRotations[0].nb_max_rotations > 1
                            ? `${statsToFormat.uniqueProductWithMaxRotations[0].nb_max_rotations} ${t(
                                  'stockStats.figures.uniqueProductWithMaxRotations.rotations',
                              )}`
                            : `${statsToFormat.uniqueProductWithMaxRotations[0].nb_max_rotations} ${t(
                                  'stockStats.figures.uniqueProductWithMaxRotations.rotation',
                              )}`,
                    note: statsToFormat.uniqueProductWithMaxRotations[0].puid,
                }
                formattedData.averageRotationsBeforeOutOfOrder = {
                    figureDescription: 'stockStats.figures.averageRotationsBeforeOutOfOrder.description',
                    value: Number(
                        statsToFormat.averageRotationsBeforeOutOfOrder[0].average_rotations_before_out_of_order,
                    ).toLocaleString(),
                }
                formattedData.productsNeverUsed = {
                    figureDescription: 'stockStats.figures.productsNeverUsed.description',
                    value: Number(statsToFormat.productsNeverUsed[0].nb_products_never_used).toLocaleString(),
                }
                break
            case 'logistics':
                formattedData.evolutionCharts = {
                    labels: Object.entries(statsToFormat.evolutionCharts).map((item: any) => {
                        return dayjs(item[0], 'YYYY-MM').format('MMM YYYY')
                    }),
                    datasets: [
                        {
                            label: t('stockStats.figures.productsCreated.description'),
                            backgroundColor: chartColors[0],
                            data: Object.entries(statsToFormat.evolutionCharts).map((item: any) => {
                                return item[1].nb_products_created ?? 0
                            }),
                        },
                        {
                            label: t('stockStats.figures.productsReturnedToCustomer.description'),
                            backgroundColor: chartColors[4],
                            data: Object.entries(statsToFormat.evolutionCharts).map((item: any) => {
                                return item[1].nb_products_returned_to_customer ?? 0
                            }),
                        },
                    ],
                }
                buildingChartOptions.evolutionCharts = barChartOptions
                formattedData.productsCreatedOnDaterange = {
                    figureDescription: 'stockStats.figures.productsCreatedOnDaterange.description',
                    value: Number(
                        statsToFormat.productsCreatedOnDaterange[0].products_created_on_daterange,
                    ).toLocaleString(),
                    hint: 'stockStats.figures.productsCreatedOnDaterange.hint',
                }
                break
        }
        setFullChartOptions(buildingChartOptions)
        setStats(formattedData)
        setIsLoading(false)
    }

    const handleTabChange = (event: any, newValue: any) => {
        setActiveTab(newValue)
    }

    const handleEndDateChange = (endDateToSet: Dayjs) => {
        if (endDateToSet.diff(startDate, 'day') < 0) {
            setStartDate(dayjs(endDateToSet).subtract(1, 'day'))
            setEndDate(endDateToSet)
        } else {
            setEndDate(endDateToSet)
        }
    }

    const exportAllButton = () => {
        return (
            <div className={classes.mainExportButtonContainer}>
                <ExportButton onClick={() => downloadProductListToCsv()} />
                <Link className={classes.accessUniqueProductsListLink} to={WMS_UNIQUE_PRODUCT_LIST}>
                    {t('buttons.accessUniqueProducts')}
                </Link>
            </div>
        )
    }

    return (
        <Page
            section={t('menu.analytics.title')}
            title={t('menu.analytics.stock')}
            tagline={t('stockStats.tagline')}
            rightAddon={{ addon: exportAllButton(), displayOnMobile: false }}
            withDivider={false}
        >
            <TabContext value={activeTab}>
                <Box className={classes.tabsBox}>
                    <TabList
                        className={classes.tabs}
                        onChange={handleTabChange}
                        aria-label="stat-tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            label={t('stockStats.tabs.uniqueProductStatuses.menuTitle')}
                            value="uniqueProductStatuses"
                        />
                        <Tab
                            label={t('stockStats.tabs.uniqueProductTurnover.menuTitle')}
                            value="uniqueProductTurnover"
                        />
                        <Tab label={t('stockStats.tabs.logistics.menuTitle')} value="logistics" />
                    </TabList>
                </Box>
                <TabPanel className={classes.tabContent} value="uniqueProductStatuses">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('stockStats.tabs.uniqueProductStatuses.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('stockStats.tabs.uniqueProductStatuses.tagline')}</Typography>

                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.productsInService && (
                                <Figure
                                    testId="uniqueProductStatuses-main-figure"
                                    figure={stats.productsInService}
                                    main={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.productsRentedOnce && (
                                <Figure
                                    testId="products-rented-once-main-figure"
                                    figure={stats.productsRentedOnce}
                                    main={true}
                                />
                            )}
                        </Grid>
                        <Grid item md={3} />
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.chartContainer}>
                                {stats?.productsInServiceByStatuses && (
                                    <Chart
                                        type="doughnut"
                                        data={stats?.productsInServiceByStatuses}
                                        options={fullChartOptions?.productsInServiceByStatuses}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.spacer} />
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.productsInStock && (
                                <Figure testId="products-in-stock" figure={stats.productsInStock} withColor={true} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.productsAvailableAtWarehouse && (
                                <Figure
                                    testId="products-available-at-warehouse"
                                    figure={stats.productsAvailableAtWarehouse}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.availabilityRate && (
                                <Figure testId="availability-rate" figure={stats.availabilityRate} withColor={true} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.productsAtRenterHome && (
                                <Figure testId="at-renter-home" figure={stats.productsAtRenterHome} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.productsOutOfOrder && (
                                <Figure testId="out-of-order" figure={stats.productsOutOfOrder} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.productsLost && <Figure testId="lost" figure={stats.productsLost} />}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.rentedRate && <Figure testId="rented-rate" figure={stats.rentedRate} />}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.outOfOrderRate && (
                                <Figure testId="out-of-order-rate" figure={stats.outOfOrderRate} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.lostRate && <Figure testId="lost-rate" figure={stats.lostRate} />}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="uniqueProductTurnover">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('stockStats.tabs.uniqueProductTurnover.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('stockStats.tabs.uniqueProductTurnover.tagline')}</Typography>

                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            {stats?.productsAverageRotations && (
                                <Figure
                                    testId="uniqueProductTurnover-main-figure"
                                    figure={stats.productsAverageRotations}
                                    main={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.chartContainer}>
                                {stats?.distributionOfUniqueProductsByNumberOfRotations && (
                                    <Chart
                                        type="doughnut"
                                        data={stats?.distributionOfUniqueProductsByNumberOfRotations}
                                        options={fullChartOptions?.distributionOfUniqueProductsByNumberOfRotation}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={12} className={classes.spacer} />
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.uniqueProductWithMaxRotations && (
                                <Figure
                                    testId="most-used-product"
                                    figure={stats.uniqueProductWithMaxRotations}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.averageRotationsBeforeOutOfOrder && (
                                <Figure
                                    testId="average-rotations-before-out-of-order"
                                    figure={stats.averageRotationsBeforeOutOfOrder}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.productsNeverUsed && (
                                <Figure
                                    testId="products-never-used"
                                    figure={stats.productsNeverUsed}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel className={classes.tabContent} value="logistics">
                    <Typography className={classes.tabContentTitle} variant="h3">
                        {t('stockStats.tabs.logistics.contentTitle')}
                        <LoadingIndicator isLoading={isLoading} />
                    </Typography>
                    <Typography variant="body1">{t('stockStats.tabs.logistics.tagline')}</Typography>
                    <DateRangePicker
                        className={classes.dateRangePicker}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={handleEndDateChange}
                    />
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.barChartContainer}>
                                {stats?.evolutionCharts && (
                                    <Chart
                                        type="bar"
                                        data={stats?.evolutionCharts}
                                        options={fullChartOptions?.evolutionCharts}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {stats?.productsCreatedOnDaterange && (
                                <Figure
                                    testId="products-created-on-daterange"
                                    figure={stats.productsCreatedOnDaterange}
                                    withColor={true}
                                />
                            )}
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </Page>
    )
}

export default StockStatsApp
