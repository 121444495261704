import * as React from 'react'
import { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Text from '_atoms/text/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTimer } from 'react-timer-hook'
import { useTranslation } from 'react-i18next'
import Status from '_atoms/badges/Status'

type StyleProps = {
    onClick: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        section: {
            margin: theme.space(3, 0),
            color: theme.palette.neutral[800],
        },
        processedInstructionContainer: {
            border: `1px solid ${theme.palette.primary[100]}`,
            borderRadius: theme.space(4 / 5),
            marginBottom: theme.space(2),
            cursor: (onClick) => (onClick ? 'pointer' : 'default'),
        },
        processedInstructionHeadLine: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.space(2),
            color: theme.palette.neutral[800],
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.space(4 / 5, 4 / 5, 0, 0),
        },
        headlineLeft: {
            display: 'flex',
            alignItems: 'center',
        },
        processedInstructionContent: {
            display: 'flex',
            padding: theme.space(1, 2),
            backgroundColor: theme.palette.primary[50],
            justifyContent: 'space-between',
            borderRadius: theme.space(0, 0, 4 / 5, 4 / 5),
        },
        rowIcon: {
            backgroundColor: theme.palette.primary[50],
            color: theme.palette.neutral[500],
            borderRadius: '2px',
            marginRight: theme.space(3),
            width: theme.space(12 / 5),
            height: theme.space(12 / 5),
            padding: theme.space(4 / 5),
        },
    }),
)

type InstructionOverviewTableProps = {
    sectionTitle: string
    sectionIcon: IconProp
    sectionRightAddon?: any
    itemLabel: string
    itemIcon: IconProp
    itemRightAddon?: any
    onClick?: () => void
    timer?: number
    onTimerExpire?: () => void
    dataTestId?: string
}

const InstructionOverviewTable: React.FC<InstructionOverviewTableProps> = ({
    sectionTitle,
    sectionIcon,
    sectionRightAddon,
    itemLabel,
    itemIcon,
    itemRightAddon,
    onClick,
    timer = 0,
    onTimerExpire,
    dataTestId,
}) => {
    const classes = useStyles({ onClick: !!onClick })
    const { t } = useTranslation()

    const time = new Date()
    time.setSeconds(time.getSeconds() + timer)
    const { minutes, start } = useTimer({
        autoStart: false,
        expiryTimestamp: time,
        onExpire: onTimerExpire,
    })

    useEffect(() => {
        if (timer) {
            start()
        }
    }, [timer])

    return (
        <div
            className={`instructionOverviewTable ${classes.processedInstructionContainer}`}
            onClick={onClick}
            data-testid={dataTestId}
        >
            <div className={classes.processedInstructionHeadLine}>
                <div className={classes.headlineLeft}>
                    <FontAwesomeIcon className={classes.rowIcon} icon={sectionIcon} />
                    <Text variant="S2">{sectionTitle}</Text>
                </div>
                {timer ? (
                    <Status
                        label={`${minutes + 1} ${
                            minutes > 1
                                ? t('new_refit.home.remainingTime.plural')
                                : t('new_refit.home.remainingTime.singular')
                        }`}
                        color="primary"
                    />
                ) : (
                    sectionRightAddon
                )}
            </div>
            <div className={classes.processedInstructionContent}>
                <div className={classes.headlineLeft}>
                    <FontAwesomeIcon className={classes.rowIcon} icon={itemIcon}></FontAwesomeIcon>
                    <Text variant="P5">{itemLabel}</Text>
                </div>
                {itemRightAddon}
            </div>
        </div>
    )
}

export default InstructionOverviewTable
