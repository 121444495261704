import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page from '_organisms/Page'
import { IOrderPackingList } from 'interfaces/Packing.interface'
import Button from '_atoms/buttons/Button'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { faTriangleExclamation as faTriangleExclamationRegular } from '@fortawesome/pro-regular-svg-icons'
import EnrichedIcon from '_molecules/EnrichedIcon'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import { PICK_PACK_ISSUE_URL } from 'utils/routes/backend'
import { PickPackIssueType } from 'interfaces/PickPackIssue.interface'
import { PACKING_ROUTE } from 'utils/routes/frontend'
import { toast } from 'utils/toast'
import { useNavigate, useParams } from 'react-router-dom'
import rmsApi from 'utils/api'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            gap: `${theme.space(2)}px`,
        },
        wrongReference: {
            [theme.breakpoints.down('sm')]: {
                flex: 4,
            },
        },
        warningIcon: {
            backgroundColor: theme.palette.error[100],
            color: theme.palette.error.main,
        },
    }),
)

type OrderPackingLabelErrorProps = {
    onLeavePage: () => void
    orderPackingData: IOrderPackingList
}

const OrderPackingLabelError: React.FC<OrderPackingLabelErrorProps> = ({ orderPackingData, onLeavePage }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { orderId } = useParams()
    const navigate = useNavigate()
    const buildErrorMessage = useErrorMessage()

    const { customer, omsOrderId, batchId } = orderPackingData

    async function reportIssue() {
        try {
            await rmsApi.post(PICK_PACK_ISSUE_URL, {
                orderId,
                issueType: PickPackIssueType.LABEL_ERROR,
            })
            navigate(PACKING_ROUTE(batchId))
            toast.success(t('new_orders.packing.packingOrderErrorIssue'))
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { orderId }, 'new_orders.errors.createIssueError'))
        }
    }

    const footer = (
        <Button
            onClick={reportIssue}
            endIcon={faTriangleExclamationRegular}
            className={classes.wrongReference}
            data-testid="report-issue-generating-pdf-button"
            label={t('new_orders.packing.errorGeneratingPdfs.button')}
        />
    )

    return (
        <Page title={`${customer.name} #${omsOrderId}`} handlePrevious={() => onLeavePage()}>
            <BackgroundWithScanAndFooter footer={footer} footerInBoxDesktop={true}>
                <EnrichedIcon
                    icon={faTriangleExclamation}
                    title={t('new_orders.packing.errorGeneratingPdfs.title')}
                    tagline={t('new_orders.packing.errorGeneratingPdfs.tagline')}
                    iconProps={{ className: classes.warningIcon }}
                />
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default OrderPackingLabelError
