import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IUniqueProduct } from 'interfaces'
import {
    LOCATIONS_FROM_SKU,
    UNIQUE_PRODUCT_UPDATE_LOCATION_URL,
    UNIQUE_PRODUCTS_BY_BARCODEUID_URL,
} from 'utils/routes/backend'
import AccordionStepper from 'components/wmsStepper/AccordionStepper'
import Step from 'components/wmsStepper/Step'
import ProductWithoutDisabledStepInput from 'components/WMS/ProductWithoutDisabledStepInput'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page from '_organisms/Page'
import { ErrorCode } from '../utils/errors'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

enum stepLevel {
    PUID = 0,
    LOCATION = 1,
}

const LinkLocation: React.FC = () => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = React.useState<number | null>(null)
    const [currentStep, setCurrentStep] = React.useState<number>(0)
    const [stepperValues, setStepperValues] = React.useState<string[]>([])
    const [scannedUniqueProduct, setScannedUniqueProduct] = React.useState<IUniqueProduct | null>(null)
    const [puidsLocation, setPuidsLocation] = useState<any>(null)
    const [puidSKU, setPuidSKU] = useState<string>('')
    const [setDisabledSelectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.setDisabledSelectedCustomers,
    ])
    const [setDisabledSelectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [
        state.setDisabledSelectedWarehouse,
    ])
    const updateStorageLocation = async function (): Promise<{
        uniqueProduct: IUniqueProduct | null
        error: string
    }> {
        const response: { uniqueProduct: IUniqueProduct | null; error: string } = {
            uniqueProduct: null,
            error: '',
        }

        const payload = {
            barcodeUid: [stepperValues[stepLevel.PUID]],
            storageLocation: stepperValues[stepLevel.LOCATION],
        }

        try {
            const updateLocationRequest = await rmsApi.patch(UNIQUE_PRODUCT_UPDATE_LOCATION_URL, payload)
            response.uniqueProduct = updateLocationRequest.data
        } catch (e: any) {
            const errorData = e.response?.data
            const { status, message } = errorData

            if (status === 403 && message === ErrorCode.SECOND_HAND_WITHOUT_PHOTOS) {
                response.error = t('wms.linkLocation.errorSecondHandWithoutPhotos')
            } else if (message === ErrorCode.NOT_FOUND) {
                response.error = t('wms.linkLocation.error', {
                    PUID: payload.barcodeUid,
                    storageLocation: payload.storageLocation,
                })
            } else {
                response.error =
                    t('wms.linkLocation.unknownError', {
                        PUID: payload.barcodeUid,
                        storageLocation: payload.storageLocation,
                    }) + `: ${e.message}`
            }
        }
        return response
    }

    useEffect(() => {
        setExpanded(currentStep)
    }, [currentStep])

    useEffect(() => {
        if (stepperValues.length >= 1) updateProduct()
        // eslint-disable-next-line
    }, [stepperValues])

    useEffect(() => {
        if (scannedUniqueProduct !== null) {
            setDisabledSelectedCustomers(true)
            setDisabledSelectedWarehouse(true)
        } else {
            setDisabledSelectedCustomers(false)
            setDisabledSelectedWarehouse(false)
        }
    }, [scannedUniqueProduct])

    const handleChange = (panel: number) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
        if (
            (panel === 1 && !stepperValues[stepLevel.PUID] && stepperValues[stepLevel.PUID] === '') ||
            (panel === 0 && !stepperValues[stepLevel.LOCATION] && stepperValues[stepLevel.LOCATION] === '')
        )
            return
        else if (isExpanded) setCurrentStep(panel)
    }

    const handleStepperUpdated = async (stepUpdated: number, submittedStep: any) => {
        const handleStepperValuesUpdate = () => {
            const newStepperValues = [...stepperValues]
            newStepperValues[stepUpdated] = submittedStep
            setStepperValues(newStepperValues)
        }

        if (!submittedStep) return

        let uniqueProduct = null
        if (stepUpdated === stepLevel.PUID) {
            uniqueProduct = await fetchPuidLocation(submittedStep)
            if (uniqueProduct) {
                handleStepperValuesUpdate()
            }
        } else {
            handleStepperValuesUpdate()
        }
    }

    const handleNextStep = (overrideStep?: number) => {
        if (overrideStep != null && overrideStep >= stepLevel.PUID) {
            setCurrentStep(overrideStep)
        } else {
            setCurrentStep(currentStep + 1)
        }
    }

    async function fetchOtherPUIDsLocation(currentSku: string) {
        const req = await rmsApi.get(LOCATIONS_FROM_SKU(currentSku))
        const productReference = req?.data

        const { uniqueProducts, sku } = productReference

        const locations = new Map()
        for (const uniqueProduct of uniqueProducts) {
            if (uniqueProduct.storageLocation !== null) {
                const storageLocationQuantity = locations.get(uniqueProduct.storageLocation)
                locations.set(uniqueProduct.storageLocation, storageLocationQuantity ? storageLocationQuantity + 1 : 1)
            }
        }

        setPuidsLocation(Object.fromEntries(locations))
        setPuidSKU(sku)
    }

    async function fetchPuidLocation(puid: string) {
        try {
            const uniqueProductRequest = await rmsApi.get(UNIQUE_PRODUCTS_BY_BARCODEUID_URL(puid))
            const uniqueProduct = uniqueProductRequest?.data
            if (!uniqueProduct) {
                toast.error(t('wms.linkLocation.error', { PUID: puid }))
                return null
            } else {
                const { productReference } = uniqueProduct
                setScannedUniqueProduct(uniqueProduct)
                if (productReference?.sku) {
                    await fetchOtherPUIDsLocation(productReference.sku)
                }
                return uniqueProduct
            }
        } catch (e: any) {
            const status = e.response?.data?.status
            if (status === 404) {
                toast.error(t('wms.linkLocation.error', { PUID: puid }))
            } else {
                toast.error(t('wms.linkLocation.unknownError'))
            }
        }
    }

    async function updateProduct() {
        if (!stepperValues[stepLevel.PUID] || !stepperValues[stepLevel.LOCATION]) {
            handleNextStep()
        }
        if (stepperValues[stepLevel.PUID] && stepperValues[stepLevel.LOCATION]) {
            toast.info(t('wms.linkLocation.loading'))
            const response = await updateStorageLocation()
            toast.dismiss()
            if (!response.uniqueProduct) {
                toast.error(response.error)
            } else {
                toast.success(
                    t('wms.linkLocation.success', {
                        PUID: stepperValues[stepLevel.PUID],
                        storageLocation: stepperValues[stepLevel.LOCATION],
                    }),
                )
            }
            handleNextStep(stepLevel.PUID)
            setStepperValues([])
            setScannedUniqueProduct(null)
        }
    }

    return (
        <Page title={t('wms.linkLocation.title')}>
            <AccordionStepper>
                <Step
                    expanded={expanded}
                    handleChange={handleChange}
                    stepTitle={t('wms.linkLocation.puid.title')}
                    stepSubTitle={t('wms.linkLocation.puid.subTitle')}
                    uniqueProduct={scannedUniqueProduct}
                    puidsLocation={puidsLocation}
                    puidSKU={puidSKU}
                >
                    <ProductWithoutDisabledStepInput
                        label={t('wms.linkLocation.puid.inputLabel')}
                        handleStepperUpdated={handleStepperUpdated}
                        defaultValue={stepperValues[stepLevel.PUID]}
                    />
                </Step>
                <Step
                    expanded={expanded}
                    handleChange={handleChange}
                    stepTitle={t('wms.linkLocation.storageLocation.title')}
                    stepSubTitle={t('wms.linkLocation.storageLocation.subTitle')}
                >
                    <ProductWithoutDisabledStepInput
                        label={t('wms.linkLocation.storageLocation.inputLabel')}
                        handleStepperUpdated={handleStepperUpdated}
                        defaultValue={stepperValues[stepLevel.LOCATION]}
                    />
                </Step>
            </AccordionStepper>
        </Page>
    )
}

export default LinkLocation
