import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import { useTranslation } from 'react-i18next'
import Tips from '_atoms/containers/Tips'
import { IInstructionGroup } from 'interfaces'
import ModalFooter from '_molecules/ModalFooter'
import DropDownPicker from '_molecules/DropDownPicker'
import InputWithLabel from '_molecules/InputWithLabel'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tips: {
            marginTop: theme.space(4),
        },
        dropdown: {
            width: '100%',
        },
        menu: {
            width: theme.space(96),
            '& .MuiInputBase-root svg': {
                color: theme.palette.primary[600],
            },
        },
    }),
)

type LinkToGroupModalProps = {
    isOpen: boolean
    groups: IInstructionGroup[]
    onSubmit: (groupId: number) => void
    onClose: () => void
}

const LinkProductsToGroupModal: React.FC<LinkToGroupModalProps> = ({ groups, onSubmit, isOpen, onClose }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [selectedGroupId, setSelectedGroupId] = useState<number>()
    function submit() {
        onSubmit(selectedGroupId!)
        setSelectedGroupId(undefined)
    }

    function closeModale() {
        setSelectedGroupId(undefined)
        onClose()
    }

    return (
        <ModalWithHeader
            title={t('refit.instructionGroups.manageGroup.linkedProductsTab.linkToGroup')}
            headerType={HeaderType.DIVIDER}
            open={isOpen}
            handleClose={closeModale}
            width={600}
        >
            <InputWithLabel label={t('refit.instructionGroups.manageGroup.linkedProductsTab.instructionGroup')}>
                <DropDownPicker
                    value={groups.find((group) => group.id === selectedGroupId)?.name || ''}
                    className={classes.dropdown}
                    menuClassName={classes.menu}
                    placeholder={t('refit.instructionGroups.manageGroup.linkedProductsTab.groupName')}
                    options={groups.map((group) => group.name)}
                    optionLabelKey="name"
                    submitSelection={(groupName: string) =>
                        setSelectedGroupId(groups.find((group) => group.name === groupName)?.id)
                    }
                    data-testid="groupPicker"
                />
            </InputWithLabel>
            <Tips className={classes.tips}>{t('refit.instructionGroups.manageGroup.linkedProductsTab.tips')}</Tips>
            <ModalFooter
                label={t('refit.instructionGroups.manageGroup.linkedProductsTab.validate')}
                onSubmit={submit}
                onCancel={closeModale}
                submitDisabled={!selectedGroupId}
            />
        </ModalWithHeader>
    )
}

export default LinkProductsToGroupModal
