import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core'
import SquareProductImage from '_atoms/images/SquareProductImage'
import { faClone, faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { toast } from 'utils/toast'
import * as React from 'react'
import { IUniqueProduct } from 'interfaces'
import { useTranslation } from 'react-i18next'
import Text from '_atoms/text/Text'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalCardContainer: {
            padding: theme.space(3, 4),
            [theme.breakpoints.down('sm')]: {
                zIndex: 1001,
                position: 'fixed',
                top: theme.space(16),
                left: theme.space(3),
                width: `calc(100vw - ${theme.space(6)}px)`,
                padding: 0,
            },
            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.palette.primary[50],
                borderRadius: '5px',
            },
        },
        firstLine: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: `${theme.space(3)}px`,
            marginBottom: theme.space(6),
        },
        secondLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.space(3)}px`,
        },
        detailContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(4)}px`,
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                '& > *:first-child p': {
                    color: theme.palette.common.white,
                },
                '& > *:first-child p:first-child': {
                    opacity: 0.5,
                },
            },
        },
        label: {
            color: theme.palette.neutral[600],
        },
        value: {
            color: theme.palette.neutral[700],
        },
        labeledValueWithAddon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: `${theme.space(2)}px`,
        },
        modalImage: {
            width: theme.space(20),
            height: theme.space(20),
            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.05)',
        },
        divider: {
            backgroundColor: theme.palette.neutral[100],
            height: theme.space(6),
        },
        ellipsisButton: {
            minWidth: 0,
            width: theme.space(6),
            height: theme.space(3),
            color: theme.palette.common.white,
            background: 'rgba(40, 48, 68, 0.15)',
            [theme.breakpoints.up('md')]: {
                color: theme.palette.primary[800],
                background: theme.palette.primary[100],
            },
            [theme.breakpoints.down('md')]: {
                border: 'none',
                '&:focus:enabled': {
                    background: 'rgba(40, 48, 68, 0.15)',
                    border: 'none',
                },
            },
        },
        copyButton: {
            zIndex: 2,
            minWidth: theme.spacing(2),
            color: 'rgba(20, 34, 61, 0.15)',
            '&:hover': {
                color: 'rgba(20, 34, 61, 0.5)',
                background: 'none',
            },
            transition: '0.1s ease-in-out',
            [theme.breakpoints.up('md')]: {
                fontSize: theme.typography.pxToRem(18),
            },
        },
    }),
)

type LabeledValueProps = {
    label: string
    value: string
    white?: boolean
}

const LabeledValue: React.FC<LabeledValueProps> = ({ label, value }) => {
    const classes = useStyles()
    return (
        <div>
            <Text variant="S3" className={classes.label}>
                {label}
            </Text>
            <Text variant="S2" className={classes.value}>
                {value}
            </Text>
        </div>
    )
}

interface LabeledValueWithAddonProps extends LabeledValueProps {
    children: React.ReactNode
}

const LabeledValueWithAddon: React.FC<LabeledValueWithAddonProps> = ({ label, value, children }) => {
    const classes = useStyles()
    return (
        <div className={classes.labeledValueWithAddon}>
            <LabeledValue label={label} value={value} />
            {children}
        </div>
    )
}

type UniqueProductModalType = {
    uniqueProduct: IUniqueProduct
    setIsEditPUIDModaleOpened: (value: boolean) => any
}

const UniqueProductModal: React.FC<UniqueProductModalType> = ({ uniqueProduct, setIsEditPUIDModaleOpened }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <div className={classes.modalCardContainer}>
            <div className={classes.firstLine}>
                <SquareProductImage
                    image={uniqueProduct.productReference?.image || uniqueProduct.productReference?.product?.image}
                    className={classes.modalImage}
                />
                <div className={classes.detailContainer}>
                    <LabeledValueWithAddon label={t('wms.uniqueProducts.columnID')} value={uniqueProduct.barcodeUid}>
                        <Button
                            color="neutral"
                            startIcon={faEllipsis}
                            className={classes.ellipsisButton}
                            onClick={() => {
                                setIsEditPUIDModaleOpened(true)
                            }}
                            data-testid="updatePUIDButton"
                        />
                    </LabeledValueWithAddon>
                    <LabeledValue
                        label={t('wms.uniqueProducts.columnCustomerName')}
                        value={uniqueProduct.productReference?.product?.customer?.name || 'n/c'}
                    />
                </div>
            </div>
            <div className={classes.secondLine}>
                {uniqueProduct?.productReference?.sku && (
                    <LabeledValueWithAddon
                        label={t('wms.uniqueProducts.columnSku')}
                        value={uniqueProduct.productReference.sku}
                    >
                        <Button
                            color="neutral"
                            variant="ghost"
                            startIcon={faClone}
                            className={classes.copyButton}
                            onClick={() => {
                                navigator.clipboard.writeText(uniqueProduct.productReference.sku || '')
                                toast.info(`${t('wms.uniqueProducts.columnSku')} ${t('returnParcel.copied')}`)
                            }}
                        />
                    </LabeledValueWithAddon>
                )}
                <Divider orientation="vertical" className={classes.divider} />
                {uniqueProduct?.productReference?.eans && uniqueProduct?.productReference?.eans.length > 0 && (
                    <LabeledValueWithAddon
                        label={t('wms.uniqueProducts.columnEans')}
                        value={uniqueProduct?.productReference?.eans[0].ean}
                    >
                        <Button
                            color="neutral"
                            startIcon={faClone}
                            variant="ghost"
                            className={classes.copyButton}
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    uniqueProduct?.productReference?.eans?.map((ean: any) => ean.ean).join() || '',
                                )
                                toast.info(`${t('wms.uniqueProducts.columnEans')} ${t('returnParcel.copied')}`)
                            }}
                        />
                    </LabeledValueWithAddon>
                )}
            </div>
        </div>
    )
}

export default UniqueProductModal
