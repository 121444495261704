import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DoughnutAndCards from '../tabs/DoughnutAndCards'
import Button from '_atoms/buttons/Button'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { OPERATOR_HP_STATS_ROUTE } from 'utils/routes/backend'
import {
    APPOINTMENTS_ROUTE,
    ORDERS_ROUTE,
    REFIT_ROUTE,
    RETURNS_LIST_ROUTE,
    WMS_INSERT_UNIQUE_PRODUCT,
} from 'utils/routes/frontend'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

type OperatorHpStats = {
    puidsInStock: number
    toPrepareToday: number
    toPrepareTomorrow: number
    returnToProcess: number
    productsToRefit: number
}

const OperatorHP: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [figures, setFigures] = useState<OperatorHpStats>({
        puidsInStock: 0,
        toPrepareToday: 0,
        toPrepareTomorrow: 0,
        returnToProcess: 0,
        productsToRefit: 0,
    })
    const [user] = useConnectedUserStore((state: ConnectedUserState) => [state.user])
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    async function fetchHpData() {
        try {
            const customerIds = selectedCustomers.map((customer) => customer.id).join(',')
            const dataRequest = await rmsApi.get(
                `${OPERATOR_HP_STATS_ROUTE}?customerIds[]=${customerIds}&warehouseId=${selectedWarehouse?.id}`,
            )
            setFigures({
                puidsInStock: dataRequest.data.puidsInStock.nb_products_in_stock,
                toPrepareToday: dataRequest.data.ordersToPrepareToday.orders_to_prepare,
                toPrepareTomorrow: dataRequest.data.ordersToPrepareTomorrow.orders_to_prepare,
                returnToProcess: dataRequest.data.parcelsToProcess.nb_return_parcels_to_process,
                productsToRefit: dataRequest.data.productsToRefit.nb_products_to_refit,
            })
        } catch (e: any) {
            toast.error(t('homepage.errors.statsFetchError', { message: e.message }))
        }
    }

    useEffect(() => {
        fetchHpData()
    }, [selectedCustomers, selectedWarehouse])

    const steps = {
        first: {
            title: t('homepage.roles.operator.firstStep.title'),
            tagline: t('homepage.roles.operator.firstStep.tagline'),
            figures: [
                {
                    figure: figures.puidsInStock,
                    figureTitle: t('homepage.roles.operator.firstStep.figureTitles.puidsInStock'),
                },
            ],
            actions: (
                <Button
                    color="secondary"
                    size="small"
                    onClick={() => navigate(WMS_INSERT_UNIQUE_PRODUCT)}
                    data-testid="hpAddPuids"
                >
                    {t('homepage.roles.operator.firstStep.actions.addPuids')}
                </Button>
            ),
        },
        second: {
            title: t('homepage.roles.operator.secondStep.title'),
            tagline: t('homepage.roles.operator.secondStep.tagline'),
            figures: [
                {
                    figure: figures.toPrepareToday,
                    figureTitle: t('homepage.roles.operator.secondStep.figureTitles.toPrepare'),
                },
                {
                    figure: figures.toPrepareTomorrow,
                    figureTitle: t('homepage.roles.operator.secondStep.figureTitles.tomorrow'),
                },
            ],
            actions: (
                <>
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate(ORDERS_ROUTE)}
                        data-testid="hpPrepareAnOrder"
                    >
                        {t('homepage.roles.operator.secondStep.actions.prepareAnOrder')}
                    </Button>
                    {selectedWarehouse?.hasCalendlyToken && (
                        <Button color="secondary" size="small" onClick={() => navigate(APPOINTMENTS_ROUTE)}>
                            {t('homepage.roles.operator.secondStep.actions.appointmentCalendar')}
                        </Button>
                    )}
                </>
            ),
        },
        third: {
            title: t('homepage.roles.operator.thirdStep.title'),
            tagline: t('homepage.roles.operator.thirdStep.tagline'),
            figures: [
                {
                    figure: figures.returnToProcess,
                    figureTitle: t('homepage.roles.operator.thirdStep.figureTitles.returnToProcess'),
                },
            ],
            actions: (
                <>
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate(RETURNS_LIST_ROUTE)}
                        data-testid="hpGoToReturnList"
                    >
                        {t('homepage.roles.operator.thirdStep.actions.goToReturnList')}
                    </Button>
                    {selectedWarehouse?.hasCalendlyToken && (
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => navigate(APPOINTMENTS_ROUTE)}
                            data-testid="hpAppointmentCalendar"
                        >
                            {t('homepage.roles.operator.thirdStep.actions.appointmentCalendar')}
                        </Button>
                    )}
                </>
            ),
        },
        fourth: {
            title: t('homepage.roles.operator.fourthStep.title'),
            tagline: t('homepage.roles.operator.fourthStep.tagline'),
            figures: [
                {
                    figure: figures.productsToRefit,
                    figureTitle: t('homepage.roles.operator.fourthStep.figureTitles.productsToRefit'),
                },
            ],
            actions: (
                <Button
                    color="secondary"
                    size="small"
                    onClick={() => navigate(REFIT_ROUTE)}
                    data-testid="hpRefitProduct"
                >
                    {t('homepage.roles.operator.fourthStep.actions.refitProduct')}
                </Button>
            ),
        },
    }

    return <DoughnutAndCards steps={steps} />
}

export default OperatorHP
