import * as React from 'react'
import { useEffect, useState } from 'react'
import VerticalStep from '_organisms/VerticalStepper/VerticalStep'
import { useTranslation } from 'react-i18next'
import SliderSecondHand, { MarkType } from '_atoms/inputs/Slider'
import InfoQualityModal from '_organisms/uniqueProduct/InfoQualityModal'
import { HandleSteps } from '_organisms/VerticalStepper/VerticalStepper'
import usePrismicQualitiesStore, { PrismicQualitiesState } from 'utils/store/usePrismicQualities.store'
import { StepBulletStatus } from '_atoms/badges/StepBullet'

// Needed because sometimes Prismic send empty state to Cypress and tests failed
const defaultQualities = [
    { value: 0, name: 'refusedCondition', label: 'Non repris', emoji: '😔' },
    { value: 1, name: 'goodCondition', label: 'Très bon état', emoji: '😐' },
    { value: 2, name: 'veryGoodCondition', label: 'Excellent état', emoji: '🙂' },
    { value: 3, name: 'perfectCondition', label: 'État neuf', emoji: '🤩' },
]

type CheckQualityStepProps = {
    quality: string
    setQuality: (value: string) => void
    currentQuality: string
    handleSteps?: HandleSteps
    reset?: () => void
}

const CheckQualityStep: React.FC<CheckQualityStepProps> = ({ handleSteps, currentQuality, quality, setQuality }) => {
    const { t } = useTranslation()
    const [qualities, setQualities] = useState<MarkType[]>(defaultQualities)
    const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false)

    const prismicQualities = usePrismicQualitiesStore((state: PrismicQualitiesState) => state.qualities)
    const prismicQuality = prismicQualities?.find((prismicQuality) => prismicQuality.value === currentQuality)
    const uniqueProductQuality = prismicQuality?.label || currentQuality
    const qualitiesEmojis = ['😔', '😐', '🙂', '🤩']

    useEffect(() => {
        if (prismicQualities.length > 0) {
            const allQualities = prismicQualities.map((prismicQuality, index) => ({
                value: index,
                name: prismicQuality.value,
                label: prismicQuality.label,
                emoji: qualitiesEmojis[index],
            }))
            setQualities(allQualities)
        }
    }, [])

    function getQualityValue() {
        const qualityValue = qualities.find((item) => item.name === quality)?.value
        return qualityValue === undefined ? null : qualityValue
    }

    function checkQuality() {
        const qualityValue = getQualityValue()
        if (qualityValue === 0) return StepBulletStatus.ERROR
        if (qualityValue && qualityValue > 0) return StepBulletStatus.SUCCESS
        return StepBulletStatus.DEFAULT
    }

    function updateQuality(value: number) {
        setQuality(qualities.find((item) => item.value === value)?.name || '')
    }

    return (
        <VerticalStep
            title={`${t('secondHand.checkQuality.conditionTitle')} : ${uniqueProductQuality}`}
            handleSteps={handleSteps}
            tagline={t('secondHand.checkQuality.isConditionAsStated')}
            status={checkQuality()}
            openInfoModal={() => setInfoModalOpen(true)}
        >
            <SliderSecondHand
                value={getQualityValue()}
                setValue={updateQuality}
                marks={qualities}
                dataTestId={'check-quality-slider'}
            />
            <InfoQualityModal isOpen={isInfoModalOpen} handleClose={() => setInfoModalOpen(false)} />
        </VerticalStep>
    )
}

export default CheckQualityStep
