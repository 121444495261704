import * as React from 'react'
import { useEffect, useState } from 'react'
import { createStyles, Divider, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Page, { HeaderStyleEnum } from '_organisms/Page'
import PauseAndReportButtons from './PauseAndReportButtons'
import Timer from './Timer'
import { useStopwatch } from 'react-timer-hook'
import dayjs from 'dayjs'
import BackgroundWithFooter from './BackgroundWithFooter'
import MediaGrid from './MediaGrid'
import ScanInput from '_molecules/ScanInput'
import { toast } from 'utils/toast'
import { IRefitOperation, IRefitStep } from 'interfaces'
import { downloadFiles, RMSFile } from 'utils/files'
import RefitIssueModal from './RefitIssueModal'
import { REFIT_INSTRUCTION_DOWNLOAD_FILES } from 'utils/routes/backend'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import Counter from '_atoms/badges/Counter'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerAddon: {
            display: 'flex',
            alignItems: 'center',
        },
        headerDivider: {
            height: theme.space(4),
            margin: theme.space(0, 3),
            backgroundColor: theme.palette.neutral[200],
        },
        mobileBanner: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: `calc(100vw - ${theme.space(6)}px)`,
            position: 'fixed',
            zIndex: 1001,
            transform: `translate(0, ${theme.space(-18)}px)`,
        },
        instructionHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: `${theme.space(5)}px`,
            marginBottom: theme.space(4),
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.space(8),
            },
        },
    }),
)

type InstructionProps = {
    refitStep: IRefitStep
    currentOperation?: IRefitOperation | null
    handleUniqueProductScan: (scannedCode: string) => void
    handleIgnoreInstruction: (barcode: string) => void
    goToHomePage: () => void
    handlePauseInstruction: (operationId: number) => void
}

const Instruction: React.FC<InstructionProps> = ({
    refitStep,
    handleUniqueProductScan,
    handleIgnoreInstruction,
    goToHomePage,
    currentOperation,
    handlePauseInstruction,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { seconds, minutes, hours, start, reset } = useStopwatch({ autoStart: false })
    const { uniqueProduct, totalInstructions, instruction, currentInstructionPosition } = refitStep
    const { barcodeUid } = uniqueProduct

    const [issueModalIsOpen, setIssueModalIsOpen] = useState(false)
    const [files, setFiles] = useState<RMSFile[]>([])

    async function buildFilesURLs(files: RMSFile[], instructionId: number) {
        try {
            const sortedFiles = files.sort((a, b) => a.position! - b.position!)
            const data = await downloadFiles(sortedFiles, REFIT_INSTRUCTION_DOWNLOAD_FILES(instructionId!))
            for (let i = 0; i < files.length; i += 1) {
                sortedFiles[i].url = data![i]
            }
            setFiles(sortedFiles)
        } catch (e: any) {
            toast.error(t('files.fetchError', { message: e.message }))
        }
    }

    const { title, description, scanOnly, isMandatory, estimatedDuration } = instruction
    const isLastInstruction = currentInstructionPosition === totalInstructions

    useEffect(() => {
        if (instruction.files) {
            buildFilesURLs(instruction.files, instruction.id!)
        }
    }, [refitStep])

    useEffect(() => {
        if (currentOperation) {
            start()
        }
        return () => {
            reset(new Date(), false)
        }
    }, [currentOperation])

    function handleNextInstruction() {
        handleUniqueProductScan(barcodeUid)
    }

    function handleScanDuringRefit(scannedCode: string) {
        if (scannedCode !== barcodeUid) {
            toast.error(t('new_refit.errors.scanWrongPuidDuringRefit'))
        } else {
            handleNextInstruction()
        }
    }

    function openReportIssueModal() {
        setIssueModalIsOpen(true)
    }

    function setExtraDuration() {
        const duration = dayjs.duration({ hours, minutes, seconds }).as('seconds')
        const durationDelta = duration + (currentOperation?.duration || 0) - estimatedDuration
        return durationDelta > 0 ? durationDelta : 0
    }

    const renderHeaderAddon = () => (
        <div className={classes.headerAddon}>
            <Counter current={currentInstructionPosition} total={totalInstructions} inHeader />
            {!isMobile && (
                <>
                    <Divider orientation="vertical" className={classes.headerDivider} />
                    <PauseAndReportButtons
                        handlePause={() => handlePauseInstruction(currentOperation!.id)}
                        openReportIssueModal={openReportIssueModal}
                    />
                </>
            )}
        </div>
    )

    return (
        <Page
            title={title}
            section={t('new_refit.home.title')}
            rightAddon={{ addon: renderHeaderAddon(), displayOnMobile: true }}
            withMenuButton={false}
            headerStyle={HeaderStyleEnum.WITH_TOOLBAR}
        >
            {currentOperation && (
                <>
                    <ScanInput onSubmit={handleScanDuringRefit} invisible />
                    {isMobile && (
                        <div className={classes.mobileBanner}>
                            <PauseAndReportButtons
                                handlePause={() => handlePauseInstruction(currentOperation!.id)}
                                openReportIssueModal={openReportIssueModal}
                            />
                            <Timer duration={estimatedDuration} extraDuration={setExtraDuration()} />
                        </div>
                    )}
                    <BackgroundWithFooter
                        scanOnly={scanOnly}
                        isMandatory={isMandatory}
                        isLastInstruction={isLastInstruction}
                        handleNextInstruction={handleNextInstruction}
                        handleIgnoreInstruction={() => handleIgnoreInstruction(barcodeUid)}
                    >
                        <>
                            <div className={classes.instructionHeader}>
                                <BlueBackgroundHeader
                                    title={t('new_refit.instruction.descriptionTitle')}
                                    tagline={description}
                                />
                                {!isMobile && <Timer duration={estimatedDuration} extraDuration={setExtraDuration()} />}
                            </div>
                            {files && <MediaGrid files={files} />}
                        </>
                    </BackgroundWithFooter>
                </>
            )}
            {issueModalIsOpen && (
                <RefitIssueModal
                    isOpen={issueModalIsOpen}
                    handleClose={() => setIssueModalIsOpen(false)}
                    refitStep={refitStep}
                    refitOperationId={currentOperation!.id}
                    goToHomePage={goToHomePage}
                />
            )}
        </Page>
    )
}

export default Instruction
