import * as React from 'react'
import { createStyles, makeStyles, Theme, CardMedia } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImageSlash } from '@fortawesome/pro-regular-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        productPictureContainer: {
            position: 'relative',
            flexBasis: 'auto',
            height: '90px',
            width: '90px',
            overflow: 'hidden',
            borderRadius: '4%',
        },
        productPicture: {
            objectFit: 'contain',
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            margin: 'auto',
            borderRadius: '4%',
            color: theme.palette.grey[300],
            fontSize: theme.typography.pxToRem(36),
        },
    }),
)

type SquareProductImageProps = {
    image?: string | null
    className?: string
    children?: JSX.Element
    [anyProps: string]: any
}

const SquareProductImage: React.FC<SquareProductImageProps> = (props) => {
    const classes = useStyles()
    const { image, className, children, ...anyProps } = props
    return (
        <div className={`${classes.productPictureContainer} ${className}`} {...anyProps}>
            {image ? (
                <CardMedia component="img" className={classes.productPicture} image={image} alt="Product image" />
            ) : (
                <FontAwesomeIcon icon={faImageSlash} className={classes.productPicture} />
            )}
            {children}
        </div>
    )
}

export default SquareProductImage
