import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import { IParcel, Order } from 'interfaces'
import GroupedComments from '_molecules/GroupedComments'
import rmsApi from 'utils/api'
import { UNIQUE_PRODUCTS_LAST_COMMENT_URL } from 'utils/routes/backend'
import { useEffect, useState } from 'react'
import { toast } from 'utils/toast'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        soonAvailableLink: {
            color: 'grey',
            pointerEvents: 'none',
            cursor: 'default',
        },
    }),
)

type ParcelInfoSectionProps = {
    parcel?: IParcel
    order?: Order | null
}

const ParcelCommentsSection: React.FC<ParcelInfoSectionProps> = ({ parcel, order: dateToDateOrder }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [orders, setOrders] = useState<Order[]>(dateToDateOrder ? [dateToDateOrder] : [])

    async function fetchLastOrderComment(uniqueProductIds: number[]): Promise<void> {
        if (uniqueProductIds?.length) {
            try {
                const uniqueProductsCommentRequest = await rmsApi.get(
                    UNIQUE_PRODUCTS_LAST_COMMENT_URL(uniqueProductIds),
                )

                // Avoid to display several times the same comment
                const uniqueOrders = new Set<Order>()
                for (const uniqueProduct of uniqueProductsCommentRequest.data) {
                    const order = uniqueProduct.shipments[0]?.order
                    uniqueOrders.add(order)
                }
                setOrders(Array.from(uniqueOrders))
            } catch (e: any) {
                toast.warning(t('returns.parcelInfos.parcelCommentsError'))
            }
        }
    }

    useEffect(() => {
        if (!dateToDateOrder && parcel?.returnItems?.length) {
            const uniqueProductIds = parcel?.returnItems.map((returnItem) => returnItem.uniqueProductId)
            fetchLastOrderComment(uniqueProductIds)
        }
    }, [])

    const commentsInfos = []
    for (const order of orders) {
        if (order.comment) {
            commentsInfos.push({
                comment: order.comment,
                detail: t('returns.parcelInfos.order', { omsOrderId: order.omsOrderId }),
            })
        }
    }

    return (
        <Grid item xs={12} sm={6}>
            {commentsInfos && commentsInfos.length > 0 && (
                <>
                    <SectionTitle className={classes.sectionTitle} size="large">
                        {t('returns.parcelInfos.parcelComments')}
                    </SectionTitle>
                    <GroupedComments comments={commentsInfos} />
                </>
            )}
        </Grid>
    )
}

export default ParcelCommentsSection
