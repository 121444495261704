import * as React from 'react'
import { Box, CardMedia, createStyles, makeStyles, Theme } from '@material-ui/core'
import { faImageSlash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles<Theme, { size: string }>((theme: Theme) =>
    createStyles({
        root: {
            width: ({ size }) => size,
            height: ({ size }) => size,
            border: `1px solid ${theme.palette.neutral[200]}`,
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        image: {
            maxHeight: '100%',
            width: 'auto',
            maxWidth: '100%',
            height: 'auto',
        },
        noImage: {
            color: theme.palette.grey[300],
            fontSize: theme.typography.pxToRem(36),
        },
    }),
)

export enum SquaredImageSize {
    'M' = '90px',
    'S' = '80px',
    'XS' = '60px',
    'XXS' = '50px',
    'XXXS' = '40px',
}

export interface SquaredImageProps {
    src: string | null
    alt?: string
    size?: SquaredImageSize
    className?: string
}

const SquaredImage: React.FC<SquaredImageProps> = ({ src, alt = '', size = SquaredImageSize.M, className }) => {
    const classes = useStyles({ size })

    return (
        <Box className={`${classes.root} ${className}`}>
            {src ? (
                <CardMedia component="img" src={src} alt={alt} className={classes.image} />
            ) : (
                <FontAwesomeIcon icon={faImageSlash} className={classes.noImage} />
            )}
        </Box>
    )
}

export default SquaredImage
