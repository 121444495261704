import * as React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PICKING_PACKING_ROUTE } from 'utils/routes/frontend'
import { UNPROCESSED_PICK_PACK_ISSUES_URL } from 'utils/routes/backend'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page from '_organisms/Page'
import { IPickPackIssueDisplay, PickPackIssueListFilter } from 'interfaces/PickPackIssue.interface'
import Text from '_atoms/text/Text'
import ButtonGroup from '_molecules/ButtonGroup'
import PickPackIssueCard from './PickPackIssueCard'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        filterButton: {
            marginBottom: theme.space(6),
        },
        text: {
            color: theme.palette.neutral[600],
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
            marginBottom: theme.space(4),
            padding: theme.space(0, 5),
        },
        issueList: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
            paddingBottom: theme.space(6),
        },
    }),
)

const PickPackIssueList: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])
    const [issues, setIssues] = useState<IPickPackIssueDisplay[]>([])
    const [filter, setFilter] = useState<string>(PickPackIssueListFilter.NEED_OPERATOR_ACTION)

    async function fetchIssues() {
        try {
            const issuesRequest = await rmsApi.get(UNPROCESSED_PICK_PACK_ISSUES_URL)
            setIssues(issuesRequest.data)
        } catch (e) {
            toast.error(t('new_orders.errors.fetchIssuesError'))
        }
    }

    useEffect(() => {
        fetchIssues()
    }, [selectedCustomers, selectedWarehouse])

    const buttonGroupOptions = [
        { value: PickPackIssueListFilter.NEED_OPERATOR_ACTION, label: t('new_orders.issueList.withSavActions.title') },
        {
            value: PickPackIssueListFilter.NEED_SAV_ACTION,
            label: t('new_orders.issueList.withoutSavActions.title'),
        },
    ]

    return (
        <Page
            title={t('new_orders.issueList.pageTitle')}
            section={t('new_orders.pickPackTab')}
            handlePrevious={() => navigate(PICKING_PACKING_ROUTE)}
            withDivider
        >
            <div className={classes.header}>
                <ButtonGroup
                    options={buttonGroupOptions}
                    value={filter}
                    setValue={setFilter}
                    buttonClass={classes.filterButton}
                />
                <Text variant="P5" className={classes.text}>
                    {t(`new_orders.issueList.${filter}.tagline`)}
                </Text>
            </div>
            <div className={classes.issueList}>
                {issues
                    .filter((issue) => issue.needSavAction === (filter === PickPackIssueListFilter.NEED_SAV_ACTION))
                    .map((issue, index) => (
                        <PickPackIssueCard key={index} {...issue} />
                    ))}
            </div>
        </Page>
    )
}

export default PickPackIssueList
