import * as React from 'react'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import DropDownSearch from '_molecules/DropDownSearch'

import { Link } from 'react-router-dom'
import { IRefitInstruction } from 'interfaces/RefitInstruction.interface'
import { useTranslation } from 'react-i18next'

type SearchInstructionModalProps = {
    handleChange: (instruction: IRefitInstruction) => void
    options: any[]
    handleClose: () => void
}

const SearchInstructionModal: React.FC<SearchInstructionModalProps> = ({ handleChange, options, handleClose }) => {
    const { t } = useTranslation()

    return (
        <ModalWithHeader
            title={t('refit.instructionGroups.manageGroup.addInstruction.link')}
            headerType={HeaderType.DIVIDER}
            handleClose={handleClose}
            width={800}
            open={true}
        >
            <DropDownSearch
                placeholder={t('refit.instructionGroups.manageGroup.addInstruction.instructionName')}
                onChangeSelection={handleChange}
                options={options}
                optionLabelKey="categorization"
                renderNoResult={
                    <div>
                        {t('refit.instructionGroups.addInstructionToGroup.noResult')}{' '}
                        <Link to={'/refit-instruction-categories'} onMouseDown={(e) => e.preventDefault()}>
                            {t('refit.instructionGroups.addInstructionToGroup.noResultCTA')}
                        </Link>
                    </div>
                }
                dataTestId="dropdownSelect"
            />
        </ModalWithHeader>
    )
}

export default SearchInstructionModal
