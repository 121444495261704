import { useEffect } from 'react'

export const useDebouncedEffect = (effect: (...args: any) => void, deps: any, delay = 1000): void => {
    useEffect(() => {
        const handler = setTimeout((...args: any) => effect(args), delay)

        return () => clearTimeout(handler)
        // eslint-disable-next-line
    }, [...(deps || []), delay])
}
