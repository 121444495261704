export function readablePrice(price?: string | number): string | undefined {
    if (typeof price === 'number') price = price.toString()
    if (price && price.length > 1) {
        const position = price.length - 2
        let output = ''

        if (price.slice(position) === '00') {
            output = price.slice(0, position)
        } else {
            output = [price.slice(0, position), price.slice(position)].join(',')
        }

        return `${output}\xa0€`
    } else if (price === '0') return `${price}\xa0€`
    else return undefined
}

export function returnPluralPath(t: any, path: string, count: number, displayCount = true, data: any = {}) {
    return `${displayCount ? count + ' ' : ''}${t(`${path}.${count > 1 ? 'plural' : 'singular'}`, data)}`
}
