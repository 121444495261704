import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'

import { ORDER_LIST_URL, PARCELS_URL, SHIPMENTS_URL } from 'utils/routes/backend'

import { IParcel, Order, Shipment, ShipmentStatus } from 'interfaces'
import { makeStyles } from '@material-ui/styles'
import { ThreeDots } from 'react-loader-spinner'
import theme from '../theme'
import debounce from 'lodash/debounce'
import { Alert } from '@material-ui/lab'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import Page from '_organisms/Page'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'

const useStyles = makeStyles(() => ({
    contentBox: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 6),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 2),
        },
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    alert: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '40px',
        maxWidth: 'max-content',
    },
    content: {
        marginTop: '40px',
        marginBottom: '40px',
    },
    fields: {
        marginTop: '40px',
        marginBottom: '40px',
    },
}))

const OrderDeliveredApp: React.FC = () => {
    const classes = useStyles()

    const { t } = useTranslation()
    const navigate = useNavigate()
    const orderId = useParams<{ orderId: string }>().orderId

    const [order, setOrder] = useState<Order>({} as Order)
    const [parcel, setParcel] = useState<IParcel>({} as IParcel)
    const [shipment, setShipment] = useState<Shipment>({} as Shipment)
    const [loading, setLoading] = useState<boolean>(true)
    const [withdrawn, setWithdrawn] = useState(false)
    const [delivered, setDelivered] = useState(false)
    const [comment, setComment] = useState<string>('')
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])

    const debouncedCommentUpdate = useCallback(
        debounce((value) => setComment(value), 1000),
        [],
    )

    useEffect(() => {
        if (comment && comment !== order.comment) {
            updateOrderComment()
        }
        // eslint-disable-next-line
    }, [comment])

    async function updateOrderComment(): Promise<void> {
        try {
            await rmsApi.patch(`${ORDER_LIST_URL}/${orderId}/comment`, {
                comment: comment,
            })
            order.comment = comment
            setComment(comment)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    async function fetchOrder(): Promise<void> {
        try {
            const orderRequest = await rmsApi.get(`${ORDER_LIST_URL}/${orderId}`)
            const order = orderRequest.data
            setOrder(order)
            setComment(order.comment)

            const shipment = order.shipments.find((shipment: Shipment) => !shipment.isReturn)
            const parcel = shipment.parcels[0] // hypothesis : there can be only one parcel in the in store module
            setShipment(shipment)
            setParcel(parcel)
            setDelivered(!(shipment.status === ShipmentStatus.TO_BE_DELIVERED))
            setWithdrawn(!!parcel.shippedAt)
            setLoading(false)
        } catch (e: any) {
            if (e.response?.status === 403) {
                toast.error(t('orders.error403'))
                setTimeout(() => {
                    navigate('/orders')
                }, 5000)
            } else if (e.response?.status === 404) {
                toast.error(t('orders.error404'))
                setTimeout(() => {
                    navigate('/orders')
                }, 5000)
            } else {
                console.error(e.message)
            }
        }
    }

    useEffect(() => {
        fetchOrder()
        // eslint-disable-next-line
    }, [selectedCustomers])

    async function confirmParcelWithdrawal(): Promise<void> {
        try {
            setWithdrawn(true)
            await rmsApi.patch(`${PARCELS_URL}/${parcel.id}/shippedAt`)
        } catch (e: any) {
            const { response } = e
            const message403 = t('orders.parcelHasBeenWithdrawn')
            const message404 = t('orders.parcelNotFound')
            if (response.status === 403) toast.error(message403)
            if (response.status === 404) toast.error(message404)
        }
    }

    async function confirmParcelDelivered(): Promise<void> {
        try {
            await rmsApi.patch(`${SHIPMENTS_URL}/${shipment.id}/set-status-to-withdraw`)
            setDelivered(true)
            toast.success(t('orders.parcelSuccessfullyDelivered'))
            navigate('/orders')
        } catch (e: any) {
            const { response } = e
            const message403 = t('orders.parcelHasBeenDelivered')
            const message404 = t('orders.parcelNotFound')
            if (response.status === 403) toast.error(message403)
            if (response.status === 404) toast.error(message404)
        }
    }

    return (
        <>
            {loading ? (
                <div className={classes.center}>
                    <ThreeDots visible={loading} color={theme.palette.primary.main} height={100} width={100} />
                </div>
            ) : (
                <Page
                    title={
                        !delivered
                            ? `${t('orders.parcelDelivered')}${order.omsOrderId}`
                            : `${t('orders.parcelWithdrawal')}${order.omsOrderId}`
                    }
                >
                    <Box className={classes.contentBox}>
                        {withdrawn && (
                            <Alert severity="success" className={classes.alert} data-testid={'orderDeliveredAlert'}>
                                {t('orders.parcelHasBeenWithdrawn')}
                            </Alert>
                        )}

                        <div className={classes.content}>
                            <Typography variant="body1">
                                <span data-testid="orderDeliveredName">
                                    <b>{t('orders.name')} : </b> {order.firstname} {order.lastname}
                                </span>
                                <br />
                                <span data-testid="orderDeliveredPhone">
                                    <b>{t('orders.phone')} : </b> {order.phone}
                                </span>
                                <br />
                            </Typography>
                            {order.productReferences && (
                                <div>
                                    <Typography variant="body1">
                                        <b>{t('orders.productsList')} : </b> <br />
                                    </Typography>
                                    <ul data-testid="orderDeliveredProducts">
                                        {order.productReferences.map((productReferences: any) => {
                                            return (
                                                <li key={productReferences.productReference.id}>
                                                    <Typography variant="body1">
                                                        {productReferences.productReference.size
                                                            ? `${productReferences.productReference.name} - ${productReferences.productReference.size}`
                                                            : productReferences.productReference.name}
                                                    </Typography>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                        </div>

                        {delivered && (
                            <div className={classes.fields}>
                                <Typography variant="body1">
                                    <b>{t('orders.comment')} : </b> <br />
                                </Typography>
                                <TextField
                                    defaultValue={comment}
                                    onChange={(e) => debouncedCommentUpdate(e.target.value)}
                                    disabled={withdrawn}
                                    fullWidth={true}
                                    multiline={true}
                                    rows={2}
                                    inputProps={{ maxLength: 500 }}
                                    data-testid={'orderDeliveredTextArea-comment'}
                                />
                            </div>
                        )}

                        <div className={classes.center}>
                            {delivered ? (
                                <>
                                    <Button
                                        onClick={confirmParcelWithdrawal}
                                        variant="contained"
                                        color="primary"
                                        disabled={withdrawn}
                                        data-testid={'orderDeliveredButton-withdrawal'}
                                    >
                                        <Typography variant="button">{t('orders.confirmParcelWithdrawal')}</Typography>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        onClick={confirmParcelDelivered}
                                        variant="contained"
                                        color="primary"
                                        disabled={delivered}
                                        data-testid={'orderDeliveredButton-delivered'}
                                    >
                                        <Typography variant="button">{t('orders.confirmParcelDelivered')}</Typography>
                                    </Button>
                                </>
                            )}
                        </div>
                    </Box>
                </Page>
            )}
        </>
    )
}

export default OrderDeliveredApp
