import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { RMSFile } from 'utils/files'
import SquaredImage, { SquaredImageSize } from '_atoms/images/SquaredImage'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filesContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: `${theme.space(4)}px`,
        },

        mediaContainer: {
            position: 'relative',
            marginTop: theme.space(2),
        },
        deleteIcon: {
            position: 'absolute',
            right: theme.space(-2),
            top: theme.space(-2),
            padding: theme.spacing(0.5),
            cursor: 'pointer',
            height: '12px',
            width: '12px',
            backgroundColor: '#484E5A',
            color: theme.palette.common.white,
            borderRadius: '50%',
        },
    }),
)

type PictureGridProps = {
    pictures: RMSFile[]
    handleDelete?: (file: RMSFile) => void
    size?: SquaredImageSize
}

const PictureGrid: React.FC<PictureGridProps> = ({ pictures, size = SquaredImageSize.XS, handleDelete }) => {
    const classes = useStyles()

    return (
        <Box className={classes.filesContainer} data-testid="thumbnailGrid">
            {pictures.map((picture, index) => (
                <Box key={index} className={classes.mediaContainer}>
                    <SquaredImage size={size} src={picture.url ? picture.url : URL.createObjectURL(picture)} />
                    {handleDelete && (
                        <FontAwesomeIcon
                            icon={faXmark}
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(picture)}
                            data-testid="deleteImage"
                        />
                    )}
                </Box>
            ))}
        </Box>
    )
}

export default PictureGrid
