import * as React from 'react'
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

const useSectionTitleStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: theme.typography.fontFamily,
            color: '#484E5A',
            fontWeight: 600,
        },
        sectionTitle: {
            color: theme.palette.grey[400],
            fontWeight: 100,
            [theme.breakpoints.down('sm')]: {
                color: 'rgba(255, 255, 255, 0.75)',
            },
        },
        small: {
            fontSize: theme.typography.pxToRem(12),
        },
        medium: {
            fontSize: theme.typography.pxToRem(14),
        },
        large: {
            fontSize: theme.typography.pxToRem(18),
        },
        rightAddonContainer: {
            display: 'flex',
            flexDirection: 'row-reverse',
            marginLeft: 'auto',
            marginTop: 'auto',
        },
    }),
)

type SectionTitleProps = {
    size?: 'small' | 'medium' | 'large'
    className?: string
    children: string
    rightAddon?: any
    [typographyProps: string]: any
}
// SectionTitle is a title into the content of the page (we might easily have several SectionTitle in a page)
export const SectionTitle: React.FC<SectionTitleProps> = ({
    size = 'medium',
    className,
    children,
    rightAddon,
    ...typographyProps
}) => {
    const classes = useSectionTitleStyles()
    return (
        <Grid container alignItems="center">
            <Grid item>
                <Typography
                    className={`${classes.title} ${classes[size] || ''} ${className || ''}`}
                    variant="h6"
                    component="p"
                    data-testid="sectionTitle"
                    {...typographyProps}
                >
                    {children}
                </Typography>
            </Grid>
            {rightAddon && (
                <Grid item className={classes.rightAddonContainer}>
                    {rightAddon}
                </Grid>
            )}
        </Grid>
    )
}
