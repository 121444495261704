import { ReactElement, useEffect, useState } from 'react'
import * as React from 'react'
import {
    makeStyles,
    Theme,
    createStyles,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Collapse,
    Box,
    TablePagination,
    TableSortLabel,
    useMediaQuery,
    useTheme,
    Grid,
    Typography,
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ChevronRight from '@material-ui/icons/ChevronRight'

type StyleProps = {
    hideCollapsedArrow: boolean
    collapseOpen?: boolean
}

const MIN_WIDTH_DEFAULT = 100

const useRowStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        tableContainer: {
            marginBottom: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                maxHeight: '60vh',
            },
            '& .MuiTableHead-root .MuiTableCell-head': {
                padding: theme.spacing(1.5),
                lineHeight: 1,
                fontWeight: 700,
                [theme.breakpoints.down('lg')]: {
                    fontSize: theme.typography.pxToRem(12),
                },
            },
        },
        root: (props) =>
            props.hideCollapsedArrow
                ? {
                      cursor: 'pointer',
                      '&:hover, &:focus': {
                          '&>.MuiTableCell-root': {
                              color: theme.palette.common.black,
                              backgroundColor: theme.palette.grey[200],
                          },
                      },
                  }
                : {
                      '& > *': {
                          borderBottom: 'unset',
                      },
                      '&:nth-of-type(odd)': {
                          backgroundColor: theme.palette.action.hover,
                      },
                  },
        retardedRows: {
            backgroundColor: '#ff0000',
        },
        collapseRow: {
            backgroundColor: theme.palette.grey[100],
            '& > *': {
                borderBottom: 'unset',
            },
        },
        errorMessage: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.error.main,
        },
        collapseBox: {
            overflowY: 'auto',
            marginRight: 0,
            marginLeft: 0,
            '& > .MuiTypography-root': {
                paddingLeft: 10,
            },
            '& > .MuiTypography-root ~ .MuiTypography-root': {
                paddingLeft: 0,
            },
            '& .MuiTableCell-root': {
                [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(0.5, 1),
                    fontSize: theme.typography.pxToRem(12),
                },
            },
            '& .MuiTableRow-head': {
                '& .MuiTableCell-root': {
                    [theme.breakpoints.down('md')]: {
                        fontSize: theme.typography.pxToRem(10),
                        lineHeight: 1,
                    },
                },
            },
        },
        tablePagination: {
            '& .MuiTablePagination-actions': {
                marginLeft: 0,
            },
            '& .MuiTablePagination-selectRoot': {
                marginRight: 8,
            },
        },
        cardList: {
            marginTop: theme.spacing(2),
            boxShadow: '0px 6px 15px 0px rgba(3,25,36, 0.15)',
        },
        cardMobile: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            // padding: theme.spacing(1.75, 1),
            padding: theme.spacing(1, 0.5),
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
        cardMobileClickable: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            // padding: theme.spacing(1.75, 1),
            padding: theme.spacing(1, 0.5),
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:hover, &:focus-within': {
                cursor: 'pointer',
            },
        },
        cardLayout: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: `${theme.spacing(0.5)}px`,
        },
        overlayMobile: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            '& .btn': {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                border: 0,
                opacity: 0,
                width: '100%',
            },
        },
        labelPrimary: {
            textTransform: 'uppercase',
            fontSize: theme.typography.pxToRem(10),
            lineHeight: 1.2,
        },
        rowPrimary: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 1.3,
            fontWeight: 700,
        },
        labelSecondary: {
            fontSize: theme.typography.pxToRem(10),
            color: theme.palette.grey[700],
            lineHeight: 1,
            '& strong': {
                color: theme.palette.common.black,
            },
        },
        labelSecondaryVal: {
            fontSize: theme.typography.pxToRem(10),
            fontWeight: 700,
            color: theme.palette.common.black,
            marginLeft: '.5ch',
        },
        cardCellPrimary: {
            padding: theme.spacing(0.5),
            wordBreak: 'break-all',
        },
        cardCellSecondary: {
            lineHeight: 1,
            padding: theme.spacing(0, 0.5),
        },
        alignRight: {
            textAlign: 'right',
        },
        mobileFilter: {
            position: 'relative',
            display: 'inline-flex',
            flexDirection: 'column',
            padding: theme.spacing(1),
        },
        sort: {
            whiteSpace: 'nowrap',
            flexDirection: 'row',
            '&>.MuiSvgIcon-root': {
                [theme.breakpoints.down('md')]: {
                    opacity: 1,
                },
            },
            '&.MuiTableSortLabel-active': {
                color: theme.palette.primary.main,
            },
        },
        clickableRow: {
            '&:hover, &:focus-within': {
                cursor: 'pointer',
            },
        },
    }),
)

type CollapsableRowProps = {
    styleProps: any
    displayed: any[]
    collapsed?: any
    hideCollapsedArrow: boolean
    loading?: boolean
    colspan?: number
    className?: any
    onRowClick?: (row: any) => void
}

const Row: React.FC<CollapsableRowProps> = ({
    displayed,
    collapsed,
    styleProps,
    hideCollapsedArrow,
    loading,
    colspan,
    className,
    onRowClick,
}) => {
    const [open, setOpen] = React.useState(false)
    const classes = useRowStyles({ hideCollapsedArrow, collapseOpen: open })

    return (
        <>
            <TableRow className={classes.root} data-testid="tableRow">
                {loading &&
                    displayed.map((column, index) => {
                        return (
                            <TableCell key={index}>
                                <Skeleton variant="text"></Skeleton>
                            </TableCell>
                        )
                    })}
                {collapsed && !hideCollapsedArrow && (
                    <TableCell style={styleProps}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )}
                {!loading &&
                    displayed?.map((row, key) => (
                        <TableCell
                            key={key}
                            style={styleProps}
                            align="center"
                            onClick={() =>
                                collapsed && hideCollapsedArrow
                                    ? setOpen(!open)
                                    : onRowClick
                                    ? onRowClick(displayed)
                                    : ''
                            }
                            colSpan={colspan || 1}
                            className={className || ''}
                            data-testid={`column_${key}`}
                        >
                            {row}
                        </TableCell>
                    ))}
            </TableRow>
            {collapsed && (
                <TableRow className={classes.collapseRow}>
                    <TableCell style={{ padding: 0 }} colSpan={11}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1} className={classes.collapseBox}>
                                {collapsed}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

type HeaderProps = {
    headerRows: HeaderRows[]
    overrideHeader?: FilterProps[]
    hasCollapsedValues: boolean
    hideCollapsedArrow: boolean
    sortOrder?: 'desc' | 'asc' | undefined
    sortOrderBy?: string
    isMobile?: boolean
}

type FilterProps = {
    component?: ReactElement
    sortHandler?: (rowName: string) => void
}

const TableHeader: React.FC<HeaderProps> = ({
    headerRows,
    overrideHeader,
    hasCollapsedValues,
    hideCollapsedArrow,
    sortOrder,
    sortOrderBy,
    isMobile,
}) => {
    const classes = useRowStyles({ hideCollapsedArrow })
    return isMobile ? (
        <>
            {headerRows?.map((row, idx) => {
                const sortHandler =
                    overrideHeader && overrideHeader[idx] && overrideHeader[idx].sortHandler
                        ? overrideHeader[idx].sortHandler
                        : undefined
                return (
                    overrideHeader &&
                    !isEmpty(overrideHeader[idx]) && (
                        <div key={idx} className={classes.mobileFilter}>
                            {sortHandler && (
                                <TableSortLabel
                                    className={classes.sort}
                                    active={sortOrderBy === row.id}
                                    direction={sortOrderBy === row.id ? sortOrder : 'asc'}
                                    onClick={() => (sortHandler ? sortHandler(row.id) : '')}
                                >
                                    {row.label}
                                </TableSortLabel>
                            )}
                            {overrideHeader[idx].component ? (
                                <>
                                    <div className={classes.labelPrimary}>{row.label}</div>
                                    <div>{overrideHeader[idx].component}</div>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                )
            })}
        </>
    ) : (
        <TableHead>
            <TableRow>
                {hasCollapsedValues && !hideCollapsedArrow && <TableCell />}
                {headerRows?.map((row, idx) => {
                    const sortHandler =
                        overrideHeader && overrideHeader[idx] && overrideHeader[idx].sortHandler
                            ? overrideHeader[idx].sortHandler
                            : undefined
                    return (
                        <TableCell
                            key={idx}
                            align="center"
                            className={classes.tableHeaders}
                            style={{ minWidth: row.desktopMinWidth || MIN_WIDTH_DEFAULT }}
                        >
                            {overrideHeader && !isEmpty(overrideHeader[idx]) ? (
                                <>
                                    {sortHandler && (
                                        <TableSortLabel
                                            className={classes.sort}
                                            active={sortOrderBy === row.id}
                                            direction={sortOrderBy === row.id ? sortOrder : 'asc'}
                                            onClick={() => (sortHandler ? sortHandler(row.id) : '')}
                                        >
                                            {row.label}
                                        </TableSortLabel>
                                    )}
                                    {overrideHeader[idx].component ? (
                                        <>
                                            {row.label} {overrideHeader[idx].component}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                row.label
                            )}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

export type TPagination = {
    totalItems: number
    itemCount: number
    itemsPerPage: number
    totalPages: number
    currentPage: number
    handleChangePage: () => void
    handleChangeRowsPerPage: () => void
}

export type HeaderRows = {
    id: string
    label: string
    desktopMinWidth?: number
}

type TableProps = {
    header: HeaderRows[]
    overrideHeader?: FilterProps[]
    rows: CollapsableRowProps[]
    hideCollapsedArrow?: boolean
    paginationProps?: TPagination
    sortOrder?: 'desc' | 'asc' | undefined
    sortOrderBy?: string
    loading?: boolean
    tableUI?: string
    onRowClick?: (row: any) => void
}

export const CollapsibleTable: React.FC<TableProps> = ({
    header,
    overrideHeader,
    rows,
    hideCollapsedArrow = false,
    paginationProps,
    sortOrder,
    sortOrderBy,
    loading,
    tableUI,
    onRowClick,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const classes = useRowStyles({ hideCollapsedArrow })
    const totalCols = header && header.length ? header.length : 6
    const hasCollapsedValues = rows.length > 0 && rows[0].collapsed
    const loadingDatasRow = [{ displayed: new Array(totalCols).fill(''), hideCollapsedArrow: true, styleProps: {} }]
    const errorDataRow = [
        {
            displayed: new Array(1).fill(t('table.emptyTable')),
            hideCollapsedArrow: true,
            styleProps: {},
            className: classes.errorMessage,
        },
    ]
    rows = loading ? loadingDatasRow : rows

    const [mobileCollapseOpen, setMobileCollapseOpen] = useState(rows.map(() => false))

    useEffect(() => {
        if (rows.length !== mobileCollapseOpen.length) {
            setMobileCollapseOpen(rows.map(() => false))
        }
    }, [rows, mobileCollapseOpen])

    return (
        <>
            {isMobile ? (
                <>
                    <TableHeader
                        headerRows={header}
                        overrideHeader={overrideHeader}
                        hasCollapsedValues={hasCollapsedValues}
                        hideCollapsedArrow={hideCollapsedArrow}
                        sortOrder={sortOrder}
                        sortOrderBy={sortOrderBy}
                        isMobile
                    />
                    {rows.length <= 0 ? (
                        <Box textAlign={'center'} p={3} className={classes.errorMessage}>
                            {errorDataRow[0].displayed}
                        </Box>
                    ) : (
                        <>
                            <div className={classes.cardList}>
                                {!tableUI && (
                                    <>
                                        {rows.map((row, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className={
                                                        onRowClick ? classes.cardMobileClickable : classes.cardMobile
                                                    }
                                                    onClick={() => {
                                                        if (onRowClick) {
                                                            onRowClick(row?.displayed)
                                                        } else {
                                                            setMobileCollapseOpen((el) =>
                                                                el.map((o, i) => {
                                                                    if (i === idx) return !o
                                                                    return o
                                                                }),
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <Grid container spacing={0}>
                                                        {row?.displayed.map((el, index) => {
                                                            const isSecondary = index >= 3
                                                            return (
                                                                <Grid
                                                                    item
                                                                    xs={isSecondary ? 'auto' : 4}
                                                                    sm={isSecondary ? 'auto' : 3}
                                                                    key={index}
                                                                >
                                                                    <div
                                                                        className={
                                                                            isSecondary
                                                                                ? classes.cardCellSecondary
                                                                                : classes.cardCellPrimary
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            component={isSecondary ? 'span' : 'div'}
                                                                            className={
                                                                                isSecondary
                                                                                    ? classes.labelSecondary
                                                                                    : classes.labelPrimary
                                                                            }
                                                                        >
                                                                            {header[index].label}
                                                                        </Typography>
                                                                        <Typography
                                                                            component={isSecondary ? 'span' : 'div'}
                                                                            className={
                                                                                isSecondary
                                                                                    ? classes.labelSecondaryVal
                                                                                    : classes.rowPrimary
                                                                            }
                                                                        >
                                                                            {el === '' ? 'N/A' : el}
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                            )
                                                        })}
                                                        {row?.collapsed && (
                                                            <Grid item xs={12}>
                                                                <Collapse
                                                                    in={mobileCollapseOpen[idx]}
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                >
                                                                    <Box margin={1} className={classes.collapseBox}>
                                                                        {row?.collapsed}
                                                                    </Box>
                                                                </Collapse>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                                {tableUI === 'preparation-step1' &&
                                    rows.map((row, idx) => {
                                        return (
                                            <div key={idx} className={classes.cardMobile}>
                                                <div className={classes.overlayMobile}>{row.displayed[0]}</div>
                                                <div className={classes.cardLayout}>
                                                    <div className={classes.rowMobile}>
                                                        <Grid container spacing={1} wrap={'nowrap'}>
                                                            <Grid item xs={4}>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.labelPrimary}
                                                                >
                                                                    {header[1].label}
                                                                </Typography>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.rowPrimary}
                                                                >
                                                                    {row.displayed[1] === undefined
                                                                        ? 'N/A'
                                                                        : row.displayed[1]}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.labelPrimary}
                                                                >
                                                                    {header[2].label}
                                                                </Typography>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.rowPrimary}
                                                                >
                                                                    {row.displayed[2]}
                                                                </Typography>
                                                            </Grid>
                                                            {row.displayed[3] !== t('wms.preparation.lowPriority') && (
                                                                <Grid item xs={4}>
                                                                    <Typography
                                                                        component={'div'}
                                                                        className={classes.rowPrimary}
                                                                    >
                                                                        {row.displayed[3]}
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </div>
                                                    <div className={classes.rowMobile}>
                                                        <Grid container spacing={1} wrap={'nowrap'}>
                                                            <Grid item>{row.displayed[4]}</Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    component={'span'}
                                                                    className={classes.labelSecondary}
                                                                >
                                                                    {header[5].label}&nbsp;
                                                                    <strong>{row.displayed[5]}</strong>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    component={'span'}
                                                                    className={classes.labelSecondary}
                                                                >
                                                                    {header[6].label}&nbsp;
                                                                    <strong>{row.displayed[6]}</strong>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                                <div>
                                                    <ChevronRight />
                                                </div>
                                            </div>
                                        )
                                    })}
                                {tableUI === 'preparation-step2' &&
                                    rows.map((row, idx) => {
                                        return (
                                            <div key={idx} className={classes.cardMobile}>
                                                <div className={classes.cardLayout}>
                                                    <div className={classes.rowMobile}>
                                                        <Grid container spacing={1} wrap={'nowrap'}>
                                                            <Grid item xs={8}>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.labelPrimary}
                                                                >
                                                                    {header[3].label}
                                                                </Typography>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.rowPrimary}
                                                                >
                                                                    {row.displayed[3]}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} className={classes.alignRight}>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.labelPrimary}
                                                                >
                                                                    {header[1].label}
                                                                </Typography>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.rowPrimary}
                                                                >
                                                                    {row.displayed[1]}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className={classes.rowMobile}>
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            wrap={'nowrap'}
                                                            alignItems={'flex-end'}
                                                        >
                                                            <Grid item xs={8}>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.labelPrimary}
                                                                >
                                                                    {header[0].label}
                                                                </Typography>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.rowPrimary}
                                                                >
                                                                    {row.displayed[0]}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} className={classes.alignRight}>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.labelPrimary}
                                                                >
                                                                    {header[2].label}
                                                                </Typography>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.rowPrimary}
                                                                >
                                                                    {row.displayed[2]}
                                                                </Typography>
                                                                <Typography
                                                                    component={'div'}
                                                                    className={classes.rowPrimary}
                                                                >
                                                                    {row.displayed[7]}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <TableContainer component={Paper} className={`${classes.tableContainer} ${tableUI}`}>
                        <Table stickyHeader aria-label="collapsible table" data-testid={`collapsible-table`}>
                            <TableHeader
                                headerRows={header}
                                overrideHeader={overrideHeader}
                                hasCollapsedValues={hasCollapsedValues}
                                hideCollapsedArrow={hideCollapsedArrow}
                                sortOrder={sortOrder}
                                sortOrderBy={sortOrderBy}
                            />
                            <TableBody>
                                {rows.length <= 0 && (
                                    <Row
                                        displayed={errorDataRow[0].displayed}
                                        styleProps={errorDataRow[0].styleProps}
                                        hideCollapsedArrow={hideCollapsedArrow}
                                        loading={loading}
                                        colspan={totalCols}
                                        className={errorDataRow[0].className}
                                    />
                                )}
                                {rows.map((row, idx) => (
                                    <Row
                                        key={idx}
                                        displayed={row.displayed}
                                        collapsed={row.collapsed}
                                        styleProps={row.styleProps}
                                        hideCollapsedArrow={hideCollapsedArrow}
                                        loading={loading}
                                        onRowClick={onRowClick}
                                        className={onRowClick ? classes.clickableRow : ''}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            {paginationProps && (
                <TablePagination
                    className={classes.tablePagination}
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={paginationProps.totalItems}
                    rowsPerPage={paginationProps.itemsPerPage}
                    page={paginationProps.currentPage - 1}
                    onChangePage={paginationProps.handleChangePage}
                    onChangeRowsPerPage={paginationProps.handleChangeRowsPerPage}
                    backIconButtonText={t('table.pagination.backIcon')}
                    nextIconButtonText={t('table.pagination.nextIcon')}
                    labelRowsPerPage={t('table.pagination.labelRowsPerPage')}
                />
            )}
        </>
    )
}
