import * as React from 'react'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { AVAILABLE_DEMO_STACK, DEPLOY_DEMO_STACK } from 'utils/routes/backend'

import {
    Box,
    Button,
    Card,
    CardContent,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import AlertIcon from 'mdi-react/AlertIcon'
import Page from '_organisms/Page'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& a': {
                textDecoration: 'none',
            },
        },
        loader: {
            marginTop: '100px',
            margin: '0 auto',
        },
        sectionHeader: {
            margin: theme.spacing(0, 0, 3),
            '& .MuiTypography-root': {
                display: 'inline-flex',
                [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.pxToRem(14),
                },
            },
        },
        sectionTitle: {
            marginLeft: theme.spacing(3),
        },
        icon: {
            height: '2em',
            width: '2em',
            marginRight: theme.spacing(0.5),
            transition: theme.transitions.create('opacity'),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(0.25),
                height: '1.5em',
                width: '1.5em',
            },
        },
        on: {
            opacity: 1,
            color: theme.palette.success.main,
        },
        off: {
            opacity: 0,
        },
        alert: {
            color: theme.palette.warning.main,
        },
        card: {
            cursor: 'pointer',
            textAlign: 'left',
            position: 'relative',
        },
    }),
)

type CleverCloudStack = {
    id: number
    syliusStackName: string
    nextjsStackName: string
    customer?: {
        id: number
        name: string
    }
}

type DemoStacks = {
    demoStack: CleverCloudStack[]
    customerStack: CleverCloudStack[]
}

const dummyPassword = 'renttheduck'

const DemoAdminApp: React.FC = () => {
    const classes = useStyles()
    const [password, setPassword] = useState<string | null>(null)
    const [stackData, setStackData] = useState<DemoStacks | null>(null)
    const [pickedDemoStack, setPickedDemoStack] = useState<CleverCloudStack | null>(null)
    const [pickedCustomerStack, setPickedCustomerStack] = useState<CleverCloudStack | null>(null)
    const [openModale, setOpenModale] = useState<boolean>(false)
    const [openSuccessModale, setOpenSuccessModale] = useState<boolean>(false)
    const [nextjsStackLink, setNextjsStackLink] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)

    function promptPassword() {
        const pwd = prompt('Please enter password: #untilwe...', '')
        setPassword(pwd)
        return pwd
    }

    async function fetchAvailableStackData(): Promise<void> {
        if (password !== dummyPassword) {
            if (promptPassword() !== dummyPassword) return
        }
        try {
            const demoRequest = await rmsApi.get(AVAILABLE_DEMO_STACK)
            const demoStacks = demoRequest.data
            setStackData(demoStacks)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    async function deploy() {
        setLoading(true)
        try {
            const demoRequest = await rmsApi.post(
                DEPLOY_DEMO_STACK,
                {
                    storeStackId: pickedCustomerStack?.id,
                    demoStackId: pickedDemoStack?.id,
                },
                { timeout: 100000 },
            )
            setNextjsStackLink(demoRequest.data)
            setOpenSuccessModale(true)
        } catch (e: any) {
            toast.error(e.response.data.error)
        }
        setLoading(false)
        setOpenModale(false)
    }

    useEffect(() => {
        fetchAvailableStackData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (pickedDemoStack && pickedCustomerStack) setOpenModale(true)
        // eslint-disable-next-line
    }, [pickedDemoStack, pickedCustomerStack])

    return (
        <Page withHeader={false}>
            <Box p={3}>
                <Typography variant="h1" component="h2" align={'left'}>
                    Bienvenue dans le module de déploiement des démos&nbsp;<br></br>
                    Ici vous pouvez déployer les stacks csm-test pour effectuer vos démos.
                </Typography>
            </Box>
            <Box p={2}>
                <Typography className={classes.sectionTitle} variant="h3">
                    Stacks de démo disponibles
                </Typography>
                <Grid container spacing={3}>
                    {stackData &&
                        stackData?.demoStack?.map((demoStack: any, index: number) => {
                            const demoLastLaunched = dayjs(demoStack.lastLaunched)
                            const demoLastLaunchedTime = dayjs().diff(dayjs(demoLastLaunched), 'hour') || "moins d'une"
                            const now = dayjs()

                            return (
                                <Grid item xs={12} md={4} key={index}>
                                    <Card
                                        onClick={() =>
                                            pickedDemoStack?.id === demoStack.id
                                                ? setPickedDemoStack(null)
                                                : setPickedDemoStack(demoStack)
                                        }
                                        className={classes.card}
                                    >
                                        <CardContent>
                                            <CheckCircleIcon
                                                className={`${classes.icon} ${
                                                    pickedDemoStack?.id === demoStack.id ? classes.on : classes.off
                                                }`}
                                            />
                                            <Typography variant="h3">{demoStack.syliusStackName}</Typography>
                                            <Typography>Dernier déploiement: {demoStack.lastLaunched}</Typography>
                                            <div
                                                style={
                                                    demoLastLaunched.get('date') === now.get('date')
                                                        ? { visibility: 'visible' }
                                                        : { visibility: 'hidden' }
                                                }
                                            >
                                                <AlertIcon className={`${classes.icon} ${classes.alert}`} />
                                                {`Attention, cette stack a été déployée il y a ${demoLastLaunchedTime} heure, veillez à
                                                    ce que personne n'en ait besoin avant de la remettre à zéro.`}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                </Grid>
            </Box>
            <Box p={2}>
                <Typography className={classes.sectionTitle} variant="h3">
                    Stacks de clients déployables
                </Typography>
                <Grid container spacing={3}>
                    {stackData &&
                        stackData?.customerStack?.map((customerStack: any, index: number) => {
                            const customerLastLaunched = dayjs(customerStack.lastLaunched)
                            const customerLastLaunchedTime =
                                dayjs().diff(dayjs(customerLastLaunched), 'hour') || "moins d'une"
                            const now = dayjs()

                            return (
                                <Grid item xs={12} md={4} key={index}>
                                    <Card
                                        key={index}
                                        className={classes.card}
                                        onClick={() =>
                                            pickedCustomerStack?.id === customerStack.id
                                                ? setPickedCustomerStack(null)
                                                : setPickedCustomerStack(customerStack)
                                        }
                                    >
                                        <CardContent>
                                            <CheckCircleIcon
                                                className={`${classes.icon} ${
                                                    pickedCustomerStack?.id === customerStack.id
                                                        ? classes.on
                                                        : classes.off
                                                }`}
                                            />
                                            <Typography variant="h3">{customerStack.customer.name}</Typography>
                                            <Typography>Type: {customerStack.customer.storeType}</Typography>
                                            <Typography>
                                                Dernier déploiement:{' '}
                                                {dayjs(customerLastLaunched).format('DD/MM HH:mm:ss')}
                                            </Typography>
                                            <div
                                                style={
                                                    customerLastLaunched.get('date') === now.get('date')
                                                        ? { visibility: 'visible' }
                                                        : {
                                                              visibility: 'hidden',
                                                          }
                                                }
                                            >
                                                <AlertIcon className={`${classes.icon} ${classes.alert}`} />
                                                {`Attention, cette stack a été déployée il y a ${customerLastLaunchedTime} heure, veillez à
                                                    ce que personne n'en ait besoin avant de la remettre à zéro.`}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                </Grid>
            </Box>

            {!nextjsStackLink && (
                <Dialog
                    open={openModale}
                    onClose={() => setOpenModale(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {loading ? (
                        <>
                            <DialogTitle id="alert-dialog-title">
                                <Typography variant="h3">Déploiement en cours...</Typography>
                            </DialogTitle>
                            <DialogContent></DialogContent>
                        </>
                    ) : (
                        <>
                            <DialogTitle id="alert-dialog-title">
                                <Typography variant="h3">Êtes-vous sûr(e) ?</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {`Vous vous apprétez à déployer la stack ${
                                        pickedCustomerStack?.customer?.name
                                    } sur la stack de test ${pickedDemoStack?.syliusStackName?.replace(
                                        '-prod-sylius',
                                        '',
                                    )}.`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenModale(false)}>Annuler</Button>
                                <Button
                                    variant="contained"
                                    disableFocusRipple
                                    color="primary"
                                    onClick={deploy}
                                    autoFocus
                                >
                                    Valider
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            )}
            {nextjsStackLink && (
                <>
                    <Dialog
                        open={openSuccessModale}
                        onClose={() => setOpenSuccessModale(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant="h3">Processus terminé !</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {`La stack ${
                                    pickedCustomerStack?.customer?.name
                                } a été déployée sur la stack de test ${pickedDemoStack?.syliusStackName?.replace(
                                    '-prod-sylius',
                                    '',
                                )}.`}
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                {`Afin de finaliser le déploiement, rendez-vous sur ${nextjsStackLink} et cliquez sur "re-Build et redémarrer" (en haut à droite)`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenSuccessModale(false)}>Je verrai plus tard</Button>
                            {/* eslint-disable-next-line */}
                            <a href={nextjsStackLink || ''} target="_blank" rel="noreferrer">
                                <Button
                                    variant="contained"
                                    disableFocusRipple
                                    color="primary"
                                    onClick={() => null}
                                    autoFocus
                                >
                                    Y aller
                                </Button>
                            </a>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Page>
    )
}

export default DemoAdminApp
