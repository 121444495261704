import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { REFIT_CATEGORIES_ROUTE } from 'utils/routes/backend'
import ManageCategoryModal from './modals/ManageCategoryModal'
import DeleteModal from '_organisms/DeleteModal'
import { CategoryActionType, IRefitCategory } from 'interfaces'
import CategoryCard from './CategoryCard'
import { InstructionCategoriesLevels } from './InstructionsManagement'

type HandleCategoriesProps = {
    categories: IRefitCategory[]
    handleRefresh: () => void
    deleteItem: (url: string) => void
    updateSelection: (category: IRefitCategory) => void
    level?: InstructionCategoriesLevels
}

const HandleCategories: React.FC<HandleCategoriesProps> = ({
    categories,
    handleRefresh,
    deleteItem,
    updateSelection,
    level = InstructionCategoriesLevels.CATEGORIES,
}) => {
    const { t } = useTranslation()

    const [openCategoryActionModal, setOpenCategoryActionModal] = useState<CategoryActionType | undefined>()
    const [selectedCategory, setSelectedCategory] = useState<IRefitCategory | undefined>()

    function handleDelete() {
        deleteItem(`${REFIT_CATEGORIES_ROUTE}/${selectedCategory!.id}`)
        setOpenCategoryActionModal(undefined)
    }

    return (
        <>
            {categories?.map((category: any, index: number) => (
                <CategoryCard
                    key={index}
                    updateSelection={() => updateSelection(category)}
                    category={category}
                    setSelectedCategory={setSelectedCategory}
                    setOpenCategoryActionModal={setOpenCategoryActionModal}
                />
            ))}
            {openCategoryActionModal === CategoryActionType.EDIT && (
                <ManageCategoryModal
                    category={selectedCategory}
                    handleClose={() => setOpenCategoryActionModal(undefined)}
                    handleRefresh={handleRefresh}
                    parentId={selectedCategory?.parentCategoryId}
                />
            )}
            {openCategoryActionModal === CategoryActionType.DELETE && (
                <DeleteModal
                    onSubmit={handleDelete}
                    onClose={() => setOpenCategoryActionModal(undefined)}
                    title={t(`refit.instructionCategories.modal.${level}.deleteTitle`)}
                    label={t(`refit.instructionCategories.modal.${level}.deleteLabel`)}
                    inputValue={selectedCategory!.title}
                    tipText={t(`refit.instructionCategories.modal.${level}.deleteTip`)}
                    buttonLabel={t(`refit.instructionCategories.modal.deleteButton`)}
                />
            )}
        </>
    )
}

export default HandleCategories
