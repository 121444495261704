import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, createStyles, Grid, InputAdornment, makeStyles, TextField, Theme, Typography } from '@material-ui/core'

import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import {
    PREPARATIONS_ROUTE_BY_ID,
    PREPARATIONS_TOSHIP_ROUTE,
    REMOVE_ORDER_FROM_BATCH_ROUTE,
} from 'utils/routes/backend'
import { LateStatus, Preparation, PreparationStatus, preparationStatusDisplay } from 'interfaces'
import { CollapsibleTable, TPagination } from 'components/reusable/Table'
import { DayPicker } from 'components/reusable/DropDownDatePickers'
import WarningIcon from '@material-ui/icons/Warning'
import { toast } from 'utils/toast'
import ConfirmModal from 'components/reusable/ConfirmModal'
import rmsApi from 'utils/api'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import Status from '_atoms/badges/Status'
import { PaletteType } from '_atoms/buttons/Button'
import Pill from '_atoms/badges/Pill'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import useSelectedWarehouseStore from 'utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from 'utils/store/selectedWarehouse.store'
import { useNavigate } from 'react-router-dom'
import { ORDER_UNITARY_PREPARATION_ROUTE } from 'utils/routes/frontend'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.space(6),
        },
        daypickerContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiTypography-root': {
                fontWeight: 700,
                color: theme.palette.grey[700],
                [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.pxToRem(11),
                },
            },
        },
        filtersContainer: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& p': {
                whiteSpace: 'nowrap',
                marginRight: theme.spacing(1),
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
            },
        },
        textField: {
            margin: theme.spacing(2, 0, 2),
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(1, 0, 1),
                '& .MuiInputBase-root': {
                    minWidth: 300,
                },
            },
        },
        btnMobile: {},
        priority: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.warning.main,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(10),
            },
        },
        status: {
            margin: 'auto',
        },
    }),
)

function preparationToRow(preparation: Preparation, startPreparation: () => void, t: any, classes: any): any {
    const {
        order: { id: orderId, omsOrderId, type, shipments, batch },
        status,
        isUrgent,
    } = preparation
    const forthShipment = shipments?.find((s) => !s.isReturn)

    const action = (
        <Button
            color="primary"
            variant="outlined"
            onClick={startPreparation}
            size="small"
            data-testid={`startPreparation_${orderId}`}
            className={'btn'}
        >
            {t('wms.preparation.cta')}
        </Button>
    )

    const now = dayjs(dayjs().format('YYYY-MM-DD'))
    const dayjsShippingDate = dayjs(forthShipment?.shippingDate)
    let late: LateStatus
    if (dayjsShippingDate.isBefore(now)) late = LateStatus.LATE
    else if (dayjsShippingDate > now) late = LateStatus.NOT_LATE
    else late = LateStatus.ALMOST_LATE

    const carrierToDisplay = forthShipment?.carrier?.name || t('wms.preparation.customCarrier')

    const priority = isUrgent ? (
        <div className={classes.priority}>
            <WarningIcon /> {t('wms.preparation.highPriority')}
        </div>
    ) : (
        t('wms.preparation.lowPriority')
    )

    return {
        displayed: [
            action,
            <div data-orderid={orderId} key={orderId}>
                {omsOrderId}
            </div>,
            <div key={orderId}>{batch?.idPerWarehouse || ''}</div>,
            <Pill
                key={`orderType_${orderId}`}
                dataTestId={`orderType_${orderId}`}
                label={t(`wms.preparation.orderType.${type}`)}
                className={classes.status}
            />,
            dayjs(forthShipment?.shippingDate).format('DD/MM/YY'),
            priority,
            <Status
                key={orderId}
                color={preparationStatusDisplay(late)[status] as PaletteType}
                label={t(`wms.preparation.preparationStatus.${status}`)}
                dataTestId={`highlightedText_${orderId}`}
                className={classes.status}
            />,
            carrierToDisplay,
            preparation.order.rmsParcelShop?.name,
            preparation.order.customer.name,
        ],
    }
}

const UnitaryPreparationTab: React.FC = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const navigate = useNavigate()

    const [preparationList, setPreparationList] = useState<Preparation[]>([])
    const [pickedShippingDate, setPickedShippingDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
    const [receivedPagination, setReceivedPagination] = useState<TPagination>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<number>(30)
    const [omsOrderIdField, setOmsOrderIdValue] = useState<string>('')
    const [omsOrderId, setOmsOrderIdSearch] = useState<string>('')
    const [selectedCustomers] = useSelectedCustomersStore((state: SelectedCustomersState) => [state.selectedCustomers])
    const [temporaryPreparation, setTemporaryPreparation] = useState<Preparation>()
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    const handleChangePage = (event: any, newPage: number) => {
        setCurrentPage(newPage + 1)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setCurrentPage(1)
    }

    const debouncedOmsIdSave = useCallback(
        debounce((nextValue) => setOmsOrderIdSearch(nextValue), 1000),
        [], // will be created only once initially
    )

    const handleUpdateOmsId = (event: any) => {
        const { value: nextValue } = event.target
        setOmsOrderIdValue(nextValue)
        debouncedOmsIdSave(nextValue)
    }

    async function fetchPreparations() {
        try {
            const preparationsRequest = await rmsApi.get(
                `${PREPARATIONS_TOSHIP_ROUTE}?date=${pickedShippingDate}&page=${currentPage}&limit=${rowsPerPage}&omsOrderId=${omsOrderId}`,
            )
            const { items: preparations, meta } = preparationsRequest.data
            if (meta) setReceivedPagination({ ...meta, handleChangePage, handleChangeRowsPerPage })
            setPreparationList(preparations)
        } catch (e: any) {
            toast.error(t('wms.pickPackTable.preparationsFetchError', { message: e.message }))
        }
    }

    useEffect(() => {
        fetchPreparations()
    }, [selectedCustomers, selectedWarehouse, pickedShippingDate, currentPage, rowsPerPage, omsOrderId])

    useEffect(() => {
        setCurrentPage(1)
    }, [pickedShippingDate, omsOrderId])

    const closeModal = () => {
        setTemporaryPreparation(undefined)
    }

    async function confirmSelectPreparation() {
        const { id: preparationId, order } = temporaryPreparation!
        const { id: orderId, batch } = order
        try {
            if (batch) {
                await rmsApi.delete(REMOVE_ORDER_FROM_BATCH_ROUTE(orderId!))
            }
            closeModal()
            navigate(ORDER_UNITARY_PREPARATION_ROUTE(preparationId))
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    const rows = preparationList.map((preparation) =>
        preparationToRow(
            preparation,
            async () => {
                try {
                    const { status, order, id } = preparation
                    if (status === PreparationStatus.IN_PROGRESS || order.batch) {
                        setTemporaryPreparation(preparation)
                    } else {
                        await rmsApi.patch(PREPARATIONS_ROUTE_BY_ID(id), { status: PreparationStatus.IN_PROGRESS })
                        navigate(ORDER_UNITARY_PREPARATION_ROUTE(id))
                    }
                } catch (e: any) {
                    toast.error(e.message)
                }
            },
            t,
            classes,
        ),
    )

    const headers = [
        { id: 'action', label: t('wms.preparation.action') },
        { id: 'omsId', label: t('wms.preparation.omsId') },
        { id: 'batch', label: t('orders.columnBatchId') },
        { id: 'orderType', label: t('wms.preparation.type') },
        { id: 'shippingDate', label: t('wms.preparation.shippingDate') },
        { id: 'isUrgent', label: t('wms.preparation.isUrgent') },
        { id: 'status', label: t('wms.preparation.status') },
        { id: 'carrier', label: t('wms.preparation.carrier') },
        { id: 'parcelShopName', label: t('wms.preparation.store') },
        { id: 'customer', label: t('wms.preparation.customer') },
    ]

    return (
        <div className={classes.root}>
            <Grid container className={classes.filtersContainer}>
                <Grid item xs={12} md={7}>
                    <div className={classes.daypickerContainer}>
                        <Typography variant="body1">{t('wms.preparation.datepicker')}</Typography>
                        <DayPicker
                            range={20}
                            value={pickedShippingDate}
                            onChange={(date) => setPickedShippingDate(date)}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <TextField
                        data-testid="omsOrderIdSearchInput"
                        value={omsOrderIdField}
                        label={t('orders.omsOrderIdSearchInput.label')}
                        placeholder={t('orders.omsOrderIdSearchInput.placeholder')}
                        fullWidth
                        variant="outlined"
                        onChange={handleUpdateOmsId}
                        className={classes.textField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MagnifyIcon className="MuiSvgIcon-root" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <CollapsibleTable
                header={headers}
                rows={rows}
                paginationProps={receivedPagination}
                tableUI={'preparation-step1'}
            />
            <ConfirmModal
                title={
                    temporaryPreparation?.order.batch
                        ? t('wms.preparation.confirmModal.title.batch')
                        : t('wms.preparation.confirmModal.title.inProgress')
                }
                description={
                    temporaryPreparation?.order.batch
                        ? t('wms.preparation.confirmModal.description.batch')
                        : t('wms.preparation.confirmModal.description.inProgress')
                }
                buttonSuccessText={t('global.yes')}
                buttonDangerText={t('global.no')}
                handleConfirm={confirmSelectPreparation}
                isOpen={!!temporaryPreparation}
                onClose={closeModal}
            />
        </div>
    )
}

export default UnitaryPreparationTab
