import * as React from 'react'
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import theme from '../../theme'
import StorageLocation from './StorageLocation'
import { useTranslation } from 'react-i18next'
import ProductVariantDetail from './ProductVariantDetail'
import ImageOffOutlineIcon from 'mdi-react/ImageOffOutlineIcon'
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon'
import ArrowSplitVerticalIcon from 'mdi-react/ArrowSplitVerticalIcon'
import PaletteIcon from 'mdi-react/PaletteIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            borderRadius: 5,
            margin: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
            boxShadow: '2px 5px 12px 0px #545454',
        },

        cardGrid: {
            alignItems: 'center',
            padding: '0 5px',
        },
        productPictureContainer: {
            position: 'relative',
            flexBasis: 'auto',
            height: theme.spacing(18),
            width: '100%',
            overflow: 'hidden',
            borderRadius: '4%',
        },
        productPicture: {
            objectFit: 'contain',
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            margin: 'auto',
        },
        cardContent: {
            padding: `${theme.spacing(1)}px`,
            '&:last-child': {
                paddingBottom: theme.spacing(1),
            },
        },
        productName: {
            paddingLeft: theme.spacing(0.5),
        },
        variantDetailContainer: {
            marginLeft: theme.spacing(1),
        },
        quantity: {
            justifyContent: 'flex-end',
            marginRight: theme.spacing(1),
        },
        box: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 3,
            backgroundColor: theme.palette.grey[500],
        },
        quantityCounter: {
            margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
            color: theme.palette.common.white,
        },
        counterComplete: {
            backgroundColor: theme.palette.success.main,
        },
        optional: {
            backgroundColor: theme.palette.warning.main,
        },
        iconNoPicture: {
            textAlign: 'center',
            color: theme.palette.grey[400],
            height: '10em',
            width: '10em',
        },
    }),
)

type ProductPreparationCardProps = {
    productReference: any
    dataTestId: string
    onClick?: () => void
}

const ProductPreparationCard: React.FC<ProductPreparationCardProps> = ({ productReference, dataTestId, onClick }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const { name, image, uniqueProductsLocation, firstLocation, color, size, additionalProperties } = productReference
    const location = { name: firstLocation, stock: uniqueProductsLocation[firstLocation] }

    const pickedQuantity = productReference.PUIDs.length
    const requiredQuantity = productReference.quantity
    const isComplete = pickedQuantity === requiredQuantity

    return (
        <Card className={classes.card} onClick={onClick} data-testid={dataTestId}>
            <Grid className={classes.cardGrid} container>
                <Grid className={classes.productPictureContainer} item xs={4}>
                    {image ? (
                        <CardMedia
                            component="img"
                            className={classes.productPicture}
                            image={image}
                            alt="Product image"
                        />
                    ) : (
                        <ImageOffOutlineIcon className={classes.iconNoPicture} />
                    )}
                </Grid>
                <Grid item xs={8}>
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.productName} variant="h5" component="div" gutterBottom>
                            {name}
                        </Typography>
                        <Divider></Divider>
                        <div className={classes.variantDetailContainer}>
                            {size && (
                                <ProductVariantDetail
                                    label={t('wms.productReferences.size')}
                                    value={size}
                                    icon={<ArrowSplitVerticalIcon />}
                                />
                            )}
                            {color && (
                                <ProductVariantDetail
                                    label={t('wms.productReferences.color')}
                                    value={color}
                                    icon={<PaletteIcon />}
                                />
                            )}
                            {uniqueProductsLocation && (
                                <>
                                    <ProductVariantDetail
                                        label={t('wms.productReferences.columnStorageQty')}
                                        icon={<GoogleMapsIcon />}
                                    />
                                    <div
                                        style={{
                                            width: 'fit-content',
                                            marginTop: theme.spacing(1),
                                            marginLeft: theme.spacing(2),
                                        }}
                                    >
                                        <StorageLocation location={location.name} quantity={location.stock} />
                                    </div>
                                </>
                            )}
                            {additionalProperties?.ski && (
                                <>
                                    <ProductVariantDetail
                                        label={t('wms.productReferences.level')}
                                        value={additionalProperties.ski.level}
                                    />
                                    <ProductVariantDetail
                                        label={t('wms.productReferences.size')}
                                        value={`${additionalProperties.ski.size} cm`}
                                    />
                                    <ProductVariantDetail
                                        label={t('wms.productReferences.weight')}
                                        value={`${additionalProperties.ski.weight} kg`}
                                    />
                                    <ProductVariantDetail
                                        label={t('wms.productReferences.firstname')}
                                        value={additionalProperties.ski.firstname}
                                    />
                                    <ProductVariantDetail
                                        label={t('wms.productReferences.age')}
                                        value={additionalProperties.ski.age}
                                    />
                                </>
                            )}
                        </div>
                        <Grid container className={classes.quantity}>
                            <Box
                                className={`${classes.box} ${isComplete ? classes.counterComplete : ''} ${
                                    productReference.optional ? classes.optional : ''
                                }`}
                            >
                                <Typography className={classes.quantityCounter} variant="h5" component="div">
                                    {`${pickedQuantity} / ${requiredQuantity} ${
                                        productReference.optional ? `(${t('wms.productReferences.optional')})` : ''
                                    }`}
                                </Typography>
                            </Box>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ProductPreparationCard
