import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrismicQuality, PrismicQualityCondition } from 'utils/prismic'
import ModalWithHeader, { HeaderType } from '../../_molecules/ModalWithHeader/ModalWithHeader'
import { VerticalRadioButtons } from '_atoms/inputs/VerticalRadioButtons'
import ModalFooter from '_molecules/ModalFooter'

type SetQualityModalProps = {
    isOpen: boolean
    handleClose: () => void
    handleSetQuality: (quality: string) => void
    applyButton?: boolean
    puidQuality?: string
    prismicQualities: PrismicQuality[]
}

const SetQualityModal: React.FC<SetQualityModalProps> = ({
    isOpen,
    handleClose,
    handleSetQuality,
    puidQuality,
    prismicQualities,
    applyButton = false,
}) => {
    const { t } = useTranslation()

    const [quality, setQuality] = useState(puidQuality)

    const allQualities = prismicQualities?.filter(
        (prismicQuality) => prismicQuality.value !== PrismicQualityCondition.REFUSED_CONDITION,
    )

    return (
        <ModalWithHeader
            headerType={HeaderType.MOBILE}
            title={t('wms.scanUniqueProduct.setQualityModal.title')}
            tagline={t('wms.scanUniqueProduct.setQualityModal.tagline')}
            open={isOpen}
            handleClose={handleClose}
            width={600}
        >
            <VerticalRadioButtons
                options={allQualities}
                value={quality}
                onChange={(e) => {
                    if (applyButton) {
                        setQuality(e.value)
                    } else {
                        handleSetQuality(e.value)
                        handleClose()
                    }
                }}
                disabled={false}
            />
            {applyButton ? (
                <ModalFooter
                    label={t('global.validate')}
                    onSubmit={() => {
                        handleSetQuality(quality!)
                        handleClose()
                    }}
                    onCancel={handleClose}
                    submitDisabled={!quality || quality === puidQuality}
                />
            ) : (
                <></>
            )}
        </ModalWithHeader>
    )
}

export default SetQualityModal
