import { PropsWithChildren } from 'react'
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
}))

const AccordionStepper: React.FC<PropsWithChildren<any>> = ({ children }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            {React.Children.map(children, (child: React.ReactElement, index: number) =>
                React.cloneElement(child, { stepId: index, totalSteps: children.length }),
            )}
        </div>
    )
}

export default AccordionStepper
