import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import Dash from '_atoms/Dash'
import DotsActions, { TooltipAction } from '_molecules/DotsActions'
import { faCopy, faPen, faTrash } from '@fortawesome/pro-light-svg-icons'
import { IInstructionGroup, InstructionGroupActionType } from 'interfaces/RefitInstructionGroup.interface'
import Text from '_atoms/text/Text'
import { useTranslation } from 'react-i18next'
import Card from '_atoms/containers/Card'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        categoryLine: {
            justifyContent: 'space-between',
            marginBottom: theme.space(2),
        },
        categoryGrid: {
            display: 'flex',
            alignItems: 'center',
        },
        attachedProductsInfo: {
            color: theme.palette.neutral[500],
        },
        attachedProductsHover: {
            [theme.breakpoints.up('sm')]: {
                transform: 'translate(-78px, 0px)',
                transition: 'all 0.3s ease-in-out',
            },
        },
        groupTitle: {
            color: theme.palette.neutral[700],
        },
        groupInfos: {
            display: 'flex',
            alignItems: 'center',
        },
        addon: {
            display: 'flex',
        },
    }),
)

type InstructionGroupCardProps = {
    group: IInstructionGroup
    setSelectedGroup: (i: IInstructionGroup) => void
    selectInstructionGroup: (i: IInstructionGroup) => void
    setOpenGroupActionModal: (action: InstructionGroupActionType) => void
    className?: string
    [props: string]: any
}

const InstructionGroupCard: React.FC<InstructionGroupCardProps> = ({
    group,
    setSelectedGroup,
    selectInstructionGroup,
    setOpenGroupActionModal,
    className,
    ...props
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [actionsVisible, setActionsVisible] = useState<boolean>(false)

    function clickTooltipAction(event: Event, action: InstructionGroupActionType) {
        event.stopPropagation() // Prevent triggering parent element click
        setSelectedGroup(group)
        setOpenGroupActionModal(action)
    }

    const renderDotAction = () => (
        <DotsActions visible={actionsVisible}>
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionGroups.actionButtons.clone')}
                icon={faCopy}
                onClick={(e) => clickTooltipAction(e, InstructionGroupActionType.CLONE)}
                data-testid={`${props ? props['data-testid'] : ''}_clone`}
            />
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionGroups.actionButtons.edit')}
                icon={faPen}
                onClick={(e) => clickTooltipAction(e, InstructionGroupActionType.EDIT)}
                data-testid={`${props ? props['data-testid'] : ''}_edit`}
            />
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionGroups.actionButtons.delete')}
                color={theme.palette.error[600]}
                icon={faTrash}
                onClick={(e) => clickTooltipAction(e, InstructionGroupActionType.DELETE)}
                data-testid={`${props ? props['data-testid'] : ''}_delete`}
            />
        </DotsActions>
    )

    const renderProductsLabel = () => (
        <Text
            variant="P5"
            className={`${classes.attachedProductsInfo} ${actionsVisible ? classes.attachedProductsHover : ''}`}
        >
            {t('refit.instructionGroups.manageGroups.groupsTab.productsCount', {
                count: group.nbProducts,
            })}
        </Text>
    )

    return (
        <Card
            {...props}
            onClick={() => selectInstructionGroup(group)}
            onMouseEnter={() => setActionsVisible(true)}
            onMouseLeave={() => setActionsVisible(false)}
            rightAddon={
                <div className={classes.addon}>
                    {!isMobile && renderProductsLabel()}
                    {renderDotAction()}
                </div>
            }
        >
            <>
                <Text variant="S1" className={classes.groupTitle}>
                    {group.name}
                </Text>
                <div className={classes.groupInfos}>
                    {!isMobile && <Dash color={theme.palette.secondary[600]} />}
                    <Text variant="P4">
                        {t('refit.instructionGroups.manageGroups.groupsTab.instructionsCount', {
                            count: group.nbInstructions,
                        })}
                    </Text>
                    {isMobile && (
                        <>
                            <Dash color={theme.palette.secondary[600]} />
                            {renderProductsLabel()}
                        </>
                    )}
                </div>
            </>
        </Card>
    )
}

export default InstructionGroupCard
