import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import Button from '_atoms/buttons/Button'

const useStyles = makeStyles<Theme, { width: number }>((theme: Theme) =>
    createStyles({
        header: {
            position: 'fixed',
            marginTop: theme.space(2),
            [theme.breakpoints.down('sm')]: {
                right: theme.space(2),
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: ({ width }) => width - (theme.space(10) as number),
            },
        },
    }),
)

type CloseHeaderProps = {
    handleClose: () => void
    width: number
}

const CloseHeader = React.forwardRef<HTMLDivElement, CloseHeaderProps>(({ handleClose, width }, ref) => {
    const classes = useStyles({ width })

    return (
        <div className={classes.header} ref={ref}>
            <Button
                onClick={handleClose}
                startIcon={faXmark}
                shape="squared"
                size="medium"
                color="neutral"
                variant="ghost"
                data-testid="close-modal"
            />
        </div>
    )
})

export default CloseHeader
