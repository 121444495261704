import * as React from 'react'
import { useState, useEffect } from 'react'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import EnrichedIcon from '_molecules/EnrichedIcon'
import Page from '_organisms/Page'
import { IParcel } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { PARCEL_BY_BARCODE_UID_URL } from 'utils/routes/backend'
import SuggestedParcelList from './SuggestedParcelList'

type SearchParcelByPuidProps = {
    puid: string
    onPickParcel: (parcelBarcode: string) => void
    goToHomePage: () => void
}

const SearchParcelByPuid: React.FC<SearchParcelByPuidProps> = ({ puid, onPickParcel, goToHomePage }) => {
    const { t } = useTranslation()

    const [parcels, setParcels] = useState<IParcel[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    async function fetchParcelsByPuid() {
        try {
            const parcelRequest = await rmsApi.get(PARCEL_BY_BARCODE_UID_URL(puid))
            setParcels(parcelRequest.data)
            setIsLoading(false)
        } catch (e: any) {
            toast.error(
                e.response.status === 404 ? t('returnParcel.searchByProduct.noParcelError', { puid }) : e.message,
            )
            goToHomePage()
        }
    }

    useEffect(() => {
        fetchParcelsByPuid()
    }, [])

    return (
        <Page title={t('returnParcel.searchByProduct.title', { puid })} handlePrevious={goToHomePage}>
            {isLoading && (
                <EnrichedIcon
                    icon={faLoader}
                    title={t('returnParcel.searchByProduct.loadingPage.title')}
                    tagline={t('returnParcel.searchByProduct.loadingPage.tagline')}
                    iconProps={{ spin: true }}
                />
            )}
            {parcels && <SuggestedParcelList parcels={parcels} onPickParcel={onPickParcel} />}
        </Page>
    )
}

export default SearchParcelByPuid
