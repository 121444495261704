import * as React from 'react'
import { useState } from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemLabel } from '_atoms/text/Labels'
import { faCopy, faEye, faPen, faTrash } from '@fortawesome/pro-light-svg-icons'
import DotsActions, { TooltipAction } from '_molecules/DotsActions'
import { InstructionInfo } from './InstructionInfo'
import Card from '_atoms/containers/Card'
import { InstructionActionType, IRefitInstruction } from 'interfaces'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.space(3)}px`,
        },
    }),
)

type InstructionCardProps = {
    instruction: IRefitInstruction
    setInstruction: (instruction: IRefitInstruction) => void
    setOpenInstructionActionModal: (action: InstructionActionType) => void
}

const InstructionCard: React.FC<InstructionCardProps> = ({
    instruction,
    setInstruction,
    setOpenInstructionActionModal,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    const [actionsVisible, setActionsVisible] = useState<boolean>(false)

    function clickTooltipAction(event: Event, action: InstructionActionType) {
        event.stopPropagation() // Prevent triggering parent element click
        setInstruction(instruction)
        setOpenInstructionActionModal(action)
    }

    const renderDotAction = () => (
        <DotsActions visible={actionsVisible}>
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionCategories.actionButton.view')}
                icon={faEye}
                onClick={(e) => clickTooltipAction(e, InstructionActionType.VIEW)}
            />
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionCategories.actionButton.clone')}
                icon={faCopy}
                onClick={(e) => clickTooltipAction(e, InstructionActionType.CLONE)}
            />
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionCategories.actionButton.edit')}
                icon={faPen}
                onClick={(e) => clickTooltipAction(e, InstructionActionType.EDIT)}
            />
            <TooltipAction
                tooltipPlacement="top"
                tooltipText={t('refit.instructionCategories.actionButton.delete')}
                color={theme.palette.error[600]}
                icon={faTrash}
                onClick={(e) => clickTooltipAction(e, InstructionActionType.DELETE)}
            />
        </DotsActions>
    )

    return (
        <>
            <Card
                rightAddon={renderDotAction()}
                onMouseEnter={() => setActionsVisible(true)}
                onMouseLeave={() => setActionsVisible(false)}
                onClick={() => {
                    setInstruction(instruction)
                    setOpenInstructionActionModal(InstructionActionType.VIEW)
                }}
            >
                <div className={classes.labelContainer}>
                    <ItemLabel>{instruction.title}</ItemLabel>
                    <InstructionInfo instruction={instruction} />
                </div>
            </Card>
        </>
    )
}

export default InstructionCard
