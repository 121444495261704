import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'

import Tips from '_atoms/containers/Tips'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noGroupText: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: `${theme.space(2)}px`,
        },
        tip: {
            width: '100%',
        },
    }),
)

type NoResultTipProps = {
    text: string
    children?: React.ReactNode
}

const NoResultTip: React.FC<NoResultTipProps> = ({ text, children }) => {
    const classes = useStyles()
    return (
        <div className={classes.noGroupText}>
            <Tips className={classes.tip}>{text}</Tips>
            {children}
        </div>
    )
}

export default NoResultTip
