import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'

/*
 * To print with this, you will have to provide your printable component as parameter of the returned 'printableContent' element
 * This will provide a togglePrintWindow function which MUST BE an optional function prop of your printable component.
 * You will then determine when to use it by calling togglePrintWindow().
 *
 * the afterPrint function will be triggered automatically on cancel or on print. We suggest that you use it to reset state of
 * your component before it's printed.
 *
 * You can find a specific use case in returnedParcel (src/containers/ReturnedParcel.tsx)
 *
 * */

export const useExportPdf = (afterPrint?: () => void): { printableContent: (children: any) => any } => {
    const componentRef = useRef()

    const buttonTrig = (
        <button style={{ opacity: 0 }} id="print-button">
            INVISIBLE PRINT BUTTON
        </button>
    )

    const afterPrintExecute = async () => {
        await setTimeout(() => {
            if (afterPrint) afterPrint()
        }, 2000)
    }

    const reactToPrintButton = (
        <ReactToPrint
            trigger={() => buttonTrig}
            content={() => {
                return componentRef.current as any
            }}
            copyStyles={true}
            onBeforePrint={afterPrintExecute}
            // onAfterPrint={afterPrint}
            // onPrintError={afterPrint}
            documentTitle={''}
        />
    )

    const container = (children: any) => {
        // Automatically toggle print on page display
        const togglePrintWindow = () => document?.getElementById('print-button')?.click()

        return (
            <div>
                {reactToPrintButton}
                <div ref={componentRef as any}>{React.cloneElement(children, { togglePrintWindow })}</div>
            </div>
        )
    }

    return { printableContent: container }
}
