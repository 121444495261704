import * as React from 'react'
import { createStyles, Divider as Hr, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        visible: {
            margin: theme.spacing(2.5, 0),
            backgroundColor: ({ color, dashed }) => (dashed ? '' : `${color || theme.palette.neutral[200]}`),
            width: '100%',
        },
        invisible: {
            backgroundColor: 'transparent',
            backgroundImage: 'unset',
        },
        none: { margin: theme.spacing(0, 0) },
        small: { margin: theme.spacing(1, 0) },
        medium: { margin: theme.spacing(3, 0) },
        big: { margin: theme.spacing(4, 0) },
        dashed: {
            backgroundImage: ({ color }) =>
                `linear-gradient(90deg, ${color || theme.palette.neutral[200]}, ${
                    color || theme.palette.neutral[200]
                } 60%, transparent 60%, transparent 100%)`,
            backgroundSize: '5px 1px',
            backgroundColor: 'unset',
        },
    }),
)

type StyleProps = {
    color?: string
    dashed?: boolean
}

type HrProps = {
    invisible?: boolean
    color?: string
    spaceSize: 'none' | 'small' | 'medium' | 'big'
    dashed?: boolean
    className?: string
}

const Divider: React.FC<HrProps> = ({
    invisible = false,
    spaceSize = 'small',
    color,
    dashed = false,
    className = '',
}) => {
    const classes = useStyles({ color, dashed })
    return (
        <Hr
            className={`${invisible ? classes.invisible : classes.visible} ${classes[spaceSize] || ''} ${
                dashed ? classes.dashed : ''
            } ${className}`}
        ></Hr>
    )
}

export default Divider
