import * as React from 'react'
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            paddingLeft: theme.spacing(3),
            '& .MuiTypography-root': {
                '& .MuiSvgIcon-root': {
                    paddingLeft: theme.spacing(1),
                    height: '1.25em',
                    width: '1.25em',
                    verticalAlign: '-20%',
                },
            },
            '& .MuiTypography-gutterBottom': {
                marginTop: '1em',
                marginBottom: '1em',
            },
        },
    }),
)

type SectionProps = {
    title: string
    children: any
}

const Section: React.FC<SectionProps> = ({ title, children }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid item xs={12}>
            <section>
                {title && (
                    <div className={classes.sectionHeader}>
                        <Typography variant="h4" component="h3" gutterBottom>
                            {t(title)}
                        </Typography>
                    </div>
                )}
                <Grid container spacing={5} justify="center">
                    {children}
                </Grid>
            </section>
        </Grid>
    )
}

export default Section
