import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { SectionTitle } from '_atoms/text/SectionTitle'
import dayjs from 'dayjs'
import { BasicText } from '_atoms/text/BasicText'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overdue: {
            color: theme.palette.common.black,
        },
        overdueWarning: {
            color: theme.palette.warning.main,
        },
        overdueError: {
            color: theme.palette.error.main,
        },
        sectionTitle: {
            marginBottom: theme.spacing(3),
        },
        labelText: {
            color: theme.palette.grey[600],
        },
    }),
)

type RentalDateOverviewProps = {
    expectedShippingDate: string | undefined
    shippedAt: string | undefined
    arrivedAt: string | undefined
    processedAt: string | undefined
    rightOfWithdrawal: boolean
}

const RentalDateOverview: React.FC<RentalDateOverviewProps> = ({
    expectedShippingDate,
    shippedAt,
    arrivedAt,
    processedAt,
    rightOfWithdrawal,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dueDate = dayjs(expectedShippingDate)
    const daysOverdue = dayjs(shippedAt || undefined).diff(dueDate, 'day')

    return (
        <>
            <SectionTitle className={classes.sectionTitle} size="large">
                {t('returns.rentalDates.rentalDates')}
            </SectionTitle>
            <Grid container>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.rentalDates.dueDate')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {dueDate.format('DD/MM/YYYY')}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.rentalDates.sendAt')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {shippedAt ? dayjs(shippedAt).format('DD/MM/YYYY') : t('returns.rentalDates.notSentYet')}
                </Grid>
                {daysOverdue > 0 && !rightOfWithdrawal && (
                    <>
                        <Grid xs={6} item>
                            <BasicText className={classes.labelText}>{t('returns.rentalDates.overdue')}</BasicText>
                        </Grid>
                        <Grid xs={6} item>
                            <BasicText
                                className={`${classes.overdue} ${daysOverdue > 0 ? classes.overdueWarning : ''} ${
                                    daysOverdue > 3 ? classes.overdueError : ''
                                }`}
                            >
                                {`${daysOverdue} ${t('returns.rentalDates.days')}`}
                            </BasicText>
                        </Grid>
                    </>
                )}
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.rentalDates.arrivedAt')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {arrivedAt ? dayjs(arrivedAt).format('DD/MM/YYYY') : t('returns.rentalDates.notArrivedYet')}
                </Grid>
                <Grid xs={6} item>
                    <BasicText className={classes.labelText}>{t('returns.rentalDates.processedAt')}</BasicText>
                </Grid>
                <Grid xs={6} item>
                    {processedAt ? dayjs(processedAt).format('DD/MM/YYYY') : t('returns.rentalDates.notProcessedYet')}
                </Grid>
            </Grid>
        </>
    )
}

export default RentalDateOverview
