import * as React from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { Pagination as MuiPagination } from '@material-ui/lab'

import { useTranslation } from 'react-i18next'
import DropDownPicker from '_molecules/DropDownPicker'
import { DEFAULT_CURRENT_PAGE, DEFAULT_ITEMS_PER_PAGE } from 'interfaces/Table.interfaces'
import Text from '_atoms/text/Text'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paginationRow: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 1,
            color: theme.palette.primary[700],
            borderTop: `1px solid ${theme.palette.neutral[300]}`,
            backgroundColor: theme.palette.common.white,
            height: theme.space(10),
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.space(0, 5),
            [theme.breakpoints.down('md')]: {
                padding: theme.space(0, 2),
            },
        },
        itemsPerPage: {
            display: 'flex',
            gap: `${theme.space(2)}px`,
            alignItems: 'center',
        },
    }),
)

export type TPagination = {
    totalItems: number
    itemCount: number
    itemsPerPage: number
    totalPages: number
    currentPage: number
}

export enum EPagination {
    ITEMSPERPAGE = 'itemsPerPage',
    CURRENTPAGE = 'currentPage',
}

/**
 * Since pagination behaviour is generic, you will juste have to pass:
 * onChangeRowPerPage={(value: number) => setFilterParams(updatePagination(filterParams, value, EPagination.ITEMSPERPAGE))}
 *   or
 * onChangeRowPerPage={(value: number) => setFilterParams(updatePagination(filterParams, value, EPagination.CURRENTPAGE))}
 * with setFilterParams as your custom param state handler containing itemsPerPage and currentPage.
 * It will return a copy of your current state filterParams with only pagination modified.
 */

type PaginationProps = {
    label?: string
    totalItems?: number
    itemCount?: number
    itemsPerPage: number
    totalPages?: number
    currentPage: number
    onChangeItemsPerPage: (value: number) => void
    onChangePage: (value: number) => void
    scrollToTopAnchor?: any
}

const Pagination: React.FC<PaginationProps> = ({
    label,
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
    totalItems,
    itemCount,
    totalPages,
    currentPage = DEFAULT_CURRENT_PAGE,
    onChangeItemsPerPage,
    onChangePage,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const defaultRowsPerPage = ['3', '10', '30', '50', '100']

    function handlePaginationChange(value: number) {
        onChangePage(value)
        // scroll on top of the table
        document.getElementById('tableBody')?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    }

    return (
        <div className={classes.paginationRow}>
            <div className={classes.itemsPerPage}>
                {!isMobile && <div>{`${label || t('table.pagination.rows')} ${t('table.pagination.perPage')}`}</div>}
                <DropDownPicker
                    options={defaultRowsPerPage}
                    value={itemsPerPage.toString()}
                    submitSelection={(value: string) => onChangeItemsPerPage(parseInt(value))}
                    withFilter={false}
                    color="neutral"
                    variant="ghost"
                />
            </div>
            {!isMobile && (
                <Text variant="N9">
                    {itemCount || '?'}/{totalItems || '?'}
                </Text>
            )}
            {!!totalPages && (
                <MuiPagination
                    count={totalPages}
                    size={isMobile ? 'small' : 'medium'}
                    page={currentPage}
                    onChange={(_: any, value: number) => {
                        handlePaginationChange(value)
                    }}
                    color={'primary'}
                />
            )}
        </div>
    )
}

export default Pagination
