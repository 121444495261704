import { useEffect } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Backdrop, createStyles, IconButton, makeStyles, Modal, Paper, Theme, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalClose: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(4),
            border: `1px solid ${theme.palette.common.white}`,
            [theme.breakpoints.down('lg')]: {
                right: 0,
            },
        },
        bodyContent: {
            maxHeight: '80vh',
            overflowX: 'hidden',
            overflowY: 'auto',
            maxWidth: 900,
            padding: theme.spacing(2),
            '& ol': {
                padding: 0,
                listStyle: 'none',
                textAlign: 'left',
                fontWeight: 700,
                fontSize: theme.typography.pxToRem(14),
                '&>li': {
                    display: 'flex',
                    alignItems: 'center',
                    margin: theme.spacing(0, 0, 2),
                },
                [theme.breakpoints.up('lg')]: {
                    maxWidth: 420,
                    margin: theme.spacing(2, 'auto'),
                },
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(2),
                margin: 0,
                width: 900,
            },
        },
        container: {
            padding: theme.spacing(2, 3),
        },
        headerContent: {
            position: 'relative',
            textAlign: 'center',
            padding: theme.spacing(2, 3),
        },
        headerDescription: {
            color: theme.palette.grey[700],
        },
    }),
)

type StatusModalProps = {
    title: string
    isOpen: boolean
    description?: string
    onClose: () => void
}

// Modal boilerplate
const BasicModal: React.FC<StatusModalProps> = ({ title, isOpen, onClose, description, children }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const rootAppDom = document.getElementById('root')

    useEffect(() => {
        if (isOpen) {
            setOpen(true)
            rootAppDom?.classList.add('js-modal-openned')
            rootAppDom?.classList.remove('js-modal-closed')
        } else {
            setOpen(false)
            rootAppDom?.classList.add('js-modal-closed')
            rootAppDom?.classList.remove('js-modal-openned')
            rootAppDom?.classList.contains('js-modal-closed') &&
                setTimeout(() => rootAppDom?.classList.remove('js-modal-closed'), 250)
        }
    }, [rootAppDom, isOpen])

    const handleCloseModal = () => {
        setOpen(false)
        rootAppDom?.classList.remove('js-modal-openned')
        rootAppDom?.classList.add('js-modal-closed')
        rootAppDom?.classList.contains('js-modal-closed') &&
            setTimeout(() => rootAppDom?.classList.remove('js-modal-closed'), 250)
        onClose()
    }

    return (
        <Modal
            aria-labelledby="modalTitle"
            aria-describedby="modalDesc"
            className={classes.modal}
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper className={classes.container}>
                <div className={classes.headerContent}>
                    <Typography variant="h2" component="h3">
                        {title}
                    </Typography>
                    {description && (
                        <Typography variant="h5" component="h6" className={classes.headerDescription}>
                            {description}
                        </Typography>
                    )}
                    <IconButton size="small" className={classes.modalClose} onClick={handleCloseModal}>
                        <CloseIcon />
                        <Typography variant="srOnly">{t('parcel.help.close')}</Typography>
                    </IconButton>
                </div>
                <div className={classes.bodyContent}>{children}</div>
            </Paper>
        </Modal>
    )
}

export default BasicModal
