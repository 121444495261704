import axios, { AxiosInstance } from 'axios'
import accessTokenStore from './store/accessToken.store'
import selectedCustomersStore from 'utils/store/selectedCustomers.store'
import selectedWarehouseStore from 'utils/store/selectedWarehouse.store'

const rmsApi: AxiosInstance = axios.create({
    baseURL: import.meta.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

rmsApi.interceptors.request.use((config) => {
    const accessToken = accessTokenStore.getState().accessToken
    const customers = selectedCustomersStore.getState().selectedCustomers?.map((customer) => customer.id)
    const warehouse = selectedWarehouseStore.getState().selectedWarehouse?.id

    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined
    config.headers['x-rms-customer-ids'] = customers
    config.headers['x-rms-warehouse-id'] = warehouse
    return config
})

export default rmsApi
