import * as React from 'react'
import VerticalStep from '_organisms/VerticalStepper/VerticalStep'
import RadioButtons from '_atoms/inputs/RadioButtons'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { HandleSteps } from '_organisms/VerticalStepper/VerticalStepper'
import { StepBulletStatus } from '_atoms/badges/StepBullet'

type CheckLabelStepProps = {
    handleSteps?: HandleSteps
    isLabelCorrect: boolean | undefined
    handleChange: (value: boolean) => void
}

const CheckLabelStep: React.FC<CheckLabelStepProps> = ({ handleSteps, isLabelCorrect, handleChange }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

    function checkLabelStatus() {
        if (isLabelCorrect) return StepBulletStatus.SUCCESS
        if (isLabelCorrect === false) return StepBulletStatus.ERROR
        return StepBulletStatus.DEFAULT
    }

    return (
        <VerticalStep
            handleSteps={handleSteps}
            title={t('secondHand.checkQuality.labelTitle')}
            tagline={t('secondHand.checkQuality.isLabelPresent')}
            status={checkLabelStatus()}
        >
            <RadioButtons
                options={[
                    {
                        value: true,
                        label: t('global.yes'),
                    },
                    {
                        value: false,
                        label: t('global.no'),
                    },
                ]}
                value={isLabelCorrect}
                onChange={handleChange}
                disabled={false}
                dataTestId="check-label-radio-buttons"
                enabledBorder={isMobile}
                size={isMobile ? 'small' : 'medium'}
            />
        </VerticalStep>
    )
}

export default CheckLabelStep
