import * as React from 'react'
import { useEffect, useState } from 'react'
import { StepHandlerType } from '_organisms/Stepper'
import { IProductReference, IUniqueProduct, UniqueProductStatus } from 'interfaces'
import { useTranslation } from 'react-i18next'
import Divider from '_atoms/Divider'
import { RejectSecondHandReason } from 'interfaces/SecondHand.interface'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import Page from '_organisms/Page'
import BackgroundWithScanAndFooter from '_molecules/BackgroundWithScanAndFooter'
import VerticalStepper from '_organisms/VerticalStepper/VerticalStepper'
import rmsApi from '../../../utils/api'
import { PRODUCT_REFERENCE_BY_PRODUCT_ID_URL, UNIQUE_PRODUCT_UPDATE_URL } from '../../../utils/routes/backend'
import { toast } from 'utils/toast'
import CheckLabelStep from './CheckConditionStep/CheckLabelStep'
import CheckQualityStep from './CheckConditionStep/CheckQualityStep'
import CheckSizeStep from './CheckConditionStep/CheckSizeStep'
import Button from '_atoms/buttons/Button'
import BlueBackgroundHeader from '_molecules/backgrounds/BlueBackgroundHeader'
import { StepBulletStatus } from '_atoms/badges/StepBullet'
import { isSecondHandUntreatedProduct } from '../../../components/reusable/utils/UniqueProductsUtils'

type UniqueProductToUpdate = { productReferenceId?: number; quality?: string }

type CheckConditionStepProps = {
    uniqueProduct: IUniqueProduct
    handleRefuse: (reason: RejectSecondHandReason) => void
    handleSteps?: StepHandlerType
}

const CheckConditionStep: React.FC<CheckConditionStepProps> = ({ uniqueProduct, handleSteps, handleRefuse }) => {
    const { t } = useTranslation()

    const [productReferences, setProductReferences] = useState<IProductReference[]>([])
    const [isLabelCorrect, setIsLabelCorrect] = useState<boolean | undefined>(undefined)
    const [quality, setQuality] = useState<string>('')
    const [isSizeCorrect, setIsSizeCorrect] = useState<boolean | undefined>(undefined)
    const [productReferenceId, setProductReferenceId] = useState<number | ''>('')
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [submitButtonStatus, setSubmitButtonStatus] = useState<StepBulletStatus>(StepBulletStatus.DEFAULT)

    useEffect(() => {
        const accepted = uniqueProduct.status === UniqueProductStatus.TO_REFIT
        const withPuid = !isSecondHandUntreatedProduct(uniqueProduct)
        if (accepted && withPuid) {
            setIsLabelCorrect(true)
            setQuality(uniqueProduct.quality)
            setIsSizeCorrect(true)
        }
        const refused = uniqueProduct.status === UniqueProductStatus.REFUSED
        const withReturnItems = uniqueProduct.returnItems && uniqueProduct.returnItems?.length > 0
        if (refused && withReturnItems) {
            const comment = uniqueProduct.returnItems![0].comment
            const noMissingLabel = comment !== RejectSecondHandReason.MISSING_LABEL
            const noWrongReference = comment !== RejectSecondHandReason.WRONG_REFERENCE
            const refusedQuality = comment === RejectSecondHandReason.CONDITION_NOT_AS_STATED
            if (noWrongReference) {
                setIsLabelCorrect(noMissingLabel)
            }
            setQuality(refusedQuality ? 'refusedCondition' : '')
        }
    }, [])

    useEffect(() => {
        const fetchProductReferences = async () => {
            try {
                const res = await rmsApi.get(
                    PRODUCT_REFERENCE_BY_PRODUCT_ID_URL(uniqueProduct.productReference?.product?.id),
                )
                setProductReferences(res.data)
            } catch (e: any) {
                toast.error(t('secondHand.checkQuality.errorProductReferences'))
            }
        }

        fetchProductReferences()
    }, [uniqueProduct])

    async function updateUniqueProduct() {
        const data: UniqueProductToUpdate = {}

        if (productReferenceId !== '') data.productReferenceId = productReferenceId
        if (
            quality !== uniqueProduct.quality ||
            // If the product has been refused before (the price is 0), we force the change of price by re-sending the quality
            (uniqueProduct.status === UniqueProductStatus.REFUSED && quality === uniqueProduct.quality)
        )
            data.quality = quality

        if (Object.keys(data).length > 0) {
            try {
                await rmsApi.patch(UNIQUE_PRODUCT_UPDATE_URL(uniqueProduct.id), data)
            } catch (e) {
                toast.error(t('secondHand.update.error'))
            }
        }
    }

    async function handleValidate() {
        await updateUniqueProduct()
        uniqueProduct.status !== UniqueProductStatus.NOT_SET && !isSecondHandUntreatedProduct(uniqueProduct)
            ? await handleSteps?.onSubmit()
            : await handleSteps?.nextStep()
    }

    function handleLabelCheck(target: any) {
        const value = target.value
        if (value === isLabelCorrect) return
        setIsLabelCorrect(value)
    }

    function handleConfirmRefuse() {
        handleRefuse(
            isLabelCorrect === false
                ? RejectSecondHandReason.MISSING_LABEL
                : RejectSecondHandReason.CONDITION_NOT_AS_STATED,
        )
    }

    const renderNextButton = () => {
        if (submitButtonStatus === StepBulletStatus.SUCCESS) {
            return (
                <Button
                    label={t('secondHand.checkQuality.finish')}
                    size="large"
                    onClick={handleValidate}
                    endIcon={faArrowRight}
                    data-testid="checkstate-next-button"
                />
            )
        } else if (submitButtonStatus === StepBulletStatus.ERROR && isSizeCorrect !== false) {
            return (
                <Button
                    label={t('secondHand.checkQuality.reject')}
                    color={'error'}
                    size="large"
                    onClick={handleConfirmRefuse}
                    endIcon={faArrowRight}
                    data-testid="refuse-button"
                />
            )
        }
    }

    const stepperChildren = [
        <CheckLabelStep key="CheckLabelStep" isLabelCorrect={isLabelCorrect} handleChange={handleLabelCheck} />,
        <CheckQualityStep
            key="CheckQualityStep"
            quality={quality}
            setQuality={setQuality}
            currentQuality={uniqueProduct.quality}
            reset={() => setQuality('')}
        />,
        productReferences.length > 1 && (
            <CheckSizeStep
                key="CheckSizeStep"
                uniqueProduct={uniqueProduct}
                productReferences={productReferences}
                size={productReferenceId}
                setSize={setProductReferenceId}
                isSizeCorrect={isSizeCorrect}
                setIsSizeCorrect={setIsSizeCorrect}
                reset={() => {
                    setProductReferenceId('')
                    setIsSizeCorrect(undefined)
                }}
            />
        ),
    ].filter(Boolean) as JSX.Element[]

    return (
        <Page
            title={t('secondHand.checkQuality.title')}
            rightAddon={{ addon: handleSteps?.getCounter(), displayOnMobile: true }}
            handlePrevious={() => handleSteps?.previousStep()}
        >
            <BackgroundWithScanAndFooter footer={renderNextButton()}>
                <BlueBackgroundHeader
                    title={t('secondHand.checkQuality.title')}
                    tagline={t('secondHand.checkQuality.sectionTagline')}
                />
                <Divider invisible={true} spaceSize="medium" />
                <VerticalStepper
                    currentStep={currentStep}
                    setStep={setCurrentStep}
                    setSubmitButtonStatus={setSubmitButtonStatus}
                >
                    {stepperChildren}
                </VerticalStepper>
            </BackgroundWithScanAndFooter>
        </Page>
    )
}

export default CheckConditionStep
