import create from 'zustand'
import { ICustomer } from 'interfaces/Customer'

export interface SelectedCustomerState {
    selectedCustomer: ICustomer | undefined
    setSelectedCustomer: (customer: ICustomer | undefined) => void
}

const useSelectedCustomerStore = create<SelectedCustomerState>()((set) => ({
    selectedCustomer: undefined,
    setSelectedCustomer: (selectedCustomer: ICustomer | undefined) =>
        set(() => ({ selectedCustomer: selectedCustomer })),
}))

export default useSelectedCustomerStore
