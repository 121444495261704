import * as React from 'react'
import { useState } from 'react'
import Page from '_organisms/Page'
import { useTranslation } from 'react-i18next'
import { PaginationInfos, SearchFieldDefaultFilterKey } from 'interfaces/Table.interfaces'
import rmsApi from 'utils/api'
import { GENERATE_PARCEL_COLLECTION_URL, PARCELS_READY_TO_SEND_URL } from 'utils/routes/backend'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'
import dayjs from 'dayjs'
import { ParcelStatus, ParcelStatusDisplay } from 'interfaces'
import MobileSearchFilter from '_organisms/tableV2/filters/MobileSearchFilter'
import CheckboxListFilter from '_organisms/tableV2/filters/CheckboxListFilter'
import TableV2 from '_organisms/tableV2/TableV2'
import { toast } from 'utils/toast'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import Status from '../../_atoms/badges/Status'
import DatePickerFilter from '../../_organisms/tableV2/filters/DatePickerFilter'
import { JuneEvent, trackJuneEvent } from '../../utils/june'

interface TableRowParcel {
    parcelId: number
    trackingCode: JSX.Element
    carrier: string
    omsOrderId: string
    shippingDate: string
    status: JSX.Element
}

const ParcelCollectionTable: React.FC = () => {
    const { t } = useTranslation()
    const buildErrorMessage = useErrorMessage()

    const selectedCustomers = useSelectedCustomersStore((state: SelectedCustomersState) => state.selectedCustomers)

    const [parcelCollection, setParcelCollection] = useState<TableRowParcel[]>([])

    const allCarriers = selectedCustomers
        .flatMap((customer) => customer.carriers || [])
        .filter((carrier, index, self) => index === self.findIndex((c) => c.id === carrier.id))

    const defaultStartDate = dayjs().startOf('year')
    const defaultEndDate = dayjs().add(15, 'day')

    async function buildRows(parcels: any) {
        const rows: TableRowParcel[] = []

        for (const parcel of parcels) {
            const trackingCode = (
                <a target="_blank" rel="noopener noreferrer" href={parcel.trackingLink}>
                    {parcel.trackingCode}
                </a>
            )

            let omsOrderId
            if (parcel.shipment.order) {
                omsOrderId = parcel.shipment.order.omsOrderId
            }
            const shippingDate = dayjs(parcel.shipment.shippingDate).format('DD/MM/YY')
            const status = (
                <Status
                    key={parcel.id}
                    label={t(`parcelStatus.${parcel.status}`)}
                    color={ParcelStatusDisplay[parcel.status as ParcelStatus]}
                />
            )

            const carrier = parcel.shipment.carrier.name
            const parcelId = parcel.id

            rows.push({
                parcelId,
                trackingCode,
                omsOrderId,
                shippingDate,
                status,
                carrier,
            })
        }

        setParcelCollection(rows)
    }

    function buildSearchParams(filters: any) {
        const search = filters.searchField ? `&search=${filters.searchField}` : ''
        const carrier = filters.carrier ? `&carrier=${filters.carrier}` : ''
        const from = `&from=${(filters.dateRange?.startDate || defaultStartDate).format('YYYY-MM-DD')}`
        const to = `&to=${(filters.dateRange?.endDate || defaultEndDate).format('YYYY-MM-DD')}`

        return `${search}${carrier}${from}${to}`
    }

    async function fetchParcels(
        filters: any,
        currentPage: number,
        itemsPerPage: number,
        setPagination: (p: PaginationInfos) => void,
    ): Promise<void> {
        const params = buildSearchParams(filters)

        try {
            const request = await rmsApi.get(
                `${PARCELS_READY_TO_SEND_URL}?page=${currentPage}&limit=${itemsPerPage}${params}`,
            )
            const { items, meta } = request.data

            if (meta) setPagination(meta)
            await buildRows(items)
        } catch (e: any) {
            toast.error(buildErrorMessage(e))
        }
    }

    const header = [
        { value: 'trackingCode', label: 'forthParcels.columnTracking' },
        { value: 'carrier', label: 'forthParcels.collapsed.carrier' },
        { value: 'omsOrderId', label: 'orders.columnOmsOrderId' },
        { value: 'shippingDate', label: 'forthParcels.columnShippingDate' },
        { value: 'status', label: 'shipments.columnStatus' },
    ]

    const filterOptions = (filters: any) => [
        {
            filterKey: SearchFieldDefaultFilterKey,
            label: t('table.searchField'),
            component: <MobileSearchFilter placeholder={t('parcelCollection.searchInput.placeholder')} />,
        },
        {
            filterKey: 'carriers',
            label: t('parcelCollection.filters.carriers'),
            component: (
                <CheckboxListFilter
                    options={(allCarriers.length > 0 && allCarriers.map((carrier) => carrier.name!)) || []}
                    filteredOptions={filters?.carrier || []}
                />
            ),
        },
        {
            filterKey: 'dateRange',
            label: t('returns.filters.dateRange'),
            component: (
                <DatePickerFilter
                    startDate={filters?.dateRange?.startDate || defaultStartDate}
                    endDate={filters?.dateRange?.endDate || defaultEndDate}
                    range
                />
            ),
        },
    ]

    const defaultFilters = { dateRange: { startDate: defaultStartDate, endDate: defaultEndDate } }

    const generateParcelCollectionPdf = async (filters: any, selectedRows: any[]) => {
        try {
            const parcelIds = selectedRows.map((row) => row.parcelId)
            const res = await rmsApi.post(GENERATE_PARCEL_COLLECTION_URL, { parcelIds })
            const pdf = res?.data
            const link = document.createElement('a')
            const linkSource = `data:application/pdf;base64,${pdf}`
            const fileName = `parcel-collection_${dayjs().format('YYYYMMssSSS')}`

            link.href = linkSource
            link.download = fileName
            link.click()
            trackJuneEvent(JuneEvent.PARCEL_COLLECTION_PDF_CREATED)
        } catch (e: any) {
            toast.error(t('parcelCollection.errorGeneratingPdf'))
        }
    }

    const batchActions = (filters: any, selectedRows: any[]) => [
        {
            label: t('parcelCollection.button'),
            action: () =>
                generateParcelCollectionPdf(
                    filters,
                    selectedRows.map((s) => s.row),
                ),
        },
    ]

    return (
        <Page
            title={t('menu.shipmentsAndWithdrawals.parcelCollection')}
            section={t('menu.shipmentsAndWithdrawals.title')}
            contentPaddingTopDesktop={2}
        >
            <TableV2
                fetchRows={fetchParcels}
                initialHeader={header}
                rows={parcelCollection}
                filterOptions={filterOptions}
                batchActions={batchActions}
                searchFieldPlaceholder={t('parcelCollection.searchInput.placeholder')}
                defaultFilters={defaultFilters}
                localStorageName="parcelCollectionTable"
            />
        </Page>
    )
}

export default ParcelCollectionTable
