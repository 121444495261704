import * as React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import SquaredImage, { SquaredImageProps } from '_atoms/images/SquaredImage'
import { UniqueProductStatus, UniqueProductStatusDisplay, UniqueProductStatusFront } from 'interfaces'
import Status from '_atoms/badges/Status'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        status: {
            position: 'absolute',
            bottom: theme.space(1),
            left: theme.space(1),
            right: theme.space(1),
            width: `calc(100% - ${theme.space(2)}px)!important`,
            overflow: 'hidden',
            whiteSpace: 'normal',
            padding: theme.space(3 / 5, 0),
            lineHeight: theme.typography.pxToRem(14),
        },
    }),
)

interface SquaredImageWithStatusProps extends SquaredImageProps {
    status: UniqueProductStatus | UniqueProductStatusFront
}

const SquaredImageWithStatus: React.FC<SquaredImageWithStatusProps> = ({ status, ...squaredImageProps }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Box className={classes.root}>
            <SquaredImage {...squaredImageProps} />
            <Status
                color={UniqueProductStatusDisplay[status]}
                label={t(`uniqueProductStatus.${status}`)}
                className={classes.status}
            />
        </Box>
    )
}

export default SquaredImageWithStatus
