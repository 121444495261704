import { Fragment } from 'react'
import * as React from 'react'
import { Grid, Typography } from '@material-ui/core'
import BasicModal from '../reusable/BasicModal'
import { CalendarDetails, CalendarEvent } from 'interfaces'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Card from './Card'

type EventModalProps = {
    title: string
    description: string
    event: CalendarEvent
    eventDetailsArray: CalendarDetails[] | null
    isOpen: boolean
    onClose: () => void
}

const EventModal: React.FC<EventModalProps> = ({ title, description, event, eventDetailsArray, isOpen, onClose }) => {
    const { t } = useTranslation()

    const appointmentTime = () => {
        const start = dayjs(event.start).format('HH[h]mm')
        const end = dayjs(event.end).format('HH[h]mm')
        return `${start} - ${end}`
    }

    return (
        <BasicModal
            title={title}
            isOpen={isOpen}
            description={`${description} : ${appointmentTime()}`}
            onClose={onClose}
        >
            {eventDetailsArray && (
                <>
                    <Grid container spacing={2} direction="row" alignItems="flex-start">
                        {eventDetailsArray.map((eventDetails, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card
                                    title={eventDetails.renterFullname}
                                    loading={false}
                                    actions={[
                                        {
                                            title: t('orders.appointments.rescheduleLink'),
                                            link: eventDetails.rescheduleLink,
                                        },
                                        { title: t('orders.appointments.cancelLink'), link: eventDetails.cancelLink },
                                    ]}
                                >
                                    <Grid item xs={12}>
                                        <Typography>
                                            <b>{t('orders.appointments.renterEmail')}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{eventDetails.renterEmail}</Typography>
                                    </Grid>
                                    {eventDetails.questionsAndAnswers?.length &&
                                        eventDetails.questionsAndAnswers.map(({ question, answer }, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <b>{question}</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>{answer}</Typography>
                                                </Grid>
                                            </Fragment>
                                        ))}
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </BasicModal>
    )
}

export default EventModal
