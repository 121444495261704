import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import {
    Box,
    Collapse,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import useIsDrawerOpenStore, { IsDrawerOpenState } from 'utils/store/useIsDrawerOpen.store'
import useConnectedUserStore, { ConnectedUserState } from 'utils/store/useConnectedUser.store'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useTranslation } from 'react-i18next'
import { buildRmsMenuCategories, RmsMenuItem } from './buildRmsMenuCategories'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from 'components/Logo'
import CustomerSwitcher from '_organisms/drawerMenu/CustomerSwitcher'
import useSelectedCustomersStore from 'utils/store/useSelectedCustomers.store'
import { SelectedCustomersState } from 'utils/store/selectedCustomers.store'
import { ICustomer } from 'interfaces/Customer'
import { faCog, faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import AccountPopover from '_organisms/drawerMenu/AccountPopover'
import useSelectedWarehouseStore from '../../utils/store/useSelectedWarehouse.store'
import { SelectedWarehouseState } from '../../utils/store/selectedWarehouse.store'

const useStyles = makeStyles((theme) => ({
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        width: theme.space(68),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100vh',
        },
        '& .MuiPaper-root': {
            background: theme.palette.primary[600],
        },
    },
    drawerOpen: {
        transition: '.25s ease-in-out',
        width: theme.space(68),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    drawerClose: {
        transition: '.25s ease-in-out',
        width: 0,
        [theme.breakpoints.up('md')]: {
            width: theme.space(16),
        },
        '& .MuiCollapse-container': {
            display: 'none',
        },
        '& .MuiBox-root': {
            padding: theme.space(0),
            '& hr': {
                marginRight: theme.space(2),
            },
            '& .MuiButtonBase-root': {
                width: theme.space(11),
            },
            '& svg': {
                margin: 0,
            },
            '& .MuiListItemText-root': {
                maxWidth: 0,
            },
        },
        '& .MuiTypography-root': {
            display: 'none',
        },
    },
    closeMenuButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    linkTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.space(6, 0),
        height: theme.space(9),
    },
    bottomDivider: {
        backgroundColor: 'rgb(256, 256, 256, 0.16)',
    },
    customerSwitcherContainer: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.primary[50],
            height: theme.space(20),
        },
    },
    navContainer: {
        flex: 1,
        overflowY: 'scroll',
        overflowX: 'hidden',
        color: theme.palette.common.white,
        MsOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'space-between',
            height: '100%',
            backgroundColor: `${theme.palette.primary[50]}`,
            paddingTop: theme.space(3),
            paddingBottom: 'none',
        },
    },
    bottomNavContainer: {
        padding: theme.space(3, 0),
        overflowY: 'scroll',
        overflowX: 'hidden',
        color: theme.palette.common.white,
        MsOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'space-between',
            height: '100%',
            backgroundColor: `${theme.palette.primary[50]}`,
            paddingTop: theme.space(3),
            paddingBottom: 'none',
        },
    },
    link: {
        display: 'flex',
        padding: theme.space(0, 5),
        marginBottom: theme.space(1),
        '& .MuiListItem-root': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: theme.space(0, 3),
            height: theme.space(10),
            borderRadius: '6px',
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary[700],
                backgroundColor: theme.palette.primary[50],
            },
        },
        '& svg': {
            marginRight: theme.space(3),
            width: '16px',
            height: 'auto',
            padding: '4px',
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary[700],
            },
        },
        '& .MuiListItem-root:hover': {
            [theme.breakpoints.up('sm')]: {
                background: 'rgba(255, 255, 255, 0.04)',
            },
        },
        '& .MuiTypography-root': {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    currentLink: {
        opacity: 1,
        paddingLeft: theme.space(0),
        '& hr': {
            background: theme.palette.common.white,
            width: '3px',
            height: theme.space(10),
            marginRight: theme.space(5),
            borderRadius: '0px 6px 6px 0px',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.primary[700],
            },
        },
        '& .MuiListItem-root': {
            background: 'rgba(255, 255, 255, 0.16)',
            [theme.breakpoints.down('sm')]: {
                background: theme.palette.primary[100],
            },
        },
        '& .MuiTypography-root': {
            color: theme.palette.common.white,
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary[700],
            },
        },
        '& ~ .MuiCollapse-container': {
            marginTop: theme.space(2),
        },
    },
    sublink: {
        opacity: 0.8,
        height: theme.space(5),
        margin: theme.space(0, 5, 3, 5 + 3 / 5),
        width: 'auto',
        borderRadius: '6px',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.primary[700],
        },
        '& svg': {
            fontSize: theme.space(4 / 5),
            visibility: 'hidden',
            minWidth: '4px',
            padding: theme.space(0, 5),
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary[700],
            },
        },
        '&:hover': {
            background: 'none',
            '& svg': {
                color: 'rgba(255, 255, 255, 0.4)',
                visibility: 'visible',
                [theme.breakpoints.down('sm')]: {
                    color: theme.palette.primary[700],
                },
            },
            '& .MuiTypography-root': {
                color: theme.palette.common.white,
            },
        },
        '& .MuiTypography-root': {
            fontSize: theme.typography.pxToRem(14),
            color: 'rgba(255, 255, 255, 0.64)',
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary[700],
            },
        },
        '& .MuiListItemText-root': {
            margin: 0,
        },
    },
    currentSublink: {
        opacity: 1,
        '& svg': {
            visibility: 'visible',
        },
        '& .MuiTypography-root': {
            color: theme.palette.common.white,
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary[700],
            },
        },
    },
    rmsMenuCategories: {
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            paddingTop: theme.space(4),
            backgroundColor: '#FFF',
            borderTop: `1px solid ${theme.palette.neutral[200]}`,
        },
    },
}))

type DrawerMenuProps = {
    hasRealmRole: (roles: string[]) => boolean
    logout: () => void
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({ hasRealmRole, logout }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))
    const navigate = useNavigate()
    const { trackPageView } = useMatomo()
    const [user] = useConnectedUserStore((state: ConnectedUserState) => [state.user])
    const [shouldDisplaySecondHandElements] = useSelectedCustomersStore((state: SelectedCustomersState) => [
        state.shouldDisplaySecondHandElements,
    ])
    const [customers, setCustomers] = useState<ICustomer[]>([])
    const [selectedWarehouse] = useSelectedWarehouseStore((state: SelectedWarehouseState) => [state.selectedWarehouse])

    const [isDrawerOpen, setIsDrawerOpen] = useIsDrawerOpenStore((state: IsDrawerOpenState) => [
        state.isDrawerOpen,
        state.setIsDrawerOpen,
    ])
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [openedSubmenu, setOpenedSubmenu] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (isMobile) {
            setIsDrawerOpen(false)
        } else {
            setIsDrawerOpen(true)
        }
    }, [isMobile])
    const rmsMenuCategories = buildRmsMenuCategories(
        t,
        shouldDisplaySecondHandElements(),
        !selectedWarehouse?.hasCalendlyToken,
    )

    useEffect(() => {
        // Track page view
        trackPageView({ documentTitle: location.pathname })
        // Open the submenu according to the displayed page
        const pathname = `${location.pathname}${location.search}`
        const displayedRoute = rmsMenuCategories.filter(
            (e) => pathname === e.route || e.submenu?.map((f) => f.route).includes(pathname),
        )
        if (displayedRoute.length === 1) {
            setOpenedSubmenu(displayedRoute[0].index)
        }
    }, [location])

    const openFreshdeskWidget = () => {
        window.FreshworksWidget('open')
    }

    function onCategoryClick(category: RmsMenuItem) {
        const { submenu, index, route, external } = category
        if (!isDrawerOpen) setIsDrawerOpen(true)
        if (submenu) {
            openedSubmenu !== index ? setOpenedSubmenu(index) : setOpenedSubmenu(undefined)
        } else {
            if (external) {
                window.open(route, '_blank')?.focus()
            } else {
                if (rmsMenuCategories.map((e) => e.route).includes(route)) {
                    setOpenedSubmenu(undefined)
                }
                navigate(route!)
            }
            if (isMobile) {
                setIsDrawerOpen(false)
            }
        }
    }

    function hideCategory(category: RmsMenuItem) {
        const { roles, route, disabled, submenu } = category
        return !hasRealmRole(roles) || (route === undefined && submenu === undefined) || disabled
    }

    function currentLinkClass(category: RmsMenuItem) {
        const { route, submenu } = category
        const pathname = `${location.pathname}${location.search}`
        return pathname === route || submenu?.map((e) => e.route).includes(pathname) ? classes.currentLink : ''
    }

    function currentSublinkClass(category: RmsMenuItem) {
        const { route } = category
        return route === `${location.pathname}${location.search}` ? classes.currentSublink : ''
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isDrawerOpen,
                [classes.drawerClose]: !isDrawerOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: isDrawerOpen,
                    [classes.drawerClose]: !isDrawerOpen,
                }),
            }}
        >
            <IconButton onClick={() => setIsDrawerOpen(false)} className={classes.closeMenuButton}>
                <CloseIcon />
            </IconButton>
            <Link data-testid="homeHeader" className={classes.linkTitle} to={'/'}>
                <Logo isTiny={!isDrawerOpen} />
            </Link>
            <List component="nav" data-testid="menuList" className={classes.navContainer}>
                <div className={classes.customerSwitcherContainer}>
                    {isMobile && <Divider className={classes.bottomDivider} />}
                    {user.customers.length > 1 && (
                        <CustomerSwitcher
                            user={user}
                            isDrawerOpen={isDrawerOpen}
                            customers={customers}
                            setCustomers={setCustomers}
                        />
                    )}
                </div>
                <div className={classes.rmsMenuCategories} data-testid="menu">
                    {rmsMenuCategories.map(
                        (category) =>
                            !hideCategory(category) && (
                                <div key={category.name}>
                                    <Box
                                        className={`${classes.link} ${currentLinkClass(category)} ${
                                            category.index === openedSubmenu ? 'currentOpened' : ''
                                        }`}
                                        data-testid={category.submenu ? 'collapsable' : ''}
                                    >
                                        <Divider orientation="vertical" />
                                        <ListItem button onClick={() => onCategoryClick(category)} disableGutters>
                                            {category.icon}
                                            <ListItemText primary={category.name} />
                                        </ListItem>
                                    </Box>
                                    <Collapse
                                        in={openedSubmenu === category.index}
                                        timeout="auto"
                                        unmountOnExit
                                        data-testid="subMenuList"
                                    >
                                        {category.submenu?.map(
                                            (subcategory, index) =>
                                                !hideCategory(subcategory) && (
                                                    <ListItem
                                                        button
                                                        key={subcategory.name}
                                                        onClick={() => onCategoryClick(subcategory)}
                                                        className={`${classes.sublink} ${currentSublinkClass(
                                                            subcategory,
                                                        )}`}
                                                        data-testid={`menuLink-${index}`}
                                                    >
                                                        <FontAwesomeIcon icon={faCircle} color="#fff" />
                                                        <ListItemText primary={subcategory.name} />
                                                    </ListItem>
                                                ),
                                        )}
                                    </Collapse>
                                </div>
                            ),
                    )}
                </div>
            </List>
            {!isMobile && (
                <>
                    <Divider className={classes.bottomDivider} />
                    <List component="nav" data-testid="menuList" className={classes.bottomNavContainer}>
                        <div className={classes.rmsMenuCategories} data-testid="menu">
                            <Box className={`${classes.link} `} data-testid={'help'}>
                                <Divider orientation="vertical" />
                                <ListItem button onClick={() => openFreshdeskWidget()} disableGutters>
                                    <FontAwesomeIcon icon={faQuestionCircle} color="#fff" />
                                    <ListItemText primary={t('menu.help.title')} />
                                </ListItem>
                            </Box>

                            <Box className={`${classes.link} `} data-testid={'help'}>
                                <Divider orientation="vertical" />
                                <ListItem button onClick={handleClick} disableGutters data-testid="account">
                                    <FontAwesomeIcon icon={faCog} color="#fff" />
                                    <ListItemText primary={user.name} />
                                </ListItem>
                            </Box>
                        </div>
                    </List>
                    <AccountPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} logout={logout} />
                </>
            )}
        </Drawer>
    )
}

export default DrawerMenu
