import * as React from 'react'
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { faArrowRight, faScannerGun } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import ModalWithHeader, { HeaderType } from '_molecules/ModalWithHeader/ModalWithHeader'
import ModalFooter from '_molecules/ModalFooter'
import ScanInput from '_molecules/ScanInput'
import { toast } from 'utils/toast'
import rmsApi from 'utils/api'
import { UNIQUE_PRODUCT_UPDATE_EAN_OR_SKU } from 'utils/routes/backend'
import { useState } from 'react'
import { useErrorMessage } from 'utils/hooks/useBuildErrorMessage'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            color: theme.palette.neutral[600],
        },
        scanInput: {
            width: '100%',
            '& .MuiInput-root': {
                backgroundColor: `${theme.palette.primary[50]}!important`,
                boxShadow: 'none',
            },
        },
    }),
)

type UpdateEanOrSkuModalProps = {
    initialUniqueProduct: { id: number; initialEan: string; initialSku: string; puid: string }
    title: string
    tagline: string
    handleClose: () => void
    handleSuccess: (eanOrSku: string) => void
}

const UpdateEanOrSkuModal: React.FC<UpdateEanOrSkuModalProps> = ({
    initialUniqueProduct,
    title,
    tagline,
    handleClose,
    handleSuccess,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const buildErrorMessage = useErrorMessage()
    const { t } = useTranslation()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [value, setValue] = useState<string>('')

    async function fetchProductReference(newEanOrSku: string) {
        try {
            await rmsApi.patch(UNIQUE_PRODUCT_UPDATE_EAN_OR_SKU(initialUniqueProduct.id), {
                eanOrSku: newEanOrSku,
            })

            toast.success(
                t('wms.uniqueProducts.updateEanSkuModal.success', {
                    eanOrSku: newEanOrSku,
                    barcodeUid: initialUniqueProduct.puid,
                }),
            )
            handleSuccess && handleSuccess(newEanOrSku)
            handleClose()
        } catch (e: any) {
            toast.error(buildErrorMessage(e, { eanOrSku: newEanOrSku, barcodeUid: initialUniqueProduct.puid }))
        }
    }

    return (
        <ModalWithHeader
            title={title}
            tagline={tagline}
            open={true}
            width={520}
            handleClose={handleClose}
            disableRestoreFocus
            headerType={HeaderType.MOBILE}
            headerIconColor={theme.palette.neutral[900]!}
            withDesktopTagline
        >
            <ScanInput
                placeholder={t('wms.uniqueProducts.updateEanSkuModal.inputPlaceholder')}
                onSubmit={fetchProductReference}
                iconStart={faScannerGun}
                className={`${classes.scanInput}`}
                value={value}
                onChange={(value) => setValue(value)}
            />
            <ModalFooter
                label={t('wms.uniqueProducts.updateEanSkuModal.submitButton')}
                endIcon={faArrowRight}
                onSubmit={() => fetchProductReference(value)}
                onCancel={handleClose}
                submitDisabled={value === ''}
            />
        </ModalWithHeader>
    )
}

export default UpdateEanOrSkuModal
